export const columns = () => ([
    {
        Header: 'Tanggal',
        accessor: 'tanggal',
    },
    {
        Header: 'Nilai',
        accessor: 'nilai',
    },
    {
        Header: 'Waktu Pengerjaan',
        accessor: 'durasiPengerjaan',
    },
]);

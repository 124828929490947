import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { hideErrorAlert, showErrorAlert } from "../../../app";
import { loginUser } from "../../service/AuthService";
import { loginAction } from "./AuthSlice";
import { GetParentPath } from "../../../common";
const { version } = require("../../../../package.json");

function LoginForm(props) {
  const dispatch = useDispatch();
  const [username, setUsername] = useState("");
  const [usernameError, setUsernameError] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const parentPath = GetParentPath();
  const navigate = useNavigate();
  const location = useLocation();
  const authed = useSelector((state) => state.auth.jsonWebToken);
  const from = location.state?.from?.pathname || `${parentPath}/dashboard`;

  useEffect(() => {
    // jika user ingin mengakses suatu halaman yang butuh otentikasi namun user belum login,
    // user akan diarahkan ke halaman login, dan jika login berhasil user akan diarahkan
    // ke halaman yang sebelumnya ingin diakses
    if (authed) navigate(from, { replace: true });
  }, [from, authed, navigate]);

  const loginFormHeader = (
    <Container fluid className="text-center" style={{ marginBottom: "4rem" }}>
      <h4>
        <b>Login</b>
      </h4>
    </Container>
  );

  const usernameField = (
    <Form.Group className="mb-3 w-100 fsz-075" controlId="usernameField">
      <Form.Label>Username/E-mail :</Form.Label>
      <Form.Control
        placeholder="Masukkan Username/E-mail"
        type="text"
        className={`${
          usernameError ? "login-form-element-error" : "login-form-element"
        }`}
        onChange={(e) => {
          setUsername(e.target.value);
          setUsernameError(false);
        }}
      />
    </Form.Group>
  );

  const passwordField = (
    <Form.Group className="mb-3 w-100 fsz-075" controlId="passwordField">
      <Form.Label>Kata Sandi :</Form.Label>
      <InputGroup>
        <Form.Control
          placeholder="Masukkan Sandi"
          type={showPassword ? "text" : "password"}
          className={`${
            passwordError ? "login-form-element-error" : "login-form-element"
          } border-end-0`}
          onChange={(e) => {
            setPassword(e.target.value);
            setPasswordError(false);
          }}
        />
        <Button
          variant="light"
          onClick={() => setShowPassword((prev) => !prev)}
          className={`${
            passwordError ? "login-form-element-error" : "login-form-element"
          } border-start-0 bg-white`}
        >
          {showPassword ? (
            <FontAwesomeIcon icon={faEye} color={"#6C757D"} />
          ) : (
            <FontAwesomeIcon icon={faEyeSlash} color={"#6C757D"} />
          )}
        </Button>
      </InputGroup>
    </Form.Group>
  );

  const forgotPasswordLink = (
    <Container
      fluid
      className="text-end p-0 fsz-075"
      style={{ marginBottom: "2.5rem" }}
    >
      <Link
        className="forgot-password-link text-decoration-none"
        to={`${parentPath}/lupa-sandi`}
      >
        <b>Lupa Kata Sandi</b>
      </Link>
    </Container>
  );

  const loginButton = (
    <Container fluid className="fsz-0875 text-center">
      <Button
        variant="primary"
        type="submit"
        className="px-5"
        style={{ marginBottom: "2.25rem" }}
      >
        <b>Masuk</b>
      </Button>
    </Container>
  );

  const aboutLink = (
    <Container fluid className="text-start p-0 fsz-075">
      <Link
        className="login-footer-link text-decoration-none"
        to={`${parentPath}/lupa-sandi`}
      >
        <b>About</b>
      </Link>
    </Container>
  );

  const contactLink = (
    <Container fluid className="text-end p-0 fsz-075">
      <Link
        className="login-footer-link text-decoration-none"
        to={`${parentPath}/lupa-sandi`}
      >
        <b>Contact</b>
      </Link>
    </Container>
  );

  const login = () => {
    loginUser(username, password)
      .then(
        (response) => {
          switch (response.data.status_code) {
            case "LGN200":
              dispatch(loginAction(response.data.message.jwt));
              break;
            case "LGN402":
              setUsernameError(true);
              setPasswordError(true);
              dispatch(showErrorAlert("E-mail atau kata sandi salah!"));
              break;
            case "LGN404":
              dispatch(
                showErrorAlert("Akun tidak aktif! Silakan hubungi admin!")
              );
              break;
            default:
              dispatch(showErrorAlert("Terjadi kesalahan pada server!"));
              break;
          }
        },
        (error) => {
          console.log("Auth error :", error);
        }
      )
      .finally((info) => {});
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(hideErrorAlert());

    if (username === "") {
      setUsernameError(true);
      dispatch(showErrorAlert("Isi Username/E-mail!"));
    } else if (password === "") {
      setPasswordError(true);
      dispatch(showErrorAlert("Isi kata Sandi!"));
    } else {
      login();
    }
  };

  return (
    <Container
      className="login-form-container bg-white ms-auto me-0"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <div>
        {loginFormHeader}
        <Form noValidate onSubmit={(e) => handleSubmit(e)}>
          {usernameField}
          {passwordField}
          {forgotPasswordLink}
          {loginButton}
        </Form>
        <Row className="mx-0">
          <Col className="p-0">{aboutLink}</Col>
          <Col className="p-0">{contactLink}</Col>
        </Row>
      </div>
      <div className="align-self-center">
        <span style={{ fontWeight: 600, fontSize: 14 }}>v {version}</span>
      </div>
    </Container>
  );
}

export default LoginForm;

import {
    Button, ProgressBar
} from "react-bootstrap";

export const columns = ({ lihatFunction }) => ([
    {
        Header: 'Judul',
        accessor: 'judul',
    },
    {
        Header: 'Status',
        accessor: 'isDone',
        disableSortBy: true,
        Cell: (row) => {
            return (
                <>
                    {
                        row.row.original.isDone ?
                            <Button variant="success" className="w-25"
                                onClick={(e) => lihatFunction(e, row.row.original.id)}
                            >
                                Selesai
                            </Button> :
                            <Button variant="primary" className="w-25"
                                onClick={(e) => lihatFunction(e, row.row.original.id)}
                            >
                                Lihat
                            </Button>
                    }
                </>
            );
        }
    },
]);

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { showErrorAlert } from '../../../app';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

// styling
import './KuisAkhir.css';
import { faEye, faEyeSlash, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Container,
    Form,
    Row,
    Col,
    InputGroup
} from 'react-bootstrap';

// Component
import { Table } from '../../common/table';
import { columns } from './detailcolumns';

// API
import { listSoalKuisAkhirTrainer } from '../../service/SoalKuisAkhirService';
import SoalKuisAkhirPopUp from './SoalKuisAkhirPopup';
import SoalKuisAkhirPopUpDelete from './SoalKuisAkhirPopupDelete';
import SoalKuisAkhirDeleteConfirmation from './SoalKuisAkhirDeleteConfirmation';

function KuisAkhirDetail({ kuisAkhirData, setSearchParams }) {
    const [idParams, setIdParams] = useSearchParams();
    const [id, setId] = useState(idParams.get('id'));
    const [judul, setJudul] = useState();
    const [idTopik, setIdTopik] = useState();
    const [judulTopik, setJudulTopik] = useState();
    const [idBab, setIdBab] = useState();
    const [namaBab, setNamaBab] = useState();
    const [idSubBab, setIdSubBab] = useState();
    const [namaSubbab, setNamaSubbab] = useState();
    const [visibilitas, setVisibilitas] = useState('');

    useEffect(() => {
        setId(kuisAkhirData?.id || '');
        setJudul(kuisAkhirData?.judul || '');
        setIdTopik(kuisAkhirData?.idTopik || '');
        setJudulTopik(kuisAkhirData?.judulTopik || '');
        setIdBab(kuisAkhirData?.idBab || '');
        setNamaBab(kuisAkhirData?.namaBab || '');
        setIdSubBab(kuisAkhirData?.idSubBab || '');
        setNamaSubbab(kuisAkhirData?.namaSubbab || '');
        setVisibilitas(kuisAkhirData?.visibilitas || 'sembunyi');
    }, [kuisAkhirData]);

    const judulKuisField = <Col className='p-0'>
        <Form.Group>
            <Form.Label>Judul Kuis</Form.Label>
            <InputGroup>
                <Form.Label className='fsz-125'><b>{judul}</b></Form.Label>
            </InputGroup>
        </Form.Group>
    </Col>

    const judulTopikField = <Col className='p-0'>
        <Form.Group>
            <Form.Label>Judul/Topik Materi</Form.Label>
            <InputGroup>
                <Form.Label className='fsz-125'><b>{judulTopik}</b></Form.Label>
            </InputGroup>
        </Form.Group>
    </Col>

    const firstRowForm = <Row className='mx-0 my-3'>
        {judulKuisField}
        {judulTopikField}
    </Row>

    const visibilitasField = <Col className='p-0'>
        <Form.Group>
            <Form.Label>Visibilitas</Form.Label>
            <InputGroup>
                <Form.Label className='fsz-125'>
                    {visibilitas === 'publik'
                        ? <FontAwesomeIcon icon={faEye} size='xs' />
                        : <FontAwesomeIcon icon={faEyeSlash} size='xs' />
                    }
                    <b className='ms-2'>{visibilitas.charAt(0).toUpperCase() + visibilitas.slice(1).toLowerCase()}</b>
                </Form.Label>
            </InputGroup>
        </Form.Group>
    </Col>

    const namaBabField = <Col className='p-0'>
        <Form.Group>
            <Form.Label>Bab</Form.Label>
            <InputGroup>
                <Form.Label className='fsz-125'><b>{namaBab}</b></Form.Label>
            </InputGroup>
        </Form.Group>
    </Col>



    const secondRowForm = <Row className='mx-0 mb-3'>
        {visibilitasField}
        {namaBabField}
    </Row>

    const namaSubBabField = <Col className='p-0'>
        <Form.Group>
            <Form.Label>Sub Bab</Form.Label>
            <InputGroup>
                <Form.Label className='fsz-125'><b>{namaSubbab}</b></Form.Label>
            </InputGroup>
        </Form.Group>
    </Col>

    const thirdRowForm = <Row className='mx-0 mb-3'>
        <Col xs={6}></Col>
        {namaSubBabField}
    </Row>

    // Soal Kuis Table
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const [showPopUp, setShowPopUp] = useState(false);
    const [refreshData, setRefreshData] = useState(1);
    const [showPopUpDelete, setShowPopUpDelete] = useState(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [soalKuisAkhirData, setSoalKuisAkhirData] = useState();

    useEffect(() => {
        listSoalKuisAkhirTrainer({idKuisAkhir:id}).then(
            (response) => {
                switch (response.data.status_code) {
                    case 'SKA200':
                        setData(response.data.message);
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('Soal Kuis Akhir error :', error);
            }).finally((info) => {

            });
    }, [dispatch, id, refreshData]);

    const addFunction = () => {
        setSoalKuisAkhirData({idKuisAkhir: id});
        setIsEdit(false);
        setShowPopUp(prev => !prev);
    }

    const editFunction = (row) => {
        setSoalKuisAkhirData(row);
        setIsEdit(true);
        setShowPopUp(prev => !prev);
    }

    const deleteFunction = (row) => {
        setSoalKuisAkhirData(row);
        setShowPopUpDelete(prev => !prev);
    }

    return (
        <>
            <SoalKuisAkhirPopUp setRefreshData={setRefreshData}
                show={showPopUp} setShow={setShowPopUp}
                soalKuisAkhirData={soalKuisAkhirData} isEdit={isEdit} />
            <SoalKuisAkhirPopUpDelete
                show={showPopUpDelete} setShow={setShowPopUpDelete}
                setShowDeleteConfirmation={setShowDeleteConfirmation}/>
            <SoalKuisAkhirDeleteConfirmation setRefreshData={setRefreshData}
                show={showDeleteConfirmation}
                setShow={setShowDeleteConfirmation}
                soalKuisAkhirData={soalKuisAkhirData}
                setShowPopUpDelete={setShowPopUpDelete}/>
            <Container className='bg-white p-5 pb-4 rounded mt-5'
                style={{ minWidth: '90%', textAlign: 'justify' }}>
                <Row>
                    <Col>
                        <h4>
                            <span onClick={e => setSearchParams()} style={{ cursor: 'pointer' }}>
                                <FontAwesomeIcon icon={faChevronLeft} size='xs' className='me-2' />
                            </span>
                            Detail Kuis Akhir
                        </h4>
                    </Col>
                </Row>
                {firstRowForm}
                {secondRowForm}
                {thirdRowForm}
            </Container>
            <Table
                data={data}
                columns={columns(editFunction, deleteFunction)}
                rowClickFunction={(e) => {}}
                addFunction={addFunction}
                title='Soal Pilihan Ganda' />
        </>
    );

}

export default KuisAkhirDetail;
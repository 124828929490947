import { Badge } from "react-bootstrap";
import { 
    FontAwesomeIcon
 } from "@fortawesome/react-fontawesome";

 import {
    faEye,
    faEyeSlash,
    faEdit,
    faTrash
} from '@fortawesome/free-solid-svg-icons';

export const columns = (editFunction, deleteFunction) => [
    {
        Header: "No",
        accessor: "nomor",
        Cell: (row) => {
            return <div>{Number(row.row.id) + 1}</div>;
        },
        disableFilters: true,
        disableSortBy: true,
    },
    {
        Header: 'Judul/Topik',
        accessor: 'judul',
    },
    {
        Header: 'Tingkatan',
        accessor: 'tingkatan',
        Cell: ({ cell: { value } }) => {
            if (value.toLowerCase() === 'mudah') return <span className='badge w-100 tingkatan-mudah'>{value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()}</span>;
            else if (value.toLowerCase() === 'sedang') return <span className='badge w-100 tingkatan-sedang'>{value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()}</span>;
            else return <span className='badge w-100 tingkatan-sulit'>{value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()}</span>;
        }
    },
    {
        Header: 'Visibilitas',
        accessor: 'visibilitas',
        Cell: ({ cell: { value } }) => {
            if (value.toLowerCase() === 'publik') return <><FontAwesomeIcon icon={faEye} size='xs' /> Publik</>;
            else return <><FontAwesomeIcon icon={faEyeSlash} size='xs' /> Sembunyi</>;
        }
    },
    {
        Header: 'Tag',
        accessor: 'tag'
    },
    {
        Header: 'Aksi',
        accessor: 'aksi',
        disableSortBy: true,
        disableFilters: true,
        Cell: (row) => {
            return (
                <>
                    <FontAwesomeIcon icon={faEdit} color="#ffc107" className='me-2'
                        onClick={(e) => editFunction(row.row.original)}
                        style={{ cursor: 'pointer' }}
                    />
                    <FontAwesomeIcon icon={faTrash} color="#dc3545"
                        onClick={(e) => deleteFunction(row.row.original)}
                        style={{ cursor: 'pointer' }}
                    />
                </>
            );
        }
    },
];

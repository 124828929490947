import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { showErrorAlert, showSuccessAlert } from '../../../app';
import { getTokenUsername } from '../../service/AuthService';

// component
import { popCrumb, pushCrumb } from '../../common/breadcrumbs';
import { Table } from '../../common/table';
import { columns } from './columns';
// import RiwayatLoginDetail from './RiwayatLoginDetail';

// service
import { listRiwayatLogin } from '../../service/RiwayatLoginService';
import { sendWhatsappRiwayatLogin } from '../../service/WhatsappService';

function RiwayatLogin() {
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const [idTalent, setIdTalent] = useState(searchParams.get('idTalent'));

    const [currentPage, setCurrentPage] = useState(0); // State untuk menyimpan halaman saat ini
    const [pageSize, setPageSize] = useState(5);

    const [data, setData] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [laporanNilaiData, setRiwayatLoginData] = useState();
    const [filterSearch, setFilterSearch] = useState("");
    const [refreshData, setRefreshData] = useState(1);
    const [selectedRows, setSelectedRows] = useState([]);
    const username = getTokenUsername();
    const [isMasterChecked, setIsMasterChecked] = useState(false);
    const [pageCheckStatus, setPageCheckStatus] = useState({});
  
    const [parameter, setParameter] = useState();
    
    // useEffect(() => {
    //   console.log("selectedRows:", selectedRows);
    //   console.log("Array size: ", selectedRows.length);
    // }, [selectedRows]);

    // useEffect(() => {
    //     listRiwayatLogin().then(
    //         (response) => {
    //             console.log("response", response.data.message);
    //             switch (response.data.status_code) {
    //                 case 'TAL200':
    //                     setData(response.data.message || []);
    //                     break;
    //                 default:
    //                     dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
    //                     break;
    //             }
    //         },
    //         (error) => {
    //             console.log('Kuis Akhir error :', error);
    //         }).finally((info) => {

    //         });
    // }, [dispatch, refreshData]);

    useEffect(() => {
        setIdTalent(searchParams.get('idTalent'));
        if (searchParams.get('idTalent')) {
            dispatch(pushCrumb("Nilai"));
        } else {
            dispatch(popCrumb("Nilai"));
        }
    }, [searchParams, dispatch]);

    const detailFunction = (row) => {
        setIdTalent(row.idTalent);
        setRiwayatLoginData(row);
        setSearchParams({ idTalent: row.idTalent });
    };

    const fetchRiwayatLogin = (val, filter) => {
      // setFilterSearch(val);
      const obj =
        filter === "lastLoginDate"
          ?{
            lastLoginFrom: val?.lastLoginFrom,
            lastLoginTo: val?.lastLoginTo,
          } : {
            [filter]: val
          };
      setFilterSearch(obj);
      listRiwayatLogin(obj)
      .then(
        (response) => {
          switch (response.data.status_code) {
            case "TAL200":
              setData(response.data.message || 0);
              break;
              default:
                dispatch(showErrorAlert("Terjadi kesalahan pada server!"));
              break;
          }
        },
        (error) => {
          console.log("Total Talent Join error :", error);
        }
      )
      .finally((info) => {});
    };

    // const fetchSearchRiwayatLogin = (keywordSearch) => {
    //   // setFilterSearch(keywordSearch);
    //   listSearchRiwayatLogin({keyWordSearch: keywordSearch})
    //   .then(
    //     (response) => {
    //       switch (response.data.status_code) {
    //         case "TAL200":
    //           setData(response.data.message || 0);
    //           break;
    //           default:
    //             dispatch(showErrorAlert("Terjadi kesalahan pada server!"));
    //           break;
    //       }
    //     },
    //     (error) => {
    //       console.log("Total Talent Join error :", error);
    //     }
    //   )
    //   .finally((info) => {});
    // };

    const sendWhatsapp = () => {
        const payload = {
          namaAdmin: username,
          talentContacts: selectedRows?.map((data, i) => ({
            noWa: data.nomor_telp
          }))
        }
    
        sendWhatsappRiwayatLogin(payload).then((response) => {
          switch (response.data.status_code) {
            case "WA200":
              dispatch(showSuccessAlert("Pesan berhasil dikirim!"));
              break;
            default:
              dispatch(showErrorAlert("Terjadi kesalahan pada server!"));
              break;
          }
        });
    };

    const handleMasterCheckboxChange = () => {
      const newIsMasterChecked = !pageCheckStatus[currentPage];
      setPageCheckStatus((prevPageCheckStatus) => ({
        ...prevPageCheckStatus,
        [currentPage]: newIsMasterChecked,
      }));
    
      if (newIsMasterChecked) {
        const newData = data?.slice(parameter.start, parameter.end);
        setSelectedRows((prevSelectedRows) => [...prevSelectedRows, ...newData]);
        setIsMasterChecked(true);
      } else {
        setSelectedRows((prevSelectedRows) =>
          prevSelectedRows.filter(
            (prevRow) =>
              !data?.slice(parameter.start, parameter.end).some((newRow) => newRow.id === prevRow.id)
          )
        );
        setIsMasterChecked(false);
      }
    };
    
  
    const handleRowCheckboxChange = (row) => {
      const selectedRowIds = selectedRows.map((row) => row.id);
        selectedRowIds.includes(row.id)
          ? setSelectedRows(selectedRows.filter((r) => r.id !== row.id))
          : setSelectedRows([...selectedRows, row]);
      };
  
    const getItemPage = ({ pageIndex, pageSize }) => {
      const start = pageIndex * pageSize;
      const end = start + pageSize ;
      setParameter({ start, end });
      setCurrentPage(pageIndex);
      setPageCheckStatus((prevPageCheckStatus) => ({
        ...prevPageCheckStatus,
        [pageIndex]: prevPageCheckStatus[pageIndex] || false, 
      }));
    };
  
    // console.log("selection", parameter)
  
    useEffect(() => {
      fetchRiwayatLogin();
    }, [dispatch]);
  
    useEffect(() => {
        setIsMasterChecked(pageCheckStatus[currentPage]);
    }, [currentPage, pageCheckStatus]);

    useEffect(() => {
      localStorage.setItem("pageCheckStatus", JSON.stringify(pageCheckStatus));
    }, [pageCheckStatus]); 
  
    useEffect(() => {
      const storedPageCheckStatus = JSON.parse(localStorage.getItem("pageCheckStatus"));
      setPageCheckStatus(storedPageCheckStatus || {});
    }, []);

    return (
        <>
            {/* {idTalent && <RiwayatLoginDetail
                idTalentRow={idTalent}
                laporanNilaiData={data.find(item => item.idTalent === idTalent)}
                setSearchParams={setSearchParams}
            />} */}
            {!idTalent && <Table
                data={data}
                // rowClickFunction={detailFunction}
                columns={columns(
                    isMasterChecked,
                    handleMasterCheckboxChange, 
                    handleRowCheckboxChange,
                    selectedRows,
                    )}
                showAddButton={false}
                showSearch={false}
                showFilter={false}
                showCustomize={true}
                showToolbar={true}
                customSearch
                size={pageSize}
                setPageSize={setPageSize}
                reverseSearchFilter = {true}
                customFilterSearch
                filterLastLoginDate={true}
                filterJoinDate={false}
                whatsappButton={sendWhatsapp}
                getItemPage={getItemPage}
                whatsappButtonDisabled={selectedRows.length !== 0 ? false : true}
                onSearchChange={(lastLoginFrom, lastLoginTo, keywordSearch) => fetchRiwayatLogin(lastLoginFrom, lastLoginTo, keywordSearch)}
                title='Riwayat Login'
                subTitle='Total Talent Tidak Login >3 Hari' />
            }
        </>
    );
}

export default RiwayatLogin;
import React, { useEffect, useState } from "react";
import {
  Card,
  Col,
  Row
} from "react-bootstrap";
  
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { showErrorAlert } from "../../../app";
import {
  getTotalTalentKelasArutala,
  getTotalTalentKolamBesar,
  getTotalTalentLolosKelasTeknologi,
  getTotalTalentMengerjakanMoodle,
  getTotalTalentTidakLogin
} from "../../service/DashboardService";
import "./Dashboard.css";

export default function StatusTalent(){
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [dataTotalTalentTidakLogin, setDataTotalTalentTidakLogin] = useState(0);
  const [dataTotalTalentKelasArutala, setDataTotalTalentKelasArutala] = useState(0);
  const [dataTotalTalentLolosKelasTeknologi, setDataTotalTalentLolosKelasTeknologi] = useState(0);
  const [dataTotalTalentKolamBesar, setDataTotalTalentKolamBesar] = useState(0);
  const [dataTotalTalentMengerjakanMoodle, setDataTotalTalentMengerjakanMoodle] = useState(0);

  const fetcTotalTalentTidakLogin = (filter) => {
    getTotalTalentTidakLogin(filter)
      .then(
        (response) => {
          switch (response.data.status_code) {
            case "TAL200":
              setDataTotalTalentTidakLogin(response.data.message.total);
              break;
            default:
              dispatch(showErrorAlert("Terjadi kesalahan pada server!"));
              break;
          }
        },
        (error) => {
          console.log("error :", error);
        }
      )
      .finally((info) => {});
  };

  const fetcTotalTalentKelasArutala = (filter) => {
    getTotalTalentKelasArutala(filter)
      .then(
        (response) => {
          switch (response.data.status_code) {
            case "TAL200":
              setDataTotalTalentKelasArutala(response.data.message.total);
              break;
            default:
              dispatch(showErrorAlert("Terjadi kesalahan pada server!"));
              break;
          }
        },
        (error) => {
          console.log("error :", error);
        }
      )
      .finally((info) => {});
  };

  const fetcTotalTalentLolosKelasTeknologi = (filter) => {
    getTotalTalentLolosKelasTeknologi(filter)
      .then(
        (response) => {
          switch (response.data.status_code) {
            case "TAL200":
              setDataTotalTalentLolosKelasTeknologi(response.data.message.total);
              break;
            default:
              dispatch(showErrorAlert("Terjadi kesalahan pada server!"));
              break;
          }
        },
        (error) => {
          console.log("error :", error);
        }
      )
      .finally((info) => {});
  };

  const fetcTotalTalentKolamBesar = (filter) => {
    getTotalTalentKolamBesar(filter)
      .then(
        (response) => {
          switch (response.data.status_code) {
            case "TAL200":
              setDataTotalTalentKolamBesar(response.data.message.total);
              break;
            default:
              dispatch(showErrorAlert("Terjadi kesalahan pada server!"));
              break;
          }
        },
        (error) => {
          console.log("error :", error);
        }
      )
      .finally((info) => {});
  };

  const fetcTotalTalentMengerjakanMoodle = (filter) => {
    getTotalTalentMengerjakanMoodle(filter)
      .then(
        (response) => {
          switch (response.data.status_code) {
            case "TAL200":
              setDataTotalTalentMengerjakanMoodle(response.data.message.total);
              break;
            default:
              dispatch(showErrorAlert("Terjadi kesalahan pada server!"));
              break;
          }
        },
        (error) => {
          console.log("error :", error);
        }
      )
      .finally((info) => {});
  };

  useEffect(() => {
    fetcTotalTalentTidakLogin();
    fetcTotalTalentKelasArutala();
    fetcTotalTalentLolosKelasTeknologi();
    fetcTotalTalentKolamBesar();
    fetcTotalTalentMengerjakanMoodle();
  }, [dispatch]);

  const linkToTalentBulanIni = () => {
    navigate("/trainer/master-talent  ");
  };

  const totalTalentTidakLogin = () => {
    return (
      <div className="py-2">
        <Card className="h-100 w-100 flex justify-content-center px-3 py-3"
        style={{ cursor: "pointer" }}
        >
              <strong className="fsz-075" style={{ fontSize: 12, color: "#000", fontWeight: 700 }}>
                Talent Tidak Login &gt;3 Hari
              </strong>
              <hr />
              <span style={{ fontSize: 12, color: "#8D94A0" }}>
              <strong
                style={{
                    fontSize: 20,
                    fontWeight: 700,
                    color: "#000",
                    marginRight: 5,
                  }}>
                    {dataTotalTalentTidakLogin}
            </strong>
                Talent
            </span>
              <strong
                style={{
                  fontSize: 12,
                  textDecorationLine: "underline",
                  color: "#0078D7",
                  cursor: "pointer",
                }}
                onClick={() => navigate("/trainer/riwayat-login")}
              >
                Lihat Detail
              </strong>
        </Card>
      </div>
    );
  };

  const totalTalentKelasArutala = () => {
    return (
      <div className="py-2">
        <Card
          className="h-100 w-100 flex justify-content-center px-3 py-3"
          style={{ cursor: "pointer" }}
        >
          <strong className="fsz-075" style={{ fontSize: 12, color: "#000", fontWeight: 700 }}>Talent Masuk Kelas Arutala</strong>
          <hr />
          <span style={{ fontSize: 12, color: "#8D94A0" }}>
            <strong
              style={{
                fontSize: 20,
                fontWeight: 700,
                color: "#000",
                marginRight: 5,
              }}
            >
              {dataTotalTalentKelasArutala}
            </strong>
            Talent
          </span>
          <strong
            style={{
              fontSize: 12,
              textDecorationLine: "underline",
              color: "#0078D7",
              cursor: "pointer",
            }}
            onClick={linkToTalentBulanIni}
          >
            Lihat Detail
          </strong>
        </Card>
      </div>
    );
  };

  const totalTalentLolosKelasTeknologi = () => {
    return (
      <div className="py-2">
        <Card
          className="h-100 w-100 flex justify-content-center px-3 py-3"
          style={{ cursor: "pointer" }}
        >
          <strong className="fsz-075" style={{ fontSize: 12, color: "#000", fontWeight: 700 }}>Talent Lolos Kelas Teknologi</strong>
          <hr />
          <span style={{ fontSize: 12, color: "#8D94A0" }}>
            <strong
              style={{
                fontSize: 20,
                fontWeight: 600,
                color: "#000",
                marginRight: 5,
              }}
            >
              {dataTotalTalentLolosKelasTeknologi}
            </strong>
            Talent
          </span>
          <strong
            style={{
              fontSize: 12,
              textDecorationLine: "underline",
              color: "#0078D7",
              cursor: "pointer",
            }}
            onClick={linkToTalentBulanIni}
          >
            Lihat Detail
          </strong>
        </Card>
      </div>
    );
  };

  const totalTalentKolamBesar = () => {
    return (
      <div className="py-2">
        <Card
          className="h-100 w-100 flex justify-content-center px-3 py-3"
          style={{ cursor: "pointer" }}
        >
          <strong className="fsz-075" style={{ fontSize: 12, color: "#000", fontWeight: 700 }}>Talent Kolam Besar</strong>
          <hr />
          <span style={{ fontSize: 12, color: "#8D94A0" }}>
            <strong
              style={{
                fontSize: 20,
                fontWeight: 600,
                color: "#000",
                marginRight: 5,
              }}
            >
              {dataTotalTalentKolamBesar}
            </strong>
            Talent
          </span>
          <strong
            style={{
              fontSize: 12,
              textDecorationLine: "underline",
              color: "#0078D7",
              cursor: "pointer",
            }}
            onClick={linkToTalentBulanIni}
          >
            Lihat Detail
          </strong>
        </Card>
      </div>
    );
  };

  const totalTalentMengerjakanMoodle = () => {
    return (
      <div className="py-2">
        <Card
          className="h-100 w-100 flex justify-content-center px-3 py-3"
          style={{ cursor: "pointer" }}
        >
          <strong className="fsz-075" style={{ fontSize: 12, color: "#000", fontWeight: 700 }}>Talent Mengerjakan Moodle</strong>
          <hr />
          <span style={{ fontSize: 12, color: "#8D94A0" }}>
            <strong
              style={{
                fontSize: 20,
                fontWeight: 600,
                color: "#000",
                marginRight: 5,
              }}
            >
              {dataTotalTalentMengerjakanMoodle}
            </strong>
            Talent
          </span>
          <strong
            style={{
              fontSize: 12,
              textDecorationLine: "underline",
              color: "#0078D7",
              cursor: "pointer",
            }}
            onClick={linkToTalentBulanIni}
          >
            Lihat Detail
          </strong>
        </Card>
      </div>
    );
  };

  return(
    // <Container fluid className="py-2">
        <Row className="h-20 py-2">
            <Col>{totalTalentKolamBesar()}</Col>
            <Col>{totalTalentKelasArutala()}</Col>
            <Col>{totalTalentMengerjakanMoodle()}</Col>
            <Col>{totalTalentLolosKelasTeknologi()}</Col>
            <Col>{totalTalentTidakLogin()}</Col>
        </Row>
    // </Container>
  )
}
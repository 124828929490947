import React, { useEffect, useState } from 'react';
import './Bab.css';
import { Col, Form, InputGroup, Modal, Row } from 'react-bootstrap';

export default function PopupDetil({ show, setShow, babData }) {
    const [id, setId] = useState();
    const [nama, setNama] = useState();
    const [judulTopik, setJudulTopik] = useState();
    const [idTopik, setIdTopik] = useState();
    const [skalaNilai, setSkalaNilai] = useState();
    const [visibilitas, setVisibilitas] = useState();
    const [tag, setTag] = useState();
    const [deskripsi, setDeskripsi] = useState();

    useEffect(() => {
        setId(babData?.id || '');
        setNama(babData?.nama || '');
        setJudulTopik(babData?.judulTopik || '');
        setIdTopik(babData?.idTopik || '');
        setSkalaNilai(babData?.skalaNilai || '');
        setVisibilitas(babData?.visibilitas || 'sembunyi');
        setTag(babData?.tag || '');
        setDeskripsi(babData?.deskripsi || '');
    }, [show, babData]);

    const namaBabField = <Col>
        <Form.Group>
            <Form.Label>Nama Bab</Form.Label>
            <InputGroup>
                <Form.Label className='fsz-125'><b>{nama}</b></Form.Label>
            </InputGroup>
        </Form.Group>
    </Col>

    const firstRowForm = <Row className='mx-0 mb-3'>
        {namaBabField}
    </Row>

    const judulTopikField = <Col>
        <Form.Group>
            <Form.Label>Judul/Topik Materi</Form.Label>
            <div class='fsz-125-image'>
                <Form.Label className='fsz-125'><b>{judulTopik}</b></Form.Label>
            </div>
        </Form.Group>
    </Col>

    const secondRowForm = <Row className='mx-0 mb-3'>
        {judulTopikField}
    </Row>

    const deskripsiField = <Col>
        <Form.Group>
            <Form.Label>Deskripsi</Form.Label>
            <InputGroup>
                <Form.Label className='fsz-125'><b>{deskripsi}</b></Form.Label>
            </InputGroup>
        </Form.Group>
    </Col>

    const thirdRowForm = <Row className='mx-0 mb-3'>
        {deskripsiField}
    </Row>

    const tagField = <Col>
        <Form.Group>
            <Form.Label>Tag</Form.Label>
            <InputGroup>
                <Form.Label className='fsz-125'><b>{tag}</b></Form.Label>
            </InputGroup>
        </Form.Group>
    </Col>

    const fourthRowForm = <Row className='mx-0 mb-3'>
        {tagField}
    </Row>

    const skalaNilaiField = <Col>
        <Form.Group>
            <Form.Label>Skala Nilai</Form.Label>
            <InputGroup>
                <Form.Label className='fsz-125'><b>{skalaNilai}</b></Form.Label>
            </InputGroup>
        </Form.Group>
    </Col>

    const visibilitasField = <Col>
        <Form.Group>
            <Form.Label>Visibilitas</Form.Label>
            <InputGroup>
                <Form.Label className='fsz-125'><b>{visibilitas}</b></Form.Label>
            </InputGroup>
        </Form.Group>
    </Col>

    const fifthRowForm = <Row className='mx-0 mb-3'>
        {skalaNilaiField}
        {visibilitasField}
    </Row>

    return (
        <Modal show={show} onHide={() => setShow(prev => !prev)} centered size='lg'>
            <Modal.Header closeButton>
                <Modal.Title>Detail Bab</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    {firstRowForm}
                    {secondRowForm}
                    {thirdRowForm}
                    {fourthRowForm}
                    {fifthRowForm}
                </Form>
            </Modal.Body>
        </Modal>
    );
}

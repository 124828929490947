import { Button } from "react-bootstrap";
import moment from "moment";

export const columns = (editFunction, deleteFunction) => [
  {
    Header: "Nama Talent",
    accessor: "nama",
  },
  {
    Header: "Email",
    accessor: "email",
  },
  {
    Header: "Nomor Telepon",
    accessor: "nomor_telp",
  },
  {
    Header: "Jenis Kelamin",
    accessor: "jenis_kelamin",
  },
  {
    Header: "Tanggal Join",
    accessor: "tanggal_terdaftar",
    Cell: (row) => {
      return (
        <span>
          {moment(row.row.original.tanggal_terdaftar).format("DD MMMM YYYY, hh:mm")}
        </span>
      );
    },
  },
  {
    Header: "Asal Lembaga",
    accessor: "nama_lembaga",
  },
  {
    Header: "Tipe Lembaga",
    accessor: "tipe_lembaga",
  },
  // {
  //   Header: "Aksi",
  //   accessor: "aksi",
  //   disableSortBy: true,
  //   disableFilters: true,
  //   Cell: (row) => {
  //     return (
  //       <>
  //         <Button onClick={(e) => editFunction(row.row.original)}>
  //           Kirim Notifikasi
  //         </Button>
  //       </>
  //     );
  //   },
  // },
];

import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { Container, Row, Col, Button, Tabs, Tab, ListGroup, Image, Stack, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { GetParentPath } from '../../../common';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import MomentLocaleUtils from 'react-day-picker/moment';
import moment from 'moment';
import 'moment/locale/id';
import uuid from 'react-uuid';
import { showErrorAlert } from '../../../app';
import DatePicker from 'react-datepicker'; import 'react-datepicker/dist/react-datepicker.css';
import { listLeaderboardsAll, listLeaderboardsWeekly, listLeaderboardsMonthly } from '../../service/LeaderboardsService';
import { useDispatch } from 'react-redux';
import { default as PopupDetil } from './PopupDetil';

export default function Leaderboards() {
    moment.locale('en');
    const dispatch = useDispatch();
    const parentPath = GetParentPath();
    const [hoverRange, setHoverRange] = useState(undefined);
    const [selectedDays, setSelectedDays] = useState([]);
    const daysAreSelected = selectedDays.length > 0;
    const [monthDate, setMonthDate] = useState();
    const [allRankingList, setAllRankingList] = useState([]);
    const [weeklyRankingList, setWeeklyRankingList] = useState([]);
    const [monthlyRankingList, setMonthlyRankingList] = useState([]);
    const [showPopup, setShowPopup] = useState(false);
    const [userData, setUserData] = useState();
    const profilePhoto = '/img/avatar.png';

    function getUserId() {
        var jwt = localStorage.getItem('json-web-token');
        var decodedJwt = JSON.parse(window.atob(jwt.split('.')[1]));
        return decodedJwt.account_id;
    }

    useEffect(() => {
        var now = moment().toDate();
        setSelectedDays(getWeekDays(getWeekRange(now).from));
        setMonthDate(now);
        listLeaderboardsAll().then(
            (response) => {
                switch (response.data.status_code) {
                    case 'LDB200':
                        setAllRankingList(response.data.message.leaderboards || []);
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('Ranking Leaderboards All error :', error);
            })
        setRankingWeekly();
        setRankingMonthly();
    }, [])

    useEffect(() => {
        setRankingWeekly();
    }, [selectedDays])

    useEffect(() => {
        setRankingMonthly();
    }, [monthDate])

    function setRankingWeekly() {
        var weekly = moment(selectedDays[1]).format('YYYY-WW');
        listLeaderboardsWeekly({ weekly }).then(
            (response) => {
                switch (response.data.status_code) {
                    case 'LDB200':
                        setWeeklyRankingList(response.data.message.leaderboards || []);
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('Ranking Leaderboards Weekly error :', error);
            })
    }

    function setRankingMonthly() {
        var monthly = moment(monthDate).format('YYYY-MM');
        listLeaderboardsMonthly({ monthly }).then(
            (response) => {
                switch (response.data.status_code) {
                    case 'LDB200':
                        setMonthlyRankingList(response.data.message.leaderboards || []);
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('Ranking Leaderboards Monthly error :', error);
            })
    }

    const getWeekDays = (weekStart) => {
        const days = [weekStart];
        for (let i = 1; i < 7; i += 1) {
            days.push(
                moment(weekStart)
                    .add(i, 'days')
                    .toDate()
            );
        }
        return days;
    }

    const getWeekRange = (date) => {
        return {
            from: moment(date)
                .startOf('week')
                .toDate(),
            to: moment(date)
                .endOf('week')
                .toDate(),
        };
    }

    const handleDayChange = (date) => {
        setSelectedDays(getWeekDays(getWeekRange(date).from))
    };

    const handleMonthPicker = (date) => {
        setMonthDate(date);
    }

    const handleRowClick = (data) => {
        setUserData(data);
        setShowPopup(prev => !prev);
    }

    const modifiers = {
        hoverRange,
        selectedRange: daysAreSelected && {
            from: selectedDays[0],
            to: selectedDays[6],
        },
        hoverRangeStart: hoverRange && hoverRange.from,
        hoverRangeEnd: hoverRange && hoverRange.to,
        selectedRangeStart: daysAreSelected && selectedDays[0],
        selectedRangeEnd: daysAreSelected && selectedDays[6],
    };

    function DisabledPicker() {
        return (<div className='border rounded leaderboards-picker leaderboards-disabledpicker p-1 my-3 mx-auto'>
            <Dropdown>
                <div className='row'>
                    <Dropdown.Toggle disabled className='text-dark d-flex justify-content-between'>
                        Pilih Tanggal
                        <FontAwesomeIcon icon={faCalendar} />
                    </Dropdown.Toggle>
                </div>
            </Dropdown>
        </div>
        )
    };

    function WeekPicker() {
        return (<div className='border rounded leaderboards-picker leaderboards-weekpicker p-1 my-3 mx-auto'>
            <Dropdown>
                <div className='row mx-0'>
                    <Dropdown.Toggle className='text-dark d-flex justify-content-between'>
                        {selectedDays[0] ? moment(selectedDays[1]).format('YYYY-Wo') : 'Pilih Tanggal'}
                        <FontAwesomeIcon icon={faCalendar} />
                    </Dropdown.Toggle>
                </div>

                <Dropdown.Menu className='border' align='end'>
                    <DayPicker
                        localeUtils={MomentLocaleUtils}
                        locale={'id'}
                        selectedDays={selectedDays}
                        showOutsideDays
                        modifiers={modifiers}
                        onDayClick={(date) => handleDayChange(date)}
                    />
                </Dropdown.Menu>
            </Dropdown>
        </div>
        )
    };

    const MonthPickerCustomInput = forwardRef(({ value, onClick }, ref) => (
        <div className='py-1 month-picker-custom-input d-flex align-items-center justify-content-between' onClick={onClick} ref={ref}>
            {value} <FontAwesomeIcon icon={faCalendar} />
        </div>
    ));

    function MonthPicker() {
        return (<div className='border rounded leaderboards-picker leaderboards-monthpicker px-2 py-1 my-3 mx-auto'>
            <Row className='p-0'>
                <Col className='m-0 '>
                    <DatePicker showMonthYearPicker selected={monthDate}
                        onChange={(date) => handleMonthPicker(date)}
                        dateFormat='yyyy - MM' placeholderText='Pilih Tanggal'
                        customInput={<MonthPickerCustomInput />}
                    />
                </Col>
            </Row>
        </div>
        )
    };

    function RankingItemComponent({ ranking }) {
        if (!Array.isArray(ranking)) return <></>;
        var topten = [], userId = getUserId();
        topten = ranking?.slice();
        return topten.map((item, index) => (
            <ListGroup.Item key={index}
                className={`border-0 rounded ${(item.idUser === userId) ? `user-row` : ``}`}
                onClick={() => handleRowClick(item)}
            >
                <Row>
                    <Col lg='1' className='d-flex justify-content-between'>
                        <span className={`rounded-circle rank-number rank-number-${item.ranking}`}>
                            {item.ranking}
                        </span>
                        <Image src={profilePhoto} width='25px' />
                    </Col>
                    <Col lg='9'>{item.name}</Col>
                    <Col lg='2' className='d-flex justify-content-between'>
                        <Image src='/img/xp.png' />{item.xp}
                    </Col>
                </Row>
            </ListGroup.Item>
        ))
    };

    function Ranking({ tabkey }) {
        return (
            <Stack className='bg-white' key={uuid()}>
                {tabkey === 'all-time' && <>
                    <DisabledPicker />
                    <ListGroup>
                        <RankingItemComponent ranking={allRankingList} />
                    </ListGroup>
                </>
                }
                {tabkey === 'weekly' && <>
                    <WeekPicker />
                    <ListGroup>
                        <RankingItemComponent ranking={weeklyRankingList} />
                    </ListGroup>
                </>
                }
                {tabkey === 'monthly' && <>
                    <MonthPicker />
                    <ListGroup>
                        <RankingItemComponent ranking={monthlyRankingList} />
                    </ListGroup>
                </>
                }
            </Stack>
        );
    };

    return (
        <>
            <PopupDetil show={showPopup} setShow={setShowPopup} data={userData} />
            <Container id='leaderboard-container' className='pt-5'>
                <Row>
                    <Col>
                        <Link to={`${parentPath}/topik`}>
                            <Button variant='light'
                                className='back-bab-button bg-transparent border-0 p-0 fsz-1125 mb-2'
                            >
                                <AiOutlineArrowLeft className='me-2' style={{ color: '#757575' }} />
                                <span style={{ marginLeft: '1.5rem', color: '#081F32' }}>Kembali</span>
                            </Button>
                        </Link>
                    </Col>
                </Row>
                <Row>
                    <Col className='my-2'>
                        <b><h3>Leaderboards</h3></b>
                    </Col>
                </Row>
                <Row>
                    <Col className='py-2 rounded bg-white'>
                        <Tabs defaultActiveKey='all-time' id='ranking-tab'>
                            <Tab eventKey='all-time' title='All Time'>
                                <Ranking tabkey={'all-time'} />
                            </Tab>
                            <Tab eventKey='weekly' title='Mingguan'>
                                <Ranking tabkey={'weekly'} />
                            </Tab>
                            <Tab eventKey='monthly' title='Bulanan'>
                                <Ranking tabkey={'monthly'} />
                            </Tab>
                        </Tabs>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

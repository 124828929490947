import { useRef, useState } from 'react'
import Benefit from '../landing/components/Benefit/Benefit'
import Faqs from '../landing/components/Faqs/Faqs'
import Footer from '../landing/components/Footer/Footer'
import Goals from '../landing/components/Goals/Goals'
import HeroJumbotron from '../landing/components/HeroJumbotron/HeroJumbotron'
import LearningMaterials from '../landing/components/LearningMaterials/LearningMaterials'
import Navbar from '../landing/components/Navbar/Navbar'
import Registration from '../landing/components/Registration/Registration'
import TheMentors from '../landing/components/TheMentors/TheMentors'
import TimePrediction from '../landing/components/TimePrediction/TimePrediction'
import './Landing.css'
import WhatsappButton from './components/WhatsappButton/WhatsappButton'

const Desktop = () => {
  const [refContainer] = useState([
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ])

  const scrollToContainer = (containerId) => {
    refContainer[containerId]?.current?.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <div className="landing-page bg-white">
      <Navbar scrollToContainer={scrollToContainer} />
      <HeroJumbotron />
      <Goals refContainer={refContainer[0]} />
      <LearningMaterials />
      <Benefit refContainer={refContainer[1]} />
      <WhatsappButton />
      <TimePrediction refContainer={refContainer[2]} />
      <TheMentors refContainer={refContainer[3]} />
      <Registration />
      <Faqs />
      <Footer />
    </div>
  )
}

export default Desktop

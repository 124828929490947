import './Footer.css'
import logoarutalab from '../../assets/logoarutalab.png';
import logotujuhsembilan from '../../assets/logotujuhsembilan.png';
import frame75 from "../../assets/frame-75.svg"
import frame74 from "../../assets/frame-74.svg"
import instagram from "../../assets/instragarm.svg"
import linkedin from "../../assets/linkedin.svg"
import { Link } from 'react-scroll';
const Footer = () => {
  return (
    <footer className="footer container-fluid">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-5 mb-4 mb-md-0">
            <div className="">
              <div className="d-flex flex-column">
                <div className="d-flex">
                <div className="logo">
                  <div className="logo1">
                    <img className="imageIcon" alt="" src={logoarutalab} />
                  </div>
                  <div className="logoChild" />
                  <div className="logo2">
                    <img className="image21Icon" alt="" src={logotujuhsembilan} />
                  </div>
                </div>
                </div>
                <div className="slogan-space">
                  FUNTASTIC 79 - Found the New Talent's Spirit <br /> for
                  Technology Information Camp 79
                </div>
              </div>
              <div className="d-flex flex-row box-container">
                <a href="https://www.facebook.com/padepokantujuhsembilan/" className="box-socmed">
                  <img className="" alt="" src={frame75} />
                </a>
                <a href="https://www.instagram.com/padepokantujuhsembilan/" className="box-socmed">
                  <img className="" alt="" src={instagram} />
                </a>
                <a href="https://www.linkedin.com/company/padepokantujuhsembilan/" className="box-socmed">
                  <img className="" alt="" src={linkedin} />
                </a>
                <a href="https://www.youtube.com/@tujuhsembilantv5555" className="box-socmed">
                  <img className="" alt="" src={frame74} />
                </a>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-7">
            <div className="row">
              <div className="col">
                <div className="footer-child-title">Quick Links</div>
                <ul className="footer-link">
                  <li>
                    <Link activeClass="active" to="0" spy={true} smooth={true} offset={-45} duration={500}>
                      Goals
                    </Link>
                  </li>
                  <li>
                    <Link activeClass="active" to="1" spy={true} smooth={true} offset={-45} duration={500}>
                      Benefit
                    </Link>
                  </li>
                  <li>
                    <Link activeClass="active" to="2" spy={true} smooth={true} offset={-45} duration={500}>
                      Time Prediction
                    </Link>
                  </li>
                  <li>
                    <Link activeClass="active" to="3" spy={true} smooth={true} offset={-25} duration={500}>
                      The Mentors
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="col">
                <div className="footer-child-title">Materi</div>
                <ul className="footer-link">
                  <li>
                    Java
                  </li>
                  <li>
                    GIT
                  </li>
                  <li>
                    OOP
                  </li>
                  <li>
                    SQL
                  </li>
                </ul>
              </div>
              <div className="col">
                <div className="footer-child-title">Kelas</div>
                <ul className="footer-link">
                  <li>
                    Umum
                  </li>
                  <li>
                    Arutala
                  </li>
                  <li>
                    Teknologi
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer

import {
    FontAwesomeIcon
} from "@fortawesome/react-fontawesome";

import {
    faEye, faEyeSlash,
    faEdit, faTrash
} from '@fortawesome/free-solid-svg-icons';
import { Container, Image, Stack } from "react-bootstrap";

export const columns = (editFunction, deleteFunction) => ([
    {
        Header: "No",
        accessor: "nomor",
        Cell: (row) => {
            return <div>{Number(row.row.id) + 1}</div>;
        },
        disableFilters: true,
        disableSortBy: true,
    },
    {
        Header: 'Judul Video',
        accessor: 'judul',
        Cell: (row) => {
            return (
                <Stack direction='horizontal'>
                    <Container style={{ height: '3rem', width: '4.5rem' }}
                        className='p-0 m-0 me-3 d-flex align-items-center justify-content-center'
                    >
                        <div><Image src={row.row.original.thumbnail}
                            style={{
                                height: 'auto', width: 'auto',
                                maxHeight: '3rem', maxWidth: '4.5rem',
                            }}
                        /></div>
                    </Container>
                    <>{row.row.original.judul}</>
                </Stack>
            );
        },
    },
    {
        Header: 'SubBab',
        accessor: 'namaSubbab',
    },
    {
        Header: 'Bab',
        accessor: 'namaBab',
    },
    {
        Header: 'Judul/Topik',
        accessor: 'judulTopik',
    },
    {
        Header: 'Tag',
        accessor: 'tag',
    },
    {
        Header: 'Visibilitas',
        accessor: 'visibilitas',
        Cell: ({ cell: { value } }) => {
            if (value === 'publik') return <div><FontAwesomeIcon icon={faEye} size='xs' /> Publik</div>;
            else return <div><FontAwesomeIcon icon={faEyeSlash} size='xs' /> Sembunyi</div>;
        },
    },
    {
        Header: 'Aksi',
        accessor: 'aksi',
        disableSortBy: true,
        disableFilters: true,
        Cell: (row) => {
            return (
                <>
                    <FontAwesomeIcon icon={faEdit} color="#ffc107" className='me-2'
                        onClick={(e) => editFunction(row.row.original)}
                        style={{ cursor: 'pointer' }}
                    />
                    <FontAwesomeIcon icon={faTrash} color="#dc3545"
                        onClick={(e) => deleteFunction(row.row.original)}
                        style={{ cursor: 'pointer' }}
                    />
                </>
            );
        },
    },
]);

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';

export const columns = [
    {
        Header: "No",
        accessor: "nomor",
        Cell: (row) => {
            return <div>{Number(row.row.id) + 1}</div>;
        },
        disableFilters: true,
        disableSortBy: true,
    },
    {
        Header: 'Nama Talent',
        accessor: 'namaTalent',
    },
    {
        Header: 'Jumlah Video Ditonton',
        accessor: 'jumlahVideoDitonton',
    },
    {
        Header: 'Jumlah Kuis Dikerjakan',
        accessor: 'jumlahKuisAkhirSelesai',
    },
    {
        Header: 'Jumlah Latihan Soal Dikerjakan',
        accessor: 'jumlahKuisBabSelesai',
    },
    {
        Header: 'Jumlah Latihan Praktik Dikerjakan',
        accessor: 'jumlahLatihanPraktikSelesai',
    },
    {
        Header: 'Jumlah Ujian Praktik Dikerjakan',
        accessor: 'jumlahUjianPraktikSelesai',
    },
];


// {
//     Header: 'Jumlah Video Ditonton',
//     accessor: 'jumlahVideoDitonton',
// },
// {
//     Header: 'Jumlah Kuis Dikerjakan',
//     accessor: 'jumlahKuisAkhirSelesai',
// },
// {
//     Header: 'Jumlah Latihan Soal Dikerjakan',
//     accessor: 'jumlahKuisBabSelesai',
// },
// {
//     Header: 'Jumlah Ujian Dikerjakan',
//     accessor: 'jumlahCekKemampuanSelesai',
// },
// {
//     Header: 'Jumlah Latihan Praktik Dikerjakan',
//     accessor: 'jumlahLatihanPraktikSelesai',
// },
// {
//     Header: 'Jumlah Ujian Praktik Dikerjakan',
//     accessor: 'jumlahUjianPraktikSelesai',
// },
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Image } from "react-bootstrap";

export const video = [
    {
        Header: 'Judul Video',
        accessor: 'judul',
        Cell: (row) => {
            return (
                <div className="kuism-thumbnail-cell">
                    <div className='kuism-thumbnail-image me-2'>
                        <Image fluid src={row.row.original.thumbnail} alt="thumbnail"/>
                        </div>
                    {row.row.original.judul}
                </div>
            );
        }
    },
    {
        Header: 'Judul/Topik',
        accessor: 'judulTopik',
    },
    {
        Header: 'Tanggal Ditonton',
        accessor: 'tanggalRaw',
        Cell: (row) => {
            return (
                <>
                    {row.row.original.tanggal}
                </>
            );
        }
    }
];

export const kuis = [
    {
        Header: 'Judul Kuis',
        accessor: 'judul',
    },
    {
        Header: 'Judul/Topik',
        accessor: 'judulTopik',
    },
    {
        Header: 'Tanggal Dikerjakan',
        accessor: 'tanggalRaw',
        Cell: (row) => {
            return (
                <>
                    {row.row.original.tanggal}
                </>
            );
        }
    }
];

export const latihansoal = [
    {
        Header: 'Judul Kuis',
        accessor: 'judul',
    },
    {
        Header: 'Judul/Topik',
        accessor: 'judulTopik',
    },
    {
        Header: 'Tanggal Dikerjakan',
        accessor: 'tanggalRaw',
        Cell: (row) => {
            return (
                <>
                    {row.row.original.tanggal}
                </>
            );
        }
    }
];

export const ujian = [
    {
        Header: 'Judul Kuis',
        accessor: 'judul',
    },
    {
        Header: 'Judul/Topik',
        accessor: 'judulTopik',
    },
    {
        Header: 'Tanggal Dikerjakan',
        accessor: 'tanggalRaw',
        Cell: (row) => {
            return (
                <>
                    {row.row.original.tanggal}
                </>
            );
        }
    }
];

export const latihanpraktik = [
    {
        Header: 'Judul Kuis',
        accessor: 'judul',
    },
    {
        Header: 'Judul/Topik',
        accessor: 'judulTopik',
    },
    {
        Header: 'Tanggal Dikerjakan',
        accessor: 'tanggalRaw',
        Cell: (row) => {
            return (
                <>
                    {row.row.original.tanggal}
                </>
            );
        }
    }
];

export const ujianpraktik = [
    {
        Header: 'Judul Kuis',
        accessor: 'judul',
    },
    {
        Header: 'Judul/Topik',
        accessor: 'judulTopik',
    },
    {
        Header: 'Tanggal Dikerjakan',
        accessor: 'tanggalRaw',
        Cell: (row) => {
            return (
                <>
                    {row.row.original.tanggal}
                </>
            );
        }
    }
];
// cloud 79
export const base_url_auth = "https://digitalbootcamp-auth.tujuhsembilan.com";
export const base_url_learning = "https://digitalbootcamp-learn.tujuhsembilan.com";
export const base_url_minio =
  "https://storage.cloudias79.com:9000/digitalbootcamp-dev";
export const base_url_gcs =
  "https://storage.googleapis.com/dgt-bootcamp-sorage-prod";
export const base_url_compile =
  "https://digitalbootcamp-compile.tujuhsembilan.com";

// localhost
export const local_base_url_auth = "http://localhost:8881";
export const local_base_url_learning = "http://localhost:8882";

// gcs
export const gcs_endpoint = `${base_url_learning}/learning/api/gcs/image-upload`;

// minio
export const minio_endpoint = `${base_url_learning}/learning/api/image-upload`;

// trainer auth
export const trainer_auth_endpoint = `${base_url_auth}/auth/api/trainer/auth`;
export const trainer_forgot_password_endpoint = `${base_url_auth}/auth/api/trainer/forgot-password`;
export const trainer_reset_password_endpoint = `${base_url_auth}/auth/api/trainer/reset-password`;

// trainer learning
export const trainer_topik_endpoint = `${base_url_learning}/learning/api/trainer/topik`;
export const trainer_kuismateri_endpoint = `${base_url_learning}/learning/api/trainer/kuis-materi`;
export const trainer_kuisakhir_endpoint = `${base_url_learning}/learning/api/trainer/kuis-akhir`;
export const trainer_soalkuisakhir_endpoint = `${base_url_learning}/learning/api/trainer/soal-kuis-akhir`;
export const trainer_kuisbab_endpoint = `${base_url_learning}/learning/api/trainer/kuis-bab`;
export const trainer_kuiscekkemampuan_endpoint = `${base_url_learning}/learning/api/trainer/cek-kemampuan`;
export const trainer_soalkuiscekkemampuan_endpoint = `${base_url_learning}/learning/api/trainer/soal-cek-kemampuan`;
export const trainer_soalkuisbab_endpoint = `${base_url_learning}/learning/api/trainer/soal-kuis-bab`;
export const trainer_subbab_endpoint = `${base_url_learning}/learning/api/trainer/subbab`;
export const trainer_materi_endpoint = `${base_url_learning}/learning/api/trainer/materi`;
export const trainer_soalkuismateri_endpoint = `${base_url_learning}/learning/api/trainer/soal-kuis-materi`;
export const trainer_timeline_endpoint = `${base_url_learning}/learning/api/trainer/timeline`;
export const trainer_bab_endpoint = `${base_url_learning}/learning/api/trainer/bab`;
export const trainer_rangkuman_endpoint = `${base_url_learning}/learning/api/trainer/rangkuman`;
export const trainer_tag_endpoint = `${base_url_learning}/learning/api/trainer/tag`;
export const trainer_kelolaakun_endpoint = `${base_url_learning}/learning/api/trainer/kelola-akun`;
export const trainer_soalpraktik_endpoint = `${base_url_learning}/learning/api/trainer/soal-praktik`;
export const trainer_laporanbelajar_endpoint = `${base_url_learning}/learning/api/trainer/laporan-belajar`;
export const trainer_laporannilai_endpoint = `${base_url_learning}/learning/api/trainer/laporan-nilai`;
export const trainer_language_endpoint = `${base_url_compile}/languages`;
export const trainer_komentar_endpoint = `${base_url_learning}/learning/api/trainer/komentar`;
export const trainer_notifikasi_endpoint = `${base_url_learning}/learning/api/trainer/notifikasi`;
export const trainer_totaltalentjoin_endpoint = `${base_url_learning}/learning/api/dashboard/total-talent-join`;
export const trainer_totaltalentjointoday_endpoint = `${base_url_learning}/learning/api/dashboard/total-talent-join/today`;
export const trainer_statistikpendaftaran_endpoint = `${base_url_learning}/learning/api/dashboard/registrant-statistic`;
export const trainer_totaltalenttidaklogin_endpoint = `${base_url_learning}/learning/api/dashboard/total-talent-not-login`;
export const trainer_totaltalentarutalaclass_endpoint = `${base_url_learning}/learning/api/dashboard/total-talent-arutala-class`;
export const trainer_totaltalentpasstechnologyclass_endpoint = `${base_url_learning}/learning/api/dashboard/total-talent-pass-technology-class`;
export const trainer_totaltalentkolambesar_endpoint = `${base_url_learning}/learning/api/dashboard/total-talent-kolam-besar`;
export const trainer_totaltalentmengerjakanmoodle_endpoint = `${base_url_learning}/learning/api/dashboard/total-talent-mengerjakan-moodle`;
export const trainer_reportbelajar_endpoint = `${base_url_learning}/learning/api/dashboard/learning-report-talents`;
export const trainer_progressbelajar_endpoint = `${base_url_learning}/learning/api/dashboard/learning-progress-talents`;
export const trainer_groupinstitution_endpoint = `${base_url_learning}/learning/api/dashboard/total-talent-join/group/institution`;
export const trainer_riwayatloginall_endpoint = `${base_url_learning}/learning/api/dashboard/login-history/all`;
export const trainer_talentterdaftar_endpoint = `${base_url_learning}/learning/api/talents/registered`;
export const trainer_mastertalent_endpoint = `${base_url_learning}/learning/api/master/talents`;
export const trainer_masterlembaga_endpoint = `${base_url_learning}/learning/api/master/institutions`;
export const trainer_groupinstitutionpercentage_endpoint = `${base_url_learning}/learning/api/dashboard/total-talent-join/group/institution/percentage`;
export const trainer_whatsapp_learning_progress_endpoint = `${base_url_learning}/learning/api/whatsapp/send-learning-report-progress`;
export const trainer_whatsapp_learning_report_endpoint = `${base_url_learning}/learning/api/whatsapp/send-learning-report`;
export const trainer_whatsapp_learning_reminder_not_active_endpoint = `${base_url_learning}/learning/api/whatsapp/send-reminder-not-active`;
export const trainer_statusPeserta_endpoint = `${base_url_learning}/learning/api/trainer/status-peserta`;
export const trainer_allTalentInstitution_endpoint = `${base_url_learning}/learning/api/dashboard/total-talent/all/institution`;
export const trainer_allTalentCity_endpoint = `${base_url_learning}/learning/api/dashboard/total-talent/all/city`;
export const trainer_allTalentProvince_endpoint = `${base_url_learning}/learning/api/dashboard/total-talent/all/province`;
export const trainer_allProvince_endpoint = `${base_url_learning}/learning/api/trainer/provinsi-by-id`;
export const trainer_allCity_endpoint = `${base_url_learning}/learning/api/trainer/kab-kota`;

// talent auth
export const talent_auth_endpoint = `${base_url_auth}/auth/api/talent/auth`;
export const talent_forgotpass_endpoint = `${base_url_auth}/auth/api/talent/forgot-password`;
export const talent_resetpass_endpoint = `${base_url_auth}/auth/api/talent/reset-password`;
export const talent_auth_google_endpoint = `${base_url_auth}/auth/api/talent/login-with-google`;
export const talent_register_endpoint = `${base_url_auth}/auth/api/talent/register`;
export const talent_verif_endpoint = `${base_url_auth}/auth/api/talent/register/verify`;
export const talent_institution_endpoint = `${base_url_auth}/auth/api/institution`;

// talent learning
export const talent_topik_endpoint = `${base_url_learning}/learning/api/talent/topik`;
export const talent_bab_endpoint = `${base_url_learning}/learning/api/talent/bab`;
export const talent_subbab_endpoint = `${base_url_learning}/learning/api/talent/subbab`;
export const talent_profile_endpoint = `${base_url_learning}/learning/api/talent/profile`;
export const talent_password_endpoint = `${base_url_learning}/learning/api/talent/change-password`;
export const talent_catatan_endpoint = `${base_url_learning}/learning/api/talent/catatan`;
export const talent_materi_endpoint = `${base_url_learning}/learning/api/talent/materi`;
export const talent_submitmateri_endpoint = `${base_url_learning}/learning/api/talent/submit-materi`;
export const talent_timeline_endpoint = `${base_url_learning}/learning/api/talent/timeline`;
export const talent_rangkuman_endpoint = `${base_url_learning}/learning/api/talent/rangkuman`;
export const talent_download_endpoint = `${base_url_learning}/learning/api/talent/download`;
export const talent_screenshot_endpoint = `${base_url_learning}/learning/api/talent/screenshot`;
export const talent_komentar_endpoint = `${base_url_learning}/learning/api/talent/komentar`;
export const talent_materiterakhir_endpoint = `${base_url_learning}/learning/api/talent/terakhir-dipelajari`;
export const talent_soalpraktik_endpoint = `${base_url_learning}/learning/api/talent/soal-praktik`;

// talent learning (kuis bab)
export const talent_kuisbab_endpoint = `${base_url_learning}/learning/api/talent/kuis-bab`;
export const talent_soalkuisbab_endpoint = `${base_url_learning}/learning/api/talent/soal-kuis-bab`;
export const talent_laporsoalkuisbab_endpoint = `${base_url_learning}/learning/api/talent/lapor-soal-kuis-bab`;
export const talent_submitkuisbab_endpoint = `${base_url_learning}/learning/api/talent/submit-kuis-bab`;
export const talent_riwayatkuisbab_endpoint = `${base_url_learning}/learning/api/talent/riwayat-kuis-bab`;

// talent learning (kuis akhir)
export const talent_kuisakhir_endpoint = `${base_url_learning}/learning/api/talent/kuis-akhir`;
export const talent_soalkuisakhir_endpoint = `${base_url_learning}/learning/api/talent/soal-kuis-akhir`;
export const talent_laporsoalkuisakhir_endpoint = `${base_url_learning}/learning/api/talent/lapor-soal-kuis-akhir`;
export const talent_submitkuisakhir_endpoint = `${base_url_learning}/learning/api/talent/submit-kuis-akhir`;
export const talent_riwayatkuisakhir_endpoint = `${base_url_learning}/learning/api/talent/riwayat-kuis-akhir`;

// talent learning (kuis materi)
export const talent_kuismateri_endpoint = `${base_url_learning}/learning/api/talent/kuis-materi`;
export const talent_soalkuismateri_endpoint = `${base_url_learning}/learning/api/talent/soal-kuis-materi`;
export const talent_laporsoalkuismateri_endpoint = `${base_url_learning}/learning/api/talent/lapor-soal-kuis-materi`;
export const talent_submitkuismateri_endpoint = `${base_url_learning}/learning/api/talent/submit-kuis-materi`;
export const talent_leaderboardsall_endpoint = `${base_url_learning}/learning/api/talent/leaderboards-all`;
export const talent_leaderboardsweek_endpoint = `${base_url_learning}/learning/api/talent/leaderboards-week`;
export const talent_leaderboardsmonth_endpoint = `${base_url_learning}/learning/api/talent/leaderboards-month`;

// talent learning (cek kemampuan)
export const talent_cekkemampuan_endpoint = `${base_url_learning}/learning/api/talent/cek-kemampuan`;
export const talent_soalkcekkemampuan_endpoint = `${base_url_learning}/learning/api/talent/soal-cek-kemampuan`;
export const talent_laporsoalcekkemampuan_endpoint = `${base_url_learning}/learning/api/talent/lapor-soal-cek-kemampuan`;
export const talent_submitcekkemampuan_endpoint = `${base_url_learning}/learning/api/talent/submit-cek-kemampuan`;
export const talent_riwayatcekkemampuan_endpoint = `${base_url_learning}/learning/api/talent/riwayat-kuis-cek`;

// talent learning (laporan belajar)
export const talent_laporanbelajarsummary_endpoint = `${base_url_learning}/learning/api/talent/laporan-belajar-summary`;
export const talent_laporanbelajarkuisbab_endpoint = `${base_url_learning}/learning/api/talent/laporan-belajar-kuis-bab`;
export const talent_laporanbelajarcekkemampuan_endpoint = `${base_url_learning}/learning/api/talent/laporan-belajar-cek-kemampuan`;
export const talent_laporanbelajarlatihanpraktik_endpoint = `${base_url_learning}/learning/api/talent/laporan-belajar-latihan-praktik`;
export const talent_laporanbelajarujianpraktik_endpoint = `${base_url_learning}/learning/api/talent/laporan-belajar-ujian-praktik`;

import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Card,
  Table,
} from "react-bootstrap";
import { useDispatch } from "react-redux";
import { MdSend } from "react-icons/md";
import { hideErrorAlert, showErrorAlert } from "../../../app";
import {
  addKomentarTrainer,
  listKomentarTrainer,
} from "../../service/KomentarService";
import InfiniteScroll from "react-infinite-scroller";
import Statistik from "./Statistik";
import { TableDashboard } from "../../common/table";
import { columnsProgressBelajar } from "./columns";
import {
  getProgressBelajar,
  exportProgressBelajar,
} from "../../service/DashboardService";
import FileSaver from "file-saver";

export default function ProgressBelajar({}) {
  const dispatch = useDispatch();

  const [dataProgressBelajar, setDataProgressBelajar] = useState([]);
  const [filterSearch, setFilterSearch] = useState("");

  const fetchProgressBelajar = (val) => {
    setFilterSearch(val);
    getProgressBelajar({ keywordSearch: val })
      .then(
        (response) => {
          switch (response.data.status_code) {
            case "TAL200":
              setDataProgressBelajar(response.data.message || 0);
              break;
            default:
              dispatch(showErrorAlert("Terjadi kesalahan pada server!"));
              break;
          }
        },
        (error) => {
          console.log("Total Talent Join error :", error);
        }
      )
      .finally((info) => {});
  };

  const fetchExportProgressBelajar = () => {
    exportProgressBelajar({ keywordSearch: filterSearch })
      .then(
        (response) => {
          switch (response.status) {
            case 200:
              FileSaver.saveAs(response.data, "Progress Belajar Peserta.xlsx");
              break;
            default:
              dispatch(showErrorAlert("Terjadi kesalahan pada server!"));
              break;
          }
        },
        (error) => {
          console.log("Total Talent Join error :", error);
        }
      )
      .finally((info) => {});
  };

  useEffect(() => {
    fetchProgressBelajar();
  }, [dispatch]);

  return (
    <TableDashboard
      data={dataProgressBelajar}
      columns={columnsProgressBelajar()}
      showAddButton={false}
      showFilter={false}
      onSearchChange={(val) => fetchProgressBelajar(val)}
      title="Progress Belajar Peserta"
      urlNavigate="/trainer/progress-belajar"
      onExport={fetchExportProgressBelajar}
    />
  );
}

import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { showErrorAlert } from "../../../app";

// component
import { Table } from "../../common/table";
import { columns } from "./columns";
import { pushCrumb, popCrumb } from "../../common/breadcrumbs";
import FileSaver from "file-saver";

// service
import {
  getAllTalentTerdaftar,
  exportTalentTerdaftar,
} from "../../service/TalentTerdaftarService";

function TalentTerdaftar() {
  const dispatch = useDispatch();
  const [dataTalentTerdaftar, setDataTalentTerdaftar] = useState([]);
  const [filterSearch, setFilterSearch] = useState("");

  const fetchTalentTerdaftar = (val) => {
    setFilterSearch(val);
    getAllTalentTerdaftar({ keywordSearch: val })
      .then(
        (response) => {
          switch (response.data.status_code) {
            case "TAL200":
              setDataTalentTerdaftar(response.data.message || 0);
              break;
            default:
              dispatch(showErrorAlert("Terjadi kesalahan pada server!"));
              break;
          }
        },
        (error) => {
          console.log("Total Talent Join error :", error);
        }
      )
      .finally((info) => {});
  };

  const fetchExportTalentTerdaftar = () => {
    exportTalentTerdaftar({ keywordSearch: filterSearch })
      .then(
        (response) => {
          switch (response.status) {
            case 200:
              FileSaver.saveAs(response.data, "Talent Terdaftar.xlsx");
              break;
            default:
              dispatch(showErrorAlert("Terjadi kesalahan pada server!"));
              break;
          }
        },
        (error) => {
          console.log("Total Talent Join error :", error);
        }
      )
      .finally((info) => {});
  };

  useEffect(() => {
    fetchTalentTerdaftar();
  }, [dispatch]);

  return (
    <Table
      data={dataTalentTerdaftar}
      columns={columns()}
      showAddButton={false}
      showSearch={false}
      showFilter={false}
      showCustomize={false}
      customSearch={true}
      onSearchChange={(val) => fetchTalentTerdaftar(val)}
      onExport={fetchExportTalentTerdaftar}
      title="Talent Terdaftar"
    />
  );
}

export default TalentTerdaftar;

import React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation,
  Outlet,
} from "react-router-dom";
import { useSelector } from "react-redux";
import { ErrorAlert, SuccessAlert, Home } from "../trainer/common";
import { Home as HomeTalent } from "../talent/common";
import { Topik } from "../trainer/features/topik";
import { KuisAkhir } from "../trainer/features/kuis-akhir";
import { KuisMateri } from "../trainer/features/kuis-materi";
import { KelolaAkun } from "../trainer/features/kelola-akun";
import { KuisBab } from "../trainer/features/kuis-bab";
import { KuisCekKemampuan } from "../trainer/features/kuis-cek-kemampuan";
import { Subbab } from "../trainer/features/subbab";
import { Bab } from "../trainer/features/bab";
import { Materi } from "../trainer/features/materi";
import Landing   from "../landing";
import {
  LoginPage,
  ForgotPasswordPage,
  ResetPasswordPage,
} from "../trainer/features/auth";
import { Rangkuman } from "../trainer/features/rangkuman";
import { Praktik } from "../trainer/features/praktik";
import { LaporanBelajar } from "../trainer/features/laporan-belajar";
import { LaporanNilai } from "../trainer/features/laporan-nilai";
import { Dashboard } from "../trainer/features/dashboard";
import { FollowUp } from "../trainer/features/follow-up";
import { RiwayatLogin } from "../trainer/features/riwayat-login";
import { TalentTerdaftar } from "../trainer/features/talent-terdaftar";
import { ProgressBelajar } from "../trainer/features/progress-belajar";
import { ReportBelajar } from "../trainer/features/report-belajar-peserta";
import { MasterTalent } from "../trainer/features/master-talent";

import {
  LoginPage as LoginPageTalent,
  ResetSandiPage,
  VerifikasiPage,
} from "../talent/features/auth";
import { GetParentPath } from "../common";
import { Topik as TopikTalent } from "../talent/features/topik";
import { Leaderboards } from "../talent/features/leaderboards";
import { Catatan } from "../talent/features/catatan";
import { HomeMateri } from "../talent/features/home-materi";
import { Rangkuman as RangkumanTalent } from "../talent/features/rangkuman";
import { KuisMateri as KuisMateriTalent } from "../talent/features/kuis-materi";
import { KuisAkhir as KuisAkhirTalent } from "../talent/features/kuis-akhir";
import { CekKemampuan as CekKemampuanTalent } from "../talent/features/cek-kemampuan";
import { KuisBab as KuisBabTalent } from "../talent/features/kuis-bab";
import { Subbab as SubbabTalent } from "../talent/features/subbab";
import { Bab as BabTalent } from "../talent/features/bab";
import { VideoPlayer } from "../talent/features/video-player";
import { HomeKuis } from "../talent/features/home-kuis";
import { LihatDownload } from "../talent/features/download";
import { Screenshot } from "../talent/features/screenshot";
import { LaporanBelajar as LaporanBelajarTalent } from "../talent/features/laporan-belajar";
import { UjianPraktik } from "../talent/features/ujian-praktik";
import { LatihanPraktik } from "../talent/features/latihan-praktik";
import { MasterLembaga } from "../trainer/features/master-lembaga";

// harus sudah login
// kalau belum login, redirect ke halaman login
function RequireAuth({ children, isTalent = false }) {
  const parentPath = GetParentPath();
  const authed = useSelector((state) =>
    isTalent ? state.authTalent.jsonWebToken : state.auth.jsonWebToken
  );
  const location = useLocation();
  return authed ? (
    children
  ) : (
    <Navigate to={`${parentPath}/login`} state={{ from: location }} replace />
  );
}

// harus belum login
// kalau sudah login, redirect ke halaman topik
function RequireNotAuth({ children, isTalent = false }) {
  const parentPath = GetParentPath();
  const authed = useSelector((state) =>
    isTalent ? state.authTalent.jsonWebToken : state.auth.jsonWebToken
  );
  return authed ? <Navigate to={`${parentPath}/topik`} replace /> : children;
}

// harus admin
function RequireAuthAdmin({ children }) {
  const parentPath = GetParentPath();
  const jwt = useSelector((state) => state.auth.jsonWebToken);
  const role = useSelector((state) => state.auth.role);
  const authed = role === "admin" && jwt;
  return authed ? children : <Navigate to={`${parentPath}/dashboard`} replace />;
}

function App() {
  return (
    <>
      <ErrorAlert />
      <SuccessAlert />
      <BrowserRouter>
        <Routes>
          <Route path="/trainer" element={<Outlet />}>
            <Route path="login" element={<LoginPage />} />
            <Route
              path="reset-sandi"
              element={
                <RequireNotAuth>
                  <ResetPasswordPage />
                </RequireNotAuth>
              }
            />
            <Route
              path="lupa-sandi"
              element={
                <RequireNotAuth>
                  <ForgotPasswordPage />
                </RequireNotAuth>
              }
            />
            <Route
              path="topik"
              element={
                <RequireAuth>
                  <Home name="topik" crumbs={["Daftar Topik"]}>
                    <Topik />
                  </Home>
                </RequireAuth>
              }
            />
            <Route
              path="bab"
              element={
                <RequireAuth>
                  <Home name="bab" crumbs={["Daftar Bab"]}>
                    <Bab />
                  </Home>
                </RequireAuth>
              }
            />
            <Route
              path="subbab"
              element={
                <RequireAuth>
                  <Home name="sub bab" crumbs={["Daftar SubBab"]}>
                    <Subbab />
                  </Home>
                </RequireAuth>
              }
            />
            <Route
              path="materi"
              element={
                <RequireAuth>
                  <Home name="materi" crumbs={["Materi"]}>
                    <Materi />
                  </Home>
                </RequireAuth>
              }
            />
            <Route
              path="rangkuman"
              element={
                <RequireAuth>
                  <Home name="Rangkuman" crumbs={["Rangkuman"]}>
                    <Rangkuman />
                  </Home>
                </RequireAuth>
              }
            />
            <Route
              path="kuis-materi"
              element={
                <RequireAuth>
                  <Home
                    name="kuis materi"
                    crumbs={["Soal Kuis", "Kuis Materi"]}
                  >
                    <KuisMateri />
                  </Home>
                </RequireAuth>
              }
            />
            <Route
              path="kuis-akhir"
              element={
                <RequireAuth>
                  <Home name="kuis akhir" crumbs={["Soal Kuis", "Kuis Akhir"]}>
                    <KuisAkhir />
                  </Home>
                </RequireAuth>
              }
            />
            <Route
              path="kuis-bab"
              element={
                <RequireAuth>
                  <Home name="kuis bab" crumbs={["Soal Kuis", "Kuis Bab"]}>
                    <KuisBab />
                  </Home>
                </RequireAuth>
              }
            />
            <Route
              path="cek-kemampuan"
              element={
                <RequireAuth>
                  <Home
                    name="kuis cek kemampuan"
                    crumbs={["Soal Kuis", "Kuis Cek Kemampuan"]}
                  >
                    <KuisCekKemampuan />
                  </Home>
                </RequireAuth>
              }
            />
            <Route
              path="praktik"
              element={
                <RequireAuth>
                  <Home name="praktik" crumbs={["Soal Kuis", "Praktik"]}>
                    <Praktik />
                  </Home>
                </RequireAuth>
              }
            />
            <Route
              path="laporan-belajar"
              element={
                <RequireAuth>
                  <Home name="laporan belajar" crumbs={["Laporan", "Belajar"]}>
                    <LaporanBelajar />
                  </Home>
                </RequireAuth>
              }
            />
            <Route
              path="laporan-nilai"
              element={
                <RequireAuth>
                  <Home name="laporan nilai" crumbs={["Laporan", "Nilai"]}>
                    <LaporanNilai />
                  </Home>
                </RequireAuth>
              }
            />
            <Route
              path="talent-terdaftar"
              element={
                <RequireAuth>
                  <Home
                    name="talent terdaftar"
                    crumbs={["Laporan", "Talent Terdaftar"]}
                  >
                    <TalentTerdaftar />
                  </Home>
                </RequireAuth>
              }
            />
            <Route
              path="riwayat-login"
              element={
                <RequireAuth>
                  <Home
                    name="riwayat login"
                    crumbs={["Laporan", "Riwayat Login"]}
                  >
                    <RiwayatLogin />
                  </Home>
                </RequireAuth>
              }
            />
            <Route
              path="report-belajar"
              element={
                <RequireAuth>
                  <Home
                    name="report belajar"
                    crumbs={["Laporan", "Report Belajar Peserta"]}
                  >
                    <ReportBelajar />
                  </Home>
                </RequireAuth>
              }
            />
            <Route
              path="progress-belajar"
              element={
                <RequireAuth>
                  <Home
                    name="progress belajar"
                    crumbs={["Laporan", "Progress Belajar Peserta"]}
                  >
                    <ProgressBelajar />
                  </Home>
                </RequireAuth>
              }
            />
            <Route
              path="master-talent"
              element={
                <RequireAuth>
                  <Home name="master talent" crumbs={["Master Data", "Talent"]}>
                    <MasterTalent />
                  </Home>
                </RequireAuth>
              }
            />
            <Route
              path="master-lembaga"
              element={
                <RequireAuth>
                  <Home
                    name="master lembaga"
                    crumbs={["Master Data", "Lembaga"]}
                  >
                    <MasterLembaga />
                  </Home>
                </RequireAuth>
              }
            />
            <Route
              path="kelola-akun"
              element={
                <RequireAuthAdmin>
                  <Home name="kelola akun" crumbs={["Kelola Akun"]}>
                    <KelolaAkun />
                  </Home>
                </RequireAuthAdmin>
              }
            />
            {/* New Menu Dashboard & Follow Up */}
            <Route
              path="dashboard"
              element={
                <RequireAuth>
                  <Home name="dashboard" crumbs={["Dashboard"]}>
                    <Dashboard />
                  </Home>
                </RequireAuth>
              }
            />
            <Route
              path="follow-up"
              element={
                <RequireAuth>
                  <Home name="follow up" crumbs={["Follow Up"]}>
                    <FollowUp />
                  </Home>
                </RequireAuth>
              }
            />
            <Route exact path="" element={<Navigate to="login" />} />
            <Route exact path="*" element={<Navigate to="login" />} />
          </Route>
          {/* <Route path="/login" element={<LoginPageTalent page="login" />} /> */}
          <Route
            path="/register"
            element={<LoginPageTalent page="register" />}
          />
          <Route
            path="/lupa-sandi"
            element={<LoginPageTalent page="lupa-sandi" />}
          />
          <Route path="/reset-sandi" element={<ResetSandiPage />} />
          <Route path="/verifikasi" element={<VerifikasiPage />} />
          <Route
            path="/topik"
            element={
              <RequireAuth isTalent>
                <HomeTalent>
                  <TopikTalent />
                </HomeTalent>
              </RequireAuth>
            }
          />
          <Route
            path="/bab"
            element={
              <RequireAuth isTalent>
                <HomeTalent>
                  <BabTalent />
                </HomeTalent>
              </RequireAuth>
            }
          />
          <Route
            path="/subbab"
            element={
              <RequireAuth isTalent>
                <HomeTalent>
                  <SubbabTalent />
                </HomeTalent>
              </RequireAuth>
            }
          />
          <Route
            path="/materi"
            element={
              <RequireAuth isTalent>
                <HomeMateri>
                  <VideoPlayer />
                </HomeMateri>
              </RequireAuth>
            }
          />
          <Route
            path="/kuis-materi"
            element={
              <RequireAuth isTalent>
                <HomeMateri activeTab="kuis-materi">
                  <KuisMateriTalent />
                </HomeMateri>
              </RequireAuth>
            }
          />
          <Route
            path="/kuis-akhir"
            element={
              <RequireAuth isTalent>
                <HomeKuis>
                  <KuisAkhirTalent />
                </HomeKuis>
              </RequireAuth>
            }
          />
          <Route
            path="/cek-kemampuan"
            element={
              <RequireAuth isTalent>
                <HomeKuis>
                  <CekKemampuanTalent />
                </HomeKuis>
              </RequireAuth>
            }
          />
          <Route
            path="/kuis-bab"
            element={
              <RequireAuth isTalent>
                <HomeKuis>
                  <KuisBabTalent />
                </HomeKuis>
              </RequireAuth>
            }
          />
          <Route
            path="/rangkuman"
            element={
              <RequireAuth isTalent>
                <HomeMateri activeTab="rangkuman">
                  <RangkumanTalent />
                </HomeMateri>
              </RequireAuth>
            }
          />
          <Route
            path="/catatan"
            element={
              <RequireAuth isTalent>
                <HomeTalent>
                  <Catatan />
                </HomeTalent>
              </RequireAuth>
            }
          />
          <Route
            path="/leaderboards"
            element={
              <RequireAuth isTalent>
                <HomeTalent>
                  <Leaderboards />
                </HomeTalent>
              </RequireAuth>
            }
          />
          <Route
            path="/download"
            element={
              <RequireAuth isTalent>
                <HomeTalent>
                  <LihatDownload />
                </HomeTalent>
              </RequireAuth>
            }
          />
          <Route
            path="/screenshot"
            element={
              <RequireAuth isTalent>
                <HomeTalent>
                  <Screenshot />
                </HomeTalent>
              </RequireAuth>
            }
          />
          <Route
            path="/laporan-belajar"
            element={
              <RequireAuth isTalent>
                <HomeTalent>
                  <LaporanBelajarTalent />
                </HomeTalent>
              </RequireAuth>
            }
          />
          <Route
            path="/ujian-praktik"
            element={
              <RequireAuth isTalent>
                <UjianPraktik />
              </RequireAuth>
            }
          />
          <Route
            path="/latihan-praktik"
            element={
              <RequireAuth isTalent>
                <LatihanPraktik />
              </RequireAuth>
            }
          />
          <Route path="/landing" element={<Landing />} />
          <Route exact path="*" element={<Navigate to="/landing" />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;

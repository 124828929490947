import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { showErrorAlert } from "../../../app";

// component
import _ from "lodash";
import {
  Button,
  Col,
  Form,
  FormControl,
  InputGroup,
  Modal,
  Row,
} from "react-bootstrap";
import Select from "react-select";
import {
  getOptLembaga,
  getOptStatusPeserta
} from "../../service/MasterTalentService";

function MasterTalentPopup({ show, type, onClose, data, updateData }) {
  const dispatch = useDispatch();
  const [value, setValue] = useState({
    asalLembaga: {
      value: "",
      label: "Pilih Universitas",
    },
  });
  const [loading, setLoading] = useState(false);
  const [optionLembaga, setOptionLembaga] = useState([]);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isKeteranganValid, setIsKeteranganValid] = useState(true);
  const maxKeteranganLength = 1275;
  const specialCharacterRegex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\|/\\-]/;
  const [keteranganErrorMessage, setKeteranganErrorMessage] = useState('');

  const jenisKelaminOptions = [
    { value: "L", label: "Laki - Laki" },
    { value: "P", label: "Perempuan" },
  ];

  const bulanOptions = [
    { value: "Januari", label: "Januari" },
    { value: "Februari", label: "Februari" },
    { value: "Maret", label: "Maret" },
    { value: "April", label: "April" },
    { value: "Mei", label: "Mei" },
    { value: "Juni", label: "Juni" },
    { value: "Juli", label: "Juli" },
    { value: "Agustus", label: "Agustus" },
    { value: "September", label: "September" },
    { value: "Oktober", label: "Oktober" },
    { value: "November", label: "November" },
    { value: "Desember", label: "Desember" },
  ];

  const [optionStatusPEserta, setOptionStatusPeserta] = useState([]);

  const getLembaga = (val) => {
    setLoading(true);
    getOptLembaga({
      tipeLembaga: value?.tipeLembaga,
      keywordSearch: val,
    })
      .then(
        (response) => {
          switch (response.data.status_code) {
            case "TAL200":
              const arr = response.data.message.map((e) => ({
                value: e.namaLembaga,
                label: e.namaLembaga,
              }));
              setOptionLembaga(arr);
              setLoading(false);
              break;
            default:
              dispatch(showErrorAlert("Terjadi kesalahan pada server!"));
              setLoading(false);
              break;
          }
        },
        (error) => {
          console.log("Verifkasi error :", error);
        }
      )
      .finally((info) => {});
  };

  const getStatusPeserta = (val) => {
    setLoading(true);
    getOptStatusPeserta().then((response) => {
      switch(response.data.status_code) {
        case "TS200":
          const arr = response.data.message.map((e) => ({
            value: e.id,
            label: e.nama_status,
          }))
          setOptionStatusPeserta(arr)
          setLoading(false)
          break;
        default :
          dispatch(showErrorAlert("Terjadi kesalahan pada server!"))
          setLoading(false)
          break;
      }
    })

  }

  useEffect(() => {
    setValue({
      talentID: data?.id,
      email: data?.email,
      jenisKelamin:
        jenisKelaminOptions.find(
          (item) => item.value === data?.jenis_kelamin
        ) || null,
      nama: data?.nama,
      namaLembaga: data?.nama_lembaga,
      nomorTelp: data?.nomor_telp,
      noWa: data?.nomor_wa,
      tipeLembaga: data?.tipe_lembaga,
      generasi: data?.generasi,
      asalLembaga: {
        value: data?.nama_lembaga,
        label: data?.nama_lembaga,
      },
      statusPeserta: {
        value: data?.status_peserta_id,
        label: data?.status_peserta
      },
      keterangan: data?.keterangan,
      siapMasukBulan:
        bulanOptions.find(
          (item) => item.value === data?.siap_masuk_bulan
        ) || null,
    });
    setIsValidEmail(true);
  }, [show]);

  useEffect(() => {
    getStatusPeserta()
  }, [])

  const handleSubmit = () => {};

  const isRequired = (
    <Form.Control.Feedback type="invalid">
      Field is Required
    </Form.Control.Feedback>
  );

  const emailValidate = (value) => {
    if (!value) return false;
    const re = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,6}$/g;
    return re.test(value.trim());
  };

  const handleChange = (val, e) => {
    setValue({ ...value, [val]: e });
  };

  const textField = (label, val, required) => {
    return (
      <Row className="mx-0 mb-3">
        <Col>
          <Form.Group>
            <Form.Label>
              {label} {required && <span style={{ color: "red" }}>*</span>}
            </Form.Label>
            <Form.Control
              placeholder={label}
              value={value[val]}
              onChange={(e) => {
                const str = e.target.value;
                if (val === "email") {
                  setIsValidEmail(emailValidate(str));
                }
                handleChange(val, str);
              }}
              type={val === "email" ? "email" : "text"}
              isInvalid={
                value[val] === "" && required
                  ? isRequired
                  : val === "email" && !isValidEmail
              }
            />
            {value[val] === "" && required
              ? isRequired
              : val === "email" &&
                !isValidEmail && (
                  <Form.Control.Feedback type="invalid">
                    E-mail tidak sesuai format!
                  </Form.Control.Feedback>
                )}
          </Form.Group>
        </Col>
      </Row>
    );
  };

  const numberField = (label, val, required) => {
    return (
      <Row className="mx-0 mb-3">
        <Col>
          <Form.Group>
            <Form.Label>
              {label} {required && <span style={{ color: "red" }}>*</span>}
            </Form.Label>
            <InputGroup>
              {(val === "nomorTelp" || val === "noWa") && (
                <InputGroup.Text>62</InputGroup.Text>
              )}
              <FormControl
                placeholder={label}
                value={value[val]}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                onChange={(e) => {
                  const str = e.target.value;
                  if (str.charAt(0) === "0") {
                    str = str.slice(1);
                  }
                  handleChange(val, e.target.value);
                }}
                isInvalid={
                  (value[val] === "" && required) || value[val]?.length < 9
                }
                maxLength={12}
              />
              {/* {isRequired} */}
              {value[val] === "" && required ? (
                isRequired
              ) : value[val]?.length < 9 ? (
                <Form.Control.Feedback type="invalid">
                  {label} harus 9-12 digit!
                </Form.Control.Feedback>
              ) : null}
            </InputGroup>
            {required && isRequired}
          </Form.Group>
        </Col>
      </Row>
    );
  };

  const handleSelect = (e, val) => {
    setValue({ ...value, [val]: e });
  };

  const selectField = (label, val, options, required) => {
    return (
      <Row className="mx-0 mb-3">
        <Col>
          <Form.Group>
            <Form.Label>
              {label} {required && <span style={{ color: "red" }}>*</span>}
            </Form.Label>
            <Select
              value={value[val]}
              noOptionsMessage={() => "No options"}
              placeholder={label}
              options={options}
              onChange={(e) => handleSelect(e, val)}
            />
            {required && isRequired}
          </Form.Group>
        </Col>
      </Row>
    );
  };

  const onLembagaChange = (event) => {
    const val = event.target.id;
    const tipe = val === "Institusi" ? "Pilih Universitas" : "Pilih Sekolah";
    setValue({
      ...value,
      tipeLembaga: val,
      asalLembaga: {
        value: "",
        label: tipe,
      },
    });
  };

  const pilihLembaga = (
    <Row className="mx-0 mb-3">
      <Col>
        <Form.Group className="w-100" controlId="pilihLembaga">
          <Form.Label>
            Pilih Lembaga<span style={{ color: "red" }}>*</span>
          </Form.Label>
          <InputGroup>
            <Form.Check
              inline
              label="Universitas"
              name="tipeLembaga"
              type="radio"
              id="1"
              checked={value?.tipeLembaga === "1"}
              onChange={onLembagaChange}
            />
            <Form.Check
              inline
              label="Sekolah"
              name="tipeLembaga"
              type="radio"
              id="2"
              checked={value?.tipeLembaga === "2"}
              onChange={onLembagaChange}
            />
          </InputGroup>
        </Form.Group>
      </Col>
    </Row>
  );

  const handleLembaga = (event) => {
    if (!event) {
      const tipe =
        value?.tipeLembaga === "Institusi"
          ? "Pilih Universitas"
          : "Pilih Sekolah";
      event = {
        target: null,
        value: "",
        label: tipe,
      };
    }
    setValue({ ...value, asalLembaga: event });
  };

  const filterLembaga = (val) => {
    if (val.length > 2) {
      getLembaga(val);
    } else {
      setOptionLembaga([]);
    }
  };

  const debounce = useMemo(
    () => _.debounce(filterLembaga, 500),
    [value?.tipeLembaga]
  );

  const selectLembaga = (
    <Row className="mx-0 mb-3">
      <Col>
        <Form.Group className="w-100" controlId="selectLembaga">
          <Select
            value={value?.asalLembaga}
            noOptionsMessage={() => "No options"}
            options={optionLembaga}
            onChange={handleLembaga}
            onInputChange={(e) => debounce(e)}
            isClearable={value?.asalLembaga.value}
            isLoading={loading}
            styles={{
              control: (provided, state) => ({
                ...provided,
                borderColor: !value?.asalLembaga.value ? "#dc3545" : "#ced4da",
              }),
            }}
          />
          <span style={{ fontSize: 14 }} className="text-danger">
            {!value?.asalLembaga.value && "Field is Required"}
          </span>
        </Form.Group>
      </Col>
    </Row>
  );

  const keterangan = (
    <Row
      className="mx-0 mb-3"
      style={{
        display:
          value.statusPeserta?.label === "Pending" ||
          value.statusPeserta?.label === "Mengundurkan Diri"
            ? "block"
            : "none",
      }}
    >
      <Col>
        <Form.Group>
          <Form.Label>
            Keterangan<span style={{ color: "red" }}>*</span>
          </Form.Label>
          <FormControl
            as="textarea"
            placeholder="Keterangan"
            value={value.keterangan}
            onChange={(e) => {
              const keteranganValue = e.target.value;
              const hasSpecialCharacters = specialCharacterRegex.test(
                keteranganValue
              );

              if (keteranganValue.length <= maxKeteranganLength) {
                if (!hasSpecialCharacters) {
                  handleChange("keterangan", keteranganValue);
                  setIsKeteranganValid(true);
                  setKeteranganErrorMessage('');
                } else {
                  setIsKeteranganValid(false);
                  setKeteranganErrorMessage("Tidak boleh mengandung karakter khusus!");
                }
              } else {
                setIsKeteranganValid(false);
                setKeteranganErrorMessage(`Keterangan sudah mencapai batas maksimal input!`);
              }
            }}
            onPaste={(e) => {
              const pastedText = e.clipboardData.getData("text/plain");
              const keteranganValue = value.keterangan + pastedText;
              const hasSpecialCharacters = specialCharacterRegex.test(keteranganValue);

              if (keteranganValue.length <= maxKeteranganLength) {
                if (!hasSpecialCharacters) {
                  handleChange("keterangan", keteranganValue);
                  setIsKeteranganValid(true);
                  setKeteranganErrorMessage('');
                } else {
                  e.preventDefault();
                  setIsKeteranganValid(false);
                  setKeteranganErrorMessage("Tidak boleh mengandung karakter khusus!");
                }
              } else {
                e.preventDefault();
                setIsKeteranganValid(false);
                setKeteranganErrorMessage(`Keterangan sudah mencapai batas maksimal input!`);
              }
            }}
            type="text"
            isInvalid={value.keterangan && !isKeteranganValid}
          />
            {!isKeteranganValid && value.keterangan && (
              <Form.Control.Feedback type="invalid">
                {keteranganErrorMessage}
              </Form.Control.Feedback>
            )}
        </Form.Group>
      </Col>
    </Row>
  );
  

  console.log("value");

  const isValid = (value) => {
    console.log("value", value);
    if (
      !value?.noWa ||
      !value?.nomorTelp ||
      !value?.email ||
      !value?.jenisKelamin?.value ||
      !value?.asalLembaga?.value ||
      !value?.siapMasukBulan?.value
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Modal show={show} onHide={onClose} centered backdrop="static" size="md">
      <Modal.Header closeButton>
        <Modal.Title>Edit Talent</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {textField("E-Mail", "email", true)}
        {numberField("Nomor Telepon", "nomorTelp", true)}
        {numberField("Nomor WA Aktif", "noWa", true)}
        {selectField(
          "Jenis Kelamin",
          "jenisKelamin",
          jenisKelaminOptions,
          true
        )}
        {pilihLembaga}
        {selectLembaga}
        {selectField(
          "Masuk Kelas Arutala",
          "siapMasukBulan",
          bulanOptions,
          true
        )}
        {selectField(
          "Status Peserta",
          "statusPeserta",
          optionStatusPEserta,
          true
        )}
        {keterangan}
        {/* {textField("Generasi", "generasi", false)} */}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="light"
          onClick={onClose}
          style={{ color: "#0078D7", border: "1px solid #0078D7" }}
        >
          Batal
        </Button>
        <Button
          type="submit"
          variant="primary"
          disabled={
            !value?.noWa ||
            value?.noWa?.length < 9 ||
            !value?.nomorTelp ||
            value?.nomorTelp?.length < 9 ||
            !value?.email ||
            !value?.jenisKelamin?.value ||
            !value?.asalLembaga?.value ||
            !value?.siapMasukBulan?.value ||
            !value?.statusPeserta?.value ||
            !isValidEmail||
            (value?.statusPeserta?.label === "Pending" ||
            value?.statusPeserta?.label === "Mengundurkan Diri") &&
            (!value?.keterangan || value?.keterangan.trim() === "")
              }
              onClick={() => updateData(value)}
            >
          Simpan
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default MasterTalentPopup;

import React, { useEffect, useState } from 'react';
import { Table } from '../../common/table';
import { columns } from './columns';
import { useDispatch } from 'react-redux';
import { RangkumanPopupDelete, RangkumanPopupDetil, RangkumanPopupTambahEdit, RangkumanDeleteConfirmation } from '.';
import { listRangkumanTrainer } from '../../service/RangkumanService';
import { showErrorAlert } from '../../../app';

function Rangkuman() {
    const [state, setState] = useState([]);
    const [showPopUp, setShowPopUp] = useState(false);
    const [showPopUpDelete, setShowPopUpDelete] = useState(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [showPopUpDetail, setShowPopUpDetail] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [rangkumanData, setRangkumanData] = useState();
    const [refreshData, setRefreshData] = useState(1);

    const dispatch = useDispatch();
    
    useEffect(() => {
        const listRangkumanHit = () => {
            listRangkumanTrainer().then(
                (response) => {
                    switch (response.data.status_code) {
                        case 'RKN200':
                            setState(response.data.message || []);
                            break;
                        default:
                            dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                            break;
                    }
                },
                (error) => {
                    console.log('List Rangkuman error :', error);
                })
        }
        listRangkumanHit();
    }, [dispatch, refreshData]);

    const addFunction = () => {
        setRangkumanData();
        setIsEdit(false);
        setShowPopUp(prev => !prev);
    }

    const editFunction = (row) => {
        console.log(row);
        setRangkumanData(row);
        setIsEdit(true);
        setShowPopUp(prev => !prev);
    }

    const deleteFunction = (row) => {
        setRangkumanData(row);
        setShowPopUpDelete(prev => !prev);
    }

    const detailFunction = (row) => {
        setRangkumanData(row);
        setShowPopUpDetail(prev => !prev);
    }

    return (
        <>
            <RangkumanPopupTambahEdit
                setRefreshData={setRefreshData}
                show={showPopUp}
                setShow={setShowPopUp}
                rangkumanData={rangkumanData} 
                isEdit={isEdit} />
            <RangkumanPopupDetil
                show={showPopUpDetail}
                setShow={setShowPopUpDetail}
                rangkumanData={rangkumanData}
                />
            <RangkumanPopupDelete
                setRefreshData={setRefreshData}
                show={showPopUpDelete}
                setShow={setShowPopUpDelete}
                rangkumanData={rangkumanData}
                setShowDeleteConfirmation={setShowDeleteConfirmation}
            />
            <RangkumanDeleteConfirmation
                setRefreshData={setRefreshData}
                show={showDeleteConfirmation}
                setShow={setShowDeleteConfirmation}
                rangkumanData={rangkumanData}
                setShowPopUpDelete={setShowPopUpDelete}
            />
            <Table
                data={state}
                rowClickFunction={detailFunction}
                columns={columns(editFunction, deleteFunction)}
                title='Rangkuman Digital Bootcamp 79'
                addFunction={addFunction} />
        </>
    );
}

export default Rangkuman;

import React, { useEffect, useState } from 'react';
import './Bab.css';
import { Button, Col, Form, Modal, OverlayTrigger, Row, Stack, Tooltip } from 'react-bootstrap';
import { faEye, faEyeSlash, faInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import { useDispatch } from 'react-redux';
import { addBabTrainer, editBabTrainer } from '../../service/BabService';
import { showErrorAlert, hideErrorAlert, showSuccessAlert, hideSuccessAlert } from '../../../app';
import { listTopikTrainer } from '../../service/TopikService';
import { listTagTrainer } from '../../service/TagService';

export default function PopupTambahEdit({ show, setShow, babData, isEdit, setRefreshData }) {
    const dispatch = useDispatch();

    const [id, setId] = useState();
    const [nama, setNama] = useState();
    const [judulTopik, setJudulTopik] = useState();
    const [idTopik, setIdTopik] = useState();
    const [skalaNilai, setSkalaNilai] = useState();
    const [visibilitas, setVisibilitas] = useState();
    const [tag, setTag] = useState();
    const [deskripsi, setDeskripsi] = useState();

    const [topikList, setTopikList] = useState([]);
    const [tagList, setTagList] = useState([]);
    const [visibilitasOptions, setVisibilitasOptions] = useState([
        {
            value: 'sembunyi', label: <span>
                <FontAwesomeIcon icon={faEyeSlash} color={'#263238'} className='me-2' />Sembunyi</span>
        },
        {
            value: 'publik', label: <span>
                <FontAwesomeIcon icon={faEye} color={'#263238'} className='me-2' />Publik</span>
        },
    ]);

    useEffect(() => {
        setId(babData?.id || '');
        setNama(babData?.nama || '');
        setJudulTopik(babData?.judulTopik || '');
        setIdTopik(babData?.idTopik || '');
        setSkalaNilai(babData?.skalaNilai || '');
        setVisibilitas(babData?.visibilitas || 'sembunyi');
        setTag(babData?.tag || '');
        setDeskripsi(babData?.deskripsi || '');
    }, [show, babData]);

    useEffect(() => {
        const listTopikHit = () => {
            listTopikTrainer().then(
                (response) => {
                    switch (response.data.status_code) {
                        case 'TPK200':
                            setTopikList(response.data.message);
                            break;
                        default:
                            dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                            break;
                    }
                },
                (error) => {
                    console.log('List Topik error :', error);
                })
        }
        listTopikHit();
    }, [dispatch]);

    useEffect(() => {
        const listTagHit = () => {
            listTagTrainer().then(
                (response) => {
                    switch (response.data.status_code) {
                        case 'TAG200':
                            setTagList(response.data.message);
                            break;
                        default:
                            dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                            break;
                    }
                },
                (error) => {
                    console.log('List Tag error :', error);
                })
        }
        listTagHit();
    }, [dispatch]);

    const topikOptions = topikList.map(topik => ({ value: topik.id, label: topik.judul }));
    const topikSelected = topikOptions.find(item => item.value === idTopik) || null;

    const tagOptions = tagList.map(item => ({ value: item, label: item }));
    const tagSelected = tag ? tag?.split(',').map(item => ({ value: item.trim(), label: item.trim() })) : null;

    const visibilitasSelected = visibilitasOptions.find(item => item.value === visibilitas) || null;

    const handleTopikChange = (value) => {
        setIdTopik(value.value);
        setJudulTopik(value.label);
    }

    const isOnlyLetterAndSpace = (str) => {
        const re = new RegExp(/^[A-Za-z\s]*$/);
        return re.test(str);
    }

    const handleTagChange = (tags) => {
        for (const tag of tags) {
            if (!isOnlyLetterAndSpace(tag.value)) {
                dispatch(showErrorAlert('Tag hanya boleh mengandung huruf dan spasi!'));
                return;
            }
        }
        setTag(tags.map(tag => tag.value).join(', '));
    }

    const handleVisibilitasChange = (value) => {
        setVisibilitas(value.value);
    }

    const namaBabField = <Col>
        <Form.Group>
            <Form.Label>Nama Bab <b className='asterisk'>*</b></Form.Label>
            <Form.Control placeholder='Nama Bab'
                value={nama}
                onChange={(e) => { setNama(e.target.value) }}
            />
        </Form.Group>
    </Col>

    const firstRowForm = <Row className='mx-0 mb-3'>
        {namaBabField}
    </Row>

    const judulTopikField = <Col>
        <Form.Group>
            <Form.Label>Topik/Judul Materi <b className='asterisk'>*</b></Form.Label>
            <Select
                value={topikSelected}
                noOptionsMessage={() => 'No options'}
                placeholder='Topik/Judul Materi'
                options={topikOptions}
                onChange={handleTopikChange}
            />
        </Form.Group>
    </Col>

    const secondRowForm = <Row className='mx-0 mb-3'>
        {judulTopikField}
    </Row>

    const deskripsiField = <Col>
        <Form.Group>
            <Form.Label>Deskripsi Materi<b className='asterisk'>*</b></Form.Label>
            <Form.Control as="textarea" placeholder="Deskripsi"
                onChange={(e) => { setDeskripsi(e.target.value) }}
                value={deskripsi} />
        </Form.Group>
    </Col>

    const thirdRowForm = <Row className='mx-0 mb-3'>
        {deskripsiField}
    </Row>

    const tagTooltip = <OverlayTrigger
        trigger='hover'
        placement='top'
        overlay={
            <Tooltip className='tag-tooltip'>
                Mohon diisi minimal 1 tag
            </Tooltip>
        }
    >
        <Button variant='light'
            className='tag-tooltip-button bg-transparent p-0 ms-2 rounded-circle
                border border-2 border-dark d-flex align-items-center justify-content-center'
            style={{ width: '1rem', height: '1rem' }}
        >
            <FontAwesomeIcon icon={faInfo} style={{ fontSize: '0.625rem' }} />
        </Button>
    </OverlayTrigger>

    const tagField = <Col>
        <Form.Group>
            <Stack direction='horizontal' className='mb-2'>
                <Form.Label className='mb-0'>Tag <b className='asterisk'>*</b></Form.Label>
                {tagTooltip}
            </Stack>
            <CreatableSelect isMulti isClearable={false}
                value={tagSelected}
                noOptionsMessage={() => 'No options'}
                placeholder='Tag'
                options={tagOptions}
                formatCreateLabel={(newTag) => `Buat tag '${newTag}'`}
                onChange={handleTagChange}
                getNewOptionData={(value, label) => {
                    return {
                        value: value.toUpperCase(),
                        label: label.replace(`${value}`, value.toUpperCase())
                    }
                }}
            />
        </Form.Group>
    </Col>

    const fourthRowForm = <Row className='mx-0 mb-3'>
        {tagField}
    </Row>

    const skalaNilaiField = <Col>
        <Form.Group>
            <Form.Label>Skala Nilai <b className='asterisk'>*</b></Form.Label>
            <Form.Control placeholder='Skala Nilai'
                type="number"
                value={skalaNilai}
                onChange={(e) => { setSkalaNilai(e.target.value) }}
            />
        </Form.Group>
    </Col>

    const visibilitasField = <Col>
        <Form.Group>
            <Form.Label>Visibilitas <b className='asterisk'>*</b></Form.Label>
            <Select value={visibilitasSelected}
                noOptionsMessage={() => 'No options'}
                placeholder='Visibilitas'
                options={visibilitasOptions}
                onChange={handleVisibilitasChange}
            />
        </Form.Group>
    </Col>

    const fifthRowForm = <Row className='mx-0 mb-3'>
        {skalaNilaiField}
        {visibilitasField}
    </Row>

    const addBab = () => {
        addBabTrainer({ idTopik, nama, deskripsi, tag, skalaNilai, visibilitas }).then(
            (response) => {
                switch (response.data.status_code) {
                    case 'BAB200':
                        dispatch(showSuccessAlert('Bab berhasil dibuat'));
                        setRefreshData(prev => prev + 1);
                        setShow(prev => !prev);
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('Add Bab error :', error);
            }).finally((info) => {

            });
    }

    const editBab = () => {
        editBabTrainer({ id, idTopik, nama, deskripsi, tag, skalaNilai, visibilitas }).then(
            (response) => {
                switch (response.data.status_code) {
                    case 'BAB200':
                        dispatch(showSuccessAlert('Bab berhasil diubah'));
                        setRefreshData(prev => prev + 1);
                        setShow(prev => !prev);
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('Edit Bab error :', error);
            }).finally((info) => {

            });
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        dispatch(hideErrorAlert());
        dispatch(hideSuccessAlert());

        if (!nama) {
            dispatch(showErrorAlert('Isi nama bab!'));
        }else if (!judulTopik) {
            dispatch(showErrorAlert('Pilih topik!'));
        }else if (!deskripsi) {
            dispatch(showErrorAlert('Isi deskripsi!'));
        }else if (!tag) {
            dispatch(showErrorAlert('Isi tag (minimal 1 tag)!'));
        }else if (!skalaNilai) {
            dispatch(showErrorAlert('Isi skala nilai!'));
        }else if (!visibilitas) {
            dispatch(showErrorAlert('Pilih visibilitas!'));
        } else {
            if (isEdit) editBab();
            else addBab();
        }
    }

    return (
        <Modal show={show} onHide={() => setShow(prev => !prev)} centered>
            <Modal.Header closeButton>
                <Modal.Title>{!isEdit ? 'Buat Bab Baru' : 'Edit Bab'}</Modal.Title>
            </Modal.Header>
            <Form onSubmit={(e) => handleSubmit(e)}>
                <Modal.Body>

                    {firstRowForm}
                    {secondRowForm}
                    {thirdRowForm}
                    {fourthRowForm}
                    {fifthRowForm}

                </Modal.Body>
                <Modal.Footer>
                    <Button variant='light' onClick={() => setShow(prev => !prev)}
                        style={{ color: '#0078D7', border: '1px solid #0078D7' }}
                    >
                        Batal
                    </Button>
                    <Button type='submit' variant='primary'>
                        Simpan
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    breadcrumbs: ["Video Materi", "Detil Video Materi"],
};

export const BreadcrumbsSlice = createSlice({
    name: 'breadcrumbs',
    initialState: initialState,
    reducers: {
        // loading
        pushCrumb: (state, action) => {
            const index = state.breadcrumbs.indexOf(action.payload);
            if (index < 0) {
                state.breadcrumbs.push(action.payload);
            }
        },
        popCrumb: (state, action) => {
            const index = state.breadcrumbs.indexOf(action.payload);
            if (index > -1) {
                state.breadcrumbs.splice(index, 1);
            }
        },
        popAllCrumbs: (state) => {
            state.breadcrumbs = [];
        },
        setCrumbs: (state, action) => {
            state.breadcrumbs = action.payload;
        },
    },
});

export const {
    pushCrumb, popCrumb, popAllCrumbs, setCrumbs,
} = BreadcrumbsSlice.actions;
export default BreadcrumbsSlice.reducer;

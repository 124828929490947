import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  Card,
  Col,
  Container,
  Form,
  Row
} from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { showErrorAlert } from "../../../app";
import {
  getGroupInstitution,
  getGroupInstitutionPercentage,
  getStatistikPendaftaran,
  getTotalTalentJoin,
  getTotalTalentJoinToday,
  getTotalTalentKelasArutala,
  getTotalTalentLolosKelasTeknologi,
  getTotalTalentTidakLogin,
} from "../../service/DashboardService";
import BarChart from "./BarChart";
import "./Dashboard.css";
import DoughnutChart from "./DoughnutChart";
import { bgColor, bgColor2 } from "./dummy";

export default function Statistik() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [filter, setFilter] = useState("week");
  const [dataTotalTalentJoin, setDataTotalTalentJoin] = useState(0);
  const [dataTotalTalentJoinToday, setDataTotalTalentJoinToday] = useState(0);
  const [dataStatistikPendaftaran, setDataStatistikPendaftaran] = useState([]);
  const [dataGroupInstitution, setDataGroupInstitution] = useState([]);
  const [dataGroupInstitutionPercentage, setDataGroupInstitutionPercentage] =
    useState([]);
  const [today, setToday] = useState(moment(new Date()).format("dddd"));

  const options = [
    {
      label: "This Week",
      value: "week",
    },
    {
      label: "This Month",
      value: "month",
    },
    {
      label: "This Year",
      value: "year",
    },
  ];

  const fetchTotalTalent = (filter) => {
    getTotalTalentJoin(filter)
      .then(
        (response) => {
          switch (response.data.status_code) {
            case "TAL200":
              setDataTotalTalentJoin(response.data.message?.total || 0);
              break;
            default:
              dispatch(showErrorAlert("Terjadi kesalahan pada server!"));
              break;
          }
        },
        (error) => {
          console.log("error :", error);
        }
      )
      .finally((info) => {});
  };

  const fetchTotalTalentToday = () => {
    getTotalTalentJoinToday()
      .then(
        (response) => {
          switch (response.data.status_code) {
            case "TAL200":
              setDataTotalTalentJoinToday(response.data.message.total);
              break;
            default:
              dispatch(showErrorAlert("Terjadi kesalahan pada server!"));
              break;
          }
        },
        (error) => {
          console.log("error :", error);
        }
      )
      .finally((info) => {});
  };

  const fetchStatistikPendaftaran = (filter) => {
    getStatistikPendaftaran(filter)
      .then(
        (response) => {
          switch (response.data.status_code) {
            case "TAL200":
              setDataStatistikPendaftaran(response.data.message);
              break;
            default:
              dispatch(showErrorAlert("Terjadi kesalahan pada server!"));
              break;
          }
        },
        (error) => {
          console.log("error :", error);
        }
      )
      .finally((info) => {});
  };

  const fetcGroupInstitution = (filter) => {
    getGroupInstitution(filter)
      .then(
        (response) => {
          switch (response.data.status_code) {
            case "TAL200":
              setDataGroupInstitution(response.data.message);
              break;
            default:
              dispatch(showErrorAlert("Terjadi kesalahan pada server!"));
              break;
          }
        },
        (error) => {
          console.log("error :", error);
        }
      )
      .finally((info) => {});
  };

  const fetcGroupInstitutionPercentage = (filter) => {
    getGroupInstitutionPercentage(filter)
      .then(
        (response) => {
          const newArr = response.data.message?.map((e) => ({
            ...e,
            total: e?.percentage,
          }));
          switch (response.data.status_code) {
            case "TAL200":
              setDataGroupInstitutionPercentage(newArr);
              break;
            default:
              dispatch(showErrorAlert("Terjadi kesalahan pada server!"));
              break;
          }
        },
        (error) => {
          console.log("error :", error);
        }
      )
      .finally((info) => {});
  };

  useEffect(() => {
    fetchTotalTalentToday();
  }, [dispatch]);

  useEffect(() => {
    fetchTotalTalent(filter);
    fetchStatistikPendaftaran(filter);
    fetcGroupInstitution(filter);
    fetcGroupInstitutionPercentage(filter);
  }, [filter]);

  const linkToTalentTerdaftar = () => {
    navigate("/trainer/talent-terdaftar");
  };

  const linkToTalentBulanIni = () => {
    navigate("/trainer/master-talent  ");
  };

  const statistikPendaftaran = () => {
    return (
      <Col xs={6} className="mt-2">
        <Container className="p-0 w-100 h-100">
          <Row>
            <Col xs={12} className="f-row-between">
              <span style={{ fontWeight: 700, fontSize: 16 }}>
                Statistik Pendaftaran
              </span>
              <Form.Group>
                <Form.Select
                  style={{
                    backgroundColor: "#F6FBFF",
                    color: "#015394",
                    fontWeight: "bold",
                    border: "none",
                    borderRadius: 12,
                    fontSize: 14,
                    cursor: "pointer",
                  }}
                  onChange={(event) => setFilter(event.target.value)}
                >
                  {options?.map((d) => {
                    return <option value={d.value}>{d?.label}</option>;
                  })}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col xs={12} className="mt-1">
              <BarChart filter={filter} datas={dataStatistikPendaftaran} />
            </Col>
          </Row>
        </Container>
      </Col>
    );
  };

  const totalTalentJoin = () => {
    return (
      <div className="py-2">
        <Card
          className="h-100 w-100 flex justify-content-center px-2 py-4"
          style={{ cursor: "pointer" }}
          onClick={linkToTalentTerdaftar}
        >
          <strong className="fsz-075">Total Talent Join</strong>
          <span style={{ fontSize: 12, color: "#8D94A0" }}>
            <strong
              style={{
                fontSize: 20,
                fontWeight: 600,
                color: "#0078D7",
                marginRight: 5,
              }}
            >
              {dataTotalTalentJoin}
            </strong>
            Pelamar
          </span>
        </Card>
      </div>
    );
  };

  const totalTalentJoinHariIni = () => {
    return (
      <div className="py-2">
        <Card
          className="h-100 w-100 flex justify-content-center px-2 py-4"
          style={{ cursor: "pointer" }}
          onClick={linkToTalentTerdaftar}
        >
          <strong className="fsz-075">Talent Join Hari ini "{today}"</strong>
          <span style={{ fontSize: 12, color: "#8D94A0" }}>
            <strong
              style={{
                fontSize: 20,
                fontWeight: 600,
                color: "#16C098",
                marginRight: 5,
              }}
            >
              {dataTotalTalentJoinToday || "0"}
            </strong>
            Pelamar
          </span>
        </Card>
      </div>
    );
  };


  const statistikLembaga = () => {
    return (
      <div className="py-2">
        <Card className="h-100 w-100 px-2 py-3">
          <Container className="p-0">
            <Row>
              <Col xs={7} className="f-column-between">
                <strong className="fsz-075" style={{ color: "#586A84" }}>
                  Lembaga
                </strong>
                <div>
                  {dataGroupInstitution.length > 0 ? (
                    <>
                      {dataGroupInstitution.map((d, i) => {
                        return (
                          <div className="d-flex flex-row align-items-center">
                            <div
                              style={{
                                width: 10,
                                height: 10,
                                background: bgColor[i],
                                marginRight: 5,
                              }}
                            />
                            <span style={{ fontSize: 11 }}>
                              {d?.label} : <strong>{d.total}</strong>
                            </span>
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    <strong style={{ color: "#f03c32" }}>Data not found</strong>
                  )}
                </div>
                <strong
                  style={{
                    fontSize: 12,
                    textDecorationLine: "underline",
                    color: "#0078D7",
                    cursor: "pointer",
                  }}
                  onClick={linkToTalentTerdaftar}
                >
                  Lihat Detail
                </strong>
              </Col>
              <Col xs={5}>
                <DoughnutChart
                  filter={filter}
                  datas={dataGroupInstitution}
                  bgColor={bgColor}
                />
              </Col>
            </Row>
          </Container>
        </Card>
      </div>
    );
  };

  const presentaseLembaga = () => {
    return (
      <div className="py-2">
        <Card className="h-100 w-100 px-2 py-3">
          <Container className="p-0">
            <Row>
              <Col xs={7} className="f-column-between">
                <strong className="fsz-075" style={{ color: "#586A84" }}>
                  Presentase Lembaga
                </strong>
                <div>
                  {dataGroupInstitutionPercentage.length > 0 ? (
                    <>
                      {dataGroupInstitutionPercentage.map((d, i) => {
                        return (
                          <div className="d-flex flex-row align-items-center">
                            <div
                              style={{
                                width: 10,
                                height: 10,
                                background: bgColor2[i],
                                marginRight: 5,
                              }}
                            />
                            <span style={{ fontSize: 11 }}>
                              {d?.label} : <strong>{d.total} %</strong>
                            </span>
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    <strong style={{ color: "#f03c32" }}>Data not found</strong>
                  )}
                </div>
                <strong
                  style={{
                    fontSize: 12,
                    textDecorationLine: "underline",
                    color: "#0078D7",
                    cursor: "pointer",
                  }}
                  onClick={linkToTalentTerdaftar}
                >
                  Lihat Detail
                </strong>
              </Col>
              <Col xs={5}>
                <DoughnutChart
                  filter={filter}
                  datas={dataGroupInstitutionPercentage}
                  bgColor={bgColor2}
                />
              </Col>
            </Row>
          </Container>
        </Card>
      </div>
    );
  };

  return (
    <Card>
      <Container fluid className="p-3">
        <Row className="h-100">
          <Col xs={12} style={{ fontWeight: 700, fontSize: 20 }}>
            Selamat Datang, Admin
          </Col>
          <Col xs={12} style={{ color: "#8D94A0", fontSize: 14 }}>
            Dashboard
          </Col>
          {statistikPendaftaran()}
          <Col xs={6} className="p-0 py-4">
            <Container className="h-100 py-3 ps-0 pe-4">
              <Row className="h-100">
                {/* <Col xs={3} className="py-2 pe-1 f-column-between">
                </Col> */}
                {/* <Col xs={9} className="py-2 pe-1 f-column-between"> */}
                  <Row>
                    <Col>{totalTalentJoinHariIni()}</Col>
                    <Col>{totalTalentJoin()}</Col>
                  </Row>
                  <Row>
                    <Col xs={6}>{statistikLembaga()}</Col>
                    <Col xs={6}>{presentaseLembaga()}</Col>
                  </Row>
                {/* </Col> */}
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </Card>
  );
}

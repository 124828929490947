import React, { useState } from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import { default as PembahasanPopup } from './PembahasanPopup';

export default function Pembahasan({ jawabanBenar, pembahasan, jawabanDipilih, referensiSoal, imageUrl }) {
    const [showDetail, setShowDetail] = useState(false);

    return (
        <>
            <PembahasanPopup
                show={showDetail}
                setShow={setShowDetail}
                imageUrl={imageUrl}
            />
            <Container fluid id='pembahasan-soal'>
                <Row><Col className='pembahasan-hr'><hr /></Col></Row>
                <Row>
                    <Col className='d-flex justify-content-center pembahasan-title'><b>Pembahasan</b></Col>
                </Row>
                <Row className="jawaban-row">
                    <Col className='jawaban-benar-col'>Jawaban yang benar adalah {jawabanBenar}</Col>
                </Row>
                <Row className="image-pembahasan-row mb-3">
                    <Col className='image-pembahasan-col d-flex justify-content-center'
                        style={{ cursor: 'pointer' }}
                        onClick={e => {
                            setShowDetail(true);
                        }}>
                        <Image className="h-100 pembahasan-image" src={imageUrl} />
                    </Col>
                </Row>
                <Row>
                    <Col className='pembahasan-col'>{pembahasan}</Col>
                </Row>
                <Row className="jawaban-row">
                    <Col
                        style={jawabanBenar === jawabanDipilih ? { color: 'green' } : { color: 'red' }}
                        hidden={!jawabanDipilih}
                    >
                        Jawaban Kamu adalah {jawabanDipilih}
                    </Col>
                </Row>
                <Row>
                    <Col>Kamu bisa mempelajari soal tersebut lebih lanjut pada materi berikut:</Col>
                </Row>
                <Row>
                    <Col className='referensi-col'><a href={referensiSoal}>{referensiSoal}</a></Col>
                </Row>
            </Container>
        </>
    )
}

import React from 'react';

import {
    useTable,
    useSortBy,
} from 'react-table';

import {
    Table as BTable,
    Container,
    Row,
} from 'react-bootstrap';

import { useDispatch } from 'react-redux';

import {
    faCaretDown,
    faCaretUp,
    faSort
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import uuid from 'react-uuid';


function Table({ title = 'Digital Bootcamp 79', data, columns, rowClickFunction }) {
    const dispatch = useDispatch();
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({
        columns,
        data,
    },
        useSortBy,
    );

    return (
        <>
            <Container fluid>
                <Row>
                    <BTable {...getTableProps()} className='table-borderless'>
                        <thead>
                            {headerGroups.map(headerGroup => (
                                <tr key={uuid()} {...headerGroup.getHeaderGroupProps()} >
                                    {headerGroup.headers.map(column => (
                                        <th key={uuid()}
                                            {...column.getHeaderProps(column.getSortByToggleProps(),{
                                                style:{with:column.width}
                                            })}
                                        >
                                            {column.render('Header')}
                                            <span className='ms-2'>
                                                {
                                                    column.canSort
                                                        ? column.isSorted
                                                            ? column.isSortedDesc
                                                                ? <FontAwesomeIcon icon={faCaretUp} size='xs' />
                                                                : <FontAwesomeIcon icon={faCaretDown} size='xs' />
                                                            : <FontAwesomeIcon icon={faSort} size='xs' />
                                                        : ' '
                                                }
                                            </span>
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {rows.map((row, i) => {
                                prepareRow(row);
                                return (
                                    <tr key={uuid()} {...row.getRowProps()}>
                                        {row.cells.map(cell => {
                                            return <td {...cell.getCellProps()} className='align-middle'
                                                onClick={() => {
                                                    if (cell.column.id === 'aksi') return;
                                                    rowClickFunction(row.original);
                                                }}
                                                key={uuid()}
                                            >{cell.render('Cell')}</td>
                                        })}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </BTable>
                </Row>
            </Container>
        </>
    );
}

export default Table;

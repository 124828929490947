import axios from "axios";
import { trainer_masterlembaga_endpoint, trainer_allProvince_endpoint, trainer_allCity_endpoint } from "../../app";
import { authHeader } from "./AuthHeader";

export const getAllMasterLembaga = ({ keywordSearch }) => {
  return axios({
    url: trainer_masterlembaga_endpoint,
    method: "GET",
    headers: { "Content-type": "application/json", ...authHeader() },
    params: {
      keywordSearch,
    },
  }).then((response) => {
    return response;
  });
};

export const getAllProvince = ({ provinceId }) => {
  return axios({
    url: trainer_allProvince_endpoint,
    method: "GET",
    headers: { "Content-type": "application/json", ...authHeader() },
    params: {
      provinceId,
    },
  }).then((response) => {
    return response;
  });
};

export const getAllCity = () => {
  return axios({
    url: trainer_allCity_endpoint,
    method: "GET",
    headers: { "Content-type": "application/json", ...authHeader() },
  }).then((response) => {
    return response;
  });
};

export const createMasterLembaga = (body) => {
  return axios({
    url: trainer_masterlembaga_endpoint,
    method: "POST",
    headers: { "Content-type": "application/json", ...authHeader() },
    data: body,
  }).then((response) => {
    console.log("Create Master Lembaga Result :", response.data);
    return response;
  });
};

export const updateMasterLembaga = (body) => {
  return axios({
    url: trainer_masterlembaga_endpoint,
    method: "PUT",
    headers: { "Content-type": "application/json", ...authHeader() },
    data: body,
  }).then((response) => {
    console.log("Create Master Lembaga Result :", response.data);
    return response;
  });
};

export const deleteMasterLembaga = (body) => {
  return axios({
    url: trainer_masterlembaga_endpoint,
    method: "DELETE",
    headers: { "Content-type": "application/json", ...authHeader() },
    data: body,
  }).then((response) => {
    console.log("Delete Master Lembaga Result :", response.data);
    return response;
  });
};

import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const columns = (isMasterChecked, handleMasterCheckboxChange, handleRowCheckboxChange, selectedRows, editFunction, deleteFunction) => [
  {
    Header: () => (
      <input
          type="checkbox"
          checked={isMasterChecked}
          onChange={handleMasterCheckboxChange}
        />
    ),
    width: 50,
    accessor: "check",
    disableSortBy: true,
    Cell: ({ row }) => ( 
      <input
          key={row.id}
          type="checkbox"
          checked={selectedRows.some((r) => r.id === row.original.id)}
          onChange={() => handleRowCheckboxChange(row.original)}
        />
    ),
  },
  {
    Header: "Nama",
    accessor: "nama",
  },
  {
    Header: "Lembaga",
    accessor: "nama_lembaga",
  },
  {
    Header: "Tipe Lembaga",
    accessor: "nama_tipe_lembaga",
  },
  {
    Header: "Email",
    accessor: "email_talent",
  },
  {
    Header: "Nomor Telepon",
    accessor: "nomor_wa_talent",
  },
  {
    Header: "Masuk Kelas Arutala",
    accessor: "siap_masuk_bulan",
  },
  {
    Header: "Durasi",
    accessor: "duration",
    Cell: (row) => {
      let progress = row.row.original.progress;
      let bab = progress?.bab;
      return (
        <div className="d-flex flex-column">
          {progress?.map((d, i) => {
            return (
              <div
                className="d-flex flex-row align-items-center py-1"
                style={{ whiteSpace: "nowrap", justifyContent: "center" }}
              >
                <span style={{ height: 26, textAlign: "center" }}>
                  {d?.duration || "-"}
                </span>
              </div>
            );
          })}
        </div>
      );
    },
  },
  {
    Header: "Progress",
    accessor: "progress",
    width: 500,
    Cell: (row) => {
      let progress = row.row.original.progress;
      let bab = progress?.bab;
      return (
        <div className="d-flex flex-column">
          {/* {bab?.map((d, i) => {
            return (
              <div
                className="d-flex flex-row align-items-center px-1"
                style={{ fontSize: 12, color: "#fff" }}
              >
                {d.subbab.length === 0
                  ? renderBab(d, i)
                  : d?.subbab?.map((e, j) => renderSubBab(d, i, e, j))}
              </div>
            );
          })} */}
          {progress?.map((d, i) => {
            return (
              <div
                className="d-flex flex-row align-items-center py-1"
                style={{ whiteSpace: "nowrap" }}
              >
                <span
                  style={{ minWidth: 40, textAlign: "right", marginRight: 10 }}
                >
                  {d?.percentage} %
                </span>
                <strong>{d?.nama_topik}</strong>
                {d?.subbab?.map((e, j) => {
                  return (
                    <div
                      className="mx-1"
                      style={{
                        padding: "3px 10px 3px 10px",
                        border: "1px solid #fff",
                        background: e?.done ? "#16C098" : "#D9D9D9",
                        borderRadius: 20,
                        color: e?.done ? "#fff" : "#BCBCBC",
                        fontSize: 12,
                      }}
                    >
                      <span className="me-1">
                        <FontAwesomeIcon icon={faCheckCircle} size="md" />
                      </span>
                      {e.label}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      );
    },
  },
];

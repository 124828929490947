import React, { useEffect } from 'react';
import { Stack, Row, Col } from 'react-bootstrap';
import { MdLibraryBooks, MdSchedule } from 'react-icons/md';
import { Timeline, TimelineHeader } from '../../common/timeline';

function SubbabTimeline({ bab, subbabList = [], setIdSubbab }) {
    const title = bab?.nama;
    const tag = bab?.tag;
    let subbabIsUnlockedCount = 0;
    // const subbabTotalDuration = 0;
    // const subbabUnlockedDuration = 0;

    useEffect(() => {
        subbabList.forEach((item) => {
            if (item.isUnlocked) {
                subbabIsUnlockedCount++;
                // subbabUnlockedDuration += parseInt(item.durasi, 10);
            }
            // subbabTotalDuration += parseInt(item.durasi, 10);
        })
    }, [subbabIsUnlockedCount, subbabList]);

    const colorMap = {
        primary: '#757575',
        secondary: '#263238',
        tertiary: '#979797',
    };

    const styleMap = {
        '--border-color-acordion': '#263238',
    };

    const timelineHeaderItems = [{
        icon: <MdLibraryBooks className='me-2 timeline-header-icon' style={{ color: colorMap.primary }} />,
        label: <span style={{ color: colorMap.secondary }}>
            <span style={{ color: colorMap.tertiary }}>{subbabIsUnlockedCount}</span>&nbsp;
            <span className='fw-bold'>/ {subbabList.length} Sub-Bab</span>
        </span>
    }, {
        icon: <MdLibraryBooks className='me-2 timeline-header-icon' style={{ color: colorMap.primary }} />,
        label: <span style={{ color: colorMap.secondary }}>
            <span style={{ color: colorMap.tertiary }}>{(bab?.kuisSelesai || bab?.kuisSelesai === 0) ? bab?.kuisSelesai : "-"}</span>&nbsp;
            <span className='fw-bold'>/ {(bab?.totalKuis || bab?.totalKuis === 0) ? bab?.totalKuis : "-"} Kuis</span>
        </span>
    }, {
        icon: <MdSchedule className='me-2 timeline-header-icon' style={{ color: colorMap.primary }} />,
        label: <span style={{ color: colorMap.secondary }}>
            <span style={{ color: colorMap.tertiary }}>{(bab?.durasiVideoDitonton || bab?.durasiVideoDitonton === 0)? Math.ceil(bab?.durasiVideoDitonton/60) : "-"}</span>&nbsp;
            <span className='fw-bold'>/ {(bab?.totalDurasiVideo || bab?.totalDurasiVideo === 0) ? Math.ceil(bab?.totalDurasiVideo/60) : "-"} Menit</span>
        </span>
    },];

    const timelineItems = subbabList.map((subbab, index) => (
        {
            name: subbab.nama,
            active: subbab.isUnlocked,
            last: subbab.id === bab?.terakhirDipelajari,
            onClick: () => setIdSubbab(subbab.id)
        }
    ));

    return (
        <div className='mb-3 w-100 bg-white subbab-timeline'>
            <Row>
                <Col>
                    <Stack style={{ color: colorMap.secondary }}>
                        <span className='fw-bold'>{title}</span>
                        <span className='fw-light fsz-075'>Tag : {tag}</span>
                    </Stack>
                </Col>
            </Row>
            <Row>
                <Col>
                    <TimelineHeader items={timelineHeaderItems} />
                    <Timeline items={timelineItems} />
                </Col>
            </Row>
        </div>
    );
}

export default SubbabTimeline;

import React from 'react';
import { Button } from 'react-bootstrap';

function Timeline({ isLastSeen, showName = true, items = [], isVertical = false }) {
    const totalItems = items.length;
    const numberOfActiveItems = items.filter(item => item.active).length;
    const progressBar = totalItems > 1 ?
        numberOfActiveItems > 0 ? (numberOfActiveItems - 1) / (totalItems - 1) * 100 : 0 : 0;

    const horizontalStyle = {
        '--transform-content': 'translateX(-50%)',
        '--items-position': 'relative',
        '--load-width': 'auto',
        '--load-height': '0.625rem',
        '--margin': '3.5rem 4rem 3.5rem 4rem',
        '--margin-items': '-1.25rem -1rem 0 -1rem',
        '--flex-direction': 'row',
        '--progress-bar-width': `${progressBar}%`,
        '--progress-bar-height': '100%',
        '--items-height': 'auto',
        '--justify-content-items': `${totalItems > 1 ? 'space-between' : 'space-around'}`,
    }

    const verticalStyle = {
        '--transform-content': 'translate(2rem, 85%)',
        '--items-top': '-1rem',
        '--items-position': 'absolute',
        '--load-width': '0.625rem',
        '--load-height': `${totalItems > 1 ? (totalItems - 1) * 5 : 2}rem`,
        '--margin': '1.5rem 2rem 1.5rem 2rem',
        '--margin-items': `0 -0.6rem 0 -0.6rem`,
        '--flex-direction': 'column',
        '--progress-bar-width': '100%',
        '--progress-bar-height': `${progressBar}%`,
        '--items-height': `${(totalItems - 1) * 5 + 2}rem`,
        '--justify-content-items': 'space-between',
    }

    const colorNormal = {
        '--default-timeline': `${totalItems > 1 ? '#F0F0F0' : 'transparent'}`,
        '--done-timeline': `${totalItems > 1 ? '#0078D7' : 'transparent'}`,
        '--primary-timeline': '#0078D7',
        '--secondary-timeline': '#979797',
        '--tertiary-timeline': '#F0F0F0',
        '--quaternary-timeline': '#FFFFFF',
    }

    const colorLastSeen = {
        '--default-timeline': `${totalItems > 1 ? '#F0F0F0' : 'transparent'}`,
        '--done-timeline': `${totalItems > 1 ? '#5EDFFF' : 'transparent'}`,
        '--primary-timeline': '#5EDFFF',
        '--secondary-timeline': '#FFFFFF',
        '--tertiary-timeline': '#F0F0F0',
        '--quaternary-timeline': '#FFFFFF',
    }

    return (
        <>
            {items.length > 0 && <div className='timeline'
                style={{
                    ...(isLastSeen ? colorLastSeen : colorNormal),
                    ...(isVertical ? verticalStyle : horizontalStyle),
                }}
            >
                <div className='timeline-progress'></div>
                <div className='timeline-items'>
                    {items.map((item, index) => (
                        <Button key={index} variant='light'
                            onClick={() => item.onClick()}
                            className={`p-0 m-0 bg-transparent timeline-item border-0
                            ${(item.active ? ' active' : '')} ${(item.last ? ' last' : '')}`}
                            style={{ '--content': `'${index + 1}` }}
                            disabled={!item.active}
                        >
                            {showName && <div
                                className={`${index % 2 === 0 || isVertical ?
                                    'timeline-content-top' : 'timeline-content-bottom'}`}
                            >
                                {item.name}
                            </div>}
                        </Button>
                    ))}
                </div>
            </div>}
        </>
    );
}

export default Timeline;

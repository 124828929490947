import React from "react";
import './Rangkuman.css';

// Component


function RangkumanGrid({children}){
    return(
        <div className="rangkuman-grid">
            {children}
        </div>
    )
}

export default RangkumanGrid;
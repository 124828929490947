import axios from "axios";
import { talent_profile_endpoint, talent_password_endpoint } from "../../app";
import { authHeader } from "./AuthHeader";

export const getProfile = () => {
  return axios({
    url: talent_profile_endpoint,
    method: "GET",
    headers: { "Content-type": "application/json", ...authHeader() },
  }).then((response) => {
    console.log("Get Profile Result :", response.data);
    return response;
  });
};

export const editProfilTrainer = ({
  nama,
  jenisKelamin,
  email,
  noTelp,
  noWa,
  tipeLembaga,
  asalLembaga,
}) => {
  return axios({
    url: talent_profile_endpoint,
    method: "PUT",
    headers: { "Content-type": "application/json", ...authHeader() },
    data: {
      nama,
      jenisKelamin,
      email,
      noTelp,
      noWa,
      tipeLembaga,
      asalLembaga,
    },
  }).then((response) => {
    console.log("Edit Profil Result :", response.data);
    return response;
  });
};

export const changePassword = ({
  oldPassword,
  newPassword,
  newPasswordConfirmation,
}) => {
  return axios({
    url: talent_password_endpoint,
    method: "PUT",
    headers: { "Content-type": "application/json", ...authHeader() },
    data: {
      oldPassword,
      newPassword,
      newPasswordConfirmation,
    },
  }).then((response) => {
    console.log("Change Pass Result :", response.data);
    return response;
  });
};

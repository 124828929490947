import axios from 'axios';
import { base_url, port_learning, trainer_materi_endpoint } from '../../app';
import { authHeader } from './AuthHeader';
import { uploadImage } from "../../service/GCSService";
import moment from 'moment';

export const listMateriTrainer = () => {
    return axios({
        url: trainer_materi_endpoint,
        method: 'GET',
        headers: {
            'Content-type': 'application/json',
            ...authHeader(),
        },
    }).then((response) => {
        console.log('List Materi Result :', response.data);
        return response;
    });
};

export const addMateriTrainer = ({
    judul, idTopik, idBab, idSubbab, thumbnail,
    video, deskripsi, tag, skalaNilai, visibilitas, durasi
}) => {
    var current = new Date();
    const skala = parseInt(skalaNilai);
    const gambarName = `${thumbnail.name.split('.').shift()}_${moment(current).format("YYYY-MM-DD_HH'MM'SS")}.${thumbnail.name.split('.').pop()}`;
    return uploadImage({ fileName: gambarName, folderName: 'thumbnail-materi', file: thumbnail }).then(
        (response) => {
            return axios({
                url: trainer_materi_endpoint,
                method: 'POST',
                headers: {
                    'Content-type': 'application/json',
                    ...authHeader(),
                },
                data: {
                    judul, idTopik, idBab, idSubbab, thumbnail: response.urlImage,
                    video, deskripsi, tag, skalaNilai: skala , visibilitas, durasi
                }
            }).then((response) => {
                console.log('Add Materi Result :', response.data);
                return response;
            });
        },
        (error) => {
            console.log(error)
            return null;
        }
    );
};

export const editMateriTrainer = ({
    id, judul, idTopik, idBab, idSubbab, thumbnail,
    video, deskripsi, tag, skalaNilai, visibilitas, durasi
}) => {
    var current = new Date();
    let urlGambar = '';
    const skala = parseInt(skalaNilai);
    let promises = [];
    if (thumbnail) {
        const gambarName = `${thumbnail.name.split('.').shift()}_${moment(current).format("YYYY-MM-DD_HH'MM'SS")}.${thumbnail.name.split('.').pop()}`;
        promises.push(
            uploadImage({ fileName: gambarName, folderName: 'thumbnail-materi', file: thumbnail }).then(
                (response) => {
                    urlGambar = response.urlImage;
                },
                (error) => {
                    console.log(error)
                    return null;
                }
            ));
    }

    return Promise.all(promises).then(
        () => {
            return axios({
                url: trainer_materi_endpoint,
                method: 'PUT',
                headers: {
                    'Content-type': 'application/json',
                    ...authHeader(),
                },
                data: {
                    id, judul, idTopik, idBab, idSubbab, thumbnail: urlGambar,
                    video, deskripsi, tag, skalaNilai: skala, visibilitas, durasi
                }
            }).then((response) => {
                console.log('Edit Materi Result :', response.data);
                return response;
            })
        });
};

export const deleteMateriTrainer = ({ id }) => {
    return axios({
        url: trainer_materi_endpoint,
        method: 'DELETE',
        headers: {
            'Content-type': 'application/json',
            ...authHeader(),
        },
        data: {
            id,
        },
    }).then((response) => {
        console.log('Delete Materi Result :', response.data);
        return response;
    });
};

import React, { useEffect, useMemo } from "react";

import "./Table.css";

import {
  useGlobalFilter, usePagination, useSortBy, useTable
} from "react-table";
// import { data } from './dummy';
import {
  Table as BTable,
  Button, Col, Container, Form,
  InputGroup, OverlayTrigger, Pagination, Popover, Row
} from "react-bootstrap";

import _ from "lodash";
import { useDispatch } from "react-redux";
import { showErrorAlert } from "../../../app";

import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import {
  faCaretDown,
  faCaretUp, faCog, faDownload, faPlus, faPlusCircle, faSearch, faSort, faTimes
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import uuid from "react-uuid";

// UI Searching
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  filterValue,
  setFilterValue,
}) {
  return (
    <InputGroup>
      <Form.Control
        placeholder="Pencarian"
        className="search-input"
        value={globalFilter || ""}
        onChange={(e) => {
          setFilterValue(e.target.value);
          setGlobalFilter(e.target.value);
        }}
      />
      <InputGroup.Text
        id="btnGroupAddon"
        className="bg-white search-icon"
        onClick={() => {
          setFilterValue("");
          setGlobalFilter("");
        }}
        style={globalFilter ? { cursor: "pointer" } : {}}
      >
        {!globalFilter ? (
          <FontAwesomeIcon icon={faSearch} size="xs" />
        ) : (
          <FontAwesomeIcon size="xs" icon={faTimes} />
        )}
      </InputGroup.Text>
    </InputGroup>
  );
}

function Table({
  title = "Digital Bootcamp 79",
  data,
  columns,
  showSearch = true,
  showCustomize = true,
  showToolbar = true,
  addFunction,
  rowClickFunction,
  showAddButton = true,
  showFilter = true,
  reverseSearchFilter = false,
  subTitle,
  customSearch,
  onExport,
  onSearchChange,
  addButtonTitle,
  onAdd,
  customFilterSearch,
  filterJoinDate,
  filterLastLoginDate,
  whatsappButton,
  whatsappButtonDisabled = true,
  getItemPage = false,
}) {
  const [filter, setFilter] = React.useState("all");
  const [filterSearch, setFilterSearch] = React.useState("keywordSearch");
  const [filterValueSearch, setFilterValueSearch] = React.useState("");
  const [filterValueDate, setFilterValueDate] = React.useState({
    lastLoginFrom: "",
    lastLoginTo: "",
    joinDateFrom: "",
    joinDateTo: ""
  });
  const [filterValue, setFilterValue] = React.useState("");
  const dispatch = useDispatch();

  const ColumnFilter = (props) => {
    return (
      <Form.Select
        placeholder="Filter"
        onChange={(e) => {
          props.setFunc(e.target.value);
          setTimeout(console.log(filter), 500);
        }}
        value={filter}
      >
        <option value="all" key="all">
          All Filter
        </option>
        {columns.map(
          (data) =>
            !(
              data.accessor === "nomor" ||
              data.accessor === "aksi" ||
              data.disableFilters === true
            ) && (
              <option key={data.accessor} value={data.accessor}>
                {data.Header}
              </option>
            )
        )}
      </Form.Select>
    );
  };

  const globalFilter = (rows, columns, filterValue) => {
    return rows.filter((row) => {
      if (filter !== "all") {
        const rowValue = row.values[filter];
        return rowValue !== undefined
          ? String(rowValue)
              .toLowerCase()
              .includes(String(filterValue).toLowerCase())
          : true;
      } else {
        const rowValues = row.values;

        for (var key in rowValues) {
          if (rowValues[key] !== undefined)
            if (
              String(rowValues[key])
                .toLowerCase()
                .includes(String(filterValue).toLowerCase())
            )
              return true;
        }

        return false;
      }
    });
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,

    // Pagination
    page,
    pageOptions,
    state: { pageIndex, pageSize },
    gotoPage,
    previousPage,
    nextPage,
    onPageChange,
    setPageSize,
    canPreviousPage,
    canNextPage,
    pageCount,

    // Search
    preGlobalFilteredRows,
    setGlobalFilter,
    state,

    allColumns,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        // sortBy: [
        //     {
        //         id: 'nomor',
        //         desc: false
        //     }
        // ],
        pageSize: 5,
      },
      globalFilter,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  useEffect(() => {
    setGlobalFilter(filterValue);
  }, [data]);

  const customizeColumns = allColumns.filter((col) => !col.mustShow);
  const minCheckConstant = 3 - (allColumns.length - customizeColumns.length);
  const [checkedState, setCheckedState] = React.useState(
    new Array(customizeColumns.length).fill(true)
  );
  const [checkedCount, setCheckedCount] = React.useState(
    customizeColumns.length
  );
  const minChecked =
    customizeColumns.length < minCheckConstant
      ? customizeColumns.length
      : minCheckConstant;

  const handleChangeCustomize = (position, toggleHidden) => {
    if (checkedCount >= minChecked) {
      const updatedCheckedState = checkedState.map((item, index) => {
        if (index === position) {
          if (item) {
            setCheckedCount((prev) => prev - 1);
            if (checkedCount <= minChecked) {
              dispatch(showErrorAlert(`Jumlah kolom minimal 3!`));
              setCheckedCount((prev) => prev + 1);
              return item;
            } else {
              toggleHidden();
              return !item;
            }
          } else {
            setCheckedCount((prev) => prev + 1);
            toggleHidden();
            return !item;
          }
        } else {
          return item;
        }
      });

      setCheckedState(updatedCheckedState);
    } else {
      setCheckedState((prev) => prev);
    }
  };

  const popover = (allCol) => {
    return (
      <Popover id="popover-basic">
        <Popover.Body>
          {allCol.map((column, index) => (
            <div key={column.id}>
              <label>
                <input
                  type="checkbox"
                  checked={checkedState[index]}
                  onChange={() =>
                    handleChangeCustomize(index, column.toggleHidden)
                  }
                />{" "}
                {column.Header === "No" ? "Nomor" : column.Header}
              </label>
            </div>
          ))}
        </Popover.Body>
      </Popover>
    );
  };

  const ColumnSelect = (props) => {
    return (
      <div
      style={{ marginLeft: 10 }}>
        <OverlayTrigger
          trigger="click"
          placement="bottom"
          overlay={popover(props.data)}
        >
          <Button variant="outline-secondary" className="w-100">
            <FontAwesomeIcon icon={faCog} size="xs" /> Customize
          </Button>
        </OverlayTrigger>
      </div>
    );
  };

  function showRenderPageNumbers(pageOptions) {
    if (pageOptions) {
      let result = pageOptions.map((data) => {
        if (data < 0) return <Pagination.Ellipsis key={uuid()} />;
        return (
          <Pagination.Item
            key={uuid()}
            onClick={() => gotoPage(data)}
            active={pageIndex === data}
          >
            {data + 1}
          </Pagination.Item>
        );
      });
      return result;
    }
  }

  function showPaginationNumbers(pageNumbers) {
    let paginationNumbers = [];
    if (pageNumbers) {
      paginationNumbers.push(0);
      if (pageIndex - 2 > 0) paginationNumbers.push(-1);
      if (pageIndex - 1 > 0) paginationNumbers.push(pageIndex - 1);
      if (pageIndex !== 0 && pageIndex !== pageNumbers - 1)
        paginationNumbers.push(pageIndex);
      if (pageIndex + 1 < pageNumbers - 1)
        paginationNumbers.push(pageIndex + 1);
      if (pageIndex + 2 < pageNumbers - 1) paginationNumbers.push(-1);
      if (pageNumbers > 1) paginationNumbers.push(pageNumbers - 1);

      return showRenderPageNumbers(paginationNumbers);
    }
  }

  useEffect(() => {
    if (filterValueDate?.lastLoginFrom && filterValueDate?.lastLoginTo) {
      onSearchChange(filterValueDate, "lastLoginDate");
    }
     if (filterValueDate?.joinDateFrom && filterValueDate?.joinDateTo) {
      onSearchChange(filterValueDate, "joinDate");
     }

  }, [filterValueDate]);

  useEffect(() => {
    if(getItemPage){
      getItemPage({ pageIndex, pageSize }); 
    }
  }, [pageIndex, pageSize]); 

  const filterInput = () => {
    
  }

  const CustomSearchExport = () => {
    const debounce = useMemo(
      () =>
        _.debounce((e, filterSearch) => onSearchChange(e, filterSearch), 500),
      []
    );
    return (
      <Row>
        <Col className="d-flex flex-row align-items-center justify-content-between">
          <div></div>
          <div className="d-flex flex-row align-items-center">
            {customFilterSearch ? (
              <>
                <div className="d-flex flex-row align-items-center">
                  <strong style={{ whiteSpace: "nowrap" }}>Filter By</strong>
                  <Form.Select
                    className="mx-2"
                    style={{ fontWeight: "bold" }}
                    onChange={(e) => {
                      if(e.target.value === "keywordSearch"){
                        onSearchChange("");
                      }
                      setFilterValueSearch("");
                      setFilterValueDate({ lastLoginFrom: "", lastLoginTo: "" });
                      setFilterValueDate({ joinDateFrom: "", joinDateTo: "" });
                      setFilterSearch(e.target.value);
                    }}
                  >
                    <option value="keywordSearch">All</option>
                    {filterJoinDate && (<option value="joinDate">Join Date</option>)}
                    {filterLastLoginDate && <option value="lastLoginDate">Terakhir Login</option>}
                  </Form.Select>
                </div>
                {filterSearch !== "keywordSearch" ? (
                  <>
                    {filterSearch === "joinDate" ? (
                      <div className="form-group d-flex flex-row align-items-center">
                      <strong className="ps-2 pe-1">From</strong>
                      <input
                        type="date"
                        class="form-control"
                        placeholder="Pilih Tanggal"
                        value={filterValueDate?.joinDateFrom}
                        onChange={(event) => {
                          setFilterValueDate({
                            ...filterValueDate,
                            joinDateFrom: event.target.value,
                          });
                        }}
                        style={{ width: 175 }}
                      />
                      <strong className="ps-3 pe-1">To</strong>
                      <input
                        type="date"
                        class="form-control"
                        placeholder="Pilih Tanggal"
                        value={filterValueDate?.joinDateTo}
                        onChange={(event) => {
                          setFilterValueDate({
                            ...filterValueDate,
                            joinDateTo: event.target.value,
                          });
                        }}
                        style={{ width: 175 }}
                      />
                    </div>
                    ) : (
                      <div className="form-group d-flex flex-row align-items-center">
                    <strong className="ps-2 pe-1">From</strong>
                    <input
                      type="date"
                      class="form-control"
                      placeholder="Pilih Tanggal"
                      value={filterValueDate?.lastLoginFrom}
                      onChange={(event) => {
                        setFilterValueDate({
                          ...filterValueDate,
                          lastLoginFrom: event.target.value,
                        });
                      }}
                      style={{ width: 175 }}
                    />
                    <strong className="ps-3 pe-1">To</strong>
                    <input
                      type="date"
                      class="form-control"
                      placeholder="Pilih Tanggal"
                      value={filterValueDate?.lastLoginTo}
                      onChange={(event) => {
                        setFilterValueDate({
                          ...filterValueDate,
                          lastLoginTo: event.target.value,
                        });
                      }}
                      style={{ width: 175 }}
                    />
                  </div>
                    )}
                  </>
                ) : (
                  <div class="form-group has-search">
                    <span class="fa fa-search form-control-feedback">
                      <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
                    </span>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Search..."
                      value={filterValueSearch}
                      onChange={(event) => {
                        setFilterValueSearch(event.target.value);
                        debounce(event.target.value, filterSearch);
                      }}
                      style={{ width: 200 }}
                      onKeyPress={(event) => {
                        if (filterSearch === "kolamBesar") {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }
                      }}
                    />
                  </div>
                )}
              </>
            ) : (
              <div class="form-group has-search">
                <span class="fa fa-search form-control-feedback">
                  <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
                </span>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Search..."
                  onChange={(event) => {
                    debounce(event.target.value);
                  }}
                  style={{ width: 200 }}
                />
              </div>
            )}

            {!addButtonTitle ? (
              onExport &&
              <Button
                variant="primary"
                size="sm"
                style={{
                  fontWeight: 700,
                  borderRadius: 5,
                  padding: "7px 20px 7px 20px",
                  marginLeft: 20,
                }}
                onClick={() => onExport()}
                disabled={page?.length === 0}
              >
                <FontAwesomeIcon
                  icon={faDownload}
                  style={{ marginRight: 10 }}
                ></FontAwesomeIcon>
                Export
              </Button>
            ) : (
              <Button
                variant="primary"
                size="sm"
                style={{
                  fontWeight: 700,
                  borderRadius: 5,
                  padding: "7px 20px 7px 20px",
                  marginLeft: 20,
                }}
                onClick={() => onAdd()}
                disabled={page?.length === 0}
              >
                <FontAwesomeIcon
                  icon={faPlusCircle}
                  style={{ marginRight: 10 }}
                ></FontAwesomeIcon>
                {addButtonTitle}
              </Button>
            )}
            {/* <Col xs={3}> */}
                  {/* <div className="d-grid gap-2 position-relative"> */}
                    {showCustomize ? (
                      <ColumnSelect data={customizeColumns} />
                    ) : (
                      ""
                    )}
                  {/* </div>
            </Col> */}

            {whatsappButton ? (
              <Button 
              variant="success"
              size="sm"
                style={{
                  fontWeight: 700,
                  borderRadius: 5,
                  padding: "7px 20px 7px 20px",
                  marginLeft: 10,
                }}
                onClick={() => whatsappButton()}
                disabled={whatsappButtonDisabled}
              >
                <FontAwesomeIcon
              icon={faWhatsapp}
              fontSize={20}
            ></FontAwesomeIcon></Button>
            ) : (
              <></>
            )

            }
          </div>
        </Col>
      </Row>
    );
  };

  return (
    <>
      <Container
        className={`bg-white rounded ${showToolbar ? "p-5 mt-5" : "p-2"}`}
        style={{ minWidth: "90%" }}
      >
        <Row>
          <h4 className="col ps-0">{title}</h4>
        </Row>
        {subTitle && (
          <Row>
            <span className="col ps-0">{subTitle}</span>
          </Row>
        )}
        {showToolbar && (
          <Row className="my-2">
            <Col xs={5} className="col-5 ps-0">
              {showAddButton ? (
                <Button
                  variant="primary"
                  className="px-3"
                  onClick={() => addFunction()}
                >
                  <FontAwesomeIcon icon={faPlus} size="xs" className="me-2" />
                  Tambah
                </Button>
              ) : (
                ""
              )}
            </Col>
            <Col xs={7}>
              <Row>
                <Col className="d-flex flex-row align-items-center justify-content-between">
                  {reverseSearchFilter ? (
                    <>
                      <Col xs={3}>
                        {showFilter ? <ColumnFilter setFunc={setFilter} /> : ""}
                      </Col>
                      <Col xs={6}>
                        {showSearch ? (
                          <InputGroup>
                            <GlobalFilter
                              filterValue={filterValue}
                              setFilterValue={setFilterValue}
                              preGlobalFilteredRows={preGlobalFilteredRows}
                              globalFilter={state.globalFilter}
                              setGlobalFilter={setGlobalFilter}
                            />
                          </InputGroup>
                        ) : (
                          ""
                        )}
                      </Col>
                    </>
                  ) : (
                    <>
                      {!showFilter && <Col xs={3} />}
                      {showSearch ? (
                        <Col xs={5}>
                          <InputGroup>
                            <GlobalFilter
                              filterValue={filterValue}
                              setFilterValue={setFilterValue}
                              preGlobalFilteredRows={preGlobalFilteredRows}
                              globalFilter={state.globalFilter}
                              setGlobalFilter={setGlobalFilter}
                            />
                          </InputGroup>
                        </Col>
                      ) : (
                        ""
                      )}
                      {showFilter ? (
                        <Col xs={3}>
                          <ColumnFilter setFunc={setFilter} />
                        </Col>
                      ) : (
                        ""
                      )}
                    </>
                  )}
                  {/* <Col xs={3}>
                  <div className="d-grid gap-2 position-relative">
                    {showCustomize ? (
                      <ColumnSelect data={customizeColumns} />
                    ) : (
                      ""
                    )} */}
                    {/* {!customSearch && onExport && (
                      <Button
                        variant="primary"
                        size="sm"
                        style={{
                          fontWeight: 700,
                          borderRadius: 5,
                          padding: "7px 20px 7px 20px",
                          marginLeft: 20,
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faDownload}
                          style={{ marginRight: 10 }}
                        ></FontAwesomeIcon>
                        Export
                      </Button>
                    )} */}
                  {/* </div>
                </Col> */}
                </Col>
              </Row>
            </Col>
          </Row>
        )}
        {customSearch && CustomSearchExport()}
        <Row>
          <BTable {...getTableProps()} hover className="caption-top" responsive>
            <caption></caption>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr
                  key={uuid()}
                  {...headerGroup.getHeaderGroupProps()}
                  className="border-top"
                >
                  {headerGroup.headers.map((column) => (
                    <th
                      key={uuid()}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render("Header")}
                      <span className="ms-2">
                        {column.canSort ? (
                          column.isSorted ? (
                            column.isSortedDesc ? (
                              <FontAwesomeIcon icon={faCaretUp} size="xs" />
                            ) : (
                              <FontAwesomeIcon icon={faCaretDown} size="xs" />
                            )
                          ) : (
                            <FontAwesomeIcon icon={faSort} size="xs" />
                          )
                        ) : (
                          " "
                        )}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            {page?.length > 0 && (
              <tbody {...getTableBodyProps()}>
                {page.map((row, i) => {
                  prepareRow(row);
                  return (
                    <tr key={uuid()} {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td
                            {...cell.getCellProps()}
                            style={{
                              overflowX:
                                cell.column.Header === "Progress" && "auto",
                              maxWidth: cell.column.width,
                              cursor: "pointer",
                              wordWrap: "break-word",
                            }}
                            className="align-middle"
                            onClick={() => {
                              if (cell.column.id === "aksi") return;
                              rowClickFunction(row.original);
                            }}
                            key={uuid()}
                          >
                            {cell.render("Cell") || "-"}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            )}
          </BTable>
          {page?.length === 0 && (
            <Row>
              <Col className="d-flex justify-content-center">
                <img
                  src="/img/nodata.jpg"
                  style={{ width: 250, objectFit: "contain" }}
                />
              </Col>
            </Row>
          )}
          <Row className="justify-content-end mt-2">
            <Col xs="auto">
              <Form.Select
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                  onPageChange(0);
                }}
              >
                {[5, 10, 20, 30, 40, 50].map((size) => (
                  <option key={size} value={size}>
                    Show {size} Rows
                  </option>
                ))}
              </Form.Select>
            </Col>
            <Col xs="auto">
              <Pagination>
                <Pagination.Prev
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                />
                {showPaginationNumbers(pageCount)}
                <Pagination.Next
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                />
              </Pagination>
            </Col>
          </Row>
        </Row>
      </Container>
    </>
  );
}

export default Table;

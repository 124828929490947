import React, { useState, useEffect } from 'react';

import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Modal, Form, Row, Col } from 'react-bootstrap';

import Select from 'react-select';
import { useDispatch } from 'react-redux';
import {
    showErrorAlert, hideErrorAlert,
    showSuccessAlert, hideSuccessAlert,
} from '../../../app';

import { addKuisAkhirTrainer, editKuisAkhirTrainer } from '../../service/KuisAkhirService';

function KuisAkhirPopUp({ show, setShow, kuisAkhirData, isEdit, setRefreshData
    , topikList: topikListData
    , babList: babListData
    , subBabList: subBabListData
}) {
    const dispatch = useDispatch();

    const [id, setId] = useState();
    const [judul, setJudul] = useState();
    const [idTopik, setIdTopik] = useState();
    const [judulTopik, setJudulTopik] = useState();
    const [idBab, setIdBab] = useState();
    const [namaBab, setNamaBab] = useState();
    const [idSubbab, setIdSubbab] = useState();
    const [namaSubbab, setNamaSubbab] = useState();
    const [visibilitas, setVisibilitas] = useState();

    const [visibilitasOptions, setVisibilitasOptions] = useState([
        {
            value: 'sembunyi', label: <span>
                <FontAwesomeIcon icon={faEyeSlash} color={'#263238'} className='me-2' />Sembunyi</span>
        },
        {
            value: 'publik', label: <span>
                <FontAwesomeIcon icon={faEye} color={'#263238'} className='me-2' />Publik</span>
        },
    ]);

    useEffect(() => {
        setId(kuisAkhirData?.id || '');
        setJudul(kuisAkhirData?.judul || '');
        setIdTopik(kuisAkhirData?.idTopik || '');
        setJudulTopik(kuisAkhirData?.judulTopik || '');
        setIdBab(kuisAkhirData?.idBab || '');
        setNamaBab(kuisAkhirData?.namaBab || '');
        setIdSubbab(kuisAkhirData?.idSubbab || '');
        setNamaSubbab(kuisAkhirData?.namaSubbab || '');
        setVisibilitas(kuisAkhirData?.visibilitas || 'sembunyi');
    }, [show, kuisAkhirData]);

    const topikList = topikListData;
    const babList = babListData;
    const subBabList = subBabListData;

    const topikOptions = topikList.map(item => ({ value: item.id, label: item.judul }));
    const babOptions = babList.filter(item => item.idTopik === idTopik)
        .map(item => ({ value: item.id, label: item.nama }))
    const subBabOptions = subBabList.filter(item => item.idBab === idBab)
        .map(item => ({ value: item.id, label: item.nama }))

    const topikSelected = topikOptions.find(item => item.value === idTopik) || null;
    const babSelected = babOptions.find(item => item.value === idBab) || null;
    const subBabSelected = subBabOptions.find(item => item.value === idSubbab) || null;
    const visibilitasSelected = visibilitasOptions.find(item => item.value === visibilitas) || null;

    const handleTopikChange = ({ value, label }) => {
        setIdTopik(value);
        setJudulTopik(label);
        setIdBab();
        setNamaBab();
        setIdSubbab();
        setNamaSubbab();
    }

    const handleBabChange = ({ value, label }) => {
        setIdBab(value);
        setNamaBab(label);
        setIdSubbab();
        setNamaSubbab();
    }

    const handleSubbabChange = ({ value, label }) => {
        setIdSubbab(value);
        setNamaSubbab(label);
    }

    const handleVisibilitasChange = (value) => {
        setVisibilitas(value.value);
    }

    const judulKuisField = <Col>
        <Form.Group>
            <Form.Label>Judul Kuis <b className='asterisk'>*</b></Form.Label>
            <Form.Control placeholder='Judul Kuis'
                value={judul}
                onChange={(e) => { setJudul(e.target.value) }}
            />
        </Form.Group>
    </Col>

    const judulTopikField = <Col>
        <Form.Group>
            <Form.Label>Judul/Topik <b className='asterisk'>*</b></Form.Label>
            <Select value={topikSelected}
                noOptionsMessage={() => 'No options'}
                placeholder='Judul/Topik'
                options={topikOptions}
                onChange={handleTopikChange}
            />
        </Form.Group>
    </Col>

    const firstRowForm = <Row className='mx-0 mb-3'>
        {judulKuisField}
        {judulTopikField}
    </Row>

    const visibilitasField = <Col>
        <Form.Group>
            <Form.Label>Visibilitas <b className='asterisk'>*</b></Form.Label>
            <Select value={visibilitasSelected}
                noOptionsMessage={() => 'No options'}
                placeholder='Visibilitas'
                options={visibilitasOptions}
                onChange={handleVisibilitasChange}
            />
        </Form.Group>
    </Col>

    const namaBabField = <Col>
        <Form.Group>
            <Form.Label>Bab <b className='asterisk'>*</b></Form.Label>
            <Select value={babSelected}
                noOptionsMessage={() => 'No options'}
                placeholder='Bab'
                options={babOptions}
                onChange={handleBabChange}
                isDisabled={judulTopik ? false : true}
            />
        </Form.Group>
    </Col>



    const secondRowForm = <Row className='mx-0 mb-3'>
        {visibilitasField}
        {namaBabField}
    </Row>

    const namaSubbabField = <Col>
        <Form.Group>
            <Form.Label>Sub Bab <b className='asterisk'>*</b></Form.Label>
            <Select value={subBabSelected}
                noOptionsMessage={() => 'No options'}
                placeholder='Sub Bab'
                options={subBabOptions}
                onChange={handleSubbabChange}
                isDisabled={namaBab ? false : true}
            />
        </Form.Group>
    </Col>

    const thirdRowForm = <Row className='mx-0 mb-3'>
        <Col xs={6}></Col>
        {namaSubbabField}
    </Row>

    const addKuisAkhir = () => {
        addKuisAkhirTrainer({
            judul, idTopik, idBab,
            idSubbab, visibilitas
        }).then(
            (response) => {
                switch (response.data.status_code) {
                    case 'KAH200':
                        dispatch(showSuccessAlert('Kuis Akhir berhasil dibuat'));
                        setRefreshData(prev => prev + 1);
                        setShow(prev => !prev);
                        break;
                    case 'KAH490':
                        dispatch(showErrorAlert('Hanya bisa membuat satu kuis per subbab!'));
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('Add Kuis Akhir error :', error);
            }).finally((info) => {

            });
    }

    const editKuisAkhir = () => {
        editKuisAkhirTrainer({
            id, judul, idTopik, idBab,
            idSubbab, visibilitas
        }).then(
            (response) => {
                switch (response.data.status_code) {
                    case 'KAH200':
                        dispatch(showSuccessAlert('Kuis Akhir berhasil diubah'));
                        setRefreshData(prev => prev + 1);
                        setShow(prev => !prev);
                        break;
                    case 'KAH490':
                        dispatch(showErrorAlert('Hanya bisa membuat satu kuis per subbab!'));
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('Edit Kuis Akhir error :', error);
            }).finally((info) => {

            });
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        dispatch(hideErrorAlert());
        dispatch(hideSuccessAlert());

        if (!judul) {
            dispatch(showErrorAlert('Isi judul kuis akhir !'));
        } else if (!idTopik || !judulTopik) {
            dispatch(showErrorAlert('Pilih judul/topik akhir !'));
        } else if (!idBab || !namaBab) {
            dispatch(showErrorAlert('Pilih bab!'));
        } else if (!idSubbab || !namaSubbab) {
            dispatch(showErrorAlert('Pilih sub bab!'));
        } else if (!visibilitas) {
            dispatch(showErrorAlert('Pilih visibilitas!'));
        } else {
            if (isEdit) editKuisAkhir();
            else addKuisAkhir();
        }
    }

    return (
        <Modal show={show} onHide={() => setShow(prev => !prev)} centered size='lg'>
            <Modal.Header closeButton>
                <Modal.Title>{isEdit ? 'Edit Kuis Akhir' : 'Buat Kuis Akhir'}</Modal.Title>
            </Modal.Header>
            <Form onSubmit={(e) => handleSubmit(e)}>
                <Modal.Body>

                    {firstRowForm}
                    {secondRowForm}
                    {thirdRowForm}

                </Modal.Body>
                <Modal.Footer>
                    <Button variant='light' onClick={() => setShow(prev => !prev)}
                        style={{ color: '#0078D7', border: '1px solid #0078D7' }}
                    >
                        Batal
                    </Button>
                    <Button type='submit' variant='primary'>
                        Simpan
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}

export default KuisAkhirPopUp;

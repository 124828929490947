import './Goals.css'
import asset2100112x from '../../assets/2100-1--1@2x.png';
const Goals = ({ refContainer }) => {
  return (
    <div ref={refContainer} className="container space-container bg-white" id="0">
      <div className="row">
        <div className="col-md-5">
          <img className="goalsImage" alt="" src={asset2100112x} />
        </div>
        <div className="col-md-7 px-md-5 my-auto">
          <div className="onlineCoursesTeachTheStudeParent">
            <b className="onlineCoursesTeach">Funtastic 79 Bootcamp</b>
            <div className="paragraph1">
              Dapat melakukan proses pembelajaran dimanapun dan kapanpun.
              Dilengkapi dengan materi serta media tolak ukur kemampuan berupa
              quiz dan kasus menarik sehari-hari. Tujuan diadakannya Funtastic
              79 Bootcamp :
            </div>
          </div>
          <div className="d-flex flex-wrap my-auto mt-4 pe-md-5 parent">
            <div className="div">
              <div className="number">
                <div className="div1">1</div>
              </div>
              <div className="menciptakanPeluangPekerjaan">
                Menciptakan peluang pekerjaan baru dengan cara memberikan
                edukasi terlebih dahulu.
              </div>
            </div>
            <div className="div2">
              <div className="number1">
                <div className="div1">2</div>
              </div>
              <div className="menciptakanPeluangPekerjaan">
                Memulai sebagai user aktif dengan
                mengalokasikan/menginvestasikan waktu minimal 5 jam/hari untuk
                hasil sesuai harapan.
              </div>
            </div>
            <div className="div4">
              <div className="number2">
                <div className="div1">3</div>
              </div>
              <div className="menciptakanPeluangPekerjaan">
                Menjalankan proses pembelajaran dan penilaian rekrutmen dari
                hasil monitoring Digital Bootcamp tersebut.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Goals

import axios from "axios";
import { base_url, port_learning, trainer_rangkuman_endpoint } from "../../app";
import { uploadImage } from "../../service/GCSService";
import { authHeader } from "./AuthHeader";
import moment from 'moment';

export const listRangkumanTrainer = () => {
    return axios({
        url: trainer_rangkuman_endpoint,
        method: 'GET',
        headers: { 'Content-type': 'application/json', ...authHeader() },
    }).then((response) => {
        console.log('List Rangkuman Result :', response.data);
        return response;
    });
};

export const addRangkumanTrainer = ({ judul, idTopik, idBab, idSubbab, file, tag, visibilitas }) => {
    var current = new Date();
    let urlArr = [];
    let promises = [];
    for (let i = 0; i < file.length; i++) {
        let gambarName = `${file[i].name.split('.').shift()}_${moment(current).format("YYYY-MM-DD_HH'MM'SS")}.${file[i].name.split('.').pop()}`;
        promises.push(
            uploadImage({ fileName: gambarName, folderName: 'rangkuman', file: file[i] }).then(
                (response) => {
                    urlArr.push(response.urlImage);
                    return response;
                },
                (error) => {
                    console.log(error)
                    return null;
                }
            )
        );
    }

    return Promise.all(promises).then(
        () => {
            return axios({
                url: trainer_rangkuman_endpoint,
                method: 'POST',
                responseType: 'application/json',
                headers: {
                    'Content-type': 'multipart/form-data',
                    ...authHeader(),
                },
                data: {
                    judul, idTopik, idBab, idSubbab, file: urlArr, tag, visibilitas
                },
            }).then((response) => {
                console.log('Add Rangkuman Result :', response.data);
                return response;
            });
        });
};

export const editRangkumanTrainer = ({ id, judul, idTopik, idBab, idSubbab, file, url, tag, visibilitas }) => {
    var current = new Date();
    let urlArr = url;
    let promises = [];

    for (let i = 0; i < file.length; i++) {
        let gambarName = `${file[i].name.split('.').shift()}_${moment(current).format("YYYY-MM-DD_HH'MM'SS")}.${file[i].name.split('.').pop()}`;
        promises.push(
            uploadImage({ fileName: gambarName, folderName: 'rangkuman', file: file[i] }).then(
                (response) => {
                    urlArr.push(response.urlImage);
                    return response;
                },
                (error) => {
                    console.log(error)
                    return null;
                }
            )
        );
    }

    return Promise.all(promises).then(
        () => {
            return axios({
                url: trainer_rangkuman_endpoint,
                method: 'PUT',
                responseType: 'application/json',
                headers: {
                    'Content-type': 'multipart/form-data',
                    ...authHeader(),
                },
                data: {
                    id, judul, idTopik, idBab, idSubbab, file: urlArr, tag, visibilitas
                },
            }).then((response) => {
                console.log('Edit Rangkuman Result :', response.data);
                return response;
            });
        });
}

export const deleteRangkumanTrainer = ({ id }) => {
    return axios({
        url: trainer_rangkuman_endpoint,
        method: 'DELETE',
        headers: {
            'Content-type': 'application/json',
            ...authHeader(),
        },
        data: {
            id,
        },
    }).then((response) => {
        console.log('Delete Rangkuman Result :', response.data);
        return response;
    });
};

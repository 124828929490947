import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Image, Row } from 'react-bootstrap';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import { MdHighlightOff, MdRemoveCircleOutline, MdAccessTime } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { GetParentPath } from '../../../common';
import { Table } from '../../../trainer/common/table';
import { columns } from './columns';

export default function KuisAkhirEnding({
    subbab, nilai = 0, timeEllapsed = '00:00:00',
    jawabanBenar = 0, jawabanSalah = 0, dilewati = 0,
    setStartKuis, setEndKuis, setPembahasan, riwayatKuis,
}) {
    const parentPath = GetParentPath();
    const showScore = (jawabanBenar + jawabanSalah + dilewati > 0);
    const showRiwayat = riwayatKuis?.length > 0;

    const jenisKuis = <div className='text-center fs-4'>
        Kuis Akhir
    </div>

    const namaSubbab = <div className='text-center fsz-1125 mb-4'>
        {subbab?.nama}
    </div>

    const progressNilai = <Container style={{ width: '10rem', height: '10rem' }}>
        <CircularProgressbar value={nilai} text={`${nilai}`}
            styles={{
                path: {
                    stroke: '#34A853',
                    strokeWidth: '5',
                    strokeLinecap: 'butt',
                },
                trail: {
                    stroke: '#263238',
                    strokeWidth: '2.5',
                },
                text: {
                    fontSize: '2rem',
                    fill: '#263238',
                }
            }}
        />
    </Container>

    const jawabanBenarText = <Row>
        <Col className='d-flex align-items-center'>
            <AiOutlineCheckCircle
                color={'#34a853'}
                style={{ width: '2rem', height: '2rem' }}
            />
            <span className='fs-4 ms-2'>Jawaban Benar</span>
        </Col>
    </Row>

    const jumlahJawabanBenar = <Row>
        <Col className='d-flex align-items-center fw-bold px-3 mb-4'
            style={{ color: '#0078D7', fontSize: '2.25rem' }}
        >
            {jawabanBenar}
        </Col>
    </Row>

    const dilewatiText = <Row>
        <Col className='d-flex align-items-center'>
            <MdRemoveCircleOutline
                color={'#979797'}
                style={{ width: '2rem', height: '2rem' }}
            />
            <span className='fs-4 ms-2'>Dilewati</span>
        </Col>
    </Row>

    const jumlahDilewati = <Row>
        <Col className='d-flex align-items-center fw-bold px-3'
            style={{ color: '#0078D7', fontSize: '2.25rem' }}
        >
            {dilewati}
        </Col>
    </Row>

    const jawabanSalahText = <Row>
        <Col className='d-flex align-items-center'>
            <MdHighlightOff
                color={'#ea4335'}
                style={{ width: '2rem', height: '2rem' }}
            />
            <span className='fs-4 ms-2'>Jawaban Salah</span>
        </Col>
    </Row>

    const jumlahJawabanSalah = <Row>
        <Col className='d-flex align-items-center fw-bold px-3 mb-4'
            style={{ color: '#0078D7', fontSize: '2.25rem' }}
        >
            {jawabanSalah}
        </Col>
    </Row>

    const totalWaktu = <Row>
        <Col className='d-flex align-items-center'>
            <MdAccessTime
                color={'#fbbc04'}
                style={{ width: '2rem', height: '2rem' }}
            />
            <span className='fs-4 ms-2'>Total Waktu</span>
        </Col>
    </Row>

    const jumlahTotalWaktu = <Row>
        <Col className='d-flex align-items-center fw-bold px-3'
            style={{ color: '#0078D7', fontSize: '2.25rem' }}
        >
            {timeEllapsed}
        </Col>
    </Row>

    const ulangiTombol = <Col className='col-auto'>
        <Button variant='outline-primary'
            className='kuis-back-button'
            style={{ width: '9rem' }}
            onClick={() => setEndKuis(false)}
        >
            Ulangi
        </Button>
    </Col>

    const selesaiButton = <Col className='col-auto'>
        <Link to={`${parentPath}/subbab?idBab=${subbab.idBab}`}>
            <Button
                style={{ width: '9rem' }}
            >
                Selesai
            </Button>
        </Link>
    </Col >

    const pembahasanButton = <Col className='col-auto'>
        <Button
            style={{ width: '9rem' }}
            onClick={() => setPembahasan(true)}
        >
            Pembahasan
        </Button>
    </Col>

    return (
        <>
            <Row className='justify-content-center'
                style={{ marginTop: '12rem', color: '#263238' }}
            >
                <Col className='p-5 col-4 bg-white mx-4'
                    style={{ borderRadius: '8px' }}
                    hidden={!showScore}
                >
                    {jenisKuis}
                    {namaSubbab}
                    {progressNilai}
                    <Row className=''>
                        <Col className='col-6'>
                            {jawabanBenarText}
                            {jumlahJawabanBenar}
                            {dilewatiText}
                            {jumlahDilewati}
                        </Col>
                        <Col className='col-6'>
                            {jawabanSalahText}
                            {jumlahJawabanSalah}
                            {totalWaktu}
                            {jumlahTotalWaktu}
                        </Col>
                    </Row>
                </Col>
                <Col className='px-4 py-3 col-4 bg-white mx-4'
                    style={{ borderRadius: '8px' }}
                    hidden={!showRiwayat}
                >
                    <Table
                        data={riwayatKuis}
                        columns={columns()}
                        title='Riwayat Pengerjaan'
                        showToolbar={false}
                        rowClickFunction={() => { }}
                    />
                </Col>
            </Row>
            <Row className='justify-content-center mt-4 mb-5'>
                <Col className='col-6'>
                    <Row className='justify-content-center gap-5'>
                        {ulangiTombol}
                        {selesaiButton}
                        {pembahasanButton}
                    </Row>
                </Col>
            </Row>
        </>
    );
}

import axios from "axios";
import { talent_laporsoalkuisakhir_endpoint, talent_soalkuisakhir_endpoint } from "../../app";
import { authHeader } from "./AuthHeader";

export const listSoalKuisAkhirTalent = ({ idKuisAkhir }) => {
    return axios({
        url: talent_soalkuisakhir_endpoint,
        method: 'GET',
        headers: { 'Content-type': 'application/json', ...authHeader() },
        params: { idKuisAkhir },
    }).then((response) => {
        console.log('List Soal Kuis Akhir Result :', response.data);
        return response;
    });
};

export const laporkanSoalAkhirTalent = ({ idSoal, jenisLaporan }) => {
    return axios({
        url: talent_laporsoalkuisakhir_endpoint,
        method: 'POST',
        headers: { 'Content-type': 'application/json', ...authHeader() },
        data: {
            idSoal, jenisLaporan,
        }
    }).then((response) => {
        console.log('Lapor Soal Kuis Akhir Result :', response.data);
        return response;
    });
};

import { Button } from "react-bootstrap";
import moment from "moment";
import { faEye, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const columns = (onEdit, onDelete) => [
  {
    Header: "Nama Lembaga",
    accessor: "institution_name",
  },
  {
    Header: "Jenis Lembaga",
    accessor: "institution_type_name",
  },
  {
    Header: "Kabupaten/Kota",
    accessor: "institution_city",
  },
  {
    Header: "Provinsi",
    accessor: "institution_province",
  },
  {
    Header: "Action",
    accessor: "action",
    Cell: (row) => {
      return (
        <div className="d-flex flex-row align-items-center">
          <div
            style={{
              marginLeft: 5,
              marginRight: 5,
              padding: "5px 10px 5px 10px",
              borderRadius: 5,
              background: "#0078D7",
            }}
            onClick={() => onEdit(row.row.original)}
          >
            <FontAwesomeIcon icon={faEdit} color="#fff" />
          </div>
          <div
            style={{
              marginLeft: 5,
              marginRight: 5,
              padding: "5px 10px 5px 10px",
              borderRadius: 5,
              background: "#D72700",
            }}
            onClick={() => onDelete(row.row.original)}
          >
            <FontAwesomeIcon icon={faTrash} color="#fff" />
          </div>
        </div>
      );
    },
  },
];

import React from "react";

// Component
import { Image } from 'react-bootstrap';

function Thumb({ dataDetail, setShowDetail, setRangkumanDetail, file }) {
    return (
        <>
            <div className="d-flex flex-column justify-content-center"
                style={{ cursor: "pointer" }} onClick={e => {
                    setRangkumanDetail(file);
                    setShowDetail(true);
                }}>
                <div className="h-100">
                    <Image className="rangkuman-thumb" src={file} />
                </div>

                <span className="rangkuman-thumb-name mt-2">{dataDetail.judul}</span>
            </div>
        </>
    )
}

export default Thumb;
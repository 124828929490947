import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faTrash } from '@fortawesome/free-solid-svg-icons';

export const columns = (redownloadFunction, deleteFunction) => [
    {
        Header: 'Judul Materi',
        accessor: 'judulMateri',
    },
    {
        Header: 'Subbab',
        accessor: 'judulSubbab',
    },
    {
        Header: 'Bab',
        accessor: 'judulBab',
    },
    {
        Header: 'Topik',
        accessor: 'judulTopik',
    },
    {
        Header: 'Tanggal Download',
        accessor: 'tanggalDownloadRaw',
        Cell: (row) => {
            return (
                <>
                    {row.row.original.tanggalDownload}
                </>
            );
        }
    },
    {
        Header: 'Download Ulang',
        accessor: 'downloadUlang',
        disableSortBy: true,
        disableFilters: true,
        Cell: (row) => {
            return (
                <>
                    <Button className="p-0" variant="transparent" onClick={(e) => redownloadFunction(row.row.original)}>
                        <FontAwesomeIcon icon={faDownload} className="mx-auto" color="#0d6efd"
                            style={{ cursor: 'pointer' }}
                        />
                    </Button>
                </>
            );
        }
    },
    {
        Header: 'Hapus',
        accessor: 'hapus',
        disableSortBy: true,
        disableFilters: true,
        Cell: (row) => {
            return (
                <>
                    <Button className="p-0" variant="transparent" onClick={(e) => deleteFunction(row.row.original)}>
                        <FontAwesomeIcon icon={faTrash} color="#dc3545"
                            style={{ cursor: 'pointer' }}
                        />
                    </Button>
                </>
            );
        }
    },
];

import axios from "axios";
import { base_url, port_learning, trainer_topik_endpoint } from "../../app";
import { authHeader } from "./AuthHeader";
import { uploadImage } from "../../service/GCSService";
import moment from 'moment';

export const listTopikTrainer = () => {
    return axios({
        url: trainer_topik_endpoint,
        method: 'GET',
        headers: { 'Content-type': 'application/json', ...authHeader() },
    }).then((response) => {
        console.log('List Topik Result :', response.data);
        return response;
    });
};

export const addTopikTrainer = ({ judul, selectedFile: thumbnail, deskripsi, tingkatan, visibilitas, tag }) => {
    var current = new Date();
    const gambarName = `${thumbnail.name.split('.').shift()}_${moment(current).format("YYYY-MM-DD_HH'MM'SS")}.${thumbnail.name.split('.').pop()}`;
    return uploadImage({ fileName: gambarName, folderName: 'thumbnail-topik', file: thumbnail }).then(
        (response) => {
            return axios({
                url: trainer_topik_endpoint,
                method: 'POST',
                headers: {
                    'Content-type': 'application/json',
                    ...authHeader(),
                },
                data: {
                    judul, thumbnail: response.urlImage, deskripsi, tingkatan, visibilitas, tag,
                }
            }).then((response) => {
                console.log('Add Topik Result :', response.data);
                return response;
            });
        },
        (error) => {
            console.log(error)
            return null;
        }
    );
};

export const editTopikTrainer = ({ id, judul, selectedFile: thumbnail, deskripsi, tingkatan, visibilitas, tag }) => {
    var current = new Date();
    let urlGambar = '';
    let promises = [];
    if (thumbnail) {
        const gambarName = `${thumbnail.name.split('.').shift()}_${moment(current).format("YYYY-MM-DD_HH'MM'SS")}.${thumbnail.name.split('.').pop()}`;
        promises.push(
            uploadImage({ fileName: gambarName, folderName: 'thumbnail-topik', file: thumbnail }).then(
                (response) => {
                    urlGambar = response.urlImage;
                },
                (error) => {
                    console.log(error)
                    return null;
                }
            ));
    }
    return Promise.all(promises).then(
        () => {
            return axios({
                url: trainer_topik_endpoint,
                method: 'PUT',
                headers: {
                    'Content-type': 'application/json',
                    ...authHeader(),
                },
                data: {
                    id, judul, thumbnail: urlGambar, deskripsi, tingkatan, visibilitas, tag,
                }
            }).then((response) => {
                console.log('Edit Topik Result :', response.data);
                return response;
            })
        });
};

export const deleteTopikTrainer = ({ id }) => {
    return axios({
        url: trainer_topik_endpoint,
        method: 'DELETE',
        headers: {
            'Content-type': 'application/json',
            ...authHeader(),
        },
        data: {
            id,
        },
    }).then((response) => {
        console.log('Delete Topik Result :', response.data);
        return response;
    });
};

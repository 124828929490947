import { faPlus, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { showErrorAlert } from '../../../app';
import { listTimelineTrainer } from '../../service/TimelineService';
import TimelinePill from './TimelinePill';
import TimelinePillAdd from './TimelinePillAdd';
import TimelinePopUpDelete from './TimelineDeletePopup';
import TimelineDeleteConfirmation from './TimelineDeleteConfirmation';
import { useSearchParams } from 'react-router-dom';

function Timeline({durasi}) {
    const [searchParams, setSearchParams] = useSearchParams();
    const [idMateri, setIdMateri] = useState(searchParams.get('id'));
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const [timelineData, setTimelineData] = useState();
    const [showAdd, setShowAdd] = useState(false);
    const [showPopUpDelete, setShowPopUpDelete] = useState(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [refreshData, setRefreshData] = useState(1);

    useEffect(() => {
        setShowAdd(false);
        listTimelineTrainer({ idMateri }).then(
            (response) => {
                switch (response.data.status_code) {
                    case 'TML200':
                        setData(response.data.message || []);
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('Timeline error :', error);
            }).finally((info) => {

            });
    }, [dispatch, idMateri, refreshData]);

    const deleteFunction = (id) => {
        setTimelineData(id);
        setShowPopUpDelete(prev => !prev);
    }

    return (
        <>
            <TimelinePopUpDelete
                show={showPopUpDelete}
                setShow={setShowPopUpDelete}
                setShowDeleteConfirmation={setShowDeleteConfirmation}
            />
            <TimelineDeleteConfirmation setRefreshData={setRefreshData}
                show={showDeleteConfirmation}
                setShow={setShowDeleteConfirmation}
                timelineData={timelineData}
                setShowPopUpDelete={setShowPopUpDelete}
            />

            <Container fluid className='p-3'>
                <Button variant='primary' className='p-0 px-2 mb-2 fsz-0875'
                    onClick={() => setShowAdd(prev => !prev)}
                >
                    {!showAdd ? <FontAwesomeIcon icon={faPlus} className='me-2' /> : <FontAwesomeIcon icon={faX} className='me-2' />}
                    {!showAdd ? <span>Tambah</span> : <span>Cancel</span>}
                </Button>
                <Container fluid className='p-0' style={{ height: '21.125rem', overflowY: 'auto' }}>
                    {data.map((item, index) => (
                        <TimelinePill key={item.id} {...item} data={data} setData={setData} durasi={durasi}
                            deleteFunction={deleteFunction}
                            setRefreshData={setRefreshData} />
                    ))}
                    {showAdd && <TimelinePillAdd idMateri={idMateri} setRefreshData={setRefreshData} durasi={durasi}/>}
                </Container>
            </Container>
        </>
    );
}

export default Timeline;

import React, { useEffect, useState } from 'react';
import { Col, Form, InputGroup, Modal, Row } from 'react-bootstrap';

export default function PopupDetil({ show, setShow, akunData }) {
    const [id, setId] = useState();
    const [username, setUsername] = useState();
    const [email, setEmail] = useState();
    const [role, setRole] = useState();
    const [status, setStatus] = useState();

    useEffect(() => {
        setId(akunData?.id || '');
        setUsername(akunData?.username || '');
        setEmail(akunData?.email || '');
        setRole(akunData?.role || '');
        setStatus(akunData?.status || '');
    }, [show, akunData]);

    const usernameField = <Col>
        <Form.Group>
            <Form.Label>Username</Form.Label>
            <InputGroup>
                <Form.Label className='fsz-125'><b>{username}</b></Form.Label>
            </InputGroup>
        </Form.Group>
    </Col>

    const emailField = <Col>
        <Form.Group>
            <Form.Label>E-mail</Form.Label>
            <InputGroup>
                <Form.Label className='fsz-125'><b>{email}</b></Form.Label>
            </InputGroup>
        </Form.Group>
    </Col>

    const firstRowForm = <Row className='mx-0 mb-3'>
        {usernameField}
        {emailField}
    </Row>

    const roleField = <Col>
        <Form.Group>
            <Form.Label>Role</Form.Label>
            <div class='fsz-125-image'>
                <Form.Label className='fsz-125'><b>{role}</b></Form.Label>
            </div>
        </Form.Group>
    </Col>

    const statusField = <Col>
        <Form.Group>
            <Form.Label>Status</Form.Label>
            <div class='fsz-125-image'>
                <Form.Label className='fsz-125'><b>{status}</b></Form.Label>
            </div>
        </Form.Group>
    </Col>

    const secondRowForm = <Row className='mx-0 mb-3'>
        {roleField}
        {statusField}
    </Row>

    return (
        <Modal show={show} onHide={() => setShow(prev => !prev)} centered size='lg'>
            <Modal.Header closeButton>
                <Modal.Title>Detail Akun</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    {firstRowForm}
                    {secondRowForm}
                </Form>
            </Modal.Body>
        </Modal>
    );
}

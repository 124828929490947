import React, { useEffect } from 'react';
import { Breadcrumb, Container } from 'react-bootstrap';
import { faHouse } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch } from 'react-redux';
import Crumb from './Crumb.js';
import { setCrumbs } from '.';
import { useLocation } from 'react-router-dom';

export default function Breadcrumbs({ crumbs }) {
    return (
        <>
            {<Container fluid className="bg-light ps-4 py-3 shadow-custom">
                <Breadcrumb className="align-item-center">
                    <Breadcrumb.Item key="home" href="#"><FontAwesomeIcon icon={faHouse} /></Breadcrumb.Item>
                    <Crumb propsCrumbs={crumbs}/>
                </Breadcrumb>
            </Container>}
        </>
    )
}

import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, InputGroup, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { hideErrorAlert, showErrorAlert, showSuccessAlert } from '../../../app';
import { GetParentPath } from '../../../common';
import { lupaSandi } from '../../service/LupaSandiService';

function LupaSandiForm(props) {
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();

    const [username, setUsername] = useState(searchParams.get('u'));
    const [usernameError, setUsernameError] = useState(false);

    const parentPath = GetParentPath();
    const navigate = useNavigate();
    const location = useLocation();
    const authed = useSelector(state => state.authTalent.jsonWebToken);
    const from = location.state?.from?.pathname || `${parentPath}/topik`;
    const search = location.state?.from?.search || '';

    useEffect(() => {
        setUsernameError(false);
        dispatch(hideErrorAlert());
    }, [username, dispatch]);

    const lupaSandiFormHeader = <Container fluid className='p-0 login-form-header'>
        <h4 style={{textAlign:"center"}}><b>Lupa Kata Sandi</b></h4>
    </Container>

    const usernameField = <Form.Group className='mb-4 w-100 fsz-075' controlId='usernameField'>
        <Form.Label>Username/E-mail :</Form.Label>
        <Form.Control placeholder='Masukkan Username/E-mail'
            type='text'
            className={`${usernameError ? 'login-form-element-error' : 'login-form-element'}`}
            value={username}
            onChange={(e) => { setUsername(e.target.value); }}
        />
    </Form.Group>

    const prosesButton =<Button variant='primary' type='submit'
            className='w-100 mb-4'
        >
            <b>Proses</b>
        </Button>

    const backButton = <Container fluid className='text-center'>
        <Link className='login-footer-link text-decoration-none' to={`${parentPath}/login`}>
            <Button variant='light'
                className='p-0 bg-transparent border-0 back-login-button fsz-075'>
                &lt;&lt; Kembali
            </Button>
        </Link>
    </Container>

    const lupaSandiProses = () => {
        lupaSandi(username).then(
            (response) => {
                switch (response.data.status_code) {
                    case 'FPL200':
                        dispatch(showSuccessAlert('Cek email untuk reset password'));
                        setUsername('');
                        break;
                    case 'FPL400':
                        dispatch(showErrorAlert('Isi username / email !'));
                        setUsernameError(true);
                        break;
                    case 'FPL401':
                        dispatch(showErrorAlert('Proses gagal: Username/Email tidak sesuai atau tidak terdaftar'));
                        setUsernameError(true);
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {

            }
        )
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        dispatch(hideErrorAlert());

        if (username === '') {
            setUsernameError(true);
            dispatch(showErrorAlert('Isi Username/E-mail!'));
        } else {
            lupaSandiProses();
        }
    }

    return (
        <Container>
            {lupaSandiFormHeader}
            <Form noValidate
                onSubmit={(e) => handleSubmit(e)}
            >
                {usernameField}
                {prosesButton}
            </Form>
            {backButton}
        </Container>
    );
}

export default LupaSandiForm;

import { faEye, faEyeSlash, faInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react';
import { Button, Modal, Form, Row, Col, OverlayTrigger, Tooltip, Stack } from 'react-bootstrap';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import ImageRow from './ImageRow';
import { useDispatch } from 'react-redux';
import { showErrorAlert, hideErrorAlert, showSuccessAlert, hideSuccessAlert } from '../../../app';
import { addRangkumanTrainer, editRangkumanTrainer } from '../../service/RangkumanService';
import { listTopikTrainer } from '../../service/TopikService';
import { listBabTrainer } from '../../service/BabService';
import { listTagTrainer } from '../../service/TagService';
import { listSubbabTrainer } from '../../service/SubbabService';

export default function RangkumanPopupTambahEdit({ show, setShow, rangkumanData, isEdit, setRefreshData }) {
    const dispatch = useDispatch();

    const [id, setId] = useState();
    const [judul, setJudul] = useState();
    const [topik, setTopik] = useState();
    const [idTopik, setIdTopik] = useState();
    const [bab, setBab] = useState();
    const [idBab, setIdBab] = useState();
    const [subbab, setSubbab] = useState();
    const [idSubbab, setIdSubbab] = useState();
    const [tag, setTag] = useState();
    const [visibilitas, setVisibilitas] = useState();
    const [file, setFile] = useState([]);
    const [url, setUrl] = useState([]);

    const [topikList, setTopikList] = useState([]);
    const [babList, setBabList] = useState([]);
    const [tagList, setTagList] = useState([]);
    const [subbabList, setSubbabList] = useState([]);

    const [imageList, setImageList] = useState([]);

    function splitUrl(url) {
        return url.split('\\').pop().split('/').pop();
    }

    useEffect(() => {
        setId(rangkumanData?.id || '');
        setJudul(rangkumanData?.judul || '');
        setTopik(rangkumanData?.judulTopik || '');
        setIdTopik(rangkumanData?.idTopik || '');
        setBab(rangkumanData?.namaBab || '');
        setIdBab(rangkumanData?.idBab || '');
        setSubbab(rangkumanData?.namaSubbab || '');
        setIdSubbab(rangkumanData?.idSubbab || '');
        setTag(rangkumanData?.tag || '');
        setVisibilitas(rangkumanData?.visibilitas || 'sembunyi');
        setUrl(rangkumanData?.file || []);
        setFile([]);
    }, [show, rangkumanData]);

    useEffect(() => {
        setImageList(url?.map((item) => ({ value: item, label: item })) || []);
    }, [url])

    useEffect(() => {
        const listTopikHit = () => {
            listTopikTrainer().then(
                (response) => {
                    switch (response.data.status_code) {
                        case 'TPK200':
                            setTopikList(response.data.message);
                            break;
                        default:
                            dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                            break;
                    }
                },
                (error) => {
                    console.log('List Topik error :', error);
                })
        }
        listTopikHit();
    }, [dispatch]);

    useEffect(() => {
        const listTagHit = () => {
            listTagTrainer().then(
                (response) => {
                    switch (response.data.status_code) {
                        case 'TAG200':
                            setTagList(response.data.message);
                            break;
                        default:
                            dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                            break;
                    }
                },
                (error) => {
                    console.log('List Tag error :', error);
                })
        }
        listTagHit();
    }, [dispatch]);

    useEffect(() => {
        const listBabHit = () => {
            listBabTrainer().then(
                (response) => {
                    switch (response.data.status_code) {
                        case 'BAB200':
                            setBabList(response.data.message);
                            break;
                        default:
                            dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                            break;
                    }
                },
                (error) => {
                    console.log('List Bab error :', error);
                })
        }
        listBabHit();
    }, [dispatch]);

    useEffect(() => {
        listSubbabTrainer().then(
            (response) => {
                switch (response.data.status_code) {
                    case 'SBB200':
                        setSubbabList(response.data.message);
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('List Subbab error :', error);
            }).finally((info) => {

            });
    }, [dispatch]);

    const tagOptions = tagList.map(item => ({ value: item, label: item }));
    const tagSelected = tag ? tag?.split(',').map(item => ({ value: item.trim(), label: item.trim() })) : null;

    const topikOptions = topikList.map(topik => ({ value: topik.id, label: topik.judul }));
    const topikSelected = topikOptions.find(item => item.value === idTopik) || null;

    const babOptions = babList.filter(item => item.idTopik === idTopik)
        .map(bab => ({ value: bab.id, label: bab.nama }));
    const babSelected = babOptions.find(item => item.value === idBab) || null;

    const subbabOptions = subbabList.filter(item => item.idBab === idBab)
        .map(subbab => ({ value: subbab.id, label: subbab.nama }));
    const subbabSelected = subbabOptions.find(item => item.value === idSubbab) || null;

    const visibilitasList = [
        {
            value: 'sembunyi', label: <span>
                <FontAwesomeIcon icon={faEyeSlash} color={'#263238'} className='me-2' />Sembunyi</span>
        },
        {
            value: 'publik', label: <span>
                <FontAwesomeIcon icon={faEye} color={'#263238'} className='me-2' />Publik</span>
        },
    ];
    const visibilitasSelected = visibilitasList.find(item => item.value === visibilitas) || null;

    const removeImage = (index) => {
        var url_length = url.length;
        if (index < url_length) {
            const newUrl = url.slice();
            newUrl.splice(index, 1);
            setUrl(newUrl);
        } else {
            const newFile = file.slice();
            newFile.splice(index - url_length, 1);
            setFile(newFile);
        }
        const newImageList = imageList.slice();
        newImageList.splice(index, 1);
        setImageList(newImageList);
    }

    const handleTopikChange = ({ value, label }) => {
        setIdTopik(value);
        setTopik(label);
        setIdBab();
        setBab();
    }

    const isOnlyLetterAndSpace = (str) => {
        const re = new RegExp(/^[A-Za-z\s]*$/);
        return re.test(str);
    }

    const handleTagChange = (tags) => {
        for (const tag of tags) {
            if (!isOnlyLetterAndSpace(tag.value)) {
                dispatch(showErrorAlert('Tag hanya boleh mengandung huruf dan spasi!'));
                return;
            }
        }
        setTag(tags.map(tag => tag.value).join(', '));
    }

    const handleBabChange = (value) => {
        setIdBab(value.value);
        setBab(value.label);
        setIdSubbab();
        setSubbab();
    }

    const handleVisibilitasChange = (value) => {
        setVisibilitas(value.value);
    }

    const handleSubbabChange = (value) => {
        setIdSubbab(value.value);
        setSubbab(value.label);
    }

    const handleUploadChange = (e) => {
        const files = Array.from(e.target.files);
        var oversize = false;
        var wrongFormat = false;

        files.map((file) => {
            if (file.size > 2097152) {
                oversize = true;
            } else if (file && file?.name &&
                !['png', 'PNG', 'jpg', 'JPG', 'jpeg', 'JPEG'].includes(file?.name?.split('.').pop())) {
                wrongFormat = true
            }
            return file;
        })

        if (oversize) {
            dispatch(showErrorAlert('File size exceeds 2mb!'));
        } else if (wrongFormat) {
            dispatch(showErrorAlert('Thumbnail harus berekstensi .png, .jpg atau .jpeg!'));
        } else {
            setFile(prev => [...prev, e.target.files[0]]);
            const res = [];
            const filesLength = files.length;
            const i = 0;
            files.map((file) => {
                var temp = URL.createObjectURL(file)
                res.push({ value: temp, label: file.name });
                return file;
            })
            const newImageList = [...imageList.slice(), ...res];
            setImageList(newImageList);
        }
    }

    const addRangkuman = () => {
        addRangkumanTrainer({
            judul, idTopik, idBab, idSubbab, file, tag, visibilitas
        }).then(
            (response) => {
                switch (response.data.status_code) {
                    case 'RKN200':
                        dispatch(showSuccessAlert('Rangkuman berhasil dibuat'));
                        setRefreshData(prev => prev + 1);
                        setShow(prev => !prev);
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('Add Rangkuman error :', error);
            }).finally((info) => {

            });
    }

    const editRangkuman = () => {
        editRangkumanTrainer({
            id, judul, idTopik, idBab, idSubbab, file, url, tag, visibilitas
        }).then(
            (response) => {
                switch (response.data.status_code) {
                    case 'RKN200':
                        dispatch(showSuccessAlert('Rangkuman berhasil diubah'));
                        setRefreshData(prev => prev + 1);
                        setShow(prev => !prev);
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('Edit Rangkuman error :', error);
            }).finally((info) => {

            });
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        dispatch(hideErrorAlert());
        dispatch(hideSuccessAlert());

        if (!judul) {
            dispatch(showErrorAlert('Isi judul!'));
        } else if (!topik) {
            dispatch(showErrorAlert('Pilih judul/topik materi!'));
        } else if (!tag) {
            dispatch(showErrorAlert('Isi tag (minimal 1 tag)!'));
        } else if (!bab) {
            dispatch(showErrorAlert('Pilih bab!'));
        } else if (!subbab) {
            dispatch(showErrorAlert('Pilih subbab!'));
        } else if (!visibilitas) {
            dispatch(showErrorAlert('Pilih visibilitas!'));
        } else if (imageList.length === 0) {
            dispatch(showErrorAlert('Tambahkan gambar rangkuman!'));
        } else {
            if (isEdit) editRangkuman();
            else addRangkuman();
        }
    }

    const judulRangkumanField = <Col>
        <Form.Group>
            <Form.Label>Judul Rangkuman <b className='asterisk'>*</b></Form.Label>
            <Form.Control id="judulRangkumanField" placeholder='Nama Rangkuman'
                value={judul}
                onChange={(e) => { setJudul(e.target.value) }}
            />
        </Form.Group>
    </Col>

    const topikField = <Col>
        <Form.Group>
            <Form.Label>Topik <b className='asterisk'>*</b></Form.Label>
            <Select
                value={topikSelected}
                noOptionsMessage={() => 'No options'}
                placeholder='Topik'
                options={topikOptions}
                onChange={handleTopikChange}
            />
        </Form.Group>
    </Col>

    const tagTooltip = <OverlayTrigger
        placement='top'
        overlay={
            <Tooltip className='tag-tooltip'>
                Mohon diisi minimal 1 tag
            </Tooltip>
        }
    >
        <Button variant='light'
            className='tag-tooltip-button bg-transparent p-0 ms-2 rounded-circle
                border border-2 border-dark d-flex align-items-center justify-content-center'
            style={{ width: '1rem', height: '1rem' }}
        >
            <FontAwesomeIcon icon={faInfo} style={{ fontSize: '0.625rem' }} />
        </Button>
    </OverlayTrigger>

    const tagField = <Col>
        <Form.Group>
            <Stack direction='horizontal' className='mb-2'>
                <Form.Label className='mb-0'>Tag <b className='asterisk'>*</b></Form.Label>
                {tagTooltip}
            </Stack>
            <CreatableSelect isMulti
                value={tagSelected}
                noOptionsMessage={() => 'No options'}
                placeholder='Tag'
                options={tagOptions}
                formatCreateLabel={(newTag) => `Buat tag '${newTag}'`}
                onChange={handleTagChange}
                getNewOptionData={(value, label) => {
                    return {
                        value: value.toUpperCase(),
                        label: label.replace(`${value}`, value.toUpperCase())
                    }
                }}
            />
        </Form.Group>
    </Col>

    const babField = <Col>
        <Form.Group>
            <Form.Label>Bab <b className='asterisk'>*</b></Form.Label>
            <Select
                value={babSelected}
                noOptionsMessage={() => 'No options'}
                placeholder='Bab'
                options={babOptions}
                onChange={handleBabChange}
                isDisabled={topik ? false : true}
            />
        </Form.Group>
    </Col>

    const visibilitasField = <Col>
        <Form.Group>
            <Form.Label>Visibilitas <b className='asterisk'>*</b></Form.Label>
            <Select
                value={visibilitasSelected}
                noOptionsMessage={() => 'No options'}
                placeholder='Visibilitas'
                options={visibilitasList}
                onChange={handleVisibilitasChange}
            />
        </Form.Group>
    </Col>

    const subbabField = <Col>
        <Form.Group>
            <Form.Label>SubBab <b className='asterisk'>*</b></Form.Label>
            <Select
                value={subbabSelected}
                noOptionsMessage={() => 'No options'}
                placeholder='SubBab'
                options={subbabOptions}
                onChange={handleSubbabChange}
                isDisabled={bab ? false : true}
            />
        </Form.Group>
    </Col>

    const uploadField = <Col>
        <Form.Group>
            <Form.Label>Upload Rangkuman <b className='asterisk'>*</b></Form.Label>
            <div className="image-upload">
                <ImageRow image={imageList} removeImage={removeImage} />
                <label htmlFor="rangkuman-upload-button">
                    <img src="/img/upload-image.png" />
                </label>
                <input id="rangkuman-upload-button" type="file" accept='.png, .jpg, .jpeg' multiple onChange={(e) => handleUploadChange(e)} />
            </div>
        </Form.Group>
    </Col>

    const firstRowForm = <Row className='mx-0 mb-3'>
        {judulRangkumanField}
        {topikField}
    </Row>

    const secondRowForm = <Row className='mx-0 mb-3'>
        {tagField}
        {babField}
    </Row>

    const thirdRowForm = <Row className='mx-0 mb-3'>
        {visibilitasField}
        {subbabField}
    </Row>

    const fourthRowForm = <Row className='mx-0 mb-3'>
        {uploadField}
    </Row>

    return (
        <Modal className="rangkuman-popup" show={show} onHide={() => setShow(prev => !prev)} centered >
            <Modal.Header closeButton>
                <Modal.Title><span>{isEdit ? 'Edit' : 'Buat'}</span> Rangkuman Digital Bootcamp 79</Modal.Title>
            </Modal.Header>
            <Form onSubmit={(e) => handleSubmit(e)}>
                <Modal.Body>
                    {firstRowForm}
                    {secondRowForm}
                    {thirdRowForm}
                    {fourthRowForm}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='light' onClick={() => setShow(prev => !prev)}
                        style={{ color: '#0078D7', border: '1px solid #0078D7' }}
                    >
                        Batal
                    </Button>
                    <Button type="submit" variant='primary'>
                        Simpan
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export const columns = (judulKuis, rataRata) => ([
    {
        Header: judulKuis,
        accessor: 'judul',
        disableSortBy: true,
    },
    {
        Header: 'Nilai',
        accessor: 'nilai',
        disableSortBy: true,
        Cell: ({ cell: { value } }) => {
            return <span className={`laporannilai-${rataRata<value
                ?'green':rataRata>value?'red':'yellow'}`} 
                style={{ fontWeight: 'bolder' }}>{value}</span>
        }
    },
    {
        Header: 'Tanggal',
        accessor: 'tanggal',
        disableSortBy: true,
    },
]);

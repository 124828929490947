import axios from 'axios';
import { talent_komentar_endpoint } from '../../app';
import { authHeader } from './AuthHeader';

export const listKomentarTalent = ({ page, idMateri }) => {
    return axios({
        url: talent_komentar_endpoint,
        method: 'GET',
        headers: { 'Content-type': 'application/json', ...authHeader() },
        params: { idMateri, page, limit: 5 }
    }).then((response) => {
        console.log('List Komentar Talent Result :', response.data);
        return response;
    });
};

export const addKomentarTalent = ({ idMateri, komentar: { message, replyTo } }) => {
    return axios({
        url: talent_komentar_endpoint,
        method: 'POST',
        headers: { 'Content-type': 'application/json', ...authHeader() },
        data: { idMateri, komentar: { message, replyTo } }
    }).then((response) => {
        console.log('Add Komentar Talent Result :', response.data);
        return response;
    });
};

import React, { useEffect, useState } from 'react';
import { Button, Container, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import 'moment/locale/id';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { GetParentPath } from '../../../common';
import { listCatatanTalent } from '../../service/CatatanService';
import { showErrorAlert } from '../../../app';
import { default as CatatanDateGrid } from './CatatanDateGrid';
import { default as CatatanPopup } from './CatatanPopup';
import { default as CatatanPopupDelete } from './CatatanPopupDelete';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';

export default function Catatan() {
    moment.locale('id');
    const dispatch = useDispatch();
    const parentPath = GetParentPath();
    const [selectedDay, setSelectedDay] = useState();
    const [catatanList, setCatatanList] = useState({});
    const [dateList, setDateList] = useState([]);
    const [refreshData, setRefreshData] = useState(1);
    const [showPopup, setShowPopup] = useState(false);
    const [showPopupDelete, setShowPopupDelete] = useState(false);
    const [thumbData, setThumbData] = useState({});
    const showDate = selectedDay ? [moment(selectedDay).format('DD MMMM YYYY')] : dateList;

    const handleDayClick = (day, { selected }) => {
        setSelectedDay(selected ? undefined : day);
    }

    useEffect(() => {
        listCatatanTalent().then(
            (response) => {
                switch (response.data.status_code) {
                    case 'CTN200':
                        var catatanDateMap = {};
                        var dateListTemp = [];
                        response.data.message.forEach(element => {
                            const tanggalElement = moment(element.tanggal, 'DD MMM YYYY').format('DD MMMM YYYY');
                            if (!catatanDateMap[tanggalElement]) {
                                dateListTemp.push(tanggalElement);
                                catatanDateMap[tanggalElement] = [];
                            }
                            catatanDateMap[tanggalElement].push(element);
                        });
                        setDateList(dateListTemp || []);
                        setCatatanList(catatanDateMap || {});
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('Catatan error :', error);
            }).finally((info) => {

            });
    }, [dispatch, refreshData]);

    return (
        <>
            <CatatanPopupDelete show={showPopupDelete} setShow={setShowPopupDelete} data={thumbData} setRefreshData={setRefreshData} />
            <CatatanPopup show={showPopup} setShow={setShowPopup} data={thumbData} setRefreshData={setRefreshData} />
            <Container id='catatan-container'>
                <Link to={`${parentPath}/topik`}>
                    <Button variant='light' className='back-bab-button bg-transparent border-0 p-0 fsz-1125 mb-2'>
                        <AiOutlineArrowLeft className='me-2' style={{ color: '#757575' }} />
                        <span className='ms-4' style={{ color: '#081F32' }}>Kembali</span>
                    </Button>
                </Link>
                <div className='catatan-title py-2'><h3>Catatan Saya</h3></div>
                <div className='catatan-datepicker py-2'>
                    <Dropdown>
                        <div className="row">
                            <Dropdown.Toggle className="text-dark d-flex justify-content-between" variant='light'>
                                {selectedDay ? moment(selectedDay).format("DD-MM-YYYY") : "Pilih Tanggal"}
                                <FontAwesomeIcon icon={faCalendar} />
                            </Dropdown.Toggle>
                            {selectedDay && <FontAwesomeIcon icon={faCircleXmark} className="col-auto my-auto text-danger"
                                onClick={e => {
                                    setSelectedDay(undefined);
                                }} />}
                        </div>

                        <Dropdown.Menu className='border-0' align="end">
                            <DayPicker
                                selectedDays={selectedDay}
                                onDayClick={(day, { selected }) => handleDayClick(day, { selected })}
                            />
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                {
                    showDate.map((item, index) => {
                        return <CatatanDateGrid key={index}
                            setShowPopup={setShowPopup}
                            setShowPopupDelete={setShowPopupDelete}
                            setThumbData={setThumbData}
                            date={item} data={catatanList[item] || []}
                        />
                    })

                }
            </Container>
        </>
    )
}

import React, { useEffect, useRef, useState } from 'react';
import { Button, Form, Row, Col, Container, InputGroup, Nav } from 'react-bootstrap';
import ReactPlayer from 'react-player';

import { useDispatch, useSelector } from 'react-redux';
import {
    showErrorAlert, hideErrorAlert,
    showSuccessAlert, hideSuccessAlert,
} from '../../../app';
import Komentar from '../komentar/Komentar';
import { Timeline } from '../timeline';

function MateriPopUpDetail({ materiData }) {
    const dispatch = useDispatch();
    const videoPlayerRef = useRef(null);
    const [activeMateriNavKey, setActiveMateriNavKey] = useState('timeline');
    const role = useSelector(state => state.auth.role);

    const [id, setId] = useState();
    const [judul, setJudul] = useState();
    const [judulTopik, setJudulTopik] = useState();
    const [namaBab, setNamaBab] = useState();
    const [namaSubbab, setNamaSubbab] = useState();
    const [video, setVideo] = useState();
    const [thumbnail, setThumbnail] = useState();
    const [deskripsi, SetDeskripsi] = useState();
    const [skalaNilai, setSkalaNilai] = useState();
    const [tag, setTag] = useState();
    const [durasi, setDurasi] = useState();

    useEffect(() => {
        setId(materiData?.id);
        setJudul(materiData?.judul);
        setJudulTopik(materiData?.judulTopik);
        setNamaBab(materiData?.namaBab);
        setNamaSubbab(materiData?.namaSubbab);
        setVideo(materiData?.video);
        setThumbnail(materiData?.thumbnail);
        SetDeskripsi(materiData?.deskripsi);
        setSkalaNilai(materiData?.skalaNilai);
        setTag(materiData?.tag);
        setDurasi(materiData?.durasi);
    }, [materiData]);

    const header = <Form.Group className='mx-0 mb-3'>
        <Form.Label className='fsz-15 mb-0 fw-bolder'>Detail Video Materi</Form.Label>
    </Form.Group>

    const judulTopikField = <Form.Group className='mx-0 mb-2'>
        <Form.Label className='fsz-0875 mb-0'>Judul/Topik Materi</Form.Label>
        <InputGroup className='fsz-125 fw-bold'>
            <Form.Label>{judulTopik}</Form.Label>
        </InputGroup>
    </Form.Group>

    const namaBabField = <Form.Group className='mx-0 mb-2'>
        <Form.Label className='fsz-0875 mb-0'>Bab</Form.Label>
        <InputGroup className='fsz-125 fw-bold'>
            <Form.Label>{namaBab}</Form.Label>
        </InputGroup>
    </Form.Group>

    const namaSubbabField = <Form.Group className='mx-0 mb-2'>
        <Form.Label className='fsz-0875 mb-0'>SubBab</Form.Label>
        <InputGroup className='fsz-125 fw-bold'>
            <Form.Label>{namaSubbab}</Form.Label>
        </InputGroup>
    </Form.Group>

    const judulField = <Form.Group className='mx-0 mb-2'>
        <Form.Label className='fsz-0875 mb-0'>Judul Video</Form.Label>
        <InputGroup className='fsz-125 fw-bold'>
            <Form.Label>{judul}</Form.Label>
        </InputGroup>
    </Form.Group>

    const deskripsiField = <Form.Group className='mx-0 mb-2'>
        <Form.Label className='fsz-0875 mb-0'>Deskripsi Video</Form.Label>
        <InputGroup className='fsz-125 fw-bold'>
            <Form.Label>{deskripsi}</Form.Label>
        </InputGroup>
    </Form.Group>

    const tagField = <Form.Group className='mx-0 mb-2'>
        <Form.Label className='fsz-0875 mb-0'>Tag</Form.Label>
        <InputGroup className='fsz-125 fw-bold'>
            <Form.Label>{tag}</Form.Label>
        </InputGroup>
    </Form.Group>

    const skalaNilaiField = <Form.Group className='mx-0 mb-2'>
        <Form.Label className='fsz-0875 mb-0'>Skala Nilai</Form.Label>
        <InputGroup className='fsz-125 fw-bold'>
            <Form.Label>{skalaNilai}</Form.Label>
        </InputGroup>
    </Form.Group>

    const videoPlayer = <Container fluid className='ratio ratio-16x9 mb-3'>
        <ReactPlayer url={video} ref={videoPlayerRef} controls
            width='100%' height='100%'
        />
    </Container>

    const materiNav = <Nav variant='tabs' activeKey={activeMateriNavKey} fill
        onSelect={(eventKey, event) => setActiveMateriNavKey(eventKey)}
    >
        <Nav.Item>
            <Nav.Link eventKey='timeline' className='materi-nav-link p-1'>Timeline</Nav.Link>
        </Nav.Item>
        {role === 'trainer' && <Nav.Item>
            <Nav.Link eventKey='komentar' className='materi-nav-link p-1'>Komentar</Nav.Link>
        </Nav.Item>}
    </Nav>

    return (
        <Container className='bg-white p-5 pb-4 rounded mt-5'
            style={{ minWidth: '90%', textAlign: 'justify' }}
        >
            <Row>
                <Col className='col-7'>
                    {header}
                    {judulTopikField}
                    {namaBabField}
                    {namaSubbabField}
                    {judulField}
                    {deskripsiField}
                    {tagField}
                    {skalaNilaiField}
                </Col>
                <Col className='col-5'>
                    {videoPlayer}
                    {materiNav}
                    <Container className='p-0 border' style={{ }}>
                        {(activeMateriNavKey === 'timeline') && <Timeline idMateri={id} durasi={durasi}/>}
                        {(activeMateriNavKey === 'komentar') && <Komentar idMateri={id} />}
                    </Container>
                </Col>
            </Row>
        </Container>
    );
}

export default MateriPopUpDetail;

import React, { useEffect } from 'react';
import { Alert } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons';

import { hideErrorAlert } from '../../app';

function ErrorAlert(props) {
    const dispatch = useDispatch();
    const show = useSelector(state => state.app.showErrorAlert);
    const message = useSelector(state => state.app.errorMessage);

    useEffect(() => {
        const closeTimeout = setTimeout(() => {
            dispatch(hideErrorAlert());
        }, 5000);
        return function() {
            clearTimeout(closeTimeout);
        }
    });

    return (
        <Alert className='position-fixed bottom-0 end-0 error-alert d-flex align-items-center'
            onClose={(e) => dispatch(hideErrorAlert())}
            variant='danger' dismissible
            style={{zIndex: 1060}}
            show={show}
        >
            <FontAwesomeIcon icon={faCircleXmark} className='fs-4' />
            <div className='fsz-1125 ms-3'>
                <div>Error!</div>
                <div>{message}</div>
            </div>
        </Alert>
    );
}

export default ErrorAlert;

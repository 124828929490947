import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';

function censored(password) {
    var censored = '', count = 0;
    while (count < password.length) {
        censored += '*';
        count++;
    }
    return censored;
}

export const columns = (editFunction, deleteFunction) => [
    {
        Header: "No",
        accessor: "nomor",
        Cell: (row) => {
            return <div>{Number(row.row.id) + 1}</div>;
        },
        disableFilters: true,
        disableSortBy: true,
    },
    {
        Header: 'Username',
        accessor: 'username',
    },
    {
        Header: 'Nama Lengkap',
        accessor: 'namaLengkap',
    },
    {
        Header: 'E-mail',
        accessor: 'email',
    },
    {
        Header: 'Role',
        accessor: 'role',
        disableFilters: true,
    },
    {
        Header: 'Status',
        accessor: 'status',
        disableFilters: true,
    },
    {
        Header: 'Aksi',
        accessor: 'aksi',
        disableSortBy: true,
        disableFilters: true,
        Cell: (row) => {
            return (
                <>
                    <FontAwesomeIcon icon={faEdit} color="#ffc107" className='me-2'
                        onClick={(e) => editFunction(row.row.original)}
                        style={{ cursor: 'pointer' }}
                    />
                    {row.row.original.role !== 'admin' && <FontAwesomeIcon icon={faTrash} color="#dc3545"
                        onClick={(e) => deleteFunction(row.row.original)}
                        style={{ cursor: 'pointer' }}
                    />}
                </>
            );
        }
    },
];

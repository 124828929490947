import React from 'react';
import { Modal, Row, Col, Image } from 'react-bootstrap';

function PopupDetil({ show, setShow, data }) {
    const profilePhoto = '/img/avatar.png';

    return (
        <Modal id="popup-leaderboard" show={show} onHide={() => setShow(prev => !prev)} centered>
            <Modal.Header closeButton className='profil-modal-header'>
                <Image className="logo79" src='/img/logotujuhsembilan-mod.png' />
            </Modal.Header>
            <Modal.Body className='profil-modal-body'>
                <Row className='mx-0 mb-4 justify-content-md-center'>
                    <Col lg='auto' className='d-grid align-content-center'>
                        <div className='image-container' style={{margin:'0 auto'}}>
                            <Image src={profilePhoto} className='nav-icon' />
                        </div>
                        <span className='talent-name mt-3'>{data?.name}</span>
                    </Col>
                </Row>
                <Row className='mx-0 mb-4 justify-content-md-center'>
                    <Col lg='auto'>
                        <Image src='/img/level.png' className='navigation-profile-icon' />
                    </Col>
                    <Col lg='auto'>
                        <span>Level</span>
                    </Col>
                    <Col lg='auto ms-auto'>
                        <span>{data?.lvl}</span>
                    </Col>
                </Row>
                <Row className='mx-0 mb-4 justify-content-md-center'>
                    <Col lg='auto'>
                        <Image src='/img/xp.png' className='navigation-profile-icon' />
                    </Col>
                    <Col lg='auto'>
                        <span>Total Experience</span>
                    </Col>
                    <Col lg='auto ms-auto'>
                        <span>{data?.xp}</span>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    );
}

export default PopupDetil;
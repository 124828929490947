import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

// Component
import { Container, Row, Col, InputGroup, Form } from 'react-bootstrap';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TopikCardGrid from './TopikCardGrid';
import { listTopikTalent } from '../../service/TopikService';
import { showErrorAlert } from '../../../app';

function Topik() {
    const dispatch = useDispatch();
    const [searchTerm, setSearchTerm] = useState('');
    const [topikList, setTopikList] = useState([]);

    useEffect(() => {
        listTopikTalent().then(
            (response) => {
                switch (response.data.status_code) {
                    case 'TPK200':
                        setTopikList(response.data.message || []);
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('Topik error :', error);
            }).finally((info) => {

            });
    }, [dispatch]);

    const topikSearch = (term) => {
        const fieldSearchTopik = ['judul', 'tag', 'tingkatan'];
        return topikList.filter(topik => {
            for (let i = 0; i < fieldSearchTopik.length; i++) {
                if (topik[fieldSearchTopik[i]] !== undefined)
                    if (String(topik[fieldSearchTopik[i]])
                        .toLowerCase()
                        .includes(String(term)
                            .toLowerCase()
                        )) return true;
            }
            return false;
        })
    }

    const SearchField = <Container fluid className="mb-4">
        <Row>
            <Col xs lg={6}>
                <InputGroup>
                    <Form.Control
                        placeholder='Judul, Topik, Tag'
                        className='search-input'
                        value={searchTerm || ''}
                        onChange={e => {
                            setSearchTerm(e.target.value);
                        }} />
                    <InputGroup.Text id="btnGroupAddon" className='bg-white search-icon'
                        onClick={() => {
                            setSearchTerm('');
                        }}
                        style={searchTerm ? { cursor: 'pointer' } : {}}
                    >
                        {!searchTerm
                            ? <FontAwesomeIcon icon={faSearch} size='xs' />
                            : <FontAwesomeIcon
                                size='xs'
                                icon={faTimes} />
                        }
                    </InputGroup.Text>
                </InputGroup>
            </Col>
        </Row>
    </Container>

    return (
        <Container fluid className='px-5' style={{ paddingTop: '4rem' }}>
            <Container fluid className='fs-4 fw-bold mb-4' style={{ color: '#263238' }}>Homepage</Container>
            {SearchField}
            <TopikCardGrid topikList={topikSearch(searchTerm)} />
        </Container>
    );
}

export default Topik;

import React, { useEffect, useState } from 'react';
import { MdAccessTime } from 'react-icons/md';

export default function StopWatch({ timeEllapsed, setTimeEllapsed, pembahasan }) {
    const [time, setTime] = useState(0);
    const hours = ('0' + Math.floor(time / 3600)).slice(-2);
    const minutes = ('0' + Math.floor((time / 60) % 60)).slice(-2);
    const seconds = ('0' + (time % 60)).slice(-2);

    useEffect(() => {
        if(pembahasan) return;
        setTimeEllapsed(`${hours}:${minutes}:${seconds}`);
        const interval = setInterval(() => {
            setTime(prev => prev + 1);
        }, 1000);
        return function removeInterval() {
            clearInterval(interval);
        }
    }, [hours, minutes, pembahasan, seconds, setTimeEllapsed, time])

    return (
        <div className='d-flex align-items-center'>
            <MdAccessTime color='#FBBC04' style={{ width: '1.25rem', height: '1.25rem' }} className='me-2' />
            <span className='fw-bold' style={{ color: '#0078D7' }}>{timeEllapsed}</span>
        </div>
    );
}

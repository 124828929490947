import React from 'react';
import { saveAs } from 'file-saver';

import { Button, Modal, Image } from 'react-bootstrap';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function PembahasanPopup({ show, setShow, imageUrl }) {
    const downloadImage = () => {
        saveAs(imageUrl, imageUrl)
    }

    return (
        <Modal show={show} onHide={() => setShow(prev => !prev)} className='pembahasan-popup'>
            <Modal.Header closeButton className='pembahasan-popup-header'>
                <Button variant="success" onClick={downloadImage}>
                    <FontAwesomeIcon icon={faDownload} /> Download Gambar
                </Button>
            </Modal.Header>
            <Modal.Body className='pembahasan-popup-body'>
                <Image src={imageUrl} />
            </Modal.Body>
        </Modal>
    );
}

export default PembahasanPopup;

import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const columnsReportBelajar = (editFunction, deleteFunction) => [
  {
    Header: "Nama",
    accessor: "nama",
  },
  {
    Header: "Lembaga",
    accessor: "nama_lembaga",
  },
  {
    Header: "Email",
    accessor: "email",
  },
  {
    Header: "Nomor Telepon",
    accessor: "no_telp",
  },
  {
    Header: "Masuk Kelas Arutala",
    accessor: "siap_masuk_bulan",
  },
  {
    Header: "Fundamental Java",
    accessor: "fundamental_java_score",
  },
  {
    Header: "OOP",
    accessor: "oop_score",
  },
  {
    Header: "SQL",
    accessor: "sql_score",
  },
  {
    Header: "Total",
    accessor: "total_score",
  },
];

const renderBab = (d, i) => {
  return (
    <div
      style={{
        padding: "5px 10px 5px 10px",
        border: "1px solid #fff",
        background: d?.done ? "#16C098" : "#D9D9D9",
        borderRadius: 20,
        color: d?.done ? "#fff" : "#BCBCBC",
      }}
    >
      <span className="me-1">
        <FontAwesomeIcon icon={faCheckCircle} size="md" />
      </span>
      {d.label}
    </div>
  );
};

const renderSubBab = (d, i, e, j) => {
  return (
    <div
      style={{
        padding: "5px 10px 5px 10px",
        border: "1px solid #fff",
        background: d?.done ? "#16C098" : "#D9D9D9",
        borderTopLeftRadius: j === 0 && 20,
        borderBottomLeftRadius: j === 0 && 20,
        borderTopRightRadius: j + 1 === d?.subbab?.length && 20,
        borderBottomRightRadius: j + 1 === d?.subbab?.length && 20,
        color: d?.done ? "#fff" : "#BCBCBC",
      }}
    >
      <span className="me-1">
        <FontAwesomeIcon icon={faCheckCircle} size="md" />
      </span>
      {d.label}/{j + 1}
    </div>
  );
};

export const columnsProgressBelajar = (editFunction, deleteFunction) => [
  {
    Header: "Nama",
    accessor: "nama",
  },
  {
    Header: "Lembaga",
    accessor: "nama_lembaga",
  },
  {
    Header: "Email",
    accessor: "email_talent",
  },
  {
    Header: "Nomor Telepon",
    accessor: "nomor_telepon_talent",
  },
  {
    Header: "Masuk Kelas Arutala",
    accessor: "siap_masuk_bulan",
  },
  {
    Header: "Durasi",
    accessor: "duration",
    Cell: (row) => {
      let progress = row.row.original.progress;
      let bab = progress?.bab;
      return (
        <div className="d-flex flex-column">
          {progress?.map((d, i) => {
            return (
              <div
                className="d-flex flex-row align-items-center py-1"
                style={{ whiteSpace: "nowrap", justifyContent: "center" }}
              >
                <span style={{ height: 26, textAlign: 'center' }}>{d?.duration || "-"}</span>
              </div>
            );
          })}
        </div>
      );
    },
  },
  {
    Header: "Progress",
    accessor: "progress",
    width: 500,
    Cell: (row) => {
      let progress = row.row.original.progress;
      let bab = progress?.bab;
      return (
        <div className="d-flex flex-column">
          {/* {bab?.map((d, i) => {
            return (
              <div
                className="d-flex flex-row align-items-center px-1"
                style={{ fontSize: 12, color: "#fff" }}
              >
                {d.subbab.length === 0
                  ? renderBab(d, i)
                  : d?.subbab?.map((e, j) => renderSubBab(d, i, e, j))}
              </div>
            );
          })} */}
          {progress?.map((d, i) => {
            return (
              <div
                className="d-flex flex-row align-items-center py-1"
                style={{ whiteSpace: "nowrap" }}
              >
                <span
                  style={{ minWidth: 40, textAlign: "right", marginRight: 10 }}
                >
                  {d?.percentage} %
                </span>
                <strong>{d?.nama_topik}</strong>
                {d?.subbab?.map((e, j) => {
                  return (
                    <div
                      className="mx-1"
                      style={{
                        padding: "3px 10px 3px 10px",
                        border: "1px solid #fff",
                        background: e?.done ? "#16C098" : "#D9D9D9",
                        borderRadius: 20,
                        color: e?.done ? "#fff" : "#BCBCBC",
                        fontSize: 12,
                      }}
                    >
                      <span className="me-1">
                        <FontAwesomeIcon icon={faCheckCircle} size="md" />
                      </span>
                      {e.label}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      );
    },
  },
];

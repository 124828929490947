import { useState } from 'react'

const Faqs = () => {
  const [faqsActive, setFaqsActive] = useState([1])

  const handleFaqToggle = (faqId) => {
    if (faqsActive.includes(faqId)) {
      const updatedFaqsActive = faqsActive.filter((id) => id !== faqId)
      setFaqsActive(updatedFaqsActive)
    } else {
      setFaqsActive([...faqsActive, faqId])
    }
  }

  return (
    <div className="container space-container">
      <h2 className="fw-bold mb-4">Pertanyaan seputar Funtastic 79 Bootcamp</h2>
      <div className="accordion" id="accordionPanelsStayOpenExample">
        {faqsData.map((faq) => (
          <div className="accordion-item" key={faq.id}>
            <h2 className="accordion-header">
              <button
                className={`accordion-button ${
                  !faqsActive.includes(faq.id) && 'collapsed'
                }`}
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseOne"
                aria-expanded={faqsActive.includes(faq.id)}
                aria-controls="panelsStayOpen-collapseOne"
                onClick={() => handleFaqToggle(faq.id)}
              >
                {faq.title}
              </button>
            </h2>
            <div
              id={`panelsStayOpen-collapse${faq.id}`}
              className={`accordion-collapse collapse ${
                faqsActive.includes(faq.id) && 'show'
              }`}
            >
              <div className="accordion-body">{faq.content}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

const faqsData = [
  {
    id: 1,
    title:
      'Apa ada deadline waktu untuk menyelesaikan platform Digital Bootcamp?',
    content: `Tidak ada deadline, namun semakin cepat menyelesaikan akan cepat pula masuk kelas lanjutan
    `,
  },
  {
    id: 2,
    title: 'Materi apa saja yang nanti nya dipelajari di Kelas Arutala?',
    content: `Materi di Kelas Arutala (Solid Principle, Java Solid Principle, OOP Lanjutan, SQL Lanjutan, Mengasah Logika Dasar)`,
  },
  {
    id: 3,
    title: 'Materi apa saja yang nanti nya dipelajari di Kelas Teknologi?',
    content: `Softskill, Budaya Kerja 79, Case Project, Mengasah logika, React JS, React Native, Go, Angular, Tips Ngoding`,
  },
  {
    id: 4,
    title:
      'Jika nanti lolos sampai Kelas Teknologi dan onsite di Bandung apa disediakan fasilitas mess/kossan?',
    content:
      'Untuk saat ini tidak disediakan, tetapi bisa dibantu untuk dicarikan lokasi jika diperlukan',
  },
  {
    id: 5,
    title: 'Apakah Bootcamp ini mendapat sertifikat?',
    content: 'Untuk saat ini tidak ada sertifikat',
  },
  {
    id: 6,
    title: 'Apakah Bootcamp ini terikat kontrak & ada sistem penalti?',
    content: 'Tidak terikat kontrak dan tidak ada penalti',
  },
  {
    id: 7,
    title: 'Bagaimana alur dari proses Bootcamp ini?',
    content:
      'Alur proses Bootcamp: 1. Melakukan pendaftaran di platform digital, 2. Mengerjakan materi, latihan dan quis pada paltform digital, 3. Masuk ke Kelas Arutala jika sudah mencapai point minimal 250 di Digital Bootcamp, dan minimal 800 di Moodle 4. Masuk tes ke Kelas Teknologi 5. Kelas Teknologi',
  },
  {
    id: 8,
    title: 'Apa Bootcamp ini gratis?',
    content: 'Ya, Gratis',
  },
  {
    id: 9,
    title: 'Kak bagaimana cara mendownload materi diplatform Digital Bootcamp?',
    content:
      'Bisa langsung buka link Materi Video via Youtube, kemudian bisa langsung download menggunakan tools seperti IDM, savefrom.net, ssyoutube.com dan Tools lainnya.    ',
  },
  {
    id: 10,
    title: 'Jika pindah periode Bootcamp apa bisa?',
    content: 'Bisa',
  },
  {
    id: 11,
    title: 'Lama waktu di setiap kelas',
    content:
      'Kelas Umum (Standard 2 minggu), Kelas Arutala (Standard 1 bulan), Kelas Teknologi (Standard 3 bulan)',
  },
  {
    id: 12,
    title: 'Jika tidak lulus seleksi Kelas Arutala apakah bisa ikut kembali?',
    content: 'Bisa',
  },
  {
    id: 13,
    title: 'Jika tidak lulus seleksi Kelas Teknologi apakah bisa ikut kembali?',
    content: 'Bisa',
  },
  {
    id: 14,
    title: 'Patokan waktu berkegiatan di Kelas Arutala dalam sehari?',
    content: 'Minimal 5 jam/hari',
  },
  {
    id: 15,
    title: 'Topik yang wajib dikerjakan di Web Digital Bootcamp?',
    content: 'Java, SQL, OOP',
  },
  {
    id: 16,
    title:
      'Apa yang dilakukan setelah menyelesaikan semua topik di Web Digital Bootcamp?',
    content:
      'Peserta akan mendapatkan notifikasi ke Whats App untuk mengerjakan latihan soal di Moodle',
  },
  {
    id: 17,
    title:
      'Apa yang dilakukan setelah menyelesaikan semua quiz di Moodle, Java Fundamental 1-10?',
    content:
      'Menunggu notifikasi dari Panitia Bootcamp untuk dijadwalkan kickoff masuk Kelas Arutala',
  },
  {
    id: 18,
    title: 'Untuk penilaian di Moodle bagaimana?',
    content: 'Nilai benar mendapat 1 poin, jumlah poin yang ada 1000',
  },
  {
    id: 19,
    title: 'Untuk penilaian di Web Digital Bootcamp bagaimana?',
    content: 'Nilai benar mendapat 1 poin, jumlah poin yang ada 300',
  },
]

export default Faqs

import React, { useState, useEffect } from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import ImageRow from './ImageRow';

export default function RangkumanPopupDetil({ show, setShow, rangkumanData }) {
    const [id, setId] = useState();
    const [judul, setJudul] = useState();
    const [topik, setTopik] = useState();
    const [idTopik, setIdTopik] = useState();
    const [bab, setBab] = useState();
    const [idBab, setIdBab] = useState();
    const [subbab, setSubbab] = useState();
    const [idSubbab, setIdSubbab] = useState();
    const [tag, setTag] = useState();
    const [visibilitas, setVisibilitas] = useState();
    const [files, setFiles] = useState([]);

    useEffect(() => {
        setId(rangkumanData?.id || '');
        setJudul(rangkumanData?.judul || '');
        setTopik(rangkumanData?.judulTopik || '');
        setIdTopik(rangkumanData?.idTopik || '');
        setBab(rangkumanData?.namaBab || '');
        setIdBab(rangkumanData?.idBab || '');
        setSubbab(rangkumanData?.namaSubbab || '');
        setIdSubbab(rangkumanData?.idSubbab || '');
        setTag(rangkumanData?.tag || '');
        setVisibilitas(rangkumanData?.visibilitas || 'sembunyi');
        setFiles(rangkumanData?.file || []);
    }, [show, rangkumanData]);

    function splitUrl(url) {
        return url.split('\\').pop().split('/').pop();
    }

    const imageList = files?.map(file => ({ label: splitUrl(file), value: file }));

    return (
        <Modal className="rangkuman-popup popup-detil" show={show} onHide={() => setShow(prev => !prev)} centered >
            <Modal.Header closeButton>
                <Modal.Title>Detail Rangkuman Digital Bootcamp 79</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className='mx-0 mb-3'>
                    <Col>
                        <label>Judul Rangkuman</label>
                        <br /><span id="judulRangkumanSpan">{judul}</span>
                    </Col>
                    <Col>
                        <label>Topik</label>
                        <br /><span id="topikSpan">{topik}</span>
                    </Col>
                </Row>
                <Row className='mx-0 mb-3'>
                    <Col>
                        <label>Tag</label>
                        <br /><span id="tagSpan">{tag}</span>
                    </Col>
                    <Col>
                        <label>Bab</label>
                        <br /><span id="babSpan">{bab}</span>
                    </Col>
                </Row>
                <Row className='mx-0 mb-3'>
                    <Col>
                        <label>Visibilitas</label>
                        <br /><span id="visibilitasSpan">{visibilitas}</span>
                    </Col>
                    <Col>
                        <label>SubBab</label>
                        <br /><span id="subbabSpan">{subbab}</span>
                    </Col>
                </Row>
                <Row className='mx-0 mb-3'>
                    <Col>
                        <div className="image-upload">
                            <ImageRow image={imageList} />
                        </div>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    )
}

import React, { useEffect, useState } from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { showErrorAlert } from '../../../app';
import { getBabTalent } from '../../service/BabService';
import { listKuisBabTalent } from '../../service/KuisBabService';
import { listRiwayatKuisBab } from '../../service/RiwayatService';
import { listSoalKuisBabTalent } from '../../service/SoalKuisBabService';
import KuisBabEndConfirmation from './KuisBabEndConfirmation';
import KuisBabEnding from './KuisBabEnding';
import KuisBabMain from './KuisBabMain';
import KuisBabOpening from './KuisBabOpening';

export default function KuisBab() {
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const [idBab, setIdBab] = useState(searchParams.get('idBab') || '');
    const [id, setId] = useState(searchParams.get('id') || '');
    const [refreshData, setRefreshData] = useState(1);
    const accountId = useSelector(state => state.authTalent.accountId);
    const [loading, setLoading] = useState(true);

    const [nilai, setNilai] = useState(0);
    const [startKuis, setStartKuis] = useState(false);
    const [endKuis, setEndKuis] = useState(false);
    const [pembahasan, setPembahasan] = useState(false);
    const [timeEllapsed, setTimeEllapsed] = useState('00:00:00');

    const [jawabanBenar, setJawabanBenar] = useState(0);
    const [jawabanSalah, setJawabanSalah] = useState(0);
    const [dilewati, setDilewati] = useState(0);

    const [kuisBab, setKuisBab] = useState({});
    const [listSoalKuisBab, setListSoalKuisBab] = useState([]);
    const [riwayatKuisBab, setRiwayatKuisBab] = useState([]);
    const [bab, setBab] = useState({});

    const [showEndConfirmation, setShowEndConfirmation] = useState(false);
    const [activeSoalIndex, setActiveSoalIndex] = useState(0);
    const [choosenAnswers, setChoosenAnswers] = useState({});

    useEffect(() => {
        if (!kuisBab?.id) return;
        if (startKuis) return;
        const savedResult = JSON.parse(localStorage.getItem(`${kuisBab.id}-${accountId}`));
        if (!savedResult) return;

        setEndKuis(true);
        setJawabanBenar(savedResult.jawabanBenar);
        setJawabanSalah(savedResult.jawabanSalah);
        setDilewati(savedResult.dilewati);
        setNilai(savedResult.nilai);
        setTimeEllapsed(savedResult.timeEllapsed);
        setChoosenAnswers(savedResult.choosenAnswers);
    }, [accountId, kuisBab, startKuis]);

    useEffect(() => {
        if (pembahasan) setStartKuis(true);
    }, [pembahasan]);

    useEffect(() => {
        if (startKuis) setEndKuis(false);
        if (!startKuis) setPembahasan(false);
        if (riwayatKuisBab?.length > 0 && !startKuis) setEndKuis(true);
    }, [riwayatKuisBab?.length, startKuis]);

    useEffect(() => {
        if (endKuis) {
            setStartKuis(false);
            setPembahasan(false);
        }
    }, [endKuis]);

    useEffect(() => {
        setActiveSoalIndex(0);
    }, [startKuis, endKuis, pembahasan])

    useEffect(() => {
        listKuisBabTalent({ idBab }).then(
            (response) => {
                switch (response.data.status_code) {
                    case 'KBB200':
                        setKuisBab(response.data.message.find(data => data.id === id) || {});
                        break;
                    case 'KBB476':
                        setLoading(false);
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('List Kuis Bab error :', error);
            })
    }, [idBab, dispatch, id]);

    useEffect(() => {
        if (!kuisBab?.id) return;
        listSoalKuisBabTalent({ idKuisBab: kuisBab.id }).then(
            (response) => {
                switch (response.data.status_code) {
                    case 'SKB200':
                        setListSoalKuisBab(response.data.message || []);
                        setLoading(false);
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('List Soal Kuis Bab error :', error);
            })
    }, [kuisBab, dispatch]);

    useEffect(() => {
        if (!idBab) return;
        getBabTalent({ id: idBab }).then(
            (response) => {
                switch (response.data.status_code) {
                    case 'BAB200':
                        setBab(response.data.message || {});
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('Get Bab error :', error);
            })
    }, [idBab, dispatch]);

    useEffect(() => {
        if (!kuisBab?.id) return;
        listRiwayatKuisBab({ idKuisBab: kuisBab.id }).then(
            (response) => {
                switch (response.data.status_code) {
                    case 'RWT200':
                        setRiwayatKuisBab(response.data.message || []);
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('List Riwayat Kuis Bab error :', error);
            })
    }, [kuisBab, dispatch, refreshData]);

    const openingState = (!startKuis && !endKuis);
    const mainState = (startKuis);
    const endingState = (endKuis);

    return (
        <>
            <KuisBabEndConfirmation
                nilai={nilai}
                show={showEndConfirmation}
                setShow={setShowEndConfirmation}
                setStartKuis={setStartKuis}
                setEndKuis={setEndKuis}
            />
            {openingState && <KuisBabOpening
                listSoalKuis={listSoalKuisBab}
                setChoosenAnswers={setChoosenAnswers}
                bab={bab} kuis={kuisBab}
                setStartKuis={setStartKuis}
                loading={loading}
            />}
            {mainState && <KuisBabMain
                nilai={nilai} setNilai={setNilai}
                pembahasan={pembahasan} setPembahasan={setPembahasan}
                activeSoalIndex={activeSoalIndex} setActiveSoalIndex={setActiveSoalIndex}
                choosenAnswers={choosenAnswers} setChoosenAnswers={setChoosenAnswers}
                setRefreshData={setRefreshData}

                show={showEndConfirmation}
                setShow={setShowEndConfirmation}
                timeEllapsed={timeEllapsed} setTimeEllapsed={setTimeEllapsed}
                endKuis={endKuis} setEndKuis={setEndKuis}

                bab={bab}
                listSoalKuis={listSoalKuisBab}
                kuisBab={kuisBab} listSoalKuisBab={listSoalKuisBab}

                jawabanBenar={jawabanBenar}
                jawabanSalah={jawabanSalah}
                dilewati={dilewati}

                setJawabanBenar={setJawabanBenar}
                setJawabanSalah={setJawabanSalah}
                setDilewati={setDilewati}
            />}
            {endingState && <KuisBabEnding
                jawabanBenar={jawabanBenar}
                jawabanSalah={jawabanSalah}
                dilewati={dilewati}
                nilai={nilai} setNilai={setNilai}
                riwayatKuis={riwayatKuisBab}
                bab={bab}
                kuisBab={kuisBab} timeEllapsed={timeEllapsed}
                setStartKuis={setStartKuis}
                setEndKuis={setEndKuis}
                setPembahasan={setPembahasan}
            />}
        </>
    );
}

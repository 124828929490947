import { configureStore } from '@reduxjs/toolkit';
import { authReducer } from '../trainer/features/auth';
import { authReducer as authReducerTalent } from '../talent/features/auth';
import appReducer from './AppSlice';
import { breadcrumbsReducer } from '../trainer/common/breadcrumbs';

export const store = configureStore({
    reducer: {
        app: appReducer,
        breadcrumbs: breadcrumbsReducer,
        auth: authReducer,
        authTalent: authReducerTalent,
    },
});

import axios from "axios";
import { talent_download_endpoint } from "../../app";
import { authHeader } from "./AuthHeader";

export const listDownloadTalent = () => {
    return axios({
        url: talent_download_endpoint,
        method: 'GET',
        headers: {'Content-type': 'application/json', ...authHeader()},
    }).then((response) => {
        console.log('List Download Result :', response.data);
        return response;
    });
};

export const addDownloadTalent = ({ judulMateri, judulBab, JudulSubbab, judulTopik, id }) => {
    return axios({
        url: talent_download_endpoint,
        method: 'POST',
        headers: {'Content-type': 'application/json', ...authHeader()},
        data: {
            judulMateri, judulBab, JudulSubbab, judulTopik, id
        }
    }).then((response) => {
        console.log('Add Download Result :', response.data);
        return response;
    });
};

export const deleteDownloadTrainer = ({ id }) => {
    return axios({
        url: talent_download_endpoint,
        method: 'DELETE',
        headers: {'Content-type': 'application/json', ...authHeader()},
        data: {
            id
        }
    }).then((response) => {
        console.log('Delete Download Result :', response.data);
        return response;
    });
};
export const week = [
  {
    label: "Sen",
    total: 55,
    univ: 20,
    sekolah: 35,
  },
  {
    label: "Sel",
    total: 60,
    univ: 30,
    sekolah: 30,
  },
  {
    label: "Rab",
    total: 40,
    univ: 20,
    sekolah: 20,
  },
  {
    label: "Kam",
    total: 70,
    univ: 30,
    sekolah: 40,
  },
  {
    label: "Jum",
    total: 45,
    univ: 20,
    sekolah: 25,
  },
  {
    label: "Sab",
    total: 50,
    univ: 25,
    sekolah: 25,
  },
  {
    label: "Min",
    total: 80,
    univ: 40,
    sekolah: 40,
  },
];

export const month = [
  {
    label: "26 Mar - 1 Apr",
    total: 55,
    univ: 20,
    sekolah: 35,
  },
  {
    label: "2 Apr - 8 Apr",
    total: 40,
    univ: 20,
    sekolah: 20,
  },
  {
    label: "9 Apr - 15 Apr",
    total: 35,
    univ: 15,
    sekolah: 20,
  },
  {
    label: "16 Apr - 22 Apr",
    total: 60,
    univ: 30,
    sekolah: 30,
  },
  {
    label: "23 Apr - 29 Apr",
    total: 70,
    univ: 35,
    sekolah: 35,
  },
  {
    label: "30 Apr - 6 May",
    total: 45,
    univ: 20,
    sekolah: 25,
  },
];

export const year = [
  {
    label: "Jan",
    total: 80,
    univ: 40,
    sekolah: 40,
  },
  {
    label: "Feb",
    total: 50,
    univ: 25,
    sekolah: 25,
  },
  {
    label: "Mar",
    total: 60,
    univ: 20,
    sekolah: 40,
  },
  {
    label: "Apr",
    total: 70,
    univ: 40,
    sekolah: 30,
  },
  {
    label: "Mei",
    total: 45,
    univ: 25,
    sekolah: 5,
  },
  {
    label: "Jun",
    total: 40,
    univ: 20,
    sekolah: 20,
  },
  {
    label: "Jul",
    total: 80,
    univ: 40,
    sekolah: 40,
  },
  {
    label: "Agu",
    total: 90,
    univ: 40,
    sekolah: 50,
  },
  {
    label: "Sep",
    total: 100,
    univ: 55,
    sekolah: 45,
  },
  {
    label: "Okt",
    total: 30,
    univ: 20,
    sekolah: 10,
  },
  {
    label: "Nov",
    total: 80,
    univ: 30,
    sekolah: 50,
  },
  {
    label: "Des",
    total: 85,
    univ: 45,
    sekolah: 40,
  },
];

export const lembaga = [
  {
    label: "UPI",
    total: 50,
  },
  {
    label: "SMKN 1 Cimahi",
    total: 27,
  },
  {
    label: "SMKN 2 Cimahi",
    total: 15,
  },
  {
    label: "SMKN 3 Cimahi",
    total: 10,
  },
];

export const bgColor = ["#015394", "#F2C103", "#f03c32", "#099d"];
export const bgColor2 = ["#263238", "#16C098"];

export const cityColor = [
  "#3f5772",
  "#b6c419",
  "#f7deec",
  "#64824c",
  "#232123",
  "#c47350",
  "#050404",
  "#c99d9f",
  "#94e82e",
  "#b55f70",
  "#2ce03b",
  "#48a00e",
  "#468433",
  "#82b7a7",
  "#0a374c",
  "#1f4560",
  "#251c9e",
  "#2b1b2d",
  "#849147",
  "#261547",
  "#b0c980",
  "#2a83ba",
  "#1a1726",
  "#6946c9",
  "#aa302c",
  "#324377",
  "#302517",
  "#04090a",
  "#969491",
  "#e5ba69",
  "#29d1a4",
  "#92b567",
  "#752110",
  "#36c1b8",
  "#383d12",
  "#66d184",
  "#c96c6a",
  "#b09cd1",
  "#baddc3",
  "#e6ede6",
  "#4dcc53",
  "#28100f",
  "#89a517",
  "#1b231c",
  "#d2e5c9",
  "#bccad8",
  "#1c1a1b",
  "#dd9b21",
  "#434147",
  "#132615",
  "#4b6f8e",
  "#050402",
  "#39cc45",
  "#c47d89",
  "#e7ffe5",
  "#1499ba",
  "#1b35f9",
  "#7aaf2a",
  "#282344",
  "#5380a0",
  "#ecede3",
  "#979ba0",
  "#3f3b3d",
  "#95a575",
  "#d1590e",
  "#911f32",
  "#263460",
  "#3a3818",
  "#a120b2",
  "#1c8e18",
  "#99b563",
  "#5d41cc",
  "#b290ed",
  "#8e89c1",
  "#40590a",
  "#26070c",
  "#d3cac2",
  "#d38b2c",
  "#c95eb5",
  "#344a4c",
];

export const provinceColor = [
  "#6a6b6a",
  "#8548db",
  "#230400",
  "#30440d",
  "#281b20",
  "#fca18a",
  "#7faaf4",
  "#8d9293",
  "#58656b",
  "#577741",
  "#725532",
  "#c7e2db",
  "#2b1947",
  "#1c1619",
  "#423a38",
  "#1b4c20",
  "#997303",
  "#c9ddbe",
  "#d68baf",
  "#2d102b",
  "#190a0c",
  "#091111",
  "#1f1d21",
  "#627204",
  "#b42ccc",
  "#704525",
  "#3d5e49",
  "#372d3d",
  "#051e15",
  "#999495",
  "#464c2d",
  "#00050c",
  "#7c2028",
  "#9844ba",
  "#abd6d3",
  "#28527a",
  "#aa81d1",
  "#282d13",
  "#b220bf",
  "#423d44",
  "#3f2430",
  "#ff6905",
  "#39ad98",
  "#9148a8",
  "#1e0d0b",
  "#23079e",
  "#5e6d60",
  "#000f0e",
  "#1a0b28",
  "#75aa86",
  "#374408",
  "#183b56",
  "#c9568a",
  "#63a54d",
  "#702b3b",
  "#48474c",
  "#7f4d87",
  "#835a87",
  "#313f47",
  "#96a36d",
  "#000000",
  "#2f4a4c",
  "#e2aab7",
  "#a7efba",
  "#593733",
  "#d33dc4",
  "#bf0d6f",
  "#172638",
  "#16261d",
  "#33f450",
  "#9d86a8",
  "#11af98",
  "#190a01",
  "#665056",
  "#1b5289",
  "#efa2bd",
  "#1c1813",
  "#60bcea",
  "#749634",
  "#a894a0",
];

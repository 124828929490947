import axios from "axios";
import { talent_laporsoalcekkemampuan_endpoint, talent_soalkcekkemampuan_endpoint } from "../../app";
import { authHeader } from "./AuthHeader";

export const listSoalCekKemampuanTalent = ({ idCekKemampuan }) => {
    return axios({
        url: talent_soalkcekkemampuan_endpoint,
        method: 'GET',
        headers: { 'Content-type': 'application/json', ...authHeader() },
        params: { idCekKemampuan },
    }).then((response) => {
        console.log('List Soal Cek Kemampuan Result :', response.data);
        return response;
    });
};

export const laporkanSoalCekKemampuanTalent = ({ idSoal, jenisLaporan }) => {
    return axios({
        url: talent_laporsoalcekkemampuan_endpoint,
        method: 'POST',
        headers: { 'Content-type': 'application/json', ...authHeader() },
        data: {
            idSoal, jenisLaporan,
        }
    }).then((response) => {
        console.log('Lapor Soal Cek Kemampuan Result :', response.data);
        return response;
    });
};

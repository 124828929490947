import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { showErrorAlert } from '../../../app';
import { Header } from '../../common/header';
import { getProfile } from '../../service/ProfileService';

export default function HomeKuis({ children }) {

    const dispatch = useDispatch();
    const [refreshData, setRefreshData] = useState(1);
    const [profileData, setProfileData] = useState({});

    useEffect(() => {
        getProfile().then(
            (response) => {
                switch (response.data.status_code) {
                    case 'TAL200':
                        setProfileData(response.data.message || {});
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('Get Profile error :', error);
            })
    }, [dispatch, refreshData]);

    return (
        <Container fluid className='vh-100 m-0'>
            <Row>
                <Col lg={12} className='header-talent p-0 position-fixed'
                    style={{ zIndex: 2 }}
                >
                    <Header profileData={profileData} setRefreshData={setRefreshData} />
                </Col>
            </Row>
            {children}
        </Container>
    );
}

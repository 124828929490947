import React, { useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { hideErrorAlert, hideSuccessAlert, showErrorAlert, showSuccessAlert } from '../../../app';
import { GetParentPath } from '../../../common';
import { forgotPasswordUser } from '../../service/AuthService';

function ForgotPasswordForm(props) {
    const dispatch = useDispatch();
    const [username, setUsername] = useState('');
    const [usernameError, setUsernameError] = useState(false);
    const parentPath = GetParentPath();

    const forgotPasswordFormHeader = <Container fluid className='text-center'
        style={{ marginBottom: '4rem' }}
    >
        <h4><b>Lupa Kata Sandi</b></h4>
    </Container>

    const usernameField = <Form.Group className='mb-4 w-100 fsz-075' controlId='usernameField'>
        <Form.Label>Username/E-mail :</Form.Label>
        <Form.Control placeholder='Masukkan Username/E-mail'
            type='text'
            className={`${usernameError ? 'login-form-element-error' : 'login-form-element'}`}
            onChange={(e) => { setUsername(e.target.value); setUsernameError(false) }}
        />
    </Form.Group>

    const processButton = <Container fluid className='fsz-0875 text-center'>
        <Button variant='primary' type='submit'
            className='px-5' style={{ marginBottom: '7rem' }}
        >
            <b>Proses</b>
        </Button>
    </Container>

    const loginLink = <Container fluid className='text-center p-0 fsz-075'
        style={{ marginBottom: '2.25rem' }}
    >
        Kembali Log In <Link className='login-footer-link text-decoration-none' to={`${parentPath}/login`}>
            <b>di sini</b>
        </Link>
    </Container>

    const aboutLink = <Container fluid className='text-start p-0 fsz-075'>
        <Link className='login-footer-link text-decoration-none' to={`${parentPath}/lupa-sandi`}><b>About</b></Link>
    </Container>

    const contactLink = <Container fluid className='text-end p-0 fsz-075'>
        <Link className='login-footer-link text-decoration-none' to={`${parentPath}/lupa-sandi`}><b>Contact</b></Link>
    </Container>

    const forgotPassword = () => {
        forgotPasswordUser(username).then(
            (response) => {
                switch (response.data.status_code) {
                    case 'LGN200':
                        dispatch(showSuccessAlert('Request berhasil, silakan cek email anda!'));
                        break;
                    case 'FPW400':
                        setUsernameError(true);
                        dispatch(showErrorAlert('Isi Username / Email !'));
                        break;
                    case 'FPW401':
                        setUsernameError(true);
                        dispatch(showErrorAlert('Proses gagal: Username/Email tidak sesuai atau tidak terdaftar'));
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('Forgot password error :', error);
            }).finally((info) => {

            });
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        dispatch(hideErrorAlert());
        dispatch(hideSuccessAlert());

        if (username === '') {
            setUsernameError(true);
            dispatch(showErrorAlert('Isi Username/E-mail!'));
        } else {
            forgotPassword();
        }
    }

    return (
        <Container className='login-form-container bg-white ms-auto me-0'>
            {forgotPasswordFormHeader}
            <Form noValidate
                onSubmit={(e) => handleSubmit(e)}
            >
                {usernameField}
                {processButton}
            </Form>
            {loginLink}
            <Row className='mx-0'>
                <Col className='p-0'>
                    {aboutLink}
                </Col>
                <Col className='p-0'>
                    {contactLink}
                </Col>
            </Row>
        </Container>
    );
}

export default ForgotPasswordForm;

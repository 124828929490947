import { useState } from 'react'
import './TimePrediction.css'
import baru122x from '../../assets/roadmap-funtastic-79.png';
import icon from '../../assets/icon.svg';
import frame from '../../assets/frame.svg';
import frame1 from '../../assets/frame1.svg';
import frame2 from '../../assets/frame2.svg';
import { Col, Row } from 'react-bootstrap';
import RegisForm from '../../../talent/features/auth/NewRegisForm';
import Banner from '../../../talent/features/auth/Banner';
import { Modal } from 'react-bootstrap';
import image12 from '../../assets/image12.png';

const listClasses = [
  {
    id: 1,
    img: frame,
    title: 'Kelas Umum (2 Minggu)',
    content:
      'Kelas Umum merupakan kelas pertama di program Funtastic 79. Kelas ini dilakukan secara online. Kami memiliki mentor yang dapat membantu peserta jika menemui kendala selama proses pembelajaran.',
    longContent: (
      <ul>
        <li>Kelas Umum merupakan kelas pertama di program Funtastic 79. Kelas ini dilakukan secara <b>online</b></li>
        <li>Kami memiliki mentor yang dapat membantu peserta jika menemui kendala selama proses pembelajaran.</li>
        <li>Waktu pengerjaan tugas di kelas umum sangat fleksibel. Semakin cepat menyelesaikan tugas, maka akan semakin besar peluang untuk lanjut ke kelas berikutnya (Optimal pengerjaan 14 hari)</li>
        <li>Kegiatan yang dilakukan online secara self-learning, mengerjakan quiz, dan melakukan submission (berdasarkan keterangan yang tertera di discord) pada modul atau topik yang tersedia di platform Digital Bootcamp.</li>
        <li>Detail hal yang dilakukan pada kelas umum:</li>
        <ul>
          <li>Belajar dan melakukan pengerjaan materi yang ada pada modul Java Fundamental, OOP, SQL, & GIT pada platform Digital Bootcamp.</li>
          <li>Minimum total nilai dari rata-rata pengerjaan di setiap modul adalah 250</li>
          <li>Jika nilai sudah mencapai 250 dan sudah menyelesaikan semua modul, nanti akan dihubungi oleh admin untuk mengerjakan quiz yang ada pada platform Moodle</li>
          <li>Jika pengerjaan kuis pada Moodle telah selesai dan nilai nya sudah mencukupi, akan dihubungi oleh admin untuk masuk seleksi ke kelas Arutala.</li>
        </ul>
      </ul>
    ),
  },
  {
    id: 2,
    img: frame1,
    title: 'Kelas Arutala (1 Bulan)',
    content:
      'Kelas Arutala merupakan kelas lanjutan dari kelas umum, dimana talent akan melakukan pembelajaran advance atau lanjutan dari apa yang telah dipelajari melalui platform Digital Bootcamp. Kelas ini dilakukan secara online.',
    longContent: (
      <ul>
        <li>Kelas Arutala merupakan kelas lanjutan dari kelas umum, dimana talent akan melakukan pembelajaran advance atau lanjutan dari apa yang telah dipelajari melalui platform Digital Bootcamp. Kelas ini dilakukan secara <b>online</b></li>
        <li>Kegiatan pada kelas Arutala lebih padat, sehingga peserta wajib menginvestasikan waktu dan fokus selama mengikuti kelas ini.</li>
        <li>Fokus materi pada kelas Arutala adalah pemantapan pada Java Fundamental, OOP dan S.O.L.I.D Principle.</li>
        <li>Di kelas Arutala akan mendapatkan banyak hal:</li>
        <ul>
          <li>Latihan coding, latihan logic, program sederhana, hingga problem solving dengan case yang lebih kompleks.</li>
          <li>Kegiatan sharing session yang rutin dilakukan setelah pengerjaan task sebagai bahan evaluasi dan diskusi.</li>
          <li>Talent-talent akan tetap disupport oleh mentor-mentor dalam setiap pengerjaan tugas-tugas yang diberikan.</li>
        </ul>
        <li>Kelas Arutala akan berjalan maksimal selama 25 hari Kerja. Namun jika talent masih membutuhkan pengembangan pemahaman, talent dapat tetap melanjutkan pembelajaran di kelas Arutala.</li>
        <li>Peserta yang telah menyelesaikan seluruh assignment dengan perolehan nilai yang bagus, akan dinyatakan <b>TUNTAS</b>, dan direkomendasikan mengikuti seleksi ke kelas teknologi</li>
      </ul>
    ),
  },
  {
    id: 3,
    img: frame2,
    title: 'Kelas Teknologi (3 Bulan)',
    content:
      'Kelas Teknologi merupakan kelas yang berorientasi pada standard developer 79. Standard ini menjadi ciri khas di 79 dan menjadi rujukan yang jelas bagi Developer Junior (khususnya) dan Developer Middle hingga Senior (umumnya). Kelas ini dilakukan secara offline di kantor PT. Padepokan 79.',
    longContent: (
      <ul>
        <li>Kelas Teknologi merupakan kelas yang berorientasi pada standard developer 79. Standard ini menjadi ciri khas di 79 dan menjadi rujukan yang jelas bagi Developer Junior (khususnya) dan Developer Middle hingga Senior (umumnya). Kelas ini dilakukan secara offline di kantor PT. Padepokan 79.</li>
        <li>Kegiatan di kelas teknologi dilakukan selama maksimal 3 bulan dengan rincian sebagai berikut:</li>
        <ol type="a">
          <li>Technology Class, Talent akan dikenalkan dengan satu/lebih framework melalui sebuah kasus yang didalamnya sudah mengacu kepada checklist standard developer 79. Durasi pada kelas ini maksimal 1 bulan</li>
          <li>Onsite Trial, pada kelas ini, talent akan langsung dilibatkan di real project (Internal maupun External Project). Dengan durasi selama 2 bulan. </li>
        </ol>
        <li>Benefit Kelas Teknologi:</li>
        <ol type="a">
          <li>Talent akan diasah melakukan estimasi suatu task
            Setiap Talent memiliki standar waktu pengerjaan sebuah task dengan kompleksitas yang beragam (Low, Medium, dan High)</li>
          <li>Setiap Talent bisa mengukur waktu penyesuaian terhadap sebuah teknologi baru</li>
          <li>Setiap Talent bisa mengukur waktu penyesuaian terhadap role yang berbeda (FE ke BE, atau sebaliknya, Fullstack)</li>
          <li>Setiap Talent akan mendapatkan uang saku masing-masing Rp.2 Juta/bulan</li>
        </ol>
        <br></br>
        <br></br>
        <h5><b>Tes Masuk Kelas Teknologi</b></h5>
        <br></br>
        <li>Seleksi masuk ke Kelas Teknologi dilaksanakan oleh tim Padepokan 79 berdasarkan rekomendasi dari tim Arutala.</li>
        <li>Seleksi ini ini dilakukan secara offline di kantor PT. Padepokan 79, Jln. Terasana No 6A, Pasirkaliki - Cicendo - KOTA BANDUNG,  selama maksimal 3 hari</li>
        <li>Jika tidak lolos dalam tahapan seleksi masuk ke kelas teknologi, bisa mengikuti kembali tahun depan</li>
      </ul>
    ),
  },
]

const TimePrediction = ({ refContainer }) => {
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [showRegisterModalMobile, setShowRegisterModalMobile] = useState(false);
  const [showClassInfoModal, setShowClassInfoModal] = useState(false);
  const [selectedClass, setSelectedClass] = useState(null); // Tambahkan state selectedClass

  const handleButtonClick = (event) => {
    if (window.innerWidth <= 600) {
      // Jika perangkat seluler, tampilkan modal peringatan
      // Gantilah ini dengan logika untuk menampilkan modal peringatan
      setShowRegisterModalMobile(true);
    } else {
      setShowRegisterModal(true);
    }
  };

  const handleCloseRegisterModal = () => {
    setShowRegisterModal(false);
  };

  const handleCloseRegisterModalMobile = (event) => {
    setShowRegisterModalMobile(false);
  };

  // Tambahkan fungsi untuk menampilkan modal informasi kelas
  const handleCardClick = (classItem) => {
    setSelectedClass(classItem);
    setShowClassInfoModal(true);
  };

  const handleCloseClassInfoModal = () => {
    setShowClassInfoModal(false);
  };

  return (
    <div ref={refContainer} className="container-fluid tp-background time-prediction-container" id="2">
      <div className="container space-container">
        <div className="row tp-mb60">
          <div className="col-12 text-center">
            <div className="tp-subtitle">Time Prediction</div>
            <h2 className="tp-title">Roadmap Funtastic 79 Bootcamp</h2>
          </div>
          <div className="col-12">
            <img className="tp-img w-100" alt="" src={baru122x} />
          </div>
        </div>
        <div className="row tp-mb40">
          <div className="col-12 col-md-6">
            <h2 className="text-white fw-bold">
              Kelas di Funtastic 79 Bootcamp
            </h2>
          </div>
          <div className="col-12 col-md-6 text-start text-md-end">
            <button className="btn btn-outline-light custom-btn" onClick={handleButtonClick}>
              Daftar Sekarang Juga!
            </button>
          </div>
        </div>
        <div className="row g-5">
          {listClasses.map((classItem) => (
            <div
              className="col-12 col-sm-12 col-md-12 col-lg-4"
              key={classItem.id}
              onClick={() => handleCardClick(classItem)}
            >
              <div className="card-class" style={{height: "100%"}}>
                <div className="d-flex mb-4">
                  <div className="mb-auto">
                    <img src={classItem.img} />
                  </div>
                  <div className="mb-auto ms-auto">
                    <img src={icon} />
                  </div>
                </div>
                <div className="card-class-title">{classItem.title}</div>
                <div className="card-class-content">{classItem.content}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
            
      <Modal show={showRegisterModal} onHide={handleCloseRegisterModal} size="xl">
        <div class="modal-content-transparent rounded-0">
          <Row className='mx-0 px-0' style={{ height: '100%', width: '100%' }}>
            <Col className='col-6 login-banner-col'
              style={{
                background: 'url(/img/talent-login-banner.png) rgba(0, 0, 0, 0.75)',
                backgroundBlendMode: 'multiply',
                backgroundSize: 'cover',
              }}
            >
              <Banner />
            </Col>
            <Col className={`bg-white col-6 h-100 d-flex flex-column justify-content-center`}
              style={{
                padding: '2rem',
              }}
            >
              <button className="btn-close" onClick={handleCloseRegisterModal} style={{
                position: 'absolute',
                top: '10px',
                right: '10px', // Memindahkan tombol ke sudut kanan atas
                zIndex: '1050',
              }}></button>
              <Modal.Body>
                <RegisForm />
              </Modal.Body>
            </Col>
          </Row>
        </div>
      </Modal>

      <Modal show={showClassInfoModal} onHide={handleCloseClassInfoModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title><img src={selectedClass?.img} /></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3><b>{selectedClass?.title}</b></h3>
          {selectedClass?.longContent}
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-primary btn-block" onClick={handleButtonClick} style={{ width: "100%" }}>
            Daftar Funtastic 79 Bootcamp
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={showRegisterModalMobile} onHide={handleCloseRegisterModalMobile} size="s">
        <Modal.Body>
          <button className="btn-close" onClick={handleCloseRegisterModalMobile} style={{
            position: 'absolute',
            top: '10px',
            right: '10px', // Memindahkan tombol ke sudut kanan atas
            zIndex: '1050',
          }}></button>
          <img src={image12} className="w-100 img-modal"></img>
          <div className="Paragraph" style={{alignSelf: 'stretch', textAlign: 'center', color: '#525A80', fontSize: 16, fontFamily: 'Source Sans Pro', wordWrap: 'break-word'}}> Pendaftaran hanya dapat dilakukan pada  Desktop (Laptop/PC)</div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-primary btn-block" onClick={handleCloseRegisterModalMobile} style={{ width: "100%" }}>
            Kembali
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default TimePrediction

import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { showErrorAlert } from '../../../app';

// styling
import './Topik.css';

// component
import { Table } from '../../common/table';
import { columns } from './columns';

// dummy data
// import { data } from './dummy';

// service
import { listTopikTrainer } from '../../service/TopikService';
import { listTagTrainer } from '../../service/TagService';
import TopikPopUp from './TopikPopUp';
import TopikPopUpDelete from './TopikPopUpDelete';
import TopikPopUpDetail from './TopikPopUpDetail';
import TopikDeleteConfirmation from './TopikDeleteConfirmation';
import { listTimelineTrainer } from '../../service/TimelineService';

function Topik() {
    const [state, setState] = useState([]);
    const [showPopUp, setShowPopUp] = useState(false);
    const [showPopUpDelete, setShowPopUpDelete] = useState(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [showPopUpDetail, setShowPopUpDetail] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [topikData, setTopikData] = useState();
    const [refreshData, setRefreshData] = useState(1);

    const [tagList, setTagList] = useState([]);

    const dispatch = useDispatch();

    // Initial render
    useEffect(() => {
        listTopikTrainer().then(
            (response) => {
                switch (response.data.status_code) {
                    case 'TPK200':
                        setState(response.data.message || []);
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('List Topik error :', error);
            })
    }, [dispatch, refreshData]);

    useEffect(() => {
        listTagTrainer().then(
            (response) => {
                switch (response.data.status_code) {
                    case 'TAG200':
                        setTagList(response.data.message || []);
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('List Tag error :', error);
            })
    }, [dispatch]);

    const addFunction = () => {
        setTopikData();
        setIsEdit(false);
        setShowPopUp(prev => !prev);
    }

    const editFunction = (row) => {
        console.log(row);
        setTopikData(row);
        setIsEdit(true);
        setShowPopUp(prev => !prev);
    }

    const deleteFunction = (row) => {
        setTopikData(row);
        setShowPopUpDelete(prev => !prev);
    }

    const detailFunction = (row) => {
        setTopikData(row);
        setShowPopUpDetail(prev => !prev);
    }

    return (
        <>
            <TopikPopUp setRefreshData={setRefreshData}
                show={showPopUp}
                setShow={setShowPopUp}
                topikData={topikData}
                isEdit={isEdit}
                tagList={tagList} />
            <TopikPopUpDetail
                show={showPopUpDetail}
                setShow={setShowPopUpDetail}
                topikData={topikData}
            />
            <TopikPopUpDelete 
                show={showPopUpDelete}
                setShow={setShowPopUpDelete}
                setShowDeleteConfirmation={setShowDeleteConfirmation}
            />
            <TopikDeleteConfirmation setRefreshData={setRefreshData}
                show={showDeleteConfirmation}
                setShow={setShowDeleteConfirmation}
                topikData={topikData}
                setShowPopUpDelete={setShowPopUpDelete}
            />
            <Table
                data={state}
                rowClickFunction={detailFunction}
                columns={columns(editFunction, deleteFunction)}
                title='Topik Digital Bootcamp 79'
                addFunction={addFunction} />
        </>
    );
}

export default Topik;




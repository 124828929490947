import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import React, { useMemo, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form, FormControl, InputGroup, OverlayTrigger, Row, Tooltip
} from "react-bootstrap";
import { useDispatch } from "react-redux";
import {
  Link, useNavigate,
  useSearchParams
} from "react-router-dom";
import Select from "react-select";
import { hideErrorAlert, showErrorAlert, showSuccessAlert } from "../../../app";
import { GetParentPath } from "../../../common";
import { LoadingSpinner } from "../../../trainer/common/LoadingSpinner";
import { getOptLembaga, newRegisUser } from "../../service/RegisterService";

function NewRegisForm(props) {
  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();

  const listTab = [
    {
      key: "dataDiri",
      title: "Data Diri",
    },
    {
      key: "dataPendidikan",
      title: "Data Pendidikan",
    },
    {
      key: "komitmen",
      title: "Komitmen",
    },
  ];

  const listBulan = [
    { value: "1", label: "Januari" },
    { value: "2", label: "Februari" },
    { value: "3", label: "Maret" },
    { value: "4", label: "April" },
    { value: "5", label: "Mei" },
    { value: "6", label: "Juni" },
    { value: "7", label: "Juli" },
    { value: "8", label: "Agustus" },
    { value: "9", label: "September" },
    { value: "10", label: "Oktober" },
    { value: "11", label: "November" },
    { value: "12", label: "Desember" },
  ];

  const listJenjang = [
    { value: "D1", label: "D1" },
    { value: "D2", label: "D2" },
    { value: "D3", label: "D3" },
    { value: "D4", label: "D4" },
    { value: "S1", label: "S1" },
    { value: "S2", label: "S2" },
    { value: "S3", label: "S3" },
  ];

  const listLulus = [
    { id: "true", label: "Sudah Lulus" },
    { id: "false", label: "Belum Lulus" },
  ];

  const listAktifitas = [
    { value: "Bekerja", label: "Bekerja" },
    { value: "Bekerja Sambil Kuliah", label: "Bekerja Sambil Kuliah" },
    { value: "Pencari Kerja", label: "Pencari Kerja" },
    {
      value: "Sedang Tugas Akhir (TA)",
      label: "Sedang Tugas Akhir (TA)",
    },
    { value: "Lainnya", label: "Lainnya" },
  ];

  const listJenisKelamin = [
    { id: "P", label: "Perempuan" },
    { id: "L", label: "Laki-Laki" },
  ];

  const listSosialMedia = [
    {value: "Linked In", label: "Linked In"},
    {value: "Instagram", label: "Instagram"},
    {value: "Telegram", label: "Telegram"},
    {value: "Whatsapp", label: "Whatsapp"},
    {value: "Facebook", label: "Facebook"},
  ];

  const currentYear = new Date().getFullYear();

  const initialValue = {
    username: searchParams.get("username") || null,
    email: searchParams.get("email") || null,
    password: null,
    confirmPassword: null,
    nama: searchParams.get("name") || null,
    noTelp: null,
    noWa: null,
    confirmTermAndCondition: false,
    tipeLembaga: "1",
    asalLembaga: { value: null, label: "Pilih Universitas" },
    jenisKelamin: "P",
    namaPanggilan: null,
    aktivitas: { value: null, label: "Pilih Aktifitas" },
    jenjang: { value: null, label: "Pilih Jenjang" },
    programStudy: null,
    semester: null,
    siapMasukBulan: { value: null, label: "Pilih Bulan" },
    urlProfilLinkedin: null,
    lulus: "true",
    tahunLulus: null,
    motivasi: null,
    dariSosialMediaMana : {value: null, label: "Pilih Sosial Media"}
  };

  const [value, setValue] = useState(initialValue);
  const [isValid, setIsValid] = useState(null);
  const [key, setKey] = useState("dataDiri");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [optionLembaga, setOptionLembaga] = useState([]);
  const [loading, setLoading] = useState(false);

  const parentPath = GetParentPath();
  const navigate = useNavigate();

  const getLembaga = (val) => {
    setLoading(true);
    getOptLembaga({ tipeLembaga: value?.tipeLembaga, keywordSearch: val })
      .then(
        (response) => {
          switch (response.data.status_code) {
            case "TAL200":
              const arr = response.data.message.map((e) => ({
                value: e.namaLembaga,
                label: e.namaLembaga,
              }));
              setOptionLembaga(arr);
              setLoading(false);
              break;
            default:
              dispatch(showErrorAlert("Terjadi kesalahan pada server!"));
              setLoading(false);
              break;
          }
        },
        (error) => {
          console.log("Verifkasi error :", error);
        }
      )
      .finally((info) => {});
  };

  const regisFormHeader = (
    <Container fluid className="p-0 login-form-header">
      <h4 style={{ textAlign: "center" }}>
        <b>Registrasi Akun Baru</b>
      </h4>
    </Container>
  );

  const isRequired = (
    <Form.Control.Feedback type="invalid">
      Kolom harus diisi
    </Form.Control.Feedback>
  );

  const onLembagaChange = (event) => {
    const val = event.target.id;
    const tipe = val === "1" ? "Pilih Universitas" : "Pilih Sekolah";
    setValue({
      ...value,
      tipeLembaga: val,
      asalLembaga: {
        value: null,
        label: tipe,
      },
      jenjang: null,
      semester: null,
      programStudy: null,
      tahunLulus: null,
    });
  };

  const pilihLembaga = (
    <Form.Group className="mb-1 w-100" controlId="pilihLembaga">
      <Form.Label>
        Pilih Lembaga <span style={{ color: "red" }}>*</span>
      </Form.Label>
      <InputGroup>
        <Form.Check
          inline
          label="Universitas"
          name="tipeLembaga"
          type="radio"
          id="1"
          checked={value?.tipeLembaga === "1"}
          onChange={onLembagaChange}
        />
        {/* hide radio button sekolah */}
        {/* <Form.Check
          inline
          label="Sekolah"
          name="tipeLembaga"
          type="radio"
          id="2"
          checked={value?.tipeLembaga === "2"}
          onChange={onLembagaChange}
        /> */}
      </InputGroup>
    </Form.Group>
  );

  const handleLembaga = (event) => {
    if (!event) {
      const tipe =
        value?.tipeLembaga === "1" ? "Pilih Universitas" : "Pilih Sekolah";
      event = {
        target: null,
        value: "",
        label: tipe,
      };
    }
    setValue({ ...value, asalLembaga: event });
  };

  const filterLembaga = (val) => {
    if (val.length > 2) {
      getLembaga(val);
    } else {
      setOptionLembaga([]);
    }
  };

  const debounce = useMemo(
    () => _.debounce(filterLembaga, 500),
    [value?.tipeLembaga]
  );

  const selectLembaga = (
    <Form.Group className="mb-3 w-100" controlId="selectLembaga">
      <Select
        value={value?.asalLembaga}
        noOptionsMessage={() => null}
        options={optionLembaga}
        onChange={handleLembaga}
        onInputChange={(e) => debounce(e)}
        isClearable={value?.asalLembaga.value}
        isLoading={loading}
        styles={{
          control: (provided, state) => ({
            ...provided,
            borderColor:
              value?.asalLembaga?.value === "" ? "#EA4335" : "#ced4da",
          }),
          menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
          menu: (provided) => ({ ...provided, zIndex: 9999 }),
        }}
      />
      {value?.asalLembaga?.value === "" && (
        <span style={{ fontSize: 14 }} className="text-danger">
          Kolom harus diisi
        </span>
      )}
    </Form.Group>
  );

  const passwordField = (
    <Form.Group className="mb-3 w-100" controlId="passwordField">
      <Form.Label>
        Kata Sandi <span style={{ color: "red" }}>*</span>
      </Form.Label>
      <InputGroup>
        <Form.Control
          placeholder="Password"
          type={showPassword ? "text" : "password"}
          className={`${
            value?.password === ""
              ? "login-form-element-error"
              : "login-form-element"
          } border-end-0`}
          value={value?.password}
          onChange={(e) => {
            setValue({ ...value, password: e.target.value });
          }}
        />
        <Button
          variant="light"
          onClick={() => setShowPassword((prev) => !prev)}
          style={{ zIndex: 0 }}
          className={`${
            value?.password === ""
              ? "login-form-element-error"
              : "login-form-element"
          } border-start-0 bg-white`}
        >
          {showPassword ? (
            <FontAwesomeIcon icon={faEye} color={"#6C757D"} />
          ) : (
            <FontAwesomeIcon icon={faEyeSlash} color={"#6C757D"} />
          )}
        </Button>
      </InputGroup>
      {value?.password === "" && (
        <span style={{ fontSize: 14 }} className="text-danger">
          Kolom harus diisi
        </span>
      )}
    </Form.Group>
  );

  const confirmPasswordField = (
    <Form.Group className="mb-3 w-100" controlId="passwordField">
      <Form.Label>
        Konfirmasi Kata Sandi <span style={{ color: "red" }}>*</span>
      </Form.Label>
      <InputGroup>
        <Form.Control
          placeholder="Konfirmasi Kata Sandi"
          type={showConfirmPassword ? "text" : "password"}
          className={`${
            value?.confirmPassword !== value?.password
              ? "login-form-element-error"
              : "login-form-element"
          } border-end-0`}
          value={value?.confirmPassword}
          onChange={(e) => {
            setValue({ ...value, confirmPassword: e.target.value });
          }}
        />
        <Button
          variant="light"
          onClick={() => setShowConfirmPassword((prev) => !prev)}
          style={{ zIndex: 0 }}
          className={`${
            value?.confirmPassword !== value?.password
              ? "login-form-element-error"
              : "login-form-element"
          } border-start-0 bg-white`}
        >
          {showConfirmPassword ? (
            <FontAwesomeIcon icon={faEye} color={"#6C757D"} />
          ) : (
            <FontAwesomeIcon icon={faEyeSlash} color={"#6C757D"} />
          )}
        </Button>
      </InputGroup>

      {value?.confirmPassword !== value?.password && (
        <span style={{ fontSize: 14 }} className="text-danger">
          Konfirmasi kata sandi salah!
        </span>
      )}
    </Form.Group>
  );

  const checkField = (
    <Form.Group
      className="mb-3 w-100 fsz-075 d-flex flex-row"
      controlId="checkField"
    >
      <Form.Check
        type="checkbox"
        checked={value?.confirmTermAndCondition}
        onChange={(e) => {
          setValue({ ...value, confirmTermAndCondition: e.target.checked });
        }}
        inline={true}
      />
      <Form.Label>
        Dengan menekan tombol dibawah ini, kamu menyetujui{" "}
        <Link to={``}>Kebijakan Privasi</Link> serta{" "}
        <Link to={``}>Kondisi dan Ketentuan</Link> oleh tim Digital Bootcamp 79
      </Form.Label>
    </Form.Group>
  );

  const regisButton = () => {
    let newObj = {
      siapMasukBulan: value?.siapMasukBulan?.value,
      motivasi: value?.motivasi,
      password: value?.password,
      confirmPassword: value?.confirmPassword,
      confirmTermAndCondition: value?.confirmTermAndCondition,
    };
    if (value?.aktivitas?.value === "Lainnya") {
      newObj = { ...newObj, aktivitas: value?.aktivitasLain };
    } else {
      newObj = { ...newObj, aktivitas: value?.aktivitas?.value };
    }
    return (
      <Container fluid className="text-center p-0">
          <Button
            variant="primary"
            type="submit"
            className="w-100 mt-0 mb-3"
            disabled={
              isValidObject(newObj) || value?.confirmPassword !== value?.password || loading
            }
          >
            {loading ? <LoadingSpinner /> : <b>Daftar</b>}
          </Button>
      </Container>
    );
  };

  const backButton = (
    <Container fluid className="text-center">
      <Link
        className="login-footer-link text-decoration-none"
        to={`${parentPath}/login`}
      >
        <Button
          variant="light"
          className="p-0 bg-transparent border-0 back-login-button fsz-075 mb-3"
          onClick={(e) => {
            setValue({
              username: null,
              password: null,
            });
          }}
        >
          &lt;&lt; Kembali
        </Button>
      </Link>
    </Container>
  );

  const register = () => {
    const objValues = {
      ...value,
      noTelp: `0${value?.noTelp}`,
      noWa: `0${value?.noWa}`,
      aktivitas:
        value?.aktivitas?.value === "Lainnya"
          ? value?.aktivitasLain
          : value?.aktivitas?.value,
      asalLembaga: value?.asalLembaga?.value,
      jenjang: value?.jenjang?.value || null,
      siapMasukBulan: value?.siapMasukBulan?.value,
      lulus: value?.lulus === "true" ? true : false,
      dariSosialMediaMana: value?.dariSosialMediaMana.value
    };
    setLoading(true)
    newRegisUser(objValues)
      .then(
        (response) => {
          switch (response.data.status_code) {
            case "TAL200":
              setLoading(false);
              dispatch(
                showSuccessAlert("Berhasil registrasi, silahan cek email")
              );
              navigate(`${parentPath}/login`);
              break;
            case "TAL555":
              setLoading(false);
              const err = response.data?.message.split(" ");
              for (var i = 0; i < err.length; i++) {
                err[i] = err[i].charAt(0).toUpperCase() + err[i].slice(1);
              }
              const errMsg = err.join(" ");
              dispatch(showErrorAlert(errMsg));
              break;
            default:
              setLoading(false);
              const arr = response.data?.status.split(" ");
              for (var i = 0; i < arr.length; i++) {
                arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
              }
              const errorMsg = arr.join(" ");
              dispatch(showErrorAlert(errorMsg));
              break;
          }
        },
        (error) => {
          console.log("Auth error :", error);
        }
      )
      .finally((info) => {});
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(hideErrorAlert());
    register();
  };

  const emailValidate = (value) => {
    if (!value) return false;
    const re = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,6}$/g;
    return re.test(value.trim());
  };

  const handleChange = (val, e) => {
    setValue({ ...value, [val]: e });
  };

  const lengthValidation = (val) => {
    switch (val) {
      case "noTelp":
      case "noWa":
        return 12;
      case "tahunLulus":
        return 4;
      case "semester":
        return 2;
      default:
        return 255;
    }
  };

  const textField = (label, val, required, type, textArea) => {
    return (
      <Form.Group className="mb-3">
        <Form.Label>
          {label} {required && <span style={{ color: "red" }}>*</span>}
        </Form.Label>
        <Form.Control
          placeholder={
            val === "urlProfilLinkedin"
              ? "https://www.linkedin.com/in/fulan"
              : label
          }
          value={value[val] || ""}
          onChange={(e) => {
            const str = e.target.value;
            if (type === "email") {
              setIsValid(emailValidate(str));
            }
            handleChange(val, str);
          }}
          type={type || "text"}
          isInvalid={type === "email" ? isValid === false : value[val] === ""}
          as={textArea && "textarea"}
          rows={textArea}
          maxLength={val !== "motivasi" && lengthValidation(val)}
        />
        {required && type !== "email" && isRequired}
        {type === "email" && !isValid && (
          <Form.Control.Feedback type="invalid">
            E-mail tidak sesuai format!
          </Form.Control.Feedback>
        )}
      </Form.Group>
    );
  };

  const isValidTahun = () => {
    const val = parseInt(value?.tahunLulus);
    if (val > currentYear || val < 1945) {
      return true;
    } else {
      return false;
    }
  };

  const isValidObject = (obj) => {
    return Object.values(obj).some((x) => !x || x === undefined);
  };

  const numberField = (label, val, required) => {
    return (
      <Form.Group className="mb-3">
        <Form.Label>
          {label} {required && <span style={{ color: "red" }}>*</span>}
        </Form.Label>
        <InputGroup>
          {(val === "noTelp" || val === "noWa") && (
            <InputGroup.Text>62</InputGroup.Text>
          )}
          <FormControl
            placeholder={label}
            value={value[val] || ""}
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            onChange={(e) => {
              const str = e.target.value;
              if (str.charAt(0) === "0") {
                str = str.slice(1);
              }
              handleChange(val, str);
            }}
            isInvalid={
              (value[val] === "" && required) ||
              isValidTahun() ||
              ((val === "noTelp" || val === "noWa") && value[val]?.length < 9)
            }
            maxLength={lengthValidation(val)}
            onPaste={(e) => {
              e.preventDefault();
              return false;
            }}
          />
          {value[val] === "" && required ? (
            isRequired
          ) : (val === "noTelp" || val === "noWa") && value[val]?.length < 9 ? (
            <Form.Control.Feedback type="invalid">
              {label} harus 9-12 digit!
            </Form.Control.Feedback>
          ) : (
            isValidTahun() && (
              <Form.Control.Feedback type="invalid">
                Tahun Lulus tidak sesuai
              </Form.Control.Feedback>
            )
          )}
        </InputGroup>
      </Form.Group>
    );
  };

  const onChangeRadio = (event, val) => {
    setValue({
      ...value,
      [val]: event.target.id,
      semester: null,
      tahunLulus: null,
    });
  };

  const radioField = (label, val, options, required) => {
    return (
      <Form.Group className="mb-3 w-100" controlId="jenisKelamin">
        {label && (
          <Form.Label>
            {label} {required && <span style={{ color: "red" }}>*</span>}
          </Form.Label>
        )}
        <InputGroup>
          {options?.map((data, i) => {
            return (
              <Form.Check
                inline
                label={data?.label}
                name={data?.label}
                type="radio"
                id={data?.id}
                checked={value[val] === data?.id}
                onChange={(e) => onChangeRadio(e, val)}
              />
            );
          })}
        </InputGroup>
      </Form.Group>
    );
  };

  const handleSelect = (e, val) => {
    setValue({ ...value, [val]: e });
  };

  const selectField = (label, val, options, required) => {
    const textTooltip =
      "Kelas Arutala / Kelas Khusus adalah kelas yang akan diikuti setelah kelas umum/kelas reguler dengan durasi 1 bulan. Pada kelas ini akan ditingkatkan level pengetahuan dasar terkait Java, OOP, dan SQL.";
    return (
      <Form.Group className="mb-3 w-100">
        <OverlayTrigger
          placement="top"
          overlay={
            val === "siapMasukBulan" ? <Tooltip>{textTooltip}</Tooltip> : <></>
          }
        >
          {label && (
            <Form.Label>
              {label} {required && <span style={{ color: "red" }}>*</span>}
            </Form.Label>
          )}
        </OverlayTrigger>
        <Select
          value={value[val] || ""}
          noOptionsMessage={() => null}
          placeholder={label}
          options={options}
          onChange={(e) => handleSelect(e, val)}
          styles={{
            control: (provided, state) => ({
              ...provided,
              borderColor: value[val]?.value === "" ? "#EA4335" : "#ced4da",
            }),

            menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
            menu: (provided) => ({ ...provided, zIndex: 9999 }),
          }}
        />
        {value[val]?.value === "" && (
          <span style={{ fontSize: 14 }} className="text-danger">
            Kolom harus diisi
          </span>
        )}
      </Form.Group>
    );
  };

  const formDataDiri = (
    <div>
      {textField("Username", "username", true)}
      {textField("Nama", "nama", true)}
      {textField("Nama Panggilan", "namaPanggilan", true)}
      {textField("E-Mail", "email", true, "email")}
      {textField("LinkedIn", "urlProfilLinkedin", true)}
      {numberField("Nomor Telepon", "noTelp", true)}
      {numberField("Nomor WA Aktif", "noWa", true)}
      {radioField(
        "Pilih Jenis Kelamin",
        "jenisKelamin",
        listJenisKelamin,
        true
      )}
    </div>
  );

  const formDataPendidikan = (
    <div>
      {pilihLembaga}
      {selectLembaga}
      {value?.tipeLembaga === "1" &&
        selectField("Jenjang", "jenjang", listJenjang, true)}
      {textField("Jurusan / Program Studi", "programStudy", true)}
      {radioField("", "lulus", listLulus, false)}
      {value?.tipeLembaga === "1" &&
        Boolean(value?.lulus === "false") &&
        numberField("Semester", "semester", Boolean(value?.lulus === "false"))}
      {Boolean(value?.lulus === "true") &&
        numberField(
          "Tahun Lulus",
          "tahunLulus",
          Boolean(value?.lulus === "true")
        )}
    </div>
  );

  const formKomitmen = (
    <div>
      {selectField(
        "Kesiapan Masuk Kelas Arutala Bulan Apa ?",
        "siapMasukBulan",
        listBulan,
        true
      )}
      {selectField("Aktifitas Saat ini", "aktivitas", listAktifitas, true)}
      {value?.aktivitas?.value === "Lainnya" &&
        textField("Aktifitas Lainnya", "aktivitasLain", true)}
      {textField("Motivasi Mengikuti Bootcamp", "motivasi", true, "text", 3)}
      {selectField("Dari Sosial Media mana mengetahui Bootcamp ini?", "dariSosialMediaMana", listSosialMedia, true)}
      {/* {textField("Dari Sosial Media mana mengetahui Bootcamp ini?", "dariSosialMediaMana", true, "text")} */}
      {passwordField}
      {confirmPasswordField}
      {checkField}
      {regisButton()}
    </div>
  );

  const onChangeTab = (data) => {
    const obj = {
      tipeLembaga: value?.tipeLembaga || "",
      asalLembaga: value?.asalLembaga?.value
        ? value?.asalLembaga
        : {
            value: "",
            label:
              value?.tipeLembaga === "1"
                ? "Pilih Universitas"
                : "Pilih Sekolah",
          },
      programStudy: value?.programStudy || "",
      lulus: value?.lulus,
    };
    const jenjang = value?.jenjang?.value
      ? value?.jenjang
      : { value: "", label: "Pilih Jenjang" };
    const newObj =
      value?.tipeLembaga === "1"
        ? Boolean(value?.lulus === "false")
          ? {
              ...obj,
              jenjang: jenjang,
              semester: value?.semester || "",
            }
          : {
              ...obj,
              jenjang: jenjang,
              tahunLulus: value?.tahunLulus || "",
            }
        : value?.tipeLembaga === "2"
        ? Boolean(value?.lulus === "true")
          ? { ...obj, tahunLulus: value?.tahunLulus || "" }
          : obj
        : null;
    const isValidDataPendidikan = () => {
      if (
        isValidObject(newObj) ||
        isValidTahun() ||
        !newObj?.asalLembaga?.value ||
        (value?.tipeLembaga === "1" && !newObj?.jenjang?.value)
      ) {
        setKey("dataPendidikan");
        setValue({ ...value, ...newObj });
        dispatch(showErrorAlert("Mohon Lengkapi Data Pendidikan !"));
      } else {
        setKey(data?.key);
      }
    };

    if (key === "dataDiri") {
      const objDataDiri = {
        username: value?.username || "",
        nama: value?.nama || "",
        namaPanggilan: value?.namaPanggilan || "",
        email: value?.email || setIsValid(false),
        urlProfilLinkedin: value?.urlProfilLinkedin || "",
        noTelp: value?.noTelp || "",
        noWa: value?.noWa || "",
        jenisKelamin: value?.jenisKelamin || "",
      };
      if (
        isValidObject(objDataDiri) ||
        !emailValidate(value?.email) ||
        value?.noTelp?.length < 9 ||
        value?.noWa?.length < 9
      ) {
        setValue({ ...value, ...objDataDiri });
        dispatch(showErrorAlert("Mohon Lengkapi Data Diri !"));
      } else {
        if (data?.key === "komitmen") {
          isValidDataPendidikan();
        } else {
          setKey(data?.key);
        }
      }
    } else if (key === "dataPendidikan") {
      if (data?.key === "dataDiri" || data?.key === "dataPendidikan") {
        setKey(data?.key);
      } else {
        isValidDataPendidikan();
      }
    } else {
      setKey(data?.key);
    }
  };

  return (
    <Container>
      {regisFormHeader}
      <Row className="mb-4 px-2">
        <Col className="d-flex flex-row align-items-center justify-content-between">
          {listTab?.map((data, i) => {
            return (
              <div
                style={{ cursor: "pointer", color: "#000" }}
                onClick={() => onChangeTab(data)}
              >
                <span style={{ fontWeight: key === data?.key && 600 }}>
                  {data?.title}
                </span>
                <div
                  style={{
                    height: 3,
                    width: "auto",
                    background: key === data?.key && "#000",
                  }}
                ></div>
              </div>
            );
          })}
        </Col>
      </Row>

      <Form noValidate onSubmit={(e) => handleSubmit(e)}>
        {key === "dataDiri"
          ? formDataDiri
          : key === "dataPendidikan"
          ? formDataPendidikan
          : formKomitmen}
      </Form>
      {/* {backButton} */}
    </Container>
  );
}

export default NewRegisForm;

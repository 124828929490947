import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Image, Nav, Row, Tab, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { MdOutlinedFlag } from 'react-icons/md';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import { Pembahasan } from '../../common/pembahasan';
import { submitKuisMateriTalent } from '../../service/KuisMateriService';
import { useDispatch } from 'react-redux';
import { showErrorAlert, showSuccessAlert } from '../../../app';
import PembahasanPopup from '../../common/pembahasan/PembahasanPopup';

function SoalKuisMateri({
    idKuisMateri, listSoalKuisMateri = [], activeItemIndex,
    setRefreshData, setIdSoal, setShowLapor, subbabItemList,
    setActiveTabState, setActiveMateriId, setActiveKuisMateriId,
}) {
    const dispatch = useDispatch();
    const [soalKuisMateriActiveKey, setSoalKuisMateriActiveKey] = useState(0);
    const [choosenAnswers, setChoosenAnswers] = useState({});
    const [showDetailGambarSoal, setShowDetailDetailGambarSoal] = useState(false);
    const [detailGambarSoal, setDetailGambarSoal] = useState("");

    useEffect(() => {
        setSoalKuisMateriActiveKey(0);
        setChoosenAnswers({});
    }, [listSoalKuisMateri]);

    useEffect(() => {
        let answersCount = 0
        for (let answer in choosenAnswers) {
            answersCount = answersCount + 1
        }
        if (
            listSoalKuisMateri.length > 0 && answersCount > 0 &&
            answersCount === listSoalKuisMateri.length &&
            idKuisMateri
        ) {
            submitKuisMateriTalent({ idKuisMateri }).then(
                (response) => {
                    switch (response.data.status_code) {
                        case 'KMA200':
                            dispatch(showSuccessAlert('Selesai mengerjakan kuis materi!'));
                            setRefreshData(prev => prev + 1);
                            break;
                        default:
                            dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                            break;
                    }
                },
                (error) => {
                    console.log('Submit Kuis Materi error :', error);
                })
        }
    }, [choosenAnswers, idKuisMateri, listSoalKuisMateri.length, dispatch, setRefreshData]);

    const navSoal = <Nav variant='pills'>
        <Row className='w-100'>
            <Col className='d-flex flex-wrap gap-3 col-11'>
                {listSoalKuisMateri.map((soalKuisMateri, index) => (
                    <Nav.Item key={index} className='nav-item-soal-kuis-materi'>
                        <Nav.Link
                            disabled={(index > 0 && !choosenAnswers[index - 1])}
                            eventKey={index}
                        >
                            {index + 1}
                        </Nav.Link>
                    </Nav.Item>
                ))}
            </Col>
            <Col className='d-flex col-auto ms-auto fsz-1125 fw-bold'
                style={{ color: '#0078D7' }}
            >
                {String(parseInt(soalKuisMateriActiveKey) + 1).padStart(2, '0')}
                /{String(listSoalKuisMateri.length).padStart(2, '0')}
            </Col>
        </Row>
    </Nav>

    const soalTab = <Tab.Content>
        {listSoalKuisMateri.map((soalKuisMateri, index) => {
            const soalOption = [
                { name: soalKuisMateri.opsiA, value: 'A' },
                { name: soalKuisMateri.opsiB, value: 'B' },
                { name: soalKuisMateri.opsiC, value: 'C' },
                { name: soalKuisMateri.opsiD, value: 'D' }
            ]
            return <Tab.Pane key={index} eventKey={index}>
                <Container className='px-5' style={{ textAlign: 'justify' }}>
                    {soalKuisMateri.soal}
                </Container>
                <Container className='w-100 text-center d-flex justify-content-center'
                    style={{ cursor: 'pointer' }}
                    onClick={e => {
                        setShowDetailDetailGambarSoal(true);
                        setDetailGambarSoal(soalKuisMateri.gambar)
                    }}>
                    <Image className='pembahasan-image' src={soalKuisMateri.gambar} />
                </Container>
                <ToggleButtonGroup type='radio' name={`options-${index}`}
                    className='mt-5 d-flex gap-5'
                    style={{ height: '11rem' }}
                    value={choosenAnswers[index]}
                    onChange={val => setChoosenAnswers(prev => {
                        let newValue = { ...prev };
                        newValue[index] = val;
                        return newValue;
                    })}
                >
                    {soalOption.map((opsi, indexOpsi) => (
                        <ToggleButton id={`option-${index}-${indexOpsi}`} key={indexOpsi}
                            value={opsi.value}
                            className={`opsi ${(choosenAnswers[index] === opsi.value) ?
                                (choosenAnswers[index] === soalKuisMateri.jawaban) ?
                                    'opsi-correct-answer' : 'opsi-wrong-answer'
                                : ''
                                } d-flex align-items-center justify-content-center`}
                            disabled={choosenAnswers[index]}
                        >
                            {opsi.name}
                        </ToggleButton>
                    ))}
                </ToggleButtonGroup>
                {choosenAnswers[index] && <Pembahasan jawabanBenar={soalKuisMateri.jawaban}
                    pembahasan={soalKuisMateri.pembahasan}
                    jawabanDipilih={choosenAnswers[index]}
                    referensiSoal={soalKuisMateri.urlReferensi}
                    imageUrl={soalKuisMateri.gambarPembahasan}
                />}
            </Tab.Pane>
        })}
    </Tab.Content>

    const hidePrevButton = (activeItemIndex === 0);

    const prevButton = <Col className='col-auto'>
        <Button variant='outline-danger'
            style={{ width: '9rem', height: '2.rem' }}
            onClick={() => {
                if (soalKuisMateriActiveKey > 0) {
                    setSoalKuisMateriActiveKey(prev => parseInt(prev) - 1)
                } else {
                    const item = subbabItemList[activeItemIndex - 1];
                    if (item.type === 'kuis') {
                        setActiveKuisMateriId(item.id);
                    }
                    setActiveMateriId(
                        item.type === 'video' ? item.id : item.idMateri
                    );
                    setActiveTabState(
                        item.type === 'video' ? 'materi' : 'kuis-materi'
                    );
                }
            }}
            className={`${hidePrevButton ? 'invisible' : ''}`}
        >
            <BsChevronLeft /> Sebelumnya
        </Button>
    </Col>

    const hideNextButton = (activeItemIndex === subbabItemList.length - 1) &&
        (parseInt(soalKuisMateriActiveKey) === listSoalKuisMateri.length - 1);

    const nextButton = <Col className='col-auto'>
        <Button
            style={{ width: '9rem', height: '2.rem' }}
            onClick={() => {
                if (parseInt(soalKuisMateriActiveKey) < listSoalKuisMateri.length - 1) {
                    setSoalKuisMateriActiveKey(prev => parseInt(prev) + 1)
                } else {
                    const item = subbabItemList[activeItemIndex + 1];
                    if (item.type === 'kuis') {
                        setActiveKuisMateriId(item.id);
                    }
                    setActiveMateriId(
                        item.type === 'video' ? item.id : item.idMateri
                    );
                    setActiveTabState(
                        item.type === 'video' ? 'materi' : 'kuis-materi'
                    );
                }
            }}
            className={`${hideNextButton ? 'invisible' : ''}`}
            disabled={!choosenAnswers[soalKuisMateriActiveKey]}
        >
            Berikutnya <BsChevronRight />
        </Button>
    </Col>

    const reportButton = <Col className='col-auto mx-auto d-flex align-items-center'>
        <span>Soal ini bermasalah?</span>
        <Button variant='secondary' className='ms-3 px-2 py-1 border-0'
            style={{ backgroundColor: '#F0F0F0', color: '#263238' }}
            onClick={(e) => {
                setIdSoal(listSoalKuisMateri[soalKuisMateriActiveKey].id);
                setShowLapor(true);
            }}
        >
            Laporkan <MdOutlinedFlag />
        </Button>
    </Col>

    return (
        <Tab.Container
            activeKey={soalKuisMateriActiveKey}
            onSelect={(key) => setSoalKuisMateriActiveKey(key)}
        >
            {navSoal}
            <hr style={{ border: '1px solid #979797', opacity: '1' }} />
            {soalTab}
            <PembahasanPopup
                show={showDetailGambarSoal}
                setShow={setShowDetailDetailGambarSoal}
                imageUrl={detailGambarSoal}
            />
            <Row style={{ marginTop: '8.875rem' }}>
                {prevButton}
                {reportButton}
                {nextButton}
            </Row>
        </Tab.Container>
    );
}

export default SoalKuisMateri;

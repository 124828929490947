import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Image, Nav, Row, Tab, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { MdOutlinedFlag } from 'react-icons/md';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import { Pembahasan } from '../../common/pembahasan';
import { submitKuisBabTalent } from '../../service/KuisBabService';
import { useDispatch, useSelector } from 'react-redux';
import { hideErrorAlert, showErrorAlert } from '../../../app';
import PembahasanPopup from '../../common/pembahasan/PembahasanPopup';

export default function SoalKuisBab({
    nilai, setNilai, setRefreshData,
    endKuis, setEndKuis,
    kuisBab, listSoalKuis = [], timeEllapsed,
    choosenAnswers = {}, setChoosenAnswers,
    activeSoalIndex, setActiveSoalIndex, pembahasan,
    setJawabanBenar, setJawabanSalah, setDilewati,
    setIdSoal, setShowLapor
}) {
    const dispatch = useDispatch();
    const accountId = useSelector(state => state.authTalent.accountId);
    const [showDetailGambarSoal, setShowDetailDetailGambarSoal] = useState(false);
    const [detailGambarSoal, setDetailGambarSoal] = useState("");

    const navSoal = <Nav variant='pills'>
        <Row className='w-100'>
            <Col className='d-flex flex-wrap gap-3 col-11'>
                {listSoalKuis.map((soalKuis, index) => (
                    <Nav.Item key={index} className='nav-item-soal-kuis'>
                        <Nav.Link
                            disabled={(index > 0 && !choosenAnswers[listSoalKuis[index - 1].id])}
                            eventKey={index}
                        >
                            {index + 1}
                        </Nav.Link>
                    </Nav.Item>
                ))}
            </Col>
            <Col className='d-flex col-auto ms-auto fsz-1125 fw-bold'
                style={{ color: '#0078D7' }}
            >
                {String(parseInt(activeSoalIndex) + 1).padStart(2, '0')}
                /{String(listSoalKuis.length).padStart(2, '0')}
            </Col>
        </Row>
    </Nav>

    const soalTab = <Tab.Content>
        {listSoalKuis.map((soalKuis, index) => {
            const soalOption = [
                { name: soalKuis.opsiA, value: 'A' },
                { name: soalKuis.opsiB, value: 'B' },
                { name: soalKuis.opsiC, value: 'C' },
                { name: soalKuis.opsiD, value: 'D' }
            ]
            return <Tab.Pane key={index} eventKey={index}>
                <Container className='px-5' style={{ textAlign: 'justify' }}>
                    {soalKuis.soal}
                </Container>
                <Container className='w-100 text-center d-flex justify-content-center'
                    style={{ cursor: 'pointer' }}
                    onClick={e => {
                        setShowDetailDetailGambarSoal(true);
                        setDetailGambarSoal(soalKuis.gambar)
                    }}>
                    <Image className='pembahasan-image' src={soalKuis.gambar} />
                </Container>
                <ToggleButtonGroup type='radio' name={`options-${index}`}
                    className='mt-5 d-flex gap-5'
                    style={{ height: '11rem' }}
                    value={choosenAnswers[soalKuis.id]}
                    onChange={val => setChoosenAnswers(prev => {
                        let newValue = { ...prev };
                        newValue[soalKuis.id] = val;
                        return newValue;
                    })}
                >
                    {soalOption.map((opsi, indexOpsi) => (
                        <ToggleButton id={`option-${index}-${indexOpsi}`} key={indexOpsi}
                            value={opsi.value}
                            className={`opsi ${(choosenAnswers[soalKuis.id] === opsi.value) ?
                                (choosenAnswers[soalKuis.id] === soalKuis.jawaban || !pembahasan) ?
                                    'opsi-correct-answer' : 'opsi-wrong-answer'
                                : ''
                                } d-flex align-items-center justify-content-center`}
                            disabled={pembahasan}
                        >
                            {opsi.name}
                        </ToggleButton>
                    ))}
                </ToggleButtonGroup>
                {pembahasan && <Pembahasan jawabanBenar={soalKuis.jawaban}
                    pembahasan={soalKuis.pembahasan}
                    jawabanDipilih={choosenAnswers[soalKuis.id]}
                    referensiSoal={soalKuis.urlReferensi}
                    imageUrl={soalKuis.gambarPembahasan}
                />}
            </Tab.Pane>
        })}
    </Tab.Content>

    const submitKuis = (idKuisBab, durasiPengerjaan, nilai) => {
        submitKuisBabTalent({ idKuisBab, durasiPengerjaan, nilai }).then(
            (response) => {
                switch (response.data.status_code) {
                    case 'KBB200':
                        setRefreshData(prev => prev + 1);
                        setEndKuis(true);
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('Submit Kuis Bab error :', error);
            }).finally((info) => {

            });
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        dispatch(hideErrorAlert());

        let jumlahBenar = 0;
        let jumlahSalah = 0;
        for (let index in listSoalKuis) {
            if (choosenAnswers[listSoalKuis[index].id] === listSoalKuis[index].jawaban)
                jumlahBenar++;
            else
                jumlahSalah++;
        }
        let jumlahSkip = listSoalKuis.length - jumlahBenar - jumlahSalah;
        let nilaiAkhir = Math.floor(jumlahBenar / listSoalKuis.length * 100);
        console.log('Hasil perhitungan nilai :', jumlahBenar, jumlahSalah, jumlahSkip, nilaiAkhir);

        localStorage.setItem(`${kuisBab.id}-${accountId}`,
            JSON.stringify({
                jawabanBenar: jumlahBenar,
                jawabanSalah: jumlahSalah,
                dilewati: jumlahSkip,
                nilai: nilaiAkhir,
                choosenAnswers: choosenAnswers,
                timeEllapsed: timeEllapsed,
            })
        );

        setJawabanBenar(jumlahBenar);
        setJawabanSalah(jumlahSalah);
        setDilewati(jumlahSkip);
        setNilai(nilaiAkhir);
        submitKuis(kuisBab.id, timeEllapsed, nilaiAkhir);
    }

    const hideLeftButton = (activeSoalIndex < 1)

    const leftButton = <Col className='col-auto'>
        <Button variant='outline-danger'
            style={{ width: '9rem', height: '2.rem' }}
            onClick={() => setActiveSoalIndex(prev => parseInt(prev) - 1)}
            className={`${hideLeftButton ? 'invisible' : ''}`}
        >
            <BsChevronLeft /> Sebelumnya
        </Button>
    </Col>

    const hideRightButton = (parseInt(activeSoalIndex) === listSoalKuis.length - 1);
    const isNextButton = (parseInt(activeSoalIndex) < listSoalKuis.length - 1) || pembahasan;
    const isSubmitButton = !isNextButton;

    const rightButton = <Col className='col-auto'>
        {isNextButton && <Button
            style={{ width: '9rem', height: '2.rem' }}
            onClick={() => setActiveSoalIndex(prev => parseInt(prev) + 1)}
            className={`${hideRightButton ? 'invisible' : ''}`}
            disabled={!choosenAnswers[listSoalKuis[activeSoalIndex].id] && !pembahasan}
        >
            Berikutnya <BsChevronRight />
        </Button>}
        {(isSubmitButton && !pembahasan) && <Button
            style={{ width: '9rem', height: '2.rem' }}
            onClick={(e) => handleSubmit(e)}
            disabled={!choosenAnswers[listSoalKuis[activeSoalIndex].id]}
        >
            Submit
        </Button>}
    </Col>

    const reportButton = <Col className='col-auto mx-auto d-flex align-items-center'>
        <span>Soal ini bermasalah?</span>
        <Button variant='secondary' className='ms-3 px-2 py-1 border-0'
            style={{ backgroundColor: '#F0F0F0', color: '#263238' }}
            onClick={(e) => {
                setIdSoal(listSoalKuis[activeSoalIndex].id);
                setShowLapor(true);
            }}
        >
            Laporkan <MdOutlinedFlag />
        </Button>
    </Col>

    return (
        <Container className='bg-white rounded p-4 kuis-container'>
            <Tab.Container
                activeKey={activeSoalIndex}
                onSelect={(key) => setActiveSoalIndex(parseInt(key))}
            >
                {navSoal}
                <hr style={{ border: '1px solid #979797', opacity: '1' }} />
                {soalTab}
                <PembahasanPopup
                    show={showDetailGambarSoal}
                    setShow={setShowDetailDetailGambarSoal}
                    imageUrl={detailGambarSoal}
                />
                <Row style={{ marginTop: '8.875rem' }}>
                    {leftButton}
                    {reportButton}
                    {rightButton}
                </Row>
            </Tab.Container>
        </Container>
    );
}

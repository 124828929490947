import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { showErrorAlert } from '../../../app';

// styling
import './KuisAkhir.css';

// component
import { Table } from '../../common/table';
import { columns } from './columns';
import KuisAkhirPopUp from './KuisAkhirPopup';
import KuisAkhirPopUpDelete from './KuisAkhirPopupDelete';
import KuisAkhirDetail from './KuisAkhirDetail';
import KuisAkhirDeleteConfirmation from './KuisAkhirDeleteConfirmation';

// API
import { listKuisAkhirTrainer } from '../../service/KuisAkhirService';
import { listTopikTrainer } from '../../service/TopikService';
import { listSubbabTrainer } from '../../service/SubbabService';
import { listBabTrainer } from '../../service/BabService';

// Crumbs
import { pushCrumb, popCrumb } from '../../common/breadcrumbs';

function KuisAkhir() {
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const [id, setId] = useState(searchParams.get('id'));

    const [data, setData] = useState([]);
    const [showPopUp, setShowPopUp] = useState(false);
    const [showPopUpDelete, setShowPopUpDelete] = useState(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [kuisAkhirData, setKuisAkhirData] = useState();
    const [refreshData, setRefreshData] = useState(1);

    const [topikList, setTopikList] = useState([]);
    const [babList, setBabList] = useState([]);
    const [subBabList, setSubbabList] = useState([]);

    useEffect(() => {
        listKuisAkhirTrainer().then(
            (response) => {
                switch (response.data.status_code) {
                    case 'KAH200':
                        setData(response.data.message || []);
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('Kuis Akhir error :', error);
            }).finally((info) => {

            });
    }, [dispatch, refreshData]);

    useEffect(() => {
        setId(searchParams.get('id'));
        if (searchParams.get('id')) {
            dispatch(pushCrumb("Detail Kuis"));
        } else {
            dispatch(popCrumb("Detail Kuis"));
        }
    }, [searchParams, dispatch]);

    useEffect(() => {
        listTopikTrainer().then(
            (response) => {
                switch (response.data.status_code || []) {
                    case 'TPK200':
                        setTopikList(response.data.message);
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('List Topik error :', error);
            }).finally((info) => {

            });
    }, [dispatch]);

    useEffect(() => {
        listBabTrainer().then(
            (response) => {
                switch (response.data.status_code) {
                    case 'BAB200':
                        setBabList(response.data.message || []);
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('List Bab error :', error);
            }).finally((info) => {

            });
    }, [dispatch]);

    useEffect(() => {
        listSubbabTrainer().then(
            (response) => {
                switch (response.data.status_code) {
                    case 'SBB200':
                        setSubbabList(response.data.message || []);
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('List Subbab error :', error);
            }).finally((info) => {

            });
    }, [dispatch]);

    const addFunction = () => {
        setKuisAkhirData();
        setIsEdit(false);
        setShowPopUp(prev => !prev);
    }

    const editFunction = (row) => {
        setKuisAkhirData(row);
        setIsEdit(true);
        setShowPopUp(prev => !prev);
    }

    const deleteFunction = (row) => {
        setKuisAkhirData(row);
        setShowPopUpDelete(prev => !prev);
    }

    const detailFunction = (row) => {
        setId(row.id);
        setKuisAkhirData(row);
        setSearchParams({ id: row.id });
    }

    return (
        <>
            {!id && <KuisAkhirPopUp setRefreshData={setRefreshData}
                show={showPopUp} setShow={setShowPopUp}
                kuisAkhirData={kuisAkhirData} isEdit={isEdit}
                topikList={topikList}
                babList={babList}
                subBabList={subBabList}
            />}
            {!id && <KuisAkhirPopUpDelete
                show={showPopUpDelete} setShow={setShowPopUpDelete}
                setShowDeleteConfirmation={setShowDeleteConfirmation}
            />}
            {!id && <KuisAkhirDeleteConfirmation setRefreshData={setRefreshData}
                show={showDeleteConfirmation}
                setShow={setShowDeleteConfirmation}
                kuisAkhirData={kuisAkhirData}
                setShowPopUpDelete={setShowPopUpDelete}
            />}
            {id && <KuisAkhirDetail
                kuisAkhirData={data.find(item => item.id === id)}
                setSearchParams={setSearchParams}
            />}
            {!id && <Table
                data={data}
                rowClickFunction={detailFunction}
                columns={columns(editFunction, deleteFunction)}
                addFunction={addFunction}
                title='Kuis Akhir Digital Bootcamp 79' />
            }
        </>
    );
}

export default KuisAkhir;




import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Banner from './Banner';
import ResetPasswordForm from './ResetPasswordForm';

function ResetPage(props) {
    return (
        <Container fluid className='vh-100 m-0 d-flex align-items-center'
            style={{ padding: '9rem' }}
        >
            <Row className='w-100'>
                <Col>
                    <Banner {...props} />
                </Col>
                <Col>
                    <ResetPasswordForm {...props} />
                </Col>
            </Row>
        </Container>
    );
}

export default ResetPage;

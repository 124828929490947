import React from 'react';

import { Button, Modal, Row, Col, Image, ProgressBar } from 'react-bootstrap';

function ProfilPopup({ show, setShow, profileData, setShowPasswordPopup, setShowEditProfilPopup }) {
    const profilePhoto = '/img/avatar.png';
    const profileExpProgress = profileData.expLevel ? profileData.expProgress / profileData.expLevel * 100 : 100;
    const profileExpNeeded = profileData.expLevel ? profileData.expLevel - profileData.expProgress : 0;

    const firstRow = <Row className='mx-0 mb-4 justify-content-md-center'>
        <Col lg='auto' className='d-grid align-content-center'>
            <div className='image-container' style={{ margin: '0 auto' }}>
                <Image src={profilePhoto} className='nav-icon' />
            </div>
            <span className='talent-name mt-3'>{profileData.nama}</span>
        </Col>
    </Row>

    const levelField = <Col>
        <Row>
            <Col lg='auto'>
                <Image src='/img/level.png' className='navigation-profile-icon' />
            </Col>
            <Col lg='auto'>
                <span>Level</span>
            </Col>
            <Col lg='auto ms-auto'>
                <span>{profileData.level}</span>
            </Col>
        </Row>
    </Col>

    const coinField = <Col>
        <Row>
            <Col lg='auto'>
                <Image src='/img/coin.png' className='navigation-profile-icon' />
            </Col>
            <Col lg='auto'>
                <span>Coin</span>
            </Col>
            <Col lg='auto ms-auto'>
                <span>{profileData.coin}</span>
            </Col>
        </Row>
    </Col>

    const secondRow = <Row className='mx-0 mb-4'>
        {levelField}
        {coinField}
    </Row>

    const thirdRow = <Row className='mx-0 mb-4'>
        <Col lg={12}>
            <Row>
                <Col lg='auto'>
                    <Image src='/img/xp.png' className='navigation-profile-icon' />
                </Col>
                <Col lg='auto'>
                    <span>Total Experience</span>
                </Col>
                <Col lg='auto ms-auto'>
                    <span>{profileData.totalExp}</span>
                </Col>
            </Row>
        </Col>
    </Row>

    const fourthRow = <>
        <Row className='mx-0 xp-row'>
            <Col>XP
                <ProgressBar now={profileExpProgress} className='progress-basic' />
            </Col>
        </Row>
        <Row className="xp-row mb-4 ">
            <Col className="d-flex flex-column justify-content-center">
                <div className="mx-auto d-inline-block">
                    {
                        profileExpNeeded ?
                            `${profileExpNeeded} XP lagi untuk naik level` :
                            'Selamat! Anda sudah mencapai level tertinggi'
                    }
                </div>
            </Col>
        </Row>
    </>

    const fifthRow = <Row className='mx-0 mb-3'>
        <Col className='d-flex flex-row justify-content-between'>
            <Button onClick={() => {
                setShow(false);
                setShowEditProfilPopup(true);
            }
            }
                variant="outline-primary" className='button-foot'>Ubah Profil</Button>
            <Button onClick={() => {
                setShow(false);
                setShowPasswordPopup(true);
            }
            }
                variant="outline-primary" className='button-foot'>Ubah Password</Button>
        </Col>
    </Row>


    return (
        <Modal show={show} onHide={() => setShow(prev => !prev)} centered>
            <Modal.Header closeButton className='profil-modal-header'>
                <Image className="logo79" src='/img/logotujuhsembilan-mod.png' />
            </Modal.Header>
            <Modal.Body className='profil-modal-body'>
                {firstRow}
                {secondRow}
                {thirdRow}
                {fourthRow}
                {fifthRow}
            </Modal.Body>
        </Modal>
    );
}

export default ProfilPopup;

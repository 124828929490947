import React, { useEffect, useState } from 'react';
import { Button, Image, Modal, Stack } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { deleteCatatanTalent } from '../../service/CatatanService';
import { showErrorAlert, hideErrorAlert, showSuccessAlert, hideSuccessAlert } from '../../../app';

export default function CatatanPopupDelete({ show, setShow, data, setRefreshData }) {
    const dispatch = useDispatch();
    const [id, setId] = useState();

    useEffect(() => {
        if (show) {
            setId(data?.id);
        } else {
            setId();
        }
    }, [show, data]);

    const deleteCatatan = () => {
        deleteCatatanTalent({ id }).then(
            (response) => {
                switch (response.data.status_code) {
                    case 'CTN200':
                        dispatch(showSuccessAlert('Catatan berhasil dihapus'));
                        setRefreshData(prev => prev + 1);
                        setShow(prev => !prev);
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('Delete Catatan error :', error);
            }).finally((info) => {

            });
    }

    const handleSubmit = (event) => {
        dispatch(hideErrorAlert());
        dispatch(hideSuccessAlert());
        deleteCatatan();
    }

    return (
        <Modal show={show} onHide={() => setShow(prev => !prev)} centered>
            <Modal.Header className='border-bottom-0' closeButton>
            </Modal.Header>
            <Modal.Body className='text-center fsz-1125'>
                <Stack>
                    <Image src='/img/error_outline.png'
                        className='mx-auto mb-4' style={{ width: '5rem' }} />
                    Apakah anda yakin akan menghapus catatan tersebut?
                </Stack>
            </Modal.Body>
            <Modal.Footer className='d-flex justify-content-evenly'>
                <Button variant='light' onClick={() => setShow(prev => !prev)}
                    style={{ color: '#0078D7', border: '1px solid #0078D7', width: '9rem' }}
                >
                    Tidak
                </Button>
                <Button type='submit' variant='primary'
                    onClick={(e) => handleSubmit(e)}
                    style={{ width: '9rem' }}
                >
                    Ya
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

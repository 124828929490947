import axios from "axios";
import { trainer_whatsapp_learning_progress_endpoint, trainer_whatsapp_learning_report_endpoint } from "../../app";
import { trainer_whatsapp_learning_reminder_not_active_endpoint } from "../../app/constant";
import { authHeader } from "./AuthHeader";

export const sendWhatsappLearningProgress = ({ learningReport}) => {
  return axios({
    url: trainer_whatsapp_learning_progress_endpoint,
    method: 'POST',
    headers: { 'Content-type': 'application/json', ...authHeader() },
    data: {
      learningReport
    },
  }).then((response) => {
    console.log('response :', response.data);
    return response;
  });
};

export const sendWhatsappLearningReport = ({ learningReport}) => {
  return axios({
    url: trainer_whatsapp_learning_report_endpoint,
    method: 'POST',
    headers: { 'Content-type': 'application/json', ...authHeader() },
    data: {
      learningReport
    },
  }).then((response) => {
    console.log('response :', response.data);
    return response;
  });
};

export const sendWhatsappRiwayatLogin = ( payload ) => {
  return axios({
    url: trainer_whatsapp_learning_reminder_not_active_endpoint,
    method: 'POST',
    headers: { 'Content-type': 'application/json', ...authHeader() },
    data: payload,
  }).then((response) => {
    console.log('response :', response.data);
    return response;
  });
};
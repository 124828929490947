import axios from "axios";
import { talent_rangkuman_endpoint } from "../../app";
import { authHeader } from "./AuthHeader";

export const listRangkumanService = ({idSubbab}) => {
    return axios({
        url: talent_rangkuman_endpoint,
        method: 'GET',
        headers: {'Content-type': 'application/json', ...authHeader()},
        params: { idSubbab }
    }).then((response) => {
        console.log('List Rangkuman Result :', response.data);
        return response;
    });
};

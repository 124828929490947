import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { showErrorAlert } from '../../../app';
import { GetParentPath } from '../../../common';

// Component
import { Col, Container, Row, Button, Form, Image } from 'react-bootstrap';
import { Header } from '../../common/header';
import { showSuccessAlert } from '../../../app';
import { faCheckCircle, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


//Service
import { getProfile } from '../../service/ProfileService';
import { jawabSoalPraktik, getSoalPraktikById } from '../../service/PraktikService';
import { Link, useSearchParams } from 'react-router-dom';

const AlertPraktik = ({ show = false, status, message }) => (
    <Col className={`${show ? '' : 'd-none'}`}>
        <h4 style={{ fontWeight: 'bold', color: `${status ? '#34a853' : '#EA4335'}` }}>
            <FontAwesomeIcon icon={status ? faCheckCircle : faXmarkCircle} className='me-3' />
            {status ? 'Jawaban Benar' : 'Jawaban Salah'}
        </h4>
        <span>{message}</span>
    </Col>
)

function UjianPraktik() {
    const [refreshData, setRefreshData] = useState(1);
    const [profileData, setProfileData] = useState({});
    const [jawaban, setJawaban] = useState('');
    const [soal, setSoal] = useState({});
    const [showAlert, setShowAlert] = useState(false);
    const [statusJawaban, setStatusJawaban] = useState();
    const [msgAlert, setMsgAlert] = useState('');
    const [disabled, setDisabled] = useState(false);

    const [searchParams, setSearchParams] = useSearchParams();
    const [idSoal, setIdSoal] = useState(searchParams.get('idSoal'));
    const [idTopik, setIdTopik] = useState(searchParams.get('idTopik'));
    const [adaSoal, setAdaSoal] = useState(true);
    const dispatch = useDispatch();

    const parentPath = GetParentPath();

    useEffect(() => {
        console.log("Id soal adalah" + idSoal);
        getSoalPraktikById({ idSoal }).then(
            (response) => {
                switch (response.data.status_code) {
                    case 'SPK200':
                        setAdaSoal(true);
                        setSoal(response.data.message);
                        break;
                    case 'SPK401':
                    case 'SPK402':
                        setAdaSoal(false);
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('Get ID Soal Latihan/Ujuan Praktik error :', error);
            }).finally((info) => {

            });
    }, [idSoal, dispatch]);

    useEffect(() => {
        getProfile().then(
            (response) => {
                switch (response.data.status_code) {
                    case 'TAL200':
                        setProfileData(response.data.message || {});
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('Get Profile error :', error);
            })
    }, [dispatch])

    useEffect(() => {
        if (!idSoal) return;
        const savedResult = JSON.parse(localStorage.getItem(idSoal));
        if (!savedResult) return;

        setJawaban(savedResult.jawaban);
        setShowAlert(true);
        setStatusJawaban(savedResult.status);
        setMsgAlert(savedResult.message)
        setDisabled(true);
    }, [dispatch, refreshData, idSoal]);

    const savePraktikAnswer = ({ jawaban, status, message }) => {
        localStorage.setItem(idSoal,
            JSON.stringify({
                jawaban, status, message
            })
        );
    }

    const jawabSoal = () => {
        setShowAlert(false);
        jawabSoalPraktik({
            idSoal, jawaban
        }).then(
            (response) => {
                switch (response.data.status_code) {
                    case 'SPK200':
                        setShowAlert(true);
                        setStatusJawaban(true);
                        setMsgAlert('Kamu berhasil melewati test case');
                        savePraktikAnswer({ jawaban, status: true, message: 'Kamu berhasil melewati test case' });
                        setRefreshData(prev => prev + 1);
                        break;
                    case 'SPK201':
                    case 'SPK202':
                    case 'SPK203':
                    case 'SPK204':
                        setShowAlert(true);
                        setStatusJawaban(false);
                        setMsgAlert(response.data.message);
                        savePraktikAnswer({ jawaban, status: false, message: response.data.message });
                        setRefreshData(prev => prev + 1);
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('Jawab Soal Praktik error :', error);
            }).finally((info) => {

            });
    }

    const handleOnJawabanChange = (e) =>{
        setJawaban(e.target.value)
        setShowAlert(false);
    }

    const formJawaban = (
        <>
            <Container id="jawaban" className='rounded' fluid>
                <Row style={{ marginBottom: 22 }}>
                    <Col>
                        <span className='title'>Jawaban</span>
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <Form.Control as="textarea" rows={3} value={jawaban} onChange={(e) => { handleOnJawabanChange(e) }} disabled={disabled} />
                        </Form.Group>
                    </Col>
                </Row>
            </Container>
            <Row style={{ marginTop: 36 }} className='d-flex flex-row-reverse'>
                <Col xs='auto'>
                    <Button type='submit' variant="success" className='button-success'
                        onClick={() => { jawabSoal() }} size="sm" disabled={disabled}>Submit</Button>
                </Col>
                <AlertPraktik show={showAlert} status={statusJawaban} message={msgAlert} />
            </Row>
        </>
    )

    return (
        <Container id='main-wrapper' className='m-0 p-0' fluid>
            <Row>
                <Col>
                    <Header profileData={profileData} setRefreshData={setRefreshData} />
                    <Container id="content" className='m-0 pb-5' fluid>
                        <Row style={{ marginBottom: 22 }}>
                            <Col><span className='title'>Ujian Praktik</span></Col>
                            <Col style={{ display: 'flex', justifyContent: 'right' }}>
                                <Link to={`${parentPath}/bab?idTopik=${idTopik}`}>
                                    <Button variant="outline-danger" className='button-outline-danger'>Kembali</Button>
                                </Link>
                            </Col>
                        </Row>
                        <Container id="soal-praktik" className='m-0 rounded' fluid>
                            <Row>
                                <Col lg={4} style={{ paddingRight: 65 }}>
                                    <Image src="/img/praktik.png" width="420" />
                                </Col>
                                <Col lg={8}>
                                    <Row>
                                        <Col className='soal-title'>{adaSoal?soal.Judul:"Topik Ini Tidak Memiliki Soal Ujian Praktik"}</Col>
                                    </Row>
                                    <Row>
                                        <Col>{soal.Deskripsi}</Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Container>
                        {adaSoal ? formJawaban : ""}
                    </Container>
                </Col>
            </Row>

        </Container>
    );
}

export default UjianPraktik;
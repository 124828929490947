import { useEffect } from 'react';

const WhatsappButton = () => {
  useEffect(() => {
    const url = 'https://wati-integration-prod-service.clare.ai/v2/watiWidget.js?88611';
    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.async = true;
    s.src = url;

    const options = {
      enabled: true,
      chatButtonSetting: {
        backgroundColor: '#00e785',
        ctaText: 'Chat with us',
        borderRadius: '25',
        marginLeft: '0',
        marginRight: '20',
        marginBottom: '20',
        ctaIconWATI: false,
        position: 'right',
      },
      brandSetting: {
        brandName: 'Wati',
        brandSubTitle: 'undefined',
        brandImg:
          'https://www.wati.io/wp-content/uploads/2023/04/Wati-logo.svg',
        welcomeText: 'Hi there!\nHow can I help you?',
        messageText: 'Hello, %0A I have a question about this section',
        backgroundColor: '#00e785',
        ctaText: 'Chat with us',
        borderRadius: '25',
        autoShow: false,
        phoneNumber: '628112277979',
      },
    };

    s.onload = function () {
      window.CreateWhatsappChatWidget(options);
    };

    const x = document.getElementsByTagName('script')[0];
    x.parentNode.insertBefore(s, x);
  }, []);

  return null;
};

export default WhatsappButton;

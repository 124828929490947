import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

// component
import FileSaver from "file-saver";
import {
  showErrorAlert,
  showSuccessAlert
} from "../../../app";
import { Table } from "../../common/table";
import { columns } from "./columns";

// service
import moment from "moment";
import {
  editMasterTalent,
  exportMasterTalent,
  getAllMasterTalent,
} from "../../service/MasterTalentService";
import MasterTalentPopup from "./MasterTalentPopup";

function MasterTalent() {
  const dispatch = useDispatch();
  const [dataMasterTalent, setDataMasterTalent] = useState([]);
  const [filterSearch, setFilterSearch] = useState("");
  const [show, setShow] = useState(false);
  const [row, setRow] = useState(null);
  const [date, setDate] = useState(
    moment(new Date()).format("DDMMYYYY_hhmmss")
  );
  const [reloadData, setReloadData] = useState(false);

  const fetchMasterTalent = (val, filter) => {
    const obj =
      filter === "joinDate"
        ? {
            joinDateFrom: val?.joinDateFrom,
            joinDateTo: val?.joinDateTo,
          }
        : { [filter]: val };
    setFilterSearch(obj);
    getAllMasterTalent(obj)
      .then(
        (response) => {
          switch (response.data.status_code) {
            case "TAL200":
              setDataMasterTalent(response.data.message || 0);
              break;
            default:
              dispatch(showErrorAlert("Terjadi kesalahan pada server!"));
              break;
          }
        },
        (error) => {
          console.log("Total Talent Join error :", error);
        }
      )
      .finally((info) => {});
  };

  const fetchExportMasterTalent = () => {
    exportMasterTalent(filterSearch)
      .then(
        (response) => {
          switch (response.status) {
            case 200:
              FileSaver.saveAs(response.data, `Master Talent_${date}.xlsx`);
              break;
            default:
              dispatch(showErrorAlert("Terjadi kesalahan pada server!"));
              break;
          }
        },
        (error) => {
          console.log("Total Talent Join error :", error);
        }
      )
      .finally((info) => {});
  };

  useEffect(() => {
    fetchMasterTalent();
  }, [dispatch]);

  const onClose = () => {
    setShow(false);
  };

  const onEdit = (row) => {
    setShow(true);
    setRow(row);
  };

  const updateDataTalent = (value) => {
    const body = {
      talentID: value?.talentID,
      email: value?.email,
      phoneNumber: value?.nomorTelp,
      noWa: value?.noWa,
      gender: value?.jenisKelamin?.value,
      institutionType: value?.tipeLembaga,
      institutionName: value?.asalLembaga?.value,
      generation: value?.generasi,
      statusPeserta: value?.statusPeserta.label,
      statusPesertaID: value?.statusPeserta.value,
      keterangan: value?.keterangan,
      siapMasukBulan: value?.siapMasukBulan?.value,
    };
    console.log(body)
    editMasterTalent(body)
      .then(
        (response) => {
          switch (response.data.status_code) {
            case "TAL200":
              dispatch(showSuccessAlert("Talent berhasil diubah"));
              setShow(false);
              console.log("filterSearch", filterSearch);
              if (filterSearch?.joinDateFrom && filterSearch?.joinDateTo) {
                fetchMasterTalent(filterSearch, "joinDate");
              } else {
                fetchMasterTalent(filterSearch?.keywordSearch, "keywordSearch");
              }
              break;
            default:
              dispatch(showErrorAlert("Terjadi kesalahan pada server!"));
              break;
          }
        },
        (error) => {
          console.log("Update Talent error :", error);
        }
      )
      .finally((info) => {});
  };

  return (
    <>
      <Table
        data={dataMasterTalent}
        columns={columns(onEdit)}
        showAddButton={false}
        showSearch={false}
        showFilter={false}
        showCustomize={false}
        customSearch={true}
        onSearchChange={(val, filter) => fetchMasterTalent(val, filter)}
        onExport={fetchExportMasterTalent}
        title="Master Talent"
        customFilterSearch={true}
        filterJoinDate={true}
        filterLastLoginDate={false}
        rowClickFunction={(e) => {}}
      />
      <MasterTalentPopup
        show={show}
        onClose={onClose}
        data={row}
        updateData={updateDataTalent}
        getItemPage = {()=>{}}
      />
    </>
  );
}

export default MasterTalent;

import React, { useEffect, useState } from 'react';
import { Button, Card, Container } from 'react-bootstrap';
import { MdLibraryBooks } from 'react-icons/md';
import { RiAccountBoxFill } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import { GetParentPath } from '../../../common';

function TopikCard({ topikData: { id, thumbnail, judul, tag, jumlahBab, tingkatan } }) {
    const navigate = useNavigate();
    const [toDetail, setToDetail] = useState(false);
    const parentPath = GetParentPath();

    useEffect(() => {
        if (toDetail) navigate(`${parentPath}/bab?idTopik=${id}`);
    }, [id, toDetail, parentPath, navigate]);

    const cardImg = <Card.Img variant='top'
        src={thumbnail}
        style={{
            height: '100%', width: '100%',
            objectFit: 'cover',
        }}
    />

    const cardTitle = <Card.Title className='fw-bold mb-1 text-ellipsis'>
        {judul}
    </Card.Title>

    const cardTag = <Card.Text className='fsz-075 fw-light mb-2 text-ellipsis'>
        Tag : {tag}
    </Card.Text>

    const cardJumlahBab = <Card.Text className='mb-2 d-flex align-items-center'>
        <MdLibraryBooks className='me-1' style={{ color: '#757575' }} /> {jumlahBab} Bab
    </Card.Text>

    const renderTingkatanStyle = (tingkatan) => {
        switch (tingkatan) {
            case 'mudah':
                return { label: 'Mudah', color: '#34A853' };
            case 'sedang':
                return { label: 'Sedang', color: '#FBBC04' };
            case 'sulit':
                return { label: 'Sulit', color: '#EA4335' };
            default:
                return { label: '', color: '#000000' };
        }
    }

    const renderTingkatan = (tingkatan) => {
        const style = renderTingkatanStyle(tingkatan);
        return (
            <span style={{ color: style.color }}
                className='fw-bold'
            >
                {style.label}
            </span>
        );
    }

    const cardTingkatan = <Card.Text className='mb-3 d-flex align-items-center'>
        <RiAccountBoxFill className='me-1' style={{ color: '#757575' }} /> Tingkatan &nbsp;{renderTingkatan(tingkatan)}
    </Card.Text>

    const cardButton = <Container className='text-center'>
        <Button variant='light' className='pilih-topik-button'
            onClick={(e) => setToDetail(prev => !prev)}
        >
            Pilih Topik
        </Button>
    </Container>

    return (
        <Card className='w-100 h-100'
            style={{ borderRadius: '1rem' }}
        >
            <Container fluid className='p-0 ratio ratio-4x3 bg-light'
                style={{ borderRadius: '0.25rem 0.25rem 0 0' }}
            >
                <Container className='p-0 d-flex align-items-center justify-content-center'>
                    {cardImg}
                </Container>
            </Container>
            <Card.Body className='p-4 pt-3'
                style={{ color: '#263238' }}
            >
                {cardTitle}
                {cardTag}
                {cardJumlahBab}
                {cardTingkatan}
                {cardButton}
            </Card.Body>
        </Card>
    );
}

export default TopikCard;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { showErrorAlert } from '../../../app';
import { listCekKemampuanTalent } from '../../service/CekKemampuanService';
import { listSoalCekKemampuanTalent } from '../../service/SoalCekKemampuan';
import { getTopikTalent } from '../../service/TopikService';
import CekKemampuanEndConfirmation from './CekKemampuanEndConfirmation';
import CekKemampuanEnding from './CekKemampuanEnding';
import CekKemampuanMain from './CekKemampuanMain';
import CekKemampuanOpening from './CekKemampuanOpening';

export default function CekKemampuan() {
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const [idTopik, setIdTopik] = useState(searchParams.get('idTopik') || '');
    const [refreshData, setRefreshData] = useState(1);
    const accountId = useSelector(state => state.authTalent.accountId);
    const [loading, setLoading] = useState(true);

    const [nilai, setNilai] = useState(0);
    const [startKuis, setStartKuis] = useState(false);
    const [endKuis, setEndKuis] = useState(false);
    const [pembahasan, setPembahasan] = useState(false);
    const [timeEllapsed, setTimeEllapsed] = useState('00:00:00');

    const [jawabanBenar, setJawabanBenar] = useState(0);
    const [jawabanSalah, setJawabanSalah] = useState(0);
    const [dilewati, setDilewati] = useState(0);

    const [cekKemampuan, setCekKemampuan] = useState({});
    const [listSoalCekKemampuan, setListSoalCekKemampuan] = useState([]);
    const [topik, setTopik] = useState({});

    const [showEndConfirmation, setShowEndConfirmation] = useState(false);
    const [activeSoalIndex, setActiveSoalIndex] = useState(0);
    const [choosenAnswers, setChoosenAnswers] = useState({});

    useEffect(() => {
        if (!cekKemampuan?.id) return;
        if (startKuis) return;
        const savedResult = JSON.parse(localStorage.getItem(`${cekKemampuan.id}-${accountId}`));
        if (!savedResult) return;

        setEndKuis(true);
        setJawabanBenar(savedResult.jawabanBenar);
        setJawabanSalah(savedResult.jawabanSalah);
        setDilewati(savedResult.dilewati);
        setNilai(savedResult.nilai);
        setTimeEllapsed(savedResult.timeEllapsed);
        setChoosenAnswers(savedResult.choosenAnswers);
    }, [accountId, cekKemampuan, startKuis])

    useEffect(() => {
        if (pembahasan) setStartKuis(true);
    }, [pembahasan]);

    useEffect(() => {
        if (startKuis) setEndKuis(false);
        if (!startKuis) setPembahasan(false);
    }, [startKuis]);

    useEffect(() => {
        if (endKuis) {
            setStartKuis(false);
            setPembahasan(false);
        }
    }, [endKuis]);

    useEffect(() => {
        setActiveSoalIndex(0);
    }, [startKuis, endKuis, pembahasan])

    useEffect(() => {
        getTopikTalent({ id: idTopik }).then(
            (response) => {
                switch (response.data.status_code) {
                    case 'TPK200':
                        setTopik(response.data.message || {});
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('Get Topik error :', error);
            })
    }, [idTopik, dispatch]);

    useEffect(() => {
        listCekKemampuanTalent({ idTopik }).then(
            (response) => {
                switch (response.data.status_code) {
                    case 'KCK200':
                        setCekKemampuan(response.data.message[0] || {});
                        break;
                    case 'KCK476':
                        setLoading(false);
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('List Cek Kemampuan error :', error);
            })
    }, [idTopik, dispatch]);

    useEffect(() => {
        if (!cekKemampuan?.id) return;
        listSoalCekKemampuanTalent({ idCekKemampuan: cekKemampuan.id }).then(
            (response) => {
                switch (response.data.status_code) {
                    case 'SKK200':
                        setListSoalCekKemampuan(response.data.message || []);
                        setLoading(false);
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('List Soal Cek Kemampuan error :', error);
            })
    }, [cekKemampuan, dispatch]);

    const openingState = (!startKuis && !endKuis);
    const mainState = (startKuis);
    const endingState = (endKuis);

    return (
        <>
            <CekKemampuanEndConfirmation
                nilai={nilai}
                show={showEndConfirmation}
                setShow={setShowEndConfirmation}
                setStartKuis={setStartKuis}
                setEndKuis={setEndKuis}
            />
            {openingState && <CekKemampuanOpening
                kuis={cekKemampuan}
                topik={topik}
                listSoalKuis={listSoalCekKemampuan}
                setChoosenAnswers={setChoosenAnswers}
                setStartKuis={setStartKuis}
                loading={loading}
            />}
            {mainState && <CekKemampuanMain
                nilai={nilai} setNilai={setNilai}
                pembahasan={pembahasan} setPembahasan={setPembahasan}
                activeSoalIndex={activeSoalIndex} setActiveSoalIndex={setActiveSoalIndex}
                choosenAnswers={choosenAnswers} setChoosenAnswers={setChoosenAnswers}
                setRefreshData={setRefreshData}

                show={showEndConfirmation}
                setShow={setShowEndConfirmation}
                timeEllapsed={timeEllapsed} setTimeEllapsed={setTimeEllapsed}
                endKuis={endKuis} setEndKuis={setEndKuis}

                topik={topik}
                listSoalKuis={listSoalCekKemampuan}
                kuis={cekKemampuan}

                jawabanBenar={jawabanBenar}
                jawabanSalah={jawabanSalah}
                dilewati={dilewati}

                setJawabanBenar={setJawabanBenar}
                setJawabanSalah={setJawabanSalah}
                setDilewati={setDilewati}
            />}
            {endingState && <CekKemampuanEnding
                jawabanBenar={jawabanBenar}
                jawabanSalah={jawabanSalah}
                dilewati={dilewati}
                nilai={nilai} setNilai={setNilai}
                topik={topik} timeEllapsed={timeEllapsed}
                setStartKuis={setStartKuis}
                setEndKuis={setEndKuis}
                setPembahasan={setPembahasan}
            />}
        </>
    );
}

import React from 'react';

import {
    useTable,
    useSortBy,
} from 'react-table';

import {
    Table as BTable,
    Container,
    Row, Col
} from 'react-bootstrap';

import { useDispatch } from 'react-redux';

import {
    faCaretDown,
    faCaretUp,
    faSort
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import uuid from 'react-uuid';


function Table({ title = 'Digital Bootcamp 79', data, columns }) {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({
        columns,
        data,
    },
        useSortBy,
    );

    return (
        <>
            <Row>
                <Col className='mt-5 mb-3' style={{fontWeight:"bold"}}>{title}</Col>
                <BTable {...getTableProps()} className='table-borderless'>
                    <thead style={{borderBottom:"1px solid"}}>
                        {headerGroups.map(headerGroup => (
                            <tr key={uuid()} {...headerGroup.getHeaderGroupProps()} >
                                {headerGroup.headers.map(column => (
                                    <th key={uuid()}
                                        {...column.getHeaderProps(column.getSortByToggleProps(), {
                                            style: { with: column.width }
                                        })}
                                    >
                                        <span style={{fontWeight:'normal'}}>{column.render('Header')}</span>
                                        <span className='ms-2'>
                                            {
                                                column.canSort
                                                    ? column.isSorted
                                                        ? column.isSortedDesc
                                                            ? <FontAwesomeIcon icon={faCaretUp} size='xs' />
                                                            : <FontAwesomeIcon icon={faCaretDown} size='xs' />
                                                        : <FontAwesomeIcon icon={faSort} size='xs' />
                                                    : ' '
                                            }
                                        </span>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {rows.map((row, i) => {
                            prepareRow(row);
                            return (
                                <tr key={uuid()} {...row.getRowProps()}>
                                    {row.cells.map(cell => {
                                        return <td {...cell.getCellProps()} className='align-middle'
                                            key={uuid()}
                                        >{cell.render('Cell')}</td>
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                </BTable>
            </Row>
        </>
    );
}

export default Table;

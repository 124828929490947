import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { showErrorAlert } from '../../../app';
import { Table } from '../../common/table';
import { listBabTrainer } from '../../service/BabService';
import { listSubbabTrainer } from '../../service/SubbabService';
import { listTagTrainer } from '../../service/TagService';
import { listTopikTrainer } from '../../service/TopikService';
import { columns } from './columns';
import SubbabPopUp from './SubbabPopUp';
import SubbabPopUpDelete from './SubbabPopUpDelete';
import SubbabPopUpDetail from './SubbabPopUpDetail';
import SubbabDeleteConfirmation from './SubbabDeleteConfirmation';

function Subbab() {
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const [showPopUp, setShowPopUp] = useState(false);
    const [showPopUpDelete, setShowPopUpDelete] = useState(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [showPopUpDetail, setShowPopUpDetail] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [subbabData, setSubbabData] = useState();

    const [refreshData, setRefreshData] = useState(1);
    const [topikList, setTopikList] = useState([]);
    const [babList, setBabList] = useState([]);
    const [tagList, setTagList] = useState([]);

    useEffect(() => {
        listTopikTrainer().then(
            (response) => {
                switch (response.data.status_code) {
                    case 'TPK200':
                        setTopikList(response.data.message || []);
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('Topik error :', error);
            }).finally((info) => {

            });
    }, [dispatch]);

    useEffect(() => {
        listBabTrainer().then(
            (response) => {
                switch (response.data.status_code) {
                    case 'BAB200':
                        setBabList(response.data.message || []);
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('Bab error :', error);
            }).finally((info) => {

            });
    }, [dispatch]);

    useEffect(() => {
        listTagTrainer().then(
            (response) => {
                switch (response.data.status_code) {
                    case 'TAG200':
                        setTagList(response.data.message);
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('Tag error :', error);
            }).finally((info) => {

            });
    }, [dispatch]);

    useEffect(() => {
        listSubbabTrainer().then(
            (response) => {
                switch (response.data.status_code) {
                    case 'SBB200':
                        setData(response.data.message || []);
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('Subbab error :', error);
            }).finally((info) => {

            });
    }, [dispatch, refreshData]);

    const addFunction = () => {
        setSubbabData();
        setIsEdit(false);
        setShowPopUp(prev => !prev);
    }

    const editFunction = (row) => {
        setSubbabData(row);
        setIsEdit(true);
        setShowPopUp(prev => !prev);
    }

    const deleteFunction = (row) => {
        setSubbabData(row);
        setShowPopUpDelete(prev => !prev);
    }

    const detailFunction = (row) => {
        setSubbabData(row);
        setShowPopUpDetail(prev => !prev);
    }

    return (
        <>
            <SubbabPopUp setRefreshData={setRefreshData}
                topikList={topikList}
                babList={babList}
                tagList={tagList}
                show={showPopUp} setShow={setShowPopUp}
                subbabData={subbabData} isEdit={isEdit}
            />
            <SubbabPopUpDelete
                show={showPopUpDelete}
                setShow={setShowPopUpDelete}
                subbabData={subbabData}
                setShowDeleteConfirmation={setShowDeleteConfirmation}
            />
            <SubbabDeleteConfirmation setRefreshData={setRefreshData}
                show={showDeleteConfirmation}
                setShow={setShowDeleteConfirmation}
                subbabData={subbabData}
                setShowPopUpDelete={setShowPopUpDelete}
            />
            <SubbabPopUpDetail
                show={showPopUpDetail}
                setShow={setShowPopUpDetail}
                subbabData={subbabData}
            />
            <Table data={data}
                rowClickFunction={detailFunction}
                columns={columns(editFunction, deleteFunction)}
                addFunction={addFunction}
                title='SubBab Digital Bootcamp 79'
            />
        </>
    );
}

export default Subbab;

import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";

// component
import { Table } from "../../common/table";
import { columns } from "./columns";
import { pushCrumb, popCrumb } from "../../common/breadcrumbs";
import FileSaver from "file-saver";

// service
import {
  getAllMasterLembaga,
  createMasterLembaga,
  updateMasterLembaga,
  deleteMasterLembaga,
} from "../../service/MasterLembagaService";
import MasterLembagaPopup from "./MasterLembagaPopup";
import {
  showErrorAlert,
  hideErrorAlert,
  showSuccessAlert,
  hideSuccessAlert,
} from "../../../app";
import PopupDelete from "./PopupDelete";

function MasterLembaga() {
  const dispatch = useDispatch();
  const [dataMasterLembaga, setDataMasterLembaga] = useState([]);
  const [filterSearch, setFilterSearch] = useState("");
  const [show, setShow] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [type, setType] = useState("");
  const [row, setRow] = useState(null);

  const fetchMasterLembaga = (val) => {
    setFilterSearch(val);
    getAllMasterLembaga({ keywordSearch: val })
      .then(
        (response) => {
          switch (response.data.status_code) {
            case "LBG200":
              setDataMasterLembaga(response.data.message);
              break;
            default:
              dispatch(showErrorAlert("Terjadi kesalahan pada server!"));
              break;
          }
        },
        (error) => {
          console.log("Total Talent Join error :", error);
        }
      )
      .finally((info) => {});
  };

  const createDataMasterLembaga = (value) => {
    const body = {
      institutionId: row?.id_lembaga,
      institutionType: parseInt(value?.institutionType?.value),
      institutionName: value?.institutionName,
      institutionCity: value?.institutionCity?.value
    };
    console.log("body create", body);
    createMasterLembaga(body)
      .then(
        (response) => {
          switch (response.data.status_code) {
            case "LBG200":
              dispatch(showSuccessAlert("Lembaga berhasil ditambahkan"));
              setShow(false);
              fetchMasterLembaga(filterSearch);
              break;
            default:
              dispatch(showErrorAlert("Terjadi kesalahan pada server!"));
              break;
          }
        },
        (error) => {
          console.log("Create Master Lembaga error :", error);
        }
      )
      .finally((info) => {});
  };

  const updateDataMasterLembaga = (value) => {
    const body = {
      institutionId: row?.id_lembaga,
      institutionType: parseInt(value?.institutionType?.value),
      institutionName: value?.institutionName,
      institutionCity: value?.institutionCity?.value
    };
    console.log("body edit", body);
    updateMasterLembaga(body)
      .then(
        (response) => {
          switch (response.data.status_code) {
            case "LBG200":
              dispatch(showSuccessAlert("Lembaga berhasil diubah"));
              setShow(false);
              fetchMasterLembaga(filterSearch);
              break;
            default:
              dispatch(showErrorAlert("Terjadi kesalahan pada server!"));
              break;
          }
        },
        (error) => {
          console.log("Update Master Lembaga error :", error);
        }
      )
      .finally((info) => {});
  };

  const deleteDataMasterLembaga = (value) => {
    const body = {
      institutionId: row?.id_lembaga,
    };
    deleteMasterLembaga(body)
      .then(
        (response) => {
          switch (response.data.status_code) {
            case "LBG200":
              dispatch(showSuccessAlert("Data Lembaga berhasil dihapus"));
              setShow(false);
              fetchMasterLembaga(filterSearch);
              break;
            default:
              dispatch(showErrorAlert("Terjadi kesalahan pada server!"));
              break;
          }
        },
        (error) => {
          console.log("Update Master Lembaga error :", error);
        }
      )
      .finally((info) => {});
  };

  useEffect(() => {
    fetchMasterLembaga();
  }, [dispatch]);

  const onClose = () => {
    setShow(false);
  };

  const onAdd = () => {
    setShow(true);
    setType("add");
  };

  const onEdit = (row) => {
    setShow(true);
    setType("edit");
    setRow(row);
  };

  const onDelete = (row) => {
    setShowDelete(true);
    setRow(row);
  };


  return (
    <>
      <Table
        data={dataMasterLembaga}
        columns={columns(onEdit, onDelete)}
        showAddButton={false}
        showSearch={false}
        showFilter={false}
        showCustomize={false}
        customSearch={true}
        onSearchChange={(val) => fetchMasterLembaga(val)}
        addButtonTitle="Tambah Lembaga"
        onAdd={onAdd}
        title="Lembaga"
        subTitle="Nama-nama lembaga yang bergabung"
        rowClickFunction={(e) => {}}
      />
      <MasterLembagaPopup
        show={show}
        type={type}
        onClose={onClose}
        data={row}
        fetchData={
          type === "add" ? createDataMasterLembaga : updateDataMasterLembaga
        }
      />
      <PopupDelete show={showDelete} setShow={setShowDelete} data={row} onDelete={deleteDataMasterLembaga}/>
    </>
  );
}

export default MasterLembaga;

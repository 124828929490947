import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { showErrorAlert } from '../../../app';
import { listSoalKuisMateriTalent } from '../../service/SoalKuisMateriService';
import SoalKuisMateri from './SoalKuisMateri';
import { LaporSoal } from '../../common/lapor-soal';
import { laporkanSoalMateriTalent } from '../../service/SoalKuisMateriService';

function KuisMateri({
    idKuisMateri, setRefreshData, activeItemIndex, subbabItemList,
    setActiveTabState, setActiveMateriId, setActiveKuisMateriId,
}) {
    const dispatch = useDispatch();
    const [listSoalKuisMateri, setListSoalKuisMateri] = useState([]);
    const [idSoal, setIdSoal] = useState();
    const [showLapor, setShowLapor] = useState(false);

    useEffect(() => {
        if (!idKuisMateri) return;
        listSoalKuisMateriTalent({ idKuisMateri }).then(
            (response) => {
                switch (response.data.status_code) {
                    case 'SKM200':
                        setListSoalKuisMateri(response.data.message || []);
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('List Soal Kuis Materi error :', error);
            })
    }, [idKuisMateri, dispatch]);

    return (
        <>
            {idSoal && <LaporSoal laporSoalTrainer={laporkanSoalMateriTalent} idSoal={idSoal} show={showLapor} setShow={setShowLapor}/>}
            <Container className='kuis-talent-materi-container bg-white p-4 rounded'>
                <SoalKuisMateri listSoalKuisMateri={listSoalKuisMateri}
                    idKuisMateri={idKuisMateri}
                    setRefreshData={setRefreshData}
                    setIdSoal={setIdSoal}
                    setShowLapor={setShowLapor}
                    activeItemIndex={activeItemIndex}
                    subbabItemList={subbabItemList}
                    setActiveTabState={setActiveTabState}
                    setActiveMateriId={setActiveMateriId}
                    setActiveKuisMateriId={setActiveKuisMateriId}
                />
            </Container>
        </>
    );
}

export default KuisMateri;

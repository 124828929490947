import axios from "axios";
import { talent_bab_endpoint } from "../../app";
import { authHeader } from "./AuthHeader";

export const listBabTalent = ({ idTopik }) => {
    return axios({
        url: talent_bab_endpoint,
        method: 'GET',
        headers: {'Content-type': 'application/json', ...authHeader()},
        params: { idTopik },
    }).then((response) => {
        console.log('List Bab Result :', response.data);
        return response;
    });
};

export const getBabTalent = ({ id }) => {
    return axios({
        url: talent_bab_endpoint + `/${id}`,
        method: 'GET',
        headers: { 'Content-type': 'application/json', ...authHeader() },
    }).then((response) => {
        console.log('Get Bab Result :', response.data);
        return response;
    });
};

import React from 'react';
import { Col, Container, Dropdown, Image } from 'react-bootstrap';

export default function Notification() {
  return (
    <Dropdown.Item eventKey="1" className="notification-row">
      <Container fluid className="p-0">
        <Col className="align-top p-0 image-col"><Image src="/img/Ellipse2.png" /></Col>
        <Col className="p-0">
          <p className="bold-this"><span className="user-name">Marcus Something</span> berkomentar di Video <span className="topic-name">Python 1</span></p>
          <p><span className="comment">"Lorem ipsum dolor sit amet, consectetur .........."</span></p>
        </Col>
      </Container>
    </Dropdown.Item>
  )
}

import axios from "axios";
import { talent_laporsoalkuismateri_endpoint, talent_soalkuismateri_endpoint } from "../../app";
import { authHeader } from "./AuthHeader";

export const listSoalKuisMateriTalent = ({ idKuisMateri }) => {
    return axios({
        url: talent_soalkuismateri_endpoint,
        method: 'GET',
        headers: { 'Content-type': 'application/json', ...authHeader() },
        params: { idKuisMateri },
    }).then((response) => {
        console.log('List Soal Kuis Materi Result :', response.data);
        return response;
    });
};

export const laporkanSoalMateriTalent = ({ idSoal, jenisLaporan }) => {
    return axios({
        url: talent_laporsoalkuismateri_endpoint,
        method: 'POST',
        headers: { 'Content-type': 'application/json', ...authHeader() },
        data: {
            idSoal, jenisLaporan,
        }
    }).then((response) => {
        console.log('Lapor Soal Kuis Materi Result :', response.data);
        return response;
    });
};

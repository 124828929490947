import axios from "axios";
import { base_url, port_learning, trainer_kuismateri_endpoint } from "../../app";
import { authHeader } from "./AuthHeader";

export const listKuisMateriTrainer = () => {
    return axios({
        url: trainer_kuismateri_endpoint,
        method: 'GET',
        headers: { 'Content-type': 'application/json', ...authHeader() },
    }).then((response) => {
        console.log('List Kuis Materi Result :', response.data);
        return response;
    });
};

export const addKuisMateriTrainer = ({ judul, idTopik, idBab, idSubbab, idMateri, visibilitas }) => {
    return axios({
        url: trainer_kuismateri_endpoint,
        method: 'POST',
        headers: { 'Content-type': 'application/json', ...authHeader() },
        data: {
            judul, idTopik, idBab, idSubbab, idMateri, visibilitas
        },
    }).then((response) => {
        console.log('Add Kuis Materi Result :', response.data);
        return response;
    });
};

export const editKuisMateriTrainer = ({ id, judul, idTopik, idBab, idSubbab, idMateri, visibilitas }) => {
    return axios({
        url: trainer_kuismateri_endpoint,
        method: 'PUT',
        headers: { 'Content-type': 'application/json', ...authHeader() },
        data: {
            id, judul, idTopik, idBab, idSubbab, idMateri, visibilitas
        },
    }).then((response) => {
        console.log('Edit Kuis Materi Result :', response.data);
        return response;
    });
};

export const deleteKuisMateriTrainer = ({ id }) => {
    return axios({
        url: trainer_kuismateri_endpoint,
        method: 'DELETE',
        headers: { 'Content-type': 'application/json', ...authHeader() },
        data: {
            id
        },
    }).then((response) => {
        console.log('Edit Kuis Materi Result :', response.data);
        return response;
    });
};

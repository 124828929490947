import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { showErrorAlert } from '../../../app';
import { Table } from '../../common/table';
import { video, kuis, latihansoal, ujian, latihanpraktik, ujianpraktik } from './detailcolumns';
import { detailLaporanBelajarTrainer } from '../../service/LaporanBelajarService';
import { Row, Col, Button, Image } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

function LaporanBelajarDetail({ id }) {
    const [searchParams, setSearchParams] = useSearchParams();
    const [laporanBelajarData, setLaporanBelajarData] = useState();

    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const [columns, setColumns] = useState(video);
    const [tableTitle, setTableTitle] = useState('Riwayat Video Ditonton');
    const [namaTalent, setNamaTalent] = useState('');

    useEffect(() => {
        detailLaporanBelajarTrainer({ idUser: id }).then(
            (response) => {
                switch (response.data.status_code) {
                    case 'LBE200':
                        setLaporanBelajarData(response.data.message || []);
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('Detail Laporan Belajar error :', error);
            }).finally((info) => {

            });
    }, [dispatch]);

    useEffect(() => {
        if (!laporanBelajarData) return
        setData(laporanBelajarData.listVideo || []);
        setNamaTalent(laporanBelajarData.namaTalent);
    }, [laporanBelajarData]);

    const handleVideo = () => {
        setData(laporanBelajarData?.listVideo || []);
        setColumns(video);
        setTableTitle('Riwayat Video Ditonton');
    };

    const handleKuis = () => {
        setData(laporanBelajarData?.listKuisAkhir || []);
        setColumns(kuis);
        setTableTitle('Riwayat Kuis Dikerjakan');
    };

    const handleLatihanSoal = () => {
        setData(laporanBelajarData?.listKuisBab || []);
        setColumns(latihansoal);
        setTableTitle('Riwayat Latihan Soal Dikerjakan');
    };

    const handleUjian = () => {
        setData(laporanBelajarData?.listCekKemampuan || []);
        setColumns(ujian);
        setTableTitle('Riwayat Ujian Dikerjakan');
    };

    const handleLatihanPraktik = () => {
        setData(laporanBelajarData?.listLatihanPraktik || []);
        setColumns(latihanpraktik);
        setTableTitle('Riwayat Latihan Praktik Dikerjakan');
    };

    const handleUjianPraktik = () => {
        setData(laporanBelajarData?.listUjianPraktik || []);
        setColumns(ujianpraktik);
        setTableTitle('Riwayat Ujian Praktik Dikerjakan');
    };

    var buttonData =
        [{ icon: '/img/play_circle_outline_24px.png', amountText: laporanBelajarData?.jumlahVideoDitonton + " Video", typeText: 'Video Ditonton', func: handleVideo },
        { icon: '/img/assignment_green.png', amountText: laporanBelajarData?.jumlahKuisAkhirSelesai + " Kuis", typeText: 'Kuis Dikerjakan', func: handleKuis },
        { icon: '/img/assignment_green.png', amountText: laporanBelajarData?.jumlahKuisBabSelesai + " Latihan Bab", typeText: 'Latihan Bab Selesai', func: handleLatihanSoal },
        // { icon: '/img/assignment_green.png', amountText: laporanBelajarData?.jumlahCekKemampuanSelesai + " Ujian", typeText: 'Ujian Dikerjakan', func: handleUjian },
        { icon: '/img/assignment_green.png', amountText: laporanBelajarData?.jumlahLatihanPraktikSelesai + " Latihan Praktik", typeText: 'Latihan Praktik Dikerjakan', func: handleLatihanPraktik },
        { icon: '/img/assignment_green.png', amountText: laporanBelajarData?.jumlahUjianPraktikSelesai + " Ujian Praktik", typeText: 'Ujian Praktik Selesai', func: handleUjianPraktik },]

    const buttonList = buttonData.map(item => {
        return (
            <Col key={item.typeText} className="button-list-item bg-white rounded border-2 m-2" onClick={e => { item.func() }}>
                <Row className="py-2">
                    <Col lg="auto" className="my-auto">
                        <Image src={item.icon} />
                    </Col>
                    <Col lg="auto" className="align-item-center">
                        <Row className="item-amountText text-primary">
                            {item.amountText}
                        </Row>
                        <Row className="item-typeText">
                            {item.typeText}
                        </Row>
                    </Col>
                </Row>
            </Col>
        )
    });

    return (
        <Row id="container-laporan-belajar" className="px-5 py-4">
            <Col lg={3} className="info-talent-col">
                <div className="px-4 py-3 bg-white border-2 rounded mx-auto">
                    <Row className="align-item-center" >
                        <Col lg="auto" className="p-0">
                            <Button onClick={e => setSearchParams()}>
                                <FontAwesomeIcon icon={faChevronLeft} size='xs' className='me-2' />
                            </Button>
                        </Col>
                        <Col className="d-inline my-auto p-0" lg='auto'>Info Talent</Col>
                    </Row>
                    <Row className="align-item-center justify-content-center mt-2">
                        <Col lg='auto p-0'>
                            <Image className="profile-image p-0" src='/img/avatar.png' width="36px" />
                        </Col>
                        <Col className="d-inline profile-name my-auto" lg='auto'>
                            {namaTalent}
                        </Col>
                    </Row>
                </div>
            </Col>
            <Col lg={9}>
                <Row lg={3} className="button-list justify-content-start mx-0">
                    {buttonList}
                </Row>
                <Table
                    showSearch={false}
                    showCustomize={false}
                    showAddButton={false}
                    showFilter={false}
                    data={data}
                    rowClickFunction={(e) => { }}
                    columns={columns}
                    title={tableTitle} />
            </Col>
        </Row>
    );
}

export default LaporanBelajarDetail;

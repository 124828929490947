import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { AiOutlineArrowLeft } from 'react-icons/ai'
import { useDispatch } from 'react-redux';

import { GetParentPath } from '../../../common';
import LaporanBelajarHeader from './LaporanBelajarHeader';
import LaporanBelajarKuis from './LaporanBelajarKuis';
import { listTopikTalent } from '../../service/TopikService';
import { showErrorAlert } from '../../../app';

export default function LaporanBelajar() {
    const dispatch = useDispatch();
    const parentPath = GetParentPath();
    const [topikList, setTopikList] = useState([]);

    useEffect(() => {
        listTopikTalent().then(
            (response) => {
                switch (response.data.status_code) {
                    case 'TPK200':
                        setTopikList(response.data.message || []);
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('Topik error :', error);
            }).finally((info) => {

            });
    }, [dispatch]);

    const backButton = <Link to={`${parentPath}/topik`}>
        <Button variant='light'
            className='back-laporan-belajar-button bg-transparent border-0 p-0 fsz-1125 mb-2'
        >
            <AiOutlineArrowLeft className='me-2' style={{ color: '#757575' }} />
            <span style={{ color: '#081F32' }}>Kembali</span>
        </Button>
    </Link>

    const headerTitle = <div className='py-2 fw-bold fs-3 mb-3'>Laporan Pembelajaran</div>

    return (
        <Container fluid className='px-5' style={{ paddingTop: '4rem', overflowX: 'hidden' }}>
            {backButton}
            {headerTitle}
            <LaporanBelajarHeader />
            <Row className='mt-5 m-0'>
                <Col className='bg-white p-4 px-5 rounded me-5'>
                    <LaporanBelajarKuis topikList={topikList} kuisType='kuis-bab' />
                </Col>
                <Col className='bg-white p-4 px-5 rounded'>
                    <LaporanBelajarKuis topikList={topikList} kuisType='latihan-praktik' />
                </Col>
            </Row>
            <Row className='mt-5 m-0'>
                <Col className='bg-white p-4 px-5 rounded me-5'>
                    <LaporanBelajarKuis topikList={topikList} kuisType='ujian-praktik' />
                </Col>
                <Col className='p-4 px-5 rounded'></Col>
                {/* <Col className='bg-white p-4 px-5 rounded'>
                    <LaporanBelajarKuis topikList={topikList} kuisType='cek-kemampuan' />
                </Col> */}
            </Row>
            {/* <Row className='mt-5 m-0'>
                <Col className='bg-white p-4 px-5 rounded me-5'>
                    <LaporanBelajarKuis topikList={topikList} kuisType='kuis-bab' />
                </Col>
                <Col className='bg-white p-4 px-5 rounded'>
                    <LaporanBelajarKuis topikList={topikList} kuisType='cek-kemampuan' />
                </Col>
            </Row>
            <Row className='mt-5 m-0'>
                <Col className='bg-white p-4 px-5 rounded me-5'>
                    <LaporanBelajarKuis topikList={topikList} kuisType='latihan-praktik' />
                </Col>
                <Col className='bg-white p-4 px-5 rounded'>
                    <LaporanBelajarKuis topikList={topikList} kuisType='ujian-praktik' />
                </Col>
            </Row> */}
        </Container>
    );
}

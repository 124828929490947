import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import {
  faBookOpen,
  faDesktop,
  faFileLines,
  faPlay,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect, useState } from "react";
import { Accordion, Col, Image, Nav, Navbar, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import { GetParentPath } from "../../../common";

function NavItemCustom({ icon, title, url }) {
  return (
    <Nav.Item>
      <NavLink to={url} className="nav-link ps-4">
        <Row>
          {icon && (
            <Col lg="auto">
              <FontAwesomeIcon icon={icon} className="nav-icon" />
            </Col>
          )}
          <Col lg="auto">
            <span>{title}</span>
          </Col>
        </Row>
      </NavLink>
    </Nav.Item>
  );
}

function Navigation(props) {
  const parentPath = GetParentPath();
  
  const logo = (
    <Image src="/img/logotujuhsembilan-mod.png" style={{ height: "5vh" }} />
  );
  const role = useSelector((state) => state.auth.role);
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

  const toggleNav = () => {
    setIsNavExpanded(!isNavExpanded);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    handleResize();
    
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const itemList = [
    { icon: faDesktop, title: "Dashboard", url: `${parentPath}/dashboard` },
    { icon: faBookOpen, title: "Daftar Topik", url: `${parentPath}/topik` },
    { icon: faBookOpen, title: "Daftar Bab", url: `${parentPath}/bab` },
    { icon: faBookOpen, title: "Sub Bab", url: `${parentPath}/subbab` },
    { icon: faPlay, title: "Materi", url: `${parentPath}/materi` },
    { icon: faFileLines, title: "Rangkuman", url: `${parentPath}/rangkuman` },
  ];

  const navItemList = itemList.map((item) => (
    <NavItemCustom
      key={item.title}
      icon={item.icon}
      title={item.title}
      url={item.url}
    />
  ));

  const accordianItemList = [
    {
      header: "Soal Kuis",
      item: [
        { title: "Kuis Materi", url: `${parentPath}/kuis-materi` },
        { title: "Kuis Akhir", url: `${parentPath}/kuis-akhir` },
        { title: "Kuis Bab", url: `${parentPath}/kuis-bab` },
        { title: "Cek Kemampuan", url: `${parentPath}/cek-kemampuan` },
        { title: "Praktik", url: `${parentPath}/praktik` },
      ],
    },
    {
      header: "Laporan",
      item: [
        { title: "Belajar", url: `${parentPath}/laporan-belajar` },
        { title: "Nilai", url: `${parentPath}/laporan-nilai` },
        { title: "Talent Terdaftar", url: `${parentPath}/talent-terdaftar` },
        { title: "Riwayat Login", url: `${parentPath}/riwayat-login` },
        { title: "Report Belajar", url: `${parentPath}/report-belajar` },
        { title: "Progress Belajar", url: `${parentPath}/progress-belajar` },
      ],
    },
    {
      header: "Master Data",
      item: [
        { title: "Talent", url: `${parentPath}/master-talent` },
        { title: "Lembaga", url: `${parentPath}/master-lembaga` },
      ],
    },
  ];

  const navAccordianItemList = accordianItemList.map((accordionItem, index) => (
    <Accordion.Item
      as={Nav.Item}
      eventKey={index}
      className="w-100 accordion-item-nav"
      key={accordionItem.header}
    >
      <Accordion.Header className="accordion-header-nav">
        <Row className="fsz-1125">
          <Col lg="auto">
            <FontAwesomeIcon icon={faFileLines} className="nav-icon" />
          </Col>
          <Col lg="auto" className="accordion-item-header">
            <span>{accordionItem.header}</span>
          </Col>
        </Row>
      </Accordion.Header>
      <Accordion.Body className="accordion-body-nav p-0">
        {accordionItem.item.map((accordionItemChild) => (
          <NavItemCustom
            key={accordionItemChild.title}
            icon={accordionItemChild.icon}
            title={accordionItemChild.title}
            url={accordionItemChild.url}
          />
        ))}
      </Accordion.Body>
    </Accordion.Item>
  ));

  return (
    <Navbar className={`flex-column bg-white ${isMobile ? "" : "vh-100"}`} expand="lg">
      <Navbar.Brand style={{ marginTop: "2rem", marginBottom: "3rem"}}>
        <Link to={`${parentPath}/topik`}>{logo}</Link>
      </Navbar.Brand>
      <Navbar.Toggle onClick={toggleNav} aria-controls="basic-navbar-nav" style={{ marginTop: "1rem", marginBottom: "1rem"}} />
      <Navbar.Collapse id="basic-navbar-nav" className={`flex-column w-100 fsz-1125 ${isNavExpanded ? 'show' : ''}`}>
        <Accordion as={Nav} className="flex-column w-100 fsz-1125" variant="pills"  style={{ overflowX: "hidden", overflowY: "auto" }}>
          {navItemList}
          {navAccordianItemList}
          <NavItemCustom
            icon={faWhatsapp}
            title={"Follow Up"}
            url={`${parentPath}/follow-up`}
          />
          {role === "admin" && (
            <NavItemCustom
              icon={faUser}
              title={"Kelola Akun"}
              url={`${parentPath}/kelola-akun`}
            />
          )}
        </Accordion>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default Navigation;

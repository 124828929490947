import React, { useEffect, useState, useMemo } from "react";
import {
  Button,
  Col,
  Form,
  FormControl,
  InputGroup,
  Modal,
  Row,
} from "react-bootstrap";
import { faEye, faEyeSlash, faInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from "react-select";
import { useDispatch } from "react-redux";
import {
  addAkunTrainer,
  editAkunTrainer,
  getOptLembaga,
} from "../../service/KelolaAkunService";
import _ from "lodash";
import {
  showErrorAlert,
  hideErrorAlert,
  showSuccessAlert,
  hideSuccessAlert,
} from "../../../app";

export default function PopupTambahEdit({
  show,
  setShow,
  akunData,
  isEdit,
  setRefreshData,
}) {
  const dispatch = useDispatch();

  const [id, setId] = useState();
  const [username, setUsername] = useState();
  const [namaLengkap, setNamaLengkap] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [role, setRole] = useState();
  const [status, setStatus] = useState();

  const [showPass, setShowPass] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);

  const [roleOptions, setRoleOptions] = useState([
    { value: "admin", label: "Admin" },
    { value: "trainer", label: "Trainer" },
    { value: "talent", label: "Talent" },
  ]);
  const [statusOptions, setStatusOptions] = useState([
    { value: "aktif", label: "Aktif" },
    { value: "nonaktif", label: "Non Aktif" },
  ]);
  const [tipeLembaga, setTipeLembaga] = useState("1");
  const [asalLembaga, setAsalLembaga] = useState({
    value: "",
    label: "Pilih Universitas",
  });
  const [optionLembaga, setOptionLembaga] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setId(akunData?.idAkun || "");
    setUsername(akunData?.username || "");
    setNamaLengkap(akunData?.namaLengkap || "");
    setEmail(akunData?.email || "");
    setPassword(akunData?.password || "");
    setConfirmPassword(akunData?.password || "");
    setRole(akunData?.role || "trainer");
    setStatus(akunData?.status || "");
    setShowPass(false);
    setShowConfirmPass(false);
    setTipeLembaga(akunData?.tipeLembaga || "1");
    setAsalLembaga({
      value: akunData?.asalLembaga || "0",
      label: akunData?.asalLembaga || "Pilih Universitas",
    });
  }, [show, akunData]);

  const roleSelected = roleOptions.find((item) => item.value === role) || null;
  const statusSelected =
    statusOptions.find((item) => item.value === status) || null;

  const handleRoleChange = (value) => {
    setRole(value.value);
  };

  const handleStatusChange = (value) => {
    setStatus(value.value);
  };

  const showPassword = (value) => {
    setShowPass(!showPass);
  };

  const showConfirmPassword = (value) => {
    setShowConfirmPass(!showConfirmPass);
  };

  const usernameField = (
    <Col xxl="6">
      <Form.Group>
        <Form.Label>
          Username <b className="asterisk">*</b>
        </Form.Label>
        <Form.Control
          placeholder="Username"
          value={username}
          onChange={(e) => {
            setUsername(e.target.value);
          }}
        />
      </Form.Group>
    </Col>
  );

  const firstRowForm = <Row className="mx-0 mb-3">{usernameField}</Row>;

  const namaLengkapField = (
    <Col>
      <Form.Group>
        <Form.Label>
          Nama Lengkap <b className="asterisk">*</b>
        </Form.Label>
        <Form.Control
          placeholder="Nama Lengkap"
          value={namaLengkap}
          role="presentation"
          autoComplete="off"
          onChange={(e) => {
            setNamaLengkap(e.target.value);
          }}
        />
      </Form.Group>
    </Col>
  );

  const emailField = (
    <Col>
      <Form.Group>
        <Form.Label>
          E-mail <b className="asterisk">*</b>
        </Form.Label>
        <Form.Control
          placeholder="E-mail"
          value={email}
          role="presentation"
          autoComplete="off"
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          type="email"
        />
      </Form.Group>
    </Col>
  );

  const secondRowForm = (
    <Row className="mx-0 mb-3">
      {namaLengkapField}
      {emailField}
    </Row>
  );

  const passwordField = (
    <Col>
      <Form.Label>
        Kata Sandi <b className="asterisk">*</b>
      </Form.Label>
      <InputGroup>
        <FormControl
          placeholder="Kata Sandi"
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          value={password}
          autoComplete="new-password"
          type={showPass ? "text" : "password"}
        />
        <InputGroup.Text>
          {showPass ? (
            <FontAwesomeIcon
              icon={faEye}
              color="#ffc107"
              className="me-2"
              onClick={showPassword}
              style={{ cursor: "pointer" }}
            />
          ) : (
            <FontAwesomeIcon
              icon={faEyeSlash}
              color="#dc3545"
              onClick={showPassword}
              style={{ cursor: "pointer" }}
            />
          )}
        </InputGroup.Text>
      </InputGroup>
    </Col>
  );

  const confirmPasswordField = (
    <Col>
      <Form.Label>
        Konfirmasi Kata Sandi <b className="asterisk">*</b>
      </Form.Label>
      <InputGroup>
        <FormControl
          placeholder="Konfirmasi Kata Sandi"
          onChange={(e) => {
            setConfirmPassword(e.target.value);
          }}
          value={confirmPassword}
          autoComplete="new-password"
          type={showConfirmPass ? "text" : "password"}
        />
        <InputGroup.Text>
          {showConfirmPass ? (
            <FontAwesomeIcon
              icon={faEye}
              color="#ffc107"
              className="me-2"
              onClick={showConfirmPassword}
              style={{ cursor: "pointer" }}
            />
          ) : (
            <FontAwesomeIcon
              icon={faEyeSlash}
              color="#dc3545"
              onClick={showConfirmPassword}
              style={{ cursor: "pointer" }}
            />
          )}
        </InputGroup.Text>
      </InputGroup>
    </Col>
  );

  const thirdRowForm = (
    <Row className="mx-0 mb-3">
      {passwordField}
      {confirmPasswordField}
    </Row>
  );

  const roleField = (
    <Col>
      <Form.Group>
        <Form.Label>
          Role <b className="asterisk">*</b>
        </Form.Label>
        <Select
          value={roleSelected}
          noOptionsMessage={() => "No options"}
          placeholder="Role"
          options={roleOptions}
          onChange={handleRoleChange}
          isDisabled={true}
        />
      </Form.Group>
    </Col>
  );

  const statusField = (
    <Col>
      <Form.Group>
        <Form.Label>
          Status <b className="asterisk">*</b>
        </Form.Label>
        <Select
          value={statusSelected}
          noOptionsMessage={() => "No options"}
          placeholder="Status"
          options={statusOptions}
          onChange={handleStatusChange}
        />
      </Form.Group>
    </Col>
  );

  const fourthRowForm = (
    <Row className="mx-0 mb-3">
      {roleField}
      {statusField}
    </Row>
  );

  const getLembaga = (val) => {
    setLoading(true);
    getOptLembaga({ tipeLembaga, keywordSearch: val })
      .then(
        (response) => {
          switch (response.data.status_code) {
            case "TAL200":
              const arr = response.data.message.map((e) => ({
                value: e.namaLembaga,
                label: e.namaLembaga,
              }));
              setOptionLembaga(arr);
              setLoading(false);
              break;
            default:
              dispatch(showErrorAlert("Terjadi kesalahan pada server!"));
              setLoading(false);
              break;
          }
        },
        (error) => {
          console.log("Verifkasi error :", error);
        }
      )
      .finally((info) => {});
  };

  const addAkun = () => {
    addAkunTrainer({
      username,
      namaLengkap,
      email,
      password,
      confirmPassword,
      role,
      status,
      tipeLembaga,
      asalLembaga: asalLembaga?.value,
    })
      .then(
        (response) => {
          switch (response.data.status_code) {
            case "AKU200":
              dispatch(showSuccessAlert("Akun berhasil dibuat"));
              setRefreshData((prev) => prev + 1);
              setShow((prev) => !prev);
              break;
            default:
              dispatch(showErrorAlert("Terjadi kesalahan pada server!"));
              break;
          }
        },
        (error) => {
          console.log("Add Akun error :", error);
        }
      )
      .finally((info) => {});
  };

  const editAkun = () => {
    editAkunTrainer({
      id,
      username,
      namaLengkap,
      email,
      password,
      confirmPassword,
      role,
      status,
      tipeLembaga,
      asalLembaga: asalLembaga?.value,
    })
      .then(
        (response) => {
          switch (response.data.status_code) {
            case "AKU200":
              dispatch(showSuccessAlert("Akun berhasil diubah"));
              setRefreshData((prev) => prev + 1);
              setShow((prev) => !prev);
              break;
            default:
              dispatch(showErrorAlert("Terjadi kesalahan pada server!"));
              break;
          }
        },
        (error) => {
          console.log("Edit Akun error :", error);
        }
      )
      .finally((info) => {});
  };

  const handleSubmit = (event) => {
    console.log("asalLembaga", asalLembaga);
    event.preventDefault();
    dispatch(hideErrorAlert());
    dispatch(hideSuccessAlert());

    if (password !== confirmPassword) {
      dispatch(showErrorAlert("Konfirmasi kata sandi tidak sesuai"));
    } else if (!username) {
      dispatch(showErrorAlert("Isi username!"));
    } else if (!namaLengkap) {
      dispatch(showErrorAlert("Isi nama lengkap!"));
    } else if (!email) {
      dispatch(showErrorAlert("Isi email!"));
    } else if (!password) {
      dispatch(showErrorAlert("Isi kata sandi!"));
    } else if (!confirmPassword) {
      dispatch(showErrorAlert("Isi konfirmasi kata sandi!"));
    } else if (!role) {
      dispatch(showErrorAlert("Pilih role!"));
    } else if (!status) {
      dispatch(showErrorAlert("Pilih status!"));
    } else if (role === "talent" && !tipeLembaga) {
      dispatch(showErrorAlert("Pilih Lembaga!"));
    } else if (
      role === "talent" &&
      (!asalLembaga?.value || asalLembaga?.value === "0")
    ) {
      dispatch(showErrorAlert("Pilih Asal Lembaga!"));
    } else {
      if (isEdit) editAkun();
      else addAkun();
    }
  };

  const onLembagaChange = (event) => {
    const val = event.target.id;
    setTipeLembaga(val);
    const tipe = val === "1" ? "Pilih Universitas" : "Pilih Sekolah";
    setAsalLembaga({
      value: "",
      label: tipe,
    });
  };

  const pilihLembaga = (
    <Col lg={6}>
      <Form.Group className="w-100" controlId="pilihLembaga">
        <Form.Label style={{ whiteSpace: "nowrap", marginRight: 15 }}>
          Pilih Lembaga<span style={{ color: "red" }}>*</span>
        </Form.Label>
        <InputGroup>
          <Form.Check
            inline
            label="Universitas"
            name="tipeLembaga"
            type="radio"
            id="1"
            checked={tipeLembaga === "1"}
            onChange={onLembagaChange}
          />
          <Form.Check
            inline
            label="Sekolah"
            name="tipeLembaga"
            type="radio"
            id="2"
            checked={tipeLembaga === "2"}
            onChange={onLembagaChange}
          />
        </InputGroup>
      </Form.Group>
    </Col>
  );

  const handleLembaga = (event) => {
    if (!event) {
      const tipe = tipeLembaga === "1" ? "Pilih Universitas" : "Pilih Sekolah";
      event = {
        target: null,
        value: "",
        label: tipe,
      };
    }
    setAsalLembaga(event);
  };

  const filterLembaga = (val) => {
    if (val.length > 2) {
      getLembaga(val);
    } else {
      setOptionLembaga([]);
    }
  };

  const debounce = useMemo(() => _.debounce(filterLembaga, 500), [tipeLembaga]);

  const selectLembaga = (
    <Col lg={6}>
      <Form.Group className="mb-3 w-100" controlId="selectLembaga">
        <Form.Label>
          Pilih Asal Lembaga
          <span style={{ color: "red" }}>*</span>
        </Form.Label>
        <Select
          value={asalLembaga}
          noOptionsMessage={() => "No options"}
          options={optionLembaga}
          onChange={handleLembaga}
          onInputChange={(e) => debounce(e)}
          isClearable={asalLembaga.value}
          isLoading={loading}
          styles={{
            control: (provided, state) => ({
              ...provided,
              borderColor: !asalLembaga.value ? "#dc3545" : "#ced4da",
            }),
          }}
        />
        <span style={{ fontSize: 14 }} className="text-danger">
          {!asalLembaga.value && "Field is Required"}
        </span>
      </Form.Group>
    </Col>
  );

  return (
    <Modal
      show={show}
      onHide={() => setShow((prev) => !prev)}
      className="popuptambaheditakun"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {!isEdit ? "Tambah Akun Digital Bootcamp 79 Baru" : "Edit Akun"}
        </Modal.Title>
      </Modal.Header>
      <Form onSubmit={(e) => handleSubmit(e)} autoComplete="off">
        <Modal.Body>
          {firstRowForm}
          {secondRowForm}
          {thirdRowForm}
          {fourthRowForm}
          {role === "talent" && (
            <Row className="mx-0">
              {pilihLembaga}
              {selectLembaga}
            </Row>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="light"
            onClick={() => setShow((prev) => !prev)}
            style={{ color: "#0078D7", border: "1px solid #0078D7" }}
          >
            Batal
          </Button>
          <Button type="submit" variant="primary">
            Simpan
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

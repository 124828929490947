import React, { useEffect } from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { setCrumbs } from './BreadcrumbsSlice';

export default function Crumb({propsCrumbs}) {
    const dispatch = useDispatch();
    const crumbs = useSelector(state => state.breadcrumbs.breadcrumbs);

    useEffect(() => {
        dispatch(setCrumbs(propsCrumbs))
    }, [propsCrumbs]);

    return (
        <>
            {crumbs?.map(crumb => {
                if (crumb === crumbs[crumbs.length - 1]) {
                    return <Breadcrumb.Item key={crumb} href="#" active>{crumb}</Breadcrumb.Item>;
                } else {
                    return <Breadcrumb.Item key={crumb} href="#">{crumb}</Breadcrumb.Item>;
                }
            })}
        </>
    )
}

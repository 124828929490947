import React, { useEffect, useState } from 'react';
import { Modal, Form, Row, Col, InputGroup, Image } from 'react-bootstrap';

function TopikPopUpDetail({ show, setShow, topikData }) {
    const [id, setId] = useState();
    const [judul, setJudul] = useState();
    const [thumbnail, setThumbnail] = useState('');
    const [deskripsi, setDeskripsi] = useState();
    const [tag, setTag] = useState();
    const [tingkatan, setTingkatan] = useState();
    const [visibilitas, setVisibilitas] = useState();

    useEffect(() => {
        setId(topikData?.id || '');
        setJudul(topikData?.judul || '');
        setThumbnail(topikData?.thumbnail || '');
        setDeskripsi(topikData?.deskripsi || '');
        setTingkatan(topikData?.tingkatan || '');
        setTag(topikData?.tag || '');
        setVisibilitas(topikData?.visibilitas || 'sembunyi');
    }, [show, topikData]);

    const judulTopikField = <Col>
        <Form.Group>
            <Form.Label>Nama Topik</Form.Label>
            <InputGroup>
                <Form.Label className='fsz-125'><b>{judul}</b></Form.Label>
            </InputGroup>
        </Form.Group>
    </Col>

    const firstRowForm = <Row className='mx-0 mb-3'>
        {judulTopikField}
    </Row>

    const thumbnailField = <Col>
        <Form.Group>
            <Form.Label>Thumbnail</Form.Label>
            <div class='topik-thumbnail-image'>
                <Image fluid src={thumbnail} alt='thumb_img' />
            </div>
        </Form.Group>
    </Col>

    const secondRowForm = <Row className='mx-0 mb-3'>
        {thumbnailField}
    </Row>

    const deskripsiField = <Col>
        <Form.Group>
            <Form.Label>Deskripsi Materi</Form.Label>
            <InputGroup>
                <Form.Label className='fsz-125'><b>{deskripsi}</b></Form.Label>
            </InputGroup>
        </Form.Group>
    </Col>

    const thirdRowForm = <Row className='mx-0 mb-3'>
        {deskripsiField}
    </Row>

    const tagField = <Col>
        <Form.Group>
            <Form.Label>Tag </Form.Label>
            <InputGroup>
                <Form.Label className='fsz-125'><b>{tag}</b></Form.Label>
            </InputGroup>
        </Form.Group>
    </Col>

    const fourthRowForm = <Row className='mx-0 mb-3'>
        {tagField}
    </Row>

    const tingkatanField = <Col>
        <Form.Group>
            <Form.Label>Tingkatan</Form.Label>
            <InputGroup>
                <Form.Label className='fsz-125'><b>{tingkatan}</b></Form.Label>
            </InputGroup>
        </Form.Group>
    </Col>

    const visibilitasField = <Col>
        <Form.Group>
            <Form.Label>Visibilitas</Form.Label>
            <InputGroup>
                <Form.Label className='fsz-125'><b>{visibilitas}</b></Form.Label>
            </InputGroup>
        </Form.Group>
    </Col>

    const fifthRowForm = <Row className='mx-0 mb-3'>
        {tingkatanField}
        {visibilitasField}
    </Row>

    return (
        <Modal show={show} onHide={() => setShow(prev => !prev)} centered size='lg'>
            <Modal.Header closeButton>
                <Modal.Title>Detail SubBab</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    {firstRowForm}
                    {secondRowForm}
                    {thirdRowForm}
                    {fourthRowForm}
                    {fifthRowForm}
                </Form>
            </Modal.Body>
        </Modal>
    );
}

export default TopikPopUpDetail;
import { faEye, faEyeSlash, faInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Button, Modal, Form, Row, Col, Stack, Tooltip, OverlayTrigger } from 'react-bootstrap';

import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import { useDispatch } from 'react-redux';
import {
    showErrorAlert, hideErrorAlert,
    showSuccessAlert, hideSuccessAlert,
} from '../../../app';

import { addSubbabTrainer, editSubbabTrainer } from '../../service/SubbabService';

function SubbabPopUp({ show, setShow, subbabData, isEdit,
    topikList, babList, tagList, setRefreshData
}) {
    const dispatch = useDispatch();

    const [id, setId] = useState();
    const [nama, setNama] = useState();
    const [idTopik, setIdTopik] = useState();
    const [judulTopik, setJudulTopik] = useState();
    const [idBab, setIdBab] = useState();
    const [namaBab, setNamaBab] = useState();
    const [skalaNilai, setSkalaNilai] = useState();
    const [tag, setTag] = useState();
    const [visibilitas, setVisibilitas] = useState();

    useEffect(() => {
        setId(subbabData?.id || '');
        setNama(subbabData?.nama || '');
        setIdTopik(subbabData?.idTopik || '');
        setJudulTopik(subbabData?.judulTopik || '');
        setIdBab(subbabData?.idBab || '');
        setNamaBab(subbabData?.namaBab || '');
        setSkalaNilai(subbabData?.skalaNilai || '');
        setTag(subbabData?.tag || '');
        setVisibilitas(subbabData?.visibilitas || 'sembunyi');
    }, [show, subbabData]);

    const topikOptions = topikList.map(item => ({ value: item.id, label: item.judul }));
    const babOptions = babList.filter(item => item.idTopik === idTopik)
        .map(item => ({ value: item.id, label: item.nama }))
    const tagOptions = tagList.map(item => ({ value: item, label: item }));

    const visibilitasOptions = [{
        value: 'sembunyi', label: <span>
            <FontAwesomeIcon icon={faEyeSlash} color={'#263238'} className='me-2' />Sembunyi</span>
    }, {
        value: 'publik', label: <span>
            <FontAwesomeIcon icon={faEye} color={'#263238'} className='me-2' />Publik</span>
    }];

    const tagSelected = tag ? tag?.split(',')
        .map(item => ({ value: item.trim(), label: item.trim() })) : null;
    const topikSelected = topikOptions.find(item => item.value === idTopik) || null;
    const babSelected = babOptions.find(item => item.value === idBab) || null;
    const visibilitasSelected = visibilitasOptions.find(item => item.value === visibilitas) || null;

    const handleTopikChange = ({ value, label }) => {
        setIdTopik(value);
        setJudulTopik(label);
        setIdBab();
        setNamaBab();
    }

    const handleBabChange = ({ value, label }) => {
        setIdBab(value);
        setNamaBab(label);
    }

    const isOnlyLetterAndSpace = (str) => {
        const re = new RegExp(/^[A-Za-z\s]*$/);
        return re.test(str);
    }

    const handleTagChange = (tags) => {
        for (const tag of tags) {
            if (!isOnlyLetterAndSpace(tag.value)) {
                dispatch(showErrorAlert('Tag hanya boleh mengandung huruf dan spasi!'));
                return;
            }
        }
        setTag(tags.map(tag => tag.value).join(', '));
    }

    const handleVisibilitasChange = (value) => {
        setVisibilitas(value.value);
    }

    const namaField = <Col>
        <Form.Group>
            <Form.Label>Nama SubBab <b className='asterisk'>*</b></Form.Label>
            <Form.Control placeholder='Nama SubBab' value={nama}
                onChange={(e) => setNama(e.target.value)}
            />
        </Form.Group>
    </Col>

    const judulTopikField = <Col>
        <Form.Group>
            <Form.Label>Judul/Topik Materi <b className='asterisk'>*</b></Form.Label>
            <Select value={topikSelected}
                noOptionsMessage={() => 'No options'}
                placeholder='Judul/Topik Materi'
                options={topikOptions}
                onChange={handleTopikChange}
            />
        </Form.Group>
    </Col>

    const firstRowForm = <Row className='mx-0 mb-3'>
        {namaField}
        {judulTopikField}
    </Row>

    const tagTooltip = <OverlayTrigger
        trigger='hover'
        placement='top'
        overlay={
            <Tooltip className='tag-tooltip'>
                Mohon diisi minimal 1 tag
            </Tooltip>
        }
    >
        <Button variant='light'
            className='tag-tooltip-button bg-transparent p-0 ms-2 rounded-circle
                border border-2 border-dark d-flex align-items-center justify-content-center'
            style={{ width: '1rem', height: '1rem' }}
        >
            <FontAwesomeIcon icon={faInfo} style={{ fontSize: '0.625rem' }} />
        </Button>
    </OverlayTrigger>

    const tagField = <Col>
        <Form.Group>
            <Stack direction='horizontal' className='mb-2'>
                <Form.Label className='mb-0'>Tag <b className='asterisk'>*</b></Form.Label>
                {tagTooltip}
            </Stack>
            <CreatableSelect isMulti isClearable={false}
                value={tagSelected}
                noOptionsMessage={() => 'No options'}
                placeholder='Tag'
                options={tagOptions}
                formatCreateLabel={(newTag) => `Buat tag '${newTag}'`}
                onChange={handleTagChange}
                getNewOptionData={(value, label) => {
                    return {
                        value: value.toUpperCase(),
                        label: label.replace(`${value}`, value.toUpperCase())
                    }
                }}
            />
        </Form.Group>
    </Col>

    const namaBabField = <Col>
        <Form.Group>
            <Form.Label>Bab <b className='asterisk'>*</b></Form.Label>
            <Select value={babSelected}
                noOptionsMessage={() => 'No options'}
                placeholder='Bab'
                options={babOptions}
                onChange={handleBabChange}
                isDisabled={judulTopik ? false : true}
            />
        </Form.Group>
    </Col>

    const secondRowForm = <Row className='mx-0 mb-3'>
        {tagField}
        {namaBabField}
    </Row>

    const skalaNilaiField = <Col>
        <Form.Group>
            <Form.Label>Skala Nilai <b className='asterisk'>*</b></Form.Label>
            <Form.Control placeholder='Skala Nilai' value={skalaNilai}
                onChange={(e) => setSkalaNilai(e.target.value)}
            />
        </Form.Group>
    </Col>

    const visibilitasField = <Col>
        <Form.Group>
            <Form.Label>Visibilitas <b className='asterisk'>*</b></Form.Label>
            <Select value={visibilitasSelected}
                noOptionsMessage={() => 'No options'}
                placeholder='Visibilitas'
                options={visibilitasOptions}
                onChange={handleVisibilitasChange}
            />
        </Form.Group>
    </Col>

    const thirdRowForm = <Row className='mx-0 mb-3'>
        {skalaNilaiField}
        {visibilitasField}
    </Row>

    const addSubbab = () => {
        addSubbabTrainer({
            nama, idTopik, idBab,
            skalaNilai, visibilitas, tag
        }).then(
            (response) => {
                switch (response.data.status_code) {
                    case 'SBB200':
                        dispatch(showSuccessAlert('SubBab berhasil dibuat'));
                        setRefreshData(prev => prev + 1);
                        setShow(prev => !prev);
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('Add Subbab error :', error);
            }).finally((info) => {

            });
    }

    const editSubbab = () => {
        editSubbabTrainer({
            id, nama, idTopik, idBab,
            skalaNilai, visibilitas, tag
        }).then(
            (response) => {
                switch (response.data.status_code) {
                    case 'SBB200':
                        dispatch(showSuccessAlert('SubBab berhasil diubah'));
                        setRefreshData(prev => prev + 1);
                        setShow(prev => !prev);
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('Edit Subbab error :', error);
            }).finally((info) => {

            });
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        dispatch(hideErrorAlert());
        dispatch(hideSuccessAlert());

        if (!nama) {
            dispatch(showErrorAlert('Isi nama subbab!'));
        } else if (!tag) {
            dispatch(showErrorAlert('Isi tag (minimal 1 tag)!'));
        } else if (!skalaNilai) {
            dispatch(showErrorAlert('Isi skala nilai!'));
        } else if (!idTopik || !judulTopik) {
            dispatch(showErrorAlert('Pilih judul/topik materi!'));
        } else if (!idBab || !namaBab) {
            dispatch(showErrorAlert('Pilih bab!'));
        } else if (!visibilitas) {
            dispatch(showErrorAlert('Pilih visibilitas!'));
        } else {
            if (isEdit) editSubbab();
            else addSubbab();
        }
    }

    return (
        <Modal show={show} onHide={() => setShow(prev => !prev)} centered size='lg'>
            <Modal.Header closeButton>
                <Modal.Title>{isEdit ? 'Edit SubBab' : 'Buat SubBab Baru'}</Modal.Title>
            </Modal.Header>
            <Form onSubmit={(e) => handleSubmit(e)}>
                <Modal.Body>
                    {firstRowForm}
                    {secondRowForm}
                    {thirdRowForm}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='light' onClick={() => setShow(prev => !prev)}
                        style={{ color: '#0078D7', border: '1px solid #0078D7' }}
                    >
                        Batal
                    </Button>
                    <Button type='submit' variant='primary'>
                        Simpan
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}

export default SubbabPopUp;

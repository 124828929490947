import axios from "axios";
import { talent_kuisakhir_endpoint, talent_submitkuisakhir_endpoint } from "../../app";
import { authHeader } from "./AuthHeader";

export const listKuisAkhirTalent = ({ idSubbab }) => {
    return axios({
        url: talent_kuisakhir_endpoint,
        method: 'GET',
        headers: { 'Content-type': 'application/json', ...authHeader() },
        params: { idSubbab },
    }).then((response) => {
        console.log('List Kuis Akhir Result :', response.data);
        return response;
    });
};

export const submitKuisAkhirTalent = ({ idKuisAkhir, durasiPengerjaan, nilai }) => {
    return axios({
        url: talent_submitkuisakhir_endpoint,
        method: 'POST',
        headers: { 'Content-type': 'application/json', ...authHeader() },
        data: {
            idKuisAkhir, durasiPengerjaan, nilai,
        }
    }).then((response) => {
        console.log('Submit Kuis Akhir Result :', response.data);
        return response;
    });
};

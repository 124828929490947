import { Col, Container, Dropdown, Image, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { showErrorAlert } from "../../../app";
import { GetParentPath } from "../../../common";
import { updateNotifikasiTrainer } from "../../service/NotifikasiService";

export default function NotificationItem({
    isRead, authorName, idMateri, judulMateri, idNotification, komentar
}) {
    const parentPath = GetParentPath();
    const dispatch = useDispatch();

    const updateNotif = (e) => {
        updateNotifikasiTrainer({ idNotification: [idNotification] }).then(
            (response) => {
                switch (response.data.status_code) {
                    case 'NTF200':
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('Update Notifikasi Trainer error :', error);
            }).finally((info) => {

            });
    }

    return (
        <Dropdown.Item
            as={Link} to={`${idMateri ? `${parentPath}/materi?id=${idMateri}` : '#'}`}
            className={`${isRead ? '' : 'unreaded-notif'}`}
            onClick={(e) => updateNotif(e)}
        >
            <Container fluid className='py-2 ps-1 pe-5' style={{ width: '30rem' }}>
                <Row className='m-0'>
                    <Col className='align-top p-0 image-col col-auto'>
                        <Image src='/img/Ellipse2.png' />
                    </Col>
                    <Col className='p-0 text-ellipsis-y'>
                        {(authorName && judulMateri) && <Row>
                            <Col className='fw-bold text-ellipsis-y'>
                                <span className='user-name'>{authorName}</span>&nbsp;
                                <span>berkomentar di Video</span>&nbsp;
                                <span className='topic-name'>{judulMateri}</span>
                            </Col>
                        </Row>}
                        <Row>
                            <Col className='text-ellipsis-y'>
                                '{komentar}'
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </Dropdown.Item>
    );
}

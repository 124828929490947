import axios from "axios";
import { base_url, port_learning, trainer_soalkuisakhir_endpoint, } from "../../app";
import { authHeader } from "./AuthHeader";
import { uploadImage } from "../../service/GCSService";
import { soal_kuisakhir_list } from "../features/dummy/soal-kuisakhir";
import moment from 'moment';

export const listSoalKuisAkhirTrainer = ({ idKuisAkhir }) => {
    return axios({
        url: trainer_soalkuisakhir_endpoint,
        method: 'GET',
        headers: {
            'Content-type': 'application/json',
            ...authHeader(),
        },
        params: { idKuisAkhir }
    }).then((response) => {
        console.log('List Soal Kuis Akhir Result :', response.data);
        return response;
    });
};

export const addSoalKuisAkhirTrainer = ({
    idKuisAkhir, tingkatan, visibilitas,
    soal, selectedGambar: gambar, opsiA, opsiB, opsiC, opsiD,
    jawaban, pembahasan, selectedGambarPembahasan: gambarPembahasan, urlReferensi }) => {
    var current = new Date();
    let urlGambar = '';
    let urlGambarPembahasan = '';
    let promises = [];
    if (gambar) {
        const gambarName = `${gambar.name.split('.').shift()}_${moment(current).format("YYYY-MM-DD_HH'MM'SS")}.${gambar.name.split('.').pop()}`;
        promises.push(
            uploadImage({ fileName: gambarName, folderName: 'gambar-soal', file: gambar }).then(
                (response) => {
                    urlGambar = response.urlImage;
                },
                (error) => {
                    console.log(error)
                    return null;
                }
            ));
    }

    if (gambarPembahasan) {
        const gambarPembahasanName = `${gambarPembahasan.name.split('.').shift()}_${moment(current).format("YYYY-MM-DD_HH'MM'SS")}.${gambarPembahasan.name.split('.').pop()}`;
        promises.push(
            uploadImage({ fileName: gambarPembahasanName, folderName: 'gambar-pembahasan', file: gambarPembahasan }).then(
                (response) => {
                    urlGambarPembahasan = response.urlImage;
                },
                (error) => {
                    console.log(error)
                    return null;
                }
            ));
    }

    return Promise.all(promises).then(
        () => {
            return axios({
                url: trainer_soalkuisakhir_endpoint,
                method: 'POST',
                headers: {
                    'Content-type': 'application/json',
                    ...authHeader(),
                },
                data: {
                    idKuisAkhir, tingkatan, visibilitas,
                    soal, gambar: urlGambar, opsiA, opsiB, opsiC, opsiD,
                    jawaban, pembahasan, gambarPembahasan: urlGambarPembahasan, urlReferensi
                },
            }).then((response) => {
                console.log('Edit Soal Kuis Akhir Result :', response.data);
                return response;
            });
        });

};

export const editSoalKuisAkhirTrainer = ({
    id, idKuisAkhir, tingkatan, visibilitas,
    soal, selectedGambar: gambar, opsiA, opsiB, opsiC, opsiD,
    jawaban, pembahasan, selectedGambarPembahasan: gambarPembahasan, urlReferensi }) => {

    var current = new Date();
    let urlGambar = '';
    let urlGambarPembahasan = '';
    let promises = [];
    if (gambar) {
        const gambarName = `${gambar.name.split('.').shift()}_${moment(current).format("YYYY-MM-DD_HH'MM'SS")}.${gambar.name.split('.').pop()}`;
        promises.push(
            uploadImage({ fileName: gambarName, folderName: 'gambar-soal', file: gambar }).then(
                (response) => {
                    urlGambar = response.urlImage;
                },
                (error) => {
                    console.log(error)
                    return null;
                }
            ));
    }

    if (gambarPembahasan) {
        const gambarPembahasanName = `${gambarPembahasan.name.split('.').shift()}_${moment(current).format("YYYY-MM-DD_HH'MM'SS")}.${gambarPembahasan.name.split('.').pop()}`;
        promises.push(
            uploadImage({ fileName: gambarPembahasanName, folderName: 'gambar-pembahasan', file: gambarPembahasan }).then(
                (response) => {
                    urlGambarPembahasan = response.urlImage;
                },
                (error) => {
                    console.log(error)
                    return null;
                }
            ));
    }

    return Promise.all(promises).then(
        () => {
            return axios({
                url: trainer_soalkuisakhir_endpoint,
                method: 'PUT',
                headers: {
                    'Content-type': 'application/json',
                    ...authHeader(),
                },
                data: {
                    id, idKuisAkhir, tingkatan, visibilitas,
                    soal, gambar: urlGambar, opsiA, opsiB, opsiC, opsiD,
                    jawaban, pembahasan, gambarPembahasan: urlGambarPembahasan, urlReferensi
                },
            }).then((response) => {
                console.log('Edit Soal Kuis Akhir Result :', response.data);
                return response;
            });
        });
};

export const deleteSoalKuisAkhirTrainer = ({ id }) => {
    return axios({
        url: trainer_soalkuisakhir_endpoint,
        method: 'DELETE',
        headers: {
            'Content-type': 'application/json',
            ...authHeader(),
        },
        data: {
            id,
        },
    }).then((response) => {
        console.log('Delete Soal Kuis Akhir Result :', response.data);
        return response;
    });
};

import React, { useEffect, useMemo } from "react";

import "./Table.css";

import {
  useTable,
  usePagination,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
} from "react-table";
// import { data } from './dummy';
import {
  Table as BTable,
  Button,
  Form,
  InputGroup,
  Container,
  Row,
  Col,
  Pagination,
  OverlayTrigger,
  Popover,
  Card,
} from "react-bootstrap";
import moment from "moment";
import _ from "lodash";

import { useDispatch } from "react-redux";
import { showErrorAlert } from "../../../app";
import { useNavigate } from "react-router-dom";
import {
  faSearch,
  faCog,
  faPlus,
  faTimes,
  faCaretDown,
  faCaretUp,
  faSort,
  faEllipsisV,
  faSlidersH,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import uuid from "react-uuid";

// UI Searching
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  filterValue,
  setFilterValue,
}) {
  return (
    <InputGroup>
      <Form.Control
        placeholder="Pencarian"
        className="search-input"
        value={globalFilter || ""}
        onChange={(e) => {
          setFilterValue(e.target.value);
          setGlobalFilter(e.target.value);
        }}
      />
      <InputGroup.Text
        id="btnGroupAddon"
        className="bg-white search-icon"
        onClick={() => {
          setFilterValue("");
          setGlobalFilter("");
        }}
        style={globalFilter ? { cursor: "pointer" } : {}}
      >
        {!globalFilter ? (
          <FontAwesomeIcon icon={faSearch} size="xs" />
        ) : (
          <FontAwesomeIcon size="xs" icon={faTimes} />
        )}
      </InputGroup.Text>
    </InputGroup>
  );
}

function Table({
  title = "",
  data,
  columns,
  urlNavigate,
  showSearch = true,
  showCustomize = true,
  showToolbar = true,
  addFunction,
  rowClickFunction,
  showAddButton = true,
  showFilter = true,
  reverseSearchFilter = false,
  showExport = true,
  onSearchChange,
  onExport,
}) {
  const [filter, setFilter] = React.useState("all");
  const [filterValue, setFilterValue] = React.useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const ColumnFilter = (props) => {
    return (
      <Form.Select
        placeholder="Filter"
        onChange={(e) => {
          props.setFunc(e.target.value);
          setTimeout(console.log(filter), 500);
        }}
        value={filter}
      >
        <option value="all" key="all">
          All Filter
        </option>
        {columns.map(
          (data) =>
            !(
              data.accessor === "nomor" ||
              data.accessor === "aksi" ||
              data.disableFilters === true
            ) && (
              <option key={data.accessor} value={data.accessor}>
                {data.Header}
              </option>
            )
        )}
      </Form.Select>
    );
  };

  const globalFilter = (rows, columns, filterValue) => {
    return rows.filter((row) => {
      if (filter !== "all") {
        const rowValue = row.values[filter];
        return rowValue !== undefined
          ? String(rowValue)
              .toLowerCase()
              .includes(String(filterValue).toLowerCase())
          : true;
      } else {
        const rowValues = row.values;

        for (var key in rowValues) {
          if (rowValues[key] !== undefined)
            if (
              String(rowValues[key])
                .toLowerCase()
                .includes(String(filterValue).toLowerCase())
            )
              return true;
        }

        return false;
      }
    });
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,

    // Pagination
    page,
    pageOptions,
    state: { pageIndex, pageSize },
    gotoPage,
    previousPage,
    nextPage,
    setPageSize,
    canPreviousPage,
    canNextPage,
    pageCount,

    // Search
    preGlobalFilteredRows,
    setGlobalFilter,
    state,

    allColumns,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        // sortBy: [
        //     {
        //         id: 'nomor',
        //         desc: false
        //     }
        // ],
        pageSize: 10000,
      },
      globalFilter,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  useEffect(() => {
    setGlobalFilter(filterValue);
  }, [data]);

  const customizeColumns = allColumns.filter((col) => !col.mustShow);
  const minCheckConstant = 3 - (allColumns.length - customizeColumns.length);
  const [checkedState, setCheckedState] = React.useState(
    new Array(customizeColumns.length).fill(true)
  );
  const [checkedCount, setCheckedCount] = React.useState(
    customizeColumns.length
  );
  const minChecked =
    customizeColumns.length < minCheckConstant
      ? customizeColumns.length
      : minCheckConstant;

  const handleChangeCustomize = (position, toggleHidden) => {
    if (checkedCount >= minChecked) {
      const updatedCheckedState = checkedState.map((item, index) => {
        if (index === position) {
          if (item) {
            setCheckedCount((prev) => prev - 1);
            if (checkedCount <= minChecked) {
              dispatch(showErrorAlert(`Jumlah kolom minimal 3!`));
              setCheckedCount((prev) => prev + 1);
              return item;
            } else {
              toggleHidden();
              return !item;
            }
          } else {
            setCheckedCount((prev) => prev + 1);
            toggleHidden();
            return !item;
          }
        } else {
          return item;
        }
      });

      setCheckedState(updatedCheckedState);
    } else {
      setCheckedState((prev) => prev);
    }
  };

  const popover = (allCol) => {
    return (
      <Popover id="popover-basic">
        <Popover.Body>
          {allCol.map((column, index) => (
            <div key={column.id}>
              <label>
                <input
                  type="checkbox"
                  checked={checkedState[index]}
                  onChange={() =>
                    handleChangeCustomize(index, column.toggleHidden)
                  }
                />{" "}
                {column.Header === "No" ? "Nomor" : column.Header}
              </label>
            </div>
          ))}
        </Popover.Body>
      </Popover>
    );
  };

  const debounce = useMemo(() => _.debounce(onSearchChange, 500), []);

  return (
    <Container fluid className="p-3">
      <Row>
        <Col className="d-flex flex-row align-items-center justify-content-between">
          <strong style={{ fontSize: 16 }}>{title}</strong>
          <div className="d-flex flex-row align-items-center">
            {showSearch && (
              <div class="form-group has-search">
                <span class="fa fa-search form-control-feedback">
                  <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
                </span>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Search..."
                  onChange={(event) => debounce(event.target.value)}
                />
              </div>
            )}
            {showExport && (
              <Button
                variant="primary"
                size="sm"
                style={{
                  fontWeight: 700,
                  borderRadius: 5,
                  padding: "7px 20px 7px 20px",
                  marginLeft: 20,
                }}
                onClick={() => onExport()}
                disabled={page?.length === 0}
              >
                <FontAwesomeIcon
                  icon={faDownload}
                  style={{ marginRight: 10 }}
                ></FontAwesomeIcon>
                Export
              </Button>
            )}
          </div>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <div style={{ maxHeight: "300px", overflowY: "auto" }}>
            <BTable {...getTableProps()} hover>
              <thead
                style={{
                  fontSize: 12,
                  color: "#7D8FA9",
                  position: "sticky",
                  top: 0,
                  backgroundColor: "white",
                  zIndex: 1,
                }}
              >
                {headerGroups.map((headerGroup) => (
                  <tr key={uuid()} {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        key={uuid()}
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                      >
                        {column.render("Header")}
                        <span className="ms-2">
                          {column.canSort ? (
                            column.isSorted ? (
                              column.isSortedDesc ? (
                                <FontAwesomeIcon icon={faCaretUp} size="xs" />
                              ) : (
                                <FontAwesomeIcon icon={faCaretDown} size="xs" />
                              )
                            ) : (
                              <FontAwesomeIcon icon={faSort} size="xs" />
                            )
                          ) : (
                            " "
                          )}
                        </span>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody
                {...getTableBodyProps()}
                style={{ fontSize: 14, color: "#3B4758" }}
              >
                {page.map((row, i) => {
                  prepareRow(row);
                  return (
                    <tr key={uuid()} {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td
                            {...cell.getCellProps()}
                            style={{
                              overflowX:
                                cell.column.Header === "Progress" && "auto",
                              maxWidth: cell.column.width,
                            }}
                            className="align-middle"
                            // onClick={() => {
                            //   if (cell.column.id === "aksi") return;
                            //   rowClickFunction(row.original);
                            // }}
                            key={uuid()}
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </BTable>
          </div>
        </Col>
      </Row>
      <Row>
        {urlNavigate && (
          <Col className="d-flex justify-content-center">
            {page?.length > 0 ? (
              <span
                style={{
                  color: "#2C8AD3",
                  fontSize: 14,
                  fontWeight: 600,
                  cursor: "pointer",
                }}
                onClick={() => urlNavigate && navigate(urlNavigate)}
              >
                Lihat Semua
              </span>
            ) : (
              <img
                src="/img/nodata.jpg"
                style={{ width: 250, objectFit: "contain" }}
              />
            )}
          </Col>
        )}
      </Row>
    </Container>
  );
}

export default Table;

import axios from "axios";
import { trainer_laporannilai_endpoint } from "../../app";
import { authHeader } from "./AuthHeader";
import { list_laporannilai } from "../features/dummy/laporannilai";
import { list_laporannilai_kuisbab } from "../features/dummy/laporannilai-kuis_bab";
import { list_laporannilai_kuiscekkemampuan } from "../features/dummy/laporannilai-kuis_cekkemampuan";
import { list_laporannilai_latihanpraktik } from "../features/dummy/laporannilai-latihan_praktik";
import { list_laporannilai_kuispraktik } from "../features/dummy/laporannilai-kuis_praktik";

export const listLaporanNilai = () => {
    return axios({
        url: trainer_laporannilai_endpoint,
        method: 'GET',
        headers: { 'Content-type': 'application/json', ...authHeader() },
    }).then((response) => {
        console.log('List Laporan Nilai Result :', response.data);
        return response;
    });
};

export const listRiwayatKuisBab = ({idTalent, idTopik}) => {
    return axios({
        url: `${trainer_laporannilai_endpoint}-kuis-bab`,
        method: 'GET',
        headers: { 'Content-type': 'application/json', ...authHeader() },
        params: {idTalent, idTopik}
    }).then((response) => {
        console.log('List Riwayat Kuis Bab Result :', response.data);
        return response;
    });
};

export const listRiwayatKuisCekKemampuan = ({idTalent, idTopik}) => {
    return axios({
        url: `${trainer_laporannilai_endpoint}-cek-kemampuan`,
        method: 'GET',
        headers: { 'Content-type': 'application/json', ...authHeader() },
        params: {idTalent, idTopik}
    }).then((response) => {
        console.log('List Riwayat Cek Kemampuan Result :', response.data);
        return response;
    });
};

export const listRiwayatLatihanPraktik = ({idTalent, idTopik}) => {
    return axios({
        url: `${trainer_laporannilai_endpoint}-latihan-praktik`,
        method: 'GET',
        headers: { 'Content-type': 'application/json', ...authHeader() },
        params: {idTalent, idTopik}
    }).then((response) => {
        console.log('List Riwayat Latihan Praktik Result :', response.data);
        return response;
    });
};

export const listRiwayatKuisPraktik = ({idTalent, idTopik}) => {
    return axios({
        url: `${trainer_laporannilai_endpoint}-ujian-praktik`,
        method: 'GET',
        headers: { 'Content-type': 'application/json', ...authHeader() },
        params: {idTalent, idTopik}
    }).then((response) => {
        console.log('List Riwayat Kuis Praktik Result :', response.data);
        return response;
    });
};
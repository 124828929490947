import axios from 'axios';
import { trainer_notifikasi_endpoint } from '../../app';
import { authHeader } from './AuthHeader';

export const listNotifikasiTrainer = ({ page }) => {
    return axios({
        url: trainer_notifikasi_endpoint,
        method: 'GET',
        headers: { 'Content-type': 'application/json', ...authHeader() },
        params: { page, limit: 5 }
    }).then((response) => {
        console.log('List Notifikasi Trainer Result :', response.data);
        return response;
    });
};

export const updateNotifikasiTrainer = ({ idNotification }) => {
    return axios({
        url: trainer_notifikasi_endpoint,
        method: 'PUT',
        headers: { 'Content-type': 'application/json', ...authHeader() },
        data: { idNotification },
    }).then((response) => {
        console.log('Update Notifikasi Trainer Result :', response.data);
        return response;
    });
};

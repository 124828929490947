import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsis, faFileLines, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import React from "react";
import { Card, Col, Dropdown, Image } from 'react-bootstrap';

function ScreenshotThumb({ data, setShowPopup, setShowPopupDelete, setThumbData }) {
    return (
        <Col lg={3} className="mt-1 mb-3">
            <Card className='rounded' onClick={e => {
                setThumbData(data);
                setShowPopup(prev => !prev);
            }}>
                <div className='border-bottom d-flex card-img-container justify-content-center'>
                    <Image style={{ objectFit: 'cover' }} width='100%' src={data.gambarScreenshot} />
                    <Dropdown className="screenshot-thumb-dropdown position-absolute top-0 end-0" onClick={e => {
                        e.stopPropagation();
                    }}>
                        <Dropdown.Toggle>
                            <FontAwesomeIcon icon={faEllipsis} />
                        </Dropdown.Toggle>

                        <Dropdown.Menu className='border-0' align="end">
                            <Dropdown.Item className='border shadow text-danger' onClick={e => {
                                setThumbData(data);
                                setShowPopupDelete(prev => !prev);
                                e.stopPropagation();
                            }}>
                                <FontAwesomeIcon icon={faTrashCan} />
                                Hapus
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                <Card.Title>{data.judulVideoMateri}</Card.Title>
                <Card.Subtitle>{data.namaBab}</Card.Subtitle>
                <Card.Subtitle>{data.namaTopik}</Card.Subtitle>
            </Card>
        </Col>
    )
}

export default ScreenshotThumb;
import moment from "moment";

export const columns = (isMasterChecked, handleMasterCheckboxChange, handleRowCheckboxChange, selectedRows, editFunction, deleteFunction) => [
  {
    Header: () => (
      <input
          type="checkbox"
          checked={isMasterChecked}
          onChange={handleMasterCheckboxChange}
      />
    ),
    width: 20,
    accessor: "check",
    disableSortBy: true,
    Cell: ({ row }) => (
      <input
          key={row.id}
          type="checkbox"
          checked={selectedRows.some((r) => r.id === row.original.id)}
          onChange={() => handleRowCheckboxChange(row.original)}
      />
    ),
  },
  {
    Header: "Nama Talent",
    accessor: "nama",
  },
  {
    Header: "Terakhir Login",
    accessor: "terakhir_login_tanggal",
    Cell: (row) => {
      return (
        <span>
          {moment(row.row.original.terakhir_login_tanggal).format("DD MMMM YYYY, hh:mm")}
        </span>
      );
    },
  },
  {
    Header: "Lembaga",
    accessor: "nama_lembaga",
  },
  {
    Header: "Nomor Telepon",
    accessor: "nomor_telp",
  },
  {
    Header: "Email",
    accessor: "email",
  },
  // {
  //   Header: "Aksi",
  //   accessor: "aksi",
  //   disableSortBy: true,
  //   disableFilters: true,
  //   Cell: (row) => {
  //     return (
  //       <>
  //         <Button onClick={(e) => editFunction(row.row.original)}>
  //           Kirim Notifikasi
  //         </Button>
  //       </>
  //     );
  //   },
  // },
];

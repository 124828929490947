import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import { showErrorAlert } from '../../../app';
import {
    laporanBelajarCekKemampuan,
    laporanBelajarKuisBab,
    laporanBelajarLatihanPraktik,
    laporanBelajarUjianPraktik
} from '../../service/LaporanBelajarService';

import { columns } from './columns';
import Table from './Table';

export default function LaporanBelajarKuis({
    topikList = [], kuisType = ''
}) {
    const dispatch = useDispatch();
    const [riwayatKuis, setRiwayatKuis] = useState({});
    const [topikSelected, setTopikSelected] = useState({
        value: '', label: 'Semua Topik',
    });

    const topikOptions = [
        { value: '', label: 'Semua Topik' },
        ...topikList.map(topik => ({
            value: topik.id, label: topik.judul,
        })),
    ];

    const [laporanBelajarKuisFunc, setLaporanBelajarKuisFunc] = useState('');
    const [headerText, setHeaderText] = useState('');
    const [judulTable, setJudulTable] = useState('');
    const [judulKuisColumn, setJudulKuisColumn] = useState('');

    useEffect(() => {
        switch (kuisType) {
            case 'cek-kemampuan':
                setLaporanBelajarKuisFunc(
                    (idTopik) => (idTopik) => laporanBelajarCekKemampuan({ idTopik })
                );
                setHeaderText('Nilai Rata-Rata Ujian Topik');
                setJudulTable('Riwayat Ujian Topik');
                setJudulKuisColumn('Judul Ujian Topik');
                break;
            case 'latihan-praktik':
                setLaporanBelajarKuisFunc(
                    (idTopik) => (idTopik) => laporanBelajarLatihanPraktik({ idTopik })
                );
                setHeaderText('Nilai Rata-Rata Latihan Praktik');
                setJudulTable('Riwayat Latihan Praktik');
                setJudulKuisColumn('Judul Latihan Praktik');
                break;
            case 'ujian-praktik':
                setLaporanBelajarKuisFunc(
                    (idTopik) => (idTopik) => laporanBelajarUjianPraktik({ idTopik })
                );
                setHeaderText('Nilai Rata-Rata Ujian Praktik');
                setJudulTable('Riwayat Ujian Praktik');
                setJudulKuisColumn('Judul Ujian Praktik');
                break;
            default:
                setLaporanBelajarKuisFunc(
                    (idTopik) => (idTopik) => laporanBelajarKuisBab({ idTopik })
                );
                setHeaderText('Nilai Rata-Rata Latihan Bab');
                setJudulTable('Riwayat Latihan Bab');
                setJudulKuisColumn('Judul Latihan Bab');
                break;
        }
    }, [kuisType]);

    useEffect(() => {
        if (!laporanBelajarKuisFunc) return;
        laporanBelajarKuisFunc(topikSelected.value).then(
            (response) => {
                switch (response.data.status_code) {
                    case 'LBE200':
                        setRiwayatKuis(response.data.message || {});
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('Laporan Belajar Kuis Bab error :', error);
            }).finally((info) => {

            });
    }, [dispatch, laporanBelajarKuisFunc, topikSelected]);

    const handleTopikChange = ({ value, label }) => {
        setTopikSelected({ value, label });
    }

    const header = <Row>
        <Col>
            <h4>
                {headerText}
            </h4>
        </Col>
    </Row>

    const topikSelectDropdown = <Row className='mt-3'>
        <Col className='col-6'>
            <Select
                value={topikSelected}
                noOptionsMessage={() => 'No options'}
                options={topikOptions}
                onChange={handleTopikChange}
            />
        </Col>
    </Row>

    const nilaiNilai = <Row className='mt-3'>
        <Col className='col-6'>
            Nilai Saya
            <h2 className={`laporannilai-${riwayatKuis.rataRata < riwayatKuis.nilai
                ? 'green' : riwayatKuis.rataRata > riwayatKuis.nilai ? 'red' : 'yellow'}`}
                style={{ fontWeight: 'bolder' }}>{riwayatKuis.nilai}</h2>
        </Col>
        <Col className='col-6'>
            Nilai Talent Lain
            <h2 style={{ color: '#fbbc04', fontWeight: 'bolder' }}>{riwayatKuis.rataRata}</h2>
        </Col>
    </Row>

    const legend = <Row className='mt-3'>
        <Col className='col-6 d-flex align-content-center'>
            <div className='laporannilai-circle bg-green'> </div>
            Di atas Rata-Rata
        </Col>
        <Col className='col-6 d-flex align-content-center'>
            <div className='laporannilai-circle bg-yellow'> </div>
            Rata-Rata
        </Col>
        <Col className='col-6 d-flex align-content-center mt-2'>
            <div className='laporannilai-circle bg-red'> </div>
            Di bawah Rata-Rata
        </Col>
        <Col className='col-6 d-flex align-content-center mt-2'>
            <div className='laporannilai-circle bg-gray'> </div>
            Belum Dikerjakan
        </Col>
    </Row>

    return (
        <Container>
            {header}
            {topikSelectDropdown}
            {nilaiNilai}
            {legend}
            <Table title={judulTable}
                columns={columns(judulKuisColumn, riwayatKuis?.rataRata || 0)}
                data={
                    riwayatKuis.listKuisBab ||
                    riwayatKuis.listCekKemampuan ||
                    riwayatKuis.listLatihanPraktik ||
                    riwayatKuis.listUjianPraktik ||
                    []
                }
            />
        </Container>
    );
}

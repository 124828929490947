import axios from "axios";
import { talent_catatan_endpoint } from "../../app";
import { authHeader } from "./AuthHeader";

export const listCatatanTalent = () => {
    return axios({
        url: talent_catatan_endpoint,
        method: 'GET',
        headers: {'Content-type': 'application/json', ...authHeader()},
    }).then((response) => {
        console.log('List Catatan Result :', response.data);
        return response;
    });
};

export const getCatatanTalentByIdMateri = ({ idMateri }) => {
    return axios({
        url: talent_catatan_endpoint,
        method: 'GET',
        headers: {'Content-type': 'application/json', ...authHeader()},
        params: { idMateri },
    }).then((response) => {
        console.log('List Catatan Result :', response.data);
        return response;
    });
};

export const addCatatanTalent = ({ idTopik, idBab, idSubbab, idMateri, catatan }) => {
    return axios({
        url: talent_catatan_endpoint,
        method: 'POST',
        headers: { 'Content-type': 'application/json', ...authHeader() },
        data: { idTopik, idBab, idSubbab, idMateri, catatan },
    }).then((response) => {
        console.log('Add Catatan Result :', response.data);
        return response;
    });
};

export const editCatatanTalent = ({ id, catatan }) => {
    return axios({
        url: talent_catatan_endpoint,
        method: 'PUT',
        headers: { 'Content-type': 'application/json', ...authHeader() },
        data: {
            id, catatan
        },
    }).then((response) => {
        console.log('Edit Catatan Result :', response.data);
        return response;
    });
};

export const deleteCatatanTalent = ({ id }) => {
    return axios({
        url: talent_catatan_endpoint,
        method: 'DELETE',
        headers: {
            'Content-type': 'application/json',
            ...authHeader(),
        },
        data: {
            id,
        },
    }).then((response) => {
        console.log('Delete Catatan Result :', response.data);
        return response;
    });
};
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { showErrorAlert, showSuccessAlert } from "../../../app";

// component
import { Table } from "../../common/table";
import { columns } from "./columns";

// service
import FileSaver from "file-saver";
import {
  exportReportBelajar, getAllReportBelajar
} from "../../service/DashboardService";
import { sendWhatsappLearningReport } from "../../service/WhatsappService";

function ReportBelajar() {
  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState(0); // State untuk menyimpan halaman saat ini
  const [pageSize, setPageSize] = useState(5);

  const [data, setData] = useState([]);
  const [filterSearch, setFilterSearch] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowsPage, setSelectedRowsPage] = useState({});
  const [isMasterChecked, setIsMasterChecked] = useState(false);
  const [pageCheckStatus, setPageCheckStatus] = useState({});

  const [parameter, setParameter] = useState();

  // useEffect(() => {
  //   console.log("selectedRows:", selectedRows);
  //   console.log("Array size: ", selectedRows.length);
  // }, [selectedRows]);

  const fetchReportBelajar = (val) => {
    setFilterSearch(val);
    getAllReportBelajar({ keywordSearch: val })
      .then(
        (response) => {
          switch (response.data.status_code) {
            case "TAL200":
              setData(response.data.message || 0);
              break;
            default:
              dispatch(showErrorAlert("Terjadi kesalahan pada server!"));
              break;
          }
        },
        (error) => {
          console.log("Total Talent Join error :", error);
        }
      )
      .finally((info) => {});
  };
  
  const fetchExportReportBelajar = () => {
    exportReportBelajar({ keywordSearch: filterSearch })
      .then(
        (response) => {
          switch (response.status) {
            case 200:
              FileSaver.saveAs(response.data, "Progress Belajar Peserta.xlsx");
              break;
            default:
              dispatch(showErrorAlert("Terjadi kesalahan pada server!"));
              break;
          }
        },
        (error) => {
          console.log("Total Talent Join error :", error);
        }
      )
      .finally((info) => {});
  };

  const sendWhatsapp = () => {
    selectedRows?.map((data, i) => {
      const talentData = {
        learningReport: [
          {
            namaTalent: data.nama,
            nomorWa: data.noWa,
            javaScore: data.fundamental_java_score,
            oopScore: data.oop_score,
            sqlScore: data.sql_score,
            discordScore: data.discord_score,
            gitScore: data.git_score,
          },
        ],
      };

      sendWhatsappLearningReport(talentData).then((response) => {
        switch (response.data.status_code) {
          case "WA200":
            dispatch(showSuccessAlert("Pesan berhasil dikirim!"));
            break;
          default:
            dispatch(showErrorAlert("Terjadi kesalahan pada server!"));
            break;
        }
      });
    });
  }; 
  
  const handleMasterCheckboxChange = () => {
    const newIsMasterChecked = !pageCheckStatus[currentPage];
    setPageCheckStatus((prevPageCheckStatus) => ({
      ...prevPageCheckStatus,
      [currentPage]: newIsMasterChecked,
    }));
  
    if (newIsMasterChecked) {
      const newData = data?.slice(parameter.start, parameter.end);
      setSelectedRows((prevSelectedRows) => [...prevSelectedRows, ...newData]);
      setIsMasterChecked(true);
    } else {
      setSelectedRows((prevSelectedRows) =>
        prevSelectedRows.filter(
          (prevRow) =>
            !data?.slice(parameter.start, parameter.end).some((newRow) => newRow.id === prevRow.id)
        )
      );
      setIsMasterChecked(false);
    }
  };
  

  const handleRowCheckboxChange = (row) => {
    const selectedRowIds = selectedRows.map((row) => row.id);
      selectedRowIds.includes(row.id)
        ? setSelectedRows(selectedRows.filter((r) => r.id !== row.id))
        : setSelectedRows([...selectedRows, row]);
    };

  const getItemPage = ({ pageIndex, pageSize }) => {
    const start = pageIndex * pageSize;
    const end = start + pageSize ;
    setParameter({ start, end });
    setCurrentPage(pageIndex);
    setPageCheckStatus((prevPageCheckStatus) => ({
      ...prevPageCheckStatus,
      [pageIndex]: prevPageCheckStatus[pageIndex] || false, 
    }));
  };

  // console.log("selection", parameter)

  useEffect(() => {
    fetchReportBelajar();
  }, [dispatch]);

  useEffect(() => {
      setIsMasterChecked(pageCheckStatus[currentPage]);
  }, [currentPage, pageCheckStatus]);
  
  useEffect(() => {
    localStorage.setItem("pageCheckStatus", JSON.stringify(pageCheckStatus));
  }, [pageCheckStatus]); 

  useEffect(() => {
    const storedPageCheckStatus = JSON.parse(localStorage.getItem("pageCheckStatus"));
    setPageCheckStatus(storedPageCheckStatus || {});
  }, []);

  return (
    <Table
      data={data}
      columns={columns(
        isMasterChecked,
        handleMasterCheckboxChange, 
        handleRowCheckboxChange,
        selectedRows,
        )}
      showAddButton={false}
      showSearch={false}
      showFilter={false}
      showCustomize={false}
      customSearch={true}
      size={pageSize}
      setPageSize={setPageSize}
      onExport={fetchExportReportBelajar}
      whatsappButton={sendWhatsapp}
      getItemPage={getItemPage}
      whatsappButtonDisabled={selectedRows.length !== 0 ? false : true}
      rowClickFunction={ () => {}}
      onSearchChange={(val) => fetchReportBelajar(val)}
      title="Report Belajar Peserta"
    />
  );
}

export default ReportBelajar;

import { useState } from "react";
import "./LearningMaterials.css";
import figma from "../../assets/figma.svg";
import frame427319160 from "../../assets/frame-427319160.svg";
import frame427319181 from "../../assets/frame-427319181.svg";
import frame4273191811 from "../../assets/frame-4273191811.svg";
import iconcompareproperty2 from "../../assets/iconcompare-property2.svg";
import iconinfo from "../../assets/iconinfo.svg";
import { Col, Row } from "react-bootstrap";
import RegisForm from "../../../talent/features/auth/NewRegisForm";
import Banner from "../../../talent/features/auth/Banner";
import { Modal } from "react-bootstrap";
import image12 from '../../assets/image12.png';

const listMaterials = [
  {
    id: 1,
    img: figma,
    name: "GIT",
    content:
      "Git adalah sistem kontrol versi yang memungkinkan kolaborasi tim dalam mengelola perubahan kode sumber secara efisien.",
    chapter: 2,
    level: "Mudah",
  },
  {
    id: 2,
    img: frame427319160,
    name: "Java",
    content:
      "Java adalah bahasa pemrograman serbaguna yang terkenal karena portabilitasnya dan digunakan secara luas dalam pengembangan perangkat lunak lintas platform.",
    chapter: 10,
    level: "Mudah",
  },
  {
    id: 3,
    img: frame427319181,
    name: "OOP",
    content:
      "Pemrograman Berorientasi Objek (OOP) adalah pendekatan dalam pengembangan perangkat lunak yang memandang data dan fungsi sebagai objek yang saling berinteraksi untuk menciptakan aplikasi.",
    chapter: 7,
    level: "Sedang",
  },
  {
    id: 4,
    img: frame4273191811,
    name: "SQL",
    content:
      "SQL (Structured Query Language) adalah bahasa yang digunakan untuk mengelola dan mengakses basis data relasional.",
    chapter: 9,
    level: "Sedang",
  },
];

const LearningMaterials = () => {
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [showRegisterModalMobile, setShowRegisterModalMobile] = useState(false);

  const handleButtonClick = (event) => {
    if (window.innerWidth <= 600) {
      // Jika perangkat seluler, tampilkan modal peringatan
      // Gantilah ini dengan logika untuk menampilkan modal peringatan
      setShowRegisterModalMobile(true);
    } else {
      setShowRegisterModal(true);
    }
  };

  const handleCloseRegisterModalMobile = (event) => {
    setShowRegisterModalMobile(false);
  };

  const handleCloseRegisterModal = () => {
    setShowRegisterModal(false);
  };

  return (
    <div
      className="learning-material-container position-relative container-fluid section-materials space-container"
      style={{ backgroundColor: "#f8f8ff" }}
    >
      <div className="container position-relative">
        <div className="row mb-5">
          <div className="col-12 col-md-6">
            <h2 className="fw-bold mb-4">Materi Funtastic 79 Bootcamp</h2>
            <div className="paragraph">
              <span>
                Setiap modul dipenuhi dengan konten yang menginspirasi, dikemas
                dengan penuh dedikasi oleh para ahli di bidangnya masing-masing.
              </span>
            </div>
          </div>
          <div className="col-12 col-md-6 text-start text-md-end mt-4">
            <button
              className="btn btn-outline-primary ms-auto"
              onClick={handleButtonClick}
            >
              Daftar Sekarang Juga!
            </button>
          </div>
        </div>
        <div className="row">
          {listMaterials.map((material) => (
            <div
              className="col-12 col-sm-6 col-md-6 col-lg-3 mb-4"
              key={material.id}
            >
              <div className="card" style={{ height: "100%" }}>
                <div className="card-header">
                  <img src={material.img} />
                </div>
                <div className="card-body">
                  <div class="h-100 d-flex flex-column justify-content-between align-items-center p-0">
                    <div>
                      <div className="card-title">{material.name}</div>
                      <div className="card-content">{material.content}</div>
                    </div>
                    <div className="row g-0" style={{width: "100%"}}>
                      <div className="col-12 col-sm-6 pe-2">
                        <div className="card-button">
                          <img alt="" src={iconcompareproperty2} />
                          <div>{material.chapter} Bab</div>
                        </div>
                      </div>
                      <div className="col-12 col-sm-6 ps-2">
                        <div className="card-button">
                          <img alt="" src={iconinfo} />
                          <div>{material.level}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <Modal
        show={showRegisterModal}
        onHide={handleCloseRegisterModal}
        size="xl"
      >
        <div class="modal-content-transparent rounded-0">
          <Row className="mx-0 px-0" style={{ height: "100%", width: "100%" }}>
            <Col
              className="col-6 login-banner-col"
              style={{
                background:
                  "url(/img/talent-login-banner.png) rgba(0, 0, 0, 0.75)",
                backgroundBlendMode: "multiply",
                backgroundSize: "cover",
              }}
            >
              <Banner />
            </Col>
            <Col
              className={`bg-white col-6 h-100 d-flex flex-column justify-content-center`}
              style={{
                padding: "2rem",
              }}
            >
              <button
                className="btn-close"
                onClick={handleCloseRegisterModal}
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px", // Memindahkan tombol ke sudut kanan atas
                  zIndex: "1050",
                }}
              ></button>
              <Modal.Body>
                <RegisForm />
              </Modal.Body>
            </Col>
          </Row>
        </div>
      </Modal>

      <Modal show={showRegisterModalMobile} onHide={handleCloseRegisterModalMobile} size="s">
        <Modal.Body>
          <button className="btn-close" onClick={handleCloseRegisterModalMobile} style={{
            position: 'absolute',
            top: '10px',
            right: '10px', // Memindahkan tombol ke sudut kanan atas
            zIndex: '1050',
          }}></button>
          <img src={image12} className="w-100 img-modal"></img>
          <div className="Paragraph" style={{alignSelf: 'stretch', textAlign: 'center', color: '#525A80', fontSize: 16, fontFamily: 'Source Sans Pro', wordWrap: 'break-word'}}> Pendaftaran hanya dapat dilakukan pada  Desktop (Laptop/PC)</div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-primary btn-block" onClick={handleCloseRegisterModalMobile} style={{ width: "100%" }}>
            Kembali
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default LearningMaterials;

export const subbab_list = [
    {
        "id": "eba8386d-6fa6-a075-3478-24721127e9a9",
        "nama": "Sub Bab 1",
        "idTopik": "280356a2-22ae-6a0a-a01f-079ddecb032f",
        "judulTopik": "Topik 1",
        "idBab": "1422bcfe-d146-129e-8257-b741daef3412",
        "namaBab": "Bab 1",
        "skalaNilai": 46,
        "visibilitas": "publik",
        "tag": "OOP, STRUCTURE",
        "jumlahMateri": 2,
        "durasi": "13:00",
        "progress": 70,
        "isUnlocked": true,
        "terakhirDipelajari": "64e0fdcb-bd8c-471f-b3bc-92a87ff87fb3"
    },
    {
        "id": "71c4475f-97da-1883-0596-2208d0735b6a",
        "nama": "Sub Bab 2",
        "idTopik": "280356a2-22ae-6a0a-a01f-079ddecb032f",
        "judulTopik": "Topik 1",
        "idBab": "1422bcfe-d146-129e-8257-b741daef3412",
        "namaBab": "Bab 1",
        "skalaNilai": 43,
        "visibilitas": "sembunyi",
        "tag": "FUNDAMENTAL, STRUCTURE",
        "jumlahMateri": 5,
        "durasi": "14:00",
        "progress": 30,
        "isUnlocked": false,
        "terakhirDipelajari": "e20cbe67-8447-07d7-e282-4a9922c9bd40"
    },
    {
        "id": "eba8386d-6fa6-a075-3478-24721127e9aa",
        "nama": "Sub Bab 11",
        "idTopik": "280356a2-22ae-6a0a-a01f-079ddecb032f",
        "judulTopik": "Topik 1",
        "idBab": "1422bcfe-d146-129e-8257-b741daef3412",
        "namaBab": "Bab 1",
        "skalaNilai": 46,
        "visibilitas": "publik",
        "tag": "OOP, STRUCTURE",
        "jumlahMateri": 5,
        "durasi": "14:00",
        "progress": 30,
        "isUnlocked": true,
        "terakhirDipelajari": "64e0fdcb-bd8c-471f-b3bc-92a87ff87fb3"
    },
    {
        "id": "71c4475f-97da-1883-0596-2208d0735b66",
        "nama": "Sub Bab 22",
        "idTopik": "280356a2-22ae-6a0a-a01f-079ddecb032f",
        "judulTopik": "Topik 1",
        "idBab": "1422bcfe-d146-129e-8257-b741daef3412",
        "namaBab": "Bab 1",
        "skalaNilai": 43,
        "visibilitas": "sembunyi",
        "tag": "FUNDAMENTAL, STRUCTURE",
        "jumlahMateri": 5,
        "durasi": "14:00",
        "progress": 30,
        "isUnlocked": false,
        "terakhirDipelajari": "e20cbe67-8447-07d7-e282-4a9922c9bd40"
    },
    {
        "id": "5e8d73c9-fd2a-b42e-d6c5-3cc7dfb0939f",
        "nama": "Sub Bab 3",
        "idTopik": "280356a2-22ae-6a0a-a01f-079ddecb032f",
        "judulTopik": "Topik 1",
        "idBab": "9c9b28fe-f3f1-be9e-b850-ef4ef764e39f",
        "namaBab": "Bab 2",
        "skalaNilai": 63,
        "visibilitas": "publik",
        "tag": "OOP, FUNDAMENTAL",
        "jumlahMateri": 5,
        "durasi": "13:00",
        "progress": 30,
        "isUnlocked": true,
        "terakhirDipelajari": "f9c6a384-9a01-2d0f-b044-cf8d676bbc66"
    },
    {
        "id": "1e2f4000-14fe-ce67-cc1c-9c1ef54b8c1f",
        "nama": "Sub Bab 4",
        "idTopik": "280356a2-22ae-6a0a-a01f-079ddecb032f",
        "judulTopik": "Topik 1",
        "idBab": "9c9b28fe-f3f1-be9e-b850-ef4ef764e39f",
        "namaBab": "Bab 2",
        "skalaNilai": 100,
        "visibilitas": "sembunyi",
        "tag": "OOP, STRUCTURE",
        "jumlahMateri": 5,
        "durasi": "13:00",
        "progress": 30,
        "isUnlocked": false,
        "terakhirDipelajari": "2a66006d-4b9a-0ee6-582f-e0c93e2b0ced"
    },
    {
        "id": "5e8d73c9-fd2a-b42e-d6c5-3cc7dfb09399",
        "nama": "Sub Bab 33",
        "idTopik": "280356a2-22ae-6a0a-a01f-079ddecb032f",
        "judulTopik": "Topik 1",
        "idBab": "9c9b28fe-f3f1-be9e-b850-ef4ef764e39f",
        "namaBab": "Bab 2",
        "skalaNilai": 63,
        "visibilitas": "publik",
        "tag": "OOP, FUNDAMENTAL",
        "jumlahMateri": 5,
        "durasi": "14:00",
        "progress": 30,
        "isUnlocked": true,
        "terakhirDipelajari": "f9c6a384-9a01-2d0f-b044-cf8d676bbc66"
    },
    {
        "id": "1e2f4000-14fe-ce67-cc1c-9c1ef54b8c11",
        "nama": "Sub Bab 44",
        "idTopik": "280356a2-22ae-6a0a-a01f-079ddecb032f",
        "judulTopik": "Topik 1",
        "idBab": "9c9b28fe-f3f1-be9e-b850-ef4ef764e39f",
        "namaBab": "Bab 2",
        "skalaNilai": 100,
        "visibilitas": "sembunyi",
        "tag": "OOP, STRUCTURE",
        "jumlahMateri": 5,
        "durasi": "14:00",
        "progress": 30,
        "isUnlocked": false,
        "terakhirDipelajari": "2a66006d-4b9a-0ee6-582f-e0c93e2b0ced"
    },
    {
        "id": "eba8386d-6fa6-a075-3478-24721127e9a9",
        "nama": "Sub Bab 1",
        "idTopik": "280356a2-22ae-6a0a-a01f-079ddecb032f",
        "judulTopik": "Topik 1",
        "idBab": "1422bcfe-d146-129e-8257-b741daef3411",
        "namaBab": "Bab 1",
        "skalaNilai": 46,
        "visibilitas": "publik",
        "tag": "OOP, STRUCTURE",
        "jumlahMateri": 5,
        "durasi": "14:00",
        "progress": 30,
        "isUnlocked": true,
        "terakhirDipelajari": "64e0fdcb-bd8c-471f-b3bc-92a87ff87fb3"
    },
    {
        "id": "71c4475f-97da-1883-0596-2208d0735b6a",
        "nama": "Sub Bab 2",
        "idTopik": "280356a2-22ae-6a0a-a01f-079ddecb032f",
        "judulTopik": "Topik 1",
        "idBab": "1422bcfe-d146-129e-8257-b741daef3411",
        "namaBab": "Bab 1",
        "skalaNilai": 43,
        "visibilitas": "sembunyi",
        "tag": "FUNDAMENTAL, STRUCTURE",
        "jumlahMateri": 5,
        "durasi": "14:00",
        "progress": 30,
        "isUnlocked": false,
        "terakhirDipelajari": "e20cbe67-8447-07d7-e282-4a9922c9bd40"
    },
    {
        "id": "eba8386d-6fa6-a075-3478-24721127e9aa",
        "nama": "Sub Bab 11",
        "idTopik": "280356a2-22ae-6a0a-a01f-079ddecb032f",
        "judulTopik": "Topik 1",
        "idBab": "1422bcfe-d146-129e-8257-b741daef3411",
        "namaBab": "Bab 1",
        "skalaNilai": 46,
        "visibilitas": "publik",
        "tag": "OOP, STRUCTURE",
        "jumlahMateri": 5,
        "durasi": "14:00",
        "progress": 30,
        "isUnlocked": true,
        "terakhirDipelajari": "64e0fdcb-bd8c-471f-b3bc-92a87ff87fb3"
    },
    {
        "id": "71c4475f-97da-1883-0596-2208d0735b66",
        "nama": "Sub Bab 22",
        "idTopik": "280356a2-22ae-6a0a-a01f-079ddecb032f",
        "judulTopik": "Topik 1",
        "idBab": "1422bcfe-d146-129e-8257-b741daef3411",
        "namaBab": "Bab 1",
        "skalaNilai": 43,
        "visibilitas": "sembunyi",
        "tag": "FUNDAMENTAL, STRUCTURE",
        "jumlahMateri": 5,
        "durasi": "14:00",
        "progress": 30,
        "isUnlocked": false,
        "terakhirDipelajari": "e20cbe67-8447-07d7-e282-4a9922c9bd40"
    },
    {
        "id": "5e8d73c9-fd2a-b42e-d6c5-3cc7dfb0939f",
        "nama": "Sub Bab 3",
        "idTopik": "280356a2-22ae-6a0a-a01f-079ddecb032f",
        "judulTopik": "Topik 1",
        "idBab": "9c9b28fe-f3f1-be9e-b850-ef4ef764e399",
        "namaBab": "Bab 2",
        "skalaNilai": 63,
        "visibilitas": "publik",
        "tag": "OOP, FUNDAMENTAL",
        "jumlahMateri": 5,
        "durasi": "14:00",
        "progress": 30,
        "isUnlocked": true,
        "terakhirDipelajari": "f9c6a384-9a01-2d0f-b044-cf8d676bbc66"
    },
    {
        "id": "1e2f4000-14fe-ce67-cc1c-9c1ef54b8c1f",
        "nama": "Sub Bab 4",
        "idTopik": "280356a2-22ae-6a0a-a01f-079ddecb032f",
        "judulTopik": "Topik 1",
        "idBab": "9c9b28fe-f3f1-be9e-b850-ef4ef764e399",
        "namaBab": "Bab 2",
        "skalaNilai": 100,
        "visibilitas": "sembunyi",
        "tag": "OOP, STRUCTURE",
        "jumlahMateri": 5,
        "durasi": "14:00",
        "progress": 30,
        "isUnlocked": false,
        "terakhirDipelajari": "2a66006d-4b9a-0ee6-582f-e0c93e2b0ced"
    },
    {
        "id": "5e8d73c9-fd2a-b42e-d6c5-3cc7dfb09399",
        "nama": "Sub Bab 33",
        "idTopik": "280356a2-22ae-6a0a-a01f-079ddecb032f",
        "judulTopik": "Topik 1",
        "idBab": "9c9b28fe-f3f1-be9e-b850-ef4ef764e399",
        "namaBab": "Bab 2",
        "skalaNilai": 63,
        "visibilitas": "publik",
        "tag": "OOP, FUNDAMENTAL",
        "jumlahMateri": 5,
        "durasi": "14:00",
        "progress": 30,
        "isUnlocked": true,
        "terakhirDipelajari": "f9c6a384-9a01-2d0f-b044-cf8d676bbc66"
    },
    {
        "id": "1e2f4000-14fe-ce67-cc1c-9c1ef54b8c11",
        "nama": "Sub Bab 44",
        "idTopik": "280356a2-22ae-6a0a-a01f-079ddecb032f",
        "judulTopik": "Topik 1",
        "idBab": "9c9b28fe-f3f1-be9e-b850-ef4ef764e399",
        "namaBab": "Bab 2",
        "skalaNilai": 100,
        "visibilitas": "sembunyi",
        "tag": "OOP, STRUCTURE",
        "jumlahMateri": 5,
        "durasi": "14:00",
        "progress": 30,
        "isUnlocked": false,
        "terakhirDipelajari": "2a66006d-4b9a-0ee6-582f-e0c93e2b0ced"
    },
    {
        "id": "4e09df8a-e925-4821-c5db-b7a65f5ecb1a",
        "nama": "Sub Bab 5",
        "idTopik": "af98c9cf-c63a-7b39-84a2-d685eea95338",
        "judulTopik": "Topik 2",
        "idBab": "a30c4077-2f82-21b3-6647-37c26cee56e9",
        "namaBab": "Bab 3",
        "skalaNilai": 38,
        "visibilitas": "publik",
        "tag": "FUNDAMENTAL, STRUCTURE",
        "jumlahMateri": 5,
        "durasi": "13:00",
        "progress": 30,
        "isUnlocked": true,
        "terakhirDipelajari": "3096d52f-cec2-7d85-17f5-1db82dfcebe8"
    },
    {
        "id": "53bce5f0-4f07-da7f-a21a-769112304447",
        "nama": "Sub Bab 6",
        "idTopik": "af98c9cf-c63a-7b39-84a2-d685eea95338",
        "judulTopik": "Topik 2",
        "idBab": "a30c4077-2f82-21b3-6647-37c26cee56e9",
        "namaBab": "Bab 3",
        "skalaNilai": 60,
        "visibilitas": "sembunyi",
        "tag": "OOP, FUNDAMENTAL",
        "jumlahMateri": 5,
        "durasi": "13:00",
        "progress": 30,
        "isUnlocked": false,
        "terakhirDipelajari": "3364bb0c-7cb8-fc67-1022-ea448c9fec2d"
    },
    {
        "id": "3d010d04-81c1-a1eb-f38a-2b75af3bfcfa",
        "nama": "Sub Bab 7",
        "idTopik": "af98c9cf-c63a-7b39-84a2-d685eea95338",
        "judulTopik": "Topik 2",
        "idBab": "7de7056f-de17-a016-0084-1a79edfe9552",
        "namaBab": "Bab 4",
        "skalaNilai": 51,
        "visibilitas": "publik",
        "tag": "OOP, STRUCTURE",
        "jumlahMateri": 5,
        "durasi": "13:00",
        "progress": 30,
        "isUnlocked": true,
        "terakhirDipelajari": "80e28640-ec78-7292-0f81-6eb64b43505d"
    },
    {
        "id": "98c33766-a3e2-793f-dcc2-3f2899f02665",
        "nama": "Sub Bab 8",
        "idTopik": "af98c9cf-c63a-7b39-84a2-d685eea95338",
        "judulTopik": "Topik 2",
        "idBab": "7de7056f-de17-a016-0084-1a79edfe9552",
        "namaBab": "Bab 4",
        "skalaNilai": 33,
        "visibilitas": "sembunyi",
        "tag": "FUNDAMENTAL, STRUCTURE",
        "jumlahMateri": 5,
        "durasi": "13:00",
        "progress": 30,
        "isUnlocked": false,
        "terakhirDipelajari": "74a87fed-e14e-c1c2-e520-82172cae8aca"
    },
    {
        "id": "c116d9fa-4902-781b-130b-baee240513c2",
        "nama": "Sub Bab 9",
        "idTopik": "ca22e53b-1be1-b400-2b59-d45b31541cba",
        "judulTopik": "Topik 3",
        "idBab": "118423cc-81f3-fc8f-2e02-9476fc60b9e8",
        "namaBab": "Bab 5",
        "skalaNilai": 39,
        "visibilitas": "publik",
        "tag": "OOP, FUNDAMENTAL",
        "jumlahMateri": 5,
        "durasi": "13:00",
        "progress": 30,
        "isUnlocked": true,
        "terakhirDipelajari": "226820f6-c41d-9bfa-5d16-c8b5b8d5cbc2"
    },
    {
        "id": "212c7c9c-6611-2f2f-789d-d34cb5dee47c",
        "nama": "Sub Bab 10",
        "idTopik": "ca22e53b-1be1-b400-2b59-d45b31541cba",
        "judulTopik": "Topik 3",
        "idBab": "118423cc-81f3-fc8f-2e02-9476fc60b9e8",
        "namaBab": "Bab 5",
        "skalaNilai": 77,
        "visibilitas": "sembunyi",
        "tag": "OOP, STRUCTURE",
        "jumlahMateri": 5,
        "durasi": "13:00",
        "progress": 30,
        "isUnlocked": false,
        "terakhirDipelajari": "abc8a502-35c4-1aac-63d5-0b86348fc78a"
    },
    {
        "id": "47a9f3a9-93b9-d12b-7145-b3b5f2378d52",
        "nama": "Sub Bab 11",
        "idTopik": "ca22e53b-1be1-b400-2b59-d45b31541cba",
        "judulTopik": "Topik 3",
        "idBab": "5f24bfa7-f928-337e-35a2-13177f236284",
        "namaBab": "Bab 6",
        "skalaNilai": 79,
        "visibilitas": "publik",
        "tag": "FUNDAMENTAL, STRUCTURE",
        "jumlahMateri": 5,
        "durasi": "13:00",
        "progress": 30,
        "isUnlocked": true,
        "terakhirDipelajari": "d5043e15-24d4-d9db-d523-b344a4d2098f"
    },
    {
        "id": "b718ea14-3eba-1f71-a189-c91c027bf195",
        "nama": "Sub Bab 12",
        "idTopik": "ca22e53b-1be1-b400-2b59-d45b31541cba",
        "judulTopik": "Topik 3",
        "idBab": "5f24bfa7-f928-337e-35a2-13177f236284",
        "namaBab": "Bab 6",
        "skalaNilai": 52,
        "visibilitas": "sembunyi",
        "tag": "OOP, FUNDAMENTAL",
        "jumlahMateri": 5,
        "durasi": "13:00",
        "progress": 30,
        "isUnlocked": false,
        "terakhirDipelajari": "68ea28b5-c043-e8d3-4c0f-0b02665d176d"
    },
    {
        "id": "5a326abf-4b81-23bf-a6bf-f1d47b820779",
        "nama": "Sub Bab 13",
        "idTopik": "8b5d9330-f859-b18e-897c-235deadce6d2",
        "judulTopik": "Topik 4",
        "idBab": "ee75f230-b282-46f2-4c86-37133c88e819",
        "namaBab": "Bab 7",
        "skalaNilai": 99,
        "visibilitas": "publik",
        "tag": "OOP, STRUCTURE",
        "jumlahMateri": 5,
        "durasi": "13:00",
        "progress": 30,
        "isUnlocked": true,
        "terakhirDipelajari": "8fcc0c7d-ed12-898e-1d06-15678c483360"
    },
    {
        "id": "a44b717e-1b77-a7a1-5cf9-e97d3b19be48",
        "nama": "Sub Bab 14",
        "idTopik": "8b5d9330-f859-b18e-897c-235deadce6d2",
        "judulTopik": "Topik 4",
        "idBab": "ee75f230-b282-46f2-4c86-37133c88e819",
        "namaBab": "Bab 7",
        "skalaNilai": 24,
        "visibilitas": "sembunyi",
        "tag": "FUNDAMENTAL, STRUCTURE",
        "jumlahMateri": 5,
        "durasi": "13:00",
        "progress": 30,
        "isUnlocked": false,
        "terakhirDipelajari": "75c48248-dfd2-52ee-641a-e24b7a264ced"
    },
    {
        "id": "21d17169-46d5-9ed3-411c-09169c424f6c",
        "nama": "Sub Bab 15",
        "idTopik": "8b5d9330-f859-b18e-897c-235deadce6d2",
        "judulTopik": "Topik 4",
        "idBab": "e39a05e7-c89c-ae32-ed3a-ea1f25765a97",
        "namaBab": "Bab 8",
        "skalaNilai": 32,
        "visibilitas": "publik",
        "tag": "OOP, FUNDAMENTAL",
        "jumlahMateri": 5,
        "durasi": "13:00",
        "progress": 30,
        "isUnlocked": true,
        "terakhirDipelajari": "e5335ae1-ead9-aa2f-7281-3a9aa0bc1326"
    },
    {
        "id": "a3dcc87e-0469-8906-00e3-ac977002e28a",
        "nama": "Sub Bab 16",
        "idTopik": "8b5d9330-f859-b18e-897c-235deadce6d2",
        "judulTopik": "Topik 4",
        "idBab": "e39a05e7-c89c-ae32-ed3a-ea1f25765a97",
        "namaBab": "Bab 8",
        "skalaNilai": 95,
        "visibilitas": "sembunyi",
        "tag": "OOP, STRUCTURE",
        "jumlahMateri": 5,
        "durasi": "13:00",
        "progress": 30,
        "isUnlocked": false,
        "terakhirDipelajari": "54528efb-4dd8-b56f-2f53-29b880ee9160"
    },
    {
        "id": "57b2e73e-73f1-b803-8265-ff5d53557808",
        "nama": "Sub Bab 17",
        "idTopik": "cec92ad2-b8b3-5dfa-6820-6457e897be31",
        "judulTopik": "Topik 5",
        "idBab": "a45371ff-ad1a-c352-5a53-ca14297f10ea",
        "namaBab": "Bab 9",
        "skalaNilai": 58,
        "visibilitas": "publik",
        "tag": "FUNDAMENTAL, STRUCTURE",
        "jumlahMateri": 5,
        "durasi": "13:00",
        "progress": 30,
        "isUnlocked": true,
        "terakhirDipelajari": "eafa5126-4b29-a99b-2244-37268df35815"
    },
    {
        "id": "f8927d1a-ef23-bd53-3404-96dbf0ef0624",
        "nama": "Sub Bab 18",
        "idTopik": "cec92ad2-b8b3-5dfa-6820-6457e897be31",
        "judulTopik": "Topik 5",
        "idBab": "a45371ff-ad1a-c352-5a53-ca14297f10ea",
        "namaBab": "Bab 9",
        "skalaNilai": 86,
        "visibilitas": "sembunyi",
        "tag": "OOP, FUNDAMENTAL",
        "jumlahMateri": 5,
        "durasi": "13:00",
        "progress": 30,
        "isUnlocked": false,
        "terakhirDipelajari": "3dab661a-17c2-665b-bfb5-44bf26e0fd8f"
    },
    {
        "id": "aa895c4d-e500-efea-4195-e03586e02300",
        "nama": "Sub Bab 19",
        "idTopik": "cec92ad2-b8b3-5dfa-6820-6457e897be31",
        "judulTopik": "Topik 5",
        "idBab": "e72944e0-111e-1850-cbe8-142644cd6dbb",
        "namaBab": "Bab 10",
        "skalaNilai": 91,
        "visibilitas": "publik",
        "tag": "OOP, STRUCTURE",
        "jumlahMateri": 5,
        "durasi": "13:00",
        "progress": 30,
        "isUnlocked": true,
        "terakhirDipelajari": "cb1e789d-692d-0c41-869e-e62a71e8a6ac"
    },
    {
        "id": "885a3fed-a71a-c968-8d23-40678d3cc273",
        "nama": "Sub Bab 20",
        "idTopik": "cec92ad2-b8b3-5dfa-6820-6457e897be31",
        "judulTopik": "Topik 5",
        "idBab": "e72944e0-111e-1850-cbe8-142644cd6dbb",
        "namaBab": "Bab 10",
        "skalaNilai": 36,
        "visibilitas": "sembunyi",
        "tag": "FUNDAMENTAL, STRUCTURE",
        "jumlahMateri": 5,
        "durasi": "13:00",
        "progress": 30,
        "isUnlocked": false,
        "terakhirDipelajari": "adec692a-b866-b90e-7df8-74b8ca7cdc6e"
    },
    {
        "id": "408f62a3-ed57-3e09-769a-d15a42d43e41",
        "nama": "Sub Bab 21",
        "idTopik": "7595d015-87e5-1589-d792-44c282f989bf",
        "judulTopik": "Topik 6",
        "idBab": "e6feb036-dd29-6a7d-01a2-729afc02b3d3",
        "namaBab": "Bab 11",
        "skalaNilai": 83,
        "visibilitas": "publik",
        "tag": "OOP, FUNDAMENTAL",
        "jumlahMateri": 5,
        "durasi": "13:00",
        "progress": 30,
        "isUnlocked": true,
        "terakhirDipelajari": "75a58d17-2eb0-d5dc-d49f-cb97e147e8ca"
    },
    {
        "id": "d899ff61-5a44-0a5a-2556-755e73ee63db",
        "nama": "Sub Bab 22",
        "idTopik": "7595d015-87e5-1589-d792-44c282f989bf",
        "judulTopik": "Topik 6",
        "idBab": "e6feb036-dd29-6a7d-01a2-729afc02b3d3",
        "namaBab": "Bab 11",
        "skalaNilai": 33,
        "visibilitas": "sembunyi",
        "tag": "OOP, STRUCTURE",
        "jumlahMateri": 5,
        "durasi": "13:00",
        "progress": 30,
        "isUnlocked": false,
        "terakhirDipelajari": "c0a058de-7b08-7782-8db2-09da6e56c119"
    },
    {
        "id": "f5584d6d-8f0f-436b-e578-8f4b8076c968",
        "nama": "Sub Bab 23",
        "idTopik": "7595d015-87e5-1589-d792-44c282f989bf",
        "judulTopik": "Topik 6",
        "idBab": "01a4a304-e869-d952-5ba4-4c4aa58a16e9",
        "namaBab": "Bab 12",
        "skalaNilai": 93,
        "visibilitas": "publik",
        "tag": "FUNDAMENTAL, STRUCTURE",
        "jumlahMateri": 5,
        "durasi": "13:00",
        "progress": 30,
        "isUnlocked": true,
        "terakhirDipelajari": "b67c17d7-3cfb-0488-e2ac-ff295e7c2081"
    },
    {
        "id": "f486f69a-a73a-29fe-a869-45aef79dc5c7",
        "nama": "Sub Bab 24",
        "idTopik": "7595d015-87e5-1589-d792-44c282f989bf",
        "judulTopik": "Topik 6",
        "idBab": "01a4a304-e869-d952-5ba4-4c4aa58a16e9",
        "namaBab": "Bab 12",
        "skalaNilai": 53,
        "visibilitas": "sembunyi",
        "tag": "OOP, FUNDAMENTAL",
        "jumlahMateri": 5,
        "durasi": "13:00",
        "progress": 30,
        "isUnlocked": false,
        "terakhirDipelajari": "7d4e61b7-e3b0-93cf-4642-7d382c5a58f8"
    },
    {
        "id": "22a2101f-382a-6e47-71fd-4eee423a5b12",
        "nama": "Sub Bab 25",
        "idTopik": "8f27d94e-f413-c43b-ae0f-ea8a6222fb3e",
        "judulTopik": "Topik 7",
        "idBab": "15b4ff4e-dd82-4c9d-09fb-9188d2fb45cf",
        "namaBab": "Bab 13",
        "skalaNilai": 98,
        "visibilitas": "publik",
        "tag": "OOP, STRUCTURE",
        "jumlahMateri": 5,
        "durasi": "13:00",
        "progress": 30,
        "isUnlocked": true,
        "terakhirDipelajari": "55e7889f-e0c0-6c4b-a689-4ed247dd4879"
    },
    {
        "id": "b5e3c030-b2e2-2921-cab2-4119d3a0a7af",
        "nama": "Sub Bab 26",
        "idTopik": "8f27d94e-f413-c43b-ae0f-ea8a6222fb3e",
        "judulTopik": "Topik 7",
        "idBab": "15b4ff4e-dd82-4c9d-09fb-9188d2fb45cf",
        "namaBab": "Bab 13",
        "skalaNilai": 25,
        "visibilitas": "sembunyi",
        "tag": "FUNDAMENTAL, STRUCTURE",
        "jumlahMateri": 5,
        "durasi": "13:00",
        "progress": 30,
        "isUnlocked": false,
        "terakhirDipelajari": "09f4895a-cf16-a76e-3ecb-8740e20c4644"
    },
    {
        "id": "ea706b0d-7887-890d-e196-5b97fe4a595c",
        "nama": "Sub Bab 27",
        "idTopik": "8f27d94e-f413-c43b-ae0f-ea8a6222fb3e",
        "judulTopik": "Topik 7",
        "idBab": "73131c2f-a51a-18b2-9e5b-dc5bf71f8f3b",
        "namaBab": "Bab 14",
        "skalaNilai": 38,
        "visibilitas": "publik",
        "tag": "OOP, FUNDAMENTAL",
        "jumlahMateri": 5,
        "durasi": "13:00",
        "progress": 30,
        "isUnlocked": true,
        "terakhirDipelajari": "02906ee0-6ee6-2489-5c30-14ac7e5d4b6b"
    },
    {
        "id": "da86a42f-d45d-522f-b455-ae9eb23e3134",
        "nama": "Sub Bab 28",
        "idTopik": "8f27d94e-f413-c43b-ae0f-ea8a6222fb3e",
        "judulTopik": "Topik 7",
        "idBab": "73131c2f-a51a-18b2-9e5b-dc5bf71f8f3b",
        "namaBab": "Bab 14",
        "skalaNilai": 52,
        "visibilitas": "sembunyi",
        "tag": "OOP, STRUCTURE",
        "jumlahMateri": 5,
        "durasi": "13:00",
        "progress": 30,
        "isUnlocked": false,
        "terakhirDipelajari": "5e3fb17b-160c-02f1-68d8-601ae050b77f"
    },
    {
        "id": "81590cf3-7dcd-8087-8fdb-9eda0eec6fd3",
        "nama": "Sub Bab 29",
        "idTopik": "5bdd9c22-a054-91f4-d2ca-0190a9609a62",
        "judulTopik": "Topik 8",
        "idBab": "f92cdd19-925a-34e8-9cc7-cea7b9c00fba",
        "namaBab": "Bab 15",
        "skalaNilai": 57,
        "visibilitas": "publik",
        "tag": "FUNDAMENTAL, STRUCTURE",
        "jumlahMateri": 5,
        "durasi": "13:00",
        "progress": 30,
        "isUnlocked": true,
        "terakhirDipelajari": "7ec5a0d8-e3be-0ef8-197f-0956a918b4d0"
    },
    {
        "id": "c77438dd-bd9b-cb09-606f-8b622a4392bd",
        "nama": "Sub Bab 30",
        "idTopik": "5bdd9c22-a054-91f4-d2ca-0190a9609a62",
        "judulTopik": "Topik 8",
        "idBab": "f92cdd19-925a-34e8-9cc7-cea7b9c00fba",
        "namaBab": "Bab 15",
        "skalaNilai": 83,
        "visibilitas": "sembunyi",
        "tag": "OOP, FUNDAMENTAL",
        "jumlahMateri": 5,
        "durasi": "13:00",
        "progress": 30,
        "isUnlocked": false,
        "terakhirDipelajari": "1480c95b-09e6-a838-01dc-48a949c53c44"
    },
    {
        "id": "641ce0ed-06aa-fd25-154c-f25c26e6c43f",
        "nama": "Sub Bab 31",
        "idTopik": "5bdd9c22-a054-91f4-d2ca-0190a9609a62",
        "judulTopik": "Topik 8",
        "idBab": "10ce4eca-cdc4-7c1b-03f0-f352026dafbf",
        "namaBab": "Bab 16",
        "skalaNilai": 69,
        "visibilitas": "publik",
        "tag": "OOP, STRUCTURE",
        "jumlahMateri": 5,
        "durasi": "13:00",
        "progress": 30,
        "isUnlocked": true,
        "terakhirDipelajari": "1a3e8c25-c552-fed7-2c3a-04766cc6a47a"
    },
    {
        "id": "1065de1f-1831-405d-b3ba-212a96b1b5e8",
        "nama": "Sub Bab 32",
        "idTopik": "5bdd9c22-a054-91f4-d2ca-0190a9609a62",
        "judulTopik": "Topik 8",
        "idBab": "10ce4eca-cdc4-7c1b-03f0-f352026dafbf",
        "namaBab": "Bab 16",
        "skalaNilai": 25,
        "visibilitas": "sembunyi",
        "tag": "FUNDAMENTAL, STRUCTURE",
        "jumlahMateri": 5,
        "durasi": "13:00",
        "progress": 30,
        "isUnlocked": false,
        "terakhirDipelajari": "42c110c7-175e-d685-e9fa-73776e9415fb"
    },
    {
        "id": "595589f8-6013-7a8d-d79e-888a7b44e30c",
        "nama": "Sub Bab 33",
        "idTopik": "04d3cb50-ec00-4c70-0cce-2abc403bb9c9",
        "judulTopik": "Topik 9",
        "idBab": "8fb049c5-1b46-133c-cea3-26f5b5d688fe",
        "namaBab": "Bab 17",
        "skalaNilai": 65,
        "visibilitas": "publik",
        "tag": "OOP, FUNDAMENTAL",
        "jumlahMateri": 5,
        "durasi": "13:00",
        "progress": 30,
        "isUnlocked": true,
        "terakhirDipelajari": "e3019428-f8e8-de33-7fef-ebe64ac7856c"
    },
    {
        "id": "53bc30db-f224-02f6-ff2d-3c07d0903e21",
        "nama": "Sub Bab 34",
        "idTopik": "04d3cb50-ec00-4c70-0cce-2abc403bb9c9",
        "judulTopik": "Topik 9",
        "idBab": "8fb049c5-1b46-133c-cea3-26f5b5d688fe",
        "namaBab": "Bab 17",
        "skalaNilai": 67,
        "visibilitas": "sembunyi",
        "tag": "OOP, STRUCTURE",
        "jumlahMateri": 5,
        "durasi": "13:00",
        "progress": 30,
        "isUnlocked": false,
        "terakhirDipelajari": "be46c473-55f3-21d1-1798-b64088bb6732"
    },
    {
        "id": "a9402449-9232-c77b-4811-b511955663a7",
        "nama": "Sub Bab 35",
        "idTopik": "04d3cb50-ec00-4c70-0cce-2abc403bb9c9",
        "judulTopik": "Topik 9",
        "idBab": "4ab7b616-dbcc-51da-e37c-be1797c58dd1",
        "namaBab": "Bab 18",
        "skalaNilai": 71,
        "visibilitas": "publik",
        "tag": "FUNDAMENTAL, STRUCTURE",
        "jumlahMateri": 5,
        "durasi": "13:00",
        "progress": 30,
        "isUnlocked": true,
        "terakhirDipelajari": "7a428d27-cee6-6c24-10f9-b3d3d300d0d7"
    },
    {
        "id": "7a37057f-b9d4-c3aa-6201-10ef0b6a5bff",
        "nama": "Sub Bab 36",
        "idTopik": "04d3cb50-ec00-4c70-0cce-2abc403bb9c9",
        "judulTopik": "Topik 9",
        "idBab": "4ab7b616-dbcc-51da-e37c-be1797c58dd1",
        "namaBab": "Bab 18",
        "skalaNilai": 50,
        "visibilitas": "sembunyi",
        "tag": "OOP, FUNDAMENTAL",
        "jumlahMateri": 5,
        "durasi": "13:00",
        "progress": 30,
        "isUnlocked": false,
        "terakhirDipelajari": "de28f68b-7749-4127-9131-fa84fabe4386"
    },
    {
        "id": "8c688a7f-7f1e-92ea-9cbb-73255f64a670",
        "nama": "Sub Bab 37",
        "idTopik": "8f0530b2-1434-51c4-c09c-fd3d65301afd",
        "judulTopik": "Topik 10",
        "idBab": "1863f923-f601-8753-96cf-ed44813aaaea",
        "namaBab": "Bab 19",
        "skalaNilai": 30,
        "visibilitas": "publik",
        "tag": "OOP, STRUCTURE",
        "jumlahMateri": 5,
        "durasi": "13:00",
        "progress": 30,
        "isUnlocked": true,
        "terakhirDipelajari": "6ec0983a-2a19-9b68-4ab3-d07149afab31"
    },
    {
        "id": "bd10dc93-7d15-4674-f861-df2ebde8f9cb",
        "nama": "Sub Bab 38",
        "idTopik": "8f0530b2-1434-51c4-c09c-fd3d65301afd",
        "judulTopik": "Topik 10",
        "idBab": "1863f923-f601-8753-96cf-ed44813aaaea",
        "namaBab": "Bab 19",
        "skalaNilai": 39,
        "visibilitas": "sembunyi",
        "tag": "FUNDAMENTAL, STRUCTURE",
        "jumlahMateri": 5,
        "durasi": "13:00",
        "progress": 30,
        "isUnlocked": false,
        "terakhirDipelajari": "6df1802c-111e-df57-c378-4bbfa195d95a"
    },
    {
        "id": "ca5faef4-ab81-5bf1-02e4-169e5f58e462",
        "nama": "Sub Bab 39",
        "idTopik": "8f0530b2-1434-51c4-c09c-fd3d65301afd",
        "judulTopik": "Topik 10",
        "idBab": "152ac096-5d73-9444-7401-8f4ede87228b",
        "namaBab": "Bab 20",
        "skalaNilai": 77,
        "visibilitas": "publik",
        "tag": "OOP, FUNDAMENTAL",
        "jumlahMateri": 5,
        "durasi": "13:00",
        "progress": 30,
        "isUnlocked": true,
        "terakhirDipelajari": "74eda96f-c3f1-aa99-06ae-9ea100775633"
    },
    {
        "id": "9567e508-85a3-cbca-fdc3-b2c6dfb125d9",
        "nama": "Sub Bab 40",
        "idTopik": "8f0530b2-1434-51c4-c09c-fd3d65301afd",
        "judulTopik": "Topik 10",
        "idBab": "152ac096-5d73-9444-7401-8f4ede87228b",
        "namaBab": "Bab 20",
        "skalaNilai": 40,
        "visibilitas": "sembunyi",
        "tag": "OOP, STRUCTURE",
        "jumlahMateri": 5,
        "durasi": "13:00",
        "progress": 30,
        "isUnlocked": false,
        "terakhirDipelajari": "db72eabc-0661-3f91-5b29-51e283422a35"
    },
    {
        "id": "833108e7-e864-ba39-f480-caa7a9410f80",
        "nama": "Sub Bab 41",
        "idTopik": "b326680a-0a1a-94ce-b812-d8a40eb9bdfe",
        "judulTopik": "Topik 11",
        "idBab": "a518f7ec-bcd9-c21d-64f0-393b3bdd3c7d",
        "namaBab": "Bab 21",
        "skalaNilai": 84,
        "visibilitas": "publik",
        "tag": "FUNDAMENTAL, STRUCTURE",
        "jumlahMateri": 5,
        "durasi": "13:00",
        "progress": 30,
        "isUnlocked": true,
        "terakhirDipelajari": "2677c9f0-e268-442f-f5ff-294b9fba7887"
    },
    {
        "id": "e9fcc0a4-44e8-56be-0bf7-1545c349a744",
        "nama": "Sub Bab 42",
        "idTopik": "b326680a-0a1a-94ce-b812-d8a40eb9bdfe",
        "judulTopik": "Topik 11",
        "idBab": "a518f7ec-bcd9-c21d-64f0-393b3bdd3c7d",
        "namaBab": "Bab 21",
        "skalaNilai": 76,
        "visibilitas": "sembunyi",
        "tag": "OOP, FUNDAMENTAL",
        "jumlahMateri": 5,
        "durasi": "13:00",
        "progress": 30,
        "isUnlocked": false,
        "terakhirDipelajari": "50d725fd-897a-da2e-7ba1-04f69b896050"
    },
    {
        "id": "4ca31e1b-c07a-f4bf-cca5-be76acc4b595",
        "nama": "Sub Bab 43",
        "idTopik": "b326680a-0a1a-94ce-b812-d8a40eb9bdfe",
        "judulTopik": "Topik 11",
        "idBab": "64a174f5-e52e-faa1-fad6-5640cea94e98",
        "namaBab": "Bab 22",
        "skalaNilai": 57,
        "visibilitas": "publik",
        "tag": "OOP, STRUCTURE",
        "jumlahMateri": 5,
        "durasi": "13:00",
        "progress": 30,
        "isUnlocked": true,
        "terakhirDipelajari": "9d949505-e8a8-0b87-5e0f-91c71cc5afd8"
    },
    {
        "id": "7bd383ab-2b9d-bf91-90c3-33edf5c88094",
        "nama": "Sub Bab 44",
        "idTopik": "b326680a-0a1a-94ce-b812-d8a40eb9bdfe",
        "judulTopik": "Topik 11",
        "idBab": "64a174f5-e52e-faa1-fad6-5640cea94e98",
        "namaBab": "Bab 22",
        "skalaNilai": 86,
        "visibilitas": "sembunyi",
        "tag": "FUNDAMENTAL, STRUCTURE",
        "jumlahMateri": 5,
        "durasi": "13:00",
        "progress": 30,
        "isUnlocked": false,
        "terakhirDipelajari": "4301c95a-17d9-9340-683b-493c70ce3b49"
    },
    {
        "id": "8a3467ba-c412-997b-16dc-6c149c3a3069",
        "nama": "Sub Bab 45",
        "idTopik": "82710702-6963-f5e7-6d56-f20a510d642a",
        "judulTopik": "Topik 12",
        "idBab": "aee4bb2b-2c48-d729-d101-6db70164d793",
        "namaBab": "Bab 23",
        "skalaNilai": 23,
        "visibilitas": "publik",
        "tag": "OOP, FUNDAMENTAL",
        "jumlahMateri": 5,
        "durasi": "13:00",
        "progress": 30,
        "isUnlocked": true,
        "terakhirDipelajari": "7429c867-36e6-bc8b-25d5-66dc279b5e30"
    },
    {
        "id": "c6ebe0a8-bc5e-6e83-fcb1-883f28b4b907",
        "nama": "Sub Bab 46",
        "idTopik": "82710702-6963-f5e7-6d56-f20a510d642a",
        "judulTopik": "Topik 12",
        "idBab": "aee4bb2b-2c48-d729-d101-6db70164d793",
        "namaBab": "Bab 23",
        "skalaNilai": 77,
        "visibilitas": "sembunyi",
        "tag": "OOP, STRUCTURE",
        "jumlahMateri": 5,
        "durasi": "13:00",
        "progress": 30,
        "isUnlocked": false,
        "terakhirDipelajari": "2d8c5fba-2616-06b2-be1f-92c814636c35"
    },
    {
        "id": "afb8f72b-2211-3380-e25b-db83840d1f04",
        "nama": "Sub Bab 47",
        "idTopik": "82710702-6963-f5e7-6d56-f20a510d642a",
        "judulTopik": "Topik 12",
        "idBab": "ef9000f9-6022-fa17-e7f9-877c0b256625",
        "namaBab": "Bab 24",
        "skalaNilai": 47,
        "visibilitas": "publik",
        "tag": "FUNDAMENTAL, STRUCTURE",
        "jumlahMateri": 5,
        "durasi": "13:00",
        "progress": 30,
        "isUnlocked": true,
        "terakhirDipelajari": "1455c137-a07e-b9b1-dd81-b0b213011404"
    },
    {
        "id": "f06ca2ec-7b91-cbd1-32d8-b11bebe8d2c6",
        "nama": "Sub Bab 48",
        "idTopik": "82710702-6963-f5e7-6d56-f20a510d642a",
        "judulTopik": "Topik 12",
        "idBab": "ef9000f9-6022-fa17-e7f9-877c0b256625",
        "namaBab": "Bab 24",
        "skalaNilai": 26,
        "visibilitas": "sembunyi",
        "tag": "OOP, FUNDAMENTAL",
        "jumlahMateri": 5,
        "durasi": "13:00",
        "progress": 30,
        "isUnlocked": false,
        "terakhirDipelajari": "6f8504ca-d393-7831-0019-9d50a0104d62"
    },
    {
        "id": "4372e70f-8aab-0c1a-3d60-db2ea8b2dee4",
        "nama": "Sub Bab 49",
        "idTopik": "54cc6caf-9a49-b08b-29fc-048715809a4b",
        "judulTopik": "Topik 13",
        "idBab": "45325083-6f58-3e33-570d-b0d9c11cb71a",
        "namaBab": "Bab 25",
        "skalaNilai": 53,
        "visibilitas": "publik",
        "tag": "OOP, STRUCTURE",
        "jumlahMateri": 5,
        "durasi": "13:00",
        "progress": 30,
        "isUnlocked": true,
        "terakhirDipelajari": "96ac4447-622a-e7c5-04a4-088026754ec7"
    },
    {
        "id": "cf4fdf78-5bc3-045d-84cc-244dfd123928",
        "nama": "Sub Bab 50",
        "idTopik": "54cc6caf-9a49-b08b-29fc-048715809a4b",
        "judulTopik": "Topik 13",
        "idBab": "45325083-6f58-3e33-570d-b0d9c11cb71a",
        "namaBab": "Bab 25",
        "skalaNilai": 84,
        "visibilitas": "sembunyi",
        "tag": "FUNDAMENTAL, STRUCTURE",
        "jumlahMateri": 5,
        "durasi": "13:00",
        "progress": 30,
        "isUnlocked": false,
        "terakhirDipelajari": "390cddb0-c70c-e236-6a46-6912ce935e68"
    },
    {
        "id": "415e800d-1042-6c56-b5c8-45dcbef6f71e",
        "nama": "Sub Bab 51",
        "idTopik": "54cc6caf-9a49-b08b-29fc-048715809a4b",
        "judulTopik": "Topik 13",
        "idBab": "d36ff0f1-7906-5af8-7ba7-2048fe583e65",
        "namaBab": "Bab 26",
        "skalaNilai": 63,
        "visibilitas": "publik",
        "tag": "OOP, FUNDAMENTAL",
        "jumlahMateri": 5,
        "durasi": "13:00",
        "progress": 30,
        "isUnlocked": true,
        "terakhirDipelajari": "cf8f1ee5-a964-32c2-dd49-01809759bae3"
    },
    {
        "id": "1fe78eb5-cfe2-c442-9dd6-ec44c9af06b4",
        "nama": "Sub Bab 52",
        "idTopik": "54cc6caf-9a49-b08b-29fc-048715809a4b",
        "judulTopik": "Topik 13",
        "idBab": "d36ff0f1-7906-5af8-7ba7-2048fe583e65",
        "namaBab": "Bab 26",
        "skalaNilai": 25,
        "visibilitas": "sembunyi",
        "tag": "OOP, STRUCTURE",
        "jumlahMateri": 5,
        "durasi": "13:00",
        "progress": 30,
        "isUnlocked": false,
        "terakhirDipelajari": "dab24e49-1a0a-75dd-fe76-31084fca9c91"
    },
    {
        "id": "154d69b8-bcee-9e09-1793-1f954c8cfa16",
        "nama": "Sub Bab 53",
        "idTopik": "2619ca56-a229-5e88-6a8e-9468495cb046",
        "judulTopik": "Topik 14",
        "idBab": "77464420-f572-e205-01d8-f4817c217ab4",
        "namaBab": "Bab 27",
        "skalaNilai": 58,
        "visibilitas": "publik",
        "tag": "FUNDAMENTAL, STRUCTURE",
        "jumlahMateri": 5,
        "durasi": "13:00",
        "progress": 30,
        "isUnlocked": true,
        "terakhirDipelajari": "e9604315-1da8-2381-8358-ac7ea4b664c7"
    },
    {
        "id": "8e264ff3-70e6-c353-6682-8617ac50dd9d",
        "nama": "Sub Bab 54",
        "idTopik": "2619ca56-a229-5e88-6a8e-9468495cb046",
        "judulTopik": "Topik 14",
        "idBab": "77464420-f572-e205-01d8-f4817c217ab4",
        "namaBab": "Bab 27",
        "skalaNilai": 50,
        "visibilitas": "sembunyi",
        "tag": "OOP, FUNDAMENTAL",
        "jumlahMateri": 5,
        "durasi": "13:00",
        "progress": 30,
        "isUnlocked": false,
        "terakhirDipelajari": "e37f4adb-de8a-3359-9329-b2293fee8092"
    },
    {
        "id": "0177a269-38a8-3b1f-7cec-a509bd1c8698",
        "nama": "Sub Bab 55",
        "idTopik": "2619ca56-a229-5e88-6a8e-9468495cb046",
        "judulTopik": "Topik 14",
        "idBab": "0f41f3d6-d4b0-6fff-dfdf-19c575d8338c",
        "namaBab": "Bab 28",
        "skalaNilai": 60,
        "visibilitas": "publik",
        "tag": "OOP, STRUCTURE",
        "jumlahMateri": 5,
        "durasi": "13:00",
        "progress": 30,
        "isUnlocked": true,
        "terakhirDipelajari": "b49c370d-8660-1921-fe91-5cda7df6bcb7"
    },
    {
        "id": "7f29ac61-afa2-9e7a-efd4-cfec44804a6d",
        "nama": "Sub Bab 56",
        "idTopik": "2619ca56-a229-5e88-6a8e-9468495cb046",
        "judulTopik": "Topik 14",
        "idBab": "0f41f3d6-d4b0-6fff-dfdf-19c575d8338c",
        "namaBab": "Bab 28",
        "skalaNilai": 29,
        "visibilitas": "sembunyi",
        "tag": "FUNDAMENTAL, STRUCTURE",
        "jumlahMateri": 5,
        "durasi": "13:00",
        "progress": 30,
        "isUnlocked": false,
        "terakhirDipelajari": "4b02abcc-0534-762c-01e9-e8e268259de6"
    },
    {
        "id": "7998043c-2a01-e3e7-1796-2be521084ec8",
        "nama": "Sub Bab 57",
        "idTopik": "7f530556-789c-ee0e-eb56-1d6ed1cc8d3c",
        "judulTopik": "Topik 15",
        "idBab": "b08d1e96-153d-70ba-9a53-0d81fa544ef7",
        "namaBab": "Bab 29",
        "skalaNilai": 76,
        "visibilitas": "publik",
        "tag": "OOP, FUNDAMENTAL",
        "jumlahMateri": 5,
        "durasi": "13:00",
        "progress": 30,
        "isUnlocked": true,
        "terakhirDipelajari": "f28394c5-488d-6354-15a5-38b32954e99d"
    },
    {
        "id": "1858b680-da59-2444-507b-9a64c4732dc8",
        "nama": "Sub Bab 58",
        "idTopik": "7f530556-789c-ee0e-eb56-1d6ed1cc8d3c",
        "judulTopik": "Topik 15",
        "idBab": "b08d1e96-153d-70ba-9a53-0d81fa544ef7",
        "namaBab": "Bab 29",
        "skalaNilai": 21,
        "visibilitas": "sembunyi",
        "tag": "OOP, STRUCTURE",
        "jumlahMateri": 5,
        "durasi": "13:00",
        "progress": 30,
        "isUnlocked": false,
        "terakhirDipelajari": "b94da1f6-4459-92f5-73df-576988822cd4"
    },
    {
        "id": "0ff51574-7d2e-c746-f3ea-c5f675c7c051",
        "nama": "Sub Bab 59",
        "idTopik": "7f530556-789c-ee0e-eb56-1d6ed1cc8d3c",
        "judulTopik": "Topik 15",
        "idBab": "44ef992c-8fc2-216f-a83c-42f7e9b9e702",
        "namaBab": "Bab 30",
        "skalaNilai": 68,
        "visibilitas": "publik",
        "tag": "FUNDAMENTAL, STRUCTURE",
        "jumlahMateri": 5,
        "durasi": "13:00",
        "progress": 30,
        "isUnlocked": true,
        "terakhirDipelajari": "1c76ebf7-40bf-41be-1945-e012070ea19a"
    },
    {
        "id": "0fe66328-5847-9d72-1ecd-f50571b59d27",
        "nama": "Sub Bab 60",
        "idTopik": "7f530556-789c-ee0e-eb56-1d6ed1cc8d3c",
        "judulTopik": "Topik 15",
        "idBab": "44ef992c-8fc2-216f-a83c-42f7e9b9e702",
        "namaBab": "Bab 30",
        "skalaNilai": 64,
        "visibilitas": "sembunyi",
        "tag": "OOP, FUNDAMENTAL",
        "jumlahMateri": 5,
        "durasi": "13:00",
        "progress": 30,
        "isUnlocked": false,
        "terakhirDipelajari": "75aa6c33-e1d1-9607-f772-6d7490c590a4"
    },
    {
        "id": "52ecd7b5-c3ff-5a53-568f-a19334c3f780",
        "nama": "Sub Bab 61",
        "idTopik": "985e3905-5f8d-dcbb-a5d2-5303de1c89de",
        "judulTopik": "Topik 16",
        "idBab": "398c47ca-a8bc-2c16-053c-f8f4bc3c1787",
        "namaBab": "Bab 31",
        "skalaNilai": 81,
        "visibilitas": "publik",
        "tag": "OOP, STRUCTURE",
        "jumlahMateri": 5,
        "durasi": "13:00",
        "progress": 30,
        "isUnlocked": true,
        "terakhirDipelajari": "cb632fe9-d5a2-ef41-91a7-04d474ab9e3b"
    },
    {
        "id": "51c05ea4-6c40-5b2f-2e54-dd2d524153b7",
        "nama": "Sub Bab 62",
        "idTopik": "985e3905-5f8d-dcbb-a5d2-5303de1c89de",
        "judulTopik": "Topik 16",
        "idBab": "398c47ca-a8bc-2c16-053c-f8f4bc3c1787",
        "namaBab": "Bab 31",
        "skalaNilai": 83,
        "visibilitas": "sembunyi",
        "tag": "FUNDAMENTAL, STRUCTURE",
        "jumlahMateri": 5,
        "durasi": "13:00",
        "progress": 30,
        "isUnlocked": false,
        "terakhirDipelajari": "e894bed1-34c5-7131-bdf6-405d343490da"
    },
    {
        "id": "f13151c1-d831-40f0-0ade-3aef8f08228f",
        "nama": "Sub Bab 63",
        "idTopik": "985e3905-5f8d-dcbb-a5d2-5303de1c89de",
        "judulTopik": "Topik 16",
        "idBab": "a2837e0b-493f-7742-a011-399254a18460",
        "namaBab": "Bab 32",
        "skalaNilai": 36,
        "visibilitas": "publik",
        "tag": "OOP, FUNDAMENTAL",
        "jumlahMateri": 5,
        "durasi": "13:00",
        "progress": 30,
        "isUnlocked": true,
        "terakhirDipelajari": "2e0bdaeb-69d5-256f-af3e-c8187c009a25"
    },
    {
        "id": "2f947374-fcc8-18b5-7764-9e136939e34e",
        "nama": "Sub Bab 64",
        "idTopik": "985e3905-5f8d-dcbb-a5d2-5303de1c89de",
        "judulTopik": "Topik 16",
        "idBab": "a2837e0b-493f-7742-a011-399254a18460",
        "namaBab": "Bab 32",
        "skalaNilai": 40,
        "visibilitas": "sembunyi",
        "tag": "OOP, STRUCTURE",
        "jumlahMateri": 5,
        "durasi": "13:00",
        "progress": 30,
        "isUnlocked": false,
        "terakhirDipelajari": "b168ddf6-7961-b7bf-f857-6f0727222e3c"
    }
]
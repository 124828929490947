import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { showErrorAlert } from '../../../app';

// Component
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Container,
    Row,
    Col,
    Image
} from 'react-bootstrap';
import Select from 'react-select';
import Table from './Table';
import { detail_columns } from './detail_columns';

// service
import {
    listRiwayatKuisBab, listRiwayatKuisCekKemampuan,
    listRiwayatLatihanPraktik, listRiwayatKuisPraktik
} from '../../service/LaporanNilaiService';
import { listTopikTrainer } from '../../service/TopikService';

function LaporanNilaiDetail({ idTalentRow, laporanNilaiData, setSearchParams }) {
    const dispatch = useDispatch();
    const profilePhoto = '/img/avatar.png';
    const [idTalent, setIdTalent] = useState(idTalentRow);
    const [namaTalent, setNamaTalent] = useState(laporanNilaiData?.namaTalent);
    const [topikList, setTopikList] = useState([]);


    useEffect(() => {
        setIdTalent(idTalentRow || '');
        setNamaTalent(laporanNilaiData?.namaTalent || '');
    }, [idTalentRow, laporanNilaiData]);

    useEffect(() => {
        listTopikTrainer().then(
            (response) => {
                switch (response.data.status_code) {
                    case 'TPK200':
                        setTopikList(response.data.message || []);
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('Topik error :', error);
            }).finally((info) => {

            });
    }, [dispatch]);

    // Data Tabel
    const [idTopikKuisBab, setIdTopikKuisBab] = useState('');
    const [riwayatKuisBab, setRiwayatKuisBab] = useState({});
    const [idTopikKuisCekKemampuan, setIdTopikKuisCekKemampuan] = useState('');
    const [riwayatKuisCekKemampuan, setriwayatKuisCekKemampuan] = useState({});
    const [idTopikLatihanPraktik, setIdTopikLatihanPraktik] = useState('');
    const [riwayatLatihanPraktik, setRiwayatLatihanPraktik] = useState({});
    const [idTopikKuisPraktik, setIdTopikKuisPraktik] = useState('');
    const [riwayatKuisPraktik, setRiwayatKuisPraktik] = useState({});

    const laporanOptions = [{
        value: "", label: "Semua Topik"
    }]
    laporanOptions.push(...topikList.map(item => ({ value: item.id, label: item.judul })));

    const kuisBabSelected = laporanOptions.find(item => item.value === idTopikKuisBab) || null;
    const kuisCekKemampuanSelected = laporanOptions.find(item => item.value === idTopikKuisCekKemampuan) || null;
    const latihanPraktikSelected = laporanOptions.find(item => item.value === idTopikLatihanPraktik) || null;
    const kuisPraktikSelected = laporanOptions.find(item => item.value === idTopikKuisPraktik) || null;

    const handleSelectKuisBab = (value) => {
        setIdTopikKuisBab(value.value);
    }
    const handleSelectKuisCekKemampuan = (value) => {
        setIdTopikKuisCekKemampuan(value.value);
    }
    const handleSelectLatihanPraktik = (value) => {
        setIdTopikLatihanPraktik(value.value);
    }
    const handleSelectKuisPraktik = (value) => {
        setIdTopikKuisPraktik(value.value);
    }

    useEffect(() => {
        listRiwayatKuisBab({ idTalent, idTopik: idTopikKuisBab }).then(
            (response) => {
                switch (response.data.status_code) {
                    case 'LNI200':
                        setRiwayatKuisBab(response.data.message || {});
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('Riwayat Kuis Bab error :', error);
            }).finally((info) => {

            });
    }, [idTalent, idTopikKuisBab, dispatch]);

    // useEffect(() => {
    //     listRiwayatKuisCekKemampuan({ idTalent, idTopik: idTopikKuisCekKemampuan }).then(
    //         (response) => {
    //             switch (response.data.status_code) {
    //                 case 'LNI200':
    //                     setriwayatKuisCekKemampuan(response.data.message || []);
    //                     break;
    //                 default:
    //                     dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
    //                     break;
    //             }
    //         },
    //         (error) => {
    //             console.log('Riwayat Kuis Cek Kemampuan error :', error);
    //         }).finally((info) => {

    //         });
    // }, [idTalent, idTopikKuisCekKemampuan, dispatch]);

    useEffect(() => {
        listRiwayatLatihanPraktik({ idTalent, idTopik: idTopikLatihanPraktik }).then(
            (response) => {
                switch (response.data.status_code) {
                    case 'LNI200':
                        setRiwayatLatihanPraktik(response.data.message || []);
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('Riwayat Latihan Praktik error :', error);
            }).finally((info) => {

            });
    }, [idTalent, idTopikLatihanPraktik, dispatch]);

    useEffect(() => {
        listRiwayatKuisPraktik({ idTalent, idTopik: idTopikKuisPraktik }).then(
            (response) => {
                switch (response.data.status_code) {
                    case 'LNI200':
                        setRiwayatKuisPraktik(response.data.message || []);
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('Riwayat Kuis Praktik error :', error);
            }).finally((info) => {

            });
    }, [idTalent, idTopikKuisPraktik, dispatch]);

    return (
        <Container className='mt-5'
            style={{ minWidth: '90%', textAlign: 'justify' }}>
            <Row>
                <Col xs="auto">
                    <div className='bg-white p-4 rounded w-100'>
                        <h4>
                            <span onClick={e => setSearchParams()} style={{ cursor: 'pointer' }}>
                                <FontAwesomeIcon icon={faChevronLeft} size='xs' className='me-2' />
                            </span>
                            Info Talent
                        </h4>
                        <div className='d-flex align-items-center mt-4'>
                            <div className='laporan-nilai-pic'>
                                <Image src={profilePhoto} className='nav-icon' />
                            </div>
                            <div className='ms-2'>{namaTalent}</div>
                        </div>
                    </div>
                </Col>
                <Col>
                    <Container fluid className="ms-4">
                        <Row>
                            <Col className='bg-white p-4 px-5 rounded me-5'>
                                <Row>
                                    <Col>
                                        <h4>
                                            Nilai Rata-Rata Latihan Bab
                                        </h4>
                                    </Col>
                                </Row>
                                <Row className='mt-3'>
                                    <Col xs={6}>
                                        <Select
                                            value={kuisBabSelected}
                                            noOptionsMessage={() => 'No options'}
                                            options={laporanOptions}
                                            onChange={handleSelectKuisBab}
                                        />
                                    </Col>
                                </Row>
                                <Row className='mt-3'>
                                    <Col xs={6}>
                                        Nilai Saya
                                        <h2 className={`laporannilai-${riwayatKuisBab.rataRata < riwayatKuisBab.nilai
                                            ? 'green' : riwayatKuisBab.rataRata > riwayatKuisBab.nilai ? 'red' : 'yellow'}`}
                                            style={{ fontWeight: 'bolder' }}>{riwayatKuisBab.nilai}</h2>
                                    </Col>
                                    <Col xs={6}>
                                        Nilai Talent Lain
                                        <h2 style={{ color: '#fbbc04', fontWeight: 'bolder' }}>{riwayatKuisBab.rataRata}</h2>
                                    </Col>
                                </Row>
                                <Row className='mt-3'>
                                    <Col xs={6} className='d-flex align-content-center'>
                                        <div className='laporannilai-circle bg-green'> </div>
                                        Di atas Rata-Rata
                                    </Col>
                                    <Col xs={6} className='d-flex align-content-center'>
                                        <div className='laporannilai-circle bg-yellow'> </div>
                                        Rata-Rata
                                    </Col>
                                    <Col xs={6} className='d-flex align-content-center mt-2'>
                                        <div className='laporannilai-circle bg-red'> </div>
                                        Di bawah Rata-Rata
                                    </Col>
                                    <Col xs={6} className='d-flex align-content-center mt-2'>
                                        <div className='laporannilai-circle bg-gray'> </div>
                                        Belum Dikerjakan
                                    </Col>
                                </Row>
                                <Table title='Riwayat Latihan Bab' columns={detail_columns(riwayatKuisBab.rataRata || {})}
                                    data={riwayatKuisBab.listKuisBab || []} />
                            </Col>
                            <Col className='bg-white p-4 px-5 rounded'>
                                <Row>
                                    <Col>
                                        <h4>
                                            Nilai Latihan Praktik
                                        </h4>
                                    </Col>
                                </Row>
                                <Row className='mt-3'>
                                    <Col xs={6}>
                                        <Select
                                            value={latihanPraktikSelected}
                                            noOptionsMessage={() => 'No options'}
                                            options={laporanOptions}
                                            onChange={handleSelectLatihanPraktik}
                                        />
                                    </Col>
                                </Row>
                                <Row className='mt-3'>
                                    <Col xs={6}>
                                        Nilai Saya
                                        <h2 className={`laporannilai-${riwayatLatihanPraktik.rataRata < riwayatLatihanPraktik.nilai
                                            ? 'green' : riwayatLatihanPraktik.rataRata > riwayatLatihanPraktik.nilai ? 'red' : 'yellow'}`}
                                            style={{ fontWeight: 'bolder' }}>{riwayatLatihanPraktik.nilai}</h2>
                                    </Col>
                                    <Col xs={6}>
                                        Nilai Talent Lain
                                        <h2 style={{ color: '#fbbc04', fontWeight: 'bolder' }}>{riwayatLatihanPraktik.rataRata}</h2>
                                    </Col>
                                </Row>
                                <Row className='mt-3'>
                                    <Col xs={6} className='d-flex align-content-center'>
                                        <div className='laporannilai-circle bg-green'> </div>
                                        Di atas Rata-Rata
                                    </Col>
                                    <Col xs={6} className='d-flex align-content-center'>
                                        <div className='laporannilai-circle bg-yellow'> </div>
                                        Rata-Rata
                                    </Col>
                                    <Col xs={6} className='d-flex align-content-center mt-2'>
                                        <div className='laporannilai-circle bg-red'> </div>
                                        Di bawah Rata-Rata
                                    </Col>
                                    <Col xs={6} className='d-flex align-content-center mt-2'>
                                        <div className='laporannilai-circle bg-gray'> </div>
                                        Belum Dikerjakan
                                    </Col>
                                </Row>
                                <Table title='Riwayat Latihan Praktik' columns={detail_columns(riwayatLatihanPraktik.rataRata || {})}
                                    data={riwayatLatihanPraktik.listLatihanPraktik || []} />
                            </Col>
                        </Row>
                        <Row className='mt-5'>
                            <Col className='bg-white p-4 px-5 rounded me-5'>
                                <Row>
                                    <Col>
                                        <h4>
                                            Nilai Rata-Rata Ujian Praktik
                                        </h4>
                                    </Col>
                                </Row>
                                <Row className='mt-3'>
                                    <Col xs={6}>
                                        <Select
                                            value={kuisPraktikSelected}
                                            noOptionsMessage={() => 'No options'}
                                            options={laporanOptions}
                                            onChange={handleSelectKuisPraktik}
                                        />
                                    </Col>
                                </Row>
                                <Row className='mt-3'>
                                    <Col xs={6}>
                                        Nilai Saya
                                        <h2 className={`laporannilai-${riwayatKuisPraktik.rataRata < riwayatKuisPraktik.nilai
                                            ? 'green' : riwayatKuisPraktik.rataRata > riwayatKuisPraktik.nilai ? 'red' : 'yellow'}`}
                                            style={{ fontWeight: 'bolder' }}>{riwayatKuisPraktik.nilai}</h2>
                                    </Col>
                                    <Col xs={6}>
                                        Nilai Talent Lain
                                        <h2 style={{ color: '#fbbc04', fontWeight: 'bolder' }}>{riwayatKuisPraktik.rataRata}</h2>
                                    </Col>
                                </Row>
                                <Row className='mt-3'>
                                    <Col xs={6} className='d-flex align-content-center'>
                                        <div className='laporannilai-circle bg-green'> </div>
                                        Di atas Rata-Rata
                                    </Col>
                                    <Col xs={6} className='d-flex align-content-center'>
                                        <div className='laporannilai-circle bg-yellow'> </div>
                                        Rata-Rata
                                    </Col>
                                    <Col xs={6} className='d-flex align-content-center mt-2'>
                                        <div className='laporannilai-circle bg-red'> </div>
                                        Di bawah Rata-Rata
                                    </Col>
                                    <Col xs={6} className='d-flex align-content-center mt-2'>
                                        <div className='laporannilai-circle bg-gray'> </div>
                                        Belum Dikerjakan
                                    </Col>
                                </Row>
                                <Table title='Riwayat Ujian Praktik' columns={detail_columns(riwayatKuisPraktik.rataRata || {})}
                                    data={riwayatKuisPraktik.listUjianPraktik || []} />
                            </Col>
                            <Col className='p-4 px-5 rounded'>
                                {/* <Row>
                                    <Col>
                                        <h4>
                                            Nilai Rata-Rata Ujian Topik
                                        </h4>
                                    </Col>
                                </Row>
                                <Row className='mt-3'>
                                    <Col xs={6}>
                                        <Select
                                            value={kuisCekKemampuanSelected}
                                            noOptionsMessage={() => 'No options'}
                                            options={laporanOptions}
                                            onChange={handleSelectKuisCekKemampuan}
                                        />
                                    </Col>
                                </Row>
                                <Row className='mt-3'>
                                    <Col xs={6}>
                                        Nilai Saya
                                        <h2 className={`laporannilai-${riwayatKuisCekKemampuan.rataRata < riwayatKuisCekKemampuan.nilai
                                            ? 'green' : riwayatKuisCekKemampuan.rataRata > riwayatKuisCekKemampuan.nilai ? 'red' : 'yellow'}`}
                                            style={{ fontWeight: 'bolder' }}>{riwayatKuisCekKemampuan.nilai}</h2>
                                    </Col>
                                    <Col xs={6}>
                                        Nilai Talent Lain
                                        <h2 style={{ color: '#fbbc04', fontWeight: 'bolder' }}>{riwayatKuisCekKemampuan.rataRata}</h2>
                                    </Col>
                                </Row>
                                <Row className='mt-3'>
                                    <Col xs={6} className='d-flex align-content-center'>
                                        <div className='laporannilai-circle bg-green'> </div>
                                        Di atas Rata-Rata
                                    </Col>
                                    <Col xs={6} className='d-flex align-content-center'>
                                        <div className='laporannilai-circle bg-yellow'> </div>
                                        Rata-Rata
                                    </Col>
                                    <Col xs={6} className='d-flex align-content-center mt-2'>
                                        <div className='laporannilai-circle bg-red'> </div>
                                        Di bawah Rata-Rata
                                    </Col>
                                    <Col xs={6} className='d-flex align-content-center mt-2'>
                                        <div className='laporannilai-circle bg-gray'> </div>
                                        Belum Dikerjakan
                                    </Col>
                                </Row>
                                <Table title='Riwayat Ujian Topik' columns={detail_columns(riwayatKuisCekKemampuan.rataRata || {})}
                                    data={riwayatKuisCekKemampuan.listCekKemampuan || []} /> */}
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>
        </Container>
    );
}

export default LaporanNilaiDetail;
import React from 'react';
import { Button, Col, Container, Image, ListGroup, ProgressBar, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { GetParentPath } from '../../../common';
import { Timeline } from '../../common/timeline';

export default function NavigationMateri({
    subbab: { id, nama, idBab, progress },
    listMateri, listKuisMateri, subbabItemList,
    activeMateriId, setActiveMateriId,
    activeKuisMateriId, setActiveKuisMateriId,
    activeTabState, setActiveTabState,
}) {
    const subbabJudul = nama;
    const parentPath = GetParentPath();
    const timelineItems = subbabItemList.map((item) => (
        {
            name: <>
                {item.type === 'video' &&
                    <ListGroup.Item className='border-0'>
                        <Row>
                            <Col lg='4'><Image src={item.thumbnail} width='80px' height='45px' /></Col>
                            <Col lg='8' className='d-inline-block my-auto p-2'>{item.judul}</Col>
                        </Row>
                    </ListGroup.Item>}
                {item.type === 'kuis' &&
                    <ListGroup.Item className='border-0'>
                        <Row>
                            <Col lg='4' className='icon-container'><Image src='/img/assignment_green.png' className='mx-auto' /></Col>
                            <Col lg='8' className='d-inline-block my-auto p-2'>{item.namaKuis}</Col>
                        </Row>
                    </ListGroup.Item>}
            </>,
            active: item.active, last: item.current,
            onClick: () => {
                if (item.type === 'kuis') {
                    setActiveKuisMateriId(item.id);
                }
                setActiveMateriId(
                    item.type === 'video' ? item.id : item.idMateri
                );
                setActiveTabState(
                    item.type === 'video' ? 'materi' : 'kuis-materi'
                );
            }
        }
    ));
    const subbabItemListProgress = <Timeline items={timelineItems} isVertical />

    return (
        <Container id="navigation-materi" className="bg-white rounded py-4 px-3 base-container" >
            <Row>
                <Col><h4><b>{subbabJudul}</b></h4></Col>
            </Row>
            <Row>
                <Col lg={10}>Progress
                    <ProgressBar variant="success" now={progress} />
                </Col>
                <Col lg={1} className="ps-0 mt-auto">{progress}%</Col>
            </Row>
            <Row>
                <Col className="d-flex justify-content-center pt-3">
                    <Link to={`${parentPath}/kuis-akhir?idSubbab=${id}`}>
                        <Button className="me-4" variant="success">
                            <Image src="/img/assignment_white.png" /> Kuis Akhir
                        </Button>
                    </Link>
                    <Button variant="primary" onClick={() => setActiveTabState('rangkuman')}>
                        <Image src="/img/assignment_white.png" /> Rangkuman</Button>
                </Col>
            </Row>
            <Row><Col><hr /></Col></Row>
            <Row className="subbab-item-row"
                style={{ maxHeight: '45vh', overflowY: 'auto', overflowX: 'hidden' }}>
                <Col lg='12' className=''>{subbabItemListProgress}</Col>
            </Row>
            <Row><Col><hr /></Col></Row>
            <Row>
                <Col className="d-flex justify-content-center">
                    <Link to={`${parentPath}/subbab?idBab=${idBab}`}>
                        <Button variant="danger">Kembali</Button>
                    </Link>
                </Col>
            </Row>
        </Container>
    )
}

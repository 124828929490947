import axios from "axios";
import {
  talent_register_endpoint,
  talent_verif_endpoint,
  talent_institution_endpoint,
} from "../../app";

export const regisUser = ({
  username,
  email,
  password,
  nama,
  noTelp,
  confirmPassword,
  confirmTermAndCondition,
  tipeLembaga,
  asalLembaga,
  jenisKelamin,
}) => {
  return axios({
    url: talent_register_endpoint,
    method: "POST",
    headers: { "Content-type": "application/json" },
    data: {
      username,
      email,
      password,
      nama,
      noTelp,
      confirmPassword,
      confirmTermAndCondition,
      tipeLembaga,
      asalLembaga,
      jenisKelamin,
    },
  }).then((response) => {
    console.log("Register Result :", response.data);
    return response;
  });
};

export const verifUser = ({ idUser }) => {
  return axios({
    url: talent_verif_endpoint,
    method: "GET",
    headers: { "Content-type": "application/json" },
    params: {
      idUser,
    },
  }).then((response) => {
    console.log("Register Result :", response.data);
    return response;
  });
};

export const getOptLembaga = ({ tipeLembaga, keywordSearch }) => {
  return axios({
    url: talent_institution_endpoint,
    method: "GET",
    headers: { "Content-type": "application/json" },
    params: {
      tipeLembaga,
      keywordSearch,
    },
  }).then((response) => {
    console.log("Register Result :", response.data);
    return response;
  });
};

export const newRegisUser = (body) => {
  return axios({
    url: talent_register_endpoint,
    method: "POST",
    headers: { "Content-type": "application/json" },
    data: body,
  }).then((response) => {
    console.log("Register Result :", response.data);
    return response;
  });
};
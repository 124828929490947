import axios from "axios";
import { talent_cekkemampuan_endpoint, talent_submitcekkemampuan_endpoint } from "../../app";
import { authHeader } from "./AuthHeader";

export const listCekKemampuanTalent = ({ idTopik }) => {
    return axios({
        url: talent_cekkemampuan_endpoint,
        method: 'GET',
        headers: { 'Content-type': 'application/json', ...authHeader() },
        params: { idTopik },
    }).then((response) => {
        console.log('List Cek Kemampuan Result :', response.data);
        return response;
    });
};

export const submitCekKemampuanTalent = ({ idCekKemampuan, durasiPengerjaan, nilai }) => {
    return axios({
        url: talent_submitcekkemampuan_endpoint,
        method: 'POST',
        headers: { 'Content-type': 'application/json', ...authHeader() },
        data: {
            idCekKemampuan, durasiPengerjaan, nilai,
        }
    }).then((response) => {
        console.log('Submit Cek Kemampuan Result :', response.data);
        return response;
    });
};

import React, { useEffect } from 'react';
import { Image } from 'react-bootstrap';
import uuid from 'react-uuid';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function ImageRow({ image, removeImage }) {
    return (
        <>
            {image && image.map((file, index) => {
                return <div key={index} className="d-inline-block image-row vstack">
                    <a className="close-button" onClick={() => removeImage(index)}>
                        <FontAwesomeIcon icon={faCircleXmark} />
                    </a>
                    <Image className="rangkuman-image-border" key={uuid()} src={file.value} />
                    <div className="image-name overflow-hidden">{file.label}</div>
                </div>
            })}
        </>
    )
}

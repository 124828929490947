import { createSlice } from '@reduxjs/toolkit';

const parseJwt = (token) => {
    return JSON.parse(window.atob(token.split('.')[1]));
};

function GetJWT(jwt = localStorage.getItem('json-web-token')) {
    if (!jwt) return null;
    return jwt;
}

function GetAccountID(jwt = localStorage.getItem('json-web-token')) {
    if (!jwt) return null;
    const decodedJwt = parseJwt(jwt);
    return decodedJwt.account_id;
}

const initialState = {
    jsonWebToken: GetJWT(),
    accountId: GetAccountID(),
};

export const AuthSlice = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {
        loginAction: (state, action) => {
            state.jsonWebToken = GetJWT(action.payload);
            state.accountId = GetAccountID(action.payload);
        },
        logoutAction: (state) => {
            state.jsonWebToken = null;
            state.accountId = null;
        },
    },
});

export const { loginAction, logoutAction } = AuthSlice.actions;
export default AuthSlice.reducer;

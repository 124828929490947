import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { showErrorAlert } from '../../../app';
import { Table } from '../../common/table';
import { columns } from './columns';
import LaporanBelajarDetail from './LaporanBelajarDetail';
import { listLaporanBelajarTrainer } from '../../service/LaporanBelajarService';

function LaporanBelajar() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [id, setId] = useState(searchParams.get('id'));

    const dispatch = useDispatch();
    const [data, setData] = useState([]);

    useEffect(() => {
        listLaporanBelajarTrainer().then(
            (response) => {
                switch (response.data.status_code) {
                    case 'LBE200':
                        setData(response.data.message || []);
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('List Laporan Belajar error :', error);
            }).finally((info) => {

            });
    }, [dispatch]);

    useEffect(() => {
        setId(searchParams.get('id'));
    }, [searchParams]);

    const detailFunction = (row) => {
        setId(row.idUser);
        setSearchParams({ id: row.idUser });
    }

    return (
        <>
            {id && <LaporanBelajarDetail id={id} />}
            {!id && <Table
                showToolbar={true}
                showAddButton={false}
                showFilter={false}
                data={data}
                rowClickFunction={detailFunction}
                columns={columns}
                title='Laporan Belajar Digital Bootcamp 79' />
            }
        </>
    );
}

export default LaporanBelajar;
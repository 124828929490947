import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { MdAccessTime, MdPlayCircleOutline, MdAssignment } from 'react-icons/md'
import { useDispatch } from 'react-redux';
import { showErrorAlert } from '../../../app';
import { laporanBelajarSummary } from '../../service/LaporanBelajarService';

export default function LaporanBelajarHeader() {
    const dispatch = useDispatch();
    const [riwayatSummary, setRiwayatSummary] = useState({});

    useEffect(() => {
        laporanBelajarSummary().then(
            (response) => {
                switch (response.data.status_code) {
                    case 'LBE200':
                        setRiwayatSummary(response.data.message || {});
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('Laporan Belajar Summary error :', error);
            }).finally((info) => {

            });
    }, [dispatch]);

    const waktuBelajar = <Container className='bg-white m-0 p-3'>
        <Row>
            <Col className='col-auto d-flex align-items-center'>
                <MdAccessTime color='#FBBC04' style={{ width: '2.5rem', height: '2.5rem' }} />
            </Col>
            <Col className='d-flex flex-column'>
                <div
                    style={{ color: '#0078D7', fontWeight: 500 }}
                >
                    {Math.ceil((riwayatSummary?.jumlahWaktuBelajar || 0) / 60)} Menit
                </div>
                <div className='fsz-0875'
                    style={{ color: '#263238' }}
                >
                    Waktu Belajar
                </div>
            </Col>
        </Row>
    </Container>

    const videoDitonton = <Container className='bg-white m-0 p-3'>
        <Row>
            <Col className='col-auto d-flex align-items-center'>
                <MdPlayCircleOutline color='#EA4335' style={{ width: '2.5rem', height: '2.5rem' }} />
            </Col>
            <Col className='d-flex flex-column'>
                <div
                    style={{ color: '#0078D7', fontWeight: 500 }}
                >
                    {riwayatSummary?.jumlahVideoDitonton} Video
                </div>
                <div className='fsz-0875'
                    style={{ color: '#263238' }}
                >
                    Video Ditonton
                </div>
            </Col>
        </Row>
    </Container>

    const latihanSelesai = <Container className='bg-white m-0 p-3'>
        <Row>
            <Col className='col-auto d-flex align-items-center'>
                <MdAssignment color='#34A853' style={{ width: '2.5rem', height: '2.5rem' }} />
            </Col>
            <Col className='d-flex flex-column'>
                <div
                    style={{ color: '#0078D7', fontWeight: 500 }}
                >
                    {riwayatSummary?.jumlahKuisBabSelesai} Latihan
                </div>
                <div className='fsz-0875'
                    style={{ color: '#263238' }}
                >
                    Latihan Selesai
                </div>
            </Col>
        </Row>
    </Container>

    return (
        <Container fluid
            className='p-0 d-flex gap-5'
        >
            {waktuBelajar}
            {videoDitonton}
            {latihanSelesai}
        </Container>
    );
}

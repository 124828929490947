import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLoading: false,
    showErrorAlert: false,
    errorMessage: '',
    showSuccessAlert: false,
    successMessage: '',
};

export const AppSlice = createSlice({
    name: 'app',
    initialState: initialState,
    reducers: {
        // loading
        startLoading: (state) => {
            state.isLoading = true;
        },
        stopLoading: (state) => {
            state.isLoading = false;
        },
        // error
        showErrorAlert: (state, action) => {
            state.showErrorAlert = true;
            state.errorMessage = action.payload;
        },
        hideErrorAlert: (state) => {
            state.showErrorAlert = false;
        },
        // success
        showSuccessAlert: (state, action) => {
            state.showSuccessAlert = true;
            state.successMessage = action.payload;
        },
        hideSuccessAlert: (state) => {
            state.showSuccessAlert = false;
        },
    },
});

export const {
    startLoading, stopLoading,
    showErrorAlert, hideErrorAlert,
    showSuccessAlert, hideSuccessAlert,
} = AppSlice.actions;
export default AppSlice.reducer;

import React, { useEffect, useState } from 'react';
import { Button, Row, Col, Form, InputGroup, Modal } from 'react-bootstrap';
import moment from 'moment';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { GetParentPath } from '../../../common';
import { editCatatanTalent } from '../../service/CatatanService';
import { hideErrorAlert, hideSuccessAlert, showErrorAlert, showSuccessAlert } from '../../../app';
import { default as CatatanDateGrid } from './CatatanDateGrid';

export default function CatatanPopup({ show, setShow, data, setRefreshData }) {
    const dispatch = useDispatch();
    const [editorState, setEditorState] = useState();
    const [id, setId] = useState();

    useEffect(() => {
        if (show) {
            setId(data?.id);
            setEditorState(
                EditorState.createWithContent(
                    ContentState.createFromBlockArray(
                        htmlToDraft(data?.catatan).contentBlocks
            )));
        } else {
            setId();
            setEditorState(EditorState.createEmpty())
        }
    }, [show, data]);

    const editCatatan = () => {
        var catatan = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        editCatatanTalent({ id, catatan }).then(
            (response) => {
                switch (response.data.status_code) {
                    case 'CTN200':
                        dispatch(showSuccessAlert('Catatan berhasil diubah'));
                        setRefreshData(prev => prev + 1);
                        setShow(prev => !prev);
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('Edit Catatan error :', error);
            }).finally((info) => {

            });
    }

    const handleSubmit = (event) => {
        dispatch(hideErrorAlert());
        dispatch(hideSuccessAlert());
        editCatatan();
    }

    return (
        <Modal id='catatan-popup-modal' show={show} onHide={() => setShow(prev => !prev)} centered size='xl'>
            <Modal.Header className="border-0" closeButton>
                <Modal.Title>Catatan Saya</Modal.Title>
            </Modal.Header>
            <Modal.Body className="border-0">
                <Row>
                    <Col lg={6}>
                        <Form.Group>
                            <Form.Label>Judul Bab</Form.Label>
                            <InputGroup>
                                <Form.Label className='fsz-125'><b>{data.namaBab}</b></Form.Label>
                            </InputGroup>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>Judul Sub-Bab</Form.Label>
                            <InputGroup>
                                <Form.Label className='fsz-125'><b>{data.namaSubbab}</b></Form.Label>
                            </InputGroup>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label>Judul Video</Form.Label>
                            <InputGroup>
                                <Form.Label className='fsz-125'><b>{data.judulMateri}</b></Form.Label>
                            </InputGroup>
                        </Form.Group>
                    </Col>
                </Row>
                    <Editor
                        placeholder='Tambah Catatan...'
                        editorState={editorState}
                        toolbarClassName="catatan-toolbar"
                        wrapperClassName="catatan-wrapper"
                        editorClassName="catatan-editor"
                        onEditorStateChange={setEditorState}
                        hashtag={{}}
                        toolbar={{
                            inline: {
                                bold: { className: 'catatan-option' },
                                italic: { className: 'catatan-option' },
                                underline: { className: 'catatan-option' },
                                strikethrough: { className: 'catatan-option' },
                                monospace: { className: 'catatan-option' },
                                superscript: { className: 'catatan-option' },
                                subscript: { className: 'catatan-option' },
                            },
                            blockType: {
                                className: 'catatan-option-with-dropdown',
                            },
                            fontSize: {
                                className: 'catatan-option-with-dropdown'
                            },
                            list: {
                                unordered: { className: 'catatan-option' },
                                ordered: { className: 'catatan-option' },
                                indent: { className: 'catatan-option' },
                                outdent: { className: 'catatan-option' },
                            },
                            textAlign: {
                                left: { className: 'catatan-option' },
                                center: { className: 'catatan-option' },
                                right: { className: 'catatan-option' },
                                justify: { className: 'catatan-option' },
                            },
                            fontFamily: {
                                className: 'catatan-option-with-dropdown',
                            },
                            colorPicker: {
                                className: 'catatan-option',
                            },
                            link: {
                                link: { className: 'catatan-option' },
                                unlink: { className: 'catatan-option' },
                            },
                            emoji: {
                                className: 'catatan-option',
                            },
                            embedded: {
                                className: 'catatan-option',
                            },
                            image: {
                                className: 'catatan-option',
                                alt: { present: true, mandatory: true }
                            },
                            remove: { className: 'catatan-option' },
                            history: {
                                undo: { className: 'catatan-option' },
                                redo: { className: 'catatan-option' },
                            },
                        }}
                    />
            </Modal.Body>
            <Modal.Footer className="border-0">
                <Button variant='light' onClick={() => setShow(prev => !prev)}
                    style={{ color: '#0078D7', border: '1px solid #0078D7' }}>Batal</Button>
                <Button onClick={(e) => handleSubmit(e)}
                    variant='primary'>Simpan</Button>
            </Modal.Footer>
        </Modal>
    )
}

export const columns = (editFunction, deleteFunction) => ([
    {
        Header: 'Nama Talent',
        accessor: 'namaTalent',
    },
    {
        Header: 'Nilai Rata-Rata Latihan Bab',
        accessor: 'nilaiLatihanBab',
    },
    {
        Header: 'Nilai Latihan Praktik',
        accessor: 'nilaiLatihanPraktik',
    },
    // {
    //     Header: 'Nilai Ujian Topik',
    //     accessor: 'nilaiUjiKemampuan',
    // },
    {
        Header: 'Nilai Ujian Praktik',
        accessor: 'nilaiUjianPraktik',
    },
]);

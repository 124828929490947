import axios from "axios";
import {
  trainer_mastertalent_endpoint,
  talent_institution_endpoint,
  trainer_statusPeserta_endpoint
} from "../../app";
import { authHeader } from "./AuthHeader";

export const getAllMasterTalent = (filter) => {
  return axios({
    url: trainer_mastertalent_endpoint,
    method: "GET",
    headers: { "Content-type": "application/json", ...authHeader() },
    params: filter,
  }).then((response) => {
    return response;
  });
};

export const exportMasterTalent = (filter) => {
  return axios({
    url: trainer_mastertalent_endpoint + "/export",
    method: "GET",
    headers: { "Content-type": "application/json", ...authHeader() },
    params: filter,
    responseType: "blob",
  }).then((response) => {
    return response;
  });
};

export const getOptLembaga = ({ tipeLembaga, keywordSearch }) => {
  return axios({
    url: talent_institution_endpoint,
    method: "GET",
    headers: { "Content-type": "application/json" },
    params: {
      tipeLembaga,
      keywordSearch,
    },
  }).then((response) => {
    console.log("Register Result :", response.data);
    return response;
  });
};

export const getOptStatusPeserta = () => {
  return axios({
    url: trainer_statusPeserta_endpoint,
    method: "GET",
    headers: { "Content-type": "application/json", ...authHeader() },
  }).then((response) => {
    console.log("Status Peserta Result :", response.data);
    return response;
  });
}

export const editMasterTalent = (body) => {
  return axios({
    url: trainer_mastertalent_endpoint,
    method: "PUT",
    headers: { "Content-type": "application/json", ...authHeader() },
    data: body,
  }).then((response) => {
    console.log("Edit Master Talent Result :", response.data);
    return response;
  });
};

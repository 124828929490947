import axios from 'axios';
import { base_url, port_auth, talent_auth_endpoint, talent_auth_google_endpoint } from '../../app';

export const loginUser = (username, password) => {
    return axios({
        url: talent_auth_endpoint,
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
        auth: { username: username, password: password },
    }).then((response) => {
        console.log('Auth Result :', response.data);
        if (response.data.message.jwt) {
            localStorage.setItem('json-web-token', response.data.message.jwt);
        }
        return response;
    });
};

export const loginUserGoogle = (idToken) => {
    return axios({
        url: talent_auth_google_endpoint,
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
        data: { idToken },
    }).then((response) => {
        console.log('Auth Google Result :', response.data);
        if (response.data.message.jwt) {
            localStorage.setItem('json-web-token', response.data.message.jwt);
        }
        return response;
    });
};

export const logoutUser = () => {
    return new Promise((resolve, reject) => {
        localStorage.removeItem('json-web-token');
        resolve('success');
    });
};


import axios from "axios";
import { base_url, port_learning, trainer_tag_endpoint } from "../../app";
import { authHeader } from "./AuthHeader";

export const listTagTrainer = () => {
    return axios({
        url: trainer_tag_endpoint,
        method: 'GET',
        headers: {'Content-type': 'application/json', ...authHeader()},
    }).then((response) => {
        console.log('List Tag Result :', response.data);
        return response;
    });
};

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { hideErrorAlert, showErrorAlert, showSuccessAlert } from '../../../app';
import { GetParentPath } from '../../../common';
import { verifUser } from '../../service/RegisterService';

function VerifikasiPage() {
    const dispatch = useDispatch();
    const parentPath = GetParentPath();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [idUser, setIdSubbab] = useState(searchParams.get('idUser') || '');
    if(idUser === ''){
        navigate(`${parentPath}/login`);
    }
    verifUser({idUser}).then(
        (response) => {
            switch (response.data.status_code) {
                case 'TAL200':
                    dispatch(showSuccessAlert("Berhasil verifikasi"));
                    navigate(`${parentPath}/login`);
                    break;
                default:
                    dispatch(showErrorAlert('Gagal verifikasi!'));
                    navigate(`${parentPath}/login`);
                    break;
            }
        },
        (error) => {
            console.log('Verifkasi error :', error);
        }).finally((info) => {

        });
    return '';
}
export default VerifikasiPage;
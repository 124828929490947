import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Container, Image, ListGroup, Row, Tab, Tabs } from 'react-bootstrap';
import { faThumbsUp, faThumbsDown, faMessage } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactPlayer from 'react-player';
import Catatan from './Catatan';
import { showErrorAlert, showSuccessAlert } from '../../../app';
import { useDispatch } from 'react-redux';
import { listTimelineService } from '../../service/TimelineService';
import { submitProgressMateriTalent, updateProgressMateriTalent } from '../../service/MateriService';
import { getBabTalent } from '../../service/BabService';
import { getTopikTalent } from '../../service/TopikService';
import Komentar from './Komentar';
import { default as ScreenshotPopup } from './ScreenshotPopup';
import captureVideoFrame from 'capture-video-frame';

export default function VideoPlayer({
    materi: { thumbnail, video, deskripsi, id, idBab, idSubbab, idTopik, durasi, judul },
    subbabData, setRefreshData, subbabItemList = [], activeItemIndex,
    setActiveMateriId, setActiveKuisMateriId, setActiveTabState,
}) {
    const dispatch = useDispatch();
    const [screenshotData, setScreenshotData] = useState();
    const [showScreenshotPopup, setShowScreenshotPopup] = useState(false);
    const tesref = useRef(null);
    const videoPlayerRef = useRef(null);
    const videoUrl = video;
    const videoDeskripsi = deskripsi;
    const [namaBab, setNamaBab] = useState();
    const [namaTopik, setNamaTopik] = useState();
    const [videoCurrTime, setVideoCurrTime] = useState(0);
    const [playing, setPlaying] = useState(true);

    useEffect(() => {
        setPlaying(true);
        setScreenshotData({
            judulVideoMateri: judul,
            namaSubbab: subbabData.nama,
            idSubbab: subbabData.id,
            idVideoScreenshot: id
        })
        getBabTalent({ id: idBab }).then(
            (response) => {
                switch (response.data.status_code) {
                    case 'BAB200':
                        setNamaBab(response.data.message.nama);
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('Get Nama Bab error :', error);
            }).finally((info) => {

            });
        getTopikTalent({ id: idTopik }).then(
            (response) => {
                switch (response.data.status_code) {
                    case 'TPK200':
                        setNamaTopik(response.data.message.judul);
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('Get Nama Topik error :', error);
            })
    }, [id]);

    const [videoTimeline, setVideoTimeline] = useState([]);
    const [showKomentar, setShowKomentar] = useState(false);
    const [videoTimelineChecked, setVideoTimelineChecked] = useState([]);

    const timelineItem = videoTimelineChecked.map((timeline, index) => (
        <ListGroup.Item className="border-0 p-0" active={timeline.active}
            key={index} onClick={() => handleTimelineClick(timeline.timestamp)}
        >
            <Row className="p-2">
                <Col lg='auto'><Image src={timeline.thumbnail} width='80px' height='45px' /></Col>
                <Col>
                    <Row><Col style={timeline.active ? { color: 'white' } : { color: 'black' }}>{timeline.judul}</Col></Row>
                    <Row><Col style={timeline.active ? { color: 'white' } : { color: 'blue' }}>{new Date(timeline.timestamp * 1000).toISOString().substr(14, 5)}</Col></Row>
                </Col>
            </Row>
        </ListGroup.Item>
    ));

    useEffect(() => {
        listTimelineService({ idMateri: id }).then(
            (response) => {
                switch (response.data.status_code) {
                    case 'TML200':
                        var videoTimelineTemp = (response.data.message ? response.data.message : []);
                        videoTimelineTemp = videoTimelineTemp.map(item => ({
                            ...item, thumbnail: thumbnail, active: false
                        }));
                        setVideoTimeline(videoTimelineTemp.slice());
                        setVideoTimelineChecked(videoTimelineTemp.slice());
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('Timeline error :', error);
            }).finally((info) => {

            });
    }, [dispatch, id, thumbnail])

    useEffect(() => {
        if (!id) return;
        updateProgressMateriTalent({ id: id, timestamp: durasi }).then(
            (response) => {
                switch (response.data.status_code) {
                    case 'RWT200':
                        console.log('Success update terakir dipelajari!');
                        break;
                    default:
                        console.log('Terjadi kesalahan pada server update!');
                        break;
                }
            },
            (error) => {
                console.log('Update terakhir dipelajari error :', error);
            }).finally((info) => {

            });
    }, [durasi, id]);

    function updateTerakhirDipelajari(durasi) {
        if (!id) return;
        updateProgressMateriTalent({ id: id, timestamp: durasi }).then(
            (response) => {
                switch (response.data.status_code) {
                    case 'RWT200':
                        console.log('Success update terakir dipelajari!');
                        break;
                    default:
                        console.log('Terjadi kesalahan pada server update!');
                        break;
                }
            },
            (error) => {
                console.log('Update terakhir dipelajari error :', error);
            }).finally((info) => {

            });
    }

    function handleScreenshot() {
        var ssimage;
        try {
            ssimage = captureVideoFrame(videoPlayerRef.current.getInternalPlayer(), 'jpeg', 1);
        } catch (e) {
            console.log(e);
            dispatch(showErrorAlert('Gagal mengambil screenshot video ini!'));
            return;
        }
        setPlaying(false);
        setScreenshotData({
            ...screenshotData,
            namaBab: namaBab,
            namaTopik: namaTopik,
            gambarScreenshot: ssimage,
            timestampScreenshot: `${new Date(Math.floor(videoCurrTime) * 1000).toISOString().substr(14, 5)}/${new Date(durasi * 1000).toISOString().substr(14, 5)}`,
        })
        setShowScreenshotPopup(prev => !prev);
    }

    function handleTimelineClick(seconds) {
        videoPlayerRef.current.seekTo(seconds);
    }

    function handleProgress(value) {
        setVideoCurrTime(value.playedSeconds);
        if (Math.ceil(value.playedSeconds) % 10 === 0) {
            updateTerakhirDipelajari(Math.ceil(value.playedSeconds));
        }
        setVideoTimelineChecked(prev => {
            var newTimeline = videoTimeline.slice().reverse().map(item => ({ ...item, active: false }));
            var findindex = newTimeline.findIndex(timeline => timeline.timestamp <= value.playedSeconds);
            if (findindex >= 0) {
                newTimeline[findindex].active = true;
                return newTimeline.reverse();
            } else {
                return prev;
            }
        });
    }

    function handleVideoEnd() {
        updateTerakhirDipelajari(durasi);
        submitProgressMateriTalent({ idMateri: id }).then(
            (response) => {
                switch (response.data.status_code) {
                    case 'MAT200':
                        dispatch(showSuccessAlert('Selesai menonton video!'));
                        setRefreshData(prev => prev + 1);
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('Submit video materi error :', error);
            }).finally((info) => {

            });
    }

    const hidePrevButton = (activeItemIndex === 0);
    const hideNextButton = (activeItemIndex === subbabItemList.length - 1)
    const disableNextButton =
        (activeItemIndex < subbabItemList.length - 1) && !subbabItemList[activeItemIndex + 1]?.active;

    function handlePrev(event) {
        const item = subbabItemList[activeItemIndex - 1];
        if (item.type === 'kuis') {
            setActiveKuisMateriId(item.id);
        }
        setActiveMateriId(
            item.type === 'video' ? item.id : item.idMateri
        );
        setActiveTabState(
            item.type === 'video' ? 'materi' : 'kuis-materi'
        );
    }

    function handleNext(event) {
        const item = subbabItemList[activeItemIndex + 1];
        if (item.type === 'kuis') {
            setActiveKuisMateriId(item.id);
        }
        setActiveMateriId(
            item.type === 'video' ? item.id : item.idMateri
        );
        setActiveTabState(
            item.type === 'video' ? 'materi' : 'kuis-materi'
        );
    }

    return (
        <>
            {screenshotData && <ScreenshotPopup show={showScreenshotPopup} setShow={setShowScreenshotPopup} data={screenshotData} />}
            <Container ref={tesref} id="video-container" className="bg-white p-4">
                <Row>
                    <Col>
                        <ReactPlayer url={videoUrl} ref={videoPlayerRef} playing={playing} controls
                            onEnded={handleVideoEnd}
                            onProgress={handleProgress}
                            width='762px' height='429px'
                            config={{
                                file: {
                                    attributes: {
                                        crossOrigin: 'true'
                                    }
                                }
                            }}
                            onPlay={() => setPlaying(true)}
                            onPause={() => setPlaying(false)}
                        />
                    </Col>
                    <Col className="d-flex align-items-stretch">
                        <div className="border video-timeline ms-auto overflow-auto">
                            <Row><Col className="video-timeline-header">Timeline</Col></Row>
                            <Row>
                                <Col className="p-0">
                                    <ListGroup>
                                        {timelineItem}
                                    </ListGroup>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col className="border col-deskripsi-catatan mt-4 mx-2 p-0">
                        <Tabs defaultActiveKey="deskripsi">
                            <Tab eventKey="deskripsi" title="Deskripsi">
                                {videoDeskripsi}
                            </Tab>
                            <Tab eventKey="catatan" title="Catatan">
                                <Catatan idMateri={id} idBab={idBab} idSubbab={idSubbab} idTopik={idTopik} />
                            </Tab>
                        </Tabs>
                    </Col>
                </Row>
                <Row>
                    <Col className="d-flex justify-content-start">
                        <Button variant="outline-danger"
                            onClick={handlePrev}
                            hidden={hidePrevButton}
                        >
                            Sebelumnya
                        </Button>
                    </Col>
                    <Col className="d-flex justify-content-center">
                        <Button variant='light'
                            className="bg-transparent border-0"
                        >
                            <FontAwesomeIcon icon={faThumbsUp} color="grey" size="lg" />
                        </Button>
                        <Button variant='light'
                            className="bg-transparent border-0"
                        >
                            <FontAwesomeIcon icon={faThumbsDown} color="grey" size="lg" />
                        </Button>
                        <Button variant='light'
                            className="bg-transparent border-0"
                            onClick={() => handleScreenshot()}
                        >
                            <Image src="/img/aperture.png" width="24px" />
                        </Button>
                        <Button variant='light'
                            className="bg-transparent border-0"
                            onClick={() => setShowKomentar(true)}
                        >
                            <FontAwesomeIcon icon={faMessage} color="grey" size="lg" />
                        </Button>
                    </Col>
                    <Col className="d-flex justify-content-end">
                        <Button variant="primary"
                            onClick={handleNext}
                            hidden={hideNextButton}
                            disabled={disableNextButton}
                        >
                            Berikutnya
                        </Button>
                    </Col>
                </Row>
                <Komentar show={showKomentar} setShow={setShowKomentar} idMateri={id} />
            </Container>
        </>
    )
}

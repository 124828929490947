import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { week, month, year } from "./dummy";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  TimeScale
);

export default function BarChart({ filter, datas }) {
  const countMax = datas?.map((d) => d.total);
  const dataMax = Math.max(...countMax);
  const dispatch = useDispatch();
  const initialData = {
    labels: [],
    datasets: [
      {
        label: "",
        data: [],
        backgroundColor: "#16C098",
        minBarLength: 5,
      },
    ],
  };
  const [data, setData] = useState(initialData);

  const options = {
    responsive: true,
    // aspectRatio: 3,
    plugins: {
      legend: {
        display: false,
        position: "top",
      },
      title: {
        display: false,
        text: "Dashboard",
      },
      tooltip: {
        enabled: true,
        displayColors: false,
        callbacks: {
          title: () => null,
          beforeLabel: (res) => {
            return "Total Join : " + data?.datas[res.dataIndex].total;
          },
          label: (res) => {
            return "Univ/Ins : " + data?.datas[res.dataIndex].univ;
          },
          afterLabel: (res) => {
            return "Sekolah : " + data?.datas[res.dataIndex].sekolah;
          },
        },
      },
    },
    scales: {
      x: {
        beginAtZero: false,
        grid: {
          display: false,
        },
      },
      y: {
        border: { dash: [6, 4] }, // for the grid lines
        grid: {
          color: "#aaa", // for the grid lines
          tickBorderDash: [6, 4], // also for the tick, if long enough
        },
        ticks: {
          stepSize: dataMax < 100 ? 10 : 25,
        },
        min: 0,
        max: dataMax < 11 ? 10 : dataMax,
      },
    },
    layout: {
      padding: {
        top: 20,
        bottom: 30,
        right: 10,
      },
    },
  };

  useEffect(() => {
    let arr = [];
    if (filter === "week") {
      arr = week;
    } else if (filter === "month") {
      arr = month;
    } else if (filter === "year") {
      arr = year;
    }
    setData({
      labels: datas?.map((d) =>
        filter !== "month"
          ? d.label.replace("[not implemented] ", "").substring(0, 3)
          : d.label
      ),
      datasets: [
        {
          label: "",
          data: datas?.map((d) => d.total),
          backgroundColor: "#16C098",
          borderRadius: 5,
        },
      ],
      datas: datas,
    });
  }, [filter, datas]);

  return <Bar options={options} data={data} />;
}

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { showErrorAlert } from '../../../app';
import { Col, Container, Row } from 'react-bootstrap';
import { Header } from '../../common/header';
import { getProfile } from '../../service/ProfileService';
import { NavigationMateri } from '../navigation-materi';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getSubbabTalent } from '../../service/SubbabService';
import { updateProgressMateriTalent, getMateriTalent, listMateriTalent } from '../../service/MateriService';
import { VideoPlayer } from '../video-player';
import { Rangkuman } from '../rangkuman';
import { GetParentPath } from '../../../common';
import { listKuisMateriTalent } from '../../service/KuisMateriService';
import { KuisMateri } from '../kuis-materi';

function HomeMateri({ children, activeTab = 'materi' }) {
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const [idSubbab, setIdSubbab] = useState(searchParams.get('idSubbab') || '');
    const [subbab, setSubbab] = useState({});
    const [listMateri, setListMateri] = useState([]);
    const [listKuisMateri, setListKuisMateri] = useState([]);

    const parentPath = GetParentPath();
    const navigate = useNavigate();
    const [activeMateriId, setActiveMateriId] = useState(searchParams.get('idMateri') || '');
    const [activeKuisMateriId, setActiveKuisMateriId] = useState('');
    const [activeMateri, setActiveMateri] = useState('');
    const [activeTabState, setActiveTabState] = useState(activeTab);
    const [activeItemIndex, setActiveItemIndex] = useState(0);

    const [refreshData, setRefreshData] = useState(1);
    const [profileData, setProfileData] = useState({});

    const subbabItemList = listMateri.flatMap((materi, index) => {
        let items = []
        items.push({
            id: materi.id,
            type: 'video',
            current: activeTabState === 'materi' && materi.id === activeMateriId,
            active: materi.isUnlocked,
            thumbnail: materi.thumbnail,
            judul: materi.judul
        });
        let kuisMateri = listKuisMateri.find(item => item.idMateri === materi.id);
        if (kuisMateri)
            items.push({
                id: kuisMateri.id,
                idMateri: kuisMateri.idMateri,
                type: 'kuis',
                current: activeTabState === 'kuis-materi' && kuisMateri.idMateri === activeMateriId,
                active: kuisMateri.isUnlocked,
                namaKuis: kuisMateri.judul
            });
        return items;
    });

    useEffect(() => {
        for (let index = 0; index < subbabItemList.length; index++) {
            if (subbabItemList[index].current) {
                setActiveItemIndex(index);
            }
        }
    }, [subbabItemList]);

    useEffect(() => {
        if (!idSubbab) navigate(`${parentPath}/topik`)
    }, [parentPath, searchParams, navigate, idSubbab]);

    useEffect(() => {
        getProfile().then(
            (response) => {
                switch (response.data.status_code) {
                    case 'TAL200':
                        setProfileData(response.data.message || {});
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('Get Profile error :', error);
            })
    }, [dispatch, refreshData]);

    useEffect(() => {
        getSubbabTalent({ id: idSubbab }).then(
            (response) => {
                switch (response.data.status_code) {
                    case 'SBB200':
                        setSubbab(response.data.message || {});
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('Get Subbab error :', error);
            })
    }, [idSubbab, dispatch, refreshData]);

    useEffect(() => {
        if (!activeMateriId) {
            if (subbab.terakhirDipelajari)
                setActiveMateriId(subbab.terakhirDipelajari);
            else
                setActiveMateriId(listMateri.length > 0 ? listMateri[0].id : '');
        }
    }, [subbab, dispatch, activeTab, activeMateriId, listMateri]);

    useEffect(() => {
        if (!activeKuisMateriId && listKuisMateri.length > 0) {
            for (let kuisMateriIndex in listKuisMateri) {
                if (listKuisMateri[kuisMateriIndex].idMateri === activeMateriId) {
                    setActiveKuisMateriId(listKuisMateri[kuisMateriIndex].id);
                    return;
                }
            }
        }
    }, [listKuisMateri, activeMateriId, listMateri, activeKuisMateriId]);

    useEffect(() => {
        listMateriTalent({ idSubbab }).then(
            (response) => {
                switch (response.data.status_code) {
                    case 'MAT200':
                        setListMateri(response.data.message || []);
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('List Materi error :', error);
            })
    }, [idSubbab, refreshData, dispatch]);

    useEffect(() => {
        if (!idSubbab) return;
        listKuisMateriTalent({ idSubbab }).then(
            (response) => {
                switch (response.data.status_code) {
                    case 'KMA200':
                        setListKuisMateri(response.data.message || []);
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('List Kuis Materi error :', error);
            })
    }, [idSubbab, refreshData, dispatch]);

    useEffect(() => {
        if (activeTabState === 'materi')
            navigate(`${parentPath}/materi?idSubbab=${idSubbab}&idMateri=${activeMateriId}`);
        else if (activeTabState === 'rangkuman')
            navigate(`${parentPath}/rangkuman?idSubbab=${idSubbab}`);
        else if (activeTabState === 'kuis-materi')
            navigate(`${parentPath}/kuis-materi?idSubbab=${idSubbab}&idMateri=${activeMateriId}`);
    }, [activeMateriId, activeTabState, idSubbab, navigate, parentPath]);

    useEffect(() => {
        if (activeTab !== 'materi') return;
        if (!activeMateriId) return;
        getMateriTalent({ id: activeMateriId }).then(
            (response) => {
                switch (response.data.status_code) {
                    case 'MAT200':
                        setActiveMateri(response.data.message || {});
                        break;
                    case 'MAT406':
                        setActiveMateriId(listMateri.length > 0 ? listMateri[0].id : '');
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('Get Materi error :', error);
            })
    }, [activeMateriId, activeTab, dispatch, listMateri]);

    return (
        <Container id="home-materi" fluid className='vh-100 m-0'>
            <Row>
                <Col lg={12} className='header-talent p-0 position-fixed'
                    style={{ zIndex: 2 }}
                >
                    <Header profileData={profileData} setRefreshData={setRefreshData} />
                </Col>
            </Row>
            <Row className='below-header-talent'>
                <Col lg={3} className='position-fixed navigation-col'>
                    <NavigationMateri subbab={subbab} subbabItemList={subbabItemList}
                        activeKuisMateriId={activeKuisMateriId} setActiveKuisMateriId={setActiveKuisMateriId}
                        listMateri={listMateri} listKuisMateri={listKuisMateri}
                        activeTabState={activeTabState} setActiveTabState={setActiveTabState}
                        activeMateriId={activeMateriId} setActiveMateriId={setActiveMateriId}
                    />
                </Col>
                <Col lg={{ span: 9, offset: 3 }} className='px-0 pb-5 children-col'>
                    {(activeTab === 'materi') && activeMateri && <VideoPlayer
                        subbabData={subbab}
                        materi={activeMateri}
                        setRefreshData={setRefreshData}
                        setActiveTabState={setActiveTabState}
                        setActiveMateriId={setActiveMateriId}
                        setActiveKuisMateriId={setActiveKuisMateriId}
                        subbabItemList={subbabItemList}
                        activeItemIndex={activeItemIndex}
                    />}
                    {(activeTab === 'rangkuman') && <Rangkuman />}
                    {(activeTab === 'kuis-materi') && <KuisMateri
                        idKuisMateri={activeKuisMateriId}
                        setRefreshData={setRefreshData}
                        activeItemIndex={activeItemIndex}
                        subbabItemList={subbabItemList}
                        setActiveTabState={setActiveTabState}
                        setActiveMateriId={setActiveMateriId}
                        setActiveKuisMateriId={setActiveKuisMateriId}
                    />}
                </Col>
            </Row>
        </Container>
    );
}

export default HomeMateri;

import axios from "axios";
import { base_url, port_learning, trainer_bab_endpoint } from "../../app";
import { authHeader } from "./AuthHeader";

export const listBabTrainer = () => {
    return axios({
        url: trainer_bab_endpoint,
        method: 'GET',
        headers: {'Content-type': 'application/json', ...authHeader()},
    }).then((response) => {
        console.log('List Bab Result :', response.data);
        return response;
    });
};

export const addBabTrainer = ({ idTopik, nama, deskripsi, tag, skalaNilai, visibilitas }) => {
    return axios({
        url: trainer_bab_endpoint,
        method: 'POST',
        headers: { 'Content-type': 'application/json', ...authHeader() },
        data: {
            idTopik, nama, deskripsi, tag, skalaNilai, visibilitas
        },
    }).then((response) => {
        console.log('Add Bab Result :', response.data);
        return response;
    });
};

export const editBabTrainer = ({ id, idTopik, nama, deskripsi, tag, skalaNilai, visibilitas }) => {
    return axios({
        url: trainer_bab_endpoint,
        method: 'PUT',
        headers: { 'Content-type': 'application/json', ...authHeader() },
        data: {
            id, idTopik, nama, deskripsi, tag, skalaNilai, visibilitas
        },
    }).then((response) => {
        console.log('Edit Bab Result :', response.data);
        return response;
    });
};

export const deleteBabTrainer = ({ id }) => {

    return axios({
        url: trainer_bab_endpoint,
        method: 'DELETE',
        headers: {
            'Content-type': 'application/json',
            ...authHeader(),
        },
        data: {
            id,
        },
    }).then((response) => {
        console.log('Delete Bab Result :', response.data);
        return response;
    });
};

import {
    Button, ProgressBar
} from "react-bootstrap";

function secondToTime(t) {
    var hour = ('0' + Math.floor(t / 3600) % 24).slice(-2);
    var minutes = ('0' + Math.floor(t / 60) % 60).slice(-2);
    var second = ('0' + t % 60).slice(-2);

    return `${hour === '00' ? '' : `${hour}:`}${minutes}:${second}`;
}

export const columns = ({ lihatFunction }) => ([
    {
        Header: 'Judul',
        accessor: 'nama',
    },
    {
        Header: 'Materi Video',
        accessor: 'jumlahMateri',
        Cell: ({ cell: { value } }) => (`${(value || value === 0) ? value : "-"} Video`)
    },
    {
        Header: 'Durasi',
        accessor: 'durasi',
        Cell: ({ cell: { value } }) => (`${(value || value === 0) ? secondToTime(Math.ceil(value)) : '-:-'}`)
    },
    {
        Header: 'Progress',
        accessor: 'progress',
        Cell: ({ cell: { value } }) => {
            return <>
                <div className='w-75 d-inline-block me-2'>
                    <ProgressBar className={value === 100 ? 'progress-full' : 'progress-basic'} now={value} /></div>
                <span>{value}%</span>
            </>;
        }
    },
    {
        Header: ' ',
        disableSortBy: true,
        Cell: (row) => {
            return (
                <>
                    <Button variant="primary"
                        onClick={(e) => lihatFunction(e, row.row.original.id)}
                        disabled={!row.row.original.isUnlocked}
                    >
                        Lihat
                    </Button>
                </>
            );
        }
    },
]);

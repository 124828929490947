import React, { useEffect, useState } from 'react';
import { Table } from '../../common/table';
import { columns } from './columns';
import { useDispatch } from 'react-redux';
import { listAkunTrainer } from '../../service/KelolaAkunService';
import { showErrorAlert } from '../../../app';
import { default as PopupDelete } from './PopupDelete';
import { default as PopupDetil } from './PopupDetil';
import { default as PopupTambahEdit } from './PopupTambahEdit';

function KelolaAkun() {
    const [state, setState] = useState([]);
    const [showPopUp, setShowPopUp] = useState(false);
    const [showPopUpDelete, setShowPopUpDelete] = useState(false);
    const [showPopUpDetail, setShowPopUpDetail] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [akunData, setAkunData] = useState();
    const [refreshData, setRefreshData] = useState(1);

    const dispatch = useDispatch();

    useEffect(() => {
        const listAkunHit = () => {
            listAkunTrainer().then(
                (response) => {
                    switch (response.data.status_code) {
                        case 'AKU200':
                            setState(response.data.message || []);
                            break;
                        default:
                            dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                            break;
                    }
                },
                (error) => {
                    console.log('List Akun error :', error);
                })
        }
        listAkunHit();
    }, [dispatch, refreshData]);

    const addFunction = () => {
        setAkunData();
        setIsEdit(false);
        setShowPopUp(prev => !prev);
    }

    const editFunction = (row) => {
        setAkunData(row);
        setIsEdit(true);
        setShowPopUp(prev => !prev);
    }

    const deleteFunction = (row) => {
        setAkunData(row);
        setShowPopUpDelete(prev => !prev);
    }

    const detailFunction = (row) => {
        setAkunData(row);
        setShowPopUpDetail(prev => !prev);
    }

    return (
        <>
            <PopupTambahEdit
                setRefreshData={setRefreshData}
                show={showPopUp}
                setShow={setShowPopUp}
                akunData={akunData} 
                isEdit={isEdit} />
            <PopupDetil
                show={showPopUpDetail}
                setShow={setShowPopUpDetail}
                akunData={akunData}
                />
            <PopupDelete
                setRefreshData={setRefreshData}
                show={showPopUpDelete}
                setShow={setShowPopUpDelete}
                akunData={akunData}
            />
            <Table
                data={state}
                rowClickFunction={detailFunction}
                columns={columns(editFunction, deleteFunction)}
                title='Akun User Digital Bootcamp 79'
                addFunction={addFunction} />
        </>
    );
}

export default KelolaAkun;
import React, { forwardRef } from 'react';
import { Col, Image, Row } from 'react-bootstrap';

function KomentarBubbleRight(props, ref) {
    const { author, komentar } = props;
    const { idAuthor, name, level, isTalent, isAuthor } = author;
    const { idKomentar, datetime, message, replyTo } = komentar;

    const datetimeText = <Col className='col-auto ms-auto d-flex align-items-center'>
        {datetime}
    </Col>

    const messageText = <Col className='col-auto px-3' >
        {message}
    </Col>

    return (
        <Row className='mb-2' ref={ref}
            style={{ color: '#FFFFFF' }}
        >
            <Col className='col-auto ms-auto'>
                <Row>
                    <Col className='col-auto invisible'>
                        <Image src='/img/komentar_photo.png' style={{ width: '3rem', height: '3rem' }} />
                    </Col>
                    <Col className='fsz-075 border py-2'
                        style={{ backgroundColor: '#0078D7', borderRadius: '8px', border: '1px solid #979797' }}
                    >
                        <Row className='mb-1'>
                            {datetimeText}
                        </Row>
                        <Row className='fw-light'>
                            {messageText}
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}

export default forwardRef(KomentarBubbleRight);

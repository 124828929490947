import React from "react";

export const columns = (isMasterChecked, handleMasterCheckboxChange, handleRowCheckboxChange, selectedRows, editFunction, deleteFunction) => [
  {
    Header: () => (
      <input
          type="checkbox"
          checked={isMasterChecked}
          onChange={handleMasterCheckboxChange}
      />
    ),
    width: 20,
    accessor: "check",
    disableSortBy: true,
    Cell: ({ row }) => (
      <input
          key={row.id}
          type="checkbox"
          checked={selectedRows.some((r) => r.id === row.original.id)}
          onChange={() => handleRowCheckboxChange(row.original)}
      />
    ),
  },
  {
    Header: "Name",
    accessor: "nama",
  },
  {
    Header: "Lembaga",
    accessor: "nama_lembaga",
  },
  {
    Header: "Tipe Lembaga",
    accessor: "nama_tipe_lembaga",
  },
  {
    Header: "Email",
    accessor: "email",
  },
  {
    Header: "Nomor Telepon",
    accessor: "noWa",
  },
  {
    Header: "Masuk Kelas Arutala",
    accessor: "siap_masuk_bulan",
  },
  {
    Header: "Fundamental Java",
    accessor: "fundamental_java_score",
  },
  {
    Header: "OOP",
    accessor: "oop_score",
  },
  {
    Header: "SQL",
    accessor: "sql_score",
  },
  {
    Header: "Total",
    accessor: "total_score",
  },
];
export const kuismateri_list = [
    {
        "id": "kuism_1",
        "judul": "Kuis Materi 1",
        "idTopik": "topik_1",
        "judulTopik": "Topik 1",
        "idBab": "bab_1",
        "namaBab": "Bab 1",
        "idSubbab": "eba8386d-6fa6-a075-3478-24721127e9a9",
        "namaSubbab": "Sub Bab 1",
        "idMateri": "64e0fdcb-bd8c-471f-b3bc-92a87ff87fb3",
        "judulMateri": "Video Materi 1",
        "visibilitas": "publik",
        "thumbnail": 'https://images.unsplash.com/photo-1596496181871-9681eacf9764?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=886&q=80',
    },
    {
        "id": "kuism_2",
        "judul": "Kuis Materi 2",
        "idTopik": "topik_1",
        "judulTopik": "Topik 1",
        "idBab": "bab_1",
        "namaBab": "Bab 1",
        "idSubbab": "eba8386d-6fa6-a075-3478-24721127e9a9",
        "namaSubbab": "Sub Bab 1",
        "idMateri": "fa0bf581-8b88-3882-3a1e-b3aeff55048b",
        "judulMateri": "Video Materi 1",
        "visibilitas": "publik",
        "thumbnail": 'https://images.unsplash.com/photo-1596496181871-9681eacf9764?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=886&q=80',
    },
    {
        "id": "kuism_3",
        "judul": "Kuis Materi 3",
        "idTopik": "topik_1",
        "judulTopik": "Topik 1",
        "idBab": "bab_1",
        "namaBab": "Bab 1",
        "idSubbab": "subbab_1",
        "namaSubbab": "Sub Bab 1",
        "idMateri": "materi_1",
        "judulMateri": "Video Materi 1",
        "visibilitas": "publik",
        "thumbnail": 'https://images.unsplash.com/photo-1596496181871-9681eacf9764?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=886&q=80',
    },
    {
        "id": "kuism_4",
        "judul": "Kuis Materi 4",
        "idTopik": "topik_1",
        "judulTopik": "Topik 1",
        "idBab": "bab_1",
        "namaBab": "Bab 1",
        "idSubbab": "subbab_1",
        "namaSubbab": "Sub Bab 1",
        "idMateri": "materi_1",
        "judulMateri": "Video Materi 1",
        "visibilitas": "publik",
        "thumbnail": 'https://images.unsplash.com/photo-1596496181871-9681eacf9764?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=886&q=80',
    },
    {
        "id": "kuism_5",
        "judul": "Kuis Materi 5",
        "idTopik": "topik_1",
        "judulTopik": "Topik 1",
        "idBab": "bab_1",
        "namaBab": "Bab 1",
        "idSubbab": "subbab_1",
        "namaSubbab": "Sub Bab 1",
        "idMateri": "materi_1",
        "judulMateri": "Video Materi 1",
        "visibilitas": "publik",
        "thumbnail": 'https://images.unsplash.com/photo-1596496181871-9681eacf9764?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=886&q=80',
    },
    {
        "id": "kuism_6",
        "judul": "Kuis Materi 6",
        "idTopik": "topik_1",
        "judulTopik": "Topik 1",
        "idBab": "bab_1",
        "namaBab": "Bab 1",
        "idSubbab": "subbab_1",
        "namaSubbab": "Sub Bab 1",
        "idMateri": "materi_1",
        "judulMateri": "Video Materi 1",
        "visibilitas": "publik",
        "thumbnail": 'https://images.unsplash.com/photo-1596496181871-9681eacf9764?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=886&q=80',
    },
    {
        "id": "kuism_7",
        "judul": "Kuis Materi 7",
        "idTopik": "topik_1",
        "judulTopik": "Topik 1",
        "idBab": "bab_1",
        "namaBab": "Bab 1",
        "idSubbab": "subbab_1",
        "namaSubbab": "Sub Bab 1",
        "idMateri": "materi_1",
        "judulMateri": "Video Materi 1",
        "visibilitas": "publik",
        "thumbnail": 'https://images.unsplash.com/photo-1596496181871-9681eacf9764?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=886&q=80',
    },
    {
        "id": "kuism_8",
        "judul": "Kuis Materi 8",
        "idTopik": "topik_1",
        "judulTopik": "Topik 1",
        "idBab": "bab_1",
        "namaBab": "Bab 1",
        "idSubbab": "subbab_1",
        "namaSubbab": "Sub Bab 1",
        "idMateri": "materi_1",
        "judulMateri": "Video Materi 1",
        "visibilitas": "publik",
        "thumbnail": 'https://images.unsplash.com/photo-1596496181871-9681eacf9764?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=886&q=80',
    },
    {
        "id": "kuism_9",
        "judul": "Kuis Materi 9",
        "idTopik": "topik_1",
        "judulTopik": "Topik 1",
        "idBab": "bab_1",
        "namaBab": "Bab 1",
        "idSubbab": "subbab_1",
        "namaSubbab": "Sub Bab 1",
        "idMateri": "materi_1",
        "judulMateri": "Video Materi 1",
        "visibilitas": "publik",
        "thumbnail": 'https://images.unsplash.com/photo-1596496181871-9681eacf9764?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=886&q=80',
    },
    {
        "id": "kuism_10",
        "judul": "Kuis Materi 10",
        "idTopik": "topik_1",
        "judulTopik": "Topik 1",
        "idBab": "bab_1",
        "namaBab": "Bab 1",
        "idSubbab": "subbab_1",
        "namaSubbab": "Sub Bab 1",
        "idMateri": "materi_1",
        "judulMateri": "Video Materi 1",
        "visibilitas": "publik",
        "thumbnail": 'https://images.unsplash.com/photo-1596496181871-9681eacf9764?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=886&q=80',
    },
    {
        "id": "kuism_11",
        "judul": "Kuis Materi 11",
        "idTopik": "topik_1",
        "judulTopik": "Topik 1",
        "idBab": "bab_1",
        "namaBab": "Bab 1",
        "idSubbab": "subbab_1",
        "namaSubbab": "Sub Bab 1",
        "idMateri": "materi_1",
        "judulMateri": "Video Materi 1",
        "visibilitas": "publik",
        "thumbnail": 'https://images.unsplash.com/photo-1596496181871-9681eacf9764?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=886&q=80',
    },
    {
        "id": "kuism_12",
        "judul": "Kuis Materi 12",
        "idTopik": "topik_1",
        "judulTopik": "Topik 1",
        "idBab": "bab_1",
        "namaBab": "Bab 1",
        "idSubbab": "subbab_1",
        "namaSubbab": "Sub Bab 1",
        "idMateri": "materi_1",
        "judulMateri": "Video Materi 1",
        "visibilitas": "publik",
        "thumbnail": 'https://images.unsplash.com/photo-1596496181871-9681eacf9764?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=886&q=80',
    },
    {
        "id": "kuism_13",
        "judul": "Kuis Materi 13",
        "idTopik": "topik_1",
        "judulTopik": "Topik 1",
        "idBab": "bab_1",
        "namaBab": "Bab 1",
        "idSubbab": "subbab_1",
        "namaSubbab": "Sub Bab 1",
        "idMateri": "materi_1",
        "judulMateri": "Video Materi 1",
        "visibilitas": "publik",
        "thumbnail": 'https://images.unsplash.com/photo-1596496181871-9681eacf9764?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=886&q=80',
    },
    {
        "id": "kuism_14",
        "judul": "Kuis Materi 14",
        "idTopik": "topik_1",
        "judulTopik": "Topik 1",
        "idBab": "bab_1",
        "namaBab": "Bab 1",
        "idSubbab": "subbab_1",
        "namaSubbab": "Sub Bab 1",
        "idMateri": "materi_1",
        "judulMateri": "Video Materi 1",
        "visibilitas": "publik",
        "thumbnail": 'https://images.unsplash.com/photo-1596496181871-9681eacf9764?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=886&q=80',
    },
    {
        "id": "kuism_15",
        "judul": "Kuis Materi 15",
        "idTopik": "topik_1",
        "judulTopik": "Topik 1",
        "idBab": "bab_1",
        "namaBab": "Bab 1",
        "idSubbab": "subbab_1",
        "namaSubbab": "Sub Bab 1",
        "idMateri": "materi_1",
        "judulMateri": "Video Materi 1",
        "visibilitas": "publik",
        "thumbnail": 'https://images.unsplash.com/photo-1596496181871-9681eacf9764?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=886&q=80',
    },
    {
        "id": "kuism_16",
        "judul": "Kuis Materi 16",
        "idTopik": "topik_1",
        "judulTopik": "Topik 1",
        "idBab": "bab_1",
        "namaBab": "Bab 1",
        "idSubbab": "subbab_1",
        "namaSubbab": "Sub Bab 1",
        "idMateri": "materi_1",
        "judulMateri": "Video Materi 1",
        "visibilitas": "publik",
        "thumbnail": 'https://images.unsplash.com/photo-1596496181871-9681eacf9764?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=886&q=80',
    },
    {
        "id": "kuism_17",
        "judul": "Kuis Materi 17",
        "idTopik": "topik_1",
        "judulTopik": "Topik 1",
        "idBab": "bab_1",
        "namaBab": "Bab 1",
        "idSubbab": "subbab_1",
        "namaSubbab": "Sub Bab 1",
        "idMateri": "materi_1",
        "judulMateri": "Video Materi 1",
        "visibilitas": "publik",
        "thumbnail": 'https://images.unsplash.com/photo-1596496181871-9681eacf9764?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=886&q=80',
    },
    {
        "id": "kuism_18",
        "judul": "Kuis Materi 18",
        "idTopik": "topik_1",
        "judulTopik": "Topik 1",
        "idBab": "bab_1",
        "namaBab": "Bab 1",
        "idSubbab": "subbab_1",
        "namaSubbab": "Sub Bab 1",
        "idMateri": "materi_1",
        "judulMateri": "Video Materi 1",
        "visibilitas": "publik",
        "thumbnail": 'https://images.unsplash.com/photo-1596496181871-9681eacf9764?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=886&q=80',
    },
    {
        "id": "kuism_19",
        "judul": "Kuis Materi 19",
        "idTopik": "topik_1",
        "judulTopik": "Topik 1",
        "idBab": "bab_1",
        "namaBab": "Bab 1",
        "idSubbab": "subbab_1",
        "namaSubbab": "Sub Bab 1",
        "idMateri": "materi_1",
        "judulMateri": "Video Materi 1",
        "visibilitas": "publik",
        "thumbnail": 'https://images.unsplash.com/photo-1596496181871-9681eacf9764?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=886&q=80',
    },
]
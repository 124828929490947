import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { showErrorAlert, showSuccessAlert } from "../../../app";

// component
import { Table } from "../../common/table";
import { columns } from "./columns";

// service
import FileSaver from "file-saver";
import {
  exportProgressBelajar,
  getAllProgressBelajar,
} from "../../service/DashboardService";
import { sendWhatsappLearningProgress } from "../../service/WhatsappService";

function ProgressBelajar(setChecked) {
  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const [filterSearch, setFilterSearch] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [isMasterChecked, setIsMasterChecked] = useState(false);
  const [parameter,setParameter] = useState();
  const [pageCheckStatus, setPageCheckStatus] = useState({});
  const [currentPage, setCurrentPage] = useState(0);

  const fetchProgressBelajar = (val) => {
    setFilterSearch(val);
    getAllProgressBelajar({ keywordSearch: val })
      .then(
        (response) => {
          switch (response.data.status_code) {
            case "TAL200":
              setData(response.data.message || 0);
              break;
            default:
              dispatch(showErrorAlert("Terjadi kesalahan pada server!"));
              break;
          }
        },
        (error) => {
          console.log("Total Talent Join error :", error);
        }
      )
      .finally((info) => {});
  };

  const sendWhatsapp = () => {
    selectedRows?.map((data, i) => {

      var javaProgress = 0;
      var oopProgress = 0;
      var sqlProgress = 0;
      var discordProgress = 0;
      var gitProgress = 0;

      data.progress.map((item) => {
        if (item.nama_topik === "Fundamental Java"){
          javaProgress = item.percentage
        } else if (item.nama_topik === "Structured Query Language (SQL)"){
          sqlProgress = item.percentage
        } else if (item.nama_topik === "Object Oriented Program Java"){
          oopProgress = item.percentage
        } else if (item.nama_topik === "GIT"){
          gitProgress = item.percentage
        } else if (item.nama_topik === "Support Discord"){
          discordProgress = item.percentage
        }
      });

      const talentData = {
        learningReport: [
          {
            namaTalent: data.nama,
            nomorWa: data.nomor_wa_talent,
            javaScore: javaProgress,
            oopScore: oopProgress,
            sqlScore: sqlProgress,
            discordScore: discordProgress,
            gitScore: gitProgress,
          },
        ],
      };

      sendWhatsappLearningProgress(talentData).then((response) => {
        switch (response.data.status_code) {
          case "WA200":
            dispatch(showSuccessAlert("Pesan berhasil dikirim!"));
            break;
          default:
            dispatch(showErrorAlert("Terjadi kesalahan pada server!"));
            break;
        }
      });
      return null;
    });
  };

  const fetchExportProgressBelajar = () => {
    exportProgressBelajar({ keywordSearch: filterSearch })
      .then(
        (response) => {
          switch (response.status) {
            case 200:
              FileSaver.saveAs(response.data, "Progress Belajar Peserta.xlsx");
              break;
            default:
              dispatch(showErrorAlert("Terjadi kesalahan pada server!"));
              break;
          }
        },
        (error) => {
          console.log("Total Talent Join error :", error);
        }
      )
      .finally((info) => {});
  };

  const handleMasterCheckboxChange = () => {
    const newIsMasterChecked = !pageCheckStatus[currentPage];
    setPageCheckStatus((prevPageCheckStatus) => ({
      ...prevPageCheckStatus,
      [currentPage]: newIsMasterChecked,
    }));
  
    if (newIsMasterChecked) {
      const newData = data?.slice(parameter.start, parameter.end);
      setSelectedRows((prevSelectedRows) => [...prevSelectedRows, ...newData]);
      setIsMasterChecked(true);
    } else {
      setSelectedRows((prevSelectedRows) =>
        prevSelectedRows.filter(
          (prevRow) =>
            !data?.slice(parameter.start, parameter.end).some((newRow) => newRow.id === prevRow.id)
        )
      );
      setIsMasterChecked(false);
    }
  };

  const handleRowCheckboxChange = (row) => {
    const selectedRowIds = selectedRows.map((row) => row.id);
    if(selectedRowIds.includes(row.id)){
      setSelectedRows(selectedRows.filter((r) => r.id !== row.id));
      setIsMasterChecked(false)
    }
    else { 
      setSelectedRows([...selectedRows, row]); 
    }
  };

  const getItemPage = ({ pageIndex, pageSize }) => {
    const start = pageIndex * pageSize;
    const end = start + pageSize ;
    setParameter({ start, end });
    setCurrentPage(pageIndex);
    setPageCheckStatus((prevPageCheckStatus) => ({
      ...prevPageCheckStatus,
      [pageIndex]: prevPageCheckStatus[pageIndex] || false, 
    }));
  };

  useEffect(() => {
    fetchProgressBelajar();
  }, [dispatch]);

  useEffect(() => {
    setIsMasterChecked(pageCheckStatus[currentPage]);
  }, [currentPage, pageCheckStatus]);

  useEffect(() => {
    localStorage.setItem("pageCheckStatus", JSON.stringify(pageCheckStatus));
  }, [pageCheckStatus]); 

  useEffect(() => {
    const storedPageCheckStatus = JSON.parse(localStorage.getItem("pageCheckStatus"));
    setPageCheckStatus(storedPageCheckStatus || {});
  }, []);

  return (
    <Table
      data={data}
      columns={columns(
        isMasterChecked,
        handleMasterCheckboxChange,
        handleRowCheckboxChange,
        selectedRows
      )}
      showAddButton={false}
      showSearch={false}
      showFilter={false}
      showCustomize={false}
      customSearch={true}
      onExport={fetchExportProgressBelajar}
      onSearchChange={(val) => fetchProgressBelajar(val)}
      whatsappButton={sendWhatsapp}
      whatsappButtonDisabled={selectedRows.length !== 0 ? false : true}
      rowClickFunction={() => {}}
      title="Progress Belajar Peserta"
      getItemPage={getItemPage}
    />
  );
}

export default ProgressBelajar;

import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";

export const columns = (onEdit) => [
  // {
  //   Header: "ID Talent",
  //   accessor: "id",
  // },
  {
    Header: "Nama Talent",
    accessor: "nama",
  },
  {
    Header: "Email",
    accessor: "email",
  },
  {
    Header: "Nomor Telepon",
    accessor: "nomor_telp",
  },
  {
    Header: "Jenis Kelamin",
    accessor: "jenis_kelamin",
  },
  {
    Header: "Tanggal Join",
    accessor: "tanggal_terdaftar",
    Cell: (row) => {
      return (
        <span>
          {moment(row.row.original.tanggal_terdaftar).format(
            "DD MMMM YYYY, HH:mm"
          )}
        </span>
      );
    },
  },
  {
    Header: "Asal Lembaga",
    accessor: "nama_lembaga",
  },
  {
    Header: "Program Studi",
    accessor: "program_studi",
  },
  {
    Header: "Masuk Kelas Arutala",
    accessor: "siap_masuk_bulan",
  },
  {
    Header: "Motivasi",
    accessor: "motivasi",
    width: 600,
  },
  {
    Header: "Jenjang Talent",
    accessor: "jenjang",
  },
  {
    Header: "Aktifitas Talent",
    accessor: "aktivitas_talent",
  },
  {
    Header: "Semester",
    accessor: "semester",
  },
  {
    Header: "Tahun Lulus",
    accessor: "tahun_lulus",
  },
  {
    Header: "LinkedIn",
    accessor: "profil_linkedin_url",
  },
  {
    Header: "Status Peserta",
    accessor: "status_peserta",
  },
  {
    Header: "Keterangan",
    accessor: "keterangan",
  },
  // {
  //   Header: "Generasi",
  //   accessor: "generasi",
  // },
  {
    Header: "Action",
    accessor: "action",
    Cell: (row) => {
      return (
        <div className="d-flex flex-row align-items-center">
          <div
            style={{ marginLeft: 5, marginRight: 5 }}
            onClick={() => onEdit(row.row.original)}
          >
            <FontAwesomeIcon icon={faEdit} />
          </div>
        </div>
      );
    },
  },
];

import { faBookOpen, faPlay, faFileLines, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import {
    Button, Col, Container, Image, Nav, Navbar,
    OverlayTrigger, ProgressBar, Row, Tooltip
} from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { GetParentPath } from '../../../common';

function NavItemCustom({ icon, title, url }) {
    return (
        <Nav.Item>
            <NavLink to={url} className='nav-link ps-4'>
                <Row>
                    {icon && <Col lg='auto'>
                        <Image src={icon} className='nav-icon' />
                    </Col>}
                    <Col lg='auto'>
                        <span>{title}</span>
                    </Col>
                </Row>
            </NavLink>
        </Nav.Item>
    );
}

function Navigation({ profileData }) {
    const parentPath = GetParentPath();
    const profilePhoto = '/img/avatar.png';
    const profileName = profileData.nama;
    const profileLevel = profileData.level;
    const profileCoin = profileData.coin;
    const profileExp = profileData.totalExp;
    const profileExpProgress = profileData.expLevel ? profileData.expProgress / profileData.expLevel * 100 : 100;
    const profileExpNeeded = profileData.expLevel ? profileData.expLevel - profileData.expProgress : 0;

    const itemList = [
        { icon: '/img/poll.png', title: 'Leaderboards', url: `${parentPath}/leaderboards` },
        { icon: '/img/create.png', title: 'Catatan Saya', url: `${parentPath}/catatan` },
        { icon: '/img/folder.png', title: 'Riwayat Download', url: `${parentPath}/download` },
        { icon: '/img/party_mode.png', title: 'Lihat Screenshot', url: `${parentPath}/screenshot` },
        { icon: '/img/assignment.png', title: 'Laporan Belajar', url: `${parentPath}/laporan-belajar` },
    ];

    const navItemList = itemList.map(item => (
        <NavItemCustom key={item.title} icon={item.icon} title={item.title} url={item.url} />
    ));

    const profileItemList = [
        { icon: '/img/level.png', name: 'Level', value: profileLevel },
        { icon: '/img/coin.png', name: 'Coin', value: profileCoin },
        { icon: '/img/xp.png', name: 'Total Experience', value: profileExp },
    ];

    const profileItemNavItem = profileItemList.map(item => (
        <Row className="profileItemNavItem" key={item.name}>
            {item.icon && <Col lg='auto'>
                <Image src={item.icon} className='navigation-profile-icon' />
            </Col>}
            <Col lg='auto'>
                <span>{item.name}</span>
            </Col>
            <Col lg='auto ms-auto'>
                <span>{item.value}</span>
            </Col>
        </Row>
    ));

    const profilNameOverlay = (name) => (
        <OverlayTrigger placement='top'
            overlay={<Tooltip>{name}</Tooltip>}
        >
            <Button variant='light'
                className='p-0 bg-transparent border-0 text-ellipsis profile-name'
                style={{ cursor: 'text' }}
            >
                {name}
            </Button>
        </OverlayTrigger>
    )

    return (
        <Container fluid id="navigation-talent" className="bg-transparent p-0" >
            <Container fluid id="navigation-profile" className='bg-white rounded' >
                <Row className="align-item-center">
                    <Col lg='auto'>
                        <Image className="profile-image p-0" src={profilePhoto} />
                    </Col>
                    <Col className="d-inline profile-name my-auto text-ellipsis">
                        {profilNameOverlay(profileName)}
                    </Col>
                </Row>
                <hr />
                {profileItemNavItem}
                <Row className="xp-row">
                    <Col>XP
                        <ProgressBar now={profileExpProgress} className='progress-basic' />
                    </Col>
                </Row>
                <Row className="xp-row">
                    <Col className="d-flex flex-column justify-content-center">
                        <div className="mx-auto d-inline-block">
                            {
                                profileExpNeeded ?
                                    `${profileExpNeeded} XP lagi untuk naik level` :
                                    'Selamat! Anda sudah mencapai level tertinggi'
                            }
                        </div>
                    </Col>
                </Row>
            </Container>
            <Navbar id="navigation-normal" className='bg-white flex-column justify-content-start p-0 rounded' >
                {navItemList}
            </Navbar>
        </Container>
    );
}

export default Navigation;

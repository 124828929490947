import axios from "axios";
import { talent_laporsoalkuisbab_endpoint, talent_soalkuisbab_endpoint } from "../../app";
import { authHeader } from "./AuthHeader";

export const listSoalKuisBabTalent = ({ idKuisBab }) => {
    return axios({
        url: talent_soalkuisbab_endpoint,
        method: 'GET',
        headers: { 'Content-type': 'application/json', ...authHeader() },
        params: { idKuisBab },
    }).then((response) => {
        console.log('List Soal Kuis Bab Result :', response.data);
        return response;
    });
};

export const laporkanSoalBabTalent = ({ idSoal, jenisLaporan }) => {
    return axios({
        url: talent_laporsoalkuisbab_endpoint,
        method: 'POST',
        headers: { 'Content-type': 'application/json', ...authHeader() },
        data: {
            idSoal, jenisLaporan,
        }
    }).then((response) => {
        console.log('Lapor Kuis Bab Result :', response.data);
        return response;
    });
};

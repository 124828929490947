import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Banner from './Banner';
import ResetSandiForm from './ResetSandiForm';

function ResetSandiPage(props) {
    return (
        <Container fluid className='vh-100 mx-0 px-0 d-flex align-items-center justify-content-center'
            style={{ backgroundColor: '#003399' }}
        >
            <Row className='mx-0 px-0' style={{ height: '85%', width: '90%' }}>
                <Col className='col-6 login-banner-col'
                    style={{
                        background: 'url(/img/talent-login-banner.png) rgba(0, 0, 0, 0.75)',
                        backgroundBlendMode: 'multiply',
                        backgroundSize: 'cover',
                        borderRadius: '2rem',
                        borderTopRightRadius: '0',
                        borderBottomRightRadius: '0',
                    }}
                >
                    <Banner {...props} />
                </Col>
                <Col className='bg-white col-6 h-100 d-flex justify-content-center login-form-col'
                    style={{
                        borderRadius: '2rem',
                        borderTopLeftRadius: '0',
                        borderBottomLeftRadius: '0',
                    }}
                >
                    <ResetSandiForm {...props} />
                </Col>
            </Row>
        </Container>
    );
}

export default ResetSandiPage;

import React, { useEffect, useState } from 'react'
import { Button, Container } from 'react-bootstrap';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import { useDispatch } from 'react-redux';
import { hideErrorAlert, hideSuccessAlert, showErrorAlert, showSuccessAlert } from '../../../app';
import { getCatatanTalentByIdMateri, addCatatanTalent, editCatatanTalent } from '../../service/CatatanService';

function Catatan({ idTopik, idBab, idSubbab, idMateri }) {
    const dispatch = useDispatch();
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const catatan = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    const [catatanData, setCatatanData] = useState({});

    const [refreshData, setRefreshData] = useState(1);

    useEffect(() => {
        getCatatanTalentByIdMateri({ idMateri }).then(
            (response) => {
                switch (response.data.status_code) {
                    case 'CTN200':
                        setCatatanData(response.data.message || {});
                        setEditorState(
                            EditorState.createWithContent(
                                ContentState.createFromBlockArray(
                                    htmlToDraft(response.data.message.catatan).contentBlocks
                                )));
                        break;
                    case 'CTN401':
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('Get Catatan error :', error);
            }).finally((info) => {

            });
    }, [dispatch, idMateri, refreshData]);

    const addCatatan = () => {
        addCatatanTalent({ idTopik, idBab, idSubbab, idMateri, catatan }).then(
            (response) => {
                switch (response.data.status_code) {
                    case 'CTN200':
                        dispatch(showSuccessAlert('Berhasil membuat catatan'));
                        setRefreshData(prev => prev + 1);
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('Add Catatan error :', error);
            }).finally((info) => {

            });
    }

    const editCatatan = () => {
        editCatatanTalent({ id: catatanData.id, catatan }).then(
            (response) => {
                switch (response.data.status_code) {
                    case 'CTN200':
                        dispatch(showSuccessAlert('Berhasil mengubah catatan'));
                        setRefreshData(prev => prev + 1);
                        break;
                    case 'CTN401':
                        addCatatan();
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('Edit Catatan error :', error);
            }).finally((info) => {

            });
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        dispatch(hideErrorAlert());
        dispatch(hideSuccessAlert());

        if (catatanData?.id) editCatatan();
        else addCatatan();
    }

    return (
        <Container className='bg-white p-0 catatan-container'>
            <Editor
                placeholder='Tambah Catatan...'
                editorState={editorState}
                toolbarClassName="catatan-toolbar"
                wrapperClassName="catatan-wrapper"
                editorClassName="catatan-editor"
                onEditorStateChange={setEditorState}
                hashtag={{}}
                toolbar={{
                    inline: {
                        bold: { className: 'catatan-option' },
                        italic: { className: 'catatan-option' },
                        underline: { className: 'catatan-option' },
                        strikethrough: { className: 'catatan-option' },
                        monospace: { className: 'catatan-option' },
                        superscript: { className: 'catatan-option' },
                        subscript: { className: 'catatan-option' },
                    },
                    blockType: {
                        className: 'catatan-option-with-dropdown',
                    },
                    fontSize: {
                        className: 'catatan-option-with-dropdown'
                    },
                    list: {
                        unordered: { className: 'catatan-option' },
                        ordered: { className: 'catatan-option' },
                        indent: { className: 'catatan-option' },
                        outdent: { className: 'catatan-option' },
                    },
                    textAlign: {
                        left: { className: 'catatan-option' },
                        center: { className: 'catatan-option' },
                        right: { className: 'catatan-option' },
                        justify: { className: 'catatan-option' },
                    },
                    fontFamily: {
                        className: 'catatan-option-with-dropdown',
                    },
                    colorPicker: {
                        className: 'catatan-option',
                    },
                    link: {
                        link: { className: 'catatan-option' },
                        unlink: { className: 'catatan-option' },
                    },
                    emoji: {
                        className: 'catatan-option',
                    },
                    embedded: {
                        className: 'catatan-option',
                    },
                    image: {
                        className: 'catatan-option',
                        alt: { present: true, mandatory: true }
                    },
                    remove: { className: 'catatan-option' },
                    history: {
                        undo: { className: 'catatan-option' },
                        redo: { className: 'catatan-option' },
                    },
                }}
            />
            <Container className='p-2 text-end'>
                <Button className='py-0'
                    onClick={(e) => handleSubmit(e)}
                >
                    Simpan
                </Button>
            </Container>
        </Container>
    );
}

export default Catatan;

import axios from "axios";
import { gcs_endpoint, base_url_gcs } from "../app";
import { authHeader as authHeaderTrainer } from "../trainer/service/AuthHeader";
import { authHeader } from "../talent/service/AuthHeader";

export const uploadImage = ({ fileName, folderName, file }) => {
    const fileNameLower = fileName.toLowerCase();
    return axios({
        url: gcs_endpoint,
        method: 'POST',
        headers: { 'Content-type': 'application/json', ...authHeaderTrainer() },
        data: { fileName: fileNameLower, folderName }
    }).then((response) => {
        console.log('Request URL Result :', response.data);
        const responseData = response.data.message;
        return axios({
            url: responseData.url,
            method: 'PUT',
            headers: {
                'Content-type': 'application/octet-stream',
            },
            data: file
        }).then((response) => {
            console.log('Link image :', `${base_url_gcs}/${folderName}/${fileNameLower}`);
            return { ...response, urlImage: `${base_url_gcs}/${folderName}/${fileNameLower}` };
        });
    });
};

export const uploadImageSS = ({ fileName, folderName, screenshot }) => {
    const fileNameLower = fileName.toLowerCase();
    return axios({
        url: gcs_endpoint,
        method: 'POST',
        headers: { 'Content-type': 'application/json', ...authHeader() },
        data: { fileName: fileNameLower, folderName }
    }).then((response) => {
        console.log('Request URL Result :', response.data);
        const responseData = response.data.message;
        return axios({
            url: responseData.url,
            method: 'PUT',
            headers: {
                'Content-Type': 'application/multipart/form-data; charset=UTF-8',
            },
            data: screenshot.blob
        }).then((response) => {
            console.log('Link image :', `${base_url_gcs}/${folderName}/${fileNameLower}`);
            return { ...response, urlImage: `${base_url_gcs}/${folderName}/${fileNameLower}` };
        });
    });
};
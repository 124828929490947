import React, { useState, useEffect } from 'react';

import { faEye, faEyeSlash, faUpload, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Button, Modal, Form,
    Row, Col, InputGroup, ToggleButton
} from 'react-bootstrap';

import Select from 'react-select';
import { useDispatch } from 'react-redux';
import {
    showErrorAlert, hideErrorAlert,
    showSuccessAlert, hideSuccessAlert,
} from '../../../app';

import { addSoalKuisMateriTrainer, editSoalKuisMateriTrainer } from '../../service/SoalKuisMateriService';

function SoalKuisMateriPopUp({ show, setShow, soalKuisMateriData, isEdit, setRefreshData }) {
    const dispatch = useDispatch();

    const [id, setId] = useState();
    const [idKuisMateri, setIdKuisMateri] = useState();
    const [tingkatan, setTingkatan] = useState();
    const [visibilitas, setVisibilitas] = useState();
    const [soal, setSoal] = useState();
    const [gambar, setGambar] = useState();
    const [opsiA, setOpsiA] = useState();
    const [opsiB, setOpsiB] = useState();
    const [opsiC, setOpsiC] = useState();
    const [opsiD, setOpsiD] = useState();
    const [jawaban, setJawaban] = useState();
    const [pembahasan, setPembahasan] = useState();
    const [gambarPembahasan, setGambarPembahasan] = useState();
    const [urlReferensi, setUrlReferensi] = useState();

    // file
    const [selectedGambar, setSelectedGambar] = useState('');
    const [isGambarSelected, setIsGambarSelected] = useState(false);
    const [selectedGambarPembahasan, setSelectedGambarPembahasan] = useState('');
    const [isGambarPembahasanSelected, setIsGambarPembahasanSelected] = useState(false);

    const [tingkatanOptions, setTingkatanOptions] = useState([
        { value: 'mudah', label: 'Mudah' },
        { value: 'sedang', label: 'Sedang' },
        { value: 'sulit', label: 'Sulit' },
    ]);

    const [visibilitasOptions, setVisibilitasOptions] = useState([
        {
            value: 'sembunyi', label: <span>
                <FontAwesomeIcon icon={faEyeSlash} color={'#263238'} className='me-2' />Sembunyi</span>
        },
        {
            value: 'publik', label: <span>
                <FontAwesomeIcon icon={faEye} color={'#263238'} className='me-2' />Publik</span>
        },
    ]);

    useEffect(() => {
        setId(soalKuisMateriData?.id || '');
        setIdKuisMateri(soalKuisMateriData?.idKuisMateri || '');
        setTingkatan(soalKuisMateriData?.tingkatan || '');
        setVisibilitas(soalKuisMateriData?.visibilitas || 'sembunyi');
        setSoal(soalKuisMateriData?.soal || '');
        setOpsiA(soalKuisMateriData?.opsiA || '');
        setOpsiB(soalKuisMateriData?.opsiB || '');
        setOpsiC(soalKuisMateriData?.opsiC || '');
        setOpsiD(soalKuisMateriData?.opsiD || '');
        setJawaban(soalKuisMateriData?.jawaban || '');
        setPembahasan(soalKuisMateriData?.pembahasan || '');
        setUrlReferensi(soalKuisMateriData?.urlReferensi || '');
        setGambar(soalKuisMateriData?.gambar || '');
        setGambarPembahasan(soalKuisMateriData?.gambarPembahasan || '');
        setSelectedGambar('');
        setSelectedGambarPembahasan('');
        setIsGambarSelected(false);
        setIsGambarPembahasanSelected(false);
    }, [show, soalKuisMateriData]);

    const tingkatanSelected = tingkatanOptions.find(item => item.value === tingkatan) || null;
    const visibilitasSelected = visibilitasOptions.find(item => item.value === visibilitas) || null;

    const handleTingkatanChange = (value) => {
        setTingkatan(value.value);
    }

    const handleVisibilitasChange = (value) => {
        setVisibilitas(value.value);
    }

    const FileUploader = ({ selectedFile, setSelectedFile, isSelected, setIsSelected, gambarFile }) => {
        
        const hiddenFileInput = React.useRef(null);
        const handleClick = event => {
            hiddenFileInput.current.click();
        };

        const handleChange = event => {
            const fileUploaded = event.target.files[0];
            if (fileUploaded) {
                setSelectedFile(event.target.files[0]);
                setIsSelected(true);
            }
        };

        return (
            <Row>
                <Col xs='auto pe-0'>
                    <Button onClick={handleClick} className='d-inline me-2'>
                        <FontAwesomeIcon icon={faUpload} className='me-2' /> Upload
                    </Button>
                </Col>
                <Col className='col-6 d-flex align-items-center p-0'>
                    {isSelected
                        ? (
                            <Form.Label className='text-ellipsis mb-0'>{selectedFile?.name}</Form.Label>
                        )
                        : isEdit
                            ? (
                                <Form.Label className='text-ellipsis mb-0'>{
                                    <a href={gambarFile} target='_blank' rel='noreferrer'>
                                        {gambarFile.split(/(\\|\/)/g).pop()}
                                    </a>
                                }
                                </Form.Label>
                            )
                            : (
                                <span>Pilih file untuk diupload</span>
                            )
                    }
                </Col>
                <input
                    type='file'
                    ref={hiddenFileInput}
                    onChange={handleChange}
                    style={{ display: 'none' }}
                    accept='.png, .jpg, .jpeg' />
            </Row>
        );
    }

    const tingkatanField = <Col>
        <Form.Group>
            <Form.Label>Tingkatan <b className='asterisk'>*</b></Form.Label>
            <Select
                value={tingkatanSelected}
                noOptionsMessage={() => 'No options'}
                placeholder='Tingkatan'
                options={tingkatanOptions}
                onChange={handleTingkatanChange}
            />
        </Form.Group>
    </Col>

    const visibilitasField = <Col>
        <Form.Group>
            <Form.Label>Visibilitas <b className='asterisk'>*</b></Form.Label>
            <Select value={visibilitasSelected}
                noOptionsMessage={() => 'No options'}
                placeholder='Visibilitas'
                options={visibilitasOptions}
                onChange={handleVisibilitasChange}
            />
        </Form.Group>
    </Col>

    const firstRowForm = <Row className='mx-0 mb-3'>
        {tingkatanField}
        {visibilitasField}
    </Row>

    const soalField = <Col>
        <Form.Group>
            <Form.Label>Soal<b className='asterisk'>*</b></Form.Label>
            <Form.Control as="textarea" placeholder="Soal"
                onChange={(e) => { setSoal(e.target.value) }}
                value={soal} />
        </Form.Group>
    </Col>

    const secondRowForm = <Row className='mx-0 mb-3'>
        {soalField}
    </Row>

    const gambarSoalField = <Col>
        <Form.Group>
            <Form.Label>Upload Gambar Soal</Form.Label>
            <FileUploader gambarFile={gambar}
                selectedFile={selectedGambar} setSelectedFile={setSelectedGambar}
                isSelected={isGambarSelected} setIsSelected={setIsGambarSelected} />
        </Form.Group>
    </Col>

    const thirdRowForm = <Row className='mx-0 mb-3'>
        {gambarSoalField}
    </Row>

    const RadioInput = ({ value, isChecked, handleChange }) => {
        const handleRadioChange = e => {
            const { id } = e.currentTarget;
            handleChange(id);
        };
        return (
            <div className='radio-answer-option'>
                <ToggleButton
                    variant="outline-success"
                    type="radio"
                    className="custom-radio-button"
                    name="answer-option"
                    id={value}
                    checked={isChecked}
                    onChange={handleRadioChange}
                    size="sm">
                    <FontAwesomeIcon icon={faCheck} />
                </ToggleButton>
            </div>
        );
    };

    const opsiAField = <Col>
        <Row>
            <Col className='pe-0'>
                <InputGroup>
                    <InputGroup.Text>A</InputGroup.Text>
                    <Form.Control placeholder='Jawaban A'
                        value={opsiA}
                        onChange={(e) => { setOpsiA(e.target.value) }}
                    />
                </InputGroup>
            </Col>
            <Col xs="auto" className='d-grid align-content-center'>
                <RadioInput value='A'
                    isChecked={jawaban === 'A'}
                    handleChange={setJawaban} />
            </Col>
        </Row>
    </Col>

    const opsiBField = <Col>
        <Row>
            <Col className='pe-0'>
                <InputGroup>
                    <InputGroup.Text>B</InputGroup.Text>
                    <Form.Control placeholder='Jawaban B'
                        value={opsiB}
                        onChange={(e) => { setOpsiB(e.target.value) }}
                    />
                </InputGroup>
            </Col>
            <Col xs="auto" className='d-grid align-content-center'>
                <RadioInput value='B'
                    isChecked={jawaban === 'B'}
                    handleChange={setJawaban} />
            </Col>
        </Row>
    </Col>

    const opsiCField = <Col>
        <Row>
            <Col className='pe-0'>
                <InputGroup>
                    <InputGroup.Text>C</InputGroup.Text>
                    <Form.Control placeholder='Jawaban C'
                        value={opsiC}
                        onChange={(e) => { setOpsiC(e.target.value) }}
                    />
                </InputGroup>
            </Col>
            <Col xs="auto" className='d-grid align-content-center'>
                <RadioInput value='C'
                    isChecked={jawaban === 'C'}
                    handleChange={setJawaban} />
            </Col>
        </Row>
    </Col>

    const opsiDField = <Col>
        <Row>
            <Col className='pe-0'>
                <InputGroup>
                    <InputGroup.Text>D</InputGroup.Text>
                    <Form.Control placeholder='Jawaban D'
                        value={opsiD}
                        onChange={(e) => { setOpsiD(e.target.value) }}
                    />
                </InputGroup>
            </Col>
            <Col xs="auto" className='d-grid align-content-center'>
                <RadioInput value='D'
                    isChecked={jawaban === 'D'}
                    handleChange={setJawaban} />
            </Col>
        </Row>
    </Col>

    const jawabanField = <Col>
        <Form.Group>
            <Form.Label>Jawaban <b className='asterisk'>*</b></Form.Label>
            <Row className='mb-3'>
                {opsiAField}
                {opsiBField}
            </Row>
            <Row>
                {opsiCField}
                {opsiDField}
            </Row>
        </Form.Group>
    </Col>

    const fourthRowForm = <Row className='mx-0 mb-3'>
        {jawabanField}
    </Row>

    const pembahasanField = <Col>
        <Form.Group>
            <Form.Label>Pembahasan<b className='asterisk'>*</b></Form.Label>
            <Form.Control as="textarea" placeholder="Pembahasan"
                onChange={(e) => { setPembahasan(e.target.value) }}
                value={pembahasan} />
        </Form.Group>
    </Col>

    const fifthRowForm = <Row className='mx-0 mb-3'>
        {pembahasanField}
    </Row>

    const gambarPembahasanField = <Col xs={6}>
        <Form.Group>
            <Form.Label>Upload Gambar Pembahasan</Form.Label>
            <FileUploader gambarFile={gambarPembahasan}
                selectedFile={selectedGambarPembahasan} setSelectedFile={setSelectedGambarPembahasan}
                isSelected={isGambarPembahasanSelected} setIsSelected={setIsGambarPembahasanSelected} />
        </Form.Group>
    </Col>

    const urlReferensiField = <Col xs={6}>
        <Form.Group>
            <Form.Label>URL Referensi <b className='asterisk'>*</b></Form.Label>
            <Form.Control placeholder='URL Referensi'
                value={urlReferensi}
                onChange={(e) => { setUrlReferensi(e.target.value) }}
            />
        </Form.Group>
    </Col>

    const sixthRowForm = <Row className='mx-0 mb-3'>
        {gambarPembahasanField}
        {urlReferensiField}
    </Row>


    const addSoalKuisMateri = () => {
        addSoalKuisMateriTrainer({
            idKuisMateri, tingkatan, visibilitas,
            soal, selectedGambar, opsiA, opsiB, opsiC, opsiD,
            jawaban, pembahasan, selectedGambarPembahasan, urlReferensi,
        }).then(
            (response) => {
                switch (response.data.status_code) {
                    case 'SKM200':
                        dispatch(showSuccessAlert('Soal Kuis Materi berhasil dibuat'));
                        setRefreshData(prev => prev + 1);
                        setShow(prev => !prev);
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('Add Soal Kuis Materi error :', error);
            }).finally((info) => {

            });
    }

    const editSoalKuisMateri = () => {
        editSoalKuisMateriTrainer({
            id, idKuisMateri, tingkatan, visibilitas,
            soal, selectedGambar, opsiA, opsiB, opsiC, opsiD,
            jawaban, pembahasan, selectedGambarPembahasan, urlReferensi
        }).then(
            (response) => {
                switch (response.data.status_code) {
                    case 'SKM200':
                        dispatch(showSuccessAlert('Soal Kuis Materi berhasil diubah'));
                        setRefreshData(prev => prev + 1);
                        setShow(prev => !prev);
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('Edit Kuis Materi error :', error);
            }).finally((info) => {

            });
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        dispatch(hideErrorAlert());
        dispatch(hideSuccessAlert());

        if (!tingkatan) {
            dispatch(showErrorAlert('Pilih tingkatan!'));
        } else if (!visibilitas) {
            dispatch(showErrorAlert('Pilih visibilitas!'));
        } else if (!soal) {
            dispatch(showErrorAlert('Isi soal!'));
        } else if (!opsiA) {
            dispatch(showErrorAlert('Isi jawaban A!'));
        } else if (!opsiB) {
            dispatch(showErrorAlert('Isi jawaban B!'));
        } else if (!opsiC) {
            dispatch(showErrorAlert('Isi jawaban C!'));
        } else if (!opsiD) {
            dispatch(showErrorAlert('Isi jawaban D!'));
        } else if (selectedGambar && selectedGambar?.name &&
            !['png', 'PNG', 'jpg', 'JPG', 'jpeg', 'JPEG'].includes(selectedGambar?.name?.split('.').pop())) {
            dispatch(showErrorAlert('Thumbnail harus berekstensi .png, .jpg atau .jpeg!'));
        } else if (selectedGambar && selectedGambar.size > 2097152) {
            dispatch(showErrorAlert('Ukuran gambar soal maksimal 2MB!'));
        } else if (!jawaban) {
            dispatch(showErrorAlert('Pilih jawaban benar!'));
        } else if (!pembahasan) {
            dispatch(showErrorAlert('Isi pembahasan!'));
        } else if (selectedGambarPembahasan && selectedGambarPembahasan?.name &&
            !['png', 'PNG', 'jpg', 'JPG', 'jpeg', 'JPEG'].includes(
                selectedGambarPembahasan?.name?.split('.').pop())) {
            dispatch(showErrorAlert('Gambar pembahasan harus berekstensi .png, .jpg atau .jpeg!'));
        } else if (selectedGambarPembahasan && selectedGambarPembahasan.size > 2097152) {
            dispatch(showErrorAlert('Ukuran gambar pembahasan maksimal 2MB!'));
        } else if (!urlReferensi) {
            dispatch(showErrorAlert('Isi url referensi!'));
        } else {
            if (isEdit) editSoalKuisMateri();
            else addSoalKuisMateri();
        }
    }

    return (
        <Modal show={show} onHide={() => setShow(prev => !prev)} centered size='lg'>
            <Modal.Header closeButton>
                <Modal.Title>{isEdit ? 'Edit Soal Pilihan Ganda' : 'Tambah Soal Pilihan Ganda'}</Modal.Title>
            </Modal.Header>
            <Form onSubmit={(e) => handleSubmit(e)}>
                <Modal.Body>

                    {firstRowForm}
                    {secondRowForm}
                    {thirdRowForm}
                    {fourthRowForm}
                    {fifthRowForm}
                    {sixthRowForm}

                </Modal.Body>
                <Modal.Footer>
                    <Button variant='light' onClick={() => setShow(prev => !prev)}
                        style={{ color: '#0078D7', border: '1px solid #0078D7' }}
                    >
                        Batal
                    </Button>
                    <Button type='submit' variant='primary'>
                        Simpan
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}

export default SoalKuisMateriPopUp;

import React, { useEffect, useState } from 'react';
import { Button, Row, Col, Form, Modal, ModalBody, CloseButton } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { addScreenshotTalent } from '../../service/ScreenshotService';
import { hideErrorAlert, hideSuccessAlert, showErrorAlert, showSuccessAlert } from '../../../app';
import moment from 'moment';

export default function ScreenshotPopup({ show, setShow, data }) {
    const dispatch = useDispatch();
    const [catatan, setCatatan] = useState('');

    useEffect(() => {
        setCatatan('');
    }, [show]);

    const addScreenshot = () => {
        addScreenshotTalent({ gambarScreenshot: data.gambarScreenshot, judulVideoMateri: data.judulVideoMateri, 
            namaBab: data.namaBab, namaTopik: data.namaTopik, idSubbab: data.idSubbab, namaSubbab: data.namaSubbab, timestampScreenshot: data.timestampScreenshot, 
            catatan, idVideoScreenshot: data.idVideoScreenshot }).then(
            (response) => {
                switch (response.data.status_code) {
                    case 'SCS200':
                        dispatch(showSuccessAlert('Screenshot berhasil disimpan'));
                        setShow(prev => !prev);
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('Add Screenshot error :', error);
            }).finally((info) => {

            });
    }

    const handleSubmit = () => {
        addScreenshot();
    }

    const handleClose = () => {
        setShow(prev => !prev);
    }

    const catatanField = (
        <Col lg={12}>
            <span className='txt-popup-title'>Catatan</span><br />
            <Form.Control name='catatan'
                value={catatan}
                onChange={(e) => { setCatatan(e.target.value) }}
                as='textarea'
                placeholder="Catatan"
                maxLength={2500}
            />
            <p className='text-end m-0'>{catatan.length}/2500 Karakter</p>
        </Col>
    )

    return (
        <Modal className='modal-screenshot' show={show} onHide={handleClose} centered size='lg'>
            <Form>
                <ModalBody className='modal-body'>
                    <Row>
                        <Col>
                            <img src={data?.gambarScreenshot?.dataUri} className="w-100" />
                            <CloseButton className='close-button' variant='white' onClick={handleClose} />
                        </Col>
                    </Row>
                    <Row style={{ paddingTop: 16, paddingLeft: 16, paddingRight: 16, paddingBottom: 0 }}>
                        <Col lg={12}>
                            <span className='txt-popup-title'>Judul Bab</span><br />
                            <span className='txt-popup-content'>{data.namaBab}</span>
                        </Col>
                        <Col lg={6}>
                            <span className='txt-popup-title'>Judul Sub-Bab</span><br />
                            <span className='txt-popup-content'>{data.namaSubbab}</span>
                        </Col>
                        <Col lg={6}>
                            <span className='txt-popup-title'>Video Materi</span><br />
                            <span className='txt-popup-content'>{data.judulVideoMateri}</span>
                        </Col>
                        <Col lg={6}>
                            <span className='txt-popup-title'>Tanggal</span><br />
                            <span className='txt-popup-content'>{moment().format("DD MMMM yyyy hh:mm")}</span>
                        </Col>
                        <Col lg={6}>
                            <span className='txt-popup-title'>Timestamp</span><br />
                            <span className='txt-popup-content'>{data.timestampScreenshot}</span>
                        </Col>
                        {catatanField}
                    </Row>
                </ModalBody>
                <Modal.Footer className="border-0">
                    <Button variant='light' onClick={() => setShow(prev => !prev)}
                        style={{ color: '#0078D7', border: '1px solid #0078D7' }}>Batal</Button>
                    <Button onClick={() => handleSubmit()}
                        variant='primary'>Simpan</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

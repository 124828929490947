import React, { useEffect, useState } from 'react';
import { Modal, Form, Row, Col, InputGroup } from 'react-bootstrap';

function SubbabPopUpDetail({ show, setShow, subbabData }) {
    const [nama, setNama] = useState();
    const [idTopik, setIdTopik] = useState();
    const [judulTopik, setJudulTopik] = useState();
    const [idBab, setIdBab] = useState();
    const [namaBab, setNamaBab] = useState();
    const [skalaNilai, setSkalaNilai] = useState();
    const [tag, setTag] = useState();
    const [visibilitas, setVisibilitas] = useState();

    useEffect(() => {
        setNama(subbabData?.nama || '');
        setIdTopik(subbabData?.idTopik || '');
        setJudulTopik(subbabData?.judulTopik || '');
        setIdBab(subbabData?.idBab || '');
        setNamaBab(subbabData?.namaBab || '');
        setSkalaNilai(subbabData?.skalaNilai || '');
        setTag(subbabData?.tag || '');
        setVisibilitas(subbabData?.visibilitas || 'sembunyi');
    }, [show, subbabData]);

    const namaField = <Col>
        <Form.Group>
            <Form.Label>Nama SubBab</Form.Label>
            <InputGroup>
                <Form.Label className='fsz-125 fw-bold'>{nama}</Form.Label>
            </InputGroup>
        </Form.Group>
    </Col>

    const judulTopikField = <Col>
        <Form.Group>
            <Form.Label>Judul/Topik Materi</Form.Label>
            <InputGroup>
                <Form.Label className='fsz-125 fw-bold'>{judulTopik}</Form.Label>
            </InputGroup>
        </Form.Group>
    </Col>

    const firstRowForm = <Row className='mx-0 mb-3'>
        {namaField}
        {judulTopikField}
    </Row>

    const tagField = <Col>
        <Form.Group>
            <Form.Label>Tag</Form.Label>
            <InputGroup>
                <Form.Label className='fsz-125 fw-bold'>{tag}</Form.Label>
            </InputGroup>
        </Form.Group>
    </Col>

    const namaBabField = <Col>
        <Form.Group>
            <Form.Label>Bab</Form.Label>
            <InputGroup>
                <Form.Label className='fsz-125 fw-bold'>{namaBab}</Form.Label>
            </InputGroup>
        </Form.Group>
    </Col>

    const secondRowForm = <Row className='mx-0 mb-3'>
        {tagField}
        {namaBabField}
    </Row>

    const skalaNilaiField = <Col>
        <Form.Group>
            <Form.Label>Skala Nilai</Form.Label>
            <InputGroup>
                <Form.Label className='fsz-125 fw-bold'>{skalaNilai}</Form.Label>
            </InputGroup>
        </Form.Group>
    </Col>

    const visibilitasField = <Col>
        <Form.Group>
            <Form.Label>Visibilitas</Form.Label>
            <InputGroup>
                <Form.Label className='fsz-125 fw-bold'>{visibilitas}</Form.Label>
            </InputGroup>
        </Form.Group>
    </Col>

    const thirdRowForm = <Row className='mx-0 mb-3'>
        {skalaNilaiField}
        {visibilitasField}
    </Row>

    return (
        <Modal show={show} onHide={() => setShow(prev => !prev)} centered size='lg'>
            <Modal.Header closeButton>
                <Modal.Title>Detail SubBab</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    {firstRowForm}
                    {secondRowForm}
                    {thirdRowForm}
                </Form>
            </Modal.Body>
        </Modal>
    );
}

export default SubbabPopUpDetail;

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';

export const columns = (editFunction, deleteFunction) => ([
    {
        Header: 'Soal',
        accessor: 'soal',
    },
    {
        Header: 'Jawaban',
        accessor: 'jawaban',
    },
    {
        Header: 'Visibilitas',
        accessor: 'visibilitas',
        Cell: ({ cell: { value } }) => {
            if (value === 'publik') return <div><FontAwesomeIcon icon={faEye} size='xs' /> Publik</div>;
            else return <div><FontAwesomeIcon icon={faEyeSlash} size='xs' /> Sembunyi</div>;
        }
    },
    {
        Header: 'Aksi',
        accessor: 'aksi',
        disableSortBy: true,
        disableFilters: true,
        Cell: (row) => {
            return (
                <>
                    <FontAwesomeIcon icon={faEdit} color="#ffc107" className='me-2'
                        onClick={(e) => editFunction(row.row.original)}
                        style={{ cursor: 'pointer' }}
                    />
                    <FontAwesomeIcon icon={faTrash} color="#dc3545"
                        onClick={(e) => deleteFunction(row.row.original)}
                        style={{ cursor: 'pointer' }}
                    />
                </>
            );
        }
    },
]);

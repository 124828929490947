import { Image } from "react-bootstrap";
import {
    FontAwesomeIcon
} from "@fortawesome/react-fontawesome";

import {
    faEye,
    faEyeSlash,
    faEdit,
    faTrash
} from '@fortawesome/free-solid-svg-icons';

export const columns = (editFunction, deleteFunction) => ([
    {
        Header: "No",
        accessor: "nomor",
        Cell: (row) => {
            return <div>{Number(row.row.id) + 1}</div>;
        },
        disableFilters: true,
        disableSortBy: true,
    },
    {
        Header: 'Judul Kuis',
        accessor: 'judul',
    },
    {
        Header: 'Judul Video',
        accessor: 'judulMateri',
        Cell: (row) => {
            return (
                <div className="kuism-thumbnail-cell">
                    <div className='kuism-thumbnail-image me-2'>
                        <Image fluid src={row.row.original.thumbnail} alt="thumbnail"/>
                        </div>
                    {row.row.original.judulMateri}
                </div>
            );
        }
    },
    {
        Header: 'Visibilitas',
        accessor: 'visibilitas',
        Cell: ({ cell: { value } }) => {
            if (value === 'publik') return <div><FontAwesomeIcon icon={faEye} size='xs' /> Publik</div>;
            else return <div><FontAwesomeIcon icon={faEyeSlash} size='xs' /> Sembunyi</div>;
        }
    },
    {
        Header: 'Aksi',
        accessor: 'aksi',
        disableSortBy: true,
        disableFilters: true,
        Cell: (row) => {
            return (
                <>
                    <FontAwesomeIcon icon={faEdit} color="#ffc107" className='me-2'
                        onClick={(e) => editFunction(row.row.original)}
                        style={{ cursor: 'pointer' }}
                    />
                    <FontAwesomeIcon icon={faTrash} color="#dc3545"
                        onClick={(e) => deleteFunction(row.row.original)}
                        style={{ cursor: 'pointer' }}
                    />
                </>
            );
        }
    },
]);

import React from 'react';
import { Row } from 'react-bootstrap';
import moment from 'moment';
import 'moment/locale/id';
import { default as CatatanThumb } from './CatatanThumb';
import uuid from 'react-uuid';

export default function CatatanDateGrid({ date, data, setShowPopup, setShowPopupDelete, setThumbData }) {
    moment.locale("id");
    return (
        <Row id='catatan-date-grid-container'>
            <div className='catatan-date mt-4'>{date}</div>
            <hr />
            <Row lg={5} className="catatan-grid g-4 mt-0">
                {
                    data.map(item => {
                        return <CatatanThumb setShowPopup={setShowPopup} setShowPopupDelete={setShowPopupDelete} setThumbData={setThumbData} data={item} key={uuid()} />
                    })
                }
            </Row>
        </Row>
    )
}
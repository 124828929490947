import axios from "axios";
import { talent_leaderboardsall_endpoint, talent_leaderboardsweek_endpoint, talent_leaderboardsmonth_endpoint } from "../../app";
import { authHeader } from "./AuthHeader";

export const listLeaderboardsAll = () => {
    return axios({
        url: talent_leaderboardsall_endpoint,
        method: 'GET',
        headers: {'Content-type': 'application/json', ...authHeader()},
    }).then((response) => {
        console.log('List Leaderboards All Result :', response.data);
        return response;
    });
};

export const listLeaderboardsWeekly = ({ weekly }) => {
    return axios({
        url: talent_leaderboardsweek_endpoint,
        method: 'GET',
        headers: {'Content-type': 'application/json', ...authHeader()},
        params: { weekly },
    }).then((response) => {
        console.log('List Leaderboards Weekly Result :', response.data);
        return response;
    });
};

export const listLeaderboardsMonthly = ({ monthly }) => {
    return axios({
        url: talent_leaderboardsmonth_endpoint,
        method: 'GET',
        headers: {'Content-type': 'application/json', ...authHeader()},
        params: { monthly },
    }).then((response) => {
        console.log('List Leaderboards Monthly Result :', response.data);
        return response;
    });
};
import React, { useState, useEffect } from 'react';

import { Button, Col, Container, Form, Row, InputGroup } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { hideErrorAlert, hideSuccessAlert, showErrorAlert, showSuccessAlert } from '../../../app';
import { GetParentPath } from '../../../common';
import { resetPasswordUser, validasiResetPassword } from '../../service/AuthService';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function ResetPasswordForm(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [token, setToken] = useState(searchParams.get('token'));

    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [confirmPasswordError, setConfirmPasswordError] = useState(false);
    const parentPath = GetParentPath();

    useEffect(() => {
        setPasswordError(false);
        setConfirmPasswordError(false);
        dispatch(hideErrorAlert());
    }, [password, confirmPassword, dispatch]);

    const resetPasswordFormHeader = <Container fluid className='text-center'
        style={{ marginBottom: '4rem' }}
    >
        <h4><b>Atur Ulang Kata Sandi</b></h4>
    </Container>

    const passwordField = <Form.Group className='mb-3 w-100 fsz-075' controlId='passwordField'>
        <Form.Label>Kata Sandi Baru :</Form.Label>
        <InputGroup>
            <Form.Control placeholder='Kata Sandi Baru'
                type={showPassword ? 'text' : 'password'}
                className={`${passwordError ? 'login-form-element-error' : 'login-form-element'} border-end-0`}
                value={password}
                onChange={(e) => { setPassword(e.target.value); }}
            />
            <Button variant='light'
                onClick={() => setShowPassword(prev => !prev)}
                className={`${passwordError ? 'login-form-element-error' : 'login-form-element'} border-start-0 bg-white`}
            >
                {showPassword ? <FontAwesomeIcon icon={faEye} color={'#6C757D'} /> :
                    <FontAwesomeIcon icon={faEyeSlash} color={'#6C757D'} />}
            </Button>
        </InputGroup>
    </Form.Group>

    const confirmPasswordField = <Form.Group className='mb-3 w-100 fsz-075' controlId='passwordField'>
        <Form.Label>Konfirmasi  Kata Sandi Baru :</Form.Label>
        <InputGroup>
            <Form.Control placeholder='Konfirmasi Kata Sandi Baru'
                type={showConfirmPassword ? 'text' : 'password'}
                className={`${confirmPasswordError ? 'login-form-element-error' : 'login-form-element'} border-end-0`}
                value={confirmPassword}
                onChange={(e) => { setConfirmPassword(e.target.value); }}
            />
            <Button variant='light'
                onClick={() => setShowConfirmPassword(prev => !prev)}
                className={`${confirmPasswordError ? 'login-form-element-error' : 'login-form-element'} border-start-0 bg-white`}
            >
                {showConfirmPassword ? <FontAwesomeIcon icon={faEye} color={'#6C757D'} /> :
                    <FontAwesomeIcon icon={faEyeSlash} color={'#6C757D'} />}
            </Button>
        </InputGroup>
    </Form.Group>

    const processButton = <Container fluid className='fsz-0875 text-center'>
        <Button variant='primary' type='submit'
            className='px-5' style={{ marginBottom: '7rem' }}
        >
            <b>Proses</b>
        </Button>
    </Container>

    const loginLink = <Container fluid className='text-center p-0 fsz-075'
        style={{ marginBottom: '2.25rem' }}
    >
        Kembali Log In <Link className='login-footer-link text-decoration-none' to={`${parentPath}/login`}>
            <b>di sini</b>
        </Link>
    </Container>

    const aboutLink = <Container fluid className='text-start p-0 fsz-075'>
        <Link className='login-footer-link text-decoration-none' to={`${parentPath}/lupa-sandi`}><b>About</b></Link>
    </Container>

    const contactLink = <Container fluid className='text-end p-0 fsz-075'>
        <Link className='login-footer-link text-decoration-none' to={`${parentPath}/lupa-sandi`}><b>Contact</b></Link>
    </Container>

    const resetPassword = () => {
        resetPasswordUser({ password, token }).then(
            (response) => {
                switch (response.data.status_code) {
                    case 'LGN200':
                        dispatch(showSuccessAlert('Kata Sandi berhasil diubah!'));
                        navigate(`${parentPath}/trainer/login`)
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('Reset password error :', error);
            }).finally((info) => {

            });
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        dispatch(hideErrorAlert());

        if (password === '') {
            setPasswordError(true);
            dispatch(showErrorAlert('Isi Kata Sandi Baru'));
        } else if (confirmPassword === '') {
            setConfirmPasswordError(true);
            dispatch(showErrorAlert('Isi Konfirmasi Kata Sandi Baru'));
        } else if (password !== confirmPassword) {
            setConfirmPasswordError(true);
            dispatch(showErrorAlert('Konfirmasi Kata Sandi Baru salah !'));
        } else {
            resetPassword();
        }
    }

    return (
        <Container className='login-form-container bg-white ms-auto me-0'>
            {resetPasswordFormHeader}
            <Form noValidate
                onSubmit={(e) => handleSubmit(e)}
            >
                {passwordField}
                {confirmPasswordField}
                {processButton}
            </Form>
            {loginLink}
            <Row className='mx-0'>
                <Col className='p-0'>
                    {aboutLink}
                </Col>
                <Col className='p-0'>
                    {contactLink}
                </Col>
            </Row>
        </Container>
    );
}

export default ResetPasswordForm;

import axios from "axios";
import {
    talent_laporanbelajarcekkemampuan_endpoint,
    talent_laporanbelajarkuisbab_endpoint,
    talent_laporanbelajarlatihanpraktik_endpoint,
    talent_laporanbelajarsummary_endpoint,
    talent_laporanbelajarujianpraktik_endpoint
} from "../../app";
import { laporan_belajar_summary, list_laporan_belajar } from "../features/dummy/laporanbelajar";
import { authHeader } from "./AuthHeader";

export const laporanBelajarSummary = () => {
    return axios({
        url: talent_laporanbelajarsummary_endpoint,
        method: 'GET',
        headers: { 'Content-type': 'application/json', ...authHeader() },
    }).then((response) => {
        console.log('Laporan Belajar Summary Result :', response.data);
        return response;
    });
};

export const laporanBelajarKuisBab = ({ idTopik }) => {
    return axios({
        url: talent_laporanbelajarkuisbab_endpoint,
        method: 'GET',
        headers: { 'Content-type': 'application/json', ...authHeader() },
        params: { idTopik },
    }).then((response) => {
        console.log('Laporan Belajar Kuis Bab Result :', response.data);
        return response;
    });
};

export const laporanBelajarCekKemampuan = ({ idTopik }) => {
    return axios({
        url: talent_laporanbelajarcekkemampuan_endpoint,
        method: 'GET',
        headers: { 'Content-type': 'application/json', ...authHeader() },
        params: { idTopik },
    }).then((response) => {
        console.log('Laporan Belajar Cek Kemampuan Result :', response.data);
        return response;
    });
};

export const laporanBelajarLatihanPraktik = ({ idTopik }) => {
    return axios({
        url: talent_laporanbelajarlatihanpraktik_endpoint,
        method: 'GET',
        headers: { 'Content-type': 'application/json', ...authHeader() },
        params: { idTopik },
    }).then((response) => {
        console.log('Laporan Belajar Latihan Praktik Result :', response.data);
        return response;
    });
};

export const laporanBelajarUjianPraktik = ({ idTopik }) => {
    return axios({
        url: talent_laporanbelajarujianpraktik_endpoint,
        method: 'GET',
        headers: { 'Content-type': 'application/json', ...authHeader() },
        params: { idTopik },
    }).then((response) => {
        console.log('Laporan Belajar Ujian Praktik Result :', response.data);
        return response;
    });
};
import './HeroJumbotron.css'
import asset212x from '../../assets/asset-2-1@2x.png';
import { Link } from 'react-scroll';
const HeroJumbotron = () => {
  return (
    <div className="position-relative container-fluid px-0">
      <div className="ellipseParent space-container">
        <div className="instanceChild" />
        <div className="instanceItem" />
        <div className="container space-container position-relative">
          <div className="containerHero row">
            <div className="col-12 col-md-7 my-auto py-5">
              <b className="heading">
                <span>Raih &nbsp;</span>
                <span className="workOpportunities">Kesempatan Bekerja</span>
                <br />
                <span>Melalui Funtastic 79 Bootcamp</span>
              </b>
              <div className="paragraph">
                <span>
                  Funtastic 79 Bootcamp dapat melakukan proses pembelajaran
                  dimanapun dan kapanpun.
                  <br />
                  Raih Kesempatan
                </span>
                <b>&nbsp;bekerja di Padepokan 79, dan Industri lain</b>
                <span> lewat Funtastic 79 Bootcamp.</span>
              </div>
              <div className="d-flex mt-4">
                <Link activeClass="active" to="4" className="btn btn-outline-light me-3 custom-btn" spy={true} smooth={true} offset={-100} duration={500}>
                  Get Started
                </Link>
                {/*<div className="btn btn-outline-light custom-btn">Talk to sales</div> */}
              </div>
            </div>
            <div className="col-12 d-none d-md-block col-md-5 my-auto">
              <img className="asset21" alt="" src={asset212x} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HeroJumbotron

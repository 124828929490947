import axios from "axios";
import { trainer_laporanbelajar_endpoint } from "../../app";
import { authHeader } from "./AuthHeader";

export const listLaporanBelajarTrainer = () => {
    return axios({
        url: trainer_laporanbelajar_endpoint,
        method: 'GET',
        headers: {'Content-type': 'application/json', ...authHeader()},
    }).then((response) => {
        console.log('List Laporan Belajar Result :', response.data);
        return response;
    });
};

export const detailLaporanBelajarTrainer = ({ idUser }) => {
    return axios({
        url: trainer_laporanbelajar_endpoint,
        method: 'GET',
        headers: {'Content-type': 'application/json', ...authHeader()},
        params: { idUser },
    }).then((response) => {
        console.log('Detail Laporan Belajar Result :', response.data);
        return response;
    });
};

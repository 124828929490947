import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { showErrorAlert } from '../../../app';

// styling
import './KuisMateri.css';

// component
import { Table } from '../../common/table';
import { columns } from './columns';
import KuisMateriPopUp from './KuisMateriPopup';
import KuisMateriPopUpDelete from './KuisMateriPopupDelete';
import KuisMateriDetail from './KuisMateriDetail';
import KuisMateriDeleteConfirmation from './KuisMateriDeleteConfirmation';

// API
import { listKuisMateriTrainer } from '../../service/KuisMateriService';
import { listTopikTrainer } from '../../service/TopikService';
import { listSubbabTrainer } from '../../service/SubbabService';
import { listBabTrainer } from '../../service/BabService';
import { listMateriTrainer } from '../../service/MateriService';

// Crumbs
import { pushCrumb, popCrumb } from '../../common/breadcrumbs';

function KuisMateri() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [id, setId] = useState(searchParams.get('id'));

    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const [showPopUp, setShowPopUp] = useState(false);
    const [showPopUpDelete, setShowPopUpDelete] = useState(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [kuisMateriData, setKuisMateriData] = useState();
    const [refreshData, setRefreshData] = useState(1);

    const [topikList, setTopikList] = useState([]);
    const [babList, setBabList] = useState([]);
    const [subBabList, setSubbabList] = useState([]);
    const [materiList, setMateriList] = useState([]);

    useEffect(() => {
        listKuisMateriTrainer().then(
            (response) => {
                switch (response.data.status_code) {
                    case 'KMA200':
                        setData(response.data.message || []);
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('Kuis Materi error :', error);
            }).finally((info) => {

            });
    }, [dispatch, refreshData]);

    useEffect(() => {
        setId(searchParams.get('id'));
        if (searchParams.get('id')) {
            dispatch(pushCrumb("Detail Kuis Materi"));
        } else {
            dispatch(popCrumb("Detail Kuis Materi"));
        }
    }, [searchParams, dispatch]);

    useEffect(() => {
        listTopikTrainer().then(
            (response) => {
                switch (response.data.status_code || []) {
                    case 'TPK200':
                        setTopikList(response.data.message);
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('List Topik error :', error);
            }).finally((info) => {

            });
    }, [dispatch]);

    useEffect(() => {
        listBabTrainer().then(
            (response) => {
                switch (response.data.status_code) {
                    case 'BAB200':
                        setBabList(response.data.message || []);
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('List Bab error :', error);
            }).finally((info) => {

            });
    }, [dispatch]);

    useEffect(() => {
        listSubbabTrainer().then(
            (response) => {
                switch (response.data.status_code) {
                    case 'SBB200':
                        setSubbabList(response.data.message || []);
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('List Subbab error :', error);
            }).finally((info) => {

            });
    }, [dispatch]);

    useEffect(() => {
        listMateriTrainer().then(
            (response) => {
                switch (response.data.status_code) {
                    case 'MAT200':
                        setMateriList(response.data.message || []);
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('List Materi error :', error);
            }).finally((info) => {

            });
    }, [dispatch]);

    const addFunction = () => {
        setKuisMateriData();
        setIsEdit(false);
        setShowPopUp(prev => !prev);
    }

    const editFunction = (row) => {
        setKuisMateriData(row);
        setIsEdit(true);
        setShowPopUp(prev => !prev);
    }

    const deleteFunction = (row) => {
        setKuisMateriData(row);
        setShowPopUpDelete(prev => !prev);
    }

    const detailFunction = (row) => {
        setId(row.id);
        setKuisMateriData(row);
        setSearchParams({ id: row.id });
    }

    return (
        <>
            {!id && <KuisMateriPopUp setRefreshData={setRefreshData}
                show={showPopUp} setShow={setShowPopUp}
                kuisMateriData={kuisMateriData} isEdit={isEdit}
                topikList={topikList}
                babList={babList}
                subBabList={subBabList}
                materiList={materiList}
            />}
            {!id && <KuisMateriPopUpDelete 
                show={showPopUpDelete} setShow={setShowPopUpDelete}
                setShowDeleteConfirmation={setShowDeleteConfirmation}
            />}
            {!id && <KuisMateriDeleteConfirmation setRefreshData={setRefreshData}
                show={showDeleteConfirmation}
                setShow={setShowDeleteConfirmation}
                kuisMateriData={kuisMateriData}
                setShowPopUpDelete={setShowPopUpDelete}
            />}
            {id && <KuisMateriDetail
                setSearchParams={setSearchParams}
                kuisMateriData={data.find(item => item.id === id)}
            />}
            {!id && <Table
                data={data}
                rowClickFunction={detailFunction}
                columns={columns(editFunction, deleteFunction)}
                addFunction={addFunction}
                title='Kuis Materi Digital Bootcamp 79' />
            }
        </>
    );
}

export default KuisMateri;




import React, { useEffect } from 'react';
import { Alert } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-regular-svg-icons';

import { hideSuccessAlert } from '../../app';

function SuccessAlert(props) {
    const dispatch = useDispatch();
    const show = useSelector(state => state.app.showSuccessAlert);
    const message = useSelector(state => state.app.successMessage);

    useEffect(() => {
        const closeTimeout = setTimeout(() => {
            dispatch(hideSuccessAlert());
        }, 5000);
        return function() {
            clearTimeout(closeTimeout);
        }
    });

    return (
        <Alert className='position-fixed bottom-0 end-0 success-alert d-flex align-items-center'
            onClose={(e) => dispatch(hideSuccessAlert())}
            variant='success' show={show}
            style={{zIndex: 1060}}
            dismissible
        >
            <FontAwesomeIcon icon={faCircleCheck} className='fs-4' />
            <div className='fsz-1125 ms-3'>
                <div>Success!</div>
                <div>{message}</div>
            </div>
        </Alert>
    );
}

export default SuccessAlert;

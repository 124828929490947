import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    rangkumanImageList: [],
};

export const RangkumanSlice = createSlice({
    name: 'rangkumanImageList',
    initialState: initialState,
    reducers: {
        // loading
        pushRangkumanImage: (state, action) => {
            state.rangkumanImageList.push(action.payload);
        },
        popRangkumanImage: (state) => {
            state.rangkumanImageList.pop();
        },
        popAllRangkumanImage: (state) => {
            state.rangkumanImageList = [];
        },
        setRangkumanImage: (state, action) => {
            state.rangkumanImageList = action.payload;
        },
    },
});

export const {
    pushRangkumanImage, popRangkumanImage, popAllRangkumanImage, setRangkumanImage,
} = RangkumanSlice.actions;
export default RangkumanSlice.reducer;

import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { hideErrorAlert, showErrorAlert } from "../../../app";
import { loginUser, loginUserGoogle } from "../../service/AuthService";
import { loginAction } from "./AuthSlice";
import { GetParentPath } from "../../../common";

import { GoogleLogin } from "react-google-login";
import { FcGoogle } from "react-icons/fc";
const { version } = require("../../../../package.json");

function LoginForm(props) {
  const dispatch = useDispatch();
  const emailUnconfirmed = "unconfirmed";
  const emailRegistered = "registered";
  const emailNotRegistered = "not-registered";

  const [username, setUsername] = useState("");
  const [usernameError, setUsernameError] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [emailStatus, setEmailStatus] = useState(emailUnconfirmed);
  const [usernameReg, setUsernameReg] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");

  const parentPath = GetParentPath();
  const navigate = useNavigate();
  const location = useLocation();
  const authed = useSelector((state) => state.authTalent.jsonWebToken);
  const from = location.state?.from?.pathname || `${parentPath}/topik`;
  const search = location.state?.from?.search || "";

  useEffect(() => {
    // jika email yang dimasukkan belum terdaftar, navigate ke register page
    if (emailStatus === emailNotRegistered)
      navigate(
        `${parentPath}/register?email=${email}&name=${name}&username=${usernameReg}`
      );
    // jika user ingin mengakses suatu halaman yang butuh otentikasi namun user belum login,
    // user akan diarahkan ke halaman login, dan jika login berhasil user akan diarahkan
    // ke halaman yang sebelumnya ingin diakses
    else if (authed) navigate(`${from}${search}`, { replace: true });
  }, [
    from,
    authed,
    emailStatus,
    navigate,
    search,
    parentPath,
    email,
    name,
    usernameReg,
  ]);

  useEffect(() => {
    setUsernameError(false);
    setPasswordError(false);
  }, [username, password, dispatch]);

  const loginFormHeader = (
    <Container fluid className="p-0 login-form-header">
      <h4>
        <b>Yuk, masuk ke akun Digital Bootcamp 79 Kamu!!</b>
      </h4>
    </Container>
  );

  const usernameField = (
    <Form.Group className="mb-3 w-100 fsz-075" controlId="usernameField">
      <Form.Label>Username/E-mail :</Form.Label>
      <Form.Control
        placeholder="Masukkan Username/E-mail"
        type="text"
        className={`${
          usernameError ? "login-form-element-error" : "login-form-element"
        }`}
        value={username}
        onChange={(e) => {
          setUsername(e.target.value);
        }}
        disabled={emailStatus === emailRegistered}
      />
    </Form.Group>
  );

  const passwordField = (
    <Form.Group className="mb-3 w-100 fsz-075" controlId="passwordField">
      <Form.Label>Kata Sandi :</Form.Label>
      <InputGroup>
        <Form.Control
          placeholder="Masukkan Sandi"
          type={showPassword ? "text" : "password"}
          className={`${
            passwordError ? "login-form-element-error" : "login-form-element"
          } border-end-0`}
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
        />
        <Button
          variant="light"
          onClick={() => setShowPassword((prev) => !prev)}
          className={`${
            passwordError ? "login-form-element-error" : "login-form-element"
          } border-start-0 bg-white`}
        >
          {showPassword ? (
            <FontAwesomeIcon icon={faEye} color={"#6C757D"} />
          ) : (
            <FontAwesomeIcon icon={faEyeSlash} color={"#6C757D"} />
          )}
        </Button>
      </InputGroup>
    </Form.Group>
  );

  const forgotPasswordLink = (
    <Container fluid className="text-end p-0 fsz-075">
      <Link
        className="forgot-password-link text-decoration-none"
        to={`${parentPath}/lupa-sandi?u=${username}`}
      >
        <b>Lupa Kata Sandi</b>
      </Link>
    </Container>
  );

  const loginButton = (
    <Container fluid className="text-center p-0">
      <Button variant="primary" type="submit" className="w-100 login-button">
        <b>Masuk</b>
      </Button>
    </Container>
  );

  const loginOptionText = (
    <div className="wrap">
      <div className="links">
        <div className="bg-white px-2 fsz-075">Atau masuk melalui</div>
      </div>
    </div>
  );

  const loginGoogle = (googleUser) => {
    loginUserGoogle(googleUser.getAuthResponse().id_token)
      .then(
        (response) => {
          switch (response.data.status_code) {
            case "LGN200":
              dispatch(loginAction(response.data.message.jwt));
              break;
            case "LGN201":
              dispatch(
                showErrorAlert(
                  "Email belum diregister, silahkan lakukan registrasi!"
                )
              );
              setEmail(response.data.message.email);
              setName(response.data.message.nama);
              setEmailStatus(emailNotRegistered);
              break;
            default:
              dispatch(showErrorAlert("Terjadi kesalahan pada server!"));
              break;
          }
        },
        (error) => {
          console.log("Auth Google error (from internal) :", error);
        }
      )
      .finally((info) => {});
  };

  const responseGoogleFailed = (googleUser) => {
    console.log("Auth Google error (from Google) :", googleUser);
  };

  const loginGoogleButton = (
    <GoogleLogin
      clientId="228877543664-c3jje34ouafcp348i3pgnd2sj3nk40ad.apps.googleusercontent.com"
      buttonText="Login"
      onSuccess={loginGoogle}
      onFailure={responseGoogleFailed}
      cookiePolicy={"single_host_origin"}
      render={(renderProps) => (
        <Button
          variant="light"
          onClick={renderProps.onClick}
          disabled={renderProps.disabled}
          className="w-100 text-white login-button d-flex align-items-center justify-content-center"
          style={{ backgroundColor: "#5D5FEF" }}
        >
          <FcGoogle className="bg-white rounded-circle login-button-google-logo" />
          <b>Continue with Google</b>
        </Button>
      )}
    />
  );

  const registerLink = (
    <Container fluid className="text-center p-0 fsz-075 register-link">
      Belum punnya akun?{" "}
      <Link
        className="login-footer-link text-decoration-none"
        to={`${parentPath}/register`}
      >
        <b>Daftar di sini</b>
      </Link>
    </Container>
  );

  const aboutLink = (
    <Container fluid className="text-start p-0 fsz-075">
      <Link className="login-footer-link text-decoration-none" to="">
        <b>About</b>
      </Link>
    </Container>
  );

  const contactLink = (
    <Container fluid className="text-end p-0 fsz-075">
      <Link className="login-footer-link text-decoration-none" to="">
        <b>Contact</b>
      </Link>
    </Container>
  );

  const backButton = (
    <Container fluid className="text-center">
      <Button
        variant="light"
        className="p-0 bg-transparent border-0 back-login-button fsz-075"
        onClick={(e) => {
          setEmailStatus(emailUnconfirmed);
          setUsername("");
          setPassword("");
        }}
      >
        &lt;&lt; Kembali
      </Button>
    </Container>
  );

  const login = () => {
    loginUser(username, password)
      .then(
        (response) => {
          switch (response.data.status_code) {
            case "LGN200":
              dispatch(loginAction(response.data.message.jwt));
              break;
            case "LGN201":
              setUsernameError(true);
              if (typeof response.data.message.email === "undefined") {
                dispatch(
                  showErrorAlert(
                    "Username belum diregister, silahkan lakukan registrasi!"
                  )
                );
                setUsernameReg(response.data.message.username);
              } else {
                dispatch(
                  showErrorAlert(
                    "Email belum diregister, silahkan lakukan registrasi!"
                  )
                );
                setEmail(response.data.message.email);
              }
              setEmailStatus(emailNotRegistered);
              break;
            case "LGN401":
              setEmailStatus(emailRegistered);
              break;
            case "LGN402":
              setUsernameError(true);
              setPasswordError(true);
              dispatch(showErrorAlert("E-mail atau kata sandi salah!"));
              break;
            case "LGN404":
              dispatch(
                showErrorAlert("Akun tidak aktif! Silakan hubungi admin!")
              );
              break;
            case "LGN405":
              dispatch(
                showErrorAlert(
                  "User belum terverifikasi! Silahkan cek inbox / spam email anda"
                )
              );
              break;
            default:
              dispatch(showErrorAlert("Terjadi kesalahan pada server!"));
              break;
          }
        },
        (error) => {
          console.log("Auth error :", error);
        }
      )
      .finally((info) => {});
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(hideErrorAlert());

    if (username === "") {
      setUsernameError(true);
      dispatch(showErrorAlert("Isi Username/E-mail!"));
    } else if (password === "" && emailStatus === emailRegistered) {
      setPasswordError(true);
      dispatch(showErrorAlert("Isi kata Sandi!"));
    } else {
      login();
    }
  };

  return (
    <Container
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <div>
        {loginFormHeader}
        <Form noValidate onSubmit={(e) => handleSubmit(e)}>
          {usernameField}
          {emailStatus === emailRegistered && passwordField}
          {emailStatus === emailRegistered && forgotPasswordLink}
          {loginButton}
        </Form>
        {emailStatus === emailUnconfirmed && loginOptionText}
        {emailStatus === emailUnconfirmed && loginGoogleButton}
        {/* {emailStatus === emailUnconfirmed && registerLink} */}
        <Row className="mx-0 login-footer">
          <Col className="p-0">{aboutLink}</Col>
          <Col className="p-0">{contactLink}</Col>
        </Row>
        {emailStatus === emailRegistered && backButton}
      </div>
      <div className="align-self-center">
        <span style={{ fontWeight: 600, fontSize: 14 }}>v {version}</span>
      </div>
      {/* <Row style={{alignSelf: "flex-end"}}>
        <Col style={{ display: "flex", justifyContent: "center" }}>
        </Col>
      </Row> */}
    </Container>
  );
}

export default LoginForm;

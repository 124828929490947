import axios from 'axios';
import { base_url, port_learning, trainer_timeline_endpoint } from '../../app';
import { timeline_list } from '../features/dummy/timeline';
import { authHeader } from './AuthHeader';

export const listTimelineTrainer = ({ idMateri }) => {
    return axios({
        url: trainer_timeline_endpoint,
        method: 'GET',
        headers: { 'Content-type': 'application/json', ...authHeader() },
        params: { idMateri }
    }).then((response) => {
        console.log('List Timeline Result :', response.data);
        return response;
    });
};

export const addTimelineTrainer = ({ judul, timestamp, idMateri }) => {
    return axios({
        url: trainer_timeline_endpoint,
        method: 'POST',
        headers: {
            'Content-type': 'application/json',
            ...authHeader(),
        },
        data: {
            judul, timestamp, idMateri
        },
    }).then((response) => {
        console.log('Add Timeline Result :', response.data);
        return response;
    });
};

export const editTimelineTrainer = ({ judul, timestamp, idTimeline }) => {
    return axios({
        url: trainer_timeline_endpoint,
        method: 'PUT',
        headers: {
            'Content-type': 'application/json',
            ...authHeader(),
        },
        data: {
            judul, timestamp, idTimeline
        },
    }).then((response) => {
        console.log('Edit Timeline Result :', response.data);
        return response;
    });
};

export const deleteTimelineTrainer = ({ id }) => {
    return axios({
        url: trainer_timeline_endpoint,
        method: 'DELETE',
        headers: {
            'Content-type': 'application/json',
            ...authHeader(),
        },
        data: {
            id,
        },
    }).then((response) => {
        console.log('Delete Timeline Result :', response.data);
        return response;
    });
};

import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { showErrorAlert } from "../../../app";

// styling
import "./Praktik.css";

// component
import { Table } from "../../common/table";
import { columns } from "./columns";
import PraktikPopUp from "./PraktikPopup";
import PraktikPopUpDelete from "./PraktikPopupDelete";

// API
import { listSoalPraktikTrainer } from "../../service/SoalPraktikService";
import { listTopikTrainer } from "../../service/TopikService";
import { listSubbabTrainer } from "../../service/SubbabService";
import { listBabTrainer } from "../../service/BabService";

// Crumbs
import { pushCrumb, popCrumb } from "../../common/breadcrumbs";
import { listLanguageTrainer } from "../../service/LanguageService";

function Praktik() {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [id, setId] = useState(searchParams.get("id"));

  const [data, setData] = useState([]);
  const [showPopUp, setShowPopUp] = useState(false);
  const [showPopUpDelete, setShowPopUpDelete] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [praktikData, setPraktikData] = useState();
  const [refreshData, setRefreshData] = useState(1);

  const [topikList, setTopikList] = useState([]);
  const [languageList, setLanguageList] = useState([]);

  useEffect(() => {
    listSoalPraktikTrainer()
      .then(
        (response) => {
          switch (response.data.status_code) {
            case "SPK200":
              setData(response.data.message || []);
              break;
            default:
              dispatch(showErrorAlert("Terjadi kesalahan pada server!"));
              break;
          }
        },
        (error) => {
          console.log("Soal Praktik error :", error);
        }
      )
      .finally((info) => {});
  }, [dispatch, refreshData]);

  useEffect(() => {
    setId(searchParams.get("id"));
    if (searchParams.get("id")) {
      dispatch(pushCrumb("Detail Kuis"));
    } else {
      dispatch(popCrumb("Detail Kuis"));
    }
  }, [searchParams, dispatch]);

  useEffect(() => {
    listTopikTrainer()
      .then(
        (response) => {
          switch (response.data.status_code || []) {
            case "TPK200":
              setTopikList(response.data.message);
              break;
            default:
              dispatch(showErrorAlert("Terjadi kesalahan pada server!"));
              break;
          }
        },
        (error) => {
          console.log("List Topik error :", error);
        }
      )
      .finally((info) => {});
  }, [dispatch]);

  useEffect(() => {
    listLanguageTrainer()
      .then(
        (response) => {
          switch (response.status || []) {
            case 200:
              const arr = response.data?.map((language) => ({
                value: language.id,
                label: language.name,
              }));
              setLanguageList(arr);
              break;
            default:
              dispatch(showErrorAlert("Terjadi kesalahan pada server!"));
              break;
          }
        },
        (error) => {
          console.log("List Language error :", error);
        }
      )
      .finally((info) => {});
  }, [dispatch]);

  const addFunction = () => {
    setPraktikData();
    setIsEdit(false);
    setShowPopUp((prev) => !prev);
  };

  const editFunction = (row) => {
    setPraktikData(row);
    setIsEdit(true);
    setShowPopUp((prev) => !prev);
  };

  const deleteFunction = (row) => {
    setPraktikData(row);
    setShowPopUpDelete((prev) => !prev);
  };

  return (
    <>
      {!id && (
        <PraktikPopUp
          setRefreshData={setRefreshData}
          show={showPopUp}
          setShow={setShowPopUp}
          praktikData={praktikData}
          isEdit={isEdit}
          topikList={topikList}
          languageList={languageList}
        />
      )}
      {!id && (
        <PraktikPopUpDelete
          show={showPopUpDelete}
          setShow={setShowPopUpDelete}
          setRefreshData={setRefreshData}
          praktikData={praktikData}
        />
      )}
      {!id && (
        <Table
          data={data}
          columns={columns(editFunction, deleteFunction)}
          addFunction={addFunction}
          title="Soal Praktik Digital Bootcamp 79"
        />
      )}
    </>
  );
}

export default Praktik;

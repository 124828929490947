import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { showErrorAlert } from '../../../app';
import { Table } from '../../common/table';
import { columns } from './columns';
import KuisCekKemampuanPopUp from './KuisCekKemampuanPopUp';
import KuisCekKemampuanPopUpDelete from './KuisCekKemampuanPopUpDelete';
import KuisCekKemampuanDetail from './KuisCekKemampuanDetail';
import KuisCekKemampuanDeleteConfirmation from './KuisCekKemampuanDeleteConfirmation';
import { listKuisCekKemampuanTrainer } from '../../service/KuisCekKemampuanService';
import { listTopikTrainer } from '../../service/TopikService';
import { pushCrumb, popCrumb } from '../../common/breadcrumbs';

function KuisCekKemampuan() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [id, setId] = useState(searchParams.get('id'));

    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const [showPopUp, setShowPopUp] = useState(false);
    const [showPopUpDelete, setShowPopUpDelete] = useState(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [kuisCekKemampuanData, setKuisCekKemampuanData] = useState();
    const [refreshData, setRefreshData] = useState(1);

    const [topikList, setTopikList] = useState([]);

    useEffect(() => {
        listKuisCekKemampuanTrainer().then(
            (response) => {
                switch (response.data.status_code) {
                    case 'KCK200':
                        setData(response.data.message || []);
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('Kuis Cek Kemampuan error :', error);
            }).finally((info) => {

            });
    }, [dispatch, refreshData]);

    useEffect(() => {
        setId(searchParams.get('id'));
        if (searchParams.get('id')) {
            dispatch(pushCrumb("Detail Kuis"));
        } else {
            dispatch(popCrumb("Detail Kuis"));
        }
    }, [searchParams]);

    useEffect(() => {
        listTopikTrainer().then(
            (response) => {
                switch (response.data.status_code || []) {
                    case 'TPK200':
                        setTopikList(response.data.message);
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('List Topik error :', error);
            }).finally((info) => {

            });
    }, [dispatch]);

    const addFunction = () => {
        setKuisCekKemampuanData();
        setIsEdit(false);
        setShowPopUp(prev => !prev);
    }

    const editFunction = (row) => {
        setKuisCekKemampuanData(row);
        setIsEdit(true);
        setShowPopUp(prev => !prev);
    }

    const deleteFunction = (row) => {
        setKuisCekKemampuanData(row);
        setShowPopUpDelete(prev => !prev);
    }

    const detailFunction = (row) => {
        setId(row.id);
        setKuisCekKemampuanData(row);
        setSearchParams({ id: row.id });
    }

    return (
        <>
            {!id && <KuisCekKemampuanPopUp setRefreshData={setRefreshData}
                show={showPopUp} setShow={setShowPopUp}
                kuisCekKemampuanData={kuisCekKemampuanData} isEdit={isEdit}
                topikList={topikList}
            />}
            {!id && <KuisCekKemampuanPopUpDelete 
                show={showPopUpDelete} setShow={setShowPopUpDelete}
                kuisCekKemampuanData={kuisCekKemampuanData}
                setShowDeleteConfirmation={setShowDeleteConfirmation}
            />}
            {!id && <KuisCekKemampuanDeleteConfirmation 
                setRefreshData={setRefreshData}
                show={showDeleteConfirmation}
                setShow={setShowDeleteConfirmation}
                kuisCekKemampuanData={kuisCekKemampuanData}
                setShowPopUpDelete={setShowPopUpDelete}
            />}
            {id && <KuisCekKemampuanDetail
                setSearchParams={setSearchParams}
                kuisCekKemampuanData={data.find(item => item.id === id)}
            />}
            {!id && <Table
                data={data}
                rowClickFunction={detailFunction}
                columns={columns(editFunction, deleteFunction)}
                addFunction={addFunction}
                title='Kuis Cek Kemampuan Digital Bootcamp 79' />
            }
        </>
    );
}

export default KuisCekKemampuan;




import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import TopikCard from './TopikCard';

function TopikCardGrid({ topikList }) {
    return (
        <Container fluid>
            <Row className='g-4 m-0'>
                {topikList.map(topik => (
                    <Col className='p-0 ms-0 me-4 mt-0 mb-4' key={topik.id}
                        style={{ width: '18rem', height: '26rem', flex: '0 0 auto' }}
                    >
                        <TopikCard topikData={topik} />
                    </Col>
                ))}
            </Row>
        </Container>
    );
}

export default TopikCardGrid;

import React from 'react';
import { Button, Image, Modal, Stack } from 'react-bootstrap';

function MateriPopUpDelete({ show, setShow, setShowDeleteConfirmation }) {
    return (
        <Modal show={show} onHide={() => setShow(prev => !prev)} centered>
            <Modal.Header className='border-bottom-0' closeButton>
            </Modal.Header>
            <Modal.Body className='text-center fsz-1125'>
                <Stack>
                    <Image src='/img/error_outline.png'
                        className='mx-auto mb-4' style={{ width: '5rem' }} />
                    Apakah anda yakin akan menghapus video materi?
                </Stack>
            </Modal.Body>
            <Modal.Footer className='d-flex justify-content-evenly'>
                <Button variant='light' onClick={() => setShow(prev => !prev)}
                    style={{ color: '#0078D7', border: '1px solid #0078D7', width: '9rem' }}
                >
                    Tidak
                </Button>
                <Button variant='primary'
                    onClick={() => {
                        setShow(false);
                        setShowDeleteConfirmation(true);
                        }
                    }
                    style={{ width: '9rem' }}
                >
                    Ya
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default MateriPopUpDelete;

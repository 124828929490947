import React from 'react';
import { Button, Col, Container, Form, Image, Row } from 'react-bootstrap';
import { MdClear } from 'react-icons/md';

export default function ReplyBubble({
    author: { idAuthor, name, level, isTalent, isAuthor },
    komentar: { idKomentar, datetime, message, replyTo },
    setNewReplyTo,
}) {
    const authorNameText = <Col className='d-flex align-items-center'
        style={{ color: '#0078D7' }}
    >
        Membalas {name}
    </Col>

    const messageText = <Col className='text-ellipsis-y'
        style={{ color: '#263238' }}
    >
        {message}
    </Col>

    return (
        <Container fluid className='p-0 mb-1'>
            <Row>
                <Col>
                    <Container fluid className='p-2 fsz-075'
                        style={{
                            backgroundColor: '#F0F0F0',
                            borderRadius: '8px',
                            borderLeft: '8px solid #0078D7'
                        }}
                    >
                        <Row className='text-black mb-1 m-0'>
                            {authorNameText}
                        </Row>
                        <Row className='fw-light m-0'>
                            {messageText}
                        </Row>
                    </Container>
                </Col>
                <Col className='col-auto d-flex align-items-center'>
                    <Button variant='light'
                        className='bg-transparent p-0 border-0'
                        style={{ width: '2rem', height: '2rem' }}
                        onClick={() => setNewReplyTo('')}
                    >
                        <MdClear color='#757575' style={{ width: '1rem', height: '1rem' }} />
                    </Button>
                </Col>
            </Row>
        </Container>
    );
}

import React from 'react';
import { Container, Image } from 'react-bootstrap';

function Banner(props) {
    const logo = <Image src='/img/logotujuhsembilan-mod.png'
        className='login-logo'
        style={{ height: '10vh' }}
    />

    const title = <h1 className='fw-bold text-white'>
        Digital Bootcamp 79
    </h1>

    return (
        <Container>
            {logo}
            {title}
        </Container>
    );
}

export default Banner;

import React from 'react';
import { Button, Col, Container, Image, Placeholder, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { GetParentPath } from '../../../common';

export default function KuisAkhirOpening({
    subbab, setStartKuis, setChoosenAnswers, kuis, listSoalKuis, loading,
}) {
    const parentPath = GetParentPath();
    const kuisExist = (kuis?.id && listSoalKuis?.length);

    const jenisKuis = <div className='text-center fsz-225 mb-3'>
        Kuis Akhir
    </div>

    const namaSubbab = <div className='text-center fs-4 mb-4'>
        {subbab?.nama}
    </div>

    const judulKuis = <div className='text-center fss-1125 mb-4'>
        {kuis?.judul}
    </div>

    const hadiahText = <div className='text-center fsz-1125 mb-2'>
        Hadiah Test
    </div>

    const coinReward = <Row>
        <Col className='col-auto p-0 d-flex align-items-center'>
            <Image src='/img/coin.png' style={{ width: '1.125rem' }} />
        </Col>
        <Col className='col-auto p-0 ms-2'>{kuis?.coin || 0}</Col>
        <Col className='col-auto p-0 ms-auto'>Coin</Col>
    </Row>

    const xpReward = <Row>
        <Col className='col-auto p-0 d-flex align-items-center'>
            <Image src='/img/xp.png' style={{ width: '1.125rem' }} />
        </Col>
        <Col className='col-auto p-0 ms-2'>{kuis?.exp || 0}</Col>
        <Col className='col-auto p-0 ms-auto'>XP</Col>
    </Row>

    const rewardText = <div className={`${kuisExist ? '' : 'd-none'}`}>
        {hadiahText}
        <div>
            {coinReward}
            {xpReward}
        </div>
    </div>

    const kuisNotAvailableText = <div className={`${kuisExist ? 'd-none' : ''} fs-4 fw-bold`}>
        Subbab ini tidak memiliki kuis akhir
    </div>

    const backButton = <Col className='col-auto me-auto'>
        <Link to={`${parentPath}/subbab?idBab=${subbab.idBab}`}>
            <Button variant='outline-primary' className='kuis-button kuis-back-button'>
                Kembali
            </Button>
        </Link>
    </Col>

    const nextButton = <Col className='col-auto'>
        <Button variant='primary' className='kuis-button'
            onClick={() => { setStartKuis(true); setChoosenAnswers({}) }}
            hidden={!kuisExist}
        >
            Mulai Test
        </Button>
    </Col>

    const loadingSkeleteon = <Col
        className='px-0 pb-5 col-auto mx-auto'
        style={{ color: '#263238' }}
    >
        <Container fluid className='fsz-225 mb-3'>
            <Placeholder animation='glow' xs={6}>
                <Placeholder xs={12} />
            </Placeholder>
        </Container>
        <Container
            className='bg-white d-flex align-items-center justify-content-center flex-column'
            style={{
                borderRadius: '8px',
                width: '37rem', height: '20rem',
            }}
        >
            <Placeholder animation='glow' xs={6} className='fs-4 mb-4'>
                <Placeholder xs={12} />
            </Placeholder>
            <Placeholder animation='glow' xs={6} className='fsz-1125 mb-4'>
                <Placeholder xs={12} />
            </Placeholder>
            <Placeholder animation='glow' xs={6} className='fsz-1125 mb-2'>
                <Placeholder xs={12} />
            </Placeholder>
            <Placeholder animation='glow' xs={6} className='fsz-1125'>
                <Placeholder xs={12} />
            </Placeholder>
            <Placeholder animation='glow' xs={6} className='fsz-1125'>
                <Placeholder xs={12} />
            </Placeholder>
        </Container>
        <Row className='mt-4 justify-content-between'>
            <Col className='col-4'>
                <Placeholder animation='glow' xs={6} className='fsz-1125'>
                    <Placeholder.Button variant='primary' xs={12} />
                </Placeholder>
            </Col>
            <Col className='col-4'>
                <Placeholder animation='glow' xs={6} className='fsz-1125'>
                    <Placeholder.Button variant='primary' xs={12} />
                </Placeholder>
            </Col>
        </Row>
    </Col>

    const realComponent = <Col
        className='px-0 pb-5 col-auto mx-auto'
        style={{ color: '#263238' }}
    >
        {jenisKuis}
        <Container
            className='bg-white d-flex align-items-center justify-content-center flex-column'
            style={{
                borderRadius: '8px',
                width: '37rem', height: '20rem',
            }}
        >
            {namaSubbab}
            {judulKuis}
            {rewardText}
            {kuisNotAvailableText}
        </Container>
        <Row className='mt-4'>
            {backButton}
            {nextButton}
        </Row>
    </Col>

    return (
        <Row style={{ marginTop: '15rem' }}>
            {loading ? loadingSkeleteon : realComponent}
        </Row>
    );
}

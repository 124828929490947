import React, { useEffect, useState } from 'react';
import { Button, Row, Col, Form, InputGroup, Modal, ModalBody, CloseButton, Image } from 'react-bootstrap';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useDispatch } from 'react-redux';
import { editScreenshotTrainer } from '../../service/ScreenshotService';
import { hideErrorAlert, hideSuccessAlert, showErrorAlert, showSuccessAlert } from '../../../app';
import { GetParentPath } from '../../../common';
import { Link } from 'react-router-dom';
import moment from 'moment';

export default function ScreenshotPopup({ show, setShow, data, setRefreshData }) {
    moment.locale('id');
    const dispatch = useDispatch();
    const [id, setId] = useState();
    const [editMode, setEditMode] = useState(false);
    const [catatan, setCatatan] = useState('');
    const [idSubbab, setIdSubbab] = useState('');
    const [idVideoScreenshot, setIdVideoScreenshot] = useState('');
    const parentPath = GetParentPath();

    useEffect(() => {
        setId(data?.idScreenshot || '');
        setCatatan(data?.catatan || '');
        setIdSubbab(data?.idSubbab || '');
        setIdVideoScreenshot(data?.idVideoScreenshot || '');
    }, [show, data]);

    const editCatatan = () => {
        editScreenshotTrainer({ id, catatan }).then(
            (response) => {
                switch (response.data.status_code) {
                    case 'SCS200':
                        dispatch(showSuccessAlert('Screenshot berhasil diubah'));
                        setRefreshData(prev => prev + 1);
                        setShow(prev => !prev);
                        setEditMode(false);
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('Edit Screenshot error :', error);
            }).finally((info) => {

            });
    }

    const handleSubmitLihatVideo = (editMode) => {
        dispatch(hideErrorAlert());
        dispatch(hideSuccessAlert());
        editCatatan();
    }

    const handleClose = () => {
        setShow(prev => !prev);
        setEditMode(false);
    }

    const catatanField = (
        <Col lg={12}>
            <span className='txt-popup-title'>Catatan</span><br />
            <Form.Control name='catatan'
                value={catatan}
                onChange={(e) => { setCatatan(e.target.value) }}
                as='textarea'
                placeholder="Catatan"
                disabled={editMode ? false : true}
                maxLength={2500}
            />
            <p className='text-end m-0'>{catatan.length}/2500 Karakter</p>
        </Col>
    )

    const editButton = <Button variant='light' onClick={() => setEditMode(!editMode)}
        style={{ color: '#0078D7', border: '1px solid #0078D7', width: '9rem' }}
    >
        {editMode ? "Kembali" : "Edit"}
    </Button>

    const saveButton = <Button variant='primary'
        onClick={() => handleSubmitLihatVideo(editMode)}
        style={{ width: '9rem' }}
    >
        Simpan
    </Button>

    const toVideoButton = <Link to={`${parentPath}/materi?idSubbab=${idSubbab}&idMateri=${idVideoScreenshot}`}>
        <Button variant='primary'
            style={{ width: '9rem' }}
        >
            Lihat Video
        </Button>
    </Link>

    return (
        <Modal className='modal-screenshot' show={show} onHide={handleClose} centered size='lg'>
            <Form>
                <ModalBody className='modal-body-screenshot'>
                    <Row>
                        <Col>
                            <Image src={data.gambarScreenshot} />
                            <CloseButton className='close-button' variant='white' onClick={handleClose} />
                        </Col>
                    </Row>
                    <Row style={{ padding: 16 }}>
                        <Col lg={12}>
                            <span className='txt-popup-title'>Judul Bab</span><br />
                            <span className='txt-popup-content'>{data.namaBab}</span>
                        </Col>
                        <Col lg={6}>
                            <span className='txt-popup-title'>Judul Sub Bab</span><br />
                            <span className='txt-popup-content'>{data.namaSubbab}</span>
                        </Col>
                        <Col lg={6}>
                            <span className='txt-popup-title'>Video Materi</span><br />
                            <span className='txt-popup-content'>{data.judulVideoMateri}</span>
                        </Col>
                        <Col lg={6}>
                            <span className='txt-popup-title'>Tanggal</span><br />
                            <span className='txt-popup-content'>{moment.utc(data.waktuScreenshot).format("DD MMMM yyyy HH:mm")}</span>
                        </Col>
                        <Col lg={6}>
                            <span className='txt-popup-title'>Timestamp</span><br />
                            <span className='txt-popup-content'>{data.timestampScreenshot}</span>
                        </Col>
                        {catatanField}
                    </Row>
                </ModalBody>
                <Modal.Footer className="border-0">
                    {editButton}
                    {editMode ? saveButton : toVideoButton}
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

import React, { useEffect, useState } from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import KuisBabNavigation from './KuisBabNavigation';
import SoalKuisBab from './SoalKuisBab';
import { LaporSoal } from '../../common/lapor-soal';
import { laporkanSoalBabTalent } from '../../service/SoalKuisBabService';

export default function KuisBabMain(props) {
    const [idSoal, setIdSoal] = useState();
    const [showLapor, setShowLapor] = useState(false);

    return (
        <>
            {idSoal && <LaporSoal laporSoalTrainer={laporkanSoalBabTalent} idSoal={idSoal} show={showLapor} setShow={setShowLapor}/>}
            <Row className=''
                style={{ marginTop: '10rem' }}
            >
                <Col lg={3} className='position-fixed ms-5'>
                    <KuisBabNavigation {...props} />
                </Col>
                <Col lg={{ span: 9, offset: 3 }} className='px-0 pb-5'>
                    <SoalKuisBab setIdSoal={setIdSoal} setShowLapor={setShowLapor} {...props} />
                </Col>
            </Row>
        </>
    );
}

import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { showErrorAlert, hideErrorAlert, hideSuccessAlert, showSuccessAlert } from '../../../app';

import { Button, Form, Modal, Row, Col, InputGroup } from 'react-bootstrap';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//Service
import { changePassword } from '../../service/ProfileService';

function ChangePassPopup({ show, setShow, setShowProfilPopUp }) {
    const dispatch = useDispatch();

    const [oldPassword, setOldPassword] = useState('');
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [showPasswordNew, setShowNewPassword] = useState(false);
    const [newPasswordConfirmation, setNewPasswordConfirmation] = useState('');
    const [showNewPasswordConfirm, setShowNewPasswordConfirm] = useState(false);

    useEffect(() => {
        setOldPassword('');
        setShowOldPassword(false);
        setNewPassword('');
        setShowNewPassword(false);
        setNewPasswordConfirmation('');
        setShowNewPasswordConfirm(false);
    }, [show]);

    const firstRow = <Row className='mx-0 mb-4 justify-content-md-center'>
        <Col lg='auto' className='d-grid align-content-center'>
            <h4> Ubah Kata Sandi</h4>
        </Col>
    </Row>

    const passwordOldField = <Col>
        <Form.Group>
            <Form.Label>Kata Sandi Lama</Form.Label>
            <InputGroup>
                <Form.Control placeholder='Kata Sandi Lama'
                    type={showOldPassword ? 'text' : 'password'}
                    className={`login-form-element border-end-0`}
                    value={oldPassword}
                    onChange={(e) => { setOldPassword(e.target.value); }}
                />
                <Button variant='light'
                    onClick={() => setShowOldPassword(prev => !prev)}
                    className={`login-form-element border-start-0 bg-white`}
                >
                    {showOldPassword ? <FontAwesomeIcon icon={faEye} color={'#6C757D'} /> :
                        <FontAwesomeIcon icon={faEyeSlash} color={'#6C757D'} />}
                </Button>
            </InputGroup>
        </Form.Group>
    </Col>

    const secondRow = <Row className='mx-0 mb-4'>
        {passwordOldField}
    </Row>

    const passwordNewField = <Col>
        <Form.Group>
            <Form.Label>Kata Sandi Baru</Form.Label>
            <InputGroup>
                <Form.Control placeholder='Kata Sandi Baru'
                    type={showPasswordNew ? 'text' : 'password'}
                    className={`login-form-element border-end-0`}
                    value={newPassword}
                    onChange={(e) => { setNewPassword(e.target.value); }}
                />
                <Button variant='light'
                    onClick={() => setShowNewPassword(prev => !prev)}
                    className={`login-form-element border-start-0 bg-white`}
                >
                    {showPasswordNew ? <FontAwesomeIcon icon={faEye} color={'#6C757D'} /> :
                        <FontAwesomeIcon icon={faEyeSlash} color={'#6C757D'} />}
                </Button>
            </InputGroup>
        </Form.Group>
    </Col>

    const thirdRow = <Row className='mx-0 mb-4'>
        {passwordNewField}
    </Row>

    const passwordConfirmField = <Col>
        <Form.Group>
            <Form.Label>Konfirmasi Kata Sandi Baru</Form.Label>
            <InputGroup>
                <Form.Control placeholder='Konfirmasi Kata Sandi Baru'
                    type={showNewPasswordConfirm ? 'text' : 'password'}
                    className={`login-form-element border-end-0`}
                    value={newPasswordConfirmation}
                    onChange={(e) => { setNewPasswordConfirmation(e.target.value); }}
                />
                <Button variant='light'
                    onClick={() => setShowNewPasswordConfirm(prev => !prev)}
                    className={`login-form-element border-start-0 bg-white`}
                >
                    {showNewPasswordConfirm ? <FontAwesomeIcon icon={faEye} color={'#6C757D'} /> :
                        <FontAwesomeIcon icon={faEyeSlash} color={'#6C757D'} />}
                </Button>
            </InputGroup>
        </Form.Group>
    </Col>

    const fourthRow = <Row className='mx-0 mb-4'>
        {passwordConfirmField}
    </Row>

    const fifthRow = <Row className='mx-0 mb-3'>
        <Col className='d-flex flex-row justify-content-between'>
            <Button onClick={() => {
                setShow(false);
                setShowProfilPopUp(true);
            }}
                variant="outline-primary" className='button-foot'>Batal</Button>
            <Button type='submit' variant="primary" className='button-foot'>Simpan</Button>
        </Col>
    </Row>

    const changePass = () => {
        changePassword({oldPassword, newPassword, newPasswordConfirmation}).then(
            (response) => {
                switch (response.data.status_code) {
                    case 'TAL200':
                        dispatch(showSuccessAlert('Password berhasil diubah'));
                        setShow(false);
                        setShowProfilPopUp(true);
                        break;
                    default:
                        dispatch(showErrorAlert(response.data.message));
                        break;
                }
            },
            (error) => {
                console.log('Change Pass error :', error);
            }).finally((info) => {

            });
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        dispatch(hideErrorAlert());
        dispatch(hideSuccessAlert());
        if (!oldPassword) {
            dispatch(showErrorAlert('Isi kata sandi sebelumnya!'));
        } else if (!newPassword) {
            dispatch(showErrorAlert('Isi kata sandi baru!'));
        } else if (!newPasswordConfirmation) {
            dispatch(showErrorAlert('Isi konfirmasi kata sandi baru!'));
        } else if (newPassword !== newPasswordConfirmation) {
            dispatch(showErrorAlert('Konfirmasi kata sandi salah !'));
        } else {
            changePass();
        }
    }


    return (
        <Modal show={show} onHide={() => setShow(prev => !prev)} centered>
            <Modal.Header closeButton className='border-bottom-0'>
            </Modal.Header>
            <Modal.Body className='password-modal-body'>
                <Form onSubmit={(e) => handleSubmit(e)}>
                    {firstRow}
                    {secondRow}
                    {thirdRow}
                    {fourthRow}
                    {fifthRow}
                </Form>
            </Modal.Body>
        </Modal>
    );
}

export default ChangePassPopup;

import axios from "axios";
import {
  trainer_allTalentCity_endpoint,
  trainer_allTalentInstitution_endpoint,
  trainer_allTalentProvince_endpoint,
  trainer_groupinstitution_endpoint,
  trainer_groupinstitutionpercentage_endpoint,
  trainer_progressbelajar_endpoint,
  trainer_reportbelajar_endpoint,
  trainer_statistikpendaftaran_endpoint,
  trainer_totaltalentarutalaclass_endpoint,
  trainer_totaltalentjoin_endpoint,
  trainer_totaltalentjointoday_endpoint,
  trainer_totaltalentkolambesar_endpoint,
  trainer_totaltalentmengerjakanmoodle_endpoint,
  trainer_totaltalentpasstechnologyclass_endpoint,
  trainer_totaltalenttidaklogin_endpoint
} from "../../app";
import { authHeader } from "./AuthHeader";

export const getTotalTalentJoin = (filter) => {
  return axios({
    url: `${trainer_totaltalentjoin_endpoint}/${filter}`,
    method: "GET",
    headers: { "Content-type": "application/json", ...authHeader() },
  }).then((response) => {
    return response;
  });
};

export const getTotalTalentJoinToday = () => {
  return axios({
    url: trainer_totaltalentjointoday_endpoint,
    method: "GET",
    headers: { "Content-type": "application/json", ...authHeader() },
  }).then((response) => {
    return response;
  });
};

export const getTotalTalentTidakLogin = () => {
  return axios({
    url: trainer_totaltalenttidaklogin_endpoint,
    method: "GET",
    headers: { "Content-type": "application/json", ...authHeader() },
  }).then((response) => {
    return response;
  });
};

export const getAllInstitution = () => {
  return axios({
    url: trainer_allTalentInstitution_endpoint,
    method: "GET",
    headers: { "Content-type": "application/json", ...authHeader() },
  }).then((response) => {
    return response;
  });
};

export const getTotalTalentAllCity = () => {
  return axios({
    url: trainer_allTalentCity_endpoint,
    method: "GET",
    headers: { "Content-type": "application/json", ...authHeader() },
  }).then((response) => {
    return response;
  });
};

export const getTotalTalentAllProvince = () => {
  return axios({
    url: trainer_allTalentProvince_endpoint,
    method: "GET",
    headers: { "Content-type": "application/json", ...authHeader() },
  }).then((response) => {
    return response;
  });
};

export const getTotalTalentKelasArutala = () => {
  return axios({
    url: trainer_totaltalentarutalaclass_endpoint,
    method: "GET",
    headers: { "Content-type": "application/json", ...authHeader() },
  }).then((response) => {
    return response;
  });
};

export const getTotalTalentLolosKelasTeknologi = () => {
  return axios({
    url: trainer_totaltalentpasstechnologyclass_endpoint,
    method: "GET",
    headers: { "Content-type": "application/json", ...authHeader() },
  }).then((response) => {
    return response;
  });
};

export const getTotalTalentKolamBesar = () => {
  return axios({
    url: trainer_totaltalentkolambesar_endpoint,
    method: "GET",
    headers: { "Content-type": "application/json", ...authHeader() },
  }).then((response) => {
    return response;
  });
};

export const getTotalTalentMengerjakanMoodle = () => {
  return axios({
    url: trainer_totaltalentmengerjakanmoodle_endpoint,
    method: "GET",
    headers: { "Content-type": "application/json", ...authHeader() },
  }).then((response) => {
    return response;
  });
};

export const getReportBelajar = ({ keywordSearch }) => {
  return axios({
    url: trainer_reportbelajar_endpoint,
    method: "GET",
    headers: { "Content-type": "application/json", ...authHeader() },
    params: {
      keywordSearch,
    },
  }).then((response) => {
    return response;
  });
};

export const getAllReportBelajar = ({ keywordSearch }) => {
  return axios({
    url: trainer_reportbelajar_endpoint + "/all",
    method: "GET",
    headers: { "Content-type": "application/json", ...authHeader() },
    params: {
      keywordSearch,
    },
  }).then((response) => {
    return response;
  });
};

export const exportReportBelajar = ({ keywordSearch }) => {
  return axios({
    url: trainer_reportbelajar_endpoint + "/export",
    method: "GET",
    headers: { "Content-type": "application/json", ...authHeader() },
    params: {
      keywordSearch,
    },
    responseType: "blob",
  }).then((response) => {
    return response;
  });
};

export const getProgressBelajar = ({ keywordSearch }) => {
  return axios({
    url: trainer_progressbelajar_endpoint,
    method: "GET",
    headers: { "Content-type": "application/json", ...authHeader() },
    params: {
      keywordSearch,
    },
  }).then((response) => {
    return response;
  });
};

export const getAllProgressBelajar = ({ keywordSearch }) => {
  return axios({
    url: trainer_progressbelajar_endpoint + "/all",
    method: "GET",
    headers: { "Content-type": "application/json", ...authHeader() },
    params: {
      keywordSearch,
    },
  }).then((response) => {
    return response;
  });
};

export const exportProgressBelajar = ({ keywordSearch }) => {
  return axios({
    url: trainer_progressbelajar_endpoint + "/export",
    method: "GET",
    headers: { "Content-type": "application/json", ...authHeader() },
    params: {
      keywordSearch,
    },
    responseType: "blob",
  }).then((response) => {
    return response;
  });
};

export const getStatistikPendaftaran = (filter) => {
  return axios({
    url: `${trainer_statistikpendaftaran_endpoint}/${filter}`,
    method: "GET",
    headers: { "Content-type": "application/json", ...authHeader() },
  }).then((response) => {
    return response;
  });
};

export const getGroupInstitution = (filter) => {
  return axios({
    url: `${trainer_groupinstitution_endpoint}/${filter}`,
    method: "GET",
    headers: { "Content-type": "application/json", ...authHeader() },
  }).then((response) => {
    return response;
  });
};

export const getGroupInstitutionPercentage = (filter) => {
  return axios({
    url: `${trainer_groupinstitutionpercentage_endpoint}/${filter}`,
    method: "GET",
    headers: { "Content-type": "application/json", ...authHeader() },
  }).then((response) => {
    return response;
  });
};

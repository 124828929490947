import axios from 'axios';
import { trainer_komentar_endpoint } from '../../app';
import { authHeader } from './AuthHeader';

export const listKomentarTrainer = ({ page, idMateri }) => {
    return axios({
        url: trainer_komentar_endpoint,
        method: 'GET',
        headers: { 'Content-type': 'application/json', ...authHeader() },
        params: { idMateri, page, limit: 5 }
    }).then((response) => {
        console.log('List Komentar Trainer Result :', response.data);
        return response;
    });
};

export const addKomentarTrainer = ({ idMateri, komentar: { message, replyTo } }) => {
    return axios({
        url: trainer_komentar_endpoint,
        method: 'POST',
        headers: { 'Content-type': 'application/json', ...authHeader() },
        data: { idMateri, komentar: { message, replyTo } }
    }).then((response) => {
        console.log('Add Komentar Trainer Result :', response.data);
        return response;
    });
};


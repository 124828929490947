import axios from "axios";
import { talent_soalpraktik_endpoint, trainer_soalpraktik_endpoint } from "../../app";
import { authHeader } from "./AuthHeader";

export const getSoalPraktikByTopik = ({ idTopik }) => {
    return axios({
        url: talent_soalpraktik_endpoint + "/",
        method: 'GET',
        headers: { 'Content-type': 'application/json', ...authHeader() },
        params: {
            idTopik
        },
    }).then((response) => {
        console.log('Get Soal Praktik By Topik result :', response.data);
        return response;
    });
};

export const jawabSoalPraktik = ({ idSoal, jawaban }) => {
    console.log(idSoal + " - " + jawaban)
    return axios({
        url: talent_soalpraktik_endpoint,
        method: 'POST',
        headers: { 'Content-type': 'application/json', ...authHeader() },
        data: {
            idSoalPraktik: idSoal, jawaban
        },
    }).then((response) => {
        console.log('Jawab Soal Praktik Result :', response.data);
        return response;
    });
};

export const getSoalPraktikById = ({ idSoal }) => {
    console.log("Id soal di service adalah" + idSoal);
    return axios({
        url: talent_soalpraktik_endpoint,
        method: 'GET',
        headers: { 'Content-type': 'application/json', ...authHeader() },
        params: {
            id: idSoal
        },
    }).then((response) => {
        console.log('Get Soal Praktik By Id result :', response.data);
        return response;
    });
};
import './TheMentors.css'
import andrianto from '../../assets/img-0003-r-1transformed@2x.png';
import hendra from '../../assets/image-102@2x.png';
import hilman from '../../assets/image-103@2x.png';
import alif from '../../assets/image-104@2x.png';
import aldy from '../../assets/aldy-picturetransformed@2x.png';
const TheMentors = ({ refContainer }) => {
  return (
    <div
      ref={refContainer}
      className="position-relative container-fluid space-container"
      id="3"
    >
      <div className="container position-relative">
        <div className="row mb-5">
          <div className="col-12 text-center">
            <h2 className="fw-bold mb-2">The Mentors Funtastic 79 Bootcamp</h2>
            <div className="paragraph">
              <span>
                Nikmati beragam keuntungan istimewa yang akan diperoleh dari
                Funtastic 79 Bootcamp.
              </span>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          {listMentors.map((material) => (
            <div className="col-12 col-md-6 col-lg mb-4" key={material.id}>

              <div className="card" >
              <div className="card-header card-mentor-header">
                  <div
                    className="card-mentor-bg"
                    style={{ background: material.color }}
                  />
                  <img className="w-100 img-person" src={material.img} />
                </div>
                <div className="card-body" style={{ height: "180px" }}>
                <div className="d-flex mx-auto mt-2">
                  <div className="d-flex flex-wrap text-center">
                    <div className="card-title-benefit w-100">
                      {material.name}
                    </div>
                    <div className="card-exp mx-auto">{material.exp}</div>
                  </div>
                </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

const listMentors = [
  {
    id: 1,
    img: andrianto,
    name: 'Andrianto Nur',
    exp: 'Senior Software Developer & Expertise di Backend dan SQL',
    color: '#ff3548',
  },
  {
    id: 2,
    img: hendra,
    name: 'Hendra Yudiana',
    exp: 'Senior Mentor yang telaten di Digital Bootcamp 79',
    color: '#01c851',
  },
  {
    id: 3,
    img: hilman,
    name: 'Hilman Fahmi',
    exp: 'Mentor Muda, Sabar, Penuh Semangat dan Fast Response di Digital Bootcamp 79',
    color: '#8b77f0',
  },
  {
    id: 4,
    img: alif,
    name: 'Alif Pratomo',
    exp: 'Mentor Berpengalaman, Expertise dalam Mengembangkan Hardskill & Softskill Talent',
    color: '#3396e7',
  },
  {
    id: 5,
    img: aldy,
    name: 'Aldy Akbarrizky',
    exp: 'Mentor Muda, Full Stack Developer, Fast Learner',
    color: '#ffc451',
  },
]

export default TheMentors

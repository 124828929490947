import React, { useState, useEffect } from 'react';

import { faEye, faEyeSlash, faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Modal, Form, Row, Col } from 'react-bootstrap';

import Select from 'react-select';
import { useDispatch } from 'react-redux';
import {
    showErrorAlert, hideErrorAlert,
    showSuccessAlert, hideSuccessAlert,
} from '../../../app';

import { addSoalPraktikTrainer, editSoalPraktikTrainer } from '../../service/SoalPraktikService';

function PraktikPopUp({ show, setShow, praktikData, isEdit, setRefreshData, topikList: topikListData, languageList }) {
    console.log(praktikData);
    const dispatch = useDispatch();

    const [idSoalPraktik, setIdSoalPraktik] = useState();
    const [judul, setJudul] = useState();
    const [jenis, setJenis] = useState();
    const [deskripsi, setDeskripsi] = useState();
    const [idTopik, setIdTopik] = useState();
    const [visibilitas, setVisibilitas] = useState();
    const [idLanguage, setIdLanguage] = useState();
    const [timeLimit, setTimeLimit] = useState();
    const [memoryLimit, setMemoryLimit] = useState();
    const [expectedOutput, setExpectedOutput] = useState();

    const [visibilitasOptions, setVisibilitasOptions] = useState([
        {
            value: 'sembunyi', label: <span>
                <FontAwesomeIcon icon={faEyeSlash} color={'#263238'} className='me-2' />Sembunyi</span>
        },
        {
            value: 'publik', label: <span>
                <FontAwesomeIcon icon={faEye} color={'#263238'} className='me-2' />Publik</span>
        },
    ]);

    const [jenisSoalOptions, setJenisSoalOptions] = useState([
        {
            value: 'latihan', label: <span>Latihan</span>
        },
        {
            value: 'ujian', label: <span>Ujian</span>
        },
    ]);

    useEffect(() => {
        setIdSoalPraktik(praktikData?.Id || '');
        setJudul(praktikData?.Judul || '');
        setJenis(praktikData?.Jenis || '');
        setDeskripsi(praktikData?.Deskripsi || '');
        setIdTopik(praktikData?.IdTopik || '');
        setVisibilitas(praktikData?.Visibilitas || '');
        setIdLanguage(praktikData?.IdLanguage || '');
        setTimeLimit(praktikData?.TimeLimit || '');
        setMemoryLimit(praktikData?.MemoryLimit || '');
        setExpectedOutput(praktikData?.ExpectedOutput || '');
    }, [show, praktikData]);

    const topikList = topikListData;

    const topikOptions = topikList?.map(item => ({ value: item.id, label: item.judul }));
    const idLanguageOptions = languageList;

    const topikSelected = topikOptions?.find(item => item.value === idTopik) || null;
    const visibilitasSelected = visibilitasOptions?.find(item => item.value === visibilitas) || null;
    const jenisSoalSelected = jenisSoalOptions?.find(item => item.value === jenis) || null;
    const idLanguageSelected = idLanguageOptions?.find(item => item.value === idLanguage) || null;

    const handleTopikChange = ({ value }) => {
        setIdTopik(value);
    }

    const handleVisibilitasChange = (value) => {
        setVisibilitas(value.value);
    }

    const handleJenisSoalChange = (value) => {
        setJenis(value.value);
    }

    const handleIdLanguageChange = (value) => {
        setIdLanguage(value.value);
    }

    const judulSoalField = <Col>
        <Form.Group>
            <Form.Label>Judul Soal <b className='asterisk'>*</b></Form.Label>
            <Form.Control placeholder='Judul soal'
                value={judul}
                onChange={(e) => { setJudul(e.target.value) }}
            />
        </Form.Group>
    </Col>

    const judulTopikField = <Col>
        <Form.Group>
            <Form.Label>Judul/Topik <b className='asterisk'>*</b></Form.Label>
            <Select value={topikSelected}
                noOptionsMessage={() => 'No options'}
                placeholder='Judul/Topik'
                options={topikOptions}
                onChange={handleTopikChange}
            />
        </Form.Group>
    </Col>

    const firstRowForm = <Row className='mx-0 mb-3'>
        {judulSoalField}
        {judulTopikField}
    </Row>

    const jenisSoalField = <Col>
        <Form.Group>
            <Form.Label>Jenis Soal <b className='asterisk'>*</b></Form.Label>
            <Select value={jenisSoalSelected}
                noOptionsMessage={() => 'No options'}
                placeholder='Jenis Soal'
                options={jenisSoalOptions}
                onChange={handleJenisSoalChange}
            />
        </Form.Group>
    </Col>
    const visibilitasField = <Col>
        <Form.Group>
            <Form.Label>Visibilitas <b className='asterisk'>*</b></Form.Label>
            <Select value={visibilitasSelected}
                noOptionsMessage={() => 'No options'}
                placeholder='Visibilitas'
                options={visibilitasOptions}
                onChange={handleVisibilitasChange}
            />
        </Form.Group>
    </Col>

    const secondRowForm = <Row className='mx-0 mb-3'>
        {jenisSoalField}{visibilitasField}
    </Row>

    const deskripsiSoalField = <Col>
        <Form.Group>
            <Form.Label>Deskripsi Soal <b className='asterisk'>*</b></Form.Label>
            <Form.Control placeholder='Deskripsi Soal'
                value={deskripsi}
                onChange={(e) => { setDeskripsi(e.target.value) }}
                as="textarea"
            />
        </Form.Group>
    </Col>

    const thirdRowForm = <Row className='mx-0 mb-3'>
        {deskripsiSoalField}
    </Row>
    const idLanguageField = <Col>
        <Form.Group>
            <Form.Label>Bahasa<b className='asterisk'>*</b></Form.Label>
            <Select value={idLanguageSelected}
                noOptionsMessage={() => 'No options'}
                placeholder='Bahasa'
                options={idLanguageOptions}
                onChange={handleIdLanguageChange}
            />
        </Form.Group>
    </Col>

    const timeLimitField = <Col>
        <Form.Group>
            <Form.Label>Time Limit<b className='asterisk'>*</b></Form.Label>
            <Form.Control placeholder='Time Limit'
                value={timeLimit}
                onChange={(e) => { setTimeLimit(e.target.value) }}
                type="number"
            />
        </Form.Group>
    </Col>

    const memoryLimitField = <Col>
        <Form.Group>
            <Form.Label>Memory Limit<b className='asterisk'>*</b></Form.Label>
            <Form.Control placeholder='Memory Limit'
                value={memoryLimit}
                onChange={(e) => { setMemoryLimit(e.target.value) }}
                type="number"
                min={2048}
            />
        </Form.Group>
    </Col>

    const fourthRowForm = <Row className='mx-0 mb-3'>
        {idLanguageField}
        {timeLimitField}
        {memoryLimitField}
    </Row>

    const expectedOutputField = <Col>
        <Form.Group>
            <Form.Label>Expected Output<b className='asterisk'>*</b></Form.Label>
            <Form.Control placeholder='Expected Output'
                as='textarea'
                value={expectedOutput}
                onChange={(e) => { setExpectedOutput(e.target.value) }}
            />
        </Form.Group>
    </Col>

    const fifthRowForm = <Row className='mx-0 mb-3'>
        {expectedOutputField}
    </Row>


    const addPraktik = () => {
        addSoalPraktikTrainer({
            idTopik, judul, jenis, deskripsi, visibilitas, idLanguage, timeLimit, memoryLimit, expectedOutput
        }).then(
            (response) => {
                switch (response.data.status_code) {
                    case 'SPK200':
                        dispatch(showSuccessAlert('Soal Praktik berhasil dibuat'));
                        setRefreshData(prev => prev + 1);
                        setShow(prev => !prev);
                        break;
                    case 'SPK407':
                        jenis === 'ujian'
                            ? dispatch(showErrorAlert('Hanya bisa membuat satu ujian praktik per topik!'))
                            : dispatch(showErrorAlert('Hanya bisa membuat satu latihan praktik per topik!'));
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('Add Soal Praktik error :', error);
            }).finally((info) => {

            });
    }

    const editPraktik = () => {
        editSoalPraktikTrainer({
            idSoalPraktik, idTopik, judul, jenis, deskripsi, visibilitas, idLanguage, timeLimit, memoryLimit, expectedOutput
        }).then(
            (response) => {
                switch (response.data.status_code) {
                    case 'SPK200':
                        dispatch(showSuccessAlert('Soal Praktik berhasil diubah'));
                        setRefreshData(prev => prev + 1);
                        setShow(prev => !prev);
                        break;
                    case 'SPK407':
                        jenis === 'ujian'
                            ? dispatch(showErrorAlert('Hanya bisa membuat satu ujian praktik per topik!'))
                            : dispatch(showErrorAlert('Hanya bisa membuat satu latihan praktik per topik!'));
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('Edit Soal Praktik error :', error);
            }).finally((info) => {

            });
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        dispatch(hideErrorAlert());
        dispatch(hideSuccessAlert());

        if (!judul) {
            dispatch(showErrorAlert('Isi judul soal praktik !'));
        } else if (!idTopik) {
            dispatch(showErrorAlert('Pilih judul topik soal !'));
        } else if (!visibilitas) {
            dispatch(showErrorAlert('Pilih visibilitas!'));
        } else if (!jenis) {
            dispatch(showErrorAlert('Pilih jenis soal!'));
        } else if (!deskripsi) {
            dispatch(showErrorAlert('Isi deskripsi soal!'));
        } else if (!idLanguage) {
            dispatch(showErrorAlert('Pilih bahasa!'));
        } else if (timeLimit > 15) {
            dispatch(showErrorAlert('Nilai time limit tidak boleh lebih dari 15 detik!'));
        } else if (!timeLimit) {
            dispatch(showErrorAlert('Isi time limit!'));
        } else if (!memoryLimit) {
            dispatch(showErrorAlert('Isi memory limit!'));
        } else if (!expectedOutput) {
            dispatch(showErrorAlert('Isi expected output!'));
        } else {
            if (isEdit) editPraktik();
            else addPraktik();
        }
    }

    return (
        <Modal show={show} onHide={() => setShow(prev => !prev)} centered size='lg'>
            <Modal.Header closeButton>
                <Modal.Title>{isEdit ? 'Edit Soal Praktik' : 'Tambah Soal Praktik'}</Modal.Title>
            </Modal.Header>
            <Form onSubmit={(e) => handleSubmit(e)}>
                <Modal.Body>

                    {firstRowForm}
                    {secondRowForm}
                    {thirdRowForm}
                    {fourthRowForm}
                    {fifthRowForm}

                </Modal.Body>
                <Modal.Footer>
                    <Button variant='light' onClick={() => setShow(prev => !prev)}
                        style={{ color: '#0078D7', border: '1px solid #0078D7' }}>
                        Batal
                    </Button>
                    <Button type='submit' variant='primary'>
                        Simpan
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}

export default PraktikPopUp;

import { faEye, faEyeSlash, faInfo, faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Modal, Form, Row, Col, OverlayTrigger, Tooltip, Stack } from 'react-bootstrap';

import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import { useDispatch } from 'react-redux';
import {
    showErrorAlert, hideErrorAlert,
    showSuccessAlert, hideSuccessAlert,
} from '../../../app';

import { addMateriTrainer, editMateriTrainer } from '../../service/MateriService';
import ReactPlayer from 'react-player';

function MateriPopUp({ show, setShow, materiData, isEdit,
    topikList, babList, subbabList, tagList, setRefreshData
}) {
    const dispatch = useDispatch();

    const [id, setId] = useState();
    const [judul, setJudul] = useState();
    const [idTopik, setIdTopik] = useState();
    const [judulTopik, setJudulTopik] = useState();
    const [idBab, setIdBab] = useState();
    const [namaBab, setNamaBab] = useState();
    const [idSubbab, setIdSubbab] = useState();
    const [namaSubbab, setNamaSubbab] = useState();
    const [video, setVideo] = useState();
    const [thumbnail, setThumbnail] = useState();
    const [deskripsi, SetDeskripsi] = useState();
    const [skalaNilai, setSkalaNilai] = useState();
    const [tag, setTag] = useState();
    const [visibilitas, setVisibilitas] = useState();
    const [durasi, setDurasi] = useState();
    const [selectedFile, setSelectedFile] = useState('');
    const [isSelected, setIsSelected] = useState(false);

    useEffect(() => {
        setId(materiData?.id || '');
        setJudul(materiData?.judul || '');
        setIdTopik(materiData?.idTopik || '');
        setJudulTopik(materiData?.judulTopik || '');
        setIdBab(materiData?.idBab || '');
        setNamaBab(materiData?.namaBab || '');
        setIdSubbab(materiData?.idSubbab || '');
        setNamaSubbab(materiData?.namaSubbab || '');
        setVideo(materiData?.video || '');
        setThumbnail(materiData?.thumbnail || '');
        SetDeskripsi(materiData?.deskripsi || '');
        setSkalaNilai(materiData?.skalaNilai || '');
        setTag(materiData?.tag || '');
        setVisibilitas(materiData?.visibilitas || 'sembunyi');
        setDurasi(materiData?.durasi || 0);
        setSelectedFile('');
    }, [show, materiData]);

    const videoPlayerRef = useRef(null);
    const videoPlayer = <ReactPlayer url={video} ref={videoPlayerRef} className='d-none' />

    useEffect(() => {
        let interval = setInterval(() => {
            if (videoPlayerRef?.current)
                setDurasi(Math.ceil(videoPlayerRef.current.getDuration()) || Math.ceil(materiData?.durasi) || 0);
        }, 1000);
        return function removeInterval() {
            clearInterval(interval);
        }
    });

    const topikOptions = topikList.map(item => ({ value: item.id, label: item.judul }));
    const babOptions = babList.filter(item => item.idTopik === idTopik)
        .map(item => ({ value: item.id, label: item.nama }))
    const subbabOptions = subbabList.filter(item => item.idBab === idBab)
        .map(item => ({ value: item.id, label: item.nama }))
    const tagOptions = tagList.map(item => ({ value: item, label: item }));

    const visibilitasOptions = [{
        value: 'sembunyi', label: <span>
            <FontAwesomeIcon icon={faEyeSlash} color={'#263238'} className='me-2' />Sembunyi</span>
    }, {
        value: 'publik', label: <span>
            <FontAwesomeIcon icon={faEye} color={'#263238'} className='me-2' />Publik</span>
    }];

    const topikSelected = topikOptions.find(item => item.value === idTopik) || null;
    const babSelected = babOptions.find(item => item.value === idBab) || null;
    const subbabSelected = subbabOptions.find(item => item.value === idSubbab) || null;
    const visibilitasSelected = visibilitasOptions.find(item => item.value === visibilitas) || null;
    const tagSelected = tag ? tag?.split(',')
        .map(item => ({ value: item.trim(), label: item.trim() })) : null;

    const handleTopikChange = ({ value, label }) => {
        setIdTopik(value);
        setJudulTopik(label);
        setIdBab();
        setNamaBab();
    }

    const handleBabChange = ({ value, label }) => {
        setIdBab(value);
        setNamaBab(label);
        setIdSubbab();
        setNamaSubbab();
    }

    const handleSubbabChange = ({ value, label }) => {
        setIdSubbab(value);
        setNamaSubbab(label);
    }

    const isOnlyLetterAndSpace = (str) => {
        const re = new RegExp(/^[A-Za-z\s]*$/);
        return re.test(str);
    }

    const handleTagChange = (tags) => {
        for (const tag of tags) {
            if (!isOnlyLetterAndSpace(tag.value)) {
                dispatch(showErrorAlert('Tag hanya boleh mengandung huruf dan spasi!'));
                return;
            }
        }
        setTag(tags.map(tag => tag.value).join(', '));
    }

    const handleVisibilitasChange = (value) => {
        setVisibilitas(value.value);
    }

    const judulField = <Col>
        <Form.Group>
            <Form.Label>Judul Video <b className='asterisk'>*</b></Form.Label>
            <Form.Control placeholder='Judul Video' value={judul}
                onChange={(e) => setJudul(e.target.value)}
            />
        </Form.Group>
    </Col>

    const judulTopikField = <Col>
        <Form.Group>
            <Form.Label>Judul/Topik Materi <b className='asterisk'>*</b></Form.Label>
            <Select value={topikSelected}
                noOptionsMessage={() => 'No options'}
                placeholder='Judul/Topik Materi'
                options={topikOptions}
                onChange={handleTopikChange}
            />
        </Form.Group>
    </Col>

    const firstRowForm = <Row className='mx-0 mb-3'>
        {judulField}
        {judulTopikField}
    </Row>

    const thumbnailField = <Col className='col-6'>
        <Form.Group controlId='thumbnail-field'>
            <Form.Label>Thumbnail Video <b className='asterisk'>*</b></Form.Label>
            <Form.Control type='file' className='d-none' accept='.png, .jpg, .jpeg'
                onChange={(e) => {
                    setSelectedFile(e.target.files[0])
                    setIsSelected(true);
                }}
            />
            <Row>
                <Col className='col-3 pe-0'>
                    <Button as={Form.Label} className='rounded w-100'>
                        <FontAwesomeIcon icon={faUpload} className='me-2' />Upload
                    </Button>
                </Col>
                <Col className='col-9 d-flex align-items-center'>
                    <Form.Label className='text-ellipsis'>
                        {isSelected
                            ? (
                                <Form.Label className='text-ellipsis mb-0' style={{ maxWidth: "100%" }}>{selectedFile?.name}</Form.Label>
                            )
                            : isEdit
                                ? (
                                    <Form.Label className='text-ellipsis mb-0' style={{ maxWidth: "100%" }}>{
                                        <a href={thumbnail} target='_blank' rel='noreferrer'>
                                            {thumbnail?.split(/(\\|\/)/g).pop()}
                                        </a>
                                    }
                                    </Form.Label>
                                )
                                : (
                                    <span>Pilih file untuk diupload</span>
                                )
                        }
                    </Form.Label>
                </Col>
            </Row>
        </Form.Group>
    </Col>

    const namaBabField = <Col>
        <Form.Group>
            <Form.Label>Bab <b className='asterisk'>*</b></Form.Label>
            <Select value={babSelected}
                noOptionsMessage={() => 'No options'}
                placeholder='Bab'
                options={babOptions}
                onChange={handleBabChange}
                isDisabled={judulTopik ? false : true}
            />
        </Form.Group>
    </Col>

    const secondRowForm = <Row className='mx-0 mb-3'>
        {thumbnailField}
        {namaBabField}
    </Row>

    const videoField = <Col>
        <Form.Group>
            <Form.Label>Video Materi <b className='asterisk'>*</b></Form.Label>
            <Form.Control placeholder='URL Video Materi' value={video}
                onChange={(e) => setVideo(e.target.value)}
            />
        </Form.Group>
    </Col>

    const namaSubbabField = <Col>
        <Form.Group>
            <Form.Label>SubBab <b className='asterisk'>*</b></Form.Label>
            <Select value={subbabSelected}
                noOptionsMessage={() => 'No options'}
                placeholder='SubBab'
                options={subbabOptions}
                onChange={handleSubbabChange}
                isDisabled={namaBab ? false : true}
            />
        </Form.Group>
    </Col>

    const thirdRowForm = <Row className='mx-0 mb-3'>
        {videoField}
        {namaSubbabField}
    </Row>

    const deskripsiField = <Col>
        <Form.Group>
            <Form.Label>Deskripsi Video <b className='asterisk'>*</b></Form.Label>
            <Form.Control as={'textarea'} placeholder='Deskripsi' value={deskripsi}
                onChange={(e) => SetDeskripsi(e.target.value)}
            />
        </Form.Group>
    </Col>

    const visibilitasField = <Form.Group className='mx-0 mb-3'>
        <Form.Label>Visibilitas <b className='asterisk'>*</b></Form.Label>
        <Select value={visibilitasSelected}
            noOptionsMessage={() => 'No options'}
            placeholder='Visibilitas'
            options={visibilitasOptions}
            onChange={handleVisibilitasChange}
        />
    </Form.Group>

    const tagTooltip = <OverlayTrigger
        trigger='hover'
        placement='top'
        overlay={
            <Tooltip className='tag-tooltip'>
                Mohon diisi minimal 1 tag
            </Tooltip>
        }
    >
        <Button variant='light'
            className='tag-tooltip-button bg-transparent p-0 ms-2 rounded-circle
                border border-2 border-dark d-flex align-items-center justify-content-center'
            style={{ width: '1rem', height: '1rem' }}
        >
            <FontAwesomeIcon icon={faInfo} style={{ fontSize: '0.625rem' }} />
        </Button>
    </OverlayTrigger>

    const tagField = <Form.Group className='mx-0 mb-3'>
        <Stack direction='horizontal' className='mb-2'>
            <Form.Label className='mb-0'>Tag <b className='asterisk'>*</b></Form.Label>
            {tagTooltip}
        </Stack>
        <CreatableSelect isMulti isClearable={false}
            value={tagSelected}
            noOptionsMessage={() => 'No options'}
            placeholder='Tag'
            options={tagOptions}
            formatCreateLabel={(newTag) => `Buat tag '${newTag}'`}
            onChange={handleTagChange}
            getNewOptionData={(value, label) => {
                return {
                    value: value.toUpperCase(),
                    label: label.replace(`${value}`, value.toUpperCase())
                }
            }}
        />
    </Form.Group>

    const skalaNilaiField = <Form.Group className='mx-0 mb-3'>
        <Form.Label>Skala Nilai <b className='asterisk'>*</b></Form.Label>
        <Form.Control placeholder='Skala Nilai' value={skalaNilai}
            onChange={(e) => setSkalaNilai(e.target.value)}
            type='number'
        />
    </Form.Group>

    const fourthRowForm = <Row className='mx-0 mb-3'>
        {deskripsiField}
        <Col>
            {visibilitasField}
            {tagField}
            {skalaNilaiField}
        </Col>
    </Row>

    const addMateri = () => {
        addMateriTrainer({
            judul, idTopik, idBab, idSubbab, thumbnail: selectedFile,
            video, deskripsi, tag, skalaNilai, visibilitas, durasi
        }).then(
            (response) => {
                switch (response.data.status_code) {
                    case 'MAT200':
                        dispatch(showSuccessAlert('Video materi berhasil dibuat'));
                        setRefreshData(prev => prev + 1);
                        setShow(prev => !prev);
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('Add Materi error :', error);
            }).finally((info) => {

            });
    }

    const editMateri = () => {
        editMateriTrainer({
            id, judul, idTopik, idBab, idSubbab, thumbnail: selectedFile,
            video, deskripsi, tag, skalaNilai, visibilitas, durasi
        }).then(
            (response) => {
                switch (response.data.status_code) {
                    case 'MAT200':
                        dispatch(showSuccessAlert('Video materi berhasil diubah'));
                        setRefreshData(prev => prev + 1);
                        setShow(prev => !prev);
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('Edit Materi error :', error);
            }).finally((info) => {

            });
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        dispatch(hideErrorAlert());
        dispatch(hideSuccessAlert());

        if (!judul) {
            dispatch(showErrorAlert('Isi judul video materi!'));
        } else if (!selectedFile && !isEdit) {
            dispatch(showErrorAlert('Pilih thumbnail video materi!'));
        } else if (selectedFile && selectedFile?.name &&
            !['png', 'PNG', 'jpg', 'JPG', 'jpeg', 'JPEG'].includes(selectedFile?.name?.split('.').pop())) {
            dispatch(showErrorAlert('Thumbnail harus berekstensi .png, .jpg atau .jpeg!'));
        } else if (selectedFile && selectedFile?.size > 1048576 * 2) {
            dispatch(showErrorAlert('Ukuran thumbnail maksimal 2MB!'));
        } else if (!video) {
            dispatch(showErrorAlert('Isi URL video materi!'));
        } else if (!deskripsi) {
            dispatch(showErrorAlert('Isi deskripsi video materi!'));
        } else if (!idTopik || !judulTopik) {
            dispatch(showErrorAlert('Pilih judul/topik materi!'));
        } else if (!idBab || !namaBab) {
            dispatch(showErrorAlert('Pilih bab!'));
        } else if (!idSubbab || !namaSubbab) {
            dispatch(showErrorAlert('Pilih subbab!'));
        } else if (!visibilitas) {
            dispatch(showErrorAlert('Pilih visibilitas!'));
        } else if (!tag) {
            dispatch(showErrorAlert('Isi tag (minimal 1 tag)!'));
        } else if (!skalaNilai) {
            dispatch(showErrorAlert('Isi skala nilai!'));
        } else {
            if (isEdit) editMateri();
            else addMateri();
        }
    }

    return (
        <Modal show={show} onHide={() => setShow(prev => !prev)} centered size='xl'>
            <Modal.Header closeButton>
                <Modal.Title>{isEdit ? 'Edit Video Materi' : 'Buat Video Materi Baru'}</Modal.Title>
            </Modal.Header>
            <Form onSubmit={(e) => handleSubmit(e)}>
                <Modal.Body>
                    {videoPlayer}
                    {firstRowForm}
                    {secondRowForm}
                    {thirdRowForm}
                    {fourthRowForm}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='light' onClick={() => setShow(prev => !prev)}
                        style={{ color: '#0078D7', border: '1px solid #0078D7' }}
                    >
                        Batal
                    </Button>
                    <Button type='submit' variant='primary'>
                        Simpan
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}

export default MateriPopUp;

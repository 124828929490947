import axios from 'axios';
import { talent_resetpass_endpoint } from '../../app';

export const validasiResetSandi = ({token}) => {
    return axios({
        url: talent_resetpass_endpoint,
        method: 'GET',
        headers: { 'Content-type': 'application/json' },
        params: { token }
    }).then((response) => {
        console.log('Validasi Reset Sandi Result :', response.data);
        return response;
    });
};

export const resetSandi = ({password, token}) => {
    return axios({
        url: talent_resetpass_endpoint,
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
        data: { password: password },
        params: { token }
    }).then((response) => {
        console.log('Reset Sandi Result :', response.data);
        return response;
    });
};
import axios from "axios";
import { talent_subbab_endpoint } from "../../app";
import { authHeader } from "./AuthHeader";
import { subbab_list } from '../features/dummy/subbab';

export const listSubbabTalent = ({ idBab }) => {
    return axios({
        url: talent_subbab_endpoint,
        method: 'GET',
        headers: {'Content-type': 'application/json', ...authHeader()},
        params: { idBab }
    }).then((response) => {
        console.log('List Subbab Talent Result :', response.data);
        return response;
    });
};

export const detailSubbabTalent = ({ id }) => {
    return axios({
        url: talent_subbab_endpoint,
        method: 'GET',
        headers: {'Content-type': 'application/json', ...authHeader()},
        params: { id }
    }).then((response) => {
        console.log('Detail Subbab Talent Result :', response.data);
        return response;
    });
};

export const terakhirDipelajariSubbabTalent = ({ id, terakhirDipelajari }) => {
    return axios({
        url: talent_subbab_endpoint,
        method: 'POST',
        headers: { 'Content-type': 'application/json', ...authHeader() },
        data: {
            id, terakhirDipelajari
        },
    }).then((response) => {
        console.log('Add Subbab Terakhir Dipelajari Result :', response.data);
        return response;
    });
};

export const getSubbabTalent = ({ id }) => {
    return axios({
        url: `${talent_subbab_endpoint}/${id}`,
        method: 'GET',
        headers: { 'Content-type': 'application/json', ...authHeader() },
    }).then((response) => {
        console.log('Get Subbab Result :', response.data);
        return response;
    });
};

import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

export default function DoughnutChart({ filter, datas, bgColor }) {
  const dispatch = useDispatch();
  const initialData = {
    labels: [],
    datasets: [
      {
        label: "",
        data: [],
      },
    ],
  };
  const [data, setData] = useState(initialData);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: "left",
        labels: {
          boxWidth: 12,
        },
      },
      title: {
        display: false,
        text: "Dashboard",
      },

      tooltip: {
        enabled: false,
        position: "average",
        yAlign: "top",
        xAlign: "right",
      },
    },
    elements: {
      arc: {
        borderWidth: 0,
        borderRadius: 0,
      },
    },
  };

  useEffect(() => {
    let arr = datas;
    setData({
      labels: arr.map((d) => d.label),
      datasets: [
        {
          label: "",
          data: arr.map((d) => d.total),
          backgroundColor: bgColor.map((d) => d),
        },
      ],
    });
  }, [filter, datas]);

  return <Doughnut options={options} data={data} />;
}

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { showErrorAlert } from '../../../app';
import { faEye, faEyeSlash, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Form, Row, Col, InputGroup, Button } from 'react-bootstrap';
import { Table } from '../../common/table';
import { columns } from './detailcolumns';
import { listSoalKuisCekKemampuanTrainer } from '../../service/SoalKuisCekKemampuanService';
import SoalKuisCekKemampuanPopUp from './SoalKuisCekKemampuanPopup';
import SoalKuisCekKemampuanPopUpDelete from './SoalKuisCekKemampuanPopupDelete';
import SoalKuisCekKemampuanDeleteConfirmation from './SoalKuisCekKemampuanDeleteConfirmation';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

function KuisCekKemampuanDetail({ kuisCekKemampuanData, setSearchParams }) {

    const [idParams, setIdParams] = useSearchParams();
    const [id, setId] = useState(idParams.get('id'));
    const [judul, setJudul] = useState();
    const [idTopik, setIdTopik] = useState();
    const [judulTopik, setJudulTopik] = useState();
    const [visibilitas, setVisibilitas] = useState('');

    useEffect(() => {
        setId(kuisCekKemampuanData?.id || '');
        setJudul(kuisCekKemampuanData?.judul || '');
        setIdTopik(kuisCekKemampuanData?.idTopik || '');
        setJudulTopik(kuisCekKemampuanData?.judulTopik || '');
        setVisibilitas(kuisCekKemampuanData?.visibilitas || 'sembunyi');
    }, [kuisCekKemampuanData]);

    const judulKuisField = <Col className='p-0'>
        <Form.Group>
            <Form.Label>Judul Kuis</Form.Label>
            <InputGroup>
                <Form.Label className='fsz-125'><b>{judul}</b></Form.Label>
            </InputGroup>
        </Form.Group>
    </Col>

    const judulTopikField = <Col className='p-0'>
        <Form.Group>
            <Form.Label>Topik</Form.Label>
            <InputGroup>
                <Form.Label className='fsz-125'><b>{judulTopik}</b></Form.Label>
            </InputGroup>
        </Form.Group>
    </Col>

    const firstRowForm = <Row className='mx-0 my-3'>
        {judulKuisField}
        {judulTopikField}
    </Row>

    const visibilitasField = <Col lg={6} className='p-0'>
        <Form.Group>
            <Form.Label>Visibilitas</Form.Label>
            <InputGroup>
                <Form.Label className='fsz-125'>
                    {visibilitas === 'publik'
                        ? <FontAwesomeIcon icon={faEye} size='xs' />
                        : <FontAwesomeIcon icon={faEyeSlash} size='xs' />
                    }
                    <b className='ms-2'>{visibilitas === 'publik' ? 'Publik' : 'Sembunyi'}</b>
                </Form.Label>
            </InputGroup>
        </Form.Group>
    </Col>

    const secondRowForm = <Row className='mx-0 mb-3'>
        {visibilitasField}
    </Row>

    // Soal Kuis Table
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const [showPopUp, setShowPopUp] = useState(false);
    const [refreshData, setRefreshData] = useState(1);
    const [showPopUpDelete, setShowPopUpDelete] = useState(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [soalKuisCekKemampuanData, setSoalKuisCekKemampuanData] = useState();

    useEffect(() => {
        if (!id) return;
        listSoalKuisCekKemampuanTrainer({ idCekKemampuan: id }).then(
            (response) => {
                console.log("status code: " + response.data.status_code);
                switch (response.data.status_code) {
                    case 'SKK200':
                        setData(response.data.message);
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('Soal Kuis Cek Kemampuan error :', error);
            }).finally((info) => {

            });
    }, [dispatch, id, refreshData]);

    const addFunction = () => {
        setSoalKuisCekKemampuanData({ idCekKemampuan: id });
        setIsEdit(false);
        setShowPopUp(prev => !prev);
    }

    const editFunction = (row) => {
        setSoalKuisCekKemampuanData(row);
        setIsEdit(true);
        setShowPopUp(prev => !prev);
    }

    const deleteFunction = (row) => {
        setSoalKuisCekKemampuanData(row);
        setShowPopUpDelete(prev => !prev);
    }

    return (
        <>
            <SoalKuisCekKemampuanPopUp setRefreshData={setRefreshData}
                show={showPopUp} setShow={setShowPopUp}
                soalKuisCekKemampuanData={soalKuisCekKemampuanData} isEdit={isEdit} />
            <SoalKuisCekKemampuanPopUpDelete
                show={showPopUpDelete} setShow={setShowPopUpDelete}
                setShowDeleteConfirmation={setShowDeleteConfirmation} />
            <SoalKuisCekKemampuanDeleteConfirmation setRefreshData={setRefreshData}
                show={showDeleteConfirmation}
                setShow={setShowDeleteConfirmation}
                soalKuisCekKemampuanData={soalKuisCekKemampuanData}
                setShowPopUpDelete={setShowPopUpDelete} />
            <Container className='bg-white container-detail-kuis-cek-kemampuan p-5 rounded mt-5'>
                <Row className="header-detail-kuis-cek-kemampuan">
                    <Col>
                        <h4>
                            <Button onClick={e => setSearchParams()}>
                                <FontAwesomeIcon icon={faChevronLeft} size='xs' className='me-2' />
                            </Button>
                            Detail Kuis Cek Kemampuan
                        </h4>
                    </Col>
                </Row>
                {firstRowForm}
                {secondRowForm}
            </Container>
            <Table
                data={data}
                columns={columns(editFunction, deleteFunction)}
                rowClickFunction={(e) => { }}
                addFunction={addFunction}
                title='Soal Pilian Ganda' />
        </>
    );

}

export default KuisCekKemampuanDetail;
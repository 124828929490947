import axios from "axios";
import { talent_topik_endpoint } from "../../app";
import { authHeader } from "./AuthHeader";

export const listTopikTalent = () => {
    return axios({
        url: talent_topik_endpoint,
        method: 'GET',
        headers: {'Content-type': 'application/json', ...authHeader()},
    }).then((response) => {
        console.log('List Topik Result :', response.data);
        return response;
    });
};

export const getTopikTalent = ({ id }) => {
    return axios({
        url: talent_topik_endpoint + `/${id}`,
        method: 'GET',
        headers: { 'Content-type': 'application/json', ...authHeader() },
    }).then((response) => {
        console.log('Get Topik Result :', response.data);
        return response;
    });
};

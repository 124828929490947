import axios from "axios";
import {
    talent_riwayatcekkemampuan_endpoint,
    talent_riwayatkuisakhir_endpoint,
    talent_riwayatkuisbab_endpoint,
} from "../../app";
import { riwayat_list } from "../features/dummy/riwayat";
import { authHeader } from "./AuthHeader";

export const listRiwayatKuisAkhir = ({ idKuisAkhir }) => {
    return axios({
        url: talent_riwayatkuisakhir_endpoint,
        method: 'GET',
        headers: { 'Content-type': 'application/json', ...authHeader() },
        params: { idKuisAkhir },
    }).then((response) => {
        console.log('List Riwayat Kuis Akhir Result :', response.data);
        return response;
    });
};

export const listRiwayatCekKemampuan = ({ idKuisCek }) => {
    return axios({
        url: talent_riwayatcekkemampuan_endpoint,
        method: 'GET',
        headers: { 'Content-type': 'application/json', ...authHeader() },
        params: { idKuisCek },
    }).then((response) => {
        console.log('List Riwayat Cek Kemampuan Result :', response.data);
        return response;
    });
};

export const listRiwayatKuisBab = ({ idKuisBab }) => {
    return axios({
        url: talent_riwayatkuisbab_endpoint,
        method: 'GET',
        headers: { 'Content-type': 'application/json', ...authHeader() },
        params: { idKuisBab },
    }).then((response) => {
        console.log('List Riwayat Kuis Bab Result :', response.data);
        return response;
    });
};

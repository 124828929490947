import axios from "axios";
import { trainer_language_endpoint } from "../../app";
import { authHeader } from "./AuthHeader";

export const listLanguageTrainer = () => {
    return axios({
        url: trainer_language_endpoint,
        method: 'GET',
        headers: { 'Content-type': 'application/json', ...authHeader() },
    }).then((response) => {
        console.log('List Language Result :', response.data);
        return response;
    });
};
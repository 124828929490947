import axios from "axios";
import { base_url, port_learning, trainer_kuisakhir_endpoint } from "../../app";
import { authHeader } from "./AuthHeader";

export const listKuisAkhirTrainer = () => {
    return axios({
        url: trainer_kuisakhir_endpoint,
        method: 'GET',
        headers: { 'Content-type': 'application/json', ...authHeader() },
    }).then((response) => {
        console.log('List Kuis Akhir Result :', response.data);
        return response;
    });
};

export const addKuisAkhirTrainer = ({ judul, idTopik, idBab, idSubbab, visibilitas }) => {
    return axios({
        url: trainer_kuisakhir_endpoint,
        method: 'POST',
        headers: { 'Content-type': 'application/json', ...authHeader() },
        data: {
            judul, idTopik, idBab, idSubbab, visibilitas
        },
    }).then((response) => {
        console.log('Add Kuis Akhir Result :', response.data);
        return response;
    });
};

export const editKuisAkhirTrainer = ({ id, judul, idTopik, idBab, idSubbab, visibilitas }) => {

    return axios({
        url: trainer_kuisakhir_endpoint,
        method: 'PUT',
        headers: { 'Content-type': 'application/json', ...authHeader() },
        data: {
            id, judul, idTopik, idBab, idSubbab, visibilitas
        },
    }).then((response) => {
        console.log('Edit Kuis Akhir Result :', response.data);
        return response;
    });
};

export const deleteKuisAkhirTrainer = ({ id }) => {
    return axios({
        url: trainer_kuisakhir_endpoint,
        method: 'DELETE',
        headers: { 'Content-type': 'application/json', ...authHeader() },
        data: {
            id
        },
    }).then((response) => {
        console.log('Delete Kuis Akhir Result :', response.data);
        return response;
    });
};

import axios from 'axios';
import { talent_forgotpass_endpoint } from '../../app';

export const lupaSandi = (username) => {
    return axios({
        url: talent_forgotpass_endpoint,
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
        data: { username: username },
    }).then((response) => {
        console.log('Lupa Sandi Result :', response.data);
        return response;
    });
};
import React, { useEffect, useState, useMemo } from "react";
import {
  Button,
  Modal,
  Row,
  Col,
  Image,
  Form,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { useDispatch } from "react-redux";
import Select from "react-select";
import {
  showErrorAlert,
  hideErrorAlert,
  showSuccessAlert,
  hideSuccessAlert,
} from "../../../app";
import { editProfilTrainer } from "../../service/ProfileService";
import { getOptLembaga } from "../../service/RegisterService";
import _ from "lodash";

function EditProfilPopup({
  show,
  setShow,
  setShowProfilPopUp,
  profileData,
  setRefreshData,
}) {
  const profilePhoto = "/img/avatar.png";
  const dispatch = useDispatch();

  const [nama, setNama] = useState();
  const [jenisKelamin, setJenisKelamin] = useState();
  const [email, setEmail] = useState();
  const [noTelp, setNoTelp] = useState();
  const [noWa, setNoWa] = useState();
  const [loading, setLoading] = useState(false);

  const [jenisKelaminOptions, setJenisKelaminOptions] = useState([
    { value: "L", label: "Laki - Laki" },
    { value: "P", label: "Perempuan" },
  ]);
  const [tipeLembaga, setTipeLembaga] = useState("1");
  const [asalLembaga, setAsalLembaga] = useState({
    value: "",
    label: "Pilih Universitas",
  });
  const [optionLembaga, setOptionLembaga] = useState([]);

  console.log("profileData", profileData);

  useEffect(() => {
    setNama(profileData?.nama || "");
    setJenisKelamin(profileData?.jenisKelamin || "");
    setEmail(profileData?.email || "");
    setNoTelp(profileData?.noTelp?.slice(2) || "");
    setNoWa(profileData?.noWa?.slice(2) || "");
    setTipeLembaga(profileData?.tipeLembaga);
    setAsalLembaga({
      value: profileData?.asalLembaga,
      label: profileData?.asalLembaga,
    });
  }, [show, profileData]);

  const jenisKelaminSelected =
    jenisKelaminOptions.find((item) => item.value === jenisKelamin) || null;

  const getLembaga = (val) => {
    setLoading(true);
    getOptLembaga({ tipeLembaga, keywordSearch: val })
      .then(
        (response) => {
          switch (response.data.status_code) {
            case "TAL200":
              const arr = response.data.message.map((e) => ({
                value: e.namaLembaga,
                label: e.namaLembaga,
              }));
              setOptionLembaga(arr);
              setLoading(false);
              break;
            default:
              dispatch(showErrorAlert("Terjadi kesalahan pada server!"));
              setLoading(false);
              break;
          }
        },
        (error) => {
          console.log("Verifkasi error :", error);
        }
      )
      .finally((info) => {});
  };

  const handleJenisKelaminChange = (value) => {
    setJenisKelamin(value.value);
  };

  const editProfile = () => {
    editProfilTrainer({
      nama,
      jenisKelamin,
      email,
      noTelp: `0${noTelp}`,
      noWa: `0${noWa}`,
      tipeLembaga,
      asalLembaga: asalLembaga.value,
    })
      .then(
        (response) => {
          switch (response.data.status_code) {
            case "TAL200":
              dispatch(showSuccessAlert("Profile berhasil diubah"));
              setShow(false);
              setShowProfilPopUp(true);
              setRefreshData((prev) => prev + 1);
              break;
            default:
              dispatch(showErrorAlert("Terjadi kesalahan pada server!"));
              break;
          }
        },
        (error) => {
          console.log("Edit Profile error :", error);
        }
      )
      .finally((info) => {});
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const isRequired = (
    <Form.Control.Feedback type="invalid">
      Field is Required
    </Form.Control.Feedback>
  );

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(hideErrorAlert());
    dispatch(hideSuccessAlert());

    if (!nama || !jenisKelamin || !email || !noTelp || !noWa) {
      dispatch(showErrorAlert("Data belum terisi dengan benar!"));
    } else if (!validateEmail(email)) {
      dispatch(showErrorAlert("E-mail tidak sesuai format!"));
    } else if (!noTelp.match(/^[0-9]*$/)) {
      dispatch(showErrorAlert("Hanya isi angka pada nomor telepon!"));
    } else if (noTelp.length < 9 || noTelp.length > 12) {
      dispatch(showErrorAlert("Nomor Telepon harus 9-12 digit!"));
    }  else if (!noWa.match(/^[0-9]*$/)) {
      dispatch(showErrorAlert("Hanya isi angka pada nomor WhatsApp!"));
    } else if (noWa.length < 9 || noWa.length > 12) {
      dispatch(showErrorAlert("Nomor WhatsApp harus 9-12 digit!"));
    } else {
      editProfile();
    }
  };

  const firstRow = (
    <Row className="mx-0 mb-0 justify-content-md-center">
      <Col lg="auto" className="d-grid align-content-center">
        <div className="image-container" style={{ margin: "0 auto" }}>
          <Image src={profilePhoto} className="nav-icon" />
        </div>
        <span className="talent-name mt-3">{profileData.nama}</span>
      </Col>
    </Row>
  );

  const namaLengkapField = (
    <Col>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label>
              Nama Lengkap<span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Control
              placeholder="Nama Lengkap"
              value={nama}
              onChange={(e) => {
                setNama(e.target.value);
              }}
              isInvalid={!nama}
            />
            {isRequired}
          </Form.Group>
        </Col>
      </Row>
    </Col>
  );

  const secondRow = <Row className="mx-0 mb-4">{namaLengkapField}</Row>;

  const thirdRow = (
    <Row className="mx-0 mb-4">
      <Col>
        <Row>
          <Col>
            <Form.Group>
              <Form.Label>
                Jenis Kelamin<span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Select
                value={jenisKelaminSelected}
                noOptionsMessage={() => "No options"}
                placeholder="Jenis Kelamin"
                options={jenisKelaminOptions}
                onChange={handleJenisKelaminChange}
              />
              <span style={{ fontSize: 14 }} className="text-danger">
                {!jenisKelamin && "Field is Required"}
              </span>
            </Form.Group>
          </Col>
        </Row>
      </Col>
    </Row>
  );

  const fourthRow = (
    <Row className="mx-0 mb-4">
      <Col>
        <Form.Group>
          <Form.Label>
            E-mail<span style={{ color: "red" }}>*</span>
          </Form.Label>
          <Form.Control
            placeholder="E-mail"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            type="email"
            isInvalid={!email}
          />
          {isRequired}
        </Form.Group>
      </Col>
    </Row>
  );

  const fifthRow = (
    <Row className="mx-0 mb-4">
      <Col>
        <Form.Label>
          Nomor Telepon<span style={{ color: "red" }}>*</span>
        </Form.Label>
        <InputGroup>
          <InputGroup.Text>62</InputGroup.Text>
          <FormControl
            placeholder="Nomor Telepon"
            value={noTelp}
            onChange={(e) => {
              const str = e.target.value;
              if (str.charAt(0) === "0") {
                str = str.slice(1);
              }
              setNoTelp(str);
            }}
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            isInvalid={!noTelp}
            maxLength={12}
          />
          {isRequired}
        </InputGroup>
      </Col>
    </Row>
  );

  

  const fieldNoWa = (
    <Row className="mx-0 mb-4">
      <Col>
        <Form.Label>
          Nomor WA Aktif<span style={{ color: "red" }}>*</span>
        </Form.Label>
        <InputGroup>
          <InputGroup.Text>62</InputGroup.Text>
          <FormControl
            placeholder="Nomor Telepon"
            value={noWa}
            onChange={(e) => {
              const str = e.target.value;
              if (str.charAt(0) === "0") {
                str = str.slice(1);
              }
              setNoWa(str);
            }}
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            isInvalid={!noWa}
            maxLength={12}
          />
          {isRequired}
        </InputGroup>
      </Col>
    </Row>
  );

  const sixthRow = (
    <Row className="mx-0 mb-3">
      <Col className="d-flex flex-row justify-content-between">
        <Button
          onClick={() => {
            setShow(false);
            setShowProfilPopUp(true);
          }}
          variant="outline-primary"
          className="button-foot"
        >
          Batal
        </Button>
        <Button
          type="submit"
          variant="outline-primary"
          className="button-foot"
          disabled={
            !nama || !jenisKelamin || !email || !noTelp || !asalLembaga.value || !noWa
          }
        >
          Simpan
        </Button>
      </Col>
    </Row>
  );

  const onLembagaChange = (event) => {
    const val = event.target.id;
    setTipeLembaga(val);
    const tipe = val === "1" ? "Pilih Universitas" : "Pilih Sekolah";
    setAsalLembaga({
      value: "",
      label: tipe,
    });
  };

  const pilihLembaga = (
    <Row className="mx-0 mb-3">
      <Col>
        <Form.Group className="w-100" controlId="pilihLembaga">
          <Form.Label>
            Pilih Lembaga<span style={{ color: "red" }}>*</span>
          </Form.Label>
          <InputGroup>
            <Form.Check
              inline
              label="Universitas"
              name="tipeLembaga"
              type="radio"
              id="1"
              checked={tipeLembaga === "1"}
              onChange={onLembagaChange}
            />
            <Form.Check
              inline
              label="Sekolah"
              name="tipeLembaga"
              type="radio"
              id="2"
              checked={tipeLembaga === "2"}
              onChange={onLembagaChange}
            />
          </InputGroup>
        </Form.Group>
      </Col>
    </Row>
  );

  const handleLembaga = (event) => {
    if (!event) {
      const tipe = tipeLembaga === "1" ? "Pilih Universitas" : "Pilih Sekolah";
      event = {
        target: null,
        value: "",
        label: tipe,
      };
    }
    setAsalLembaga(event);
  };

  const filterLembaga = (val) => {
    if (val.length > 2) {
      getLembaga(val);
    } else {
      setOptionLembaga([]);
    }
  };

  const debounce = useMemo(() => _.debounce(filterLembaga, 500), [tipeLembaga]);

  const selectLembaga = (
    <Row className="mx-0 mb-3">
      <Col>
        <Form.Group className="mb-3 w-100" controlId="selectLembaga">
          <Select
            value={asalLembaga}
            noOptionsMessage={() => "No options"}
            options={optionLembaga}
            onChange={handleLembaga}
            onInputChange={(e) => debounce(e)}
            isClearable={asalLembaga.value}
            isLoading={loading}
            styles={{
              control: (provided, state) => ({
                ...provided,
                borderColor: !asalLembaga.value ? "#dc3545" : "#ced4da",
              }),
            }}
          />
          <span style={{ fontSize: 14 }} className="text-danger">
            {!asalLembaga.value && "Field is Required"}
          </span>
        </Form.Group>
      </Col>
    </Row>
  );

  return (
    <Modal
      className="edit-profile"
      show={show}
      onHide={() => setShow((prev) => !prev)}
      centered
    >
      <Modal.Header closeButton className="profil-modal-header">
        <Image className="logo79" src="/img/logotujuhsembilan-mod.png" />
      </Modal.Header>
      <Form onSubmit={(e) => handleSubmit(e)}>
        <Modal.Body className="profil-modal-body">
          {firstRow}
          {secondRow}
          {thirdRow}
          {fourthRow}
          {fifthRow}
          {fieldNoWa}
          {pilihLembaga}
          {selectLembaga}
          {sixthRow}
        </Modal.Body>
      </Form>
    </Modal>
  );
}

export default EditProfilPopup;

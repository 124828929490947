import React, { useState } from 'react';
import { Container, Dropdown, Image, Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { faCircleUser, faBell } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Notification } from '../notification';
import { useDispatch } from 'react-redux';
import { logoutUser } from '../../service/AuthService';
import { logoutAction } from '../../features/auth';
import ProfilPopup from './ProfilPopup';
import EditProfilPopup from './EditProfilPopup';
import ChangePassPopup from './ChangePassPopup';
import { Link } from 'react-router-dom';
import { GetParentPath } from '../../../common';

function Header({ profileData, setRefreshData }) {
    const dispatch = useDispatch();
    const [showProfilPopUp, setShowProfilPopUp] = useState(false);
    const [showPasswordPopup, setShowPasswordPopup] = useState(false);
    const [showEditProfilPopup, setShowEditProfilPopup] = useState(false);
    const parentPath = GetParentPath();

    return (
        <>
            <ProfilPopup
                show={showProfilPopUp}
                setShow={setShowProfilPopUp}
                setShowPasswordPopup={setShowPasswordPopup}
                setShowEditProfilPopup={setShowEditProfilPopup}
                profileData={profileData} />
            <ChangePassPopup
                setRefreshData={setRefreshData}
                show={showPasswordPopup}
                setShow={setShowPasswordPopup}
                setShowProfilPopUp={setShowProfilPopUp}
            />
            <EditProfilPopup
                setRefreshData={setRefreshData}
                show={showEditProfilPopup}
                setShow={setShowEditProfilPopup}
                setShowProfilPopUp={setShowProfilPopUp}
                profileData={profileData} />
            <Navbar id="header-talent" className="xxl-12 p-0 bg-white" expand="lg">
                <Container fluid className="align-item-center justify-content-between pe-6">
                    <Navbar.Brand>
                        <Link to={`${parentPath}/topik`}>
                            <Image className="logo79" src='/img/logotujuhsembilan-mod.png' />
                        </Link>
                    </Navbar.Brand>
                    <div className="d-flex align-items-center">
                        <div className="mr-3 position-relative">
                            <NavDropdown title={<FontAwesomeIcon className="header-icon" icon={faBell} />} id="bell-icon" align="end">
                                <Notification />
                            </NavDropdown>
                        </div>
                        <NavDropdown title={<FontAwesomeIcon className="header-icon" icon={faCircleUser} />} id="user-icon">
                            <Dropdown.Item eventKey="1" onClick={() => { setShowProfilPopUp(true); }}>Lihat Profil</Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item eventKey="2" onClick={() => { logoutUser(); dispatch(logoutAction()) }}>Logout</Dropdown.Item>
                        </NavDropdown>
                    </div>
                </Container>
            </Navbar>
        </>
    );
}

export default Header;

import React, { useEffect, useState } from 'react';
import { Button, Row, Col, Container, Image, Modal, ToggleButton } from 'react-bootstrap';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch } from 'react-redux';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { listMateriTalent } from '../../service/MateriService';
import { listSubbabTalent } from '../../service/SubbabService';
import { saveAs } from 'file-saver';
import { showErrorAlert, showSuccessAlert } from '../../../app';
import { addDownloadTalent } from '../../service/DownloadService';
import axios from "axios";

export default function PopupDownload({ show, setShow, babList, topik }) {
    const dispatch = useDispatch();
    const [checkedMateri, setCheckedMateri] = useState([]);
    const [itemList, setItemList] = useState([]);
    const [listType, setListType] = useState("bab");
    const [judulItemList, setJudulItemList] = useState();
    const [subbabList, setSubbabList] = useState([]);
    const [materiList, setMateriList] = useState([]);
    var judulBab, judulSubbab, judulTopik;

    // useEffect(() => {
    //     console.log("checkedMateri complete: "+checkedMateri);
    //     checkedMateri.map(item => {
    //         console.log("checkedMateri: "+item);
    //     })
    // }, [checkedMateri]);

    useEffect(() => {
        setItemList(babList.filter(item => item.idTopik === topik.id));
        setJudulItemList(topik?.judul);
        judulTopik = topik?.judul;
    }, [show, babList, topik]);

    useEffect(() => {
        setListType("bab");
        setCheckedMateri([]);
        judulBab = null;
        judulSubbab = null;
        judulTopik = null;
    }, [show]);

    useEffect(() => {
        if (listType === "subbab")
        setItemList(subbabList);
    }, [subbabList]);

    useEffect(() => {
        if (listType === "materi")
        setItemList(materiList);
    }, [materiList]);

    // useEffect(() => {
    //     console.log(itemList);
    // }, [itemList]);

    useEffect(() => {
        console.log(checkedMateri);
    }, [checkedMateri]);

    // const item_list = [{ nama: "Input & Output", id: "50" },
    // { nama: "Perulangan", id: "100" }, { nama: "Percabangan", id: "125" },
    // { nama: "Boolean Expression", id: "78" }, { nama: "Function", id: "64" }];

    function getSubbabListByIdBab( idBab ) {
        listSubbabTalent({ idBab }).then(
            (response) => {
                switch (response.data.status_code) {
                    case 'SBB200':
                        setSubbabList(response.data.message || []);
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('List Subbab By Id error :', error);
            }).finally((info) => {

            });
    }

    function getMateriListByIdSubbab( idSubbab ) {
        listMateriTalent({ idSubbab }).then(
            (response) => {
                switch (response.data.status_code) {
                    case 'MAT200':
                        setMateriList(response.data.message || []);
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('List Materi By Id error :', error);
            })
    }

    function downloadMateri() {
        checkedMateri.map(item => {
            var videoNameArr = item.url.split("/");
            axios({
                url: item.url,
                method: "GET",
                responseType: "blob"
            }).then(
              (response) => {
                  var blob = new Blob([response.data], {
                  type: "text/plain;charset=utf-8"
                  });
                  saveAs(blob, videoNameArr[videoNameArr.length - 1]);
                  dispatch(showSuccessAlert('File sedang diunduh!'));
                  
                addDownloadTalent({ judulMateri: item.nama, judulBab, judulSubbab, judulTopik, id: item.id }).then(
                    (response) => {
                        switch (response.data.status_code) {
                            case 'DLD200':
                                break;
                            default:
                                dispatch(showErrorAlert(`Gagal Menambahkan Riwayat Download ${item.nama}!`));
                                break;
                        }
                    },
                    (error) => {
                        console.log('Add Riwayat Download error :', error);
                    })
              },
              (error) => {
                  dispatch(showErrorAlert('Tidak dapat mengunduh file!'));
              });
        })
    }

    const handleItemList = (e, item, listType) => {
        if (listType === "bab") {
            getSubbabListByIdBab(item.id);
            setJudulItemList(item.nama);
            judulBab = item.nama;
            setListType("subbab");
        } else if (listType === "subbab") {
            getMateriListByIdSubbab(item.id);
            setJudulItemList(item.nama);
            judulSubbab = item.nama;
            setListType("materi");
        } else if (listType === "materi") {
            var temp = checkedMateri.slice();
            var index = temp.findIndex(tempitem => {return tempitem.id === item.id});
            if (index === -1) {
                temp.push({ nama: item.judul, id: item.id, url: item.video });
            } else {
                temp.splice(index, 1);
            }
            setCheckedMateri(temp);
        }
    }

    const itemListComp = (listType) => {
        return (
            itemList?.map((item, index) => {
                return (
                    <Row key={index} className="bab-item border rounded p-2 my-2" onClick={(e) => {
                        handleItemList(e, item, listType);
                    }}>
                        {listType === "materi" && <Col lg={2} className="h-100 text-center my-auto">
                            <ToggleButton name="download-radio" id={`download-opsi-${index}`}
                                value={item.id}
                                type="checkbox"
                                className="h-100 rounded checkbox"
                                variant="outline-success"
                                checked={checkedMateri.some(tempitem => tempitem.id === item.id)}
                                onClick={e => e.stopPropagation()}
                            />
                        </Col>}
                        <Col lg={2} className="text-center my-auto"><Image height="26px" src="/img/folder.png" /></Col>
                        <Col lg={8} className="d-flex align-items-center">
                            <div className='text-primary'>{listType === "materi" ? item.judul : item.nama}</div>
                            {/* <div className="text-secondary">{item.ukuran} MB</div> */}
                        </Col>
                        {listType != "materi" && <Col lg={2} className="text-end my-auto"><AiOutlineArrowRight className="right-arrow-26px" style={{ color: '#0078D7' }} /></Col>}
                    </Row>
                )
            })
        )
    }

    return (
        <Modal id="bab-download-popup" show={show} onHide={() => setShow(prev => !prev)} centered>
            <Modal.Header className='' closeButton>
                <b>Download Materi</b>
            </Modal.Header>
            <Modal.Body className='text-left fsz-1125'>
                <b>{judulItemList}</b>
                <Container className="overflow-auto">
                    {itemListComp(listType)}
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button disabled={checkedMateri.length < 1} type='submit' variant='primary' onClick={() => downloadMateri()}><FontAwesomeIcon icon={faDownload} /> Download</Button>
            </Modal.Footer>
        </Modal>
    );
}

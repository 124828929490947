import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Row, InputGroup, Form } from 'react-bootstrap';
import { faSearch, faTimes, faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch } from 'react-redux';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { AiOutlineArrowLeft } from 'react-icons/ai';

import { showErrorAlert } from '../../../app';
import { listBabTalent } from '../../service/BabService';
import { GetParentPath } from '../../../common';
import { getTopikTalent } from '../../service/TopikService';
import { getSoalPraktikByTopik } from '../../service/PraktikService';
import MainTimelineBab from './MainTimelineBab';
import BabAccordionTimeline from './BabAccordionTimeline';
import { default as PopupDownload } from './PopupDownload';

function Bab() {
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const [searchTerm, setSearchTerm] = useState('');
    const [filter, setFilter] = useState("all");
    const [idTopik, setIdTopik] = useState(searchParams.get('idTopik'));
    const [idSoalLatihanPraktik, setIdSoalLatihanPraktik] = useState('');
    const [idSoalUjianPraktik, setIdSoalUjianPraktik] = useState('');

    const [showPopupDownload, setShowPopupDownload] = useState(false);
    const [showTerakhirDipelajari, setShowTerakhirDipelajari] = useState(false);

    const navigate = useNavigate();
    const parentPath = GetParentPath();

    const [topik, setTopik] = useState({});
    const [babList, setBabList] = useState([]);

    const backButton = <Link to={`${parentPath}/topik`}>
        <Button variant='light'
            className='back-bab-button bg-transparent border-0 p-0 fsz-1125 mb-2'
        >
            <AiOutlineArrowLeft className='me-2' style={{ color: '#757575' }} />
            <span style={{ color: '#081F32' }}>Kembali</span>
        </Button>
    </Link>

    const judulTopikLabel = <Container fluid className='bg-white fw-bold fs-4 mb-3 p-3 text-center'
        style={{ color: '#263238' }}
    >
        {topik.judul}
    </Container>

    const cekKemampuanButton = <Container fluid className='fsz-1125 mb-3 p-3 text-white'
        style={{
            background: 'linear-gradient(90deg, #34A853 0%, rgba(52, 168, 83, 0.6) 100%)',
            borderRadius: '8px'
        }}
    >
        <Row className='w-100 m-0'>
            <Col className='col-auto d-flex justify-content-end align-items-center'>
                Cek Kemampuan untuk mendapatkan rekomendasi belajar yang pas
            </Col>
            <Col className='col-auto ms-auto'>
                <Link to={`${parentPath}/cek-kemampuan?idTopik=${idTopik}`}>
                    <Button variant='light' className='list-bab-kuis-button'>Cek Kemampuan</Button>
                </Link>
            </Col>
        </Row>
    </Container>

    const praktikButton = <Container fluid className='fsz-1125 mb-3 p-3 text-white'
        style={{ background: '#34A853', borderRadius: '8px' }}
    >
        <Row className='w-100 m-0'>
            <Col className='col-auto d-flex justify-content-end align-items-center'>
                Uji skill dan kemampuan
            </Col>
            <Col className='col-auto ms-auto'>
                <Button variant='light' className='list-bab-kuis-button'
                    onClick={
                        e => {
                            e.preventDefault()
                            if (idSoalLatihanPraktik)
                                navigate(`${parentPath}/latihan-praktik?idSoal=${idSoalLatihanPraktik}&idTopik=${idTopik}`)
                        }
                    } disabled={(idSoalLatihanPraktik==='')}>Latihan Praktik</Button>
            </Col>
            <Col className='col-auto'>
                <Button variant='light' className='list-bab-kuis-button'
                    onClick={
                        e => {
                            e.preventDefault()
                            if (idSoalUjianPraktik)
                                navigate(`${parentPath}/ujian-praktik?idSoal=${idSoalUjianPraktik}&idTopik=${idTopik}`)
                        }
                    } disabled={(idSoalUjianPraktik==='')}>Ujian Praktik</Button>
            </Col>
        </Row>
    </Container>

    const terakhirDipelajariHeader = <div
        className='mb-2'
        style={{ color: '#081F32' }}
    >
        Terakhir di pelajari
    </div>

    useEffect(() => {
        setIdTopik(searchParams.get('idTopik'));
        if (!idTopik) navigate(`${parentPath}/topik`)
    }, [idTopik, parentPath, searchParams, navigate]);

    useEffect(() => {
        const babIdList = babList.map(bab => bab.id);
        if (babIdList.includes(topik?.terakhirDipelajari)) setShowTerakhirDipelajari(true);
    }, [topik, babList]);

    useEffect(() => {
        getTopikTalent({ id: idTopik }).then(
            (response) => {
                switch (response.data.status_code) {
                    case 'TPK200':
                        setTopik(response.data.message || {});
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('Topik error :', error);
            }).finally((info) => {

            });
    }, [idTopik, dispatch]);

    useEffect(() => {
        listBabTalent({ idTopik }).then(
            (response) => {
                switch (response.data.status_code) {
                    case 'BAB200':
                        setBabList(response.data.message || []);
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('Bab error :', error);
            }).finally((info) => {

            });
    }, [idTopik, dispatch]);

    useEffect(() => {
        getSoalPraktikByTopik({ idTopik }).then(
            (response) => {
                switch (response.data.status_code) {
                    case 'SPK200':
                        setIdSoalLatihanPraktik(response.data.message.IdSoalLatihanPraktik);
                        setIdSoalUjianPraktik(response.data.message.IdSoalUjianPraktik);
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('Get ID Soal Latihan/Ujuan Praktik error :', error);
            }).finally((info) => {

            });
    }, [idTopik, dispatch]);

    const babSearch = (term) => {
        const fieldSearchBab = ['nama', 'tag'];

        return babList.filter(bab => {
            if (filter === 'all') {
                for (let i = 0; i < fieldSearchBab.length; i++) {
                    if (bab[fieldSearchBab[i]] !== undefined)
                        if (String(bab[fieldSearchBab[i]])
                            .toLowerCase()
                            .includes(String(term)
                                .toLowerCase()
                            )) return true;
                }
                return false;
            } else {
                if (bab[filter] !== undefined)
                    if (String(bab[filter])
                        .toLowerCase()
                        .includes(String(term)
                            .toLowerCase()
                        )) return true;
                return false;
            }
        })
    }

    const ColumnFilter = <Col xs="auto">
        <Form.Select
            placeholder='Filter'
            className='search-bab-br-05'
            onChange={e => {
                setFilter(e.target.value);
                setTimeout(console.log(filter), 500);
            }}
            value={filter}>
            <option value="all">All Filter</option>
            <option value="nama">Nama Bab</option>
            <option value="tag">Tag</option>
        </Form.Select>
    </Col>

    const DownloadMateri = <Col xs="auto" className='ms-auto'>
        <Button className='search-bab-br-05' onClick={() => {
            setShowPopupDownload(prev => !prev);
        }}>
            <FontAwesomeIcon icon={faDownload} /> Download Materi
        </Button>
    </Col>

    const SearchField = <Col xs lg={6}>
        <InputGroup className='search-bab'>
            <Form.Control
                placeholder='Pencarian'
                className='search-input'
                value={searchTerm || ''}
                onChange={e => {
                    setSearchTerm(e.target.value);
                }} />
            <InputGroup.Text id="btnGroupAddon" className='bg-white search-icon'
                onClick={() => {
                    setSearchTerm('');
                }}
                style={searchTerm ? { cursor: 'pointer' } : {}}
            >
                {!searchTerm
                    ? <FontAwesomeIcon icon={faSearch} />
                    : <FontAwesomeIcon icon={faTimes} />
                }
            </InputGroup.Text>
        </InputGroup>
    </Col>



    const SearchRow = <Container fluid className="mb-3 px-0">
        <Row>
            {SearchField}
            {ColumnFilter}
            {DownloadMateri}
        </Row>
    </Container >

    return (
        <>
            {topik && <PopupDownload show={showPopupDownload} setShow={setShowPopupDownload} babList={babList} topik={topik} />}
            <Container fluid className='px-5' style={{ paddingTop: '4rem', overflowX: 'hidden' }}>
                {backButton}
                {judulTopikLabel}
                {cekKemampuanButton}
                {praktikButton}
                {SearchRow}
                {(babList && topik) && <MainTimelineBab babList={babList} topik={topik} />}
                {showTerakhirDipelajari && terakhirDipelajariHeader}
                {showTerakhirDipelajari && <BabAccordionTimeline isLastSeen idBab={topik.terakhirDipelajari} />}
                <hr style={{ color: '#000000', opacity: '1' }} />
                {babSearch(searchTerm).map((bab, index) =>
                    <BabAccordionTimeline key={index} idBab={bab.id} disableHeaderLink={!bab.isUnlocked} />)}
            </Container>
        </>
    );
}

export default Bab;

import axios from "axios";
import { trainer_kuiscekkemampuan_endpoint } from "../../app";
import { authHeader } from "./AuthHeader";
import { kuis_cek_kemampuan_list } from "../features/dummy/kuis-cek-kemampuan";

export const listKuisCekKemampuanTrainer = () => {
    return axios({
        url: trainer_kuiscekkemampuan_endpoint,
        method: 'GET',
        headers: { 'Content-type': 'application/json', ...authHeader() },
    }).then((response) => {
        console.log('List Kuis Cek Kemampuan Result :', response.data);
        return response;
    });
};

export const addKuisCekKemampuanTrainer = ({ judul, idTopik, visibilitas }) => {
    return axios({
        url: trainer_kuiscekkemampuan_endpoint,
        method: 'POST',
        headers: { 'Content-type': 'application/json', ...authHeader() },
        data: {
            judul, idTopik, visibilitas
        },
    }).then((response) => {
        console.log('Add Kuis Cek Kemampuan Result :', response.data);
        return response;
    });
};

export const editKuisCekKemampuanTrainer = ({ id, judul, idTopik, visibilitas }) => {
    return axios({
        url: trainer_kuiscekkemampuan_endpoint,
        method: 'PUT',
        headers: { 'Content-type': 'application/json', ...authHeader() },
        data: {
            id, judul, idTopik, visibilitas
        },
    }).then((response) => {
        console.log('Edit Kuis Cek Kemampuan Result :', response.data);
        return response;
    });
};

export const deleteKuisCekKemampuanTrainer = ({ id }) => {
    return axios({
        url: trainer_kuiscekkemampuan_endpoint,
        method: 'DELETE',
        headers: { 'Content-type': 'application/json', ...authHeader() },
        data: {
            id
        },
    }).then((response) => {
        console.log('Delete Kuis Cek Kemampuan Result :', response.data);
        return response;
    });
};

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { showErrorAlert } from '../../app';

// Component
import { Col, Container, Row } from 'react-bootstrap';
import { Navigation } from './navigation';
import { Header } from './header';

//Service
import { getProfile } from '../service/ProfileService';

function Home({ children }) {
    const [refreshData, setRefreshData] = useState(1);
    const [profileData, setProfileData] = useState({});
    const dispatch = useDispatch();

    // Initial render
    useEffect(() => {
        getProfile().then(
            (response) => {
                switch (response.data.status_code) {
                    case 'TAL200':
                        setProfileData(response.data.message || {});
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('Get Profile error :', error);
            })
    }, [dispatch, refreshData]);

    return (
        <Container id="home-talent" fluid className='vh-100 m-0'>
            <Row>
                <Col lg={12} className='header-talent p-0 position-relative fixed-top'>
                    <Header profileData={profileData} setRefreshData={setRefreshData} />
                </Col>
            </Row>
            <Row className='below-header-talent'>
                <Col lg={3} className='navigation-talent p-0 position-relative'>
                    <Navigation profileData={profileData} />
                </Col>
                <Col className='px-0'>
                    {children}
                </Col>
            </Row>
        </Container>
    );
}

export default Home;

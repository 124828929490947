import React, { useEffect, useState } from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroller';
import { useDispatch } from 'react-redux';
import { showErrorAlert } from '../../../app';
import { listNotifikasiTrainer } from '../../service/NotifikasiService';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NotificationItem from './NotificationItem';

export default function Notification() {
    const dispatch = useDispatch();
    const [listNotifikasi, setListNotifikasi] = useState([]);
    const [show, setShow] = useState(false);
    const [hasMore, setHasMore] = useState(false);

    const emptyNotif = {
        idNotification: 'empty',
        isRead: true,
        authorName: '',
        idMateri: '',
        judulMateri: '',
        komentar: 'Notifikasi kosong',
    }

    const loadMoreNotif = (page) => {
        listNotifikasiTrainer({ page }).then(
            (response) => {
                switch (response.data.status_code) {
                    case 'NTF200':
                        if (response.data.message && response.data.message.length > 0) {
                            setListNotifikasi([...listNotifikasi, ...response.data.message] || []);
                        } else {
                            setHasMore(false);
                        }
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('List Notifikasi Trainer error :', error);
            }).finally((info) => {

            });
    }

    return (
        <Dropdown id='bell-icon-dropdown'
            onToggle={(isOpen, event) => {
                setShow(isOpen);
                setHasMore(isOpen);
                setListNotifikasi([]);
            }}
        >
            <Dropdown.Toggle>
                <FontAwesomeIcon className='icon-color-white' icon={faBell}
                    style={{ width: '1.5rem', height: '1.5rem' }}
                />
            </Dropdown.Toggle>
            <Dropdown.Menu align='end'
                className='shadow-custom p-0 rounded notification-dropdown'
            >
                {show && <InfiniteScroll
                    pageStart={0}
                    loadMore={loadMoreNotif}
                    hasMore={hasMore}
                    loader={<div className='loader' key={0}>Loading ...</div>}
                    useWindow={false}
                    threshold={50}
                >
                    {listNotifikasi.map((notif, index) => (<NotificationItem key={index} {...notif} />))}
                </InfiniteScroll>}
                {(show && !hasMore && listNotifikasi.length === 0) &&
                    <NotificationItem {...emptyNotif} />}
            </Dropdown.Menu>
        </Dropdown>
    )
}

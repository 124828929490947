import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    showErrorAlert, hideErrorAlert,
    showSuccessAlert, hideSuccessAlert,
} from '../../../app';

import { Button, Modal, Col, Row } from 'react-bootstrap';
import { faEraser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { deleteSoalKuisMateriTrainer } from '../../service/SoalKuisMateriService';

function SoalKuisMateriDeleteConfirmation({ show, setShow, soalKuisMateriData, setRefreshData, setShowPopUpDelete }) {
    const dispatch = useDispatch();
    const [id, setId] = useState();

    useEffect(() => {
        if (show) {
            setId(soalKuisMateriData?.id);
        } else {
            setId();
        }
    }, [show, soalKuisMateriData]);

    const deleteSoalKuisMateri = () => {
        deleteSoalKuisMateriTrainer({ id }).then(
            (response) => {
                switch (response.data.status_code) {
                    case 'SKM200':
                        dispatch(showSuccessAlert('Soal Kuis Materi berhasil dihapus'));
                        setRefreshData(prev => prev + 1);
                        setShow(prev => !prev);
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('Soal Kuis Materi delete error :', error);
            }).finally((info) => {

            });
    }

    const handleSubmit = (event) => {
        dispatch(hideErrorAlert());
        dispatch(hideSuccessAlert());
        deleteSoalKuisMateri();
    }

    return (
        <Modal show={show} onHide={() => setShow(prev => !prev)} centered>
            <Modal.Header className='border-bottom-0' closeButton>
            </Modal.Header>
            <Modal.Body className='fsz-1125'>
                <Row>
                    <Col lg='auto'>
                        <FontAwesomeIcon icon={faEraser} color={'#EA4335'} className='me-2' style={{fontSize:'3rem'}} />
                    </Col>
                    <Col>
                        Anda yakin ingin menghapus soal kuis materi ini ?
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer className='d-flex justify-content-evenly'>
                <Button variant='light' onClick={() => {
                    setShow(false);
                    setShowPopUpDelete(false);
                }}
                    style={{ color: '#0078D7', border: '1px solid #0078D7', width: '9rem' }}
                >
                    Tidak
                </Button>
                <Button type='submit' variant='danger'
                    onClick={(e) => handleSubmit(e)}
                    style={{ width: '9rem' }}
                >
                    Ya
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default SoalKuisMateriDeleteConfirmation;

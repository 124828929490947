import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Navigation } from './navigation';
import { Header } from './header';
import { Breadcrumbs, setCrumbs, pushCrumb } from './breadcrumbs';
import { useDispatch } from 'react-redux';

function Home({ crumbs, name, children }) {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    window.addEventListener('resize', handleResize);

    // Membersihkan event listener saat komponen dilepas
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // [] berarti efek ini hanya akan dijalankan sekali saat komponen dimount

  return (
    <Container fluid className='vh-100 m-0 px-0'>
      <Row>
        <Col lg={2} className={`p-0 ${isMobile ? "position-relative" : "position-fixed"}`}>
          <Navigation />
        </Col>
        <Col className='px-0 pb-5' lg={{ span: isMobile ? 0 : 10, offset: isMobile ? 0 : 2 }}>
          <Header />
          <Breadcrumbs crumbs={crumbs} />
          {children}
        </Col>
      </Row>
    </Container>
  );
}

export default Home;

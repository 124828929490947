import axios from "axios";
import { trainer_talentterdaftar_endpoint } from "../../app";
import { authHeader } from "./AuthHeader";

export const getAllTalentTerdaftar = ({ keywordSearch }) => {
  return axios({
    url: trainer_talentterdaftar_endpoint + "/all",
    method: "GET",
    headers: { "Content-type": "application/json", ...authHeader() },
    params: {
      keywordSearch,
    },
  }).then((response) => {
    return response;
  });
};

export const exportTalentTerdaftar = ({ keywordSearch }) => {
    return axios({
      url: trainer_talentterdaftar_endpoint + "/export",
      method: "GET",
      headers: { "Content-type": "application/json", ...authHeader() },
      params: {
        keywordSearch,
      },
      responseType: "blob",
    }).then((response) => {
      return response;
    });
  };

import axios from "axios";
import { talent_screenshot_endpoint } from "../../app";
import { authHeader } from "./AuthHeader";
import moment from 'moment';
import { uploadImageSS } from "../../service/GCSService";

export const listScreenshotTalent = () => {
    return axios({
        url: talent_screenshot_endpoint,
        method: 'GET',
        headers: { 'Content-type': 'application/json', ...authHeader() },
    }).then((response) => {
        console.log('List Screenshot Result :', response.data);
        return response;
    });
};

export const addScreenshotTalent = ({ gambarScreenshot, judulVideoMateri, namaBab, namaTopik,
    idSubbab, namaSubbab, timestampScreenshot, catatan, idVideoScreenshot }) => {
    var promises = [];
    let urlGambarScreenshot = '';

    if (gambarScreenshot) {
        const gambarScreenshotName = `screenshot_${idVideoScreenshot}_${moment().format("dd-MM-yyyy_hhmm")}.jpeg`;
        promises.push(
            uploadImageSS({ fileName: gambarScreenshotName, folderName: 'gambar-screenshot', screenshot: gambarScreenshot }).then(
                (response) => {
                    urlGambarScreenshot = response.urlImage;
                },
                (error) => {
                    console.log(error)
                    return null;
                }
            ));
    }
    
    return Promise.all(promises).then(
        () => {
            return axios({
                url: talent_screenshot_endpoint,
                method: 'POST',
                headers: { 'Content-type': 'application/json', ...authHeader() },
                data: { gambarScreenshot: urlGambarScreenshot, judulVideoMateri, namaBab, namaTopik,
                    idSubbab, namaSubbab, timestampScreenshot, catatan, idVideoScreenshot },
            }).then((response) => {
                console.log('Add Screenshot Result :', response.data);
                return response;
            });
        });
};

export const editScreenshotTrainer = ({ id, catatan }) => {
    return axios({
        url: talent_screenshot_endpoint,
        method: 'PUT',
        headers: { 'Content-type': 'application/json', ...authHeader() },
        data: {
            idScreenshot:id, catatan
        },
    }).then((response) => {
        console.log('Edit Screenshot Result :', response.data);
        return response;
    });
};

export const deleteScreenshotTrainer = ({ id }) => {
    return axios({
        url: talent_screenshot_endpoint,
        method: 'DELETE',
        headers: {
            'Content-type': 'application/json',
            ...authHeader(),
        },
        data: {
            idScreenshot:id,
        },
    }).then((response) => {
        console.log('Delete Screenshot Result :', response.data);
        return response;
    });
};
import axios from "axios";
import { trainer_kuisbab_endpoint } from "../../app";
import { authHeader } from "./AuthHeader";

export const listKuisBabTrainer = () => {
    return axios({
        url: trainer_kuisbab_endpoint,
        method: 'GET',
        headers: { 'Content-type': 'application/json', ...authHeader() },
    }).then((response) => {
        console.log('List Kuis Bab Result :', response.data);
        return response;
    });
};

export const addKuisBabTrainer = ({ judul, idTopik, idBab, visibilitas }) => {
    return axios({
        url: trainer_kuisbab_endpoint,
        method: 'POST',
        headers: { 'Content-type': 'application/json', ...authHeader() },
        data: {
            judul, idTopik, idBab, visibilitas
        },
    }).then((response) => {
        console.log('Add Kuis Bab Result :', response.data);
        return response;
    });
};

export const editKuisBabTrainer = ({ id, judul, idTopik, idBab, visibilitas }) => {
    return axios({
        url: trainer_kuisbab_endpoint,
        method: 'PUT',
        headers: { 'Content-type': 'application/json', ...authHeader() },
        data: {
            id, judul, idTopik, idBab, visibilitas
        },
    }).then((response) => {
        console.log('Edit Kuis Bab Result :', response.data);
        return response;
    });
};

export const deleteKuisBabTrainer = ({ id }) => {
    return axios({
        url: trainer_kuisbab_endpoint,
        method: 'DELETE',
        headers: { 'Content-type': 'application/json', ...authHeader() },
        data: {
            id
        },
    }).then((response) => {
        console.log('Delete Kuis Bab Result :', response.data);
        return response;
    });
};

import { Link } from 'react-router-dom';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { Button, Container, Row } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { GetParentPath } from '../../../common';
import { Table } from '../../../trainer/common/table';
import { columns } from './columns';
import { listDownloadTalent, addDownloadTalent } from '../../service/DownloadService';
import { getMateriTalent } from '../../service/MateriService';
import { showErrorAlert, hideErrorAlert, showSuccessAlert, hideSuccessAlert } from '../../../app';
import { useDispatch } from 'react-redux';
import { saveAs } from 'file-saver';
import axios from "axios";
import { default as PopupDelete } from './PopupDelete';

export default function LihatDownload() {
    const dispatch = useDispatch();
    const parentPath = GetParentPath();
    const [downloadList, setDownloadList] = useState([]);
    const [downloadData, setDownloadData] = useState();
    const [showPopUpDelete, setShowPopUpDelete] = useState(false);
    const [refreshData, setRefreshData] = useState(1);

    const deleteFunction = (row) => {
        setDownloadData(row);
        setShowPopUpDelete(prev => !prev);
    }

    const redownloadFunction = (row) => {
        getMateriTalent({ id: row.idMateri }).then(
            (response) => {
                switch (response.data.status_code) {
                    case 'MAT200':
                        var url = response.data.message.video;
                        var videoNameArr = url.split("/");
                        axios({
                            url: url,
                            method: "GET",
                            responseType: "blob"
                          }).then(
                            (response) => {
                                var blob = new Blob([response.data], {
                                type: "text/plain;charset=utf-8"
                                });
                                saveAs(blob, videoNameArr[videoNameArr.length - 1]);
                                dispatch(showSuccessAlert('File sedang diunduh!'));
                                
                            addDownloadTalent({ judulMateri: row.judulMateri, judulBab: row.judulBab, judulSubbab: row.judulSubbab, judulTopik: row.judulTopik, id: row.idMateri }).then(
                                (response) => {
                                    switch (response.data.status_code) {
                                        case 'DLD200':
                                            setRefreshData(prev => prev+1);
                                            break;
                                        default:
                                            dispatch(showErrorAlert(`Gagal Menambahkan Riwayat Download Ulang!`));
                                            break;
                                    }
                                },
                                (error) => {
                                    console.log('Add Riwayat Download error :', error);
                                })
                            },
                            (error) => {
                                dispatch(showErrorAlert('Tidak dapat mengunduh file!'));
                            });
                          break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('Get Url Materi error :', error);
                return null;
            })
    }

    useEffect(() => {
        listDownloadTalent().then(
            (response) => {
                switch (response.data.status_code) {
                    case 'DLD200':
                        setDownloadList(response.data.message || []);
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('List download error :', error);
            }).finally((info) => {

            });
    }, [dispatch, refreshData]);

    return (
        <>
            <PopupDelete
                setRefreshData={setRefreshData}
                show={showPopUpDelete}
                setShow={setShowPopUpDelete}
                downloadData={downloadData}
            />
            <Container className="pt-5" id='download-container'>
                <Link to={`${parentPath}/topik`}>
                    <Button variant='light' className='back-bab-button bg-transparent border-0 p-0 fsz-1125 mb-2'>
                        <AiOutlineArrowLeft className='me-2' style={{ color: '#757575' }} />
                        <span className='ms-4' style={{ color: '#081F32' }}>Kembali</span>
                    </Button>
                </Link>
                <Table
                    data={downloadList}
                    rowClickFunction={() => {}}
                    columns={columns(redownloadFunction, deleteFunction)}
                    title='Riwayat Download'
                    showAddButton={false}
                    showCustomize={false}
                    reverseSearchFilter={true} />
            </Container>
        </>
    )
}

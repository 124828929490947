import React, { useEffect, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import moment from 'moment';
import 'moment/locale/id';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { GetParentPath } from '../../../common';
import { listScreenshotTalent } from '../../service/ScreenshotService';
import { showErrorAlert } from '../../../app';
import { default as ScreenshotDateGrid } from './ScreenshotDateGrid';
import { default as ScreenshotPopup } from './ScreenshotPopup';
import { default as ScreenshotPopupDelete } from './ScreenshotPopupDelete';
import 'react-day-picker/lib/style.css';

function Screenshot() {
    moment.locale('id');
    const dispatch = useDispatch();
    const [screenshotList, setScreenshotList] = useState([]);
    const [refreshData, setRefreshData] = useState(1);
    const parentPath = GetParentPath();
    const [showPopup, setShowPopup] = useState(false);
    const [showPopupDelete, setShowPopupDelete] = useState(false);
    const [thumbData, setThumbData] = useState({});

    useEffect(() => {
        listScreenshotTalent().then(
            (response) => {
                switch (response.data.status_code) {
                    case 'SCS200':
                        var screenshotByDateTemp = [];
                        response.data.message.forEach(element => {
                            screenshotByDateTemp.push({ date: moment(element.Tanggal, 'D-MMM-YYYY'), list: element.Screenshots });
                        });
                        setScreenshotList(screenshotByDateTemp || []);
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('Screenshot error :', error);
            }).finally((info) => {

            });
    }, [dispatch, refreshData]);

    return (
        <>
            <ScreenshotPopupDelete show={showPopupDelete} setShow={setShowPopupDelete} data={thumbData} setRefreshData={setRefreshData} />
            <ScreenshotPopup show={showPopup} setShow={setShowPopup} data={thumbData} setRefreshData={setRefreshData} />
            <Container id='screenshot-container'>
                <Link to={`${parentPath}/topik`}>
                    <Button variant='light' className='back-bab-button bg-transparent border-0 p-0 fsz-1125 mb-2'>
                        <AiOutlineArrowLeft className='me-2' style={{ color: '#757575' }} />
                        <span className='ms-4' style={{ color: '#081F32' }}>Kembali</span>
                    </Button>
                </Link>
                <div className='screenshot-title py-2'><span className='title' >Lihat Screenshot</span></div>
                {
                    screenshotList.map(item => {
                        return <ScreenshotDateGrid setShowPopup={setShowPopup} setShowPopupDelete={setShowPopupDelete} setThumbData={setThumbData} date={item.date} data={item.list} key={item.date.format('YYYY-MM-DD')} />
                    })
                }
            </Container>
        </>
    )
}

export default Screenshot;

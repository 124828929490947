import React from 'react';
import { Button, ButtonGroup, Container, Dropdown, DropdownButton, Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { faCircleUser, faBell } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Notification } from '../notification';
import { useDispatch, useSelector } from 'react-redux';
import { logoutUser } from '../../service/AuthService';
import { logoutAction } from '../../features/auth';

function Header(props) {
    const dispatch = useDispatch();
    const role = useSelector(state => state.auth.role);

    return (
        <Container fluid className='xxl-12 p-0'>
            <Navbar className='bg-blue header-size' expand='lg'>
                <Container fluid className='align-item-center justify-content-end py-45 pe-55'>
                    {role === 'trainer' && <Notification />}
                    <Dropdown id='user-icon-dropdown'>
                        <Dropdown.Toggle>
                            <FontAwesomeIcon className='icon-color-white' icon={faCircleUser} />
                            <span className='align-top'>{role === 'admin' ? 'Admin' : 'Trainer'}79</span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className='shadow-custom'>
                            <Dropdown.Item eventKey='1' onClick={() => { logoutUser(); dispatch(logoutAction()) }}>Logout</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Container>
            </Navbar>
        </Container>
    );
}

export default Header;

import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Button, Card, Col, Form, InputGroup, Row } from 'react-bootstrap';
import InputMask from 'react-input-mask';
import { editTimelineTrainer } from '../../service/TimelineService';
import { useDispatch } from 'react-redux';
import {
    showErrorAlert, hideErrorAlert,
    showSuccessAlert, hideSuccessAlert,
} from '../../../app';

function timeStringtoSecond(timeString) {
    var timeSplit = timeString.split(':');
    var seconds = (+timeSplit[0]) * 60 * 60 + (+timeSplit[1]) * 60 + (+timeSplit[2]);
    return seconds;
}

function TimelinePill({ id, timestamp, judul, data,
    setData, deleteFunction, setRefreshData, durasi }) {
    const dispatch = useDispatch();
    const date = new Date(timestamp * 1000);
    const [idTimeline, setIdTimeline] = useState(id);
    const [timestampValue, setTimestampValue] = useState(date.toISOString().slice(11, 19));
    const [timeSecond, setTimeSecond] = useState(timestamp);
    const [judulValue, setJudulValue] = useState(judul);
    const [editMode, setEditMode] = useState(false);


    const editTimeline = () => {
        editTimelineTrainer({
            idTimeline, timestamp: timeSecond, judul: judulValue
        }).then(
            (response) => {
                switch (response.data.status_code) {
                    case 'TML200':
                        dispatch(showSuccessAlert('Timeline berhasil diubah'));
                        setRefreshData(prev => prev + 1);
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('Edit Timeline error :', error);
            }).finally((info) => {

            });
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        dispatch(hideErrorAlert());
        dispatch(hideSuccessAlert());

        if (!judulValue) {
            dispatch(showErrorAlert('Isi judul!'));
        } else if (!timeSecond) {
            dispatch(showErrorAlert('Isi timestamp!'));
        } else if (timeSecond > durasi) {
            dispatch(showErrorAlert('Timestamp tidak boleh melebihi durasi video'));
        } else {
            editTimeline();
            setEditMode(prev => !prev)
        }
    }

    const timestampField = <Form.Group>
        <InputMask className='form-control text-center py-0 px-1 fsz-0875 timeline-input'
            placeholder='00:00:00'
            defaultValue={timestampValue}
            mask="99:59:59"
            formatChars={{
                '5': '[0-5]',
                '9': '[0-9]',
                'a': '[A-Za-z]',
                '*': '[A-Za-z0-9]'
            }}
            onChange={(e) => {
                setTimestampValue(e.target.value);
                setTimeSecond(timeStringtoSecond(e.target.value));
            }}
            disabled={!editMode}
        />
    </Form.Group>

    const judulField = <Form.Group>
        <Form.Control className='py-0 px-2 fsz-0875 timeline-input'
            placeholder='Judul Timeline'
            defaultValue={judulValue}
            onChange={(e) => setJudulValue(e.target.value)}
            disabled={!editMode}
        />
    </Form.Group>

    const actionField = <Form.Group className='w-100 text-end'>
        <InputGroup className='w-100 d-flex justify-content-end align-items-center'>
            {!editMode && <Button className='p-0 fsz-0875 me-2' variant='light'
                onClick={() => setEditMode(prev => !prev)}
            >
                <FontAwesomeIcon icon={faEdit} color="#ffc107"
                    style={{ cursor: 'pointer' }}
                />

            </Button>}
            {!editMode && <Button className='p-0 fsz-0875' variant='light'
                style={{ cursor: 'pointer' }}
                onClick={() => deleteFunction(id)}
            >
                <FontAwesomeIcon icon={faTrash} color="#dc3545"
                />
            </Button>}
            {editMode && <Button className='p-0 fsz-0875 w-100'
                type='submit' variant='primary'
                onClick={(e) => {
                    handleSubmit(e)
                }}
            >
                Simpan
            </Button>}
        </InputGroup>
    </Form.Group>

    return (
        <Card className='px-4 py-2 mb-2'
            style={{ boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}
        >
            <Form onSubmit={(e) => handleSubmit(e)}>
                <Row>
                    <Col className='col-2 px-1'>
                        {timestampField}
                    </Col>
                    <Col className='col-8 px-1'>
                        {judulField}
                    </Col>
                    <Col className='col-2 px-1'>
                        {actionField}
                    </Col>
                </Row>
            </Form>
        </Card>
    );
}

export default TimelinePill;


import React, { forwardRef } from 'react';
import { Button, Col, Image, Row } from 'react-bootstrap';
import { MdReply } from 'react-icons/md';

function KomentarBubbleLeft(props, ref) {
    const { author, komentar, setNewReplyTo } = props;
    const { idAuthor, name, level, isTalent, isAuthor } = author;
    const { idKomentar, datetime, message, replyTo } = komentar;

    const authorNameText = <Col className='col-auto d-flex align-items-center'>
        {name}
    </Col>

    const levelText = <Col className='col-auto d-flex align-items-center gap-2'>
        <Image src='/img/level.png' style={{ width: '0.875rem', height: '0.875rem' }} />
        <span>Level</span>
        {level > 0 ? level : 'Trainer'}
    </Col>

    const datetimeText = <Col className='col-auto ms-auto d-flex align-items-center'>
        {datetime}
    </Col>

    const replyButton = <Col className='col-auto d-flex align-items-center px-0'>
        <Button variant='light'
            className='bg-transparent p-0 border-0 d-flex align-items-center px-0 me-2'
            onClick={() => setNewReplyTo(idKomentar)}
        >
            <MdReply style={{ width: '0.875rem', height: '0.875rem' }} color='#757575' />
        </Button>
    </Col>

    const messageText = <Col className='col-auto px-3' >
        {message}
    </Col>

    return (
        <Row className='mb-2' ref={ref}
            style={{ color: '#263238' }}
        >
            <Col className='col-auto'>
                <Row>
                    <Col className='col-auto'>
                        <Image src='/img/komentar_photo.png'
                            className='rounded-circle'
                            style={{ width: '3rem', height: '3rem' }}
                        />
                    </Col>
                    <Col className='fsz-075 border py-2'
                        style={{ borderRadius: '8px', border: '1px solid #979797' }}
                    >
                        <Row className='text-black mb-1'>
                            {authorNameText}
                            {levelText}
                            {datetimeText}
                            {replyButton}
                        </Row>
                        <Row className='fw-light'>
                            {messageText}
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}

export default forwardRef(KomentarBubbleLeft);

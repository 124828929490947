import axios from "axios";
import { base_url, port_learning, trainer_soalkuismateri_endpoint, trainer_soalpraktik_endpoint } from "../../app";
import { authHeader } from "./AuthHeader";
import { uploadImage } from "../../service/MinioService";
import { soal_praktik_list } from "../features/dummy/soal-praktik";
import moment from 'moment';
import { memo } from "react";

export const listSoalPraktikTrainer = () => {
    return axios({
        url: trainer_soalpraktik_endpoint,
        method: 'GET',
        headers: {
            'Content-type': 'application/json',
            ...authHeader(),
        },
    }).then((response) => {
        console.log('List Soal Praktik Result :', response.data);
        return response;
    });
};
export const addSoalPraktikTrainer = ({
    idTopik, judul, jenis, deskripsi, visibilitas, idLanguage, timeLimit, memoryLimit, expectedOutput }) => {
    return axios({
        url: trainer_soalpraktik_endpoint,
        method: 'POST',
        headers: {
            'Content-type': 'application/json',
            ...authHeader(),
        },
        data: {
            idTopik, judul, jenis, deskripsi, visibilitas, idLanguage, timeLimit:Number(timeLimit), memoryLimit:Number(memoryLimit), expectedOutput
        },
    }).then((responseData) => {
        console.log('Add Soal Praktik Result :', responseData.data);
        return responseData;
    });
};

export const editSoalPraktikTrainer = ({
    idSoalPraktik, idTopik, judul, jenis, deskripsi, visibilitas, idLanguage, timeLimit, memoryLimit, expectedOutput }) => {
    return axios({
        url: trainer_soalpraktik_endpoint,
        method: 'PUT',
        headers: {
            'Content-type': 'application/json',
            ...authHeader(),
        },
        data: {
            idSoalPraktik, idTopik, judul, jenis, deskripsi, visibilitas, idLanguage, timeLimit:Number(timeLimit), memoryLimit:Number(memoryLimit), expectedOutput
        },
    }).then((responseData) => {
        console.log('Edit Soal Praktik Result :', responseData.data);
        return responseData;
    });
};

export const deletePraktikTrainer = ({ idSoalPraktik }) => {

    return axios({
        url: trainer_soalpraktik_endpoint,
        method: 'DELETE',
        headers: {
            'Content-type': 'application/json',
            ...authHeader(),
        },
        data: {
            idSoalPraktik,
        },
    }).then((response) => {
        console.log('Delete SoalPraktik Result :', response.data);
        return response;
    });
};

import React, { useState, useEffect } from 'react';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Modal, Form, Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import { useDispatch } from 'react-redux';
import { showErrorAlert, hideErrorAlert, showSuccessAlert, hideSuccessAlert } from '../../../app';
import { addKuisBabTrainer, editKuisBabTrainer } from '../../service/KuisBabService';

function KuisBabPopUp({ show, setShow, kuisBabData, isEdit, setRefreshData, topikList: topikListData, babList: babListData }) {
    const dispatch = useDispatch();

    const [id, setId] = useState();
    const [judul, setJudul] = useState();
    const [idTopik, setIdTopik] = useState();
    const [judulTopik, setJudulTopik] = useState();
    const [idBab, setIdBab] = useState();
    const [namaBab, setNamaBab] = useState();
    const [visibilitas, setVisibilitas] = useState();

    const [visibilitasOptions, setVisibilitasOptions] = useState([
        {
            value: 'sembunyi', label: <span>
                <FontAwesomeIcon icon={faEyeSlash} color={'#263238'} className='me-2' />Sembunyi</span>
        },
        {
            value: 'publik', label: <span>
                <FontAwesomeIcon icon={faEye} color={'#263238'} className='me-2' />Publik</span>
        },
    ]);
    const visibilitasSelected = visibilitasOptions.find(item => item.value === visibilitas) || null;

    useEffect(() => {
        setId(kuisBabData?.id || '');
        setJudul(kuisBabData?.judul || '');
        setIdTopik(kuisBabData?.idTopik || '');
        setJudulTopik(kuisBabData?.judulTopik || '');
        setIdBab(kuisBabData?.idBab || '');
        setNamaBab(kuisBabData?.namaBab || '');
        setVisibilitas(kuisBabData?.visibilitas || 'sembunyi');
    }, [show, kuisBabData]);

    const topikList = topikListData;
    const topikOptions = topikList.map(item => ({ value: item.id, label: item.judul }));
    const topikSelected = topikOptions.find(item => item.value === idTopik) || null;

    const babList = babListData;
    const babOptions = babList.filter(item => item.idTopik === idTopik).map(item => ({ value: item.id, label: item.nama }));
    const babSelected = babOptions.find(item => item.value === idBab) || null;

    const handleTopikChange = ({ value, label }) => {
        setIdTopik(value);
        setJudulTopik(label);
        setIdBab();
        setNamaBab();
    }

    const handleBabChange = ({ value, label }) => {
        setIdBab(value);
        setNamaBab(label);
    }

    const handleVisibilitasChange = (value) => {
        setVisibilitas(value.value);
    }

    const addKuisBab = () => {
        addKuisBabTrainer({ judul, idTopik, idBab, visibilitas }).then(
            (response) => {
                switch (response.data.status_code) {
                    case 'KBB200':
                        dispatch(showSuccessAlert('Kuis Bab berhasil dibuat'));
                        setRefreshData(prev => prev + 1);
                        setShow(prev => !prev);
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('Add Kuis Bab error :', error);
            }).finally((info) => {

            });
    }

    const editKuisBab = () => {
        editKuisBabTrainer({ id, judul, idTopik, idBab, visibilitas }).then(
            (response) => {
                switch (response.data.status_code) {
                    case 'KBB200':
                        dispatch(showSuccessAlert('Kuis Bab berhasil diubah'));
                        setRefreshData(prev => prev + 1);
                        setShow(prev => !prev);
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('Edit Kuis Bab error :', error);
            }).finally((info) => {

            });
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        dispatch(hideErrorAlert());
        dispatch(hideSuccessAlert());

        if (!judul) {
            dispatch(showErrorAlert('Isi judul kuis!'));
        } else if (!idTopik || !judulTopik) {
            dispatch(showErrorAlert('Pilih topik!'));
        } else if (!idBab || !namaBab) {
            dispatch(showErrorAlert('Pilih bab!'));
        } else if (!visibilitas) {
            dispatch(showErrorAlert('Pilih visibilitas!'));
        } else {
            if (isEdit) editKuisBab();
            else addKuisBab();
        }
    }

    const judulKuisField = <Col>
        <Form.Group>
            <Form.Label>Judul kuis <b className='asterisk'>*</b></Form.Label>
            <Form.Control placeholder='Judul Kuis'
                value={judul}
                onChange={(e) => { setJudul(e.target.value) }}
            />
        </Form.Group>
    </Col>

    const judulTopikField = <Col>
        <Form.Group>
            <Form.Label>Topik <b className='asterisk'>*</b></Form.Label>
            <Select value={topikSelected}
                noOptionsMessage={() => 'No options'}
                placeholder='Topik'
                options={topikOptions}
                onChange={handleTopikChange}
            />
        </Form.Group>
    </Col>

    const firstRowForm = <Row className='mx-0 mb-3'>
        {judulKuisField}
        {judulTopikField}
    </Row>

    const visibilitasField = <Col>
        <Form.Group>
            <Form.Label>Visibilitas <b className='asterisk'>*</b></Form.Label>
            <Select value={visibilitasSelected}
                noOptionsMessage={() => 'No options'}
                placeholder='Visibilitas'
                options={visibilitasOptions}
                onChange={handleVisibilitasChange}
            />
        </Form.Group>
    </Col>

    const namaBabField = <Col>
        <Form.Group>
            <Form.Label>Bab <b className='asterisk'>*</b></Form.Label>
            <Select value={babSelected}
                noOptionsMessage={() => 'No options'}
                placeholder='Bab'
                options={babOptions}
                onChange={handleBabChange}
                isDisabled={judulTopik ? false : true}
            />
        </Form.Group>
    </Col>

    const secondRowForm = <Row className='mx-0 mb-3'>
        {visibilitasField}
        {namaBabField}
    </Row>

    return (
        <Modal show={show} onHide={() => setShow(prev => !prev)} centered size='lg'>
            <Modal.Header closeButton>
                <Modal.Title>{isEdit ? 'Edit ' : 'Buat '}Kuis Bab Digital Bootcamp 79</Modal.Title>
            </Modal.Header>
            <Form onSubmit={(e) => handleSubmit(e)}>
                <Modal.Body>
                    {firstRowForm}
                    {secondRowForm}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='light' onClick={() => setShow(prev => !prev)}
                        style={{ color: '#0078D7', border: '1px solid #0078D7' }}>Batal</Button>
                    <Button type='submit' variant='primary'>Simpan</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}

export default KuisBabPopUp;

import axios from "axios";
import { talent_kuisbab_endpoint, talent_submitkuisbab_endpoint } from "../../app";
import { authHeader } from "./AuthHeader";

export const listKuisBabTalent = ({ idBab }) => {
    return axios({
        url: talent_kuisbab_endpoint,
        method: 'GET',
        headers: {'Content-type': 'application/json', ...authHeader()},
        params: { idBab }
    }).then((response) => {
        console.log('List Kuis Bab Result :', response.data);
        return response;
    });
};

export const submitKuisBabTalent = ({ idKuisBab, durasiPengerjaan, nilai }) => {
    return axios({
        url: talent_submitkuisbab_endpoint,
        method: 'POST',
        headers: { 'Content-type': 'application/json', ...authHeader() },
        data: {
            idKuisBab, durasiPengerjaan, nilai,
        }
    }).then((response) => {
        console.log('Submit Kuis Bab Result :', response.data);
        return response;
    });
};

import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, InputGroup, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { hideErrorAlert, showErrorAlert, showSuccessAlert } from '../../../app';
import { GetParentPath } from '../../../common';
import { resetSandi, validasiResetSandi } from '../../service/ResetSandiService';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function ResetSandiForm(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [token, setToken] = useState(searchParams.get('token'));

    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [confirmPasswordError, setConfirmPasswordError] = useState(false);

    const parentPath = GetParentPath();

    useEffect(() => {
        validasiResetSandi({token}).then(
            (response) => {
                switch (response.data.status_code) {
                    case 'RPL200':
                        break
                    case 'RPL400':
                        dispatch(showErrorAlert('Token tidak valid! Silahkan lakukan reset password kembali'));
                        navigate(`${parentPath}/login`)
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            }
        )
    }, [token, dispatch]);

    useEffect(() => {
        setPasswordError(false);
        setConfirmPasswordError(false);
        dispatch(hideErrorAlert());
    }, [password, confirmPassword, dispatch]);

    const lupaSandiFormHeader = <Container fluid className='p-0 login-form-header'>
        <h4 style={{textAlign:"center"}}><b>Atur Ulang Kata Sandi</b></h4>
    </Container>

    const passwordField = <Form.Group className='mb-3 w-100 fsz-075' controlId='passwordField'>
        <Form.Label>Kata Sandi Baru :</Form.Label>
        <InputGroup>
            <Form.Control placeholder='Kata Sandi Baru'
                type={showPassword ? 'text' : 'password'}
                className={`${passwordError ? 'login-form-element-error' : 'login-form-element'} border-end-0`}
                value={password}
                onChange={(e) => { setPassword(e.target.value); }}
            />
            <Button variant='light'
                onClick={() => setShowPassword(prev => !prev)}
                className={`${passwordError ? 'login-form-element-error' : 'login-form-element'} border-start-0 bg-white`}
            >
                {showPassword ? <FontAwesomeIcon icon={faEye} color={'#6C757D'} /> :
                    <FontAwesomeIcon icon={faEyeSlash} color={'#6C757D'} />}
            </Button>
        </InputGroup>
    </Form.Group>

    const confirmPasswordField = <Form.Group className='mb-3 w-100 fsz-075' controlId='passwordField'>
        <Form.Label>Konfirmasi  Kata Sandi Baru :</Form.Label>
        <InputGroup>
            <Form.Control placeholder='Konfirmasi Kata Sandi Baru'
                type={showConfirmPassword ? 'text' : 'password'}
                className={`${confirmPasswordError ? 'login-form-element-error' : 'login-form-element'} border-end-0`}
                value={confirmPassword}
                onChange={(e) => { setConfirmPassword(e.target.value); }}
            />
            <Button variant='light'
                onClick={() => setShowConfirmPassword(prev => !prev)}
                className={`${confirmPasswordError ? 'login-form-element-error' : 'login-form-element'} border-start-0 bg-white`}
            >
                {showConfirmPassword ? <FontAwesomeIcon icon={faEye} color={'#6C757D'} /> :
                    <FontAwesomeIcon icon={faEyeSlash} color={'#6C757D'} />}
            </Button>
        </InputGroup>
    </Form.Group>

    const prosesButton = <Button variant='primary' type='submit'
        className='w-100 mb-4'
    >
        <b>Proses</b>
    </Button>

    const backButton = <Container fluid className='text-center'>
        <Link className='login-footer-link text-decoration-none' to={`${parentPath}/login`}>
            <Button variant='light'
                className='p-0 bg-transparent border-0 back-login-button fsz-075'>
                &lt;&lt; Kembali
            </Button>
        </Link>
    </Container>

    const resetSandiProses = () => {
        resetSandi({password, token}).then(
            (response) => {
                switch (response.data.status_code) {
                    case 'RPL200':
                        dispatch(showSuccessAlert('Kata Sandi Berhasil Diubah'));
                        navigate(`${parentPath}/login`)
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {

            }
        )
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        dispatch(hideErrorAlert());

        if (password === '') {
            setPasswordError(true);
            dispatch(showErrorAlert('Isi Kata Sandi Baru'));
        } else if (confirmPassword === '') {
            setConfirmPasswordError(true);
            dispatch(showErrorAlert('Isi Konfirmasi Kata Sandi Baru'));
        } else if (password !== confirmPassword) {
            setPasswordError(true);
            setConfirmPasswordError(true);
            dispatch(showErrorAlert('Konfirmasi Kata Sandi Baru salah !'));
        }else {
            resetSandiProses();
        }
    }

    return (
        <Container>
            {lupaSandiFormHeader}
            <Form noValidate
                onSubmit={(e) => handleSubmit(e)}
            >
                {passwordField}
                {confirmPasswordField}
                {prosesButton}
            </Form>
            {backButton}
        </Container>
    );
}

export default ResetSandiForm;

import React from 'react';
import { saveAs } from 'file-saver';

import { Button, Modal, Image } from 'react-bootstrap';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function RangkumanDetail({ show, setShow, rangkumanDetail }) {
    const downloadImage = () => {
        saveAs(rangkumanDetail, rangkumanDetail)
      }

    return (
        <Modal show={show} onHide={() => setShow(prev => !prev)} className='rangkuman-modal'>
            <Modal.Header closeButton className='rangkuman-modal-header'>
                <Button variant="success" onClick={downloadImage}>
                        <FontAwesomeIcon icon={faDownload} /> Download Gambar
                    </Button>
            </Modal.Header>
            <Modal.Body className='rangkuman-modal-body'>
                <Image src={rangkumanDetail} />
            </Modal.Body>
        </Modal>
    );
}

export default RangkumanDetail;

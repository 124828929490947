import axios from "axios";
import { trainer_soalkuiscekkemampuan_endpoint } from "../../app";
import { authHeader } from "./AuthHeader";
import { uploadImage } from "../../service/GCSService";
import { soal_kuis_cek_kemampuan_list } from "../features/dummy/soal-kuis-cek-kemampuan";
import moment from 'moment';

export const listSoalKuisCekKemampuanTrainer = ({ idCekKemampuan }) => {
    return axios({
        url: trainer_soalkuiscekkemampuan_endpoint,
        method: 'GET',
        headers: { 'Content-type': 'application/json', ...authHeader() },
        params: { idCekKemampuan },
    }).then((response) => {
        console.log('List Soal Kuis Cek Kemampuan Result :', response.data);
        return response;
    });
};

export const addSoalKuisCekKemampuanTrainer = ({ tingkatan, visibilitas, soal, selectedGambar: gambar, opsiA, opsiB, opsiC, opsiD, jawaban, pembahasan, selectedGambarPembahasan: gambarPembahasan, urlReferensi, idCekKemampuan }) => {
    var current = new Date();
    let urlGambar = '';
    let urlGambarPembahasan = '';
    let promises = [];

    if (gambar) {
        const gambarName = `${gambar.name.split('.').shift()}_${moment(current).format("YYYY-MM-DD_HH'MM'SS")}.${gambar.name.split('.').pop()}`;
        promises.push(
            uploadImage({ fileName: gambarName, folderName: 'gambar-soal', file: gambar }).then(
                (response) => {
                    urlGambar = response.urlImage;
                },
                (error) => {
                    console.log(error)
                    return null;
                }
            ));
    }
    if (gambarPembahasan) {
        const gambarPembahasanName = `${gambarPembahasan.name.split('.').shift()}_${moment(current).format("YYYY-MM-DD_HH'MM'SS")}.${gambarPembahasan.name.split('.').pop()}`;
        promises.push(
            uploadImage({ fileName: gambarPembahasanName, folderName: 'gambar-pembahasan', file: gambarPembahasan }).then(
                (response) => {
                    urlGambarPembahasan = response.urlImage;
                },
                (error) => {
                    console.log(error)
                    return null;
                }
            ));
    }
    return Promise.all(promises).then(
        () => {
            return axios({
                url: trainer_soalkuiscekkemampuan_endpoint,
                method: 'POST',
                headers: {
                    'Content-type': 'application/json',
                    ...authHeader(),
                },
                data: {
                    idCekKemampuan, tingkatan, visibilitas,
                    soal, gambar: urlGambar, opsiA, opsiB, opsiC, opsiD,
                    jawaban, pembahasan, gambarPembahasan: urlGambarPembahasan, urlReferensi
                },
            }).then((response) => {
                console.log('Add Soal Kuis Cek Kemampuan Result :', response.data);
                return response;
            });
        });
};

export const editSoalKuisCekKemampuanTrainer = ({ id, tingkatan, visibilitas, soal,
    selectedGambar: gambar, opsiA, opsiB, opsiC, opsiD, jawaban, pembahasan,
    selectedGambarPembahasan: gambarPembahasan, urlReferensi, idCekKemampuan }) => {

    var current = new Date();
    let urlGambar = '';
    let urlGambarPembahasan = '';
    let promises = [];

    if (gambar) {
        const gambarName = `${gambar.name.split('.').shift()}_${moment(current).format("YYYY-MM-DD_HH'MM'SS")}.${gambar.name.split('.').pop()}`;
        promises.push(
            uploadImage({ fileName: gambarName, folderName: 'gambar-soal', file: gambar }).then(
                (response) => {
                    urlGambar = response.urlImage;
                },
                (error) => {
                    console.log(error)
                    return null;
                }
            ));
    }
    if (gambarPembahasan) {
        const gambarPembahasanName = `${gambarPembahasan.name.split('.').shift()}_${moment(current).format("YYYY-MM-DD_HH'MM'SS")}.${gambarPembahasan.name.split('.').pop()}`;
        promises.push(
            uploadImage({ fileName: gambarPembahasanName, folderName: 'gambar-pembahasan', file: gambarPembahasan }).then(
                (response) => {
                    urlGambarPembahasan = response.urlImage;
                },
                (error) => {
                    console.log(error)
                    return null;
                }
            ));
    }
    return Promise.all(promises).then(
        () => {
            return axios({
                url: trainer_soalkuiscekkemampuan_endpoint,
                method: 'PUT',
                headers: {
                    'Content-type': 'application/json',
                    ...authHeader(),
                },
                data: {
                    id, idCekKemampuan, tingkatan, visibilitas,
                    soal, gambar: urlGambar, opsiA, opsiB, opsiC, opsiD,
                    jawaban, pembahasan, gambarPembahasan: urlGambarPembahasan, urlReferensi
                },
            }).then((response) => {
                console.log('Edit Soal Kuis Cek Kemampuan Result :', response.data);
                return response;
            });
        });
};

export const deleteSoalKuisCekKemampuanTrainer = ({ id }) => {
    return axios({
        url: trainer_soalkuiscekkemampuan_endpoint,
        method: 'DELETE',
        headers: {
            'Content-type': 'application/json',
            ...authHeader(),
        },
        data: {
            id,
        },
    }).then((response) => {
        console.log('Delete Soal Kuis Cek Kemampuan Result :', response.data);
        return response;
    });
};

import axios from "axios";
import { talent_timeline_endpoint } from "../../app";
import { authHeader } from "./AuthHeader";

export const listTimelineService = ({ idMateri }) => {
    return axios({
        url: talent_timeline_endpoint,
        method: 'GET',
        headers: {'Content-type': 'application/json', ...authHeader()},
        params: { idMateri }
    }).then((response) => {
        console.log('List Timeline Result :', response.data);
        return response;
    });
};

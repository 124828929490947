import axios from "axios";
import {
  trainer_kelolaakun_endpoint,
  talent_institution_endpoint,
} from "../../app";
import { authHeader } from "./AuthHeader";

export const listAkunTrainer = () => {
  return axios({
    url: trainer_kelolaakun_endpoint,
    method: "GET",
    headers: { "Content-type": "application/json", ...authHeader() },
  }).then((response) => {
    console.log("List Akun Result :", response.data);
    return response;
  });
};

export const addAkunTrainer = ({
  username,
  namaLengkap,
  email,
  password,
  confirmPassword,
  role,
  status,
  tipeLembaga,
  asalLembaga,
}) => {
  return axios({
    url: trainer_kelolaakun_endpoint,
    method: "POST",
    headers: { "Content-type": "application/json", ...authHeader() },
    data: {
      username,
      namaLengkap,
      email,
      password,
      confirmPassword,
      role,
      status,
      tipeLembaga,
      asalLembaga,
    },
  }).then((response) => {
    console.log("Add Akun Result :", response.data);
    return response;
  });
};

export const editAkunTrainer = ({
  id: idAkun,
  username,
  namaLengkap,
  email,
  password,
  confirmPassword,
  role,
  status,
  tipeLembaga,
  asalLembaga,
}) => {
  return axios({
    url: trainer_kelolaakun_endpoint,
    method: "PUT",
    headers: { "Content-type": "application/json", ...authHeader() },
    data: {
      idAkun,
      username,
      namaLengkap,
      email,
      password,
      confirmPassword,
      role,
      status,
      tipeLembaga,
      asalLembaga,
    },
  }).then((response) => {
    console.log("Edit Akun Result :", response.data);
    return response;
  });
};

export const deleteAkunTrainer = ({ id: idAkun }) => {
  return axios({
    url: trainer_kelolaakun_endpoint,
    method: "DELETE",
    headers: {
      "Content-type": "application/json",
      ...authHeader(),
    },
    data: {
      idAkun,
    },
  }).then((response) => {
    console.log("Delete Akun Result :", response.data);
    return response;
  });
};

export const getOptLembaga = ({ tipeLembaga, keywordSearch }) => {
  return axios({
    url: talent_institution_endpoint,
    method: "GET",
    headers: { "Content-type": "application/json" },
    params: {
      tipeLembaga,
      keywordSearch,
    },
  }).then((response) => {
    console.log("Register Result :", response.data);
    return response;
  });
};

import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { GetParentPath } from '../../../common';

// Component
import { Container, Row, Col } from 'react-bootstrap';
import Thumb from './Thumb';
import RangkumanGrid from './RangkumanGrid';
import { showErrorAlert } from '../../../app';

// API
import { listRangkumanService } from '../../service/RangkumanService';
import RangkumanDetail from './RangkumanDetail';

function Rangkuman() {
    const dispatch = useDispatch();
    const [rangkumanData, setRangkumanData] = useState([]);
    const [showDetail, setShowDetail] = useState(false);
    const [rangkumanDetail, setRangkumanDetail] = useState({});

    const [searchParams, setSearchParams] = useSearchParams();
    const [idSubbab, setIdSubbab] = useState(searchParams.get('idSubbab'));
    const navigate = useNavigate();
    const parentPath = GetParentPath();

    useEffect(() => {
        setIdSubbab(searchParams.get('idSubbab'));
        if (!idSubbab) navigate(`${parentPath}/topik`);
    }, [idSubbab, parentPath, searchParams, navigate]);

    useEffect(() => {
        listRangkumanService({ idSubbab }).then(
            (response) => {
                switch (response.data.status_code) {
                    case 'RKN200':
                        setRangkumanData(response.data.message || [])
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server !'));
                        break;
                }
            },
            (error) => {
                console.log('Get Rangkuman error : ', error);
            }
        ).finally((info) => {

        });
    }, [dispatch, idSubbab]);


    return (
        <>
            <RangkumanDetail
                show={showDetail}
                setShow={setShowDetail}
                rangkumanDetail={rangkumanDetail}
            />
            <Container className="px-5 rangkuman-wrapper" fluid>
                <Row>
                    <Col className="bg-white p-4" style={{ borderRadius: '1rem' }}>
                        <RangkumanGrid>
                            {

                                rangkumanData.map(data => {
                                    let thumb = [];
                                    for (let i = 0; i < data.file.length; i++) {
                                        thumb.push(<Thumb
                                            key={data?.id}
                                            setShowDetail={setShowDetail}
                                            setRangkumanDetail={setRangkumanDetail}
                                            dataDetail={data}
                                            file={data.file[i]}
                                        />)
                                    }
                                    return thumb;
                                }
                        )
                            }
                    </RangkumanGrid>
                </Col>
            </Row>
        </Container>
        </>
    )
}

export default Rangkuman;
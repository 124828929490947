import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { MdSend } from 'react-icons/md';
import { hideErrorAlert, showErrorAlert } from '../../../app';
import { addKomentarTrainer, listKomentarTrainer } from '../../service/KomentarService';
import KomentarBubbleLeft from './KomentarBubbleLeft';
import KomentarBubbleRight from './KomentarBubbleRight';
import ReplyBubble from './ReplyBubble';
import InfiniteScroll from 'react-infinite-scroller';

export default function Komentar({ idMateri }) {
    const dispatch = useDispatch();
    const [listKomentar, setListKomentar] = useState([]);
    const [listRefKomentar, setListRefKomentar] = useState([]);

    const [newMessage, setNewMessage] = useState('');
    const [newReplyTo, setNewReplyTo] = useState('');
    const [refreshData, setRefreshData] = useState(1);
    const [hasMore, setHasMore] = useState(true);

    useEffect(() => {
        const savedTimeout = setTimeout(() => {
            if (listRefKomentar.length > 0)
                listRefKomentar[listRefKomentar.length - 1]?.current?.scrollIntoView({
                    behavior: "smooth",
                });
        }, 0);
        return function clearSavedTimeout() {
            clearTimeout(savedTimeout);
        }
    }, [refreshData]);

    const loadMoreKomentar = (page) => {
        listKomentarTrainer({ page, idMateri }).then(
            (response) => {
                switch (response.data.status_code) {
                    case 'KMT200':
                        if (response.data.message && response.data.message.length > 0) {
                            setListKomentar([...response.data.message, ...listKomentar] || []);
                            setListRefKomentar([
                                ...response.data.message.map((item, index) => (
                                    React.createRef()
                                )),
                                ...listRefKomentar
                            ] || []);
                        } else {
                            setHasMore(false);
                        }
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('List Komentar Trainer error :', error);
            }).finally((info) => {

            });
    }

    const addKomentar = () => {
        addKomentarTrainer({ idMateri, komentar: { message: newMessage, replyTo: newReplyTo } }).then(
            (response) => {
                switch (response.data.status_code) {
                    case 'KMT200':
                        setNewMessage('');
                        setNewReplyTo('');
                        setRefreshData(prev => prev + 1);
                        setListKomentar([...listKomentar, response.data.message] || []);
                        setListRefKomentar([...listRefKomentar, React.createRef()] || []);
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('Add Komentar Trainer error :', error);
            }).finally((info) => {

            });
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        dispatch(hideErrorAlert());

        if (newMessage.length > 0) {
            addKomentar();
        }
    }

    const onEnterPress = (e) => {
        if (e.keyCode === 13 && e.shiftKey === false) {
            handleSubmit(e);
        }
    }

    const komentarForm = <Form className='w-100'
        onSubmit={(e) => handleSubmit(e)}
    >
        <Row>
            <Col>
                <Form.Control as='textarea' placeholder='Komentar'
                    onKeyDown={onEnterPress}
                    value={newMessage} onChange={(e) => setNewMessage(e.target.value)}
                    className='fsz-075' style={{ height: '1rem' }}
                />
            </Col>
            <Col className='col-auto d-flex align-items-center'>
                <Button variant='light' type='submit'
                    className='bg-transparent p-0 border-0'
                    style={{ width: '2rem', height: '2rem' }}
                >
                    <MdSend color='#0078D7' style={{ width: '100%', height: '100%' }} />
                </Button>
            </Col>
        </Row>
    </Form>

    const listKomentarElement = listKomentar.map((komentar, index) => (
        komentar?.author?.isAuthor ?
            <KomentarBubbleRight {...komentar} key={index}
                setNewReplyTo={setNewReplyTo} ref={listRefKomentar[index]}
            /> :
            <KomentarBubbleLeft {...komentar} key={index}
                setNewReplyTo={setNewReplyTo} ref={listRefKomentar[index]}
            />
    ))

    return (
        <Container fluid className='p-3'>
            <Container fluid className='p-0 pe-3'
                style={{ height: `${19 - (newReplyTo ? 4.875 : 0)}rem`, overflowY: 'auto' }}
            >
                <InfiniteScroll
                    pageStart={0}
                    loadMore={loadMoreKomentar}
                    hasMore={hasMore}
                    loader={<div className='loader' key={0}>Loading ...</div>}
                    useWindow={false}
                    isReverse={true}
                    threshold={50}
                >
                    {listKomentarElement}
                </InfiniteScroll>
            </Container>
            <hr className='border-top border-1 border-dark opacity-100' />
            {newReplyTo && <ReplyBubble
                {...listKomentar.find(komentar => (komentar.komentar.idKomentar === newReplyTo))}
                setNewReplyTo={setNewReplyTo}
            />}
            {komentarForm}
        </Container>
    );
}

import axios from "axios";
import { talent_kuismateri_endpoint, talent_submitkuismateri_endpoint } from "../../app";
import { authHeader } from "./AuthHeader";
import { kuismateri_list } from "../features/dummy/kuismateri";

export const listKuisMateriTalent = ({ idSubbab, isDummy = false }) => {
    if (isDummy) {
        return new Promise((resolve, reject) => {
            resolve({
                data: {
                    status_code: 'KMA200',
                    message: kuismateri_list.filter(kuisMateri => kuisMateri.idSubbab === idSubbab)
                }
            });
        });
    }

    return axios({
        url: talent_kuismateri_endpoint,
        method: 'GET',
        headers: { 'Content-type': 'application/json', ...authHeader() },
        params: { idSubbab },
    }).then((response) => {
        console.log('List Kuis Materi Result :', response.data);
        return response;
    });
};

export const submitKuisMateriTalent = ({
    idKuisMateri, statusPengerjaan = true, isDummy = false
}) => {
    if (isDummy) {
        return new Promise((resolve, reject) => {
            resolve({
                data: {
                    status_code: 'KMA200',
                }
            });
        });
    }

    return axios({
        url: `${talent_submitkuismateri_endpoint}`,
        method: 'POST',
        headers: { 'Content-type': 'application/json', ...authHeader() },
        data: {
            idKuisMateri, statusPengerjaan,
        }
    }).then((response) => {
        console.log('Submit Kuis Materi Result :', response.data);
        return response;
    });
};

import './Benefit.css'

import group from '../../assets/group.svg';
import group1 from '../../assets/group1.svg';
import group2 from '../../assets/group2.svg';
import group3 from '../../assets/group3.svg';
import group4 from '../../assets/group4.svg';
import group5 from '../../assets/group5.svg';
import group6 from '../../assets/group6.svg';
import group7 from '../../assets/group7.svg';
import group8 from '../../assets/group8.svg';

const Benefit = ({ refContainer }) => {
  return (
    <div ref={refContainer} className="position-relative container space-container" id="1">
      <div className="container position-relative">
        <div className="row mb-5">
          <div className="col-12 text-center">
            <h2 className="fw-bold mb-2">Benefit Funtastic 79 Bootcamp</h2>
            <div className="paragraph">
              <span>
                Nikmati beragam keuntungan istimewa yang akan diperoleh dari
                Funtastic 79 Bootcamp.
              </span>
            </div>
          </div>
        </div>
        <div className="row">
          {listBenefits.map((material) => (
            <div className="col-12 col-md-6 col-lg-4 mb-4" key={material.id}>
              <div className="card card-benefit">
                <div className="d-flex column-gap-4">
                  <img className="img-content" src={material.img} />
                  <div className="d-flex flex-wrap">
                    <div className="card-title-benefit w-100">
                      {material.name}
                    </div>
                    <div className="card-content-benefit my-auto">
                      {material?.content && (
                        <ul>
                          {material?.content?.map((text) => (
                            <li key={text}>{text}</li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

const listBenefits = [
  {
    id: 1,
    img: group,
    name: 'Bootcamp GRATIS & Online',
  },
  {
    id: 2,
    img: group1,
    name: 'Mentor-mentor keren',
  },
  {
    id: 3,
    img: group2,
    name: 'Informasi Baru & Ilmu',
  },
  {
    id: 4,
    img: group3,
    name: 'Materi Persiapan Kerja',
  },
  {
    id: 5,
    img: group4,
    name: 'Pengalaman Onboarding Project & dibimbing langsung Tim PT Padepokan Tujuh Sembilan',
  },
  {
    id: 6,
    img: group5,
    name: 'Menambah Relasi',
  },
  {
    id: 7,
    img: group6,
    name: 'Reward total maksimal 20 juta untuk peserta yang lolos ke kelas teknologi',
  },
  {
    id: 8,
    img: group7,
    name: 'Benefit After Kelas Teknologi',
    content: [
      'Basic Salary',
      'BPJS Kesehatan dan Ketenagakerjaan',
      'Allowance (Fee tambahan jika onsite di Client)',
    ],
  },
  {
    id: 9,
    img: group8,
    name: 'Kemampuan meningkat',
    content: [
      'Logika Dasar',
      'Java Coding',
      'OOP',
      'S.O.L.I.D Principle',
      'SQL',
    ],
  },
]


export default Benefit

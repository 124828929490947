import axios from "axios";
import { trainer_riwayatloginall_endpoint } from "../../app";
import { authHeader } from "./AuthHeader";

export const listRiwayatLogin = (filter) => {
    return axios({
        url: trainer_riwayatloginall_endpoint,
        method: 'GET',
        headers: { 'Content-type': 'application/json', ...authHeader() },
        params:filter
    }).then((response) => {
        return response;
    });
};
import React, { useState } from 'react';
import { ToggleButton, Modal, ToggleButtonGroup, Form, Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { showErrorAlert, hideErrorAlert, showSuccessAlert, hideSuccessAlert } from '../../../app';

export default function LaporSoal({ idSoal, show, setShow, laporSoalTrainer }) {
    const dispatch = useDispatch();
    const [jenisLaporan, setJenisLaporan] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        dispatch(hideErrorAlert());
        dispatch(hideSuccessAlert());

        if (jenisLaporan === '') {
            dispatch(showErrorAlert('Pilih salah satu isu soal yang dialami'));
        } else {
            laporSoalTrainer({ idSoal, jenisLaporan }).then(
                (response) => {
                    switch (response.data.status_code) {
                        case 'LPS200':
                            dispatch(showSuccessAlert('Isu berhasil dilaporkan'));
                            setShow(prev => !prev);
                            break;
                        default:
                            dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                            break;
                    }
                },
                (error) => {
                    console.log('Lapor Soal error :', error);
                }).finally((info) => {

                });
        }
    }

    const laporanJenisList = ["Pertanyaan Membingungkan", 
    "Jawaban Tidak Muncul", 
    "Jawaban Salah",
    "Soal Tidak Sesuai Dengan Topik Pembahasan", 
    "Pembahasan Kurang Memuaskan"];

    const toggleButtonList = laporanJenisList.map((item, index) => {
        return (
            <div key={index} className="p-3 border rounded-laporan m-3" 
            onClick={(e) => {setJenisLaporan(e.currentTarget.firstChild.value.toLowerCase())}}>
                <ToggleButton name="laporan-radio" id={`laporan-opsi-${index}`}
                    value={item}
                    type="checkbox"
                    className="h-100 rounded-circle me-3"
                    variant="outline-success"
                    checked={item.toLowerCase() === jenisLaporan}
                />
                {item}
            </div>
        )
    })

    return (
        <Modal id="laporan-soal" show={show} onHide={() => setShow(prev => !prev)} centered size='lg'>
            <Modal.Header closeButton>
                <Modal.Title>Laporkan</Modal.Title>
            </Modal.Header>
            <Form onSubmit={(e) => handleSubmit(e)}>
                <Modal.Body>
                    <div>Kenapa Melaporkan soal ini?</div>
                        {toggleButtonList}
                </Modal.Body>
                <Modal.Footer>
                    <Button type='submit' variant='primary'>Laporkan</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

import React, { useState, useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { showErrorAlert } from "../../../app";

// component
import { Table } from "../../common/table";
import { columns } from "./columns";
import { pushCrumb, popCrumb } from "../../common/breadcrumbs";
import FileSaver from "file-saver";
import {
  Button,
  Modal,
  Form,
  Row,
  Col,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import {
  getAllProvince,
  getAllCity
} from "../../service/MasterLembagaService";
import Select from "react-select";
import _ from "lodash";

function MasterLembagaPopup({ show, type, onClose, data, fetchData }) {
  const dispatch = useDispatch();
  const [value, setValue] = useState({
    institutionName: null,
    institutionType: {
      value: "",
      label: "Pilih Tipe Lembaga",
    },
    institutionProvince: {
      value: "",
      label: "Pilih Provinsi",
    },
    institutionCity: {
      value: "",
      label: "Pilih Kabupaten/Kota",
      id_provinsi: ""
    }
  });

  const [validateChar, setValidateChar] = useState(true);

  const tipeLembagaOptions = [
    { value: "1", label: "Universitas" },
    { value: "2", label: "Sekolah" },
  ];

  const [provinsiOptions, setProvinsiOptions] = useState([]);
  const [kabKotaOptions, setKabKotaOptions] = useState([]);

  const getProvinsi = () => {
    getAllProvince({provinceId: value.institutionCity.id_provinsi})
      .then(
        (response) => {
          switch (response.data.status_code) {
            case "PVS200":
              const arr = response.data.message.map((e) => ({
                value: e.id_provinsi,
                label: e.nama_provinsi,
              }))
              setProvinsiOptions(arr);
              setValue((prevValue) => ({
                ...prevValue,
                institutionProvince: {
                  value: response.data.message[0].id_provinsi,
                  label: response.data.message[0].nama_provinsi
                },
              }));
              break;
            case "PVS555":
              
            break;
            default:
              dispatch(showErrorAlert("Terjadi kesalahan pada server!"));
              break;
          }
        },
        (error) => {
          console.log("Verifkasi error :", error);
        }
      )
      .finally((info) => {});
  };

  const getKabKota = () => {
    getAllCity()
      .then(
        (response) => {
          switch (response.data.status_code) {
            case "CT200":
              const arr = response.data.message.map((e) => ({
                value: e.id_kab_kota,
                label: e.nama_kab_kota,
                id_provinsi: e.id_provinsi
              }));
              setKabKotaOptions(arr);
              break;
              case "CT555":
              
              break;
            default:
              dispatch(showErrorAlert("Terjadi kesalahan pada server!"));
              break;
          }
        },
        (error) => {
          console.log("Verifkasi error :", error);
        }
      )
      .finally((info) => {});
  };


  useEffect(() => {
    getKabKota();
    setValue({
      institutionName: type === "edit" ? data?.institution_name : null,
      institutionType: type === "edit" && {
        value: data?.institution_type_code,
        label: data?.institution_type_name,
      },
      institutionProvince: type === "edit" && {
        value: data?.institution_province_id,
        label: data?.institution_province,
      },
      institutionCity: type === "edit" && {
        value: data?.institution_city_id,
        label: data?.institution_city,
      },
    });
  }, [show]);

  useEffect(() => {
    getProvinsi();
  }, [value.institutionCity])
  

  const isRequired = (
    <Form.Control.Feedback type="invalid">
      Field is Required
    </Form.Control.Feedback>
  );

  const handleChange = (val, e) => {
    setValue({ ...value, [val]: e });
  };

  const textField = (label, val, required) => {
    return (
      <Row className="mx-0 mb-3">
        <Col>
          <Form.Group>
            <Form.Label>
              {label} {required && <span style={{ color: "red" }}>*</span>}
            </Form.Label>
            <Form.Control
              placeholder={`Masukan ${label}`}
              value={type === "view" && !value[val] ? "-" : value[val]}
              onChange={(e) => {
                handleChange(val, e.target.value);
              }}
              type={val === "email" ? "email" : "text"}
              isInvalid={value[val] === "" || value[val]?.length > 255}
              disabled={type === "view"}
            />
            <>
              {value[val] === "" ? (
                required && isRequired
              ) : (
                <Form.Control.Feedback type="invalid">
                  Maksimal 255 Karakter
                </Form.Control.Feedback>
              )}
            </>
          </Form.Group>
        </Col>
      </Row>
    );
  };

  const handleSelect = (e, val) => {
    setValue({ ...value, [val]: e });
  };

  const selectField = (label, val, options, required, disabled) => {
    return (
      <Row className="mx-0 mb-3">
        <Col>
          <Form.Group>
            <Form.Label>
              {label} {required && <span style={{ color: "red" }}>*</span>}
            </Form.Label>
            <Select
              value={value[val]}
              noOptionsMessage={() => "No options"}
              placeholder={`Pilih ${label}`}
              options={options}
              onChange={(e) => handleSelect(e, val)}
              isDisabled={type === "view" || disabled}
            />
            {required && isRequired}
          </Form.Group>
        </Col>
      </Row>
    );
  };

  console.log("validate", validateChar);

  return (
    <Modal show={show} onHide={onClose} centered backdrop="static" size="md">
      <Modal.Header closeButton>
        <Modal.Title>
          {type === "add" ? "Tambah Lembaga" : "Edit Lembaga"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {selectField(
          "Jenis Lembaga",
          "institutionType",
          tipeLembagaOptions,
          true
        )}
        {textField("Nama Lembaga", "institutionName", true)}
        {selectField(
          "Kabupaten/Kota",
          "institutionCity",
          kabKotaOptions,
          true
        )}
        {selectField(
          "Provinsi",
          "institutionProvince",
          provinsiOptions,
          false,
          true
        )}
      </Modal.Body>
      <Modal.Footer>
        <div className="w-100 px-2 d-flex flex-row align-items-center justify-content-between">
          <Button
            variant="light"
            onClick={onClose}
            style={{
              color: "#0078D7",
              border: "1px solid #0078D7",
              width: 100,
            }}
          >
            Batal
          </Button>
          <Button
            type="submit"
            variant="primary"
            disabled={
              Object.values(value).some((x) => !x || x === undefined) ||
              value?.institutionName?.length > 255
            }
            onClick={() => fetchData(value)}
            style={{ width: 100 }}
          >
            Simpan
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default MasterLembagaPopup;

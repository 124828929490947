import React, { useEffect, useState } from 'react';
import { Accordion, Stack } from 'react-bootstrap';
import { MdLibraryBooks, MdSchedule } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { showErrorAlert } from '../../../app';
import { GetParentPath } from '../../../common';
import { Timeline, TimelineHeader } from '../../common/timeline';
import { getBabTalent } from '../../service/BabService';
import { listSubbabTalent } from '../../service/SubbabService';

function BabAccordionTimeline({ isLastSeen, idBab, disableHeaderLink }) {
    const dispatch = useDispatch();
    const [bab, setBab] = useState({});
    const [listSubbab, setListSubbab] = useState([]);

    const navigate = useNavigate();
    const parentPath = GetParentPath();
    const [idSubbab, setIdSubbab] = useState('');

    useEffect(() => {
        if (idSubbab) navigate(`${parentPath}/materi?idSubbab=${idSubbab}`);
    }, [idSubbab, parentPath, navigate]);

    useEffect(() => {
        getBabTalent({ id: idBab }).then(
            (response) => {
                switch (response.data.status_code) {
                    case 'BAB200':
                        setBab(response.data.message || {});
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('Bab error :', error);
            }).finally((info) => {

            });
    }, [idBab, dispatch]);

    useEffect(() => {
        listSubbabTalent({ idBab }).then(
            (response) => {
                switch (response.data.status_code) {
                    case 'SBB200':
                        setListSubbab(response.data.message || []);
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('Subbab error :', error);
            }).finally((info) => {

            });
    }, [idBab, dispatch]);

    const colorMap = isLastSeen ? {
        primary: '#FFFFFF',
        secondary: '#FFFFFF',
        tertiary: '#FFFFFF',
    } : {
        primary: '#757575',
        secondary: '#263238',
        tertiary: '#979797',
    };

    const styleMap = isLastSeen ? {
        '--border-color-acordion': '#FFFFFF',
    } : {
        '--border-color-acordion': '#263238',
    };

    const title = bab?.nama;
    const tag = bab?.tag;

    const timelineHeaderItems = [{
        icon: <MdLibraryBooks className='me-2 timeline-header-icon' style={{ color: colorMap.primary }} />,
        label: <span style={{ color: colorMap.secondary }}>
            <span style={{ color: colorMap.tertiary }}>{(bab?.subbabSelesai || bab?.subbabSelesai === 0) ? bab?.subbabSelesai : "-"}</span>&nbsp;
            <span className='fw-bold'>/ {(bab?.totalSubbab || bab?.totalSubbab === 0) ? bab?.totalSubbab : "-"} Sub-Bab</span>
        </span>
    }, {
        icon: <MdLibraryBooks className='me-2 timeline-header-icon' style={{ color: colorMap.primary }} />,
        label: <span style={{ color: colorMap.secondary }}>
            <span style={{ color: colorMap.tertiary }}>{(bab?.kuisSelesai || bab?.kuisSelesai === 0) ? bab?.kuisSelesai : "-"}</span>&nbsp;
            <span className='fw-bold'>/ {(bab?.totalKuis || bab?.totalKuis === 0) ? bab?.totalKuis : "-"} Kuis</span>
        </span>
    }, {
        icon: <MdSchedule className='me-2 timeline-header-icon' style={{ color: colorMap.primary }} />,
        label: <span style={{ color: colorMap.secondary }}>
            <span style={{ color: colorMap.tertiary }}>{(bab?.durasiVideoDitonton || bab?.durasiVideoDitonton === 0) ? Math.ceil(bab?.durasiVideoDitonton / 60) : "-"}</span>&nbsp;
            <span className='fw-bold'>/ {(bab?.totalDurasiVideo || bab?.totalDurasiVideo === 0) ? Math.ceil(bab?.totalDurasiVideo / 60) : "-"} Menit</span>
        </span>
    },];

    const timelineItems = listSubbab.map((subbab, index) => (
        {
            name: subbab.nama,
            active: subbab.isUnlocked,
            last: subbab.id === bab.terakhirDipelajari,
            onClick: () => setIdSubbab(subbab.id)
        }
    ));

    return (
        <Accordion defaultActiveKey='0' className='mb-3'>
            <Accordion.Item eventKey='0'
                style={{
                    borderRadius: '16px',
                    ...styleMap,
                    ...(isLastSeen ?
                        { background: 'linear-gradient(90deg, rgba(0, 120, 215, 0.6) 0%, #0078D7 100%)' } : {})
                }}
            >
                <Accordion.Header
                    className={`accordion-timeline-header ${isLastSeen ? 'last-seen' : ''}`}
                >
                    <Stack style={{ color: colorMap.secondary }}>
                        {disableHeaderLink ?
                            <>
                                <div className='fw-bold'>
                                    {title}
                                </div>
                                <div className='fw-light fsz-075'>Tag : {tag}</div>
                            </> :
                            <Link to={`${parentPath}/subbab?idBab=${idBab}`}
                                style={{ textDecoration: 'none', color: 'inherit' }}
                            >
                                <div className='fw-bold'>
                                    {title}
                                </div>
                                <div className='fw-light fsz-075'>Tag : {tag}</div>
                            </Link>
                        }
                    </Stack>
                </Accordion.Header>
                <Accordion.Body>
                    <TimelineHeader items={timelineHeaderItems} />
                    <Timeline items={timelineItems} isLastSeen={isLastSeen} />
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
}

export default BabAccordionTimeline;

import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { showErrorAlert } from '../../../app';
import { Table } from '../../common/table';
import { columns } from './columns';
import KuisBabPopUp from './KuisBabPopup';
import KuisBabPopUpDelete from './KuisBabPopupDelete';
import KuisBabDetail from './KuisBabDetail';
import KuisBabDeleteConfirmation from './KuisBabDeleteConfirmation';
import { listKuisBabTrainer } from '../../service/KuisBabService';
import { listTopikTrainer } from '../../service/TopikService';
import { listBabTrainer } from '../../service/BabService';
import { pushCrumb, popCrumb } from '../../common/breadcrumbs';

function KuisBab() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [id, setId] = useState(searchParams.get('id'));

    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const [showPopUp, setShowPopUp] = useState(false);
    const [showPopUpDelete, setShowPopUpDelete] = useState(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [kuisBabData, setKuisBabData] = useState();
    const [refreshData, setRefreshData] = useState(1);

    const [topikList, setTopikList] = useState([]);
    const [babList, setBabList] = useState([]);

    useEffect(() => {
        listKuisBabTrainer().then(
            (response) => {
                switch (response.data.status_code) {
                    case 'KBB200':
                        setData(response.data.message || []);
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('Kuis Bab error :', error);
            }).finally((info) => {

            });
    }, [dispatch, refreshData]);

    useEffect(() => {
        setId(searchParams.get('id'));
        if (searchParams.get('id')) {
            dispatch(pushCrumb("Detail Kuis"));
        } else {
            dispatch(popCrumb("Detail Kuis"));
        }
    }, [searchParams]);

    useEffect(() => {
        listTopikTrainer().then(
            (response) => {
                switch (response.data.status_code || []) {
                    case 'TPK200':
                        setTopikList(response.data.message);
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('List Topik error :', error);
            }).finally((info) => {

            });
    }, [dispatch]);

    useEffect(() => {
        listBabTrainer().then(
            (response) => {
                switch (response.data.status_code) {
                    case 'BAB200':
                        setBabList(response.data.message || []);
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('List Bab error :', error);
            }).finally((info) => {

            });
    }, [dispatch]);

    const addFunction = () => {
        setKuisBabData();
        setIsEdit(false);
        setShowPopUp(prev => !prev);
    }

    const editFunction = (row) => {
        setKuisBabData(row);
        setIsEdit(true);
        setShowPopUp(prev => !prev);
    }

    const deleteFunction = (row) => {
        setKuisBabData(row);
        setShowPopUpDelete(prev => !prev);
    }

    const detailFunction = (row) => {
        setId(row.id);
        setKuisBabData(row);
        setSearchParams({ id: row.id });
    }

    return (
        <>
            {!id && <KuisBabPopUp setRefreshData={setRefreshData}
                show={showPopUp} setShow={setShowPopUp}
                kuisBabData={kuisBabData} isEdit={isEdit}
                topikList={topikList}
                babList={babList}
            />}
            {!id && <KuisBabPopUpDelete 
                show={showPopUpDelete} setShow={setShowPopUpDelete}
                kuisBabData={kuisBabData}
                setShowDeleteConfirmation={setShowDeleteConfirmation}
            />}
            {!id && <KuisBabDeleteConfirmation 
                setRefreshData={setRefreshData}
                show={showDeleteConfirmation}
                setShow={setShowDeleteConfirmation}
                kuisBabData={kuisBabData}
                setShowPopUpDelete={setShowPopUpDelete}
            />}
            {id && <KuisBabDetail
                setSearchParams={setSearchParams}
                kuisBabData={data.find(item => item.id === id)}
            />}
            {!id && <Table
                data={data}
                rowClickFunction={detailFunction}
                columns={columns(editFunction, deleteFunction)}
                addFunction={addFunction}
                title='Kuis Bab Digital Bootcamp 79' />
            }
        </>
    );
}

export default KuisBab;




import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Button, Card, Col, Form, InputGroup, Row } from 'react-bootstrap';
import InputMask from 'react-input-mask';
import { addTimelineTrainer } from '../../service/TimelineService';
import { useDispatch } from 'react-redux';
import {
    showErrorAlert, hideErrorAlert,
    showSuccessAlert, hideSuccessAlert,
} from '../../../app';

function timeStringtoSecond(timeString) {
    var timeSplit = timeString.split(':');
    var seconds = (+timeSplit[0]) * 60 * 60 + (+timeSplit[1]) * 60 + (+timeSplit[2]);
    return seconds;
}

function TimelinePillAdd({ idMateri, setRefreshData, durasi }) {
    const dispatch = useDispatch();
    const [timestampValue, setTimestampValue] = useState();
    const [timeSecond, setTimeSecond] = useState();
    const [judulValue, setJudulValue] = useState();

    const timestampField = <Form.Group>
        <InputMask className='form-control text-center py-0 px-1 fsz-0875 timeline-input'
            placeholder='00:00:00'
            value={timestampValue}
            mask="99:59:59"
            formatChars={{
                '5': '[0-5]',
                '9': '[0-9]',
                'a': '[A-Za-z]',
                '*': '[A-Za-z0-9]'
            }}
            onChange={(e) => {
                setTimestampValue(e.target.value)
                setTimeSecond(timeStringtoSecond(e.target.value));
            }}
        />
    </Form.Group>

    const judulField = <Form.Group>
        <Form.Control className='py-0 px-2 fsz-0875 timeline-input'
            placeholder='Judul Timeline'
            value={judulValue}
            onChange={(e) => setJudulValue(e.target.value)}
        />
    </Form.Group>

    const actionField = <Form.Group className='w-100 text-end'>
        <InputGroup className='w-100 d-flex justify-content-end align-items-center'>
            <Button className='p-0 fsz-0875 w-100'
                type='submit'>
                Simpan
            </Button>
        </InputGroup>
    </Form.Group>

    const addTimeline = () => {
        addTimelineTrainer({
            idMateri, timestamp: timeSecond, judul: judulValue
        }).then(
            (response) => {
                switch (response.data.status_code) {
                    case 'TML200':
                        dispatch(showSuccessAlert('Timeline berhasil ditambah'));
                        setRefreshData(prev => prev + 1);
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('Add Timeline error :', error);
            }).finally((info) => {

            });
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        dispatch(hideErrorAlert());
        dispatch(hideSuccessAlert());

        if (!judulValue) {
            dispatch(showErrorAlert('Isi judul!'));
        } else if (!timeSecond) {
            dispatch(showErrorAlert('Isi timestamp!'));
        } else if (timeSecond > durasi) {
            dispatch(showErrorAlert('Timestamp tidak boleh melebihi durasi video'));
        } else {
            addTimeline();
        }
    }

    return (
        <Card className='px-4 py-2 mb-2'
            style={{ boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}
        >
            <Form onSubmit={(e) => handleSubmit(e)}>
                <Row>
                    <Col className='col-2 px-1'>
                        {timestampField}
                    </Col>
                    <Col className='col-8 px-1'>
                        {judulField}
                    </Col>
                    <Col className='col-2 px-1'>
                        {actionField}
                    </Col>
                </Row>
            </Form>
        </Card>
    );
}

export default TimelinePillAdd;


import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { MdSend } from "react-icons/md";
import { hideErrorAlert, showErrorAlert } from "../../../app";
import {
  addKomentarTrainer,
  listKomentarTrainer,
} from "../../service/KomentarService";
import InfiniteScroll from "react-infinite-scroller";
import Statistik from "./Statistik";
import StatusTalent from "./StatusTalent";
import ReportBelajar from "./ReportBelajar";
import ProgressBelajar from "./ProgressBelajar";
import TalentInstitution from "./TalentInstitution";

export default function Dashboard({ idMateri }) {
  const dispatch = useDispatch();

  useEffect(() => {}, []);

  return (
    <Container fluid className="p-3">
      <Row>
        <Col>
          <StatusTalent />
        </Col>
      </Row>
      <Row>
        <Col>
          <Statistik />
        </Col>
      </Row>
      <Row>
        <Col>
          <TalentInstitution />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <ReportBelajar />
        </Col>
        <Col xs={12}>
          <ProgressBelajar />
        </Col>
      </Row>
    </Container>
  );
}

import React from 'react';
import { Col, Row } from 'react-bootstrap';

function TimelineHeader({ items = [] }) {
    return (
        <>
            {items.length > 0 && <Row
                className='justify-content-center gap-4'
            >
                {items.map((item, index) => (
                    <Col key={index}
                        className='col-auto d-flex align-items-center'
                    >
                        {item.icon}
                        {item.label}
                    </Col>
                ))}
            </Row>}
        </>
    );
}

export default TimelineHeader;

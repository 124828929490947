import axios from "axios";
import { talent_materiterakhir_endpoint, talent_materi_endpoint, talent_submitmateri_endpoint } from "../../app";
import { authHeader } from "./AuthHeader";

export const listMateriTalent = ({ idSubbab }) => {
    return axios({
        url: talent_materi_endpoint,
        method: 'GET',
        headers: { 'Content-type': 'application/json', ...authHeader() },
        params: { idSubbab },
    }).then((response) => {
        console.log('List Materi Result :', response.data);
        return response;
    });
};

export const getMateriTalent = ({ id }) => {
    return axios({
        url: `${talent_materi_endpoint}/${id}`,
        method: 'GET',
        headers: { 'Content-type': 'application/json', ...authHeader() },
    }).then((response) => {
        console.log('Get Materi Result :', response.data);
        return response;
    });
};

export const updateProgressMateriTalent = ({ id, timestamp }) => {
    return axios({
        url: `${talent_materiterakhir_endpoint}`,
        method: 'PUT',
        headers: { 'Content-type': 'application/json', ...authHeader() },
        data: { id, timestamp }
    }).then((response) => {
        console.log('Update Materi Terakhir Dipelajari Result :', response.data);
        return response;
    });
};

export const submitProgressMateriTalent = ({ idMateri, statusPengerjaan = true }) => {
    return axios({
        url: `${talent_submitmateri_endpoint}`,
        method: 'POST',
        headers: { 'Content-type': 'application/json', ...authHeader() },
        data: { idMateri, statusPengerjaan }
    }).then((response) => {
        console.log('Submit Materi Progress Result :', response.data);
        return response;
    });
};

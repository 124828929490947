import axios from 'axios';
import { base_url, port_learning, trainer_subbab_endpoint } from '../../app';
import { authHeader } from './AuthHeader';

export const listSubbabTrainer = () => {
    return axios({
        url: trainer_subbab_endpoint,
        method: 'GET',
        headers: {
            'Content-type': 'application/json',
            ...authHeader(),
        },
    }).then((response) => {
        console.log('List Subbab Result :', response.data);
        return response;
    });
};

export const addSubbabTrainer = ({ nama, idTopik, idBab, skalaNilai, visibilitas, tag }) => {
    return axios({
        url: trainer_subbab_endpoint,
        method: 'POST',
        headers: {
            'Content-type': 'application/json',
            ...authHeader(),
        },
        data: {
            nama, idTopik, idBab, skalaNilai, visibilitas, tag,
        },
    }).then((response) => {
        console.log('Add Subbab Result :', response.data);
        return response;
    });
};

export const editSubbabTrainer = ({ id, nama, idTopik, idBab, skalaNilai, visibilitas, tag }) => {
    return axios({
        url: trainer_subbab_endpoint,
        method: 'PUT',
        headers: {
            'Content-type': 'application/json',
            ...authHeader(),
        },
        data: {
            id, nama, idTopik, idBab, skalaNilai, visibilitas, tag,
        },
    }).then((response) => {
        console.log('Edit Subbab Result :', response.data);
        return response;
    });
};

export const deleteSubbabTrainer = ({ id }) => {
    return axios({
        url: trainer_subbab_endpoint,
        method: 'DELETE',
        headers: {
            'Content-type': 'application/json',
            ...authHeader(),
        },
        data: {
            id,
        },
    }).then((response) => {
        console.log('Delete Subbab Result :', response.data);
        return response;
    });
};

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { showErrorAlert } from '../../../app';
import { Table } from '../../common/table';
import { listBabTrainer } from '../../service/BabService';
import { listMateriTrainer } from '../../service/MateriService';
import { listSubbabTrainer } from '../../service/SubbabService';
import { listTagTrainer } from '../../service/TagService';
import { listTopikTrainer } from '../../service/TopikService';
import { columns } from './columns';
import MateriPopUp from './MateriPopUp';
import MateriPopUpDelete from './MateriPopUpDelete';
import MateriPopUpDetail from './MateriPopUpDetail';
import MateriDeleteConfirmation from './MateriDeleteConfirmation';

function Materi() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [id, setId] = useState(searchParams.get('id'));

    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const [showPopUp, setShowPopUp] = useState(false);
    const [showPopUpDelete, setShowPopUpDelete] = useState(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [materiData, setMateriData] = useState();

    const [refreshData, setRefreshData] = useState(1);
    const [topikList, setTopikList] = useState([]);
    const [babList, setBabList] = useState([]);
    const [subbabList, setSubbabList] = useState([]);
    const [tagList, setTagList] = useState([]);
    
    useEffect(() => {
        listTopikTrainer().then(
            (response) => {
                switch (response.data.status_code) {
                    case 'TPK200':
                        setTopikList(response.data.message || []);
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('Topik error :', error);
            }).finally((info) => {

            });
    }, [dispatch]);

    useEffect(() => {
        listBabTrainer().then(
            (response) => {
                switch (response.data.status_code) {
                    case 'BAB200':
                        setBabList(response.data.message || []);
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('Bab error :', error);
            }).finally((info) => {

            });
    }, [dispatch]);

    useEffect(() => {
        listSubbabTrainer().then(
            (response) => {
                switch (response.data.status_code) {
                    case 'SBB200':
                        setSubbabList(response.data.message || []);
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('Subbab error :', error);
            }).finally((info) => {

            });
    }, [dispatch]);

    useEffect(() => {
        listTagTrainer().then(
            (response) => {
                switch (response.data.status_code) {
                    case 'TAG200':
                        setTagList(response.data.message);
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('Tag error :', error);
            }).finally((info) => {

            });
    }, [dispatch]);

    useEffect(() => {
        listMateriTrainer().then(
            (response) => {
                switch (response.data.status_code) {
                    case 'MAT200':
                        setData(response.data.message || []);
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('Materi error :', error);
            }).finally((info) => {

            });
    }, [dispatch, refreshData]);

    useEffect(() => {
        setId(searchParams.get('id'));
    }, [searchParams]);

    const addFunction = () => {
        setMateriData();
        setIsEdit(false);
        setShowPopUp(prev => !prev);
    }

    const editFunction = (row) => {
        setMateriData(row);
        setIsEdit(true);
        setShowPopUp(prev => !prev);
    }

    const deleteFunction = (row) => {
        setMateriData(row);
        setShowPopUpDelete(prev => !prev);
    }

    const detailFunction = (row) => {
        setId(row.id);
        setMateriData(row);
        setSearchParams({id: row.id});
    }

    return (
        <>
            {!id && <MateriPopUp
                setRefreshData={setRefreshData}
                topikList={topikList}
                babList={babList}
                subbabList={subbabList}
                tagList={tagList}
                show={showPopUp} setShow={setShowPopUp}
                materiData={materiData} isEdit={isEdit}
            />}
            {!id && <MateriPopUpDelete
                show={showPopUpDelete}
                setShow={setShowPopUpDelete}
                materiData={materiData}
                setShowDeleteConfirmation={setShowDeleteConfirmation}
            />}
            {!id && <MateriDeleteConfirmation
                setRefreshData={setRefreshData}
                show={showDeleteConfirmation}
                setShow={setShowDeleteConfirmation}
                materiData={materiData}
                setShowPopUpDelete={setShowPopUpDelete}
            />}
            {!id && <Table data={data}
                rowClickFunction={detailFunction}
                columns={columns(editFunction, deleteFunction)}
                addFunction={addFunction}
                title='Video Materi Digital Bootcamp 79'
            />}
            {id && <MateriPopUpDetail 
                materiData={data.find(item => item.id === id)}
            />}
        </>
    );
}

export default Materi;

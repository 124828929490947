import React, { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import moment from 'moment';
import 'moment/locale/id';
import { default as ScreenshotThumb } from './ScreenshotThumb';
import uuid from 'react-uuid';

export default function ScreenshotDateGrid({ date, data, setShowPopup, setShowPopupDelete, setThumbData }) {
    moment.locale("id");

    return (
        <Row id='screenshot-date-grid-container'>
            <div className='screenshot-date mt-4'>{date.format("DD MMMM YYYY")}</div>
            <hr />
            <Row lg={5} className="screenshot-grid g-4 mt-0">
                {
                    data.map(item => {
                        return <ScreenshotThumb setShowPopup={setShowPopup} setShowPopupDelete={setShowPopupDelete} setThumbData={setThumbData} data={item} key={uuid()} />
                    })
                }
            </Row>
        </Row>
    )
}
import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { showErrorAlert } from '../../../app';

// component
import { Table } from '../../common/table';
import { columns } from './columns';
import { pushCrumb, popCrumb } from '../../common/breadcrumbs';
import LaporanNilaiDetail from './LaporanNilaiDetail';

// service
import { listLaporanNilai } from '../../service/LaporanNilaiService';

function LaporanNilai() {
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const [idTalent, setIdTalent] = useState(searchParams.get('idTalent'));

    const [data, setData] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [laporanNilaiData, setLaporanNilaiData] = useState();
    const [refreshData, setRefreshData] = useState(1);

    useEffect(() => {
        listLaporanNilai().then(
            (response) => {
                switch (response.data.status_code) {
                    case 'LNI200':
                        setData(response.data.message.daftarNilai || []);
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('Kuis Akhir error :', error);
            }).finally((info) => {

            });
    }, [dispatch, refreshData]);

    useEffect(() => {
        setIdTalent(searchParams.get('idTalent'));
        // if (searchParams.get('idTalent')) {
        //     dispatch(pushCrumb("Nilai"));
        // } else {
        //     dispatch(popCrumb("Nilai"));
        // }
    }, [searchParams, dispatch]);

    const detailFunction = (row) => {
        setIdTalent(row.idTalent);
        setLaporanNilaiData(row);
        setSearchParams({ idTalent: row.idTalent });
    }

    return (
        <>
            {idTalent && <LaporanNilaiDetail
                idTalentRow={idTalent}
                laporanNilaiData={data.find(item => item.idTalent === idTalent)}
                setSearchParams={setSearchParams}
            />}
            {!idTalent && <Table
                data={data}
                rowClickFunction={detailFunction}
                columns={columns()}
                showAddButton={false}
                showFilter={false}
                title='Laporan Nilai Digital Bootcamp 79' />
            }
        </>
    );
}

export default LaporanNilai;




import axios from 'axios';
import jwtDecode from 'jwt-decode';
import {
    trainer_auth_endpoint,
    trainer_forgot_password_endpoint,
    trainer_reset_password_endpoint
} from '../../app';

export const loginUser = (username, password) => {
    return axios({
        url: trainer_auth_endpoint,
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
        auth: { username: username, password: password },
    }).then((response) => {
        console.log('Auth Result :', response.data);
        if (response.data.message.jwt) {
            localStorage.setItem('json-web-token-trainer', response.data.message.jwt);
        }
        return response;
    });
};

export const logoutUser = () => {
    return new Promise((resolve, reject) => {
        localStorage.removeItem('json-web-token-trainer');
        resolve('success');
    });
};

export const forgotPasswordUser = (username) => {
    return axios({
        url: trainer_forgot_password_endpoint,
        method: 'POST',
        headers: { 'Content-type': 'application/json'},
        data: { username: username },
    }).then((response) => {
        console.log('Forgot Password Result :', response.data);
        return response;
    });
};

export const resetPasswordUser = ({password, token}) => {
    return axios({
        url: trainer_reset_password_endpoint,
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
        data: { password },
        params: { token }
    }).then((response) => {
        console.log('Reset Password Result :', response.data);
        return response;
    });
};

export const getTokenUsername = () => {
  const token = localStorage.getItem('json-web-token-trainer');
  if (token) {
    const decodedToken = jwtDecode(token);
    const username = decodedToken.name;
    return username;
  } else {
    console.log('Token JWT tidak ada atau kedaluwarsa.');
    return null;
  }
};
import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { MdLibraryBooks, MdSchedule } from 'react-icons/md';
import { RiAccountBoxFill } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import { GetParentPath } from '../../../common';
import { Timeline, TimelineHeader } from '../../common/timeline';

function MainTimelineBab({ babList, topik }) {
    const navigate = useNavigate();
    const parentPath = GetParentPath();
    const [idBab, setIdBab] = useState('');
    
    useEffect(() => {
        if (idBab) navigate(`${parentPath}/subbab?idBab=${idBab}`);
    }, [idBab, parentPath, navigate]);

    const renderTingkatanStyle = (tingkatan) => {
        switch (tingkatan) {
            case 'mudah':
                return { label: 'Mudah', color: '#34A853' };
            case 'sedang':
                return { label: 'Sedang', color: '#FBBC04' };
            case 'sulit':
                return { label: 'Sulit', color: '#EA4335' };
            default:
                return { label: '', color: '#000000' };
        }
    }

    const renderTingkatan = (tingkatan) => {
        const style = renderTingkatanStyle(tingkatan);
        return (
            <span style={{ color: style.color }}
                className='fw-bold'
            >
                {style.label}
            </span>
        );
    }

    const timelineHeaderItems = [{
        icon: <MdLibraryBooks className='me-2 timeline-header-icon' style={{ color: '#757575' }} />,
        label: <span style={{ color: '#263238' }}>
            <span style={{ color: '#979797' }}>{topik?.babSelesai}</span>&nbsp;
            <span className='fw-bold'>/ {topik?.totalBab} Bab</span>
        </span>
    }, {
        icon: <MdLibraryBooks className='me-2 timeline-header-icon' style={{ color: '#757575' }} />,
        label: <span style={{ color: '#263238' }}>
            <span style={{ color: '#979797' }}>{(topik?.subbabSelesai || topik?.subbabSelesai === 0) ? topik?.subbabSelesai : "-"}</span>&nbsp;
            <span className='fw-bold'>/ {(topik?.totalSubbab || topik?.totalSubbab === 0) ? topik?.totalSubbab : "-"} Sub-Bab</span>
        </span>
    }, {
        icon: <MdLibraryBooks className='me-2 timeline-header-icon' style={{ color: '#757575' }} />,
        label: <span style={{ color: '#263238' }}>
            <span style={{ color: '#979797' }}>{(topik?.kuisSelesai || topik?.kuisSelesai === 0) ? topik?.kuisSelesai : "-"}</span>&nbsp;
            <span className='fw-bold'>/ {(topik?.totalKuis || topik?.totalKuis === 0) ? topik?.totalKuis : "-"} Kuis</span>
        </span>
    }, {
        icon: <MdSchedule className='me-2 timeline-header-icon' style={{ color: '#757575' }} />,
        label: <span style={{ color: '#263238' }}>
            <span style={{ color: '#979797' }}>{(topik?.durasiVideoDitonton || topik?.durasiVideoDitonton === 0) ? Math.ceil(topik?.durasiVideoDitonton/60) : "-"}</span>&nbsp;
            <span className='fw-bold'>/ {(topik?.totalDurasiVideo || topik?.totalDurasiVideo === 0) ? Math.ceil(topik?.totalDurasiVideo/60) : "-"} Menit</span>
        </span>
    }, {
        icon: <RiAccountBoxFill className='me-2 timeline-header-icon' style={{ color: '#757575' }} />,
        label: <span style={{
            color: '#263238'
        }}>
            <span className='fw-bold'>Tingkatan</span>&nbsp;
            {renderTingkatan(topik?.tingkatan)}
        </span>
    },];

    const timelineItems = babList.map((bab, index) => (
        {
            name: bab.nama,
            active: bab.isUnlocked,
            last: bab.id === topik.terakhirDipelajari,
            onClick: () => setIdBab(bab.id)
        }
    ));

    return (
        <Container fluid
            className='mb-3 p-4 bg-white'
            style={{ borderRadius: '16px' }}
        >
            <TimelineHeader items={timelineHeaderItems} />
            <Timeline items={timelineItems} />
        </Container>
    );
}

export default MainTimelineBab;

import { useState } from 'react'
import './Registration.css'
import asset110600012x from '../../assets/11060001@2x.png';
import { Col, Row } from 'react-bootstrap';
import RegisForm from '../../../talent/features/auth/NewRegisForm';
import Banner from '../../../talent/features/auth/Banner';
import { Modal } from 'react-bootstrap';
import image12 from '../../assets/image12.png';

const Registration = () => {
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [showRegisterModalMobile, setShowRegisterModalMobile] = useState(false);
  const [isButtonHovered, setIsButtonHovered] = useState(false);

  const handleButtonClick = (event) => {
    if (window.innerWidth <= 600) {
      // Jika perangkat seluler, tampilkan modal peringatan
      // Gantilah ini dengan logika untuk menampilkan modal peringatan
      setShowRegisterModalMobile(true);
    } else {
      setShowRegisterModal(true);
    }
  };

  const handleCloseRegisterModal = () => {
    setShowRegisterModal(false);
  };

  const handleCloseRegisterModalMobile = (event) => {
    setShowRegisterModalMobile(false);
  };

  const handleButtonMouseEnter = () => {
    setIsButtonHovered(true);
  };

  const handleButtonMouseLeave = () => {
    setIsButtonHovered(false);
  };

  return (
    <div className="position-relative container-fluid" id="4">
      <div className="container position-relative">
        <div className="section-registration row">
          <div className="col-12 order-2 order-md-1 col-md-6 my-auto py-5">
            <h2 className="fw-bold mb-4">Registrasi Funtastic 79 Bootcamp</h2>
            <div className="paragraph">
              <span>
                Daftar sekarang juga untuk mendapatkan akses belajar di Digital
                Bootcamp 79 <br /> dan link Discord untuk melakukan diskusi
                bersama.
              </span>
            </div>
            <div className="d-flex mt-4">
              <div
                className={`btn ${isButtonHovered ? 'btn-hover' : 'btn-primary'} me-3`}
                onClick={handleButtonClick}
                onMouseEnter={handleButtonMouseEnter}
                onMouseLeave={handleButtonMouseLeave}
              >
                Daftar Sekarang Juga!
              </div>
            </div>
          </div>
          <div className="col-12 order-1 order-md-2 col-md-6 my-auto">
            <img className="img-mentor" alt="" src={asset110600012x} />
          </div>
        </div>
      </div>

      <Modal show={showRegisterModal} onHide={handleCloseRegisterModal} size="xl">
        <div class="modal-content-transparent rounded-0">
          <Row className='mx-0 px-0' style={{ height: '100%', width: '100%' }}>
            <Col className='col-6 login-banner-col'
              style={{
                background: 'url(/img/talent-login-banner.png) rgba(0, 0, 0, 0.75)',
                backgroundBlendMode: 'multiply',
                backgroundSize: 'cover',
              }}
            >
              <Banner />
            </Col>
            <Col className={`bg-white col-6 h-100 d-flex flex-column justify-content-center`}
              style={{
                padding: '2rem',
              }}
            >
              <button className="btn-close" onClick={handleCloseRegisterModal} style={{
                position: 'absolute',
                top: '10px',
                right: '10px', // Memindahkan tombol ke sudut kanan atas
                zIndex: '1050',
              }}></button>
              <Modal.Body>
                <RegisForm />
              </Modal.Body>
            </Col>
          </Row>
        </div>
      </Modal>

      <Modal show={showRegisterModalMobile} onHide={handleCloseRegisterModalMobile} size="s">
        <Modal.Body>
          <button className="btn-close" onClick={handleCloseRegisterModalMobile} style={{
            position: 'absolute',
            top: '10px',
            right: '10px', // Memindahkan tombol ke sudut kanan atas
            zIndex: '1050',
          }}></button>
          <img src={image12} className="w-100 img-modal"></img>
          <div className="Paragraph" style={{alignSelf: 'stretch', textAlign: 'center', color: '#525A80', fontSize: 16, fontFamily: 'Source Sans Pro', wordWrap: 'break-word'}}> Pendaftaran hanya dapat dilakukan pada  Desktop (Laptop/PC)</div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-primary btn-block" onClick={handleCloseRegisterModalMobile} style={{ width: "100%" }}>
            Kembali
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default Registration

import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Card,
  // Table,
} from "react-bootstrap";
import { useDispatch } from "react-redux";
import { MdSend } from "react-icons/md";
import { hideErrorAlert, showErrorAlert } from "../../../app";
import {
  addKomentarTrainer,
  listKomentarTrainer,
} from "../../service/KomentarService";
import InfiniteScroll from "react-infinite-scroller";
import Statistik from "./Statistik";
import { columnsReportBelajar } from "./columns";
import { TableScrollable } from "../../common/table";
import {
  getReportBelajar,
  exportReportBelajar,
  getAllInstitution,
  getTotalTalentAllCity,
  getTotalTalentAllProvince,
} from "../../service/DashboardService";
import { bgColor, bgColor2, cityColor, provinceColor } from "./dummy";
import FileSaver from "file-saver";
import DoughnutChart from "./DoughnutChart";

export default function TalentInstitution() {
  const dispatch = useDispatch();
  const [talentInstitution, setTalentInstitution] = useState([]);
  const [talentCity, setTalentCity] = useState([]);
  const [talentProvince, setTalentProvince] = useState([]);

  // function randomInteger(max) {
  //   return Math.floor(Math.random() * (max + 1));
  // }

  // function randomRGBColor() {
  //   let r = randomInteger(255);
  //   let g = randomInteger(255);
  //   let b = randomInteger(255);

  //   return [r, g, b];
  // }

  // function randomHexColor() {
  //   let [r, g, b] = randomRGBColor();
  //   let hr = r.toString(16).padStart(2, "0");
  //   let hg = g.toString(16).padStart(2, "0");
  //   let hb = b.toString(16).padStart(2, "0");
  //   return "#" + hr + hg + hb;
  // }

  const fetchAllInstitution = () => {
    getAllInstitution()
      .then(
        (response) => {
          switch (response.data.status_code) {
            case "TAL200":
              setTalentInstitution(response.data.message);
              break;
            default:
              dispatch(showErrorAlert("Terjadi kesalahan pada server!"));
              break;
          }
        },
        (error) => {
          console.log("error :", error);
        }
      )
      .finally((info) => {});
  };

  const fetchTotalTalentAllCity = () => {
    getTotalTalentAllCity()
      .then(
        (response) => {
          switch (response.data.status_code) {
            case "TAL200":
              setTalentCity(response.data.message);
              break;
            default:
              dispatch(showErrorAlert("Terjadi kesalahan pada server!"));
              break;
          }
        },
        (error) => {
          console.log("error :", error);
        }
      )
      .finally((info) => {});
  };

  const fetchTotalTalentAllProvince = () => {
    getTotalTalentAllProvince()
      .then(
        (response) => {
          switch (response.data.status_code) {
            case "TAL200":
              setTalentProvince(response.data.message);
              break;
            default:
              dispatch(showErrorAlert("Terjadi kesalahan pada server!"));
              break;
          }
        },
        (error) => {
          console.log("error :", error);
        }
      )
      .finally((info) => {});
  };

  // const setColor = () => {
  //   for (let i = 0; i < talentCity.length; i++) {
  //     setCityColor(cityColor => [...cityColor, randomHexColor()]);
  //   }
  //   for (let i = 0; i < talentProvince.length; i++) {
  //     setProvinceColor(provinceColor => [...provinceColor, randomHexColor()]);
  //   }
  // }

  useEffect(() => {
    fetchAllInstitution();
    fetchTotalTalentAllCity();
    fetchTotalTalentAllProvince();
  }, [dispatch]);

  const columns = [
    {
      Header: "Nama Lembaga",
      accessor: "label",
    },
    {
      Header: "Kabupaten/Kota",
      accessor: "kabupaten_kota",
    },
    {
      Header: "Provinsi",
      accessor: "provinsi",
    },
    {
      Header: "Jumlah Peserta",
      accessor: "total",
    },
  ];

  const statistikKota = () => {
    return (
      <div className="py-2">
        <Card className="h-100 w-100 px-2 py-3">
          <Container className="p-0">
            <Row>
              <Col xs={9} className="f-column-between">
                <strong className="fsz-075" style={{ color: "#586A84" }}>
                  Kabupaten/Kota
                </strong>
                <div style={{ maxHeight: "70px", overflowY: "auto" }}>
                  {talentCity.length > 0 ? (
                    <>
                      {talentCity.map((d, i) => {
                        return (
                          <div className="d-flex flex-row align-items-center">
                            <div
                              style={{
                                width: 10,
                                height: 10,
                                background: cityColor[i],
                                marginRight: 5,
                              }}
                            />
                            <span style={{ fontSize: 11 }}>
                              {d?.label} : <strong>{d.total}</strong> Talent
                            </span>
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    <strong style={{ color: "#f03c32" }}>Data not found</strong>
                  )}
                </div>
                <strong
                  style={{
                    fontSize: 12,
                    textDecorationLine: "underline",
                    color: "#0078D7",
                    cursor: "pointer",
                  }}
                ></strong>
              </Col>
              <Col xs={3}>
                <DoughnutChart datas={talentCity} bgColor={cityColor} />
              </Col>
            </Row>
          </Container>
        </Card>
      </div>
    );
  };

  const statistikProvinsi = () => {
    return (
      <div className="py-2">
        <Card className="h-100 w-100 px-2 py-3">
          <Container className="p-0">
            <Row>
              <Col xs={9} className="f-column-between">
                <strong className="fsz-075" style={{ color: "#586A84" }}>
                  Provinsi
                </strong>
                <div style={{ maxHeight: "70px", overflowY: "auto" }}>
                  {talentProvince.length > 0 ? (
                    <>
                      {talentProvince.map((d, i) => {
                        return (
                          <div className="d-flex flex-row align-items-center">
                            <div
                              style={{
                                width: 10,
                                height: 10,
                                background: provinceColor[i],
                                marginRight: 5,
                              }}
                            />
                            <span style={{ fontSize: 11 }}>
                              {d?.label} : <strong>{d.total}</strong> Talent
                            </span>
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    <strong style={{ color: "#f03c32" }}>Data not found</strong>
                  )}
                </div>
                <strong
                  style={{
                    fontSize: 12,
                    textDecorationLine: "underline",
                    color: "#0078D7",
                    cursor: "pointer",
                  }}
                ></strong>
              </Col>
              <Col xs={3}>
                <DoughnutChart datas={talentProvince} bgColor={provinceColor} />
              </Col>
            </Row>
          </Container>
        </Card>
      </div>
    );
  };

  return (
    <Card className="mt-4">
      <Container fluid className="p-3">
        <Row>
          <Col xs="12" lg="6">
            <TableScrollable
              data={talentInstitution}
              columns={columns}
              showAddButton={false}
              showFilter={false}
              title="Total Talent / Lembaga"
              urlNavigate={false}
              onExport={() => {}}
              onSearchChange={() => {}}
              showSearch={false}
              showExport={false}
            />
          </Col>
          <Col xs="12" lg="6">
            <Row>
              <Col xs="12" lg="12">
                {statistikProvinsi()}
              </Col>
            </Row>
            <Row>
              <Col xs="12" lg="12">
                {statistikKota()}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Card>
  );
}

import React from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { MdErrorOutline } from 'react-icons/md';

export default function KuisBabEndConfirmation({
    show, setShow, setStartKuis, setEndKuis, nilai
}) {
    const tidakButton = <Col className='col-auto'>
        <Button variant='outline-primary'
            onClick={() => setShow(false)}
            style={{
                width: '9rem',
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2), 0px 4px 8px rgba(0, 0, 0, 0.2)'
            }}
        >
            Tidak
        </Button>
    </Col>

    const yaButton = <Col className='col-auto ms-auto'>
        <Button variant='primary'
            onClick={() => {
                setShow(false);
                if (nilai) setEndKuis(true);
                else setStartKuis(false);
            }}
            style={{
                width: '9rem',
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2), 0px 4px 8px rgba(0, 0, 0, 0.2)'
            }}
        >
            Ya
        </Button>
    </Col>

    return (
        <Modal show={show} onHide={() => setShow(false)} centered>
            <Modal.Header closeButton />
            <Modal.Body
                className='px-5 py-4 d-flex align-items-center justify-content-center flex-column'
            >
                <MdErrorOutline color='#FBBC04'
                    className='mb-4'
                    style={{ width: '5rem', height: '5rem' }}
                />
                <div
                    style={{ color: '#263238', marginBottom: '3.5rem' }}
                    className='fsz-1125'
                >
                    Apakah anda ingin mengakhiri test ini?
                </div>
                <Row>
                    {tidakButton}
                    {yaButton}
                </Row>
            </Modal.Body>
        </Modal>
    );
}

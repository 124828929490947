import { Badge } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';

export const columns = (editFunction, deleteFunction) => [
    {
        Header: "No",
        accessor: "nomor",
        Cell: (row) => {
            return <div>{Number(row.row.id) + 1}</div>;
        },
        disableFilters: true,
        disableSortBy: true,
    },
    {
        Header: 'Judul Rangkuman',
        accessor: 'judul',
    },
    {
        Header: 'SubBab',
        accessor: 'namaSubbab',
    },
    {
        Header: 'Bab',
        accessor: 'namaBab',
    },
    {
        Header: 'Topik',
        accessor: 'judulTopik',
    },
    {
        Header: 'Visibilitas',
        accessor: 'visibilitas',
        Cell: ({ cell: { value } }) => {
            if (value === 'publik') return <div><FontAwesomeIcon icon={faEye} size='xs' /> Publik</div>;
            else return <div><FontAwesomeIcon icon={faEyeSlash} size='xs' /> Sembunyi</div>;
        }
    },
    {
        Header: 'Tag',
        accessor: 'tag'
    },
    {
        Header: 'Aksi',
        accessor: 'aksi',
        disableSortBy: true,
        disableFilters: true,
        Cell: (row) => {
            return (
                <>
                    <FontAwesomeIcon icon={faEdit} color="#ffc107" className='me-2'
                        onClick={(e) => editFunction(row.row.original)}
                        style={{ cursor: 'pointer' }}
                    />
                    <FontAwesomeIcon icon={faTrash} color="#dc3545"
                        onClick={(e) => deleteFunction(row.row.original)}
                        style={{ cursor: 'pointer' }}
                    />
                </>
            );
        }
    },
];
import React, { useState, useEffect } from 'react';

import { faEye, faEyeSlash, faInfo, faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Modal, Form, Row, Col, OverlayTrigger, Tooltip, Stack } from 'react-bootstrap';

import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import { useDispatch } from 'react-redux';
import {
    showErrorAlert, hideErrorAlert,
    showSuccessAlert, hideSuccessAlert,
} from '../../../app';

import { tag_list } from '../dummy/tag';
import { addTopikTrainer, editTopikTrainer } from '../../service/TopikService';

function TopikPopUp({ show, setShow, topikData, isEdit, setRefreshData, tagList: tagListData }) {
    const dispatch = useDispatch();

    const [id, setId] = useState();
    const [judul, setJudul] = useState();
    const [thumbnail, setThumbnail] = useState('');
    const [deskripsi, setDeskripsi] = useState();
    const [tag, setTag] = useState();
    const [tingkatan, setTingkatan] = useState();
    const [visibilitas, setVisibilitas] = useState();

    // file
    const [selectedFile, setSelectedFile] = useState('');
    const [isSelected, setIsSelected] = useState(false);

    const [tingkatanOptions, setTingkatanOptions] = useState([
        { value: 'mudah', label: 'Mudah' },
        { value: 'sedang', label: 'Sedang' },
        { value: 'sulit', label: 'Sulit' },
    ]);

    const [visibilitasOptions, setVisibilitasOptions] = useState([
        {
            value: 'sembunyi', label: <span>
                <FontAwesomeIcon icon={faEyeSlash} color={'#263238'} className='me-2' />Sembunyi</span>
        },
        {
            value: 'publik', label: <span>
                <FontAwesomeIcon icon={faEye} color={'#263238'} className='me-2' />Publik</span>
        },
    ]);

    useEffect(() => {
        setId(topikData?.id || '');
        setJudul(topikData?.judul || '');
        setThumbnail(topikData?.thumbnail || '');
        setDeskripsi(topikData?.deskripsi || '');
        setTingkatan(topikData?.tingkatan || '');
        setTag(topikData?.tag || '');
        setVisibilitas(topikData?.visibilitas || 'sembunyi');
        setSelectedFile('');
    }, [show, topikData]);

    const splitUrl = (thumbnail) => {
        return thumbnail.split('\\').pop().split('/').pop();
    }

    const tagList = tagListData;

    const tagSelected = tag ? tag?.split(',')
        .map(item => ({ value: item.trim(), label: item.trim() })) : null;

    const tagOptions = tagList.map(item => ({ value: item, label: item }));

    const tingkatanSelected = tingkatanOptions.find(item => item.value === tingkatan) || null;
    const visibilitasSelected = visibilitasOptions.find(item => item.value === visibilitas) || null;

    const isOnlyLetterAndSpace = (str) => {
        const re = new RegExp(/^[A-Za-z\s]*$/);
        return re.test(str);
    }

    const handleTagChange = (tags) => {
        for (const tag of tags) {
            if (!isOnlyLetterAndSpace(tag.value)) {
                dispatch(showErrorAlert('Tag hanya boleh mengandung huruf dan spasi!'));
                return;
            }
        }
        setTag(tags.map(tag => tag.value).join(', '));
    }

    const handleTingkatanChange = (value) => {
        setTingkatan(value.value);
    }

    const handleVisibilitasChange = (value) => {
        setVisibilitas(value.value);
    }

    const judulTopikField = <Col>
        <Form.Group>
            <Form.Label>Judul/Topik Materi <b className='asterisk'>*</b></Form.Label>
            <Form.Control placeholder='Judul/Topik Materi'
                value={judul}
                onChange={(e) => { setJudul(e.target.value) }}
            />
        </Form.Group>
    </Col>

    const firstRowForm = <Row className='mx-0 mb-3'>
        {judulTopikField}
    </Row>

    const FileUploader = file => {
        const hiddenFileInput = React.useRef(null);

        const handleClick = event => {
            hiddenFileInput.current.click();
        };

        const handleChange = event => {
            const fileUploaded = event.target.files[0];
            if (fileUploaded) {
                setSelectedFile(event.target.files[0]);
                setIsSelected(true);
            }
        };

        return (
            <>
                <div className='d-flex align-items-center'>
                    <Button onClick={handleClick} className='d-inline me-2'>
                        <FontAwesomeIcon icon={faUpload} className='me-2' /> Upload
                    </Button>
                    {isSelected
                        ? (
                            <Form.Label className='text-ellipsis mb-0' style={{ maxWidth: "70%" }}>{selectedFile?.name}</Form.Label>
                        )
                        : isEdit
                            ? (
                                <Form.Label className='text-ellipsis mb-0' style={{ maxWidth: "70%" }}>{
                                    <a href={thumbnail} target='_blank' rel='noreferrer'>
                                        {thumbnail.split(/(\\|\/)/g).pop()}
                                    </a>
                                }
                                </Form.Label>
                            )
                            : (
                                <span>Pilih file untuk diupload</span>
                            )
                    }
                </div>
                <input
                    type='file'
                    ref={hiddenFileInput}
                    onChange={handleChange}
                    style={{ display: 'none' }}
                    accept='.png, .jpg, .jpeg' />
            </>
        );
    }

    const thumbnailField = <Col>
        <Form.Group>
            <Form.Label>Thumbnail <b className='asterisk'>*</b></Form.Label>
            <FileUploader />
        </Form.Group>
    </Col>

    const secondRowForm = <Row className='mx-0 mb-3'>
        {thumbnailField}
    </Row>

    const deskripsiField = <Col>
        <Form.Group>
            <Form.Label>Deskripsi Materi<b className='asterisk'>*</b></Form.Label>
            <Form.Control as="textarea" placeholder="Deskripsi"
                onChange={(e) => { setDeskripsi(e.target.value) }}
                value={deskripsi} />
        </Form.Group>
    </Col>

    const thirdRowForm = <Row className='mx-0 mb-3'>
        {deskripsiField}
    </Row>

    const tagTooltip = <OverlayTrigger
        trigger='hover'
        placement='top'
        overlay={
            <Tooltip className='tag-tooltip'>
                Mohon diisi minimal 1 tag
            </Tooltip>
        }
    >
        <Button variant='light'
            className='tag-tooltip-button bg-transparent p-0 ms-2 rounded-circle
                border border-2 border-dark d-flex align-items-center justify-content-center'
            style={{ width: '1rem', height: '1rem' }}
        >
            <FontAwesomeIcon icon={faInfo} style={{ fontSize: '0.625rem' }} />
        </Button>
    </OverlayTrigger>

    const tagField = <Col>
        <Form.Group>
            <Stack direction='horizontal' className='mb-2'>
                <Form.Label className='mb-0'>Tag <b className='asterisk'>*</b></Form.Label>
                {tagTooltip}
            </Stack>
            <CreatableSelect isMulti isClearable={false}
                value={tagSelected}
                noOptionsMessage={() => 'No options'}
                placeholder='Tag'
                options={tagOptions}
                formatCreateLabel={(newTag) => `Buat tag '${newTag}'`}
                onChange={handleTagChange}
                getNewOptionData={(value, label) => {
                    return {
                        value: value.toUpperCase(),
                        label: label.replace(`${value}`, value.toUpperCase())
                    }
                }}
            />
        </Form.Group>
    </Col>

    const fourthRowForm = <Row className='mx-0 mb-3'>
        {tagField}
    </Row>

    const tingkatanField = <Col>
        <Form.Group>
            <Form.Label>Tingkatan <b className='asterisk'>*</b></Form.Label>
            <Select
                value={tingkatanSelected}
                noOptionsMessage={() => 'No options'}
                placeholder='Tingkatan'
                options={tingkatanOptions}
                onChange={handleTingkatanChange}
            />
        </Form.Group>
    </Col>

    const visibilitasField = <Col>
        <Form.Group>
            <Form.Label>Visibilitas <b className='asterisk'>*</b></Form.Label>
            <Select value={visibilitasSelected}
                noOptionsMessage={() => 'No options'}
                placeholder='Visibilitas'
                options={visibilitasOptions}
                onChange={handleVisibilitasChange}
            />
        </Form.Group>
    </Col>

    const fifthRowForm = <Row className='mx-0 mb-3'>
        {tingkatanField}
        {visibilitasField}
    </Row>

    const addTopik = () => {
        addTopikTrainer({
            judul, selectedFile, deskripsi,
            tag, tingkatan, visibilitas
        }).then(
            (response) => {
                switch (response.data.status_code) {
                    case 'TPK200':
                        dispatch(showSuccessAlert('Topik berhasil dibuat'));
                        setRefreshData(prev => prev + 1);
                        setShow(prev => !prev);
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('Add Topik error :', error);
            }).finally((info) => {

            });
    }

    const editTopik = () => {
        editTopikTrainer({
            id, judul, selectedFile, deskripsi,
            tag, tingkatan, visibilitas
        }).then(
            (response) => {
                switch (response.data.status_code) {
                    case 'TPK200':
                        dispatch(showSuccessAlert('Topik berhasil diubah'));
                        setRefreshData(prev => prev + 1);
                        setShow(prev => !prev);
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('Edit Topik error :', error);
            }).finally((info) => {

            });
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        dispatch(hideErrorAlert());
        dispatch(hideSuccessAlert());

        if (!judul) {
            dispatch(showErrorAlert('Isi judul!'));
        } else if (!tag) {
            dispatch(showErrorAlert('Isi tag (minimal 1 tag)!'));
        } else if (!selectedFile && !isEdit) {
            dispatch(showErrorAlert('Isi thumbnail!'));
        } else if (selectedFile && selectedFile?.name &&
            !['png', 'PNG', 'jpg', 'JPG', 'jpeg', 'JPEG'].includes(selectedFile?.name?.split('.').pop())) {
            dispatch(showErrorAlert('Thumbnail harus berekstensi .png, .jpg atau .jpeg!'));
        } else if (selectedFile && selectedFile.size > 2097152) {
            dispatch(showErrorAlert('Ukuran thumbnail maksimal 2MB!'));
        } else if (!deskripsi) {
            dispatch(showErrorAlert('Isi deskripsi!'));
        } else if (!tingkatan) {
            dispatch(showErrorAlert('Pilih tingkatan!'));
        } else if (!visibilitas) {
            dispatch(showErrorAlert('Pilih visibilitas!'));
        } else {
            if (isEdit) editTopik();
            else addTopik();
        }
    }

    return (
        <Modal show={show} onHide={() => setShow(prev => !prev)} centered>
            <Modal.Header closeButton>
                <Modal.Title>{isEdit ? 'Edit Topik' : 'Buat Topik Baru'}</Modal.Title>
            </Modal.Header>
            <Form onSubmit={(e) => handleSubmit(e)}>
                <Modal.Body>
                    {firstRowForm}
                    {secondRowForm}
                    {thirdRowForm}
                    {fourthRowForm}
                    {fifthRowForm}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='light' onClick={() => setShow(prev => !prev)}
                        style={{ color: '#0078D7', border: '1px solid #0078D7' }}
                    >
                        Batal
                    </Button>
                    <Button type='submit' variant='primary'>
                        Simpan
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}

export default TopikPopUp;

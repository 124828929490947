import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams, Link } from 'react-router-dom';
import { GetParentPath } from '../../../common';
import { AiOutlineArrowLeft } from 'react-icons/ai';

// Component
import {
    Container, Row, Col, Button, ProgressBar,
    Accordion, InputGroup, Form
} from 'react-bootstrap';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { columns as subBabColumns } from './subbab_columns';
import { columns as kuisBabColumns } from './kuisbab_columns';
import Table from './Table';
import SubbabTimeline from './SubbabTimeline';

import { showErrorAlert } from '../../../app';

// Service
import { getBabTalent } from '../../service/BabService';
import { listSubbabTalent } from '../../service/SubbabService';
import { listKuisBabTalent } from '../../service/KuisBabService';

function Subbab() {
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const [searchTerm, setSearchTerm] = useState('');
    const [filter, setFilter] = useState("all");
    const [idBab, setIdBab] = useState(searchParams.get('idBab'));
    const [subbabList, setSubbabList] = useState([]);
    const [kuisBabList, setKuisBabList] = useState([]);

    const navigate = useNavigate();
    const parentPath = GetParentPath();

    const [idTopik, setIdTopik] = useState('');
    const [bab, setBab] = useState({});
    const [idSubbab, setIdSubbab] = useState('');
    const [idKuisBab, setIdKuisBab] = useState('');
    const lastSeenSubbab = subbabList.find(subbab => (subbab.id === bab.terakhirDipelajari));

    useEffect(() => {
        if (idSubbab) navigate(`${parentPath}/materi?idSubbab=${idSubbab}`);
    }, [idSubbab, parentPath, navigate]);

    useEffect(() => {
        if (idBab && idKuisBab) navigate(`${parentPath}/kuis-bab?idBab=${idBab}&id=${idKuisBab}`);
    }, [idKuisBab, parentPath, navigate, idBab]);

    useEffect(() => {
        getBabTalent({ id: idBab }).then(
            (response) => {
                switch (response.data.status_code) {
                    case 'BAB200':
                        setIdTopik(response.data.message.idTopik || '');
                        setBab(response.data.message || {});
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('Get Bab error :', error);
            }).finally((info) => {

            });
    }, [idBab, dispatch])

    useEffect(() => {
        if (!idBab) return;
        listKuisBabTalent({ idBab }).then(
            (response) => {
                switch (response.data.status_code) {
                    case 'KBB200':
                        setKuisBabList(response.data.message || []);
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('List Kuis Bab error :', error);
            }).finally((info) => {

            });
    }, [idBab, dispatch])

    useEffect(() => {
        setIdBab(searchParams.get('idBab'));
        if (!idBab) navigate(`${parentPath}/topik`);
    }, [idBab, parentPath, searchParams, navigate]);

    useEffect(() => {
        listSubbabTalent({ idBab }).then(
            (response) => {
                switch (response.data.status_code) {
                    case 'SBB200':
                        setSubbabList(response.data.message || []);
                        break;
                    default:
                        dispatch(showErrorAlert('Terjadi kesalahan pada server!'));
                        break;
                }
            },
            (error) => {
                console.log('List Subbab error :', error);
            }).finally((info) => {

            });
    }, [idBab, dispatch]);

    const subbabSearch = (term) => {
        const fieldSearchSubbab = ['nama', 'tag'];

        return subbabList.filter(subbab => {
            if (filter === 'all') {
                for (let i = 0; i < fieldSearchSubbab.length; i++) {
                    if (subbab[fieldSearchSubbab[i]] !== undefined)
                        if (String(subbab[fieldSearchSubbab[i]])
                            .toLowerCase()
                            .includes(String(term)
                                .toLowerCase()
                            )) return true;
                }
                return false;
            } else {
                if (subbab[filter] !== undefined)
                    if (String(subbab[filter])
                        .toLowerCase()
                        .includes(String(term)
                            .toLowerCase()
                        )) return true;
                return false;
            }
        })
    }

    const ColumnFilter = <Col xs="auto">
        <Form.Select
            placeholder='Filter'
            className='search-bab-br-05'
            onChange={e => {
                setFilter(e.target.value);
                setTimeout(console.log(filter), 500);
            }}
            value={filter}>
            <option value="all">All Filter</option>
            <option value="nama">Nama Subbab</option>
            <option value="tag">Tag</option>
        </Form.Select>
    </Col>

    const SearchField = <Col xs lg={6}>
        <InputGroup className='search-subbab'>
            <Form.Control
                placeholder='Pencarian'
                className='search-input'
                value={searchTerm || ''}
                onChange={e => {
                    setSearchTerm(e.target.value);
                }} />
            <InputGroup.Text id="btnGroupAddon" className='bg-white search-icon'
                onClick={() => {
                    setSearchTerm('');
                }}
                style={searchTerm ? { cursor: 'pointer' } : {}}
            >
                {!searchTerm
                    ? <FontAwesomeIcon icon={faSearch} />
                    : <FontAwesomeIcon icon={faTimes} />
                }
            </InputGroup.Text>
        </InputGroup>
    </Col>

    return (
        <Container fluid className='px-5' style={{ paddingTop: '4rem', overflowX: 'hidden' }}>
            <Link to={`${parentPath}/bab?idTopik=${idTopik}`}>
                <Button variant='light'
                    className='back-bab-button bg-transparent border-0 p-0 fsz-1125 mb-2'
                >
                    <AiOutlineArrowLeft className='me-2' style={{ color: '#757575' }} />
                    <span style={{ color: '#081F32' }}>Kembali</span>
                </Button>
            </Link>
            <Container fluid className='p-0 mb-3'>
                <Row>
                    {SearchField}
                    {ColumnFilter}
                </Row>
            </Container>
            <Container fluid className='p-0'>
                <SubbabTimeline subbabList={subbabList} bab={bab} setIdSubbab={setIdSubbab} />
            </Container>
            {lastSeenSubbab?.id && <Container fluid className='mb-3 p-0'>
                <Row className='subbab-terakhir m-0'>
                    <Col>
                        <Row>
                            <Col><span className='fw-bold'>Sub-bab terakhir dilihat</span></Col>
                        </Row>
                        <Row>
                            <Col lg='auto' className='d-grid align-content-center'>
                                <span>{lastSeenSubbab?.nama}</span>
                            </Col>
                            <Col lg='auto ms-auto'>
                                <Link to={`${parentPath}/materi?idSubbab=${lastSeenSubbab?.id}`}>
                                    <Button variant='light' className='px-5 fw-bold text-primary'>
                                        Lanjut
                                    </Button>
                                </Link>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <span className='d-block'>Progress</span>
                                <div className='w-75 d-inline-block me-4'><ProgressBar variant='info'
                                    now={lastSeenSubbab?.progress} className='progress-basic'
                                />
                                </div>
                                <span>{lastSeenSubbab?.progress}%</span>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>}
            <Container fluid className='mb-3 p-0'>
                <Row className='m-0'>
                    <Col className='px-0'>
                        <Accordion defaultActiveKey={['0']} >
                            <Accordion.Item eventKey="0" style={{ borderRadius: '16px' }}>
                                <Accordion.Header className='accord-subbab-header'>
                                    Sub Bab
                                </Accordion.Header>
                                <Accordion.Body>
                                    <Table data={subbabSearch(searchTerm)}
                                        columns={subBabColumns({
                                            lihatFunction: (event, idSubbab) => {
                                                setIdSubbab(idSubbab);
                                                event.stopPropagation();
                                            }
                                        })}
                                        rowClickFunction={(e) => { }}
                                    />
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Col>
                </Row>
            </Container>
            <Container fluid className='p-0'>
                <Row className='m-0'>
                    <Col className='px-0'>
                        <Accordion defaultActiveKey={['0']} >
                            <Accordion.Item eventKey="0" style={{ borderRadius: '16px' }}>
                                <Accordion.Header className='accord-subbab-header'>
                                    Latihan / Kuis
                                </Accordion.Header>
                                <Accordion.Body>
                                    <Table data={kuisBabList}
                                        columns={kuisBabColumns({
                                            lihatFunction: (event, idKuisBab) => {
                                                setIdKuisBab(idKuisBab);
                                                event.stopPropagation();
                                            }
                                        })}
                                        rowClickFunction={(e) => { }}
                                    />
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Col>
                </Row>
            </Container>
        </Container>
    )
}

export default Subbab;
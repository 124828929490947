import { useEffect, useState } from 'react';
import './Navbar.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import { Col, Modal, Row } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-scroll';
import Banner from '../../../talent/features/auth/Banner';
import LoginForm from '../../../talent/features/auth/LoginForm';
import RegisForm from '../../../talent/features/auth/NewRegisForm';
import logoarutalab from '../../assets/logoarutalab.png';
import logotujuhsembilan from '../../assets/logotujuhsembilan.png';
import image12 from '../../assets/image12.png';

const listLinks = [
  {
    id: 0,
    name: 'Goals',
    link: '#goals',
    offset: -45,
  },
  {
    id: 1,
    name: 'Benefit',
    link: '#benefit',
    offset: -45,
  },
  {
    id: 2,
    name: 'Time Prediction',
    link: '#timeprediction',
    offset: -45,
  },
  {
    id: 3,
    name: 'The Mentors',
    link: '#thementors',
    offset: -25,
  },
]

const Navbar = ({ scrollToContainer }) => {
  const { hash } = useLocation()
  const [linkActive, setLinkActive] = useState(null)

  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [showRegisterModalMobile, setShowRegisterModalMobile] = useState(false);


  const handleButtonClick = (event, buttonType) => {
    const buttons = document.querySelectorAll(".btn-toggle");
    buttons.forEach((button) => {
      button.classList.remove("btn-primary");
      button.classList.add("btn-ghost");
    });
  
    const button = event.currentTarget; // Menggunakan currentTarget daripada target
    button.classList.remove("btn-ghost");
    button.classList.add("btn-primary");
  
    if (buttonType === "login") {
      setShowLoginModal(true);
    } else if (buttonType === "register") {
      if (window.innerWidth <= 600) {
        // Jika perangkat seluler, tampilkan modal peringatan
        // Gantilah ini dengan logika untuk menampilkan modal peringatan
        setShowRegisterModalMobile(true);
      } else {
        setShowRegisterModal(true);
      }
    }
  };

  const handleCloseLoginModal = (event) => {
    setShowLoginModal(false);
  };

  const handleCloseRegisterModal = (event) => {
    setShowRegisterModal(false);
  };

  const handleCloseRegisterModalMobile = (event) => {
    setShowRegisterModalMobile(false);
  };

  useEffect(() => {
    if (hash) {
      const matchingLink = listLinks.find((link) => link.link === hash)
      if (matchingLink) {
        setLinkActive(matchingLink.id)
      }
    }
  }, [hash])

  useEffect(() => {
    if (linkActive || linkActive === 0) {
      scrollToContainer(linkActive)
    }
  }, [linkActive])

  return (
    <nav className="navbar my-navbar navbar-expand-lg bg-white fixed-top">
      <div className="container">
        <div className="logo">
          <div className="logo1">
            <a href="http://www.arutalalab.com/" target='_blank'>
              <img className="imageIcon" alt="" src={logoarutalab} />
            </a>
          </div>
          <div className="logoChild" />
          <div className="logo2">
            <a href="https://www.tujuhsembilan.com/" target='_blank'>
              <img className="image21Icon" alt="" src={logotujuhsembilan} />
            </a>
          </div>
        </div>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
            {listLinks.map((menu) => (
              <li className="nav-item" key={menu.id}>
                <Link activeClass="active" className={`nav-link ${menu.id === linkActive && 'active'}`} to={menu.id.toString()} spy={true} smooth={true} offset={menu.offset} duration={500}>
                  {menu.name}
                </Link>
              </li>
            ))}
          </ul>
          <form className="d-flex" role="search">
            <button
              className="btn btn-ghost me-4 btn-toggle"
              type="button"
              onClick={(e) => handleButtonClick(e, "login")}
            >
              Login
            </button>
            <button
              className="btn btn-ghost me-4 btn-toggle"
              type="button"
              onClick={(e) => handleButtonClick(e, "register")}
            >
              Register
            </button>
          </form>
        </div>
      </div>

      <Modal show={showLoginModal} onHide={handleCloseLoginModal} size="xl" centered className="modal-transparent">
        <div class="modal-content-transparent rounded-0">
          <Row className='mx-0 px-0' style={{ height: '100%', width: '100%' }}>
            <Col className='col-6 login-banner-col'
              style={{
                background: 'url(/img/talent-login-banner.png) rgba(0, 0, 0, 0.75)',
                backgroundBlendMode: 'multiply',
                backgroundSize: 'cover',
              }}
            >
              <Banner />
            </Col>
            <Col className={`bg-white col-6 h-100 d-flex flex-column justify-content-center`}
              style={{
                padding: '2rem',
              }}
            >
              <button className="btn-close" onClick={handleCloseLoginModal} style={{
                position: 'absolute',
                top: '10px',
                right: '10px', // Memindahkan tombol ke sudut kanan atas
                zIndex: '1050',
              }}></button>
              <Modal.Body>
                <LoginForm />
              </Modal.Body>
            </Col>
          </Row>
        </div>
      </Modal>

      <Modal show={showRegisterModal} onHide={handleCloseRegisterModal} size="xl">
        <div class="modal-content-transparent rounded-0">
          <Row className='mx-0 px-0' style={{ height: '100%', width: '100%' }}>
            <Col className='col-6 login-banner-col'
              style={{
                background: 'url(/img/talent-login-banner.png) rgba(0, 0, 0, 0.75)',
                backgroundBlendMode: 'multiply',
                backgroundSize: 'cover',
              }}
            >
              <Banner />
            </Col>
            <Col className={`bg-white col-6 h-100 d-flex flex-column justify-content-center`}
              style={{
                padding: '2rem',
              }}
            >
              <button className="btn-close" onClick={handleCloseRegisterModal} style={{
                position: 'absolute',
                top: '10px',
                right: '10px', // Memindahkan tombol ke sudut kanan atas
                zIndex: '1050',
              }}></button>
              <Modal.Body>
                <RegisForm />
              </Modal.Body>
            </Col>
          </Row>
        </div>
      </Modal>

      <Modal show={showRegisterModalMobile} onHide={handleCloseRegisterModalMobile} size="s">
        <Modal.Body>
          <button className="btn-close" onClick={handleCloseRegisterModalMobile} style={{
            position: 'absolute',
            top: '10px',
            right: '10px', // Memindahkan tombol ke sudut kanan atas
            zIndex: '1050',
          }}></button>
          <img src={image12} className="w-100 img-modal"></img>
          <div className="Paragraph" style={{alignSelf: 'stretch', textAlign: 'center', color: '#525A80', fontSize: 16, fontFamily: 'Source Sans Pro', wordWrap: 'break-word'}}> Pendaftaran hanya dapat dilakukan pada  Desktop (Laptop/PC)</div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-primary btn-block" onClick={handleCloseRegisterModalMobile} style={{ width: "100%" }}>
            Kembali
          </button>
        </Modal.Footer>
      </Modal>

    </nav>
  )
}

export default Navbar

import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Image, ListGroup, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Timeline } from '../../common/timeline';
import StopWatch from './Stopwatch';

export default function KuisAkhirNavigation(props) {
    const judulKuis = <div className='fs-4 mb-2 fw-bold'>
        {props.kuis.judul}
    </div>

    const namaSubbab = <div className='fsz-1125 mb-5'>
        {props.subbab.nama}
    </div>

    const stopwatch = <Row className='px-5'>
        <Col className='col-auto'>
            Waktu Test :
        </Col>
        <Col className='col-auto ms-auto'>
            <StopWatch {...props} />
        </Col>
    </Row>

    const timelineItemsRaw = props.listSoalKuis.map((soal, index) => ({
        current: true,
        active: false,
        nama: `Soal ${index}`
    }));

    const timelineItems = timelineItemsRaw.map((item, index) => (
        {
            name: <ListGroup.Item className='border-0'>
                <Row>
                    <Col lg='4' className='icon-container'>
                        <Image src='/img/assignment_green.png' className='mx-auto' />
                    </Col>
                    <Col lg='8' className='d-inline-block my-auto p-2'>{item.nama}</Col>
                </Row>
            </ListGroup.Item>,
            active: (index === 0 || (index > 0 && props.choosenAnswers[props.listSoalKuis[index - 1].id])),
            last: index === props.activeSoalIndex,
            onClick: () => { props.setActiveSoalIndex(index) }
        }
    ));
    const itemListProgress = <Timeline items={timelineItems} isVertical />

    const akhiriButton = <Container className='d-flex align-items-center justify-content-center'>
        <Button variant='danger'
            onClick={() => props.setShow(true)}
            style={{
                width: '9rem',
                boxShadow: '0px 2px 4px rgba(234, 67, 53, 0.2), 0px 4px 8px rgba(234, 67, 53, 0.2)'
            }}
        >
            Akhiri
        </Button>
    </Container>

    return (
        <Container fluid className='bg-white rounded p-4'
            style={{ color: '#263238' }}
        >
            {judulKuis}
            {namaSubbab}
            {stopwatch}
            <hr className='mb-5' style={{ border: '1px solid #263238', opacity: '1' }} />
            <Container
                style={{ maxHeight: '35vh', overflowY: 'auto', overflowX: 'hidden' }}
            >
                {itemListProgress}
            </Container>
            <hr className='mb-4 mt-5' style={{ border: '1px solid #263238', opacity: '1' }} />
            {akhiriButton}
        </Container>
    );
}
